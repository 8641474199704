@import '../../landtrust.css';

.root {
  @apply --typography-heading-base;
  @apply --typography-heading;
  @apply --typography-heading-size-4xl;
  margin: 0;
}

.headingSpacing {
  margin: 0 0 var(--s-6) 0;
}
