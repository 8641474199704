@import '../../landtrust.css';

.root {
  border-radius: var(--border-radius-xl);
  box-shadow: var(--box-shadow-xl);
  padding: var(--s-8);
  background-color: var(--white);
  border: 1px var(--gray-300) solid;

  @media (--screen-md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.contentContainer {
  @media (--screen-md) {
    max-width: var(--w-2-3);
    display: flex;
  }
}

.icon {
  width: var(--s-18);
  height: var(--s-18);

  @media (--screen-md) {
    width: var(--s-24);
    height: var(--s-24);
    flex-shrink: 0;
  }
}

.title {
  text-transform: uppercase;
  font-weight: lighter;
  color: var(--gray-600);
  font-size: var(--text-sm);
  margin: var(--s-2) 0;
}

.subtitle {
  font-size: var(--text-lg);
  font-weight: bold;
  margin-bottom: var(--s-2);
}

.ctaContainer {
  margin-top: var(--s-8);
  text-align: center;

  @media (--screen-md) {
    margin-top: 0;
  }
}
