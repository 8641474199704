@import '../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
}

.formHeading {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
  margin: 0;
  color: var(--gray-900);

  @media (--viewportMedium) {
    margin-bottom: var(--s-4);
  }
}

.subHeading {
  font-size: var(--text-sm);
  margin: var(--s-2) 0 var(--s-4) 0;
  color: var(--gray-900);
  font-weight: normal;

  @media (--viewportMedium) {
    margin: 0 0 var(--s-4) 0;
  }
}

.insuranceToggle {
  font-weight: var(--font-bold);
}

.insuranceOptionWrapper,
.selected {
  border: none;
  font-weight: var(--font-bold);

  @media (--screen-md) {
    border-radius: var(--border-radius-xl);
    padding: var(--s-4) var(--s-7) var(--s-1) var(--s-4);
    margin-bottom: var(--s-4);
  }
}

.insuranceOptionWrapper {
  @media (--screen-md) {
    border: 1px solid var(--gray-300);
  }
}

.selected {
  @media (--screen-md) {
    border: 1px solid black;
  }
}

.insuranceLink {
  font-size: var(--text-sm);
  text-decoration: underline;
  margin: var(--s-6) 0 var(--s-2) var(--s-8);
  color: var(--gray-900);

  @media (--viewportMedium) {
    display: block;
    margin: 0 0 var(--s-3) var(--s-9);
  }
}

.subTextYes,
.subTextNo {
  margin: var(--s-2) 0 var(--s-4) var(--s-8);
  color: var(--gray-900);

  @media (--viewportMedium) {
    margin-left: var(--s-9);
  }
}

.subTextYes {
  font-size: var(--text-xxs);
  font-weight: normal;
}

.subTextNo {
  font-size: var(--text-xs);
  margin: 0 0 var(--s-2) var(--s-9);
}

.radioWrapper > span {
  margin-bottom: var(--s-2);
  display: block;
}

@media (--screen-md) {
  .radioWrapper {
    display: flex;
    flex-direction: column;

    & > span {
      margin-right: var(--s-7);
      margin-bottom: 0;
    }
  }
}
