@import '../../landtrust.css';

.root {
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);
}

.description {
  margin: 0 0 var(--s-3) 0;
}
