@import '../../landtrust.css';

:root {
  --PackageEmptyStateBase: {
    background-color: var(--gray-200);
    border-radius: var(--border-radius-xl);
    margin-bottom: var(--s-2);
  }
}

.package {
  position: relative;
  border: solid 1px var(--gray-300);
  padding: var(--s-5) var(--s-4) var(--s-7) var(--s-5);
  color: var(--gray-900);
  width: var(--s-88);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-xl);
  font-size: var(--text-sm);
  margin-bottom: var(--s-6);

  @media (--screen-md) {
    margin: var(--s-4);
  }
}

.packageLoadingWrapper {
  display: flex;
  flex-direction: column;
  max-height: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.fetchingStatus {
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  position: relative;
  bottom: var(--s-5);
}

.menuIconWrapper {
  position: absolute;
  top: var(--s-1);
  right: var(--s-5);
  width: var(--s-6);
}

.menuItemsWrapper {
  font-weight: var(--font-medium);

  & > li {
    margin-bottom: var(--s-1);
  }
}

.menuItemLink {
  text-decoration: none;
  color: var(--gray-900);
}

.packageTitle {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-1);
}

.packageTitleEmptyState {
  @apply --PackageEmptyStateBase;
  height: var(--s-8);
  width: 85%;
}

.packageDaysEmptyState {
  @apply --PackageEmptyStateBase;
  height: var(--s-5);
  width: 65%;
}

.packagePriceEmptyState {
  @apply --PackageEmptyStateBase;
  height: var(--s-5);
  width: 35%;
}

.packagePotenatialEarningsEmptyState {
  @apply --PackageEmptyStateBase;
  height: var(--s-5);
  width: 50%;
}

.packagePotentialEarnings {
  margin-top: var(--s-5);
}

.packageDetails {
  display: flex;
  flex-direction: column;
  font-size: var(--text-sm);
  width: 100%;
}

.packageDescription {
  display: block;
  color: var(--gray-900);
  text-decoration: underline;
  margin-top: var(--s-6);
}

.chooseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--s-31);
  height: var(--s-12);
  border-radius: var(--border-radius-xxxl);
  padding: var(--s-4) var(--s-2);
}

.bookingPackContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (--screen-md) {
    flex-direction: row;
  }
}

.packageInfo {
  display: flex;
}

.packageButton {
  margin-bottom: var(--s-3);
  display: flex;
  align-items: center;

  @media (--screen-md) {
    margin-bottom: 0;
  }
}

.protipWrapper {
  margin: var(--s-4) 0;
}

.description {
  display: flex;
  margin-bottom: var(--s-4);
}

.hasLodging {
  color: var(--gray-900);
  line-height: var(--line-height-tight);
  padding-top: var(--s-2);
}

.packagePotenatialEarnings {
  border-top: 1px solid var(--gray-300);
  padding-top: var(--s-5);
  margin-top: var(--s-5);
}

.badgesWrapper {
  margin: var(--s-5) 0 var(--s-6) 0;
}

.bookingPackageWrapper {
  display: flex;
  flex-direction: column;
}

.mobileCalendar {
  @media (--screen-md) {
    display: none;
  }
}

.desktopCalendar {
  display: none;

  @media (--screen-md) {
    display: flex;
  }
}

.packageModalBtn {
  @apply --typography-base;
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  border: none;
  padding: 0;
  background: none;
  text-decoration: underline;
  cursor: pointer;
}

.packageModalContainer {
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--s-6);
  background-color: var(--white);
  border-bottom: none;
  flex-grow: 0;
  min-height: auto;
  border-radius: 0;
  overflow: hidden;

@media (--screen-md) {
  margin: auto;
  width: var(--w-1-2);
  height: auto;
  border-radius: var(--border-radius);
}
}

.packageModalContent {
  padding: var(--s-3);
}

.packageModalHeader {
  font-weight: var(--font-medium);
  margin-bottom: var(--s-4);
  display: flex;
  align-items: center;
  width: var(--w-3-4);
}
