@import '../../marketplace.css';

.packagesContainer {
  display: none;

  @media (--screen-lg) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.modalHeading {
  margin-top: 0px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --typography-heading-size-3xl;

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: -2px;
  }
}

.bookingTitle {
  @apply --typography-heading-sans-serif;
  @apply --typography-heading-size-2xl;
  color: var(--gray-900);
  margin-top: 0;
  margin-bottom: 0;
}

.bookingHelp {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0 var(--s-24) 0;

  @media (--viewportMedium) {
    display: none;
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    max-width: 350px;
    min-height: auto;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);
  text-align: center;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: var(--z-60);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: var(--s-4) var(--s-4) 0 var(--s-4);
  background-color: var(--white);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.availLink {
  width: 100%;
}

.bookButton {
  border-radius: var(--border-radius-xxxl);
  margin-bottom: 1em;
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}
