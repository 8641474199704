@import '../../landtrust.css';

.page {
}

.container {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  @media (--screen-lg) {
    flex-direction: row;
  }
}

.formContainer {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
}

.topBarExitButton {
  font-family: var(--font-base);
  font-weight: bold;
  font-size: var(--text-sm);
  color: var(--font-color);
  cursor: pointer;
}

.topbarBackIconMobile {
  width: var(--s-4);
  height: var(--s-4);
  color: var(--gray-900);
}

.pickerContainer {
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--screen-md) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.coltonContainer {
  display: none;

  @media (--screen-lg) {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    width: 350px;
    margin-left: var(--s-12);
  }
}

.pickerElementContainer {
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--screen-md) {
    flex: initial;
    max-width: var(--w-1-2);
  }
}
