@import '../../landtrust.css';

.formSection {
  margin-bottom: var(--s-6);
}

.huntSpeciesWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(-1 * var(--s-2));
  margin-right: calc(-1 * var(--s-2));
  margin-top: calc(-1 * var(--s-2));
  margin-bottom: calc(-1 * var(--s-2));
}

.huntSpeciesItem {
  width: calc(50% - var(--s-4));
  margin-right: var(--s-2);
  margin-left: var(--s-2);
  margin-bottom: var(--s-2);
  margin-top: var(--s-2);

  @media (--viewportMedium) {
    width: calc(33.3% - var(--s-4));
  }
}

.radioWrapper {
  display: flex;

  & > span {
    margin-right: var(--s-4);
  }
}

.weaponCheckboxes,
.activityCheckboxes {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--s-2));
  margin-bottom: calc(-1 * var(--s-2));

  & > span {
    flex-basis: 50%;
    margin-top: var(--s-2);
    margin-bottom: var(--s-2);
  }
}

.activityLabelSubTitle {
  font-size: var(--text-base);
  font-weight: normal;
}
