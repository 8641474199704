@import '../../landtrust.css';

.buttonWrapper {
  break-inside: avoid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: var(--s-4);
  height: var(--s-5);
  margin-right: var(--s-1);
}
