@import '../../../landtrust.css';

.sectionContainer {
  display: flex;
  flex-direction: column;

  @media (--screen-md) {
    max-width: var(--w-3-5);
  }
}

.sectionActivities {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.titleStylesMobile {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: var(--text-xl);
  font-weight: bold;
  width: 100%;
  margin-bottom: var(--s-3);
}
