@import '../../landtrust.css';

.root {
  padding: 0;
  margin-bottom: var(--s-2);
  width: 100%;
}

.detailsGroupsContainer {
  display: flex;
  flex-flow: column;
}

.detailsGroup {
  margin-bottom: var(--s-8);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.detailsSubtitle {
  font-size: var(--text-xl);
  font-weight: bold;
  margin-bottom: var(--s-1);
}

.header {
  margin-left: var(--s-5);
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-4);

  @media (--screen-md) {
    margin-left: 0;
  }
}

.lodgingItemWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--s-4);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.lodgingItem {
  display: flex;
  align-items: center;
  margin-bottom: var(--s-1);
}

.icon {
  margin-right: var(--s-1);
  position: relative;
  right: 3px;
  height: var(--s-6);
  width: var(--s-6);
}

.lodgingItemTitle {
  font-weight: bold;
}

.lodgingItemLink {
  text-decoration: underline;
}
