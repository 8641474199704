@import '../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: var(--s-2);
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.containerLeft {
  width: 80%;
  align-self: flex-start;
}

.containerRight {
  width: 80%;
  align-self: flex-end;
}

.user {
  display: flex;
  align-items: center;
  position: relative;
  top: var(--s-1);
}

.avatar {
  width: var(--s-10);
  height: var(--s-10);
  margin-left: var(--s-4);
  margin-right: var(--s-2);
  border: 3px solid var(--white);
}

.userName {
  font-weight: var(--font-bold);
}

.message {
  padding: var(--s-3) var(--s-5) var(--s-5) var(--s-7);
  border-radius: 15px;
  background-color: var(--gray-200);
  color: var(--gray-900);
  margin-bottom: var(--s-1);
}

.messageDark {
  background-color: var(--gray-800);
  color: var(--white);
}

.messageRight {
  border-bottom-right-radius: var(--border-radius);
}

.messageLeft {
  border-bottom-left-radius: var(--border-radius);
}

.messageButton {
  background-color: var(--white);
  padding: 0;
}

.messageTransition {
  color: var(--white);
  background-color: transparent;
  padding: 0 0 var(--s-5) 0;

  @media (--screen-md) {
    width: 100%;
  }
}

.messageHeader {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-1);
}

.date {
  color: var(--gray-600);
  font-size: var(--text-xs);
}

.dateLeft {
  align-self: flex-start;
}

.dateRight {
  align-self: flex-end;
}

.buttons {
  position: fixed;
  width: 30%;
  bottom: var(--s-7);
  right: var(--s-6);
  padding: var(--s-5);
  margin: 0;
  background-color: var(--white);
  box-shadow: 0 -4px 10px 0 rgba(57, 58, 59, 0.1);
  border-bottom-left-radius: var(--s-6);
  border-bottom-right-radius: var(--s-6);
  border: 1px solid var(--gray-300);

  & > div button:only-child {
    width: 100%;
  }
}

.buttonsMobile {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--s-5);
  margin: 0;
  background-color: var(--white);
  box-shadow: 0 -4px 10px 0 rgba(57, 58, 59, 0.1);
  border: 1px solid var(--gray-300);

  & > div button:only-child {
    width: 100%;
  }
}

.buttonsTitle {
  color: var(--gray-900);
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  text-align: center;
  padding-bottom: var(--s-5);
}

.link {
  color: var(--white);
  text-decoration: underline;
}
