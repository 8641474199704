@import '../../landtrust.css';

.tooltipTitle,
.tooltipHeader {
  font-family: var(--font-base);
  display: flex;
  align-items: center;
  color: var(--gray-900);
  font-weight: bold;
}

.tooltipTitle {
  font-size: var(--text-3xl);
  margin-bottom: var(--s-4);
}

.tooltipHeader {
  font-size: var(--text-base);
  margin-bottom: var(--s-1);
}

.tooltipContent {
  font-size: var(--text-sm);
  min-width: var(--s-54);
  max-width: var(--s-64);
  margin-bottom: var(--s-7);

  @media (--screen-md) {
    min-width: var(--s-64);
    max-width: var(--s-76);
  }
}

.tooltipLink {
  position: relative;
  bottom: 2px;
  left: var(--s-2);
}

.toolTipIcon:hover {
  cursor: pointer;
  text-decoration: underline;
}

.proTipTextArea {
  display: block;
}
