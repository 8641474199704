@import '../../landtrust.css';

.root {
  background-color: var(--bg-4);
  color: var(--gray-900);
  width: 100%;
  padding: var(--s-5);
  height: 90vh;

  @media (--screen-md) {
    width: auto;
    flex-basis: var(--s-175);
    padding: var(--s-9);
    height: 70vh;
  }
}

.container {
  gap: var(--s-2);
}

.headerContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: var(--text-xl);
}

.headerImage {
  width: var(--s-46);

  @media (--screen-md) {
    width: var(--s-50);
  }
}

.intro {
  font-size: var(--text-xs);
  letter-spacing: 4.29px;
  text-transform: uppercase;
  margin-top: var(--s-2);
}

.logoImage {
  margin-top: var(--s-2);
  width: var(--s-37);
}

.header {
  margin-top: var(--s-2);
  font-size: var(--text-xl);
  font-weight: var(--font-bolder);
  width: 100%;

  @media (--screen-md) {
    width: var(--s-115);
  }
}

.content {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: var(--text-sm);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (--screen-md) {
    width: var(--s-120);
  }
}

.description {
  @media (--screen-md) {
    display: none;
  }
}

.list {
  margin-top: var(--s-3);

  & > li {
    display: flex;
    gap: var(--s-3);
  }

  @media (--screen-md) {
    margin-top: 0;
  }
}

.checkIcon {
  color: var(--green-500);
}

.buttonLearnMore {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  width: var(--s-84);
}
