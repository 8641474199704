@import '../../marketplace.css';
@import '../../landtrust.css';

.root {
}

.label {
  cursor: pointer;
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  width: 100%;
}

.avatarContainer {
  position: relative;
}

.buttonContainer {
  position: relative;
  padding: var(--s-3) var(--s-10);
  border: 1px solid transparent;
  font-weight: var(--font-medium);
  border-radius: var(--border-radius-xxxl);
  border-color: var(--gray-600);
  background-color: var(--white);
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-family: var(--font-base);
  font-size: var(--text-sm);
  background-color: var(--brand);
  color: var(--white);
  border-color: var(--brand);

  &:disabled {
    opacity: var(--o-50);
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
    background-color: var(--olive-600);
    border-color: var(--olive-600);
  }
}

.pencilIconContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 6px;
  width: var(--s-9);
  height: var(--s-9);
  background-color: var(--brand);
  color: var(--white);
  border: var(--s-1) solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pencilIcon {
  width: var(--s-8);
}

.uploadingImage {
  width: var(--s-24);
  height: var(--s-24);
  object-fit: cover;

  border-radius: 50%;
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;
}

.uploadingImageOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--gray-900);
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  top: -1000px;
  left: -1000px;
}

.avatar {
  width: var(--s-22);
  height: var(--s-22);
  margin-bottom: var(--s-4);
  cursor: pointer;
}

.modalContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 90%;
  padding: var(--s-2);
  background-color: var(--black);
  border-radius: var(--border-radius);
  border-bottom: none;
  flex-grow: 0;
  overflow: hidden;
  margin: 0;

  @media (--screen-md) {
    padding: var(--s-6) var(--s-14) 0 var(--s-6);
    height: auto;
    max-width: var(--s-230);
    margin: 0 var(--s-6);
  }
}

.closeButton {
  position: absolute;
  top: var(--s-10);
  right: var(--s-6);
  width: var(--s-10);
  height: var(--s-10);
  padding: var(--s-2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: var(--line-height-none);
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
  z-index: 1;

  @media (--screen-md) {
    top: var(--s-4);
    width: var(--s-8);
    height: var(--s-8);
  }
}

.closeIcon {
  color: white;
  transition: transform 0.3s ease;
  width: var(--s-10);
  height: var(--s-10);
}

.closeIcon:hover {
  transform: scale(1.1);
}

.avatarModalContainer {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--screen-md) {
    height: 100%;
    margin-bottom: var(--s-4);
  }
}

.modalButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.leftButtonGroup {
  display: flex;
  gap: var(--s-17);

  @media (--screen-md) {
    gap: 0;
  }
}

.iconEditContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--s-4) var(--s-4) var(--s-4) 0;
  border-radius: 5px;

  @media (--screen-md) {
    margin-left: var(--s-7);
    padding-right: var(--s-2);
    padding-left: var(--s-2);
  }
}

.iconAddPhotoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--s-4) 0 var(--s-4) var(--s-2);
  border-radius: 5px;

  @media (--screen-md) {
    margin-left: var(--s-7);
    padding-right: var(--s-2);
  }
}

.iconDeleteButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--s-4) var(--s-2) var(--s-4) var(--s-2);
  border-radius: 5px;

  @media (--screen-md) {
    margin-left: var(--s-7);
  }
}

.iconEditContainer:hover,
.iconAddPhotoContainer:hover,
.iconDeleteButtonContainer:hover {
  background-color: var(--gray-800);
}

.buttonIcons {
  font-size: var(--text-lg);
}

.changePhoto {
  font-size: var(--text-lg);
  cursor: pointer;
  color: white;
  font-family: var(--font-base);
  font-weight: 600;
}

.editModalContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 80vh;
  background-color: var(--black);
  border-radius: var(--border-radius);
  border-bottom: none;
  flex-grow: 0;
  overflow: hidden;
  margin: 0;

  @media (--screen-md) {
    padding: var(--s-6) var(--s-14) 0 var(--s-6);
    height: auto;
    max-width: var(--s-230);
    margin: 0 var(--s-6);
  }
}

.totalDivider {
  width: 100%;
  height: 1px;
  margin: var(--s-3) 0 var(--s-3) 0;
  border: none;
  background-color: var(--gray-300);

  @media (--screen-md) {
    margin-top: var(--s-10);
    margin-bottom: 0;
    margin-left: var(--s-7);
  }
}
