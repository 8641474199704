@import '../../marketplace.css';
@import '../../landtrust.css';

.root {
  margin: 0;
  @apply --typography-base;
}

.transitionItem {
  margin-bottom: var(--s-5);

  @media (--screen-md) {
    margin-bottom: var(--s-4);
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.transition {
  display: flex;
  flex-direction: row;
  border-radius: var(--s-2);
  margin-bottom: var(--s-2);

  @media (--screen-md) {
    padding: 0;
  }
}

.transitionInquiry {
  margin-bottom: var(--s-3);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.transitionDateRight {
  color: var(--gray-600);
  font-weight: var(--font-medium);
  font-size: var(--text-xs);
  margin: 7px 0 0 0;

  @media (--screen-md) {
    margin: -1px 0 1px 0;
  }
}

.transitionContent,
.transitionContentInquiry {
  font-weight: var(--font-bold);
  @media (--screen-md) {
    @apply --typography-base;
    font-weight: var(--font-bold);
  }
}
.transitionContent {
  font-size: var(--text-xl);
  margin: 0 auto;

  @media (--screen-md) {
    font-size: var(--text-lg);
  }
}

.transitionContentInquiry {
  margin: auto;
}

.transitionDate,
.transitionDateInquiry {
  font-family: var(--font-base);
  color: var(--gray-900);
  font-size: var(--text-xs);
  text-align: left;
  margin: var(--s-2) 0 0 0;

  @media (--screen-md) {
    margin: -1px 0 1px 0;
  }
}

.transitionDate {
  text-align: left;
}
.transitionDateInquiry {
  text-align: right;
}

.bullet {
  margin-right: var(--s-2);
}

.reviewContent {
  @apply --marketplaceH4FontStyles;
  font-style: italic;
  white-space: pre-line;
  margin: var(--s-2) 0 0 0;

  @media (--screen-md) {
    max-width: 500px;
  }
}

.reviewStars {
  display: block;
  margin-top: var(--s-1);

  @media (--screen-md) {
    margin: var(--s-1) 0;
  }
}

.reviewStar {
  width: var(--s-3);
  height: var(--s-3);
  margin-right: 2px;
  font-style: initial;
}

.showOlderWrapper {
  text-align: center;
  margin-bottom: 1px;

  @media (--screen-md) {
    margin-bottom: 0;
  }
}

.reviewButton {
  font-weight: var(--font-bold);
  margin: var(--s-5) auto;
  @media (--screen-md) {
    display: flex;
    margin: var(--s-4) 0 var(--s-2) 0;
  }
}

.firstName {
  color: var(--gray700);
  font-weight: var(--font-medium);
  margin: 0;
}

.circle {
  background: black;
  border-radius: var(--w-1-2);
  width: var(--s-2);
  height: var(--s-2);
  margin: var(--s-2) var(--s-2) var(--s-2) auto;
}

.status,
.circle {
  float: left;
}

.status {
  font-weight: var(--font-bold);
}

.float {
  display: inline-block;
}

.actionButtons {
  display: block;
  margin: var(--s-3) auto 0 auto;
}

.mainStatus {
  margin: 0 0 var(--s-3) 0;
}

.subtext {
  font-size: var(--text-base);
  font-weight: normal;
  margin: 0;
}

.inquiryContainer {
  margin: 0;
  padding: var(--s-2) var(--s-4) var(--s-2) var(--s-4);
  border-radius: var(--s-2);
  box-shadow: var(--box-shadow);
  font-weight: normal;
  background-color: var(--gray-200);
  color: var(--gray-800);
}
