@import '../../landtrust.css';

.root {
  position: relative;
  margin-bottom: var(--s-2);
  padding: 0;
}

.imageWrapper {
  padding-bottom: calc(147 / 335 * 100%);
  position: relative;
  border-radius: var(--s-2);
  overflow: hidden;
  margin-bottom: var(--s-4);

  @media (--screen-md) {
    border-radius: var(--s-4);
    padding-bottom: calc(211 / 488 * 100%);
  }
}

.image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
}

.listingTitle {
  display: none;

  @media (--screen-md) {
    display: block;
    font-family: var(--font-base);
    font-size: var(--text-2xl);
    font-weight: var(--font-bold);
    margin-top: var(--s-4);
    margin-bottom: var(--s-1);
  }
}

.listingTitleMobileVisible {
  composes: listingTitle;
  display: block;
}

.listingTitleLink {
  font-weight: var(--font-bold);
  font-size: var(--text-xl);
  color: var(--gray-900);
}

.packageTitleWrapper {
  display: flex;
  flex-direction: column;
  font-family: var(--font-base);
}

.packageTitleListing {
  font-size: var(--text-base);
  font-weight: normal;
}

.packageTitleListingLink {
  text-decoration: none;
}

.listingTitleInquiry {
  display: block;
  font-family: var(--font-base);
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  margin-top: var(--s-4);
  margin-bottom: var(--s-1);
}

.authorWrapper {
  display: flex;

  @media (--screen-md) {
    margin-bottom: var(--s-2);
  }
}

.avatar {
  width: var(--s-13);
  height: var(--s-13);
  margin-right: var(--s-2);
}

.authorNameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.authorLabel {
  font-size: var(--text-sm);
}

.authorName {
  font-weight: var(--font-bold);
}
