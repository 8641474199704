@import '../../landtrust.css';

.root {
  color: var(--gray-900);
  padding: 0 var(--s-6);

  @media (--screen-md) {
    padding: 0;
  }
}

.header {
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-2);
}

.tabContainer {
  color: var(--gray-900);
}

.hdTabs {
  margin-bottom: var(--s-6);
}

.tabHeaders {
  margin-bottom: var(--s-1);
  font-size: var(--text-base);
  font-weight: var(--font-bold);
}

.hdTab,
.speciesPill,
.hdTabInactive,
.speciesPillInactive {
  margin: var(--s-1) var(--s-4) var(--s-1) 0;
  font-size: var(--text-xs);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  min-width: var(--s-14);
  padding: var(--s-2) var(--s-3);
}

.hdTabInactive,
.speciesPillInactive {
  border: 1px solid var(--gray-300);
}

.speciesPillContainer {
  margin-bottom: var(--s-6);
}

.speciesPills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.licenseTypeSelector {
  margin-bottom: var(--s-5);
  width: var(--w-3-4);
  border: solid 1px var(--gray-400);
}

.tagAvailabilitySection {
  margin-bottom: var(--s-5);
}

.withTitle {
  margin-bottom: var(--s-4);
}

.titleHeader,
.infoHeader {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
}

.infoHeader {
  margin-bottom: var(--s-3);
}

.infoText {
  margin: 0;
}

.noInfoText {
  margin-bottom: var(--s-5);
}

.opportunity {
  padding: 0 var(--s-3) var(--s-5) 0;
}

.opportunity h4 {
  text-transform: capitalize;
}

.opportunityDetails {
  margin: 0 0 var(--s-2) 0;
  font-size: var(--text-sm);
  color: var(--gray-600);
}

.seasonDates {
  margin: 0;
}

.seasonDate {
  margin-bottom: var(--s-3);
}

.dateTitle {
  margin-right: var(--s-3);
}

.dateValue {
  margin-left: var(--s-2);
}

.tabContent {
  padding: var(--s-3) 0 0 0;
}

.specialTitleHeader {
  margin-top: var(--s-3);
}
