@import '../../landtrust.css';

.subscribeFormSection {
  max-width: var(--s-160);
  background-color: var(--gray-100);
  padding: var(--s-6) var(--s-4) var(--s-6) var(--s-4);
  border-radius: var(--border-radius-xl);
  border: solid 1px var(--gray-300);
  margin-bottom: var(--s-5);
  color: var(--gray-900);
}

.subscribeFormText {
  font-weight: var(--font-bold);
}

.subscribeFormSubmit {
  margin-top: var(--s-3);
}
