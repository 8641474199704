@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --DateRangeController_selectionHeight: 36px;
}

.inputRoot {
  /*
    Calendar component using react-dates has automatically padding so
    negative margin to left and right is needed for element to fit on smaller screens.
  */

  margin: 0px;

  @media (--viewportMedium) {
    margin: 0;
  }

  & :global(.DayPicker_transitionContainer__horizontal) {
    /* NOTE: there's a bug on iPhone where on the initial render the dynamic
      height isn't calculated properly, but on refresh it is. Forcing a min-height.
    */
    min-height: 334px;
  }

  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }

  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    box-shadow: none;
    background-color: transparent;
  }

  & :global(.DayPickerNavigation__horizontal) {
    position: relative;
    width: 100%;
  }
  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 21px;
    position: absolute;
    cursor: pointer;
    display: inline;
    &:first-of-type {
      left: 24px;
    }
    &:last-of-type {
      right: 24px;
    }
  }
  & :global(.DayPickerNavigation_button) {
    color: var(--matterColor);
    border: 0;
  }

  & :global(.CalendarMonth),
  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }

  & :global(.DayPicker_weekHeader) {
    color: var(--matterColor);
    top: 62px;
  }

  & :global(.DayPicker_weekHeader_li) {
    @apply --marketplaceH5FontStyles;

    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.DayPicker_weekHeader_li small) {
    font-size: 100%;
  }

  & :global(.CalendarMonth_caption) {
    color: var(--gray-900);
    font-weight: 400;
    line-height: 20px;
    padding-top: 31px;
    padding-bottom: 37px;

    &::first-letter {
      text-transform: capitalize;
    }

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.CalendarDay__default) {
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }

  & :global(.CalendarDay) {
    @apply --marketplaceH4FontStyles;
    color: var(--matterColor);
    border: 0;
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  /* Add an underline for '.renderedDay' */
  & :global(.CalendarDay__today .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeController_selectionHeight);
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%234A4A4A' fill-rule='evenodd'/></svg>");
    background-position: center 28px;
  }

  & :global(.CalendarDay__today.CalendarDay__selected .renderedDay) {
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
  }

  & :global(.CalendarDay:hover .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeController_selectionHeight);
    border: 1px solid var(--green-900);
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
    background-image: transparent;
    background-color: transparent;
  }
  & :global(.CalendarDay__hovered_span .renderedDay),
  & :global(.CalendarDay__selected_span .renderedDay),
  & :global(.CalendarDay__hovered_span:hover .renderedDay),
  & :global(.CalendarDay__selected_span:hover .renderedDay),
  & :global(.CalendarDay__hovered_span.CalendarDay__hovered_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeController_selectionHeight);
    background-color: var(--olive-300);
    color: var(--black);
    border-color: transparent;
  }

  & :global(.CalendarDay__blocked_minimum_nights .renderedDay),
  & :global(.CalendarDay__blocked_minimum_nights:hover .renderedDay) {
    background-color: transparent;
    color: var(--matterColorAnti);
    border: 0;
    text-decoration: line-through;

    & > span {
      cursor: default !important;
    }
  }

  & :global(.CalendarDay__hovered_span.CalendarDay__blocked_minimum_nights .renderedDay) {
    text-decoration: none;
  }

  & :global(.CalendarDay__selected_start.CalendarDay__blocked_minimum_nights .renderedDay) {
    text-decoration: none;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected_start) {
    background-color: transparent;
    background-image: none;
  }
  & :global(.CalendarDay__selected_start .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeController_selectionHeight);
    color: var(--white);
    border: 1px solid var(--green-900);
    background-color: var(--green-900);
  }

  & :global(.CalendarDay__selected_start .renderedDay.startDateBackground) {
    background-color: var(--olive-300);
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected_end) {
    background-color: transparent;
  }
  & :global(.CalendarDay__selected_end .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeController_selectionHeight);
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }
  & :global(.CalendarDay:hover.CalendarDay__selected_start .renderedDay),
  & :global(.CalendarDay:hover.CalendarDay__selected_span .renderedDay),
  & :global(.CalendarDay:hover.CalendarDay__selected_end .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeController_selectionHeight);
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  & :global(.CalendarDay__hovered_offset) {
    background: var(--gray-200);
    color: inherit;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_calendar .renderedDay),
  & :global(.CalendarDay__blocked_out_of_range .renderedDay),
  & :global(.CalendarDay__blocked_calendar:hover .renderedDay),
  & :global(.CalendarDay__blocked_out_of_range:hover .renderedDay) {
    background-color: transparent;
    color: var(--matterColorAnti);
    text-decoration: line-through;
    border: 0;
  }
}

.inputRootResponsive {
  /* Make react-dates full with and fluid
     Mostly from https://github.com/airbnb/react-dates/issues/108#issuecomment-422333866
     with a couple of additions to remove the left/right empty space */
  & :global(.DayPicker),
  & :global(.DayPicker > div),
  & :global(.DayPicker > div > div),
  & :global(.DayPicker_transitionContainer),
  & :global(.CalendarMonth_table) {
    width: 100% !important;
  }

  & :global(.CalendarMonthGrid) {
    width: 1000% !important;
  }

  & :global(.CalendarMonthGrid_month__horizontal) {
    width: calc(10%) !important;
  }

  & :global(.DayPicker_weekHeader) {
    width: calc(100%) !important;
    top: 44px;
  }

  & :global(.CalendarDay),
  & :global(.DayPicker_weekHeader_li) {
    width: calc(100% / 7) !important;
  }

  & :global(.CalendarMonthGrid__horizontal) {
    left: 0;
  }

  & :global(.DayPicker_weekHeaders__horizontal) {
    margin-left: 0;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 0 16px;
    top: 0;

    &:first-of-type {
      left: 0;
    }

    &:last-of-type {
      right: 0;
    }
  }

  & :global(.CalendarMonth_caption) {
    line-height: inherit;
    padding-top: 0px;
    padding-bottom: 48px;
  }
}

.arrowIcon {
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);
}

.clearDatesLink {
  display: block;
  width: 100%;
  text-align: center;
  text-decoration: underline;
  color: var(--black);
  font-size: var(--text-sm);
}

.tooltipRoot {
  padding-top: var(--s-1);
  padding-bottom: var(--s-1);
  font-size: var(--text-sm);
}
