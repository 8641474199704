@import '../../landtrust.css';

.fieldHeader {
  @apply --typography-heading-size-2xl;
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);
}

.subheader {
  display: none;

  @media (--screen-md) {
    display: block;
    font-size: var(--text-sm);
    margin-bottom: var(--s-6);
    margin-top: 0;
  }
}

.photoLibraryContainer {
  width: 100%;
  margin: 0;
  border-radius: 0;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--s-4);
  background-color: var(--white);
  border-bottom: none;
  flex-grow: 0;
  min-height: auto;
  min-width: var(--w-3-4);
  overflow: hidden;

  @media (--screen-md) {
    padding: var(--s-6);
  }
}

.photoPreviews {
  margin-top: var(--s-8);
}
