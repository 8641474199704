@import '../../landtrust.css';

.formFieldHeading {
  font-size: var(--text-base);
  margin: 0 0 0 0;
  color: var(--var-900);
  font-weight: var(--font-bold);

  padding-top: var(--s-1);
  padding-bottom: var(--s-1);

  @media (--screen-md) {
    margin: 0 0 0 0;
    padding-top: var(--s-1);
    padding-bottom: var(--s-1);
  }
}

.dateTooltip {
  padding: 0 var(--s-6) var(--s-6) var(--s-6);
}

.datesButton {
  font-family: var(--font-base);
  width: 100%;
  cursor: pointer;
  text-align: left;
  padding: var(--s-3);
  border-radius: var(--border-radius);
  border: 1px solid var(--gray-400);
  transition: all 0.2s ease-out;
  z-index: var(--z-50);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: var(--white);
}

.datesButtonLabel {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0;
  background-color: var(--white);
}

.iconContainer {
  display: flex;
  align-items: center;
}

.dateText {
  margin-left: var(--s-4);
}

.iconArrow {
  width: 15px;
  color: var(--gray-700);
}

.outsideHandler {
  display: inline;
}
