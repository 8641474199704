@import '../../landtrust.css';

.input {
}

.inputSuccess {
}

.inputError {
  border-color: var(--red-500);
}

.inputWithPostfix {
  display: flex;
  align-items: center;
}

.postfixLabel {
  font-weight: var(--font-bold);
  margin-left: var(--s-3);
}
