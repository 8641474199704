@import '../../marketplace.css';
@import '../../landtrust.css';

.root {
  max-width: 1100px;

  @media (--screen-md) {
    min-height: 640px;
  }
}

.twoColumnContainer {
  margin-top: var(--s-8);

  @media (--screen-md) {
    margin-top: 0;
    display: flex;
  }
}

.twoColumnContainer .firstColumn {
  width: var(--w-2-5);
  background-position: center center;
  background-size: cover;
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.twoColumnContainer .secondColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--s-4);

  @media (--screen-md) {
    width: var(--w-3-5);
    padding: 0;
  }
}

.innerContentContainer {
  @media (--screen-md) {
    max-width: var(--w-3-4);
  }
}

.IDModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--screen-md) {
    max-width: var(--w-3-4);
  }
}

.modalContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: var(--white);
  border-radius: var(--border-radius);
  border-bottom: none;
  flex-grow: 0;
  min-height: auto;
  height: auto;
  overflow: hidden;
  margin: 0 var(--s-6);
  align-self: center;
}

/* Title of the modal */
.modalTitle {
  @apply --typography-heading-base;
  @apply --typography-heading;
  @apply --typography-heading-size-4xl;
  font-weight: var(--font-bold);
}

/* Paragraph for the Modal */
.modalMessage {
}

/* Make the email pop */
.email {
}

.helperLink {
}

.helperText {
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.bottomWrapper {
  margin-top: var(--s-12);
}

.modalIcon {
}

.reminderModalLinkButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  padding: var(--s-3) var(--s-8);
  background-color: var(--hunter);
  color: var(--white);
  font-weight: var(--font-medium);
  border-radius: var(--border-radius-xxl);
  height: var(--s-12);
  text-align: center;

  &:hover {
    text-decoration: none;
  }
}

.reminderModalLinkButton > svg {
  width: 21px;
  height: 23px;
  margin-right: var(--s-2);
}

.stripeCTAWrapper {
  text-align: center;

  @media (--screen-md) {
    display: inline-block;
  }
}

.stripeLogoWrapper {
  margin-top: var(--s-6);

  @media (--screen-md) {
    display: inline-block;
  }
}

.stripeLogoWrapper > img {
  width: 130px;
  height: auto;
}

.ctaLink {
  color: var(--gray-900);
  text-decoration: underline;
  font-weight: var(--font-medium);
}
