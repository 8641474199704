@import '../../landtrust.css';

.filterButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: var(--gray-300);
  gap: 8px;
  padding: 8px 16px;
  flex-grow: 1;
  flex-shrink: 0;
}

.filterButton:hover {
  background-color: #f3f6f3;
  color: var(--gray-900);
}

.filterButton:focus {
  outline: 2px solid var(--gray-900);
}

.filterButtonSelected {
  background-color: var(--gray-900);
  border-color: var(--gray-900);
  color: var(--white);
}

.filterButtonSelected:hover {
  background-color: var(--gray-900);
  border-color: var(--gray-900);
  color: var(--white);
}

.filterButtonSelected:focus {
  outline: none;
}

.filterButtonOpen {
  border-color: var(--gray-900);
}

.filterDropdownWrapper {
  flex-grow: 1;
  flex-shrink: 0;
}

.filterDropdown {
  position: relative;
  display: flex;
}

.dropdown {
  position: absolute;
  width: 100%;
  top: calc(100% + 2px);
  right: 0;
  padding: var(--s-4);
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid var(--gray-300);
  background-color: var(--white);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: var(--s-2);
}

.iconArrow {
  width: 12px;
  color: var(--gray-900);
}

.iconArrowOpen {
  transform: rotate(180deg);
}

.typeOption {
  font-size: var(--text-sm);
  cursor: pointer;
  text-decoration: none;
}

.typeOption:hover {
  text-decoration: none;
}

.selectedType {
  font-weight: var(--font-bold);
}
