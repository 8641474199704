@import '../../landtrust.css';

.editor {
  font-family: var(--font-base);
  border: 2px solid var(--gray-400);
  border-radius: var(--border-radius);
  padding: 0 var(--s-4);
  min-height: var(--s-38);
  cursor: initial;
}

.linkPopup {
  width: 300px;

  & input {
    width: initial;
    max-height: auto;
    min-height: auto;
  }
}

.proTipTextArea {
  display: block;
}

.helpWrapper {
  display: flex;
  justify-content: flex-end;
}

.errorWrapper {
  flex-grow: 1;
}

.proTipWrapper {
  margin-top: var(--s-2);
  margin-bottom: var(--s-4);
}
