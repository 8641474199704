@import '../../landtrust.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label::after,
  &:focus + label::after {
    /* This is --olive-900 with alpha. Can't use opacity because of children */
    background: rgba(136, 158, 138, 0.5);
  }

  &:checked + label::after {
    /* This is --olive-900 with alpha. Can't use opacity because of children */
    background: rgba(136, 158, 138, 0.9);
  }

  &:checked + label .checkmark {
    opacity: 1;
  }
}

.label {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  cursor: pointer;
  border-radius: var(--border-radius-xl);
  overflow: hidden;

  /* Keep the aspect ratio as per design 162px x 152px */
  padding-bottom: calc((152% / 162) * 100);

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: var(--transition-base);
    background: transparent;
  }
}

.image {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text {
  position: absolute;
  z-index: 3;
  bottom: var(--s-4);
  width: 100%;
  text-align: center;
  font-weight: var(--font-medium);
  color: var(--white);
  font-size: var(--text-lg);

  @media (--screen-md) {
    font-size: var(--text-base);
  }
}

.checkmark {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  stroke: var(--white);
  stroke-width: 3px;
  transition: var(--transition-base);
  opacity: 0;
}
