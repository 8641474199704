@import '../../landtrust.css';

.toaster {
  margin-top: var(--s-10);
}

.toast {
  border-radius: var(--border-radius-5xl) !important;
}

.toast a {
  font-weight: var(--font-bold);
  color: var(--brand);
  text-decoration: underline;
}
