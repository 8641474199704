@import '../../landtrust.css';

.root {
}

.listWithHeading {
  margin-top: var(--s-5);
}

.heading {
  color: var(--gray-900);
  font-weight: var(--font-medium);
  margin: 0;

  @media (--screen-md) {
    font-size: var(--text-lg);
  }
}

.subHeading {
  color: var(--gray-900);
  margin: 0;

  @media (--screen-md) {
    font-size: var(--text-sm);
  }
}
