@import '../../landtrust.css';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 2 * var(--s-5));
  margin: var(--s-5);

  @media (--viewportMedium) {
    flex-direction: row;
    margin: var(--s-12) 0;
    width: 100%;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    width: calc(35% - var(--s-8));
    margin-right: var(--s-8);
  }
}

.separator {
  display: none;

  @media (--viewportMedium) {
    display: block;
    width: 1px;
    background-color: var(--gray-300);
  }
}

.mainContainer {
  @media (--viewportMedium) {
    width: calc(50% - var(--s-8));
    border-left: 1px solid var(--gray-300);
    padding-left: var(--s-8);
  }
}
