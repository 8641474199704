@import '../../landtrust.css';

.root {
  margin-top: var(--s-2);
  color: var(--gray-900);

  @media (--screen-md) {
    margin: var(--s-4) 0;
  }
}

.header {
  display: flex;
  align-items: center;
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-2);
  color: var(--gray-900);
}

.disclaimer {
  font-size: var(--text-xs);
  margin-bottom: var(--s-3);
}

.totalDivider {
  width: 100%;
  height: 1px;
  margin: var(--s-3) 0 var(--s-3) 0;

  border: none;
  background-color: var(--gray-300);

  @media (--viewportMedium) {
    margin: var(--s-2) 0 var(--s-1) 0;
  }
}

.valueUnderline {
  text-decoration: underline;
}
