@import '../../marketplace.css';
@import '../../landtrust.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --ContactDetailsForm_infoText: {
    @apply --marketplaceH4FontStyles;
    color: var(--successColor);

    display: inline-block;
    padding-left: 18px;
    background-repeat: no-repeat;
    background-position: top 7px left;

    margin-top: 11px;
    margin-bottom: 0;

    @media (--screen-md) {
      margin-top: 14px;
      margin-bottom: 1px;
    }
  }
}

.root {
}

.contactDetailsSection {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 6px;

  @media (--screen-md) {
    padding-top: 0;
  }
}

.name {
  @apply --fieldBase;

  display: flex;
  justify-content: space-between;
}

.firstNameRoot {
  flex-basis: 50%;
  margin-right: var(--s-2);
}

.lastNameRoot {
  flex-basis: 50%;
  margin-left: var(--s-2);
}

.phone {
  margin-top: 14px;
}

.secondaryPhone {
  margin-top: 14px;
}

.email {
  margin-top: 14px;
}

.rolesSection, .smsConsentSection {
  margin-top: var(--s-7);
  font-weight: var(--font-medium);
}

.rolesSportsman {
  margin-bottom: var(--s-4);
}

.fieldLabel {
  font-size: var(--text-lg);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);
}

.inputGroup {
  position: relative;
}

.passwordSection {
  margin-top: var(--s-6);
  position: relative;
  margin-bottom: 16px;
}

.newPasswordSection {
  position: relative;
}

.passwordIcon {
  position: absolute;
  top: 42px;
  right: var(--s-3);
  width: var(--s-5);
  height: var(--s-5);
  color: var(--gray-900);
  &:hover {
    cursor: pointer;
  }
}
.passwordIconError {
  position: absolute;
  top: 42px;
  right: var(--s-3);
  width: var(--s-5);
  height: var(--s-5);
  color: var(--gray-900);
  &:hover {
    cursor: pointer;
  }
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--s-4);
  padding: var(--s-3);
  display: none;
  background-color: var(--gray-100);
  border-radius: var(--border-radius-xl);
}

.confirmChangesSectionVisible {
  display: block;
}

.confirmChangesTitle {
  color: var(--gray-900);
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  margin-top: 0;
  margin-bottom: var(--s-3);

  @media (--screen-md) {
    margin-top: 0;
    margin-bottom: var(--s-4);
    padding-top: var(--s-1);
    padding-bottom: var(--s-1);
  }
}

.confirmChangesInfo {
  margin-top: 0;
  margin-bottom: var(--s-9);

  @media (--screen-md) {
    margin-bottom: var(--s-12);
  }
}

.emailVerified {
  display: block;
  font-size: var(--text-sm);
  margin-top: var(--s-2);
  color: var(--green-500);
  font-weight: var(--font-medium);
  font-size: var(--text-sm);
}

.emailUnverified {
  @apply --ContactDetailsForm_infoText;
  color: var(--matterColorAnti);
  padding-left: 0;
  font-size: var(--text-sm);
}

.pendingEmailUnverified {
  @apply --ContactDetailsForm_infoText;
  padding-left: 0;
  color: var(--failColor);
  width: 100%;
}

.passwordChangedSuccess {
  display: block;
  font-size: var(--text-sm);
  margin-top: var(--s-2);
  color: var(--green-500);
  font-weight: var(--font-medium);
}

.checkInbox {
  color: var(--matterColor);
}

.emailStyle {
  font-weight: var(--fontWeightBold);
  word-wrap: break-word;
}

.helperLink {
  color: var(--matterColor);
  border-bottom: solid 1px var(--matterColor);
  line-height: 20px;
  margin: 0;
  cursor: pointer;

  @media (--screen-md) {
    margin: 0;
  }
}

.tooMany {
  color: var(--matterColorAnti);
}

.emailSent {
  color: var(--matterColor);
}

.bottomWrapper {
  margin-top: 46px;

  @media (--screen-md) {
    margin-top: 96px;
  }
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 0;
}

.referralSection {
  margin-top: var(--s-7);
}

.referralOther {
  margin-top: var(--s-7);
}
