@import '../../landtrust.css';

.root {
  position: relative;
}

.inputRoot {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked + label {
    background-color: var(--gray-700);
    color: var(--white);
    border-color: var(--gray-700);

    &:hover,
    &:focus {
      border-color: var(--gray-700);
    }
  }

  &:hover + label,
  &:focus + label {
    border-color: var(--gray-600);
  }
}

.inputLabel {
  min-height: 35px;
  padding: 0 var(--s-4);
  border: 1px solid var(--gray-400);
  border-radius: 18px;
  background-color: var(--white);
  font-weight: normal;
  font-size: var(--text-sm);
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
