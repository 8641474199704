@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SearchPage_containerHeight: calc(100vh - var(--topbarHeightDesktop));
}

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  position: sticky;
  top: 0;
  left: 0;
  /* We need to raise Topbar above .container */
  z-index: var(--z-10);
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    min-height: var(--SearchPage_containerHeight);
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: var(--s-8) 0 0 0;

  @media (--viewportMedium) {
    flex-basis: 50%;
  }
}

.searchResultContainerHidden {
  display: none;
}

.error {
  color: var(--failColor);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFilters,
.searchResultSummary,
.noSearchResults {
  padding: 0 var(--s-5) 0 var(--s-5);

  @media (--viewportLarge) {
    padding: 0 var(--s-6) 0 var(--s-12);
  }
}

.searchResultSummary {
  margin-top: var(--s-4);

  @media (--viewportLarge) {
    margin-top: var(--s-6);
  }
}

.noSearchResults {
  margin-top: var(--s-4);
}

.noSearchResultsNoFilters {
  @media (--viewportLarge) {
    margin-top: var(--s-16);
  }
}

.loadingResults,
.resultsFound {
  line-height: 20px;
  color: var(--gray-900);
  font-size: var(--text-base);
  font-weight: bold;

  @media (--viewportMedium) {
    font-weight: var(--font-medium);
  }
}

.resultsFound {
  white-space: nowrap;
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: var(--s-6);

  @media (--viewportMedium) {
    margin-top: var(--s-4);
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: var(--SearchPage_containerHeight);

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
  }
}
