@import '../../marketplace.css';
@import '../../landtrust.css';

.header {
  padding: var(--s-8) 0;
}

.headerCenter {
  text-align: center;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.link {
  position: relative;
  color: var(--font-color);
  font-size: var(--text-lg);
  font-weight: var(--font-medium);
  margin-bottom: var(--s-4);
}

.linkSecondary {
  color: var(--gray-500);
}

.avatarContainer {
  padding: var(--s-4) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: var(--s-20);
  height: var(--s-20);
  margin-bottom: var(--s-5);
}

.avatarDisplayName {
  font-weight: var(--font-medium);
  margin-bottom: var(--s-2);
}

.currentPage {
  color: var(--gray-600);
}

.newFlag {
  margin: var(--s-9);
  padding: var(--s-1) var(--s-3);
  border-radius: var(--border-radius-xl);
  border: solid 1px var(--green-900);
  background-color: var(--green-400);
  font-size: 10px;
}

.activityDot {
  top: -4px;
  right: -18px;
}
