@import '../../../landtrust.css';

.root {
  position: relative;
}

.messageError {
  color: var(--red-500);
  margin: var(--s-4) 0;
}
