@import '../../../landtrust.css';

.panelContainer {
  margin-bottom: var(--s-9);
}

.speciesCheckBoxGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--s-3);
}
