@import '../../landtrust.css';

.root {
}

.title {
  margin-top: var(--s-3);
  margin-bottom: var(--s-3);
  font-weight: var(--font-bold);
}

.popularList {
  display: grid;
  grid-gap: var(--s-5) var(--s-3);
  grid-template-columns: repeat(2, minmax(0, 50%));
  grid-auto-rows: minmax(min-content, max-content);

  @media (--screen-md) {
    grid-gap: var(--s-10) var(--s-8);
    grid-template-columns: repeat(4, minmax(0, 25%));
  }
}

.popularListItem {
}

.otherSpeciesColumns {
  margin-top: var(--s-6);
  display: grid;
  grid-gap: var(--s-6) var(--s-10);
  grid-template-columns: repeat(1, minmax(0, 100%));
  grid-auto-rows: minmax(min-content, max-content);

  @media (--screen-md) {
    grid-gap: var(--s-6) var(--s-10);
    grid-template-columns: repeat(4, minmax(0, 25%));
  }
}

.otherSpeciesColumn {
}

.otherSpeciesItem {
  margin-bottom: var(--s-6);

  &:last-child {
    margin-bottom: 0;
  }
}

.otherSpeciesItemActive {
  margin-bottom: var(--s-5);

  &:last-child {
    margin-bottom: 0;
  }
}

.fieldCheckboxLabel {
  margin-bottom: 0;
}

.showMore {
  cursor: pointer;
  @apply --typography-base;
  font-weight: var(--font-medium);
  margin-top: var(--s-12);
  color: var(--brand);
  display: flex;
  align-items: baseline;
}

.arrowIcon {
  stroke: var(--brand);
  margin-left: var(--s-5);
  transition: var(--transition-base);

  & > path {
    /* Translate because the icons are annoyingly not centered in
       their viewbox which makes our rotation look bad */
    transform: translateY(1px);
  }
}

.arrowIconDown {
  /* Rotate instead of changing icon so we can animate it */
  transform: rotateZ(180deg);
}

.priceInput {
  margin-top: var(--s-2);
}
