@import '../../landtrust.css';

.root {
  position: relative;
  border-bottom: 1px solid var(--gray-300);
}

.list {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0px;
  overflow: auto;
}

.tab {
  font-family: inherit;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 var(--s-5);
  border: none;
  background: none;
  outline: none;
  color: #424d57;
  cursor: pointer;
  transition: color 0.25s;
  margin: 0 0px var(--s-4) 0px;
  overflow: visible;
  white-space: nowrap;

  &:first-child {
    padding: 0 var(--s-5);

    @media (--screen-md) {
      padding: 0 var(--s-2) 0 0;
    }
  }

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: block;
    content: '';
    background-color: var(--gray-900);
    height: 0;
  }
}

.tab__vertical {
  &:first-child {
    padding: 0 var(--s-5);
  }
}

.tab__selected {
  font-weight: var(--font-bold);

  &:before {
    height: 3px;
    bottom: calc(-1 * var(--s-4));
  }
}

.tab__selected__vertical {
  font-weight: var(--font-bold);

  &:before {
    height: 100%;
    width: 3px;
    left: 3px;
  }
}
