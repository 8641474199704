@import '../../landtrust.css';

.title {
  @media (--screen-md) {
    text-align: center;
    margin-bottom: var(--s-6);
  }
}

.carousel {
  padding: var(--s-4) 0;
  margin: 0 calc(-1 * var(--s-2));
}

.step {
  display: flex;
  padding: var(--s-2);
  flex-basis: 90%;

  @media (--screen-md) {
    flex-basis: 100%;
  }
}
