@import '../../landtrust.css';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.categoriesList {
  display: flex;
  flex-wrap: wrap;
}

.categoriesListItem {
  width: 50%;
}

.uploadImageLabel {
  cursor: pointer;
  display: flex;
  margin-top: 20px;
}

.uploadAvatarInput {
  display: none;
}

.avatarContainer {
  position: relative;
}

.pencilIconContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 6px;
  width: var(--s-9);
  height: var(--s-9);
  background-color: var(--brand);
  color: var(--white);
  border: var(--s-1) solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pencilIcon {
  width: var(--s-8);
}

.uploadingImage {
  width: var(--s-24);
  height: var(--s-24);
  object-fit: cover;

  border-radius: 50%;
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;
}

.uploadingImageOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--gray-900);
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}
