@import '../../landtrust.css';

.root {
}

.title {
  display: none;
}

@media (--screen-lg) {
  .root {
    padding: var(--s-20) var(--s-8);
  }

  .title {
    margin-bottom: var(--s-20);
    text-align: center;
    display: block;
  }

  .cardContainer {
    margin: 0 auto;
    width: 900px;
  }
}
