@import '../../landtrust.css';

.radioWrapper {
  display: grid;
  grid-gap: var(--s-2);
  grid-template-columns: repeat(2, minmax(0, 100%));
  grid-auto-rows: minmax(min-content, max-content);

  @media (--screen-md) {
    grid-template-columns: repeat(4, minmax(0, 25%));
  }
}

.lessMoreBtn {
  margin-bottom: var(--s-4);
}
