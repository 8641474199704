@import '../../landtrust.css';

.root {
  display: block;
  color: var(--font-color);
  transition: var(--transition-base);
  border-radius: var(--border-radius);

  &:hover {
    text-decoration: none;
    transform: scale(1.02);
  }
}

.rootResponsive {
  composes: root;

  @media (--screen-lg) {
    display: flex;
    align-items: center;
    background-color: var(--white);
  }
}

.imageWrapper {
  display: block;
  width: 100%;
  position: relative;
}

.imageInner {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
}

.imageRoot {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--border-radius);
}

.imageWrapperResponsive {
  composes: imageWrapper;

  @media (--screen-lg) {
    width: 42%;
    max-width: var(--s-76);
    flex-shrink: 0;
    align-self: stretch;
  }
}

.imageRootResponsive {
  composes: imageRoot;

  @media (--screen-lg) {
    position: static;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  margin-top: var(--s-3);
}

.infoContainerResponsive {
  composes: infoContainer;

  @media (--screen-lg) {
    width: 58%;
    margin: var(--s-8) var(--s-6) var(--s-8) var(--s-6);
    flex-direction: column;
  }
}

.infoMain {
  display: flex;
  flex-direction: column;
  margin-right: var(--s-3);
}

.infoMainResponsive {
  composes: infoMain;
}

.infoHeader {
  font-size: var(--text-xs);
  color: var(--gray-600);
}

.infoHeaderResponsive {
  composes: infoHeader;

  @media (--screen-lg) {
    font-size: var(--text-sm);
  }
}

.infoTitle {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}

.infoTitleResponsive {
  composes: infoTitle;

  @media (--screen-lg) {
    font-size: var(--text-2xl);
  }
}

.priceInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
}

.priceInfoResponsive {
  composes: priceInfo;

  @media (--screen-lg) {
    align-items: center;
    flex-direction: row;
  }
}

.priceInfoLabel {
  margin-bottom: 0;
  font-size: var(--text-sm);
}

.priceInfoLabelResponsive {
  composes: priceInfoLabel;

  @media (--screen-lg) {
    font-size: var(--text-base);
  }
}

.priceAmount {
  color: var(--gray-900);
  font-size: var(--text-sm);

  & span:first-of-type {
    font-weight: var(--font-bold);
  }
}

.priceAmountResponsive {
  composes: priceAmount;

  @media (--screen-lg) {
    & span:first-of-type {
      font-size: var(--text-base);
      font-weight: var(--font-bold);
    }
  }
}

.infoFooter {
  font-size: var(--text-sm);
}

.infoFooterResponsive {
  composes: infoFooter;

  @media (--screen-lg) {
    font-size: var(--text-base);
  }
}

.favoriteWrapper {
  position: absolute;
  top: var(--s-3);
  right: var(--s-3);
  z-index: 1;
}

.panelHostMobile {
  display: contents;

  @media (--screen-lg) {
    display: none;
  }
}

.firstToBook {
  font-size: var(--text-sm);
  display: flex;
  align-items: center;
}

.clockIcon {
  color: var(--green-900);
  margin-right: var(--s-2);
  height: var(--s-4);
  width: var(--s-4);
}

.ratingWrapper {
  margin-top: var(--s-1);
  margin-bottom: var(--s-2);

  @media (--screen-lg) {
    margin-top: var(--s-3);
  }
}

.reviewRating {
  display: inline-flex;
  white-space: nowrap;
}

.reviewRatingStar {
  height: var(--s-4);
  width: var(--s-4);
  margin-right: var(--s-1);

  &:last-of-type {
    margin-right: 0;
  }
}

.badgeWrapper {
  display: flex;
  background-color: var(--gray-100);
  padding: var(--s-2) var(--s-4);
  border-radius: var(--border-radius);
  border: solid 1px var(--gray-200);
}

.icon {
  height: var(--s-4);
  width: var(--s-4);
  margin-right: var(--s-2);
}

.infoSpan {
  display: block;
  font-size: var(--text-xs);
  font-weight: var(--font-bold);
}
