@import '../../marketplace.css';

.title {
  @apply --marketplaceH1FontStyles;

  margin-top: 0;
  max-width: 735px;

  @media (--viewportMedium) {
    margin-bottom: 23px;
  }
}

.columns {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 57px;
  }
}

.column {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.column:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--listingCardBorderRadius);
  object-fit: cover;
}

.linkText {
  @apply --marketplaceH2FontStyles;
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.descText {
  @apply --marketplaceBodyFontStyles;
}

.faqButton {
  @apply --ltButtonStyle;
  @apply --marketplaceBodyFontStyles;
  border-color: var(--ltForegroundColor);
  width: 120px;
}

.signupButton {
  @apply --ltCallToActionButtonStyle;
  @apply --marketplaceBodyFontStyles;
  width: 120px;
}
