@import '../../marketplace.css';
@import '../../landtrust.css';

.root {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--s-8);

  @media (--screen-md) {
    width: 100%;
    max-width: var(--s-320);
    flex-direction: row;
    padding: 0 var(--s-5);
    margin: var(--s-14) auto var(--s-7) auto;
  }

  @media (--screen-lg) {
    padding: 0;
  }
}

.txInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--s-12);
  padding: var(--s-5);

  @media (--screen-md) {
    flex-basis: 62%;
    padding-right: var(--s-26);
    margin-bottom: var(--s-29);
    padding-top: 0;
  }
}

.transactionListingHeadingMobile {
  display: block;

  @media (--screen-md) {
    display: none;
  }
}

.transactionListingHeadingDesktop {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 32px 24px 0 24px;
  }
  @media (--screen-lg) {
    margin: 32px 0 0 0;
  }
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  display: none;
  width: 100%;
  position: relative;

  @media (--screen-lg) {
    display: block;
    max-height: 268px;
    overflow-y: hidden;
  }
}

.imageHeading {
  display: none;

  @media (--screen-md) {
    display: block;
    position: relative;
    padding-bottom: var(--w-2-3);
    background-color: var(--gray-500);
    border-radius: var(--border-radius-xl);
    overflow: hidden;
  }
}

.aspectWrapper {
  padding-bottom: var(--w-1-3);
  background-color: var(--white);
  @media (--screen-sm) {
    padding-bottom: var(--w-1-5);
  }
  @media (--screen-lg) {
    display: none;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  @media (--screen-md) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -30px;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    margin-top: -32px;
    padding: 2px 0;
  }
  @media (--screen-lg) {
    margin-left: 48px;
    width: unset;
    padding: 2px 0;
  }
}
/* TODO:match the style with AirBNB */
/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  display: none;
  position: relative;

  @media (--screen-lg) {
    display: none;
  }
}

.avatarWrapperMobile {
  composes: avatarWrapper;

  @media (--screen-lg) {
    display: none;
  }
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  display: none;

  @media (--screen-lg) {
    display: block;
  }
}

.avatarWrapperProviderDesktop {
  display: none;

  @media (--screen-lg) {
    display: block;
    margin-top: 20px;
  }
}
.avatarDesktop {
  @media (--screen-lg) {
    display: flex;
  }
}

.profileFlexContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: var(--s-4);

  @media (--screen-md) {
    margin-bottom: var(--s-4);
  }
}

.avatar {
  width: var(--s-19);
  height: var(--s-19);
}

.guestNameStyles {
  font-family: var(--font-base);
  font-size: var(--text-sm);
  padding: 0;
  margin: 0;
  line-height: 1;
}

.profileInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: var(--s-4);
}

.profileName {
  font-size: var(--text-lg);
  font-weight: var(--font-bold);

  @media (--screen-md) {
    font-size: var(--text-xl);
  }
}

.profileLink {
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  text-decoration: underline;
}

/* PanelHeadings subcomponent */
.headingOrder {
  margin: 29px 24px 0 24px;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--screen-lg) {
    max-width: 100%;
    margin: 152px 0 0 0;
    padding: 0;
  }
}

.headingSale {
  margin: 18px 24px 0 24px;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--screen-lg) {
    max-width: 100%;
    margin: 42px 0 0 0;
    padding: 0;
  }
}

.mainTitle {
  display: block;
}

.transactionInfoMessage {
  margin: 18px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 23px 24px 0 24px;
  }
  @media (--screen-lg) {
    margin: 23px 0 0 0;
  }
}

/* Container for booking details in mobile layout */
.pricingDetailsMobile {
  margin-top: var(--s-3);

  @media (--screen-md) {
    display: none;
  }
}

.bookingDetailsMobile {
  display: block;
  margin-top: var(--s-1);

  @media (--screen-md) {
    display: none;
  }
}

.pricingDetailsDesktop {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.pricingPackagePanel {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.bookingDetailsDesktop {
  display: none;
  margin-top: var(--s-3);

  @media (--screen-md) {
    display: block;
  }
}

/* "aside" section in desktop layout */
.asideDesktopContainer {
  @media (--screen-md) {
    flex-basis: 38%;
  }
}

/* "aside" section in desktop layout */
.asideDesktop {
  @media (--screen-md) {
    padding: var(--s-5);
    border-radius: var(--border-radius-xxxl);
    border: 1px solid var(--gray-300);
    box-shadow: 0 40px 32px -24px rgba(15, 15, 15, 0.12);
  }
}

.mobileSection {
  display: none;
}

.detailCard {
  @media (--screen-lg) {
    z-index: 1;
  }
}

/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
  display: none;

  @media (--screen-lg) {
    display: block;
    margin: 0 var(--s-12) var(--s-3) var(--s-8);
  }
}

.detailCardTitle {
  margin-bottom: 10px;

  @media (--screen-lg) {
    margin-top: 14px;
    margin-bottom: 0;
    font-size: var(--s-5);
  }
}

.detailCardSubtitle {
  @apply --marketplaceH5FontStyles;

  margin-top: 0;
  margin-bottom: 0;

  @media (--screen-lg) {
    margin-top: 9px;
    margin-bottom: 0;
  }
}

/* AddressLinkMaybe subcomponent */

.addressProp {
  color: var(--gray-900);
  text-align: left;
  font-size: var(--text-base);
  font-weight: var(--font-bold);
}
.addressMobile {
  padding: 0 var(--s-5) var(--s-3) var(--s-5);

  @media (--screen-lg) {
    display: none;
  }
}

.address {
  color: var(--gray-900);
  text-decoration: underline;
  text-align: left;

  @media (--screen-lg) {
    text-align: left;
  }
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--gray-900);
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  margin: 0 var(--s-6) 0 var(--s-6);

  @media (--screen-lg) {
    font-size: var(--text-base);
    margin: 0 var(--s-12) var(--s-1) var(--s-8);
  }
}
.pricingExplained {
  color: var(--gray-900);
  margin: 0 var(--s-6) 0 var(--s-6);

  @media (--screen-lg) {
    font-size: var(--text-sm);
    margin: 0 var(--s-12) var(--s-1) var(--s-8);
    padding: var(--s-1) 0 var(--s-1) 0;
  }
}

.breakdownContainer {
  display: none;

  @media (--screen-lg) {
    display: block;
  }
}

.breakdown {
  margin: 14px 24px 0 24px;

  @media (--screen-lg) {
    margin: 0;
    padding: 6px 0 2px 0;
  }
}

.breakdownContainer {
  display: none;

  @media (--screen-lg) {
    display: block;
  }
}

.feed {
  margin-top: 20px;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--screen-lg) {
    margin: 12px 0 23px 0;
  }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  margin: 0;

  @media (--screen-md) {
    margin-bottom: var(--s-5);
  }
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  position: relative;
  margin: var(--s-12) 0 var(--s-4) 0;

  @media (--screen-md) {
    border-top: 0;
  }

  @media (--screen-lg) {
    margin: 47px 0 0 0;
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--screen-lg) {
    margin: 47px 0 0 0;
  }
}
.cancelButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.cancelButtons {
  width: 100%;
}

.cancelButton {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
}

.actionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;

  & button:first-child {
    margin: 0 var(--s-3) 0 0;
  }
}

.actionError {
  @apply --marketplaceH5FontStyles;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }
  @media (--screen-lg) {
    margin: 0;
  }
}

.actionErrors {
  width: 100%;
  text-align: center;

  @media (--screen-lg) {
    position: absolute;
    top: 151px;
  }
}

.cancelButtonIcon {
  width: 6px;
  color: var(--gray-900);
  margin-left: var(--s-2);
}

.declineButton {
  display: flex;
  justify-content: center;
  background: none;
}

.acceptButton {
  width: var(--w-1-2);

  @media (--screen-md) {
    width: var(--w-2-5);
  }
}

/* BookingPanel subcompnent */
.bookingPanel {
  margin: var(--s-4) 0;
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
}

.invalidPriceMessage {
  color: var(--failColor);

  margin: 16px 24px 0 24px;

  @media (--screen-lg) {
    margin: 0 48px 32px 48px;
    padding: 5px 0 0 0;
  }
}

.listingTitleFeed {
  display: none;
  @apply --typography-heading-size-3xl;
  font-weight: var(--font-bold);
  margin-bottom: var(--s-5);

  @media (--screen-md) {
    display: block;
  }
}

.listingTitleLink {
  color: var(--gray-900);
  font-size: var(--text-xl);
}

.landownerName {
  height: var(--s-6);
  font-size: var(--text-base);
  color: var(--gray-900);
  margin-bottom: var(--s-2);
  margin-left: var(--s-5);
  margin-right: var(--s-5);

  @media (--screen-lg) {
    display: none;
  }
}

.landownerDesk {
  display: none;
  @media (--screen-lg) {
    display: block;
  }
}

.paymentForm {
  display: flex;
  flex-grow: 1;
  margin: 0;
}

.cancelledMessage {
  @apply --typography-heading-size-2xl;
  color: var(--brand);
  text-align: center;
  font-weight: var(--font-medium);
}

.partyFormDiv {
  text-align: center;
}

.currentTransactionTransition {
  text-align: center;
}

.bookingStatus {
  margin: auto;
  width: 100%;
  padding: 0 var(--s-4) 0 0;
  border-radius: var(--s-3);

  @media (--screen-md) {
    margin: 0;
    padding: 0 var(--s-4) var(--s-2) 0;
  }
}
.partyMembers,
.partyMembersComplete {
  margin: 0 auto var(--s-8) auto;
  width: 100%;
  padding: var(--s-4) var(--s-4) var(--s-6) var(--s-5);
  border-radius: var(--s-3);
  background-color: var(--gray-100);

  @media (--screen-md) {
    margin: 0 0 var(--s-5) 0;
  }
}
.partyMembers {
  background-color: var(--gray-100);
  background-size: contain;
  background-position: inherit;
  background-repeat: repeat-x;
}

.iconWrapper {
  display: flex;
  justify-content: center;
}

.verificationHeader {
  font-weight: bold;
}

.inviteSent {
  text-align: center;
}

.offer {
  @media (--screen-lg) {
    font-size: 14px;
    margin-top: var(--s-6);
  }
}

.comm {
  color: var(--green-500);
}

.packagesContainer {
  @media (--screen-lg) {
    display: none;
  }
}

.submitClassName {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--s-4);
  background-color: var(--white);
  box-shadow: var(--box-shadow-xl);
  display: flex;
  flex-direction: column;
  z-index: var(--z-60);

  & > button {
    margin-top: 0;
  }

  & > p {
    margin-top: var(--s-1);
  }

  @media (--screen-md) {
    position: relative;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
}

.potentialEarnings {
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  text-align: center;

  @media (--screen-md) {
    margin-top: var(--s-3);
    text-align: right;
  }
}

.desktopBreadcrumbs {
  display: none;
  @media (--screen-md) {
    display: block;
  }
}

.mobileBreadcrumbs {
  margin-bottom: var(--s-7);

  @media (--screen-md) {
    display: none;
  }
}

.modalContainer {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  min-height: var(--w-2-3);
  width: 100%;
  overflow: hidden;
  border-radius: var(--border-radius-xxxl) var(--border-radius-xxxl) 0 0;

  @media (--screen-md) {
    right: 0;
    min-height: 100%;
    width: var(--w-3-5);
    border-radius: var(--border-radius-xxxl) 0 0 var(--border-radius-xxxl);
  }
}

.innerIDModal {
  display: flex;
  justify-content: center;
}

.mobileCheckAvailability {
  z-index: 60;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: var(--s-5);
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 10%);
  display: flex;

  @media (--screen-md) {
    display: none;
  }
}

.mobileCheckAvailabilityPrice {
  font-size: var(--text-sm);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.bookButton {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
}

.pricingPanel {
  display: none;

  @media (--screen-md) {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 0 var(--s-10) 0;
    background-color: var(--white);
    min-width: var(--s-80);
    height: fit-content;
    flex-basis: var(--w-2-5);
    flex-shrink: 1;
    flex-grow: 1;
  }
}

.divider,
.dividerTop {
  height: 1px;
  background-color: var(--gray-300);
  margin: var(--s-5) 0;
}

.dividerTop {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.listingTitle {
  font-family: var(--font-base);
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-2);

  @media (--screen-md) {
    display: none;
  }
}

.isInquiryInfo {
  margin: var(--s-2) 0 var(--s-4) 0;

  @media (--screen-md) {
    display: none;
  }
}

.notifyMobileContainer {
  z-index: 60;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: var(--s-5);
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;

  @media (--screen-md) {
    display: none;
  }
}

.notifyButton {
  background-color: var(--gray-900);
  color: var(--white);
  margin-bottom: var(--s-2);
}

.icon {
  width: var(--s-4);
  height: var(--s-5);
}

.getNotified {
  color: var(--gray-900);
  font-size: var(--text-sm);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-wrap: nowrap;
}

.availabilityBadgesWrapper {
  margin: var(--s-4) 0 var(--s-1) 0;
  padding: var(--s-3);

  @media (--screen-md) {
    display: none;
  }
}

.linkIconsWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--s-5);
}
