@import '../../landtrust.css';

.root {
  position: relative;
}

.circleFav {
  cursor: pointer;
  width: var(--s-9);
  height: var(--s-9);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  color: initial;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: var(--s-4);
}

.iconFull {
  composes: icon;
  fill: var(--red-900);

  & > path {
    fill: var(--red-900);
  }
}

.favButton {
  text-decoration: underline;

  @media (--screen-md) {
    margin-left: var(--s-7);
  }

  & .icon {
    width: var(--s-4);

    @media (--screen-md) {
      height: var(--s-4);
      width: initial;
    }
  }
}

.favButtonIconFull {
  composes: icon;
  fill: var(--white);

  & > path {
    fill: var(--white);
  }
}

.buttonBody {
  display: flex;
  align-items: center;

  & > svg {
    @media (--screen-md) {
      margin-right: var(--s-2);
    }
  }
}

.buttonLabel {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}
