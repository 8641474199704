@import '../../landtrust.css';
@import '../FlexHelper/FlexHelper.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
  @apply --flexWrapper;
}

.list {
  display: flex;
  flex-wrap: wrap;
  @apply --flexInner3;
}

.item {
  @apply --flexItem3;
}
