@import '../../landtrust.css';

.root {
  display: flex;
  width: 100%;
  margin: 0 auto;

  @media (--screen-md) {
    margin-top: var(--s-9);
  }
}

.formContainer {
  display: none;

  @media (--screen-md) {
    flex-basis: 62%;
    display: block;
  }
}

.pricingDetailsContainer {
  padding-bottom: 0;

  @media (--screen-md) {
    flex-basis: 38%;
    padding: var(--s-5);
    padding-bottom: var(--s-5);
    border-radius: var(--border-radius-xxxl);
    border: 1px solid var(--gray-300);
    box-shadow: 0 40px 32px -24px rgba(15, 15, 15, 0.12);
  }
}

.pricingDetailsMobileHeader {
  display: block;
  margin-bottom: var(--s-4);

  @media (--screen-md) {
    display: none;
  }
}

.formContainerHeader {
  display: none;

  @media (--screen-md) {
    display: block;
    max-width: var(--w-3-4);
  }
}

.headerContainer {
  display: flex;
  flex-direction: column;
}

.headerText {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-1);

  @media (--screen-md) {
    font-size: var(--text-3xl);
  }
}

.divider {
  height: 1px;
  width: 90%;
  background-color: var(--gray-300);
  margin: var(--s-2) 0;
}

.bookingDetails {
  @media (--screen-md) {
    max-width: var(--w-2-3);
    margin-top: var(--s-4);
  }
}

.bookingDetailsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--s-4);
}

.bookingDetailsHeader {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
}

.bookingDetailsMobile {
  display: block;

  @media (--screen-md) {
    display: none;
  }
}

.formFieldHelper {
  margin-top: var(--s-1);
  font-size: var(--text-sm);
  color: var(--gray-600);
}

.formFieldValue {
  font-size: var(--text-sm);
}

.formFieldWrapper {
  margin-bottom: var(--s-4);
}

.formFieldButtonWrapper {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  gap: var(--s-4);
  margin-top: var(--s-7);

  @media (--screen-md) {
    margin-top: var(--s-8);
    padding: 0;
  }
}

.acceptButton {
  flex-basis: var(--w-2-3);
}

.pricingDetails {
  display: flex;
  flex-direction: column;
  gap: var(--s-2);
}

.pricingDetailsHeader {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
}

.pricingDetailsItem {
  display: flex;
  justify-content: space-between;
}

.dateInfo {
  width: 100%;
  text-align: center;
}

.priceDetailsDesktop {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.priceDetailsMobile {
  display: block;

  @media (--screen-md) {
    display: none;
  }
}

.errorMessage {
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  color: var(--red-900);
  border-radius: var(--border-radius);
  border: 1px solid var(--red-900);
  padding: var(--s-3);
  margin-bottom: var(--s-7);
  margin-top: var(--s-7);
}
