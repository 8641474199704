@import '../../landtrust.css';

.sectionContainer {
  position: relative;
  padding: 0 var(--s-6) 0 var(--s-6);
  border-radius: var(--border-radius-xl);
  margin-top: 0;
  margin-bottom: var(--s-10);

  @media (--screen-lg) {
    margin-bottom: var(--s-10);
    padding: var(--s-12) var(--s-15) var(--s-12) 0;
  }
}

.breakoutContainer {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;

  @media (--screen-lg) {
    border-radius: var(--border-radius-xl);
    left: -50vw;
  }
}

.content {
  position: relative;
  padding-left: 0;
}

.visitHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0;
}

.descriptionHeader {
  position: relative;
  z-index: 2;
  font-family: var(--font-base);
  font-size: var(--text-2xl);
  font-weight: bold;
  color: var(--gray-900);
  display: flex;
  margin: 0;
}

.subHeading {
  position: relative;
  font-family: var(--font-base);
  z-index: 2;
  font-size: var(--text-base);
  color: var(--gray-900);
  display: flex;
  margin-bottom: var(--s-3);
  margin-left: 0;
}

.subHeadingEmptyState {
  position: relative;
  font-family: var(--font-base);
  font-weight: bold;
  z-index: 2;
  font-size: var(--text-base);
  color: var(--gray-900);
  display: flex;
  margin-bottom: var(--s-2);
  margin-top: var(--s-1);
  margin-left: 0;
}

.subtitleEmptyState {
  position: relative;
  font-family: var(--font-base);
  z-index: 2;
  font-size: var(--text-base);
  color: var(--gray-900);
  display: flex;
  margin-bottom: var(--s-5);
  margin-left: 0;
}

.displayName {
  white-space: nowrap;
}

.bellWrapper {
  position: absolute;
  bottom: 0;
  left: var(--s-9);
  transform: translate(-28%, 0%);
  color: var(--white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bellStyles {
  width: var(--s-5);
}

.showMoreButton {
  font-family: var(--font-base);
  font-size: var(--text-base);
  color: var(--gray-900);
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  z-index: 2;
  margin-left: 0;
}

.followButtonWrapper {
  position: relative;
  z-index: 2;
  margin: 0;
}

.sectionDivider {
  position: relative;
  height: 1px;
  border: none;
  background-color: var(--gray-300);
  z-index: 1;
}