@import '../../landtrust.css';

.keyListingInfoContainer {
  @apply --typography-base;

  @media (--screen-md) {
    display: flex;
    margin-left: 0;
  }
}

.keyInfoFirstRow {
  display: flex;
  margin-right: var(--s-5);
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (--screen-md) {
  }
}

.keyInfoItem {
  display: flex;
  color: var(--gray-900);
  margin: 0 var(--s-4) var(--s-1) 0;
  font-size: var(--text-sm);

  @media (--screen-md) {
    font-size: var(--text-base);
    display: flex;
    justify-content: flex-start;
  }
}

.keyInfoIcon {
  color: var(--gray-900);
  margin-right: var(--s-1);
}

.iconWrapper {
  flex-grow: 0;
  flex-shrink: 0;
}

.keyInfoTextContainer {
  flex-grow: 1;
  padding-left: 0;
  padding-top: 2px;
}

.descriptorTitle {
  font-weight: var(--font-medium);
  display: inline;
}

.nearestTown {
  color: var(--black);
  margin-left: var(--s-2);
}
