@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 10px;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}
.feeError {
  @apply --marketplaceH5FontStyles;
  color: var(--failColor);
  margin: 5 5 5 5;
  text-align: center;
}

.timeSlotsError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  text-align: center;
  margin: auto 24px 0px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 0px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 0px;
  }
}
.smallPrintDark {
  font-size: var(--text-xs);
  text-align: center;
  margin: 0 var(--s-3);
  flex-shrink: 0;
  font-weight: bolder;
  @media (--viewportMedium) {
    margin: 0;
  }
}
.smallPrintAsterisk {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}
.smallPrintDates {
  @apply --marketplaceTinyFontStyles;
  color: var(--gray-700);
  margin: auto;
  flex-shrink: 0;
  margin-bottom: 1em;

  @media (--viewportMedium) {
    margin-top: auto;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.submitButton {
  width: 100%;
  font-size: var(--text-base);
  margin-bottom: var(--s-1);
}

.cleaningFee {
  margin: 0 24px 38px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin: 0 0 38px 0;
  }
}
.perDay {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  margin-top: 0;
  padding: 0 var(--s-6);

  @media (--viewportMedium) {
    padding: 0;
  }
}

.partySize {
  margin-bottom: 38px;
  display: block;
  flex-direction: row;
  padding: 0 var(--s-6);

  @media (--viewportMedium) {
    margin-bottom: 24px;
    padding: 0;
  }
}
.partySizeLabel {
  text-align: center;
}

.bookingHelp {
  padding: 0 var(--s-6);
  color: var(--gray-900);
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: bold;
  font-size: var(--text-xl);

  @media (--viewportMedium) {
    color: var(--gray-900);
    display: block;
    margin-top: 0.5em;
    margin-left: 0;
    font-size: var(--text-xl);
    padding: 0;
  }
}

.cleaningFeeAmount {
  @apply --marketplaceListingAttributeFontStyles;
  font-weight: 600;
  margin: 0 0 0 10px;

  @media (--viewportMedium) {
    font-weight: 700;
    margin: 0 0 0 10px;
  }
}

.missingInformationModal {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;
}

.nowrap {
  font-size: 14px;
  text-align: center;
  margin-bottom: 15px;
}

.calendarHelpText {
  white-space: nowrap;
  color: #fd3713;
  font-size: var(--text-base);
  text-align: center;
  margin-bottom: var(--s-4);
  font-weight: bold;
}

.calendarProTip {
  display: flex;
  border-radius: var(--border-radius-xl);
  padding: var(--s-4);
  background-color: var(--gray-200);
  border: 1px var(--gray-300) solid;
  margin: 0 var(--s-6);

  @media (--screen-md) {
    margin: 0;
    justify-content: space-between;
  }
}

.contentContainer {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  width: var(--s-10);
  height: var(--s-10);

  @media (--screen-md) {
    width: var(--s-8);
    height: var(--s-8);
    flex-shrink: 0;
  }
}

.estimateDisclaimer {
  font-size: var(--text-xs);
}

.requestContainer {
  display: flex;
  align-items: center;
}

.requestInfo {
  display: flex;
  border-radius: var(--border-radius-xl);
  padding: var(--s-4);
  background-color: var(--gray-200);
  margin: auto var(--s-6) var(--s-10) var(--s-6);

  @media (--screen-md) {
    margin: var(--s-6) 0;
    justify-content: space-between;
  }
}
