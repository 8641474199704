@import '../../../landtrust.css';

.titleStylesMobile {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: var(--text-xl);
  font-weight: bold;
  min-width: 100%;

  @media (--screen-md) {
    display: none;
  }
}

.titleStylesDesktop {
  display: none;

  @media (--screen-md) {
    display: block;
    font-weight: 800;
    font-size: var(--text-2xl);
  }
}

.stepStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--text-base);

  @media (--screen-md) {
    display: none;
  }
}
