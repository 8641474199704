@import '../../marketplace.css';
@import '../../landtrust.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  flex: 1;
  background-color: var(--white);
  align-items: center;
  justify-content: center;
  padding: var(--s-7) var(--s-5) 0 var(--s-5);
  min-height: 100vh;

  @media (--screen-md) {
    padding: 0;
    min-height: auto;
  }
}

.modalHeading {
  margin-top: var(--s-9);
  margin-bottom: var(--s-1);

  @media (--screen-md) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --typography-heading-size-3xl;
  font-family: var(--font-base);
  color: var(--gray-700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: var(--s-1);
  }
}

.author {
  width: 100%;
  font-size: var(--text-sm);
  margin-top: var(--s-2);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--gray-700);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.bookingTitle {
  /* Font */
  color: var(--gray-700);

  margin-top: 0;
  margin-bottom: var(--s-2);
}

.bookingHelp {
  text-size: var(--text-xs);
  color: var(--gray-700);

  @media (--viewportMedium) {
    color: var(--gray-700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0 var(--s-20) 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: var(--s-96);
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 0 var(--s-6) var(--s-6) var(--s-6);
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--white);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: var(--z-60);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: var(--s-4) var(--s-4) 0 var(--s-4);
  background-color: var(--white);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: var(--s-4) var(--s-16) var(--s-4) var(--s-16);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: var(--s-6);
  padding: var(--s-1) var(--s-3);
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--gray-700);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--gray-700);
  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookButton {
  width: 100%;
  border-radius: var(--border-radius-xxxl);
  margin-bottom: 1em;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.sportsmanInfo {
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  color: var(--gray-900);

  @media (--screen-md) {
    display: block;
  }
}

.sportsmanSubInfo {
  font-size: var(--text-sm);
  color: var(--gray-900);
  margin: var(--s-2) auto var(--s-4) auto;

  @media (--screen-md) {
    display: block;
  }
}

.orderError {
  color: var(--brand);
}
