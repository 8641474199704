@import '../../landtrust.css';

.conservationContainer {
  margin-top: var(--s-10);
  border-radius: var(--border-radius-xl);
  padding: var(--s-11) var(--s-6) var(--s-6) var(--s-5);
  font-size: var(--text-sm);
  position: relative;
}

.conservationIconBase {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: var(--s-16);
  height: var(--s-16);
  border: solid 3px white;
  border-radius: 50%;
  background-color: var(--gray-200);
}

.conservationIconPackageWrapper {
  position: absolute;
  top: calc(-1 * var(--s-6));
  left: 4px;
  right: 0;
  display: flex;
  justify-content: center;
}

.conservationIconContainer {
  composes: conservationIconBase;
  top: calc(-1 * var(--s-6));
}
.conservationFarmersIconContainer {
  composes: conservationIconBase;
  top: calc(-1 * var(--s-6));
}

.conservationIconPackageFirst {
  composes: conservationIconBase;
  position: relative;
  left: 4px;
  z-index: 4;
}

.conservationIconPackageSecond {
  composes: conservationIconBase;
  position: relative;
  right: 2px;
  z-index: 3;
}

.conservationIconPackageThird {
  composes: conservationIconBase;
  position: relative;
  right: 8px;
  z-index: 2;
}

.conservationIconPackageFourth {
  composes: conservationIconBase;
  position: relative;
  right: 14px;
  z-index: 1;
}

.conservationIconImage {
  max-width: var(--s-10);
  max-height: var(--s-11);
}

.conservationFarmersIconImage {
  max-width: var(--s-16);
  max-height: var(--s-16);
}

.conservationTitle {
  font-weight: var(--font-bold);
  margin-bottom: var(--s-2);
}
