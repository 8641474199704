@import '../../landtrust.css';

.topbar {
  width: 100%;
  height: var(--s-18);
  background-color: var(--white);
  box-shadow: var(--box-shadow-xl);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--screen-md) {
    justify-content: left;
    padding: 0 var(--s-17);
  }
}

.logoMobile {
  display: block;

  @media (--screen-md) {
    display: none;
  }
}

.logoDesktop {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--s-18);
  padding: var(--s-5);

  @media (--screen-md) {
    width: 100%;
    max-width: var(--s-320);
    flex-direction: row;
    margin: var(--s-11) auto var(--s-7) auto;
  }
}

.aspectWrapper {
  position: relative;
  padding-bottom: var(--w-2-3);
  background-color: var(--gray-500);

  @media (--screen-md) {
    display: none;
  }
}

.detailsAspectWrapper {
  display: none;

  @media (--screen-md) {
    display: block;
    position: relative;
    padding-bottom: var(--w-2-3);
    background-color: var(--gray-500);
    border-radius: var(--border-radius-xl);
    overflow: hidden;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  @media (--screen-md) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  margin-left: var(--s-6);
  margin-top: calc(-1 * var(--s-8));
  position: relative;
  display: block;

  @media (--screen-md) {
    margin-left: var(--s-3);
  }
}

.avatarMobile {
  @media (--screen-md) {
    display: none;
  }
}

.avatarDesktop {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.detailsWrapper {
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--screen-md) {
    flex-basis: 62%;
    padding: var(--s-5) var(--s-32) var(--s-5) var(--s-5);
  }
}

.title {
  font-family: var(--font-base);
  width: 100%;
  margin-top: 0;
  margin-bottom: var(--s-2);
  color: var(--black);
  @media (--viewportMedium) {
    margin-top: var(--s-7);
    margin-bottom: var(--s-1);
  }
}

.detailsHeadings {
  display: none;

  @media (--screen-md) {
    display: block;
    font-weight: var(--font-bold);
    font-size: var(--text-2xl);
    margin-bottom: var(--s-5);
  }
}

.detailsTitle {
  display: none;

  @media (--screen-md) {
    display: block;
    font-size: var(--text-2xl);
    font-weight: var(--font-bold);
    margin-top: var(--s-4);
    margin-bottom: var(--s-1);
  }
}

.detailsLandownerName {
  font-size: var(--text-lg);
  font-weight: normal;
}

.breakdownWrapper {
  padding: var(--s-5);

  @media (--screen-md) {
    padding-left: var(--s-8);
    border-left: 1px solid var(--gray-300);
    flex-basis: 45%;
  }
}

.breakdown {
  @media (--screen-md) {
    max-width: var(--s-93);
  }
}

.formWrapper {
  margin-top: var(--s-3);
}

.formFieldWrapper {
  margin-bottom: var(--s-3);

  @media (--screen-md) {
    margin-bottom: var(--s-8);
  }
}

.formFieldHeading {
  font-size: var(--text-base);
  margin: 0 0 0 0;
  color: var(--var-900);
  font-weight: var(--font-bold);

  padding-top: var(--s-1);
  padding-bottom: var(--s-1);

  @media (--screen-md) {
    font-size: var(--text-lg);
    margin: 0 0 0 0;
    padding-top: var(--s-1);
    padding-bottom: var(--s-1);
  }
}

.formFieldSubtitle {
  margin-top: 0;
  margin-bottom: var(--s-2);
  font-size: var(--text-sm);
}

.datesButton {
  font-family: var(--font-base);
  width: 100%;
  cursor: pointer;
  text-align: left;
  padding: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--gray-400);
  transition: all 0.2s ease-out;
  z-index: var(--z-50);
  position: relative;
  cursor: pointer;
}

.datesButtonLabel {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: var(--s-4) var(--s-4) var(--s-4) var(--s-4);
}

.dateText {
  margin-left: var(--s-4);
}

.iconArrow {
  width: 16px;
  height: 14px;
  transition: all 0.2s ease-out;
}

.partySizeSelect {
  border: 2px solid var(--gray-400);
  padding: var(--s-4) var(--s-6) var(--s-4) var(--s-4);
}

.arrowIcon {
  /* custom styling to match native select arrow */
  width: var(--s-4);
  height: 17px;
  color: #4a4a4a;
}

.speciesCheckboxWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--s-2);
}

.speciesCheckbox {
}

.speciesFeeCheckboxLabel {
  margin-bottom: 0;
}

.error {
  color: var(--red-500);
}

.packageInfo {
  margin-top: var(--s-5);

  @media (--screen-md) {
    margin-top: 0;
  }

  & a {
    text-decoration: underline;
    color: var(--black);
  }
}

.packageInfoHeading {
  font-weight: var(--font-bold);
  font-size: var(--text-lg);

  @media (--screen-md) {
    font-size: var(--text-2xl);
  }
}

.submitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--s-4);
  background-color: var(--white);
  box-shadow: var(--box-shadow-xl);
  z-index: var(--z-60);

  @media (--screen-md) {
    position: relative;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
}

.dateInfo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateInfoTitle {
  color: var(--gray-900);
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-1);
}

.dateInfoBody {
  margin: 0;
  color: var(--gray-900);
  font-size: var(--text-xs);
}

.dateTooltip {
  padding: 0 var(--s-6) var(--s-6) var(--s-6);
}

.packagePartySize {
  font-size: var(--text-base);
  margin: 0;
  color: var(--var-900);
  font-weight: var(--font-bold);

  padding-top: var(--s-1);
  padding-bottom: var(--s-1);

  @media (--screen-md) {
    margin: 0;
    padding-top: var(--s-1);
    padding-bottom: var(--s-1);
  }
}

.lodgingContainer {
  font-size: var(--text-base);
  margin: 0;
  color: var(--var-900);
  font-weight: var(--font-bold);

  padding-top: var(--s-1);
  padding-bottom: var(--s-1);

  @media (--screen-md) {
    margin: 0;
    padding-top: var(--s-1);
    padding-bottom: var(--s-1);
  }
}

.lodging {
  font-family: var(--font-base);
  font-weight: var(--font-medium);
  font-size: var(--text-base);
  margin-top: var(--s-1);
}

.minPartyDisclaimer {
  font-size: var(--text-sm);
  margin-bottom: var(--s-4);
  @media (--screen-md) {
    font-size: var(--text-xs);
  }
}

.hasLodging {
  color: var(--gray-900);
  font-size: var(--text-lg);
  line-height: var(--line-height-tight);
  padding-top: var(--s-2);
}

.fieldPartySize {
  margin-bottom: var(--s-5);
  @media (--screen-md) {
    width: var(--w-1-2);
  }
}

.infoWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: var(--s-5) var(--s-4) var(--s-5) 0;

  @media (--screen-md) {
    margin: var(--s-5) var(--s-4) var(--s-5) 0;
  }
}

.desktopBreadcrumbs {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.mobileBreadcrumbs {
  @media (--screen-md) {
    display: none;
  }
}

.availableDates {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  font-size: var(--text-sm);
  margin-bottom: var(--s-1);

  @media (--screen-md) {
    margin-top: var(--s-2);
    margin-bottom: 0;
  }
}

.verificationIcon {
  margin-right: var(--s-3);
  min-width: var(--s-8);
}

.packageBadges {
  margin-bottom: var(--s-2);
}

.divider {
  height: 1px;
  background-color: var(--gray-300);
  margin: var(--s-4) 0;
  @media (--screen-md) {
    display: none;
  }
}

.packageIncludes {
  margin-top: var(--s-3);
  margin-bottom: var(--s-1);
}

.packageIncludesHeading {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
}
