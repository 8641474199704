@import '../../landtrust.css';

.root {
  position: relative;
  border-radius: var(--border-radius-xl);
  overflow: hidden;
  transition: var(--transition-base);

  &:hover {
    cursor: pointer;
    text-decoration: none;
    transform: scale(1.02);
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.imageContainer {
  padding-top: calc(148 / 145 * 100%);
  overflow: hidden;
  display: block;
  box-sizing: border-box;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.title {
  font-family: var(--font-base);
  text-align: center;
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  padding: var(--s-2);
  z-index: var(--z-10);
}
