@import '../../landtrust.css';

.root {
  color: var(--red-900);
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  color: var(--red-900);
  border-radius: var(--border-radius);
  border: 1px solid var(--red-900);
  padding: var(--s-3);
  margin-bottom: var(--s-7);
}
