@import '../../landtrust.css';

.actionButtonWrapper {
  display: flex;
  align-items: center;
  gap: var(--s-6);
  margin-top: var(--s-1);
  margin-bottom: var(--s-3);

  @media (max-width: 359px) {
    gap: var(--s-3);
  }
}

.truncatedActionButtonWrapper {
  display: flex;
  align-items: center;
  gap: var(--s-1);
  margin-top: var(--s-3);
  margin-bottom: 0;

  @media (--screen-md) {
    gap: var(--s-5);
  }
}

.actionStyles {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--text-xs);
  color: var(--gray-900);
  cursor: pointer;

  @media (max-width: 359px) {
    font-size: var(--text-xxs);
  }
}

.tooltipButtonStyles {
  display: flex;
  align-items: center;
  margin-right: var(--s-1);
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.iconStyles {
  width: 100%;
  height: 100%;
}

.toastStyles {
  position: relative;
  z-index: 100;
}
