@import '../../landtrust.css';

.root {
  position: relative;
}

.header {
  display: flex;
}

.titleContainer {
  width: var(--w-1-2);
  padding-top: var(--s-24);
  padding-right: var(--s-2);
}

.imageContainer {
  position: relative;
  width: var(--w-1-2);
}

.image {
  position: absolute;
  height: 100%;
  display: block;
  border-radius: var(--border-radius);
}

.content {
  margin-top: var(--s-5);
}

.content p {
  margin: 0;
}

.cta {
  margin-top: var(--s-5);
}

.testimonial {
  position: relative;
  width: 100%;
  margin-top: var(--s-16);
  padding: var(--s-8);
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-xl);
  font-size: var(--text-base);
  font-weight: var(--font-medium);
  color: var(--font-color);
  display: block;

  &:hover {
    text-decoration: none;
  }
}

.testimonialHeader {
  display: flex;
}

.testimonialHeaderContent {
  width: 100%;
  text-align: center;
}

.testimonialAvatar {
  position: absolute;
  top: calc(-1 * (var(--s-14) / 2));
  left: calc(50% - (var(--s-14) / 2));
  width: var(--s-14);
  height: var(--s-14);
  border-radius: 50%;
  object-fit: cover;
}

.testimonialAuthor {
  font-size: var(--text-base);
}

.testimonialListing {
  font-size: var(--text-xs);
  font-weight: normal;
}

.testimonialQuotes {
  margin-bottom: calc(-1 * var(--s-3));
  text-align: center;
  font-size: var(--text-6xl);
  font-family: var(--font-headings);
  line-height: var(--line-height-none);
  user-select: none;
}

@media (--screen-md) {
  .root {
    margin-bottom: var(--s-64);
  }

  .titleContainer {
    width: var(--w-2-5);
    padding-top: 5em;
    padding-right: 3em;
  }

  .imageContainer {
    position: absolute;
    top: 0;
    left: 40%;
    height: 100%;
  }

  .content {
    width: var(--w-2-5);
    padding-right: var(--s-8);
  }

  .cta {
    /* to force image to align with the container */
    margin-bottom: 226px;
  }

  .testimonial {
    position: absolute;
    bottom: calc(-1 * var(--s-48));
    left: calc(50% - (var(--s-72) / 2));
    padding-left: var(--s-14);
    max-width: var(--s-72);
    font-size: var(--text-xl);
    z-index: var(--z-10);
  }

  .testimonialHeaderContent {
    text-align: left;
  }

  .testimonialQuotes {
    text-align: left;
  }

  .testimonialAvatar {
    width: var(--s-18);
    height: var(--s-18);
    top: var(--s-6);
    left: calc(-1 * (var(--s-18) / 2));
  }

  .testimonialAuthor {
    font-size: var(--text-lg);
  }

  .testimonialListing {
    font-size: var(--text-sm);
    font-weight: normal;
  }
}
