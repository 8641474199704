@import '../../landtrust.css';

.mainContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 9;
  overflow-x: auto;
  position: relative;
}

.mainContainerMobile {
  padding-bottom: var(--s-23);
}

.mainContainerMobileConfirmPayment {
  padding-bottom: var(--s-40);
}

.listContainer {
  order: 1;
  width: 100%;
  overflow: hidden;
  z-index: 2;
}

.listContainerHidden {
  display: none;
}

.detailsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.3s ease-out;
  background: var(--white);
  z-index: 1;
}

.messagesContainer {
  padding-bottom: var(--s-40);
}

.messagesContainerHidden {
  opacity: 0;
  pointer-events: none;
}

.messagesContent {
  overflow: auto;
  flex-grow: 2;
}

.sendMessageContent {
  padding: 0 var(--s-6);
}

.mobileDetailsContainer {
  display: flex;
  margin-bottom: var(--s-7);
}

.actionContainer {
}

.actionContainerHidden {
  opacity: 0;
  pointer-events: none;
}

.mobileLinks {
  display: flex;
  gap: var(--s-5);
  margin: 0 var(--s-5);
  font-size: var(--text-sm);
  border-bottom: 1px solid var(--gray-300);

  @media (--screen-md) {
    display: none;
  }
}

.mobileLink {
  text-decoration: none;
  padding: var(--s-2) 0;

  &:hover {
    text-decoration: none;
  }
}

.mobileLinkActive {
  font-weight: var(--font-bold);
  border-bottom: 2px solid var(--gray-900);
}
