@import '../../landtrust.css';

.mainContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 9;
  overflow-x: auto;

  @media (--screen-md) {
    flex-shrink: 0;
    width: 100%;
    max-width: 1920px;
    height: calc(100vh - var(--s-18) - var(--s-21));
    flex-direction: row;
    padding: 0;
    margin: 0 auto var(--s-7) auto;
  }
}

.listContainer {
  order: 1;
  width: 100%;
  overflow: hidden;

  @media (--screen-md) {
    flex: 0 0 25%;
    overflow: auto;
    height: auto;
  }
}

.messagesContainer {
  order: 3;

  @media (--screen-md) {
    order: 2;

    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--gray-300);
    padding: 0;
  }
}

.messagesOnlyContainerLoader {
  padding: var(--s-4);
  background-color: var(--white);

  @media (--screen-md) {
    order: 2;

    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--gray-300);
    padding: 0;
  }
}

.messagesContent {
  overflow: auto;
  flex-grow: 2;
}

.sendMessageContent {
  padding: 0 var(--s-6);
}

.messagesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--s-2);
  border-bottom: 1px solid var(--gray-300);
  margin-bottom: var(--s-6);
  font-size: var(--text-lg);
  font-weight: var(--font-bold);
  padding: var(--s-8) 0 var(--s-2) var(--s-6);
}

.detailsContainer {
  display: flex;
  margin-bottom: var(--s-7);

  @media (--screen-md) {
    margin-bottom: 0;
  }
}

.actionContainer {
  order: 2;

  @media (--screen-md) {
    order: 3;

    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    border: 1px solid var(--gray-300);
    box-shadow: 0 20px 32px -24px rgba(15, 15, 15, 0.12);
    height: calc(100% - var(--s-7));
    overflow: auto;
    margin: var(--s-7) var(--s-6) 0 var(--s-6);
  }
}

.emptyStateContainer {
  order: 2;
  flex: 1 1 0%;
  border-left: 1px solid var(--gray-300);
}
