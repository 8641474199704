@import '../../landtrust.css';

.root {
  background-color: white;
  opacity: 0.8;
  border-radius: var(--border-radius);
  font-size: var(--text-xs);
  padding: var(--s-1);
  position: absolute;
  bottom: var(--s-2);
  left: var(--s-2);
  color: #2b313d;
  font-weight: var(--font-medium);
}
