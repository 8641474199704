@import '../../landtrust.css';

.root {
  font-family: var(--font-base);
  display: block;
  color: var(--font-color);
  transition: var(--transition-base);
  border-radius: var(--border-radius);
  width: var(--s-84);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    text-decoration: none;
  }
}

.rootResponsive {
  composes: root;

  @media (--screen-lg) {
    display: flex;
    align-items: center;
    background-color: var(--white);
  }
}

.imageWrapper {
  display: block;
  width: 100%;
  position: relative;
}

.imageInner {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
}

.imageRoot {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--border-radius);
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  background-color: var(--white);
  padding: var(--s-3) var(--s-4) 0 var(--s-4);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.infoMain {
  display: flex;
  flex-direction: column;
  margin-right: var(--s-3);
}

.infoHeader {
  font-size: var(--text-xs);
  color: var(--gray-600);
}

.infoTitle {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}

.infoTitleResponsive {
  composes: infoTitle;

  @media (--screen-lg) {
    font-size: var(--text-2xl);
  }
}

.priceInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
}

.priceInfoLabel {
  margin-bottom: 0;
  font-size: var(--text-sm);
}

.priceAmount {
  color: var(--gray-900);
  font-size: var(--text-sm);

  & span:first-of-type {
    font-weight: var(--font-bold);
  }
}

.infoFooter {
  font-size: var(--text-sm);
}

.favoriteWrapper {
  position: absolute;
  top: var(--s-3);
  right: var(--s-3);
  z-index: 1;
}

.panelHostMobile {
  display: contents;

  @media (--screen-lg) {
    display: none;
  }
}

.firstToBook {
  font-size: var(--text-sm);
  display: flex;
  align-items: center;
}

.clockIcon {
  color: var(--green-900);
  margin-right: var(--s-2);
  height: var(--s-4);
  width: var(--s-4);
}

.ratingWrapper {
  margin-top: var(--s-1);
  margin-bottom: var(--s-2);

  @media (--screen-lg) {
    margin-top: var(--s-3);
  }
}

.reviewRating {
  display: inline-flex;
  white-space: nowrap;
}

.reviewRatingStar {
  height: var(--s-4);
  width: var(--s-4);
  margin-right: var(--s-1);

  &:last-of-type {
    margin-right: 0;
  }
}
