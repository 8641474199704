@import '../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  margin: 16px 20px;

  @media (--screen-md) {
    margin-bottom: var(--s-20);
    display: block;
  }
}

.rootConfirmPayment {
  @media (--screen-md) {
    margin-bottom: var(--s-37);
  }
}

.divider {
  height: 1px;
  background-color: var(--gray-300);
  margin: var(--s-5) 0;
}

.bookingPanel {
  margin: var(--s-4) 0;
}

.bookingTitle {
  margin: 0;
}

.sendMessageForm {
  position: relative;
  margin: var(--s-12) 0 var(--s-4) 0;

  @media (--screen-md) {
    border-top: 0;
  }
}

.linkIconsWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--s-5);
}

.pricingPanel {
  display: none;

  @media (--screen-md) {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
    border: none;
    background-color: var(--white);
    min-width: var(--s-80);
    flex-basis: var(--w-2-5);
    flex-shrink: 1;
    flex-grow: 1;
  }
}

.bookingDetails {
  display: block;
}

.bookingDetailsDesktop {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.mobileCheckAvailability {
  z-index: 60;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: var(--s-5);
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 10%);
  display: flex;

  @media (--screen-md) {
    display: none;
  }
}

.mobileCheckAvailabilityPrice {
  font-size: var(--text-sm);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.isInquiryInfo {
  margin: var(--s-2) 0 var(--s-4) 0;

  @media (--screen-md) {
    display: none;
  }
}

.listingTitle {
  font-family: var(--font-base);
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-2);
}

.listingTitleFeed {
  display: none;
  @apply --typography-heading-size-3xl;
  font-weight: var(--font-bold);
  margin-bottom: var(--s-5);

  @media (--screen-md) {
    display: block;
  }
}

.listingTitleLink {
  color: var(--gray-900);
  font-size: var(--text-xl);
}

.profileFlexContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: var(--s-4);

  @media (--screen-md) {
    margin-bottom: var(--s-4);
  }
}

.avatar {
  width: var(--s-19);
  height: var(--s-19);
}

.guestNameStyles {
  font-family: var(--font-base);
  font-size: var(--text-sm);
  padding: 0;
  margin: 0;
  line-height: 1;
}

.profileInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: var(--s-4);
}

.profileName {
  font-size: var(--text-lg);
  font-weight: var(--font-bold);

  @media (--screen-md) {
    font-size: var(--text-xl);
  }
}

.profileLink {
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  text-decoration: underline;
}

.notifyMobileContainer {
  z-index: 60;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: var(--s-5);
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;

  @media (--screen-md) {
    display: none;
  }
}

.authorWrapper {
  display: flex;

  @media (--screen-md) {
    display: none;
  }
}

.avatar {
  width: var(--s-13);
  height: var(--s-13);
  margin-right: var(--s-2);
}

.authorNameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.authorLabel {
  font-size: var(--text-sm);
}

.authorName {
  display: flex;
  align-items: center;
  font-weight: var(--font-bold);
  gap: 12px;
}

.rating {
  display: flex;
  align-items: center;
  gap: 4px;
}
