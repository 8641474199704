@import '../../marketplace.css';
@import '../../landtrust.css';

.contentWrapper {
  margin-bottom: var(--s-10);
  margin-right: var(--s-2);
  margin-left: var(--s-2);
  margin-top: var(--s-40);
  width: 100%;
  height: 100%;
  position: relative;
  @media (--screen-md) {
    margin-top: 0;
    padding-bottom: var(--s-13);
    margin-bottom: 0;
  }
}

.headingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: var(--s-5);
}

.normalLottieAnimation {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 110%;
  height: 100%;

  @media (--screen-md) {
    top: 42%;
    width: 75%;
  }
}

.verifiedLottieAnimation {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 110%;
  height: 100%;

  @media (--screen-md) {
    top: 37%;
    width: 75%;
  }
}

.imageStyles {
  width: var(--s-23);
  margin-top: var(--s-10);

  @media (--screen-md) {
    margin-top: var(--s-14);
  }
}

.dividerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider {
  width: var(--s-31);
  height: 1px;
  background-color: var(--gray-300);
  border: none;
  margin-bottom: var(--s-4);
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--text-3xl);
  font-weight: var(--font-bolder);
  max-width: var(--s-112);
  margin-bottom: var(--s-2);
}

.subHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--text-base);
  max-width: var(--s-112);
  width: var(--s-76);

  @media (--screen-md) {
    max-width: var(--s-108);
    width: var(--s-108);
  }
}

.tipsHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--text-base);
  font-weight: bold;
  margin-bottom: var(--s-3);
}

.tipsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-bottom: var(--s-5);
}

.tipsTextOne {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--text-sm);
  width: var(--s-98);
  color: var(--gray-900);
  margin-bottom: var(--s-5);
}
