@import '../../marketplace.css';
@import '../../landtrust.css';

.sectionContainerStyle {
  overflow-x: visible;
  padding: var(--s-5);
  width: 100%;

  @media (--screen-md) {
    width: 77%;
  }
}

.headerContainerStyles {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--screen-md) {
    max-width: 60%;
  }
}

.headingStyles {
  width: 100%;
  font-weight: 800;
  text-align: center;
  font-size: var(--text-2xl);
  margin-bottom: var(--s-8);

  @media (--screen-md) {
    font-size: var(--text-4xl);
    margin-bottom: var(--s-12);
  }
}

.wrapperStyles {
  display: flex;
  flex-direction: column;
  grid-gap: var(--s-2);
  width: 100%;

  @media (--screen-md) {
    display: flex;
    flex-direction: row;
    grid-gap: var(--s-4);
  }
}

.quoteWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
