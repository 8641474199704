@import '../../landtrust.css';

.root {
  margin-top: var(--s-4);

  @media (--screen-md) {
    margin-top: var(--s-8);
  }
}

.reviewWrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 2 * var(--s-5));
  margin: var(--s-5);

  @media (--screen-md) {
    width: var(--s-160);
    margin: 0 auto var(--s-36) auto;
  }
}

.reviewTitle {
  @apply --typography-heading;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--gray-900);
  font-size: var(--text-lg);
  font-weight: var(--font-bold);
  text-align: center;
  margin-top: var(--s-4);
  margin-bottom: var(--s-4);

  @media (--screen-md) {
    font-size: var(--text-4xl);
    margin-top: var(--s-9);
  }
}

.expiredTitle {
  width: var(--s-40);

  @media (--screen-md) {
    width: 100%;
  }
}

.reviewee {
  white-space: nowrap;
}

.avatars {
  display: flex;
  justify-content: center;
}

.avatarRight {
  position: relative;
  right: var(--s-2);
  border: 3px solid var(--white);
  width: var(--s-19);
  height: var(--s-19);

  @media (--screen-md) {
    width: var(--s-25);
    height: var(--s-25);
  }
}

.avatarLeft {
  position: relative;
  left: var(--s-2);
  border: 3px solid var(--white);
  width: var(--s-19);
  height: var(--s-19);

  @media (--screen-md) {
    width: var(--s-25);
    height: var(--s-25);
  }
}

.listingTitle {
  font-size: var(--text-sm);
  margin-bottom: var(--s-6);

  @media (--screen-md) {
    margin-bottom: var(--s-4);
  }
}

.clockImage {
  margin-bottom: var(--s-4);
  height: var(--s-31);

  @media (--screen-md) {
    height: var(--s-44);
  }
}

.reviewCTA {
  display: flex;
  justify-content: center;
  margin: var(--s-8) 0;
}

.leftReviewContent {
  @media (--screen-md) {
    width: calc(100% - 2 * var(--s-5));
  }
}

.starRating {
  margin-bottom: var(--s-4);
  display: flex;
  justify-content: center;
}

.reviewContent {
  margin-bottom: var(--s-4);
  text-align: center;
}
