@import '../../landtrust.css';

.root {
  position: absolute;
  top: -8px;
  right: -8px;

  width: var(--s-5);
  height: var(--s-5);
  border-radius: 50%;

  background-color: var(--red-500);
  border: 2px solid var(--white);
  color: var(--white);

  font-size: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}
