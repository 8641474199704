@import '../../landtrust.css';

.root {
  margin: 0 var(--s-5);

  @media (--screen-md) {
    margin: 0;
  }
}

.rootWithMaxWidth {
  @media (--screen-lg) {
    max-width: 75%;
  }
}

.title {
  @apply --typography-heading-sans-serif;
  font-size: var(--text-base);
  font-weight: bold;
  margin-bottom: var(--s-3);
}

.hasBackground {
  background-color: var(--gray-100);
  padding: var(--s-3) var(--s-5);
}

.hasTopMargin {
  margin-top: var(--s-8);
}

.hasBottomMargin {
  margin-bottom: var(--s-8);
}
