@import '../../marketplace.css';
@import '../../landtrust.css';

.rootStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--yellow-500);
}

.rating {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--yellow-500);
}

.rating svg {
  margin-right: 0;
}

.rating svg:last-child {
  margin-right: var(--s-1);
}
