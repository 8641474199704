@import '../../landtrust.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .border,
  &:checked + label .border {
    fill: var(--brand);
  }

  /* successColor version */
  &:hover + label .borderSuccess,
  &:focus + label .borderSuccess,
  &:checked + label .borderSuccess {
    fill: var(--green-500);
  }

  /* Display the "check" when checked */
  &:checked + label .checkedBackground {
    display: inline;
  }

  /* Display the "check" when checked */
  &:checked + label .checkedBackgroundSuccess {
    display: inline;
  }
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
}

.checkboxWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-top: -1px;
  margin-right: var(--s-2);
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checkedBackground {
  display: none;
  fill: var(--brand);
}
.checkedBackgroundSuccess {
  display: none;
  fill: var(--green-500);
}

.borderSuccess,
.border {
  fill: var(--gray-300);
}

.text {
}

.textRoot {
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}
