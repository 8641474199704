@import '../../landtrust.css';

.button {
  color: var(--brand);
  font-weight: var(--font-bold);
  font-family: var(--font-base);
  display: flex;
  align-items: center;
  margin-bottom: var(--s-4);
}

.button > svg {
  width: var(--s-4);
  height: var(--s-4);
  margin-left: var(--s-2);
}
