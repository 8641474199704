@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

@import 'sanitize.css';
@import './landtrust.css';

 /* latin */
 @font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
    U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
    U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
    U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
    U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6FxZCJgg.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
    U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6FxZCJgg.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
    U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:global {
}

body {
  @apply --typography-base;
  font-size: 16px;
}

::selection,
::-moz-selection {
  background: var(--brand);
  color: var(--white);
}

*,
*:focus {
  outline: none;
}

a {
  color: var(--gray-900);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

h1 {
  font-family: var(--font-headings);
  font-size: var(--text-2xl);

  @media (--screen-md) {
    font-size: var(--text-4xl);
  }
}

h2 {
  font-size: var(--text-xl);
  font-weight: var(--font-medium);

  @media (--screen-md) {
    font-size: var(--text-3xl);
  }
}

h3 {
  font-size: var(--text-lg);
  font-weight: var(--font-medium);

  @media (--screen-md) {
    font-size: var(--text-2xl);
  }
}

h4 {
  font-size: var(--text-base);
  font-weight: var(--font-medium);

  @media (--screen-md) {
    font-size: var(--text-xl);
  }
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  border: 0;
  padding: 0;
  margin: 0;
}

legend,
label {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  font-family: var(--font-base);
  font-weight: var(--font-medium);
  color: var(--font-color);
  margin-bottom: var(--s-1);
}

input,
textarea {
  width: 100%;
  padding: var(--s-2) var(--s-4);
  min-height: 50px;
  background-color: var(--white);
  border: 2px var(--gray-400) solid;
  font-family: var(--font-base);
  font-size: var(--text-base);
  border-radius: var(--border-radius);
  color: var(--font-color);

  &:focus {
    border-color: var(--gray-900);
  }

  &::placeholder {
    color: var(--gray-500);
  }
}

select {
  display: block;
  width: 100%;
  margin: 0;
  padding: var(--s-2) var(--s-4);
  padding-right: var(--s-8);
  appearance: none;
  border: 2px var(--gray-400) solid;
  border-radius: var(--border-radius);
  font-family: var(--font-base);

  background-color: var(--white);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 16px 16px;
  background-position-y: center;
  background-position-x: calc(100% - 16px);
  cursor: pointer;

  &:focus {
    border-color: var(--gray-900);
  }
}
