@import '../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
  min-width: var(--s-64);
}

.header {
  font-weight: var(--font-medium);
  white-space: nowrap;
  margin-bottom: var(--s-4);
  display: flex;
  align-items: center;
  width: var(--w-3-4);
}

.icon {
  margin-right: var(--s-2);
}

.content {
  max-width: var(--s-76);
  color: var(--gray-900);
  overflow: hidden;

  & a {
    text-decoration: underline;
    color: var(--black);
  }

  & ul {
    list-style: disc;
    padding-left: var(--s-5);
  }
}
