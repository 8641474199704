@import '../../marketplace.css';
@import '../../landtrust.css';

.root {
  display: inline-block;
  user-select: none;
  margin-top: var(--s-2);
  margin-bottom: var(--s-2);
}

.light {
  color: var(--white);
}

.dark {
  color: var(--black);
}
