@import '../../landtrust.css';

.sectionContainer {
  margin-bottom: var(--s-6);
}

.fieldLabel {
  font-size: var(--text-lg);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);
}

.fieldLabelDescription {
  font-size: var(--text-sm);
  margin-bottom: var(--s-3);
}

.selectContainer {
  @media (--screen-md) {
  }
}

.selectField {
  margin-bottom: var(--s-12);
}

.buttonContainer {
  @media (--screen-md) {
    width: var(--w-3-4);
  }
}
