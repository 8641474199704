@import '../../landtrust.css';

.fieldContainer {
  @media (--screen-lg) {
    padding: 0 var(--s-3) 0 0;
  }
}
.indForm {
  position: relative;
  margin-top: var(--s-0);
  border-radius: var(--s-2);
  padding: var(--s-3) var(--s-3) var(--s-4) var(--s-3);
}

.buttonContainer {
  position: relative;
  margin-top: var(--s-0);
  border-radius: var(--s-2);
  padding: var(--s-3) var(--s-3) var(--s-4) var(--s-3);
  margin: 0 0 var(--s-2) 0;
  @media (--screen-lg) {
    margin: 0 0 0 var(--s-4);
  }
}

.shareButton {
  width: 100%;
}



.sportsmanWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(-1 * var(--s-4));
  font-size: 10px;
  text-align: center;
  margin: auto;
  width: var(--w-1-3);
  padding: var(--s-1) var(--s-4) var(--s-1) var(--s-2);
  border-radius: var(--s-3);
  background-color: var(--gray-900);
  color: var(--white);
  @media (--screen-lg) {
    width: var(--w-1-5);
  }
}

.fieldsContainer {
  @media (--screen-lg) {
    display: flex;
  }
}

.fieldsWrapper {
  margin-top: var(--s-3);
  @media (--screen-lg) {
    display: inline-block;
    margin: 0 0 0 var(--s-4);
    width: 50%;
  }
}

.requiredText {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}