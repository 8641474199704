@import '../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  color: var(--white);
  background-color: var(--bg-1);
  padding: var(--s-2);
}

.progressBarContainer {
  height: var(--s-2);
  width: 100%;
}

.progressBar {
  border-radius: 0;

  @media (--screen-md) {
    border-radius: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--s-1);
}

.counter {
  font-weight: var(--font-bold);
}

.notificationLabelDesktop {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.notificationLabelMobile {
  display: block;

  @media (--screen-md) {
    display: none;
  }
}
