@import '../../landtrust.css';

.root {
  position: relative;
  color: var(--gray-900);
}

.header {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);
}

.title {
  font-weight: var(--font-bold);
  margin-bottom: var(--s-2);
}

.description {
  margin-bottom: var(--s-5);

  @media (--screen-md) {
    margin-bottom: var(--s-7);
  }

  & > p {
    margin: 0;
  }
}

.bookingDatesIcon {
  width: var(--s-10);
  color: var(--gray-500);
}

.bookingDatesIconWrapper {
  width: var(--s-10);
  margin-right: var(--s-2);
}

.bookingDatesItemWrapper {
  display: flex;
  align-items: center;
  margin-bottom: var(--s-5);
}

.bookingDatesItem {
  display: flex;
  flex-direction: column;
}

.bookingDatesItemLabel {
  font-size: var(--text-sm);
}

.bookingDatesItemValue {
  font-weight: var(--font-bold);
}

.divider {
  height: 1px;
  background-color: var(--gray-300);
  margin: var(--s-5) 0;
}

.addressWrapper {
  margin-bottom: var(--s-5);
}

.address {
  margin: 0;
}

.waypoints {
  display: flex;
  flex-direction: column;
  gap: var(--s-3);
}

.waypointLink {
  text-decoration: underline;
  color: var(--gray-900);
}

.badgesContainer {
  margin-bottom: var(--s-3);
}

.packageTitleContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--s-3);
}

.itemTitle {
  padding-bottom: var(--s-3);
  cursor: pointer;
  text-decoration: underline;
  width: fit-content;

  &:hover {
    color: var(--gray-600);
  }
}
