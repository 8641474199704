@import '../../landtrust.css';

:root {
}

.root {
  position: relative;
  display: flex;
  flex-direction: column;

  @media (--screen-md) {
    flex-direction: row;
  }
}

.inputContainer {
  width: 100%;
  position: relative;
  display: flex;
  flex: 1;
  margin-bottom: var(--s-2);

  @media (--screen-md) {
    margin-bottom: 0;
  }
}

.marginlessInputContainer {
  margin-bottom: 0;
}

.inputGroup {
  position: relative;
  display: flex;
  flex: 1;
}

.inputError {
  border-color: var(--red-500);
}

.inputWithIconRight {
  padding-right: var(--s-9);
  border-radius: var(--border-radius-xxxl);
}

.inputWithIconLeft {
  padding-left: var(--s-9);
}

.searchIcon {
  position: absolute;
  top: calc(50% - (var(--s-5) / 2));
  right: var(--s-2);
  width: var(--s-5);
  height: var(--s-5);
  color: var(--white);
}

.searchIconLeft {
  composes: searchIcon;

  right: auto;
  left: var(--s-3);
  color: var(--gray-900);
}

.searchIconWrapper {
  position: absolute;
  top: calc(50% - (var(--s-10) / 2));
  right: var(--s-1);
  width: var(--s-10);
  height: var(--s-10);
  padding: var(--s-3);
  border-radius: var(--border-radius-xxxl);

  &:hover {
    cursor: default;
    background-color: var(--green-900);
  }
}

.predictionsRoot {
  position: absolute;
  width: calc(100% - var(--s-2));
  top: 50px;
  left: 0;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  z-index: var(--z-10);
}

.predictions {
  margin: 0;
  padding: var(--s-6) 0;

  & li {
    margin: 0;
    padding: var(--s-1) var(--s-8);
    border-left: 4px solid transparent;
    cursor: pointer;

    &:hover,
    &.highlighted {
      color: var(--brand);
      border-color: var(--brand);
    }
  }
}

li.currentLocation {
  &:hover,
  &.highlighted {
    border-color: transparent;
  }
}

li.notFound {
  color: var(--red-500);
  margin: var(--s-3) 0;
  font-weight: var(--font-bold);
  text-decoration: underline;
}

.currentLocationContainer {
  display: flex;
  align-items: center;
  padding: var(--s-2) 0;
  font-size: var(--text-lg);
}

.currentLocationIcon {
  margin-left: var(--s-2);
}

.disabled {
  pointer-events: none;
  opacity: var(--opacity-50);
}

.popularStates {
  pointer-events: none;
}

.searchButton {
  margin-top: var(--s-2);
  margin-left: var(--s-2);
  align-self: center;

  @media (--screen-md) {
    margin-top: 0;
  }
}
