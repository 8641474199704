@import '../../../landtrust.css';

.speciesCheckBoxGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sectionPackages {
  padding: 0;
  margin-bottom: var(--s-8);

  @media (--screen-md) {
    margin-bottom: var(--s-14);
  }
}

.titleStyles {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: var(--text-xl);
  font-weight: bold;
  width: 100%;
  margin-bottom: var(--s-3);
}

