@import '../../landtrust.css';

.root {
  display: block;
  position: relative;
  color: var(--font-color);
  transition: var(--transition-base);
  border-radius: var(--border-radius-xl);
  margin-bottom: var(--s-10);

  &:hover {
    text-decoration: none;
  }
}

.rootResponsive {
  composes: root;

  @media (--screen-md) {
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    background-color: var(--white);

    &:hover {
      box-shadow: var(--box-shadow-xl);
    }
  }
}

.linkRoot {
  &:hover {
    text-decoration: none;
  }
}

.imageInner {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  overflow: hidden;
  max-height: 200px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.16),
      rgba(0, 0, 0, 0.13),
      rgba(0, 0, 0, 0.09),
      rgba(0, 0, 0, 0.06),
      rgba(0, 0, 0, 0.04),
      rgba(0, 0, 0, 0.02),
      rgba(0, 0, 0, 0.01),
      rgba(0, 0, 0, 0)
    );
    border-radius: inherit;
    pointer-events: none;
    z-index: 1;
  }
}

.imageInnerBox {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;
}

.imageRoot {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-xl);
  object-fit: cover;
}

.imageWrapper {
  border-radius: var(--border-radius-xl);
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.imageWrapperResponsive {
  composes: imageWrapper;
  @media (--screen-md) {
    width: 41%;
    max-width: var(--s-76);
    max-height: 200px;
    flex-shrink: 0;
    align-self: stretch;
  }
}

.imageRootResponsive {
  composes: imageRoot;

  @media (--screen-md) {
    position: static;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  margin-top: var(--s-3);
  flex-wrap: wrap;
  position: relative;
}

.infoContainerResponsive {
  composes: infoContainer;

  @media (--screen-md) {
    flex-wrap: nowrap;
    width: calc(100% - var(--s-12));
    margin: var(--s-2) var(--s-4);
    flex-direction: column;
  }
}

.infoMain {
  display: flex;
  flex-direction: column;
}

.infoMainResponsive {
  composes: infoMain;

  @media (--screen-md) {
    margin-bottom: var(--s-2);
    flex-basis: auto;
  }
}

.infoHeader {
  font-size: var(--text-xs);
  color: var(--gray-600);
}

.infoHeaderResponsive {
  composes: infoHeader;

  @media (--screen-md) {
    font-size: var(--text-sm);
    margin-bottom: var(--s-3);
  }
}

.infoTitle {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}

.infoTitleResponsive {
  composes: infoTitle;

  @media (--screen-md) {
    font-size: var(--text-lg);
    margin-bottom: var(--s-1);
  }
}

.priceInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
  flex-basis: 30%;
}

.priceInfoResponsive {
  composes: priceInfo;

  @media (--screen-md) {
    flex-basis: auto;
    align-items: center;
    flex-direction: row;
    margin-bottom: var(--s-6);
  }
}

.priceInfoLabel {
  margin-bottom: 0;
  font-size: var(--text-sm);
}

.priceInfoLabelResponsive {
  composes: priceInfoLabel;

  @media (--screen-md) {
    font-size: var(--text-sm);
  }
}

.priceAmount {
  color: var(--gray-900);
  font-size: var(--text-sm);

  & span:first-of-type {
    font-weight: var(--font-bold);
  }
}

.priceAmountResponsive {
  composes: priceAmount;

  @media (--screen-md) {
    font-size: var(--text-sm);
  }
}

.infoFooter {
  font-size: var(--text-sm);
}

.infoFooterResponsive {
  composes: infoFooter;
  margin-bottom: var(--s-1);

  @media (--screen-md) {
    font-size: var(--text-sm);
  }
}

.badgesContainer {
  flex-basis: 100%;
  margin-top: var(--s-1);

  @media (--screen-md) {
    flex-basis: auto;
    margin-top: 0;
  }
}

.menuLink {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: var(--s-2);
  top: var(--s-2);
  width: var(--s-8);
  height: var(--s-8);
  color: var(--gray-900);
  background-color: var(--gray-300);
  border-radius: 50%;
  z-index: var(--z-10);

  & > svg {
    width: var(--s-6);
  }

  @media (--screen-md) {
    background-color: transparent;
  }
}

.menuIconWrapper {
  position: absolute;
  top: var(--s-1);
  right: var(--s-5);
  width: var(--s-6);
  z-index: var(--z-10);
}
.mainInfoLink {
  width: 100%;

  &:hover {
    text-decoration: none;
  }
}

.menuItemsWrapper {
  font-weight: var(--font-medium);

  & > li {
    margin-bottom: var(--s-1);
  }
}

.menuItemLink {
  text-decoration: none;
  color: var(--gray-900);
}

.packageLoadingWrapper {
  display: flex;
  flex-direction: column;
  max-height: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.fetchingStatus {
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  position: relative;
  bottom: var(--s-5);
}

.titleLink {
  text-decoration: underline;
}

.favoriteWrapper {
  position: absolute;
  top: var(--s-3);
  right: var(--s-3);
  z-index: 1;
}

.availablePackageWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.availablePackageBlurWrapper {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.availablePackageBlur {
  background: url('../../assets/rectangle.png');
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-xl);
  display: block;
}

.availablePackageText {
  @apply --typography-heading-size-2xl;
  width: 100%;
  transition: ease-in-out 0.1s;
  color: var(--white);
  display: flex;
  justify-content: center;
  z-index: var(--z-10);
  text-align: center;

  & > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.carouselBackIcon {
  transform: translateX(100%);
}

.carouselNextIcon {
  transform: translateX(-100%);
}

.earlyAccessBadge {
  color: var(--gray-900);
  background-color: var(--yellow-500);
  border-radius: var(--border-radius-xxl);
  font-size: var(--text-xxs);
  font-weight: var(--font-bold);
  padding: var(--s-1) var(--s-3);
  z-index: var(--z-15);
  position: absolute;
  right: var(--s-3);
  bottom: var(--s-3);
}
