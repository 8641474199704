@import '../../landtrust.css';

.heading {
  margin-top: var(--s-8);
  margin-bottom: 0;
}

.subHeading {
  margin-top: var(--s-2);
  margin-bottom: var(--s-8);
  color: var(--gray-900);
}
