@import '../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;

  @media (--screen-md) {
    overflow: hidden;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--s-3);
  padding: var(--s-8) var(--s-5) 0 var(--s-5);
  gap: var(--s-3);

  @media (--screen-md) {
    flex-wrap: wrap;
    padding: var(--s-8) var(--s-2) 0 var(--s-3);
  }
}

.header {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);

  @media (--screen-md) {
    width: 100%;
    font-size: var(--text-xl);
  }
}

.typeDropdownContainer {
  position: relative;
  flex-grow: 2;
}

.switch {
  display: flex;
  border-radius: var(--border-radius-xxl);
  background-color: var(--gray-300);
  padding: 3px;
  margin: 0 var(--s-5) var(--s-3) var(--s-5);

  @media (--screen-md) {
    margin: 0 var(--s-2) var(--s-3) var(--s-3);
  }
}

.switchItem {
  font-size: var(--text-xs);
  color: var(--gray-600);
  width: 50%;
  padding: var(--s-1) 0;
  text-align: center;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.switchItemSelected {
  color: var(--gray-900);
  font-weight: var(--font-bold);
  background-color: var(--white);
  border-radius: var(--border-radius-xxl);
}

.content {
  overflow: visible;
  height: 100%;
  padding: 0 var(--s-5) 0 var(--s-5);
  margin-bottom: 40px;
  min-height: 50vh;

  @media (--screen-md) {
    min-height: auto;
    margin-bottom: 0;
    overflow: auto;
    padding: 0 var(--s-2) 0 var(--s-3);
  }
}

.contentLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingMore {
  font-weight: var(--font-bold);
  font-size: var(--text-sm);
  text-align: center;
  padding: var(--s-3) 0;
}

.sentinel {
  height: 20px;
}

.filters {
  display: flex;
  gap: 8px;
  margin: 0 1.25rem 0.75rem 1.25rem;

  @media (--screen-md) {
    margin: 0 0.5rem 0.75rem 0.75rem;
  }
}

.filterDropdownWrapper {
  position: relative;
}

.filterDropdown {
  position: relative;
}

.noTransactions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  color: var(--gray-900);
  margin-top: 100px;
  gap: 16px;

  @media (--screen-md) {
    height: auto;
    justify-content: flex-start;
  }
}

.clearFiltersButton {
  margin-top: var(--s-2);
}

.clearFiltersButton:hover {
  background-color: #f3f6f3;
  color: var(--gray-900);
}

.loadingContainer {
  margin-top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (--screen-md) {
    margin-left: -1rem;
    margin-top: -1rem;
    width: calc(100% + 1rem);
  }
}
