@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: none;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  margin-top: var(--s-14);
}

.bottomWrapperText {
  text-align: center;
}

.termsText {
  font-size: var(--text-sm);
  color: var(--gray-900);
}

.termsLink {
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.fieldLabel {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);
}

.formSection {
  margin-bottom: var(--s-6);
}

.rolesSection {
  margin-top: var(--s-7);
  font-weight: var(--font-medium);
}

.rolesSportsman {
  margin-bottom: var(--s-2);
}

.referralSection {
  margin-top: var(--s-7);
}

.referralOther {
  margin-top: var(--s-7);
}

.signupWithIdpTitle {
  @apply --typography-heading-size-4xl;
  font-family: var(--font-base);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: var(--s-4);
  color: var(--gray-900);

  @media (--viewportMedium) {
    margin-top: 6px;
  }
}
