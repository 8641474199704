@import '../../landtrust.css';

.root {
  color: var(--gray-900);
  margin: 0 0 var(--s-5) 0;

  @media (--screen-md) {
    font-weight: var(--font-medium);
  }
}
