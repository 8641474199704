@import '../../landtrust.css';
@import '../Layout/LayoutHelper.css';

.root {
  @apply --pageContainer;
}

.fullWidthRoot {
  max-width: 100%;
}
