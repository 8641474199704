@import '../../landtrust.css';

.root {
  background-color: var(--olive-200);
  border-radius: calc(var(--s-2) / 2);
  height: var(--s-2);
  overflow: hidden;
}

.bar {
  background-color: var(--bg-2);
  height: 100%;
  transform: translateX(-100%);
  transition: var(--transition-base);
  border-top-right-radius: calc(var(--s-2) / 2);
  border-bottom-right-radius: calc(var(--s-2) / 2);
}
