@import '../../landtrust.css';

.breakdownWrapper {
  @media (--screen-md) {
    padding: var(--s-5) var(--s-5) var(--s-5) 0;
    flex-basis: 38%;
  }
}

.breakdown {
  position: relative;
  margin-bottom: var(--s-6);

  @media (--screen-md) {
    margin-bottom: 0;
    padding: var(--s-7) var(--s-5) var(--s-7) var(--s-6);
    border-radius: var(--border-radius-xxxl);
    box-shadow: var(--box-shadow);
    border: solid 1px var(--gray-300);
  }
}

.aspectWrapper {
  @media (--screen-md) {
    display: none;
  }
}

.detailsAspectWrapper {
  display: none;

  @media (--screen-md) {
    display: block;
    position: relative;
    padding-bottom: var(--w-2-3);
    background-color: var(--gray-500);
    border-radius: var(--border-radius-xl);
    overflow: hidden;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (--screen-md) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  margin-left: var(--s-6);
  margin-top: calc(-1 * var(--s-8));
  position: relative;
  display: block;

  @media (--screen-md) {
    margin-left: var(--s-3);
  }
}

.avatarMobile {
  @media (--screen-md) {
    display: none;
  }
}

.avatarDesktop {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.detailsHeadings {
  display: none;

  @media (--screen-md) {
    display: block;
    font-weight: var(--font-bold);
    font-size: var(--text-2xl);
    margin-bottom: var(--s-5);
  }
}

.detailsTitle {
  font-family: var(--font-base);
  display: block;
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  margin-top: var(--s-4);
  margin-bottom: var(--s-1);
}

.detailsLandownerName {
  font-size: var(--text-lg);
  font-weight: normal;
}

.imageWrapper {
  padding-bottom: calc(147 / 335 * 100%);
  position: relative;
  border-radius: var(--s-2);
  overflow: hidden;
  margin-bottom: var(--s-4);

  @media (--screen-md) {
    border-radius: var(--s-4);
    padding-bottom: calc(211 / 488 * 100%);
  }
}

.image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.authorWrapper {
  display: flex;
  margin: var(--s-4) 0 0 0;

  @media (--screen-md) {
    margin: 0 0 var(--s-2) 0;
  }
}

.avatar {
  width: var(--s-13);
  height: var(--s-13);
  margin-right: var(--s-2);
}

.authorNameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.authorLabel {
  font-size: var(--text-sm);
}

.authorName {
  display: flex;
  align-items: center;
  font-weight: var(--font-bold);
}

.starWrapper {
  display: flex;
  align-items: center;
  margin-left: var(--s-2);
}

.starIcon {
  height: var(--s-3);
  width: var(--s-3);
  margin-right: var(--s-1);
}

.listingTitleLink {
  color: var(--gray-900);
  font-size: var(--text-xl);
}

.titleInfoWrapper {
  display: none;

  @media (--screen-md) {
    display: block;
    margin-bottom: var(--s-5);
  }
}
