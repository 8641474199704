@import '../../../landtrust.css';

.panelContainer {
  margin-bottom: var(--s-3);
}

.maxPartyLabel {
  font-size: var(--text-sm);
  margin-bottom: var(--s-4);
}

.fieldContainer {
  margin-bottom: var(--s-5);

  @media (--screen-md) {
    max-width: var(--s-93);
  }
}

.partySizeSelect {
  width: 100%;
}

.selectedDays {
  text-align: center;
  margin-bottom: var(--s-8);
  text-decoration: none;
  font-weight: var(--font-medium);
}

.bookingDatesButton {
  font-family: var(--font-base);
  width: 100%;
  cursor: pointer;
  text-align: left;
  padding: 0;
  border-radius: var(--border-radius);
  border: 1px solid var(--gray-500);
  transition: all 0.2s ease-out;
  z-index: var(--z-50);
  position: relative;
}

.bookingDatesButtonLabel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: var(--s-4) var(--s-6) var(--s-4) var(--s-4);
}

.bookingDateText {
  margin-left: var(--s-4);
}

.iconArrow {
  stroke: var(--matterColor);
  fill: var(--matterColor);
  transition: all 0.2s ease-out;
}

.formGroupHeading {
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-6);
}

.formFieldHeading {
  font-size: var(--text-base);
  margin: 0 0 0 0;
  color: var(--gray-900);
  font-weight: var(--font-bold);

  padding-top: var(--s-1);
  padding-bottom: var(--s-1);

  @media (--screen-md) {
    font-size: var(--text-lg);
    margin: 0 0 0 0;
    padding-top: var(--s-1);
    padding-bottom: var(--s-1);
  }
}

.availableDates {
  display: flex;
  align-items: center;
  padding: var(--s-2) var(--s-4);
  background-color: var(--gray-100);
  border: 1px solid var(--gray-300);
  margin-top: var(--s-4);
  font-size: var(--text-sm);

  @media (--screen-md) {
    margin-top: var(--s-2);
    max-width: var(--s-142);
  }
}

.formFieldWrapper {
  margin-top: var(--s-5);
  margin-bottom: var(--s-5);

  @media (--screen-md) {
    max-width: var(--s-93);
  }
}

.packageLodgingAddOn {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-2);
  margin-top: var(--s-2);

  @media (--screen-md) {
    font-size: var(--text-lg);
    margin-bottom: var(--s-1);
  }
}

.verificationIcon {
  margin-right: var(--s-3);
}

.dateInfo {
  padding: 0 var(--s-2);
}

.dateInfoTitle {
  color: var(--gray-900);
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-1);
  text-align: center;
}

.lodging {
  font-family: var(--font-base);
  font-weight: var(--font-medium);
  font-size: var(--text-base);
  margin-top: var(--s-1);
}
