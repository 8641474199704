@import '../../landtrust.css';

.root {
  position: relative;
}

.buttonBody {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  & > svg {
    @media (--screen-md) {
      margin-right: var(--s-3);
    }
  }
}

.buttonLabel {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.header {
  margin-bottom: var(--s-6);
  padding-right: var(--s-10);
}

.icon {
  width: var(--s-5);
  height: var(--s-6);

  @media (--screen-md) {
    width: var(--s-4);
    height: var(--s-5);
  }
}

.listItem {
  margin-bottom: var(--s-6);
}

.link {
  position: relative;
  color: var(--gray-900);
  display: flex;
  align-items: center;

  &:hover {
    color: var(--gray-600);
    text-decoration: none;
  }
}

.linkLabel {
  margin-left: var(--s-3);
}

.linkIcon {
  width: var(--s-7);
}

.copyLink {
  position: absolute;
  top: 0;
  left: var(--s-9);
  right: 0;
  opacity: 0;
  transition-delay: 0.25s;
  transition: none;
}

.copiedLink {
  position: absolute;
  top: 0;
  left: var(--s-9);
  right: 0;
  opacity: 1;
  transition-delay: 0;
  transition: opacity 0.25s ease-in-out;
}
