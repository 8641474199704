@import '../../landtrust.css';
.root {
  position: absolute;
  max-width: var(--s-97);
  opacity: 0;
  transition: opacity 0.15s linear;
  background-color: #1a202c;
  color: #ffffff;
  box-shadow: var(--box-shadow-xl);
  display: flex;
  flex-direction: column;
  border-radius: var(--s-1);
  padding-top: var(--s-10);
  padding-bottom: var(--s-10);
  padding-left: var(--s-5);
  padding-right: var(--s-10);
  z-index: var(--z-60);

  @media (--screen-md) {
    position: fixed;
    max-width: var(--s-93);
  }
}

.root::before {
  content: '';
  position: absolute;
  top: 0;
  left: 86.7%; /* default for mobile */
  transform: translateX(-50%);
  margin-top: -9px;
  width: 0;
  height: 0;
  border-left: var(--border-radius-xl) solid transparent;
  border-right: var(--border-radius-xl) solid transparent;
  border-bottom: var(--border-radius-xl) solid #1a202c;

  @media (--screen-md) {
    left: 78%;
  }
}

.root.tooltipVisible {
  opacity: 1;
}
