@import '../../landtrust.css';

.sectionContainer {
  position: relative;
  padding: 0 var(--s-6);
  border-radius: var(--border-radius-xl);
  margin-bottom: var(--s-10);

  @media (--screen-lg) {
    margin: var(--s-9) 0;
    padding: 0;
  }
}

.sectionHeader {
  font-size: var(--text-2xl);
  display: flex;
  font-family: var(--font-base);
  font-weight: bold;
  color: var(--gray-900);
  padding-right: var(--s-8);

  @media (--screen-lg) {
    margin: 0 0 var(--s-1);
  }
}
