@import '../../landtrust.css';

.root {
  margin-bottom: var(--s-7);
}

.inputWrapper {
  position: relative;
  width: 100%;

  @media (--screen-md) {
    width: var(--w-3-4);
  }
}

.applyButton {
  position: absolute;
  top: 5px;
  right: var(--s-2);
  z-index: 10;
}

.headerLink {
  display: block;
  text-decoration: underline;
  margin-bottom: var(--s-3);
}
