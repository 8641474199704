@import '../../marketplace.css';
@import '../../landtrust.css';

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: 100vh;
    flex-direction: row;
  }
}

.root {
  justify-content: flex-start;
}

.content {
  @apply --marketplaceModalBaseStyles;
  background-color: transparent;
  padding-top: 0;

  @media (--viewportMedium) {
    margin-top: var(--s-16);
    margin-bottom: var(--s-16);
    padding-top: var(--s-2);
    padding-bottom: 0;
    padding-left: var(--s-36);
    max-width: var(--s-152);
    border-bottom: none;
  }
}

.colLeft {
  order: 2;
  background-color: var(--olive-900);
  padding: var(--s-17) var(--s-6);
  color: var(--white);

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 1;
    flex-grow: 1;
    flex-basis: 50%;
    padding-top: var(--s-4);
  }
}

.colLeftWrapper {
  max-width: var(--s-127);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.colRight,
.colRightConfirm {
  order: 1;
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.colRight {
  @media (--viewportMedium) {
    order: 2;
    justify-content: center;
  }
}

/* ================ Tabs & Form ================ */

.tab {
  flex-grow: 1;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* Terms of Service modal*/
.termsWrapper {
  width: 100%;
  padding-top: 10px;
  padding-left: var(--s-4);
  padding-right: var(--s-8);
  max-height: 560px;

  @media (--viewportMedium) {
    width: 604px;
  }
}

.termsHeading {
  @apply --marketplaceH1FontStyles;
  margin: 0 0 19px 0;

  @media (--viewportMedium) {
    margin: 0 0 19px 0;
  }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Close icon ================ */

.verifyClose {
  @apply --marketplaceModalCloseStyles;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeText {
  @apply --marketplaceModalCloseText;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

/* Helper links */
.modalHelperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  @apply --marketplaceModalHelperText;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Errors ================ */

.error {
  @apply --marketplaceModalErrorStyles;
}

/* ================ Social logins & SSO ================ */

.signupWithIdpTitle {
  @apply --typography-heading-size-4xl;
  font-family: var(--font-base);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: var(--s-4);
  color: var(--gray-900);

  @media (--viewportMedium) {
    margin-top: 6px;
  }
}
.socialButtonWrapper {
  margin-top: var(--s-6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmInfoText {
  @apply --typography-base;
}

.buttonIcon {
  position: absolute;
  left: 0;
  margin-left: var(--s-5);
}

.socialButtonsOr {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--text-sm);
  width: 100%;
  height: var(--s-8);
  margin: var(--s-4) 0 0 0;
  text-align: center;
  position: relative;
  background-color: var(--white);

  &:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px var(--gray-400);
    position: absolute;
    left: 0;
    top: var(--w-1-2);
    z-index: 1;
  }
}

@media (--viewportMedium) {
  .socialButtonsOr {
    height: var(--s-8);
    margin: var(--s-4) 0 var(--s-2) 0;
  }
}

.socialButtonsOrText {
  background-color: var(--white);
  z-index: 3;
  padding: 0 var(--s-5) 0 var(--s-5);
  position: relative;
  margin: 0;
  font-weight: var(--font-bold);
}
.headerWrapper {
  font-size: var(--text-4xl);
  font-family: var(--font-base);
  text-align: center;
  margin: 0 auto var(--s-6) auto;
  width: 100%;

  @media (--screen-md) {
    margin: auto;
  }
}

.topbar {
  width: 100%;
  height: var(--s-18);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--screen-md) {
    display: none;
  }
}

.logoMobile {
  display: block;

  @media (--screen-md) {
    display: none;
  }
}

.logoDesktop {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.tabContent {
  font-size: var(--text-base);
}

.reviewWrapper {
  display: flex;
  width: 100%;
  padding-top: 100px;
  justify-content: center;
  flex-grow: 2;
}
