@import '../../landtrust.css';

.root {
}

.button {
  font-family: inherit;
  padding: 0.375rem var(--s-4) 0.375rem var(--s-4);
  font-size: var(--text-sm);
  line-height: var(--line-height-normal);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: var(--s-1);
  color: var(--gray-800);
  border: 1px solid var(--gray-800);
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
  }
}

.buttonSelected {
  box-shadow: inset 0 0 0 1px var(--gray-800);
  font-weight: bold;
}

.buttonEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon {
  margin-right: var(--s-2);
  display: inline-block;
  max-height: var(--s-5);
  width: auto;
  fill: currentColor;
}
