@import '../../landtrust.css';

.profileInfoContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--s-7);
}

.avatar {
  width: var(--s-22);
  height: var(--s-22);
  margin-bottom: var(--s-4);
  cursor: pointer;
}

.modalContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: var(--black);
  border-radius: var(--border-radius);
  border-bottom: none;
  flex-grow: 0;
  height: 100vh;
  overflow: hidden;
  margin: 0;

  @media (--screen-md) {
    padding: var(--s-6);
    height: auto;
    max-width: var(--s-230);
    margin: 0 var(--s-6);
  }
}

.closeButton {
  position: absolute;
  top: var(--s-20);
  right: var(--s-6);
  width: var(--s-10);
  height: var(--s-10);
  padding: var(--s-2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: var(--line-height-none);
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
  z-index: 1;

  @media (--screen-md) {
    top: var(--s-4);
    width: var(--s-8);
    height: var(--s-8);
  }
}

.closeIcon {
  color: white;
  transition: transform 0.3s ease;
  width: var(--s-10);
  height: var(--s-10);

  @media (--screen-md) {
    width: var(--s-10);
    height: var(--s-10);
  }
}

.closeIcon:hover {
  transform: scale(1.1);
}

.avatarModalContainer {
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--screen-md) {
    height: 100%;
    margin-bottom: var(--s-4);
  }
}

.avatarImage {
  width: var(--s-84);
  height: var(--s-84);

  @media (--screen-md) {
    width: var(--s-100);
    height: var(--s-100);
    margin-top: var(--s-4);
  }
}

.profileInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileName {
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
}

.profileCreated {
  color: var(--gray-600);
}

.profileLink {
  position: absolute;
  top: 0;
  right: 0;
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  white-space: nowrap;
}

.profileReviews {
  display: flex;
  align-items: center;
  margin-top: var(--s-5);
}

.reviewRating {
  margin-right: var(--s-4);
}

.reviewRatingStar {
  width: 14px;
}

.profileContent {
  background-color: var(--gray-100);
  border-radius: var(--border-radius-xl);
  color: var(--gray-900);

  padding: var(--s-8) var(--s-3);
}

.profileBioHeader {
  font-weight: var(--font-bold);
  font-size: var(--text-lg);
  margin-bottom: var(--s-5);

  @media (--viewportMedium) {
    font-size: var(--text-2xl);
  }
}

.profileInfoItem {
  display: flex;
  align-items: center;
  color: var(--gray-700);
  font-weight: var(--font-medium);
  margin-top: var(--s-4);
}

.profileInfoIcon {
  width: var(--s-5);
  margin-right: var(--s-3);
  display: flex;
  align-items: center;
}

.profileListingsHeader {
  font-weight: var(--font-bold);
  font-size: var(--text-lg);
  margin-bottom: var(--s-5);
  margin-top: var(--s-5);

  @media (--viewportMedium) {
    font-size: var(--text-2xl);
    margin-top: var(--s-8);
  }
}

.profileListings {
  @media (--viewportMedium) {
    display: flex;
    flex-flow: wrap;
    margin-left: calc(-1 * var(--s-6));
    margin-right: calc(-1 * var(--s-6));
  }
}

.profileListing {
  margin-bottom: var(--s-6);

  @media (--viewportMedium) {
    width: calc(50% - var(--s-12));
    margin-right: var(--s-6);
    margin-left: var(--s-6);
  }
}

.profileSpeciesWrapper {
  margin-bottom: var(--s-10);
}

.profileSpeciesHeader {
  font-weight: var(--font-bold);
  font-size: var(--text-lg);
  margin-bottom: var(--s-5);

  @media (--viewportMedium) {
    font-size: var(--text-2xl);
    margin-top: var(--s-8);
  }
}

.profileSpecies {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(-1 * var(--s-4));
  margin-right: calc(-1 * var(--s-4));
}

.profileSpeciesImageContainer {
  position: relative;
  display: flex;
  align-items: center;

  @media (--screen-md) {
    height: var(--s-32);
  }
}

.profileSpeciesItem {
  flex-grow: 0;
  flex-basis: 50%;

  @media (--viewportMedium) {
    width: var(--s-38);
    flex-basis: auto;
    margin-right: var(--s-4);
    margin-left: var(--s-4);

    & > div > div > svg {
      width: var(--s-38);
    }
  }
}

.profileSpeciesItemTitle {
  padding: var(--s-2);
  font-weight: var(--font-medium);
  font-size: var(--text-base);
  color: var(--font-color);
  line-height: var(--line-height-none);
}

.profileSpeciesItemImage {
  position: absolute;
  top: calc(50% - (var(--s-24) / 2));
  left: calc(50% - (var(--s-24) / 2));
  width: var(--s-24);
  height: var(--s-24);
  z-index: var(--z-10);

  @media (--screen-md) {
    position: absolute;
    top: calc(50% - (117px / 2));
    left: calc(50% - (117px / 2));
    width: 117px;
    height: 117px;
    z-index: var(--z-10);
  }
}

.profileReviewsWrapper {
  display: flex;
  flex-direction: column;
}

.profileReviewsHeader {
  font-weight: var(--font-bold);
  font-size: var(--text-lg);
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: var(--text-2xl);
    margin-top: var(--s-8);
  }
}

.profileReviewsTabs {
  margin-left: calc(-1 * var(--s-5));
  margin-right: calc(-1 * var(--s-5));

  @media (--viewportMedium) {
    margin-left: 0;
    margin-right: 0;
  }
}

.reviewStarIcon {
  margin: 0 var(--s-2) 0 var(--s-2);
  height: var(--s-4);
  width: var(--s-4);
}

.profileReviewsEmpty {
  display: flex;
  margin-top: var(--s-6);
}
