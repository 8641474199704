@import '../../landtrust.css';

.root {
}

.labels {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (--screen-lg) {
    align-items: initial;
  }
}

.pageLabel {
  font-size: var(--text-base);
  color: var(--gray-900);
  font-weight: bold;
  margin-top: var(--s-3);

  @media (--screen-lg) {
    font-size: var(--text-lg);
    margin-top: var(--s-5);
  }
}

.percentLabel {
  font-size: var(--text-xs);
  color: var(--gray-700);
  font-weight: bold;
  margin-top: var(--s-3);

  @media (--screen-lg) {
    font-size: var(--text-sm);
    margin-top: var(--s-1);
  }
}
