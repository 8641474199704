@import '../../landtrust.css';

.root {
  fill: var(--white);
  stroke: var(--gray-900);
}

.filled {
  fill: var(--gray-900);
}

.goldFilled {
  fill: var(--orange-500);
}

.goldEmpty {
  fill: var(--white);
  stroke: var(--orange-500);
}
