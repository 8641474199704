@import '../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  flex-grow: 2;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--s-2);
  border-bottom: 1px solid var(--gray-300);
  margin-bottom: var(--s-6);
  font-size: var(--text-lg);
  font-weight: var(--font-bold);
  padding: var(--s-8) var(--s-6) var(--s-2) var(--s-6);
}

.content {
  overflow: auto;
  height: 100%;
  position: relative;
  padding: 0 var(--s-6);
}

.contentLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendMessageForm {
  margin-top: var(--s-5);
}

.textAreaMessageForm {
  min-height: var(--s-19);
}

.genericError {
  color: var(--red-500);
  margin: var(--s-6) 0 0 0;
}
