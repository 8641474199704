@import '../../landtrust.css';

.root {
  background-color: var(--white);
  box-shadow: var(--box-shadow-xl);
  display: flex;
  flex-direction: column;
  padding: var(--s-6) var(--s-6) var(--s-6) var(--s-6);
  transition: opacity 0.3s;
  z-index: var(--z-60);
  width: auto;

  @media (--screen-md) {
    width: auto;
    border-radius: var(--border-radius);
  }
}

.tooltipArrow {
  height: var(--s-4);
  position: absolute;
  width: var(--s-4);
}

.tooltipArrow::before {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.tooltipArrow::after {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}

.tooltipArrow[data-placement*='bottom'] {
  height: var(--s-4);
  left: 0;
  margin-top: calc(-1 * var(--s-2));
  top: 0;
  width: var(--s-4);
}

.tooltipArrow[data-placement*='bottom']::before {
  border-color: transparent transparent transparent transparent;
  border-width: 0 var(--s-2) var(--s-2) var(--s-2);
  position: absolute;
  top: -1px;
}

.tooltipArrow[data-placement*='bottom']::after {
  border-color: transparent transparent white transparent;
  border-width: 0 var(--s-2) var(--s-2) var(--s-2);
}

.tooltipArrow[data-placement*='top'] {
  bottom: 0;
  height: var(--s-4);
  left: 0;
  margin-bottom: calc(-1 * var(--s-4));
  width: var(--s-4);
}

.tooltipArrow[data-placement*='top']::before {
  border-color: transparent transparent transparent transparent;
  border-width: var(--s-2) var(--s-2) 0 var(--s-2);
  position: absolute;
  top: 1px;
}

.tooltipArrow[data-placement*='top']::after {
  border-color: white transparent transparent transparent;
  border-width: var(--s-2) var(--s-2) 0 var(--s-2);
}

.tooltipArrow[data-placement*='right'] {
  height: var(--s-4);
  left: 0;
  margin-left: calc(-1 * var(--s-3));
  width: var(--s-4);
}

.tooltipArrow[data-placement*='right']::before {
  border-color: transparent transparent transparent transparent;
  border-width: var(--s-2) var(--s-2) var(--s-2) 0;
}

.tooltipArrow[data-placement*='right']::after {
  border-color: transparent white transparent transparent;
  border-width: var(--s-2) var(--s-2) var(--s-2) 0;
  left: 6px;
  top: 0;
}

.tooltipArrow[data-placement*='left'] {
  height: var(--s-4);
  margin-right: calc(-1 * var(--s-3));
  right: 0;
  width: var(--s-4);
}

.tooltipArrow[data-placement*='left']::before {
  border-color: transparent transparent transparent transparent;
  border-width: var(--s-2) 0 var(--s-2) var(--s-2);
}

.tooltipArrow[data-placement*='left']::after {
  border-color: transparent transparent transparent white;
  border-width: var(--s-2) 0 var(--s-2) var(--s-2);
  left: 3px;
  top: 0;
}

.tooltipClose {
  position: absolute;
  right: var(--s-4);
  top: var(--s-4);
  cursor: pointer;
  width: var(--s-8);
  height: var(--s-8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  box-shadow: var(--box-shadow-xl);
  background: none;
  border: none;
  color: inherit;
  outline: none;
}
