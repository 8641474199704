@import '../../landtrust.css';

.root {
}

.labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--s-2);
}

.label {
  font-size: var(--text-sm);
  color: var(--gray-900);
  font-weight: var(--font-medium);
}
