@import '../../landtrust.css';

.sectionDetails {
  padding: 0;
  margin-bottom: var(--s-8);
}
.modalTitle {
  margin-bottom: var(--s-5);
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
}
.detailsSubtitle {
  font-size: var(--text-xl);
  font-weight: bold;
  margin-bottom: var(--s-1);
}

.icon {
  width: var(--s-5);
  color: var(--gray700);
  margin-right: var(--s-1);
}

.detailsGroupsContainer {
  display: flex;
  flex-flow: column;
}
.detailsGroup {
  margin-bottom: var(--s-8);
  margin-right: var(--s-14);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.activitiesContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--s-2);

  @media (--screen-md) {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.detail {
  break-inside: avoid;
  display: flex;
  align-items: center;
  margin-bottom: var(--s-2);

  @media (--screen-md) {
    width: var(--w-1-2);
  }
}

.detailLabel {
  font-weight: var(--font-bold);
}

.detailInfo {
  font-size: var(--text-sm);
  margin-left: var(--s-6);
}

.readMore {
  color: var(--gray-900);
  margin: 0;
  text-decoration: underline;
  font-family: var(--font-base);
}
.readMore:hover {
  cursor: pointer;
  text-decoration: underline;
}

.outdoorActivitiesModal {
  background-color: rgba(0, 0, 0, 0.4);
  justify-content: center;

  @media (--screen-md) {
    align-items: center;
  }
}

.outdoorActivitiesScrollModal {
  overflow: auto;

  @media (--screen-md) {
    width: 50%;
  }
}

.modalDetails {
  display: flex;
  flex-direction: column;
  gap: var(--s-3);
}

.modalDetailContainer {
  display: flex;
  flex-direction: column;
  gap: var(--s-1);
}

.modalDetail {
  display: flex;
  align-items: center;
}
