.root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.categoriesList {
  display: flex;
  flex-wrap: wrap;
}

.categoriesListItem {
  width: 50%;
}
