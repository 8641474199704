@import '../../landtrust.css';

.root {
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  padding-bottom: var(--s-32);

  @media (--screen-md) {
    max-width: var(--w-2-3);
    padding: 0 0 var(--s-25) 0;
  }
}

.header {
  font-weight: var(--font-bold);
  font-size: var(--text-2xl);
  margin-bottom: var(--s-5);

  @media (--screen-md) {
    font-size: var(--text-3xl);
  }
}

.imagesContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(-1 * var(--s-1));

  @media (--screen-md) {
    margin: 0 calc(-1 * var(--s-3));
  }
}

.imageContainer {
  padding: var(--s-1) var(--s-1) var(--s-5) var(--s-1);
  cursor: pointer;
  width: var(--w-2-4);

  @media (--screen-md) {
    width: var(--w-1-3);
    padding: var(--s-3);
  }
}

.imageWrapper {
  display: block;
  width: 100%;
  position: relative;
}

.imageBox {
  position: relative;
  padding-bottom: var(--w-2-3);
}

.imageSpan {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;
}

.image {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
}

.tagContainer {
  margin-top: var(--s-2);
  font-size: var(--text-xs);
  display: flex;
  align-items: center;
  gap: var(--s-2);
}

.tagLabel {
  display: flex;
  align-items: center;
  font-weight: var(--font-bold);
}

.tagBadges {
  display: flex;
  flex-wrap: wrap;
  gap: var(--s-2);
}

.tagBadge {
  color: var(--gray-900);
  padding: var(--s-1) var(--s-2);
  border-radius: var(--border-radius-xl);
  background-color: var(--gray-200);
  white-space: nowrap;

  @media (--screen-md) {
    padding: var(--s-1) var(--s-4);
  }
}

.checkIconWrapper {
  position: absolute;
  left: var(--s-2);
  top: var(--s-2);
  width: var(--s-7);
  height: var(--s-7);
  border: 2px solid var(--white);
  border-radius: 50%;
  opacity: 0.75;

  @media (--screen-md) {
    left: var(--s-3);
    top: var(--s-3);
  }
}

.checkIconWrapperSelected {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green-900);
}

.checkIcon {
  & > path {
    fill: var(--white);
  }
}

.saveBarContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--white);
  padding: var(--s-3);
  display: flex;
  justify-content: center;

  @media (--screen-md) {
    padding: var(--s-6);
  }
}

.saveBar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.saveBarText {
  font-size: var(--text-sm);
  margin-top: var(--s-2);
}

.dropzoneDescription {
  margin-bottom: var(--s-4);
}

.imagesLimitValidation {
  color: var(--red-500);
}

.dropzoneContainer {
  position: relative;
  margin-top: var(--s-5);
  margin-bottom: var(--s-3);
}

.dropzoneDropNotification {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  border: dashed 1px var(--gray-400);
  border-radius: var(--border-radius-xl);
  background: rgba(255, 255, 255, 0.9);
}

.dropzoneDropNotificationLabel {
  margin: 0;
}

.imageCategories {
  display: grid;
  grid-gap: var(--s-5) var(--s-6);
  grid-auto-rows: minmax(min-content, max-content);

  @media (--screen-md) {
    grid-gap: var(--s-4) var(--s-4);
  }
}

.progressBar {
  margin-top: var(--s-8);
}

.photoPreviewTagsContainer {
  display: flex;
  align-items: center;
  margin-top: var(--s-2);
  flex-wrap: wrap;
  gap: var(--s-2);
}

.photoPreviewTagsLinkContainer {
  display: flex;
  align-items: center;
}

.photoPreviewTagsIcon {
  height: var(--s-4);
  width: var(--s-4);
}

.photoPreviewTagsLink {
  text-decoration: underline;
  font-size: var(--text-sm);
  margin-left: var(--s-1);
}

.photoPreviewTagsItem {
  display: flex;
  align-items: center;
  font-size: var(--text-xs);
  border-radius: var(--border-radius-xxl);
  border: 1px solid var(--gray-400);
  padding: var(--s-2);
  white-space: nowrap;
  color: var(--white);
  background-color: var(--gray-900);

  &:hover {
    text-decoration: none;
  }
}

.photoPreviewTagsItemClose {
  cursor: pointer;

  & > svg > path {
    fill: var(--white);
  }
}

.photoPreviewTagsCloseIcon {
  width: 10px;
  margin-left: var(--s-2);
}

.photoPreviewTagsTooltip {
  max-height: 100vh;
  overflow: auto;
}
