@import '../../marketplace.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
  margin-bottom: var(--s-8);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.label {
  margin-bottom: var(--s-3);
  font-size: var(--text-lg);
}

.list {
  margin: 0;
}

.item {
  display: block;
  margin-bottom: var(--s-2);
  display: flex;
  align-items: center;
}

.fieldGroup {
  flex: 1;
}

.fieldWrapper {
  margin-bottom: var(--s-3);
}

.button {
  text-decoration: underline;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  margin-right: var(--s-2);
  color: var(--brand);
  width: var(--s-6);
  height: var(--s-6);
}

.removeButton {
  margin-left: var(--s-3);
}

.removeButton .icon {
  color: var(--gray-400);
  margin: 0;
}
