@import '../../landtrust.css';
@import '../../marketplace.css';
@import '../Modal/Modal.css';

.root {
  width: 100%;
}

.container {
  height: var(--s-18);
  padding: 0 var(--s-5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  box-shadow: var(--box-shadow-xl);

  @media (--screen-md) {
    display: none;
  }
}

.burgerMenu {
  color: var(--gray-900);

  & > svg {
    display: block;
  }
}

.mapToggle {
  margin-left: var(--s-5);

  & svg {
    display: block;
    fill: var(--gray-900);
  }
}

.logo {
  position: absolute;
}

.searchForm {
  width: 100%;
  margin-left: var(--s-5);
  flex: 1;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  visibility: hidden;
}

.searchFormVisible {
  visibility: visible;
  opacity: var(--o-100);
}

.modalContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--s-6);
  background-color: var(--white);
  border-bottom: none;
  flex-grow: 0;
  min-height: auto;
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin: 0;
  overflow: hidden;
}

.activityLink {
  position: relative;
  margin-left: var(--s-4);
}

.activityBellIcon {
  width: var(--s-6);
  fill: currentColor;
}
