@import '../../landtrust.css';

.root {
  display: none;

  @media (--screen-md) {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: var(--s-6);
    border-radius: var(--s-6);
    box-shadow: var(--box-shadow);
    border: none;
    background-color: var(--white);
    min-width: var(--s-80);
    flex-basis: var(--w-2-5);
    flex-shrink: 1;
    flex-grow: 1;
  }
}

.rootSticky {
  @media (--screen-md) {
    position: sticky;
    top: var(--s-22);
    bottom: var(--s-38);
  }
}

.formFieldWrapper {
  margin-top: var(--s-3);
}

.priceWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: var(--text-xl);
}

.formFieldHeading {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-2);
}

.formFieldHeadingPackageDetails {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-1);
}

.minPartyDisclaimer {
  font-size: var(--text-sm);
  margin-bottom: var(--s-4);

  @media (--screen-md) {
    font-size: var(--text-xs);
  }
}

.priceContainer {
  display: flex;
  align-items: center;
}

.priceText {
  font-size: var(--text-xl);
  font-weight: bold;
}

.perGuestText {
  font-size: var(--text-sm);
  margin-left: var(--s-1);
}

.dateInfo {
  padding: 0 var(--s-2);
}

.dateInfoTitle {
  color: var(--gray-900);
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-1);
  text-align: center;
}

.dateInfoBody {
  margin: 0;
  color: var(--gray-900);
  font-size: var(--text-xs);
}

.error {
  color: var(--red-500);
}

.availableDates {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--s-4);
  font-size: var(--text-sm);

  @media (--screen-md) {
    margin-top: var(--s-2);
  }
}

.verificationIcon {
  margin-right: var(--s-3);
  min-width: var(--s-8);
}

.lodgingContainer {
  font-size: var(--text-base);
  margin: 0;
  color: var(--var-900);
  font-weight: var(--font-bold);

  padding-top: var(--s-1);
  padding-bottom: var(--s-1);

  @media (--screen-md) {
    margin: 0;
    padding-top: var(--s-1);
    padding-bottom: var(--s-1);
  }
}

.lodging {
  font-family: var(--font-base);
  font-weight: var(--font-medium);
  font-size: var(--text-base);
  margin-top: var(--s-1);
}

.packageBadges {
  margin-top: var(--s-3);
}

.soldOut,
.bookingRange {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: var(--s-10);
  margin: var(--s-4) var(--s-1) var(--s-6) var(--s-1);
  padding: var(--s-1);
  border-radius: var(--border-radius-xl);
  font-size: var(--text-xs);
  font-weight: var(--font-bold);
}

.soldOut {
  background-color: var(--red-300);
  color: var(--red-500);
}

.bookingRange {
  background-color: var(--brown-100);
  color: var(--brown-900);
}

.notifyButton {
  background-color: var(--gray-900);
  color: var(--white);
}

.icon {
  width: var(--s-4);
  height: var(--s-5);
}

.badgeWrapper {
  margin-bottom: var(--s-7);
}

.getNotified {
  color: var(--gray-900);
  font-size: var(--text-sm);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: nowrap;
  margin: var(--s-3) 0;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--text-sm);
  font-style: italic;
  background-color: var(--gray-200);
  border-radius: var(--border-radius);
  padding: var(--s-3) 0;
}

.divider {
  height: 1px;
  background-color: var(--gray-300);
  margin: var(--s-5) 0;
}

.lightningIcon {
  color: var(--gray-900);
}

.formWrapper {
  display: flex;
  flex-direction: column;
}

.packageIncludes {
  margin-top: var(--s-3);
  margin-bottom: var(--s-1);
}

.packageIncludesHeading {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
}
