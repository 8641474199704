@import '../../landtrust.css';

.root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: var(--s-14) var(--s-6) var(--s-12) var(--s-6);
  background-color: var(--white);
  border-radius: var(--border-radius-xl);
  border-bottom: none;
  flex-basis: var(--s-120);
  position: absolute;
  max-height: 100dvh;
  overflow: auto;
  bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  @media (--screen-md) {
    position: relative;
    flex-basis: var(--s-142);
    flex-grow: 0;
    padding: var(--s-15);
    border-bottom-left-radius: var(--border-radius-xl);
    border-bottom-right-radius: var(--border-radius-xl);
  }
}

.modalContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--s-5);
}

.closeButton {
  text-decoration: underline;
  color: var(--gray-900);
}
