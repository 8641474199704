@import '../../landtrust.css';

.verifySection {
  background-color: var(--gray-100);
  padding: var(--s-6) var(--s-4) var(--s-6) var(--s-4);
  border-radius: var(--border-radius-xl);
  margin-bottom: var(--s-5);
  color: var(--gray-900);
}

.verifySectionHeader {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  display: flex;
  align-items: center;
  margin-bottom: var(--s-4);
}

.verifySectionIcon {
  color: var(--green-900);
}

.verifySectionHeaderText {
  margin-left: var(--s-3);
}

.verifySectionContent {
  font-size: var(--text-sm);
  margin-bottom: var(--s-3);
}

.verifySectionLink {
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
}
