@import '../../landtrust.css';

.topicsHeaderWrapper {
  display: flex;
  align-items: center;
  font-weight: var(--font-bold);
  margin-bottom: var(--s-4);
}

.topicsHeader {
  margin-left: var(--s-2);
}

.topicsList {
  list-style: disc;
  margin-left: var(--s-5);
}

.topicsItem {
  font-size: var(--text-sm);
  margin-bottom: var(--s-4);
}
