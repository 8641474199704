@import '../../landtrust.css';

.root {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: var(--transition-base);
  flex: 1;

  &:hover {
    text-decoration: none;
    transform: scale(1.02);
  }
}

.contentContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.imageContainer {
  position: relative;
  display: flex;
  align-items: center;

  @media (--screen-md) {
    height: 196px;
  }
}

.bg {
  width: var(--s-32);
  height: var(--s-32);

  @media (--screen-md) {
    width: initial;
    height: initial;
  }
}

.image {
  position: absolute;
  top: calc(50% - (var(--s-24) / 2));
  left: calc(50% - (var(--s-24) / 2));
  width: var(--s-24);
  height: var(--s-24);
  z-index: var(--z-10);

  @media (--screen-md) {
    top: calc(50% - (var(--s-40) / 2));
    left: calc(50% - (var(--s-40) / 2));
    width: var(--s-40);
    height: var(--s-40);
  }
}

.title {
  padding: var(--s-5);
  font-weight: var(--font-medium);
  font-size: var(--text-base);
  color: var(--font-color);
  line-height: var(--line-height-tight);
  text-align: center;

  @media (--screen-md) {
    padding: var(--s-2);
    font-size: var(--text-2xl);
  }
}
