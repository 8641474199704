@import '../../landtrust.css';

.root {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 27%, rgba(0, 0, 0, 0.66));
  }
}

.image {
  max-width: 100%;
}
