@import '../../landtrust.css';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.error {
  color: var(--red-500);
}

.dropzoneDescription {
  margin-bottom: var(--s-4);
}

.EditListingPhotoContainer {
  width: 100%;

  @media (--screen-lg) {
    width: var(--w-4-5);
  }
}

.PhotoFieldWrapper {
  width: 100%;

  @media (--screen-lg) {
    width: 455px;
  }
  @media (--screen-xl) {
    width: 660px;
  }
}

.imagesLimitValidation {
  color: var(--red-500);
}

.dropzoneContainer {
  position: relative;
}

.dropzoneContainerActive {
}

.dropzoneDropNotification {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  border: dashed 1px var(--gray-400);
  border-radius: var(--border-radius-xl);
  background: rgba(255, 255, 255, 0.9);
}

.dropzoneDropNotificationLabel {
  margin: 0;
}

.imageCategories {
  display: grid;
  grid-gap: var(--s-5) var(--s-6);
  /* grid-template-columns: repeat(2, minmax(0, 50%)); */
  grid-auto-rows: minmax(min-content, max-content);

  @media (--screen-md) {
    grid-gap: var(--s-4) var(--s-4);
    /* grid-template-columns: repeat(4, minmax(0, 25%)); */
  }
}

.imageCategory {
  position: relative;
  cursor: pointer;
}

.imageCategoryDisabled {
  position: relative;
  cursor: not-allowed;
}

.imageCategoryInnerDisabled {
  opacity: 0.7;
}

.imageCategoryInner {
  width: 100%;
  height: 100%;
  padding: var(--s-7) var(--s-5);
  box-shadow: var(--box-shadow);
  border: dashed 1px var(--gray-400);
  border-radius: var(--border-radius-xl);
  background: var(--white);
}

.imageCategoryIcon {
  height: var(--s-8);
  fill: var(--brand);
}

.imageCategoryLabel {
  font-size: var(--text-sm);
  color: var(--gray-900);
  margin: var(--s-4) 0 0 0;
  font-family: var(--font-base);
}

.progressBar {
  margin-top: var(--s-8);
}

.photoPreviews {
  margin-top: var(--s-8);
}

.uploadRestrictions {
  margin-bottom: var(--s-10);
}

.uploadRestrictionText {
  margin: 0;
  color: var(--gray-700);
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
}

.tagCheckboxContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--s-4);
}

.tagCheckboxLabel {
  font-weight: normal;
}
