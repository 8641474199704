@import '../../marketplace.css';
@import '../../landtrust.css';

:root {
  --fieldBase: {
    margin-top: var(--s-4);

    @media (--viewportMedium) {
      margin-top: var(--s-8);
    }
  }
}

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.email {
  @apply --fieldBase;
}

.password {
  @apply --fieldBase;
}

.inputGroup {
  position: relative;
}
.passwordIcon {
  position: absolute;
  top: calc(60% - (var(--s-1) / 2));
  right: var(--s-3);
  width: var(--s-5);
  height: var(--s-5);
  color: var(--gray-500);
  &:hover {
    cursor: pointer;
  }
}
.passwordIconError {
  position: absolute;
  top: calc(42% - (var(--s-1) / 2));
  right: var(--s-3);
  width: var(--s-5);
  height: var(--s-5);
  color: var(--gray-500);
  &:hover {
    cursor: pointer;
  }
}

.bottomWrapperText {
  margin-top: var(--s-2);
  margin-bottom: var(--s-6);
  text-align: right;
  font-weight: var(--font-medium);

  @media (--viewportMedium) {
    margin-bottom: var(--s-8);
  }
}

.recoveryLink {
  text-decoration: underline;
}
