@import '../../landtrust.css';

.items {
  display: flex;
  flex-wrap: wrap;
}

.item {
  display: flex;
  margin-top: var(--s-3);
  margin-right: var(--s-3);
  padding: var(--s-3) var(--s-4);
  border-radius: var(--border-radius-xxl);
  background-color: var(--gray-900);
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  color: var(--white);
}

.itemText {
  margin-right: var(--s-6);
}

.itemIcon {
  cursor: pointer;

  & > svg > path {
    fill: var(--white);
  }
}
