@import '../../landtrust.css';
@import '../FlexHelper/FlexHelper.css';

.root {
  padding: var(--s-12) var(--s-8);
}

.root--brown {
  background-color: var(--brown-300);
}

.root--olive {
  background-color: var(--olive-500);
}

.root--gray {
  background-color: var(--gray-200);
}

.title {
  text-align: center;
}

.description {
  text-align: center;
  margin-bottom: var(--s-8);
}

.ctaContainer {
  text-align: center;
}

.stepsWrapper {
  @apply --flexWrapper;
}

.stepsInner {
  @apply --flexInner10;
}

.stepRoot {
  @apply --flexItem10;
  margin-bottom: var(--s-12);
}

.stepHeader {
  display: inline-block;
}

.stepIconContainer {
  margin-bottom: var(--s-3);
  color: var(--gray-900);
}

.stepTitle {
  font-size: var(--text-2xl);
  margin-bottom: var(--s-3);
}

.stepBody {
  margin: 0;
}

@media (--screen-lg) {
  .root {
    padding: var(--s-20) var(--s-8);
  }

  .description {
    margin-left: auto;
    margin-right: auto;
    width: var(--w-2-5);
    font-size: var(--text-2xl);
  }

  .stepsWrapper {
    margin-top: var(--s-16);
  }

  .stepRoot {
    @apply --flexItem10Width30Percent;
  }

  .stepBody {
    font-size: var(--text-sm);
  }
}
