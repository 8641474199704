@import '../../marketplace.css';
@import '../../landtrust.css';

.root {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100vw;
  padding: var(--s-1) 0 var(--s-24) 0;
  background-color: var(--white);
  color: var(--gray-900);
  border-top: solid 1px var(--gray-300);

  @media (--screen-lg) {
    width: 60vw;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, 0);
    padding: var(--s-1);
    border-radius: var(--s-3);
    box-shadow: 0 0 var(--s-5) 0 rgba(0, 0, 0, 0.05);
    border: solid 1px var(--gray-300);
  }
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (--screen-lg) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 var(--s-4) var(--s-6) var(--s-8);
  }
}

.message {
  font-family: var(--font-base);
  width: var(--w-3-4);
  font-size: var(--text-xs);
  margin: 0 auto var(--s-8) auto;
  line-height: var(--s-4);

  @media (--screen-lg) {
    display: flex;
    @apply --typography-base;
    flex: 1;
    font-size: var(--text-base);
    margin: var(--s-8) var(--s-2);
    width: 75%;
  }
}

.cookieLink {
  color: var(--gray-900);
  &:hover {
    text-decoration: none;
  }
}

.continueBtn {
  /* Font */
  font-weight: var(--fontWeightSemiBold);
  width: var(--w-3-4);
  min-height: var(--s-12);

  /* Dimensions */
  padding: var(--s-1) var(--s-2) var(--s-1) var(--s-2);

  background-color: var(--gray-900);
  color: var(--white);
  border: none;
  cursor: pointer;

  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--gray-600);
    transition: var(--transitionStyleButton);
  }

  @media (--screen-lg) {
    width: var(--w-1-5);
    margin: 0 var(--s-10);
  }
}

.close {
  width: var(--s-8);
  height: var(--s-8);
  padding: var(--s-2);
  background-color: var(--white);
  color: var(--gray-900);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: var(--line-height-none);
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
  @media (--screen-lg) {
    justify-content: flex-end;
    flex: 0 1 100%;
    margin-left: var(--s-4);
  }
}
