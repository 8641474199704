@import '../../landtrust.css';

.root {
  width: 100%;

  @media (--viewportMedium) {
    padding: var(--s-1) 0 var(--s-5) 0;
  }
}

.inputWrapper {
  position: relative;
  width: 100%;
}

.loaderWrapper {
  display: flex;
  flex-direction: column;
  padding: var(--s-2) 0;
}

.skeletonTop:empty {
  position: relative;
  height: 150px;
  background-color: #ffffff;
  border-radius: 11px;
  background-image: linear-gradient(#edf2f7 22px, transparent 0),
    linear-gradient(#edf2f7 22px, transparent 0), linear-gradient(#edf2f7 22px, transparent 0),
    linear-gradient(#edf2f7 32px, transparent 0);
  background-repeat: repeat-y;
  background-size: 358px 290px, 409px 290px, 447px 290px, 197px 290px;
  background-position: left 5px top 106px, left 5px top 75px, left 5px top 44px, left 5px top 5px;
}

.skeletonTop:empty:before {
  content: ' ';
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 150px;
  border-radius: 11px;
  -webkit-mask-image: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 80%
  );
  -webkit-mask-repeat: repeat-y;
  -webkit-mask-size: 50px 150px;
  -webkit-mask-position: -20% 0;
  background-image: linear-gradient(rgba(255, 255, 255, 1) 22px, transparent 0),
    linear-gradient(rgba(255, 255, 255, 1) 22px, transparent 0),
    linear-gradient(rgba(255, 255, 255, 1) 22px, transparent 0),
    linear-gradient(rgba(255, 255, 255, 1) 32px, transparent 0);
  background-repeat: repeat-y;
  background-size: 358px 290px, 409px 290px, 447px 290px, 197px 290px;
  background-position: left 5px top 106px, left 5px top 75px, left 5px top 44px, left 5px top 5px;
  animation: shineForSkeleton 2s infinite;
}

@keyframes shineForSkeleton {
  to {
    -webkit-mask-position: 120% 0;
  }
}

.skeletonRadio:empty {
  position: relative;
  height: 225px;
  background-color: #ffffff;
  border-radius: 0px 0px 0px 0px;
  background-image: linear-gradient(#edf2f7 37px, transparent 0),
    linear-gradient(#edf2f7 22px, transparent 0),
    radial-gradient(circle 12.5px at 12.5px 12.5px, #edf2f7 11.5px, transparent 12.5px),
    linear-gradient(#edf2f7 59px, transparent 0), linear-gradient(#edf2f7 22px, transparent 0),
    linear-gradient(#edf2f7 22px, transparent 0),
    radial-gradient(circle 12.5px at 12.5px 12.5px, #edf2f7 11.5px, transparent 12.5px);
  background-repeat: repeat-y;
  background-size: 391px 290px, 413px 290px, 25px 290px, 471px 290px, 190px 290px, 413px 290px,
    25px 290px;
  background-position: left 50px top 190px, left 50px top 152px, left 5px top 150px,
    left 50px top 75px, left 50px top 40px, left 50px top 7px, left 5px top 5px;
}

.skeletonRadio:empty:before {
  content: ' ';
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 225px;
  -webkit-mask-image: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 80%
  );
  -webkit-mask-repeat: repeat-y;
  -webkit-mask-size: 50px 290px;
  -webkit-mask-position: -20% 0;
  background-image: linear-gradient(rgba(255, 255, 255, 1) 37px, transparent 0),
    linear-gradient(rgba(255, 255, 255, 1) 22px, transparent 0),
    radial-gradient(
      circle 12.5px at 12.5px 12.5px,
      rgba(255, 255, 255, 1) 11.5px,
      transparent 12.5px
    ),
    linear-gradient(rgba(255, 255, 255, 1) 59px, transparent 0),
    linear-gradient(rgba(255, 255, 255, 1) 22px, transparent 0),
    linear-gradient(rgba(255, 255, 255, 1) 22px, transparent 0),
    radial-gradient(
      circle 12.5px at 12.5px 12.5px,
      rgba(255, 255, 255, 1) 11.5px,
      transparent 12.5px
    );
  background-repeat: repeat-y;
  background-size: 391px 290px, 413px 290px, 25px 290px, 471px 290px, 190px 290px, 413px 290px,
    25px 290px;
  background-position: left 50px top 190px, left 50px top 152px, left 5px top 150px,
    left 50px top 75px, left 50px top 40px, left 50px top 7px, left 5px top 5px;
  animation: shineForSkeleton- 2s infinite;
}

@keyframes shineForSkeleton- {
  to {
    -webkit-mask-position: 120% 0;
  }
}
