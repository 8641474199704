@import '../../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem,
.lineItemRefund {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--text-base);
  margin-bottom: var(--s-2);
}

.lineItemRefund {
  font-weight: var(--font-bold);
}

.subTotalLineItem {
  font-weight: var(--font-bold);
  font-size: var(--text-base);

  margin: 0;

  @media (--screen-md) {
    margin: 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itemLabel {
  flex: 1;
  cursor: pointer;
  text-decoration: underline;
}

.itemValue {
  margin: 0 0 0 var(--s-2);

  @media (--screen-md) {
    margin: 0 0 0 var(--s-2);
  }
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: var(--s-3) 0 var(--s-1) 0;

  border: none;
  background-color: var(--gray-300);

  @media (--screen-md) {
    margin: 2px 0 var(--s-3) 0;
  }
}

.totalLabel {
  font-weight: var(--font-bold);

  /* Move so that baseline aligns with the total price */
  padding-top: var(--s-1);
}

.totalPrice {
  font-weight: var(--font-bold);
  margin: 0 0 0 var(--s-2);
  padding-top: var(--s-1);

  @media (--screen-md) {
    margin: 2px 0 0 var(--s-2);
    padding-top: 0;
  }
}
.feeInfo {
  font-size: var(--text-xs);
  margin-top: 0;
  margin-bottom: var(--s-1);
  flex-shrink: 0;
  color: var(--gray900);

  @media (--screen-md) {
    margin-top: auto;
  }

  @media (--viewportLarge) {
    margin-top: var(--s-1);
    margin-bottom: var(--s-3);
  }
}

.title {
  font-size: var(--text-lg);
  font-weight: var(--font-bold);
}

.subtitle {
  font-size: var(--text-xs);
  margin-bottom: var(--s-3);
}

.disclaimer {
  font-size: var(--text-xs);
  margin-bottom: var(--s-3);
}
