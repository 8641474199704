@import '../../marketplace.css';
@import '../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--text-sm);
  margin-bottom: var(--s-1);
}

.subTotalLineItem {
  font-weight: var(--font-bold);
  font-size: var(--text-sm);

  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itemLabel {
  flex: 1;
  margin-top: 1px; /* align with baseline */
}

.itemValue {
  margin: 0 0 0 10px;

  @media (--viewportMedium) {
    margin: 0 0 0 10px;
  }
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 13px 0 4px 0;

  border: none;
  background-color: var(--gray-300);

  @media (--viewportMedium) {
    margin: 2px 0 14px 0;
  }
}

.totalLabel {
  font-weight: var(--font-bold);

  /* Move so that baseline aligns with the total price */
  padding-top: 6px;

  @media (--viewportMedium) {
    padding-top: 8px;
  }
}

.totalPrice {
  font-weight: var(--font-bold);
  margin: 0 0 0 10px;
  padding-top: 4px;

  @media (--viewportMedium) {
    margin: 2px 0 0 10px;
    padding-top: 0;
  }
}
.feeInfo {
  font-size: var(--text-xs);
  margin-top: 0;
  margin-bottom: var(--s-1);
  flex-shrink: 0;
  color: var(--gray900);

  @media (--viewportMedium) {
    margin-top: auto;
  }

  @media (--viewportLarge) {
    margin-top: var(--s-1);
    margin-bottom: var(--s-3);
  }
}

.title {
  font-size: var(--text-lg);
  font-weight: var(--font-bold);
}

.subtitle {
  font-size: var(--text-xs);
  margin-bottom: var(--s-3);
}

.datesWrapper {
  display: flex;
  align-items: center;
  margin-bottom: var(--s-6);
}

.calendarIcon {
  margin-right: var(--s-4);
}
