@import '../../marketplace.css';
@import '../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
}
.bookingDatesDiv {
  margin: var(--s-8) 0;
  font-weight: var(--font-medium);
  color: var(--gray-900);
}
.bookingDatesLabel {
  text-align: center;
}
.bookingDates {
  flex-shrink: 0;
  margin-bottom: var(--s-3);
}
.submitButton {
  float: left;
  text-align: center;
  height: var(--s-12);
  border-radius: 5px;
}
.buttonContainer {
  text-align: center;
}
.priceInput {
  width: 50%;
  height: var(--s-12);
  margin-bottom: var(--s-12);
  font-weight: bold;
}
.priceBreakdownContainer {
}

.offerMessage {
  margin-bottom: var(--s-5);
}
