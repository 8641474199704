@import '../../landtrust.css';

.root {
}

.breadcrumbLink {
  color: var(--gray-600);
}

.statusTitle {
  margin-bottom: var(--s-7);

  @media (--screen-md) {
    margin-top: var(--s-3);
  }
}
