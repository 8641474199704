@import '../../landtrust.css';

.root {
  font-size: var(--text-base);
  font-weight: bold;
}

.description {
  font-size: var(--text-sm);
  font-weight: normal;
  margin-bottom: var(--s-4);
  display: block;
}
