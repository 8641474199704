@import '../../landtrust.css';

.root {
  /*  */
}
.sectionPackages {
  margin-bottom: var(--s-8);

  @media (--screen-md) {
    padding: 0;
    margin-bottom: var(--s-14);
  }
}

.listingBookingPanel {
  display: none;

  @media (--screen-md) {
    display: block;
    padding: 0;
  }
}

.sectionTitle {
  padding-top: var(--s-5);
  margin-bottom: var(--s-5);
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  @media (--screen-md) {
    padding-top: 0;
  }
}

.bookingPanelPackages {
  display: flex;
  border-radius: var(--border-radius-xxxl);
  box-shadow: var(--box-shadow);
  border: solid 1px var(--gray-300);
  flex-direction: column;
  margin-bottom: var(--s-5);
  padding: var(--s-3) var(--s-5) var(--s-5) var(--s-5);

  @media (--screen-md) {
    margin-bottom: var(--s-7);
    padding: var(--s-7) var(--s-5) var(--s-2) var(--s-5);
  }
}

.viewAll {
  display: flex;
  justify-content: center;
  color: var(--red-500);

  @media (--screen-md) {
    margin: var(--s-9) 0;
  }
}

.viewAllText {
  font-weight: var(--font-bold);
}

.packageTitle {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-1);
}

.packageDetails {
  display: flex;
  flex-direction: column;
  font-size: var(--text-sm);
  margin-bottom: var(--s-4);
}

.packageDescription {
  color: var(--gray-900);
  text-decoration: underline;
}

.chooseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--s-31);
  height: var(--s-12);
  border-radius: var(--border-radius-xxxl);
  padding: var(--s-4) var(--s-2);
}

.bookingPackContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: var(--s-3);
  @media (--screen-md) {
    flex-direction: row;
    margin-bottom: var(--s-5);
  }
}

.packageInfo {
  display: flex;
}

.packageButton {
  display: flex;
  @media (--screen-md) {
    display: none;
  }
}

.packageDivider {
  display: none;

  @media (--screen-md) {
    display: block;
    width: 100%;
    height: 1px;
    margin-bottom: var(--s-3);

    border: none;
    background-color: var(--gray-200);
  }
}

.protipWrapper {
  margin: var(--s-4) 0;
}

.packageButtonMobile {
  margin-bottom: var(--s-3);
  display: flex;
  align-items: center;

  @media (--screen-md) {
    margin-bottom: 0;
  }
}
