@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.reviewRating {
  margin-bottom: var(--s-9);

  @media (--viewportMedium) {
    margin-bottom: var(--s-10);
  }
}

.reviewContent {
  flex-shrink: 0;
  margin-bottom: var(--s-4);
  text-align: left;
}

.reviewCheckboxes {
  margin-top: var(--s-3);
  margin-bottom: var(--s-7);

  @media (--viewportMedium) {
    margin-top: var(--s-5);
    margin-bottom: var(--s-12);
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 96px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
  }
}
