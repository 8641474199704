@import '../../landtrust.css';

.root {
  min-height: 360px;
  background-image: url('./images/footer.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding: var(--s-14) var(--s-8);
}

.content {
  margin-bottom: var(--s-10);
}

@media (--screen-lg) {
  .root {
    background-image: url('./images/footer@3x.jpg');
    padding: var(--s-20) var(--s-8);
    min-height: 480px;
  }

  .content {
    width: var(--w-1-2);
    margin-left: auto;
    margin-right: auto;
    padding: 0 var(--s-20);
    font-size: var(--text-2xl);
  }
}
