@import '../../landtrust.css';

.root {
  display: inline-flex;
  align-items: center;
  color: var(--gray-600);
  font-family: var(--font-base);
  font-weight: var(--font-medium);
  text-decoration: underline;
}
