@import '../../landtrust.css';

.root {
  color: var(--gray-900);
  width: 100%;
  padding: var(--s-5);

  @media (--screen-md) {
    width: auto;
    padding: var(--s-9);
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--s-5);
  align-items: flex-start;
}

.headerContainer {
  display: flex;
  flex-direction: column;
}

.headerImage {
  width: var(--s-56);

  @media (--screen-md) {
    width: var(--s-50);
  }
}

.header {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
  width: 100%;

  @media (--screen-md) {
    width: var(--s-115);
    font-size: var(--text-3xl);
    line-height: 35px;
  }
}

.content {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: var(--s-5);
}

.description {
  font-size: var(--text-sm);

  @media (--screen-md) {
    font-size: var(--text-base);
  }
}

.subHeader {
  font-size: var(--text-lg);
  font-weight: var(--font-bold);
}

.listWrapper {
  font-size: var(--text-sm);
}

.list {
  list-style: disc;
  padding-left: var(--s-5);

  & li {
    margin-bottom: var(--s-4);
  }
}
