@import '../../landtrust.css';

.root {
  color: var(--gray-900);
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--gray-300);
  margin: var(--s-4) 0;
}

.paymentMethodFormWrapper {
  margin-bottom: var(--s-5);
}

.helpWrapper {
  display: flex;
  flex-direction: column;
  margin-top: var(--s-1);
}

.helpHeader {
  font-size: var(--text-xl);
  font-weight: bold;
  margin-bottom: var(--s-2);
}

.helpText {
  & a {
    text-decoration: underline;
  }
}

.cardHeading {
  margin: 0 0 var(--s-2) 0;
  color: var(--gray-900);
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
}
