.mapContainer {
  height: calc(100vh - 237px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ddd;
  color: #fff;
  font-size: 2rem;
}
.mapListingsContainer {
  height: 237px;
  width: auto;
  overflow-x: scroll;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 1rem;
}

.toFiltersButton {
  position: fixed;
  top: calc(70vh - 80px);
  left: 50%;
  width: 200px;
  margin-left: -100px;
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  padding: 0.5rem;
  color: #fff;
  background-color: #9b9b9b;
  cursor: pointer;

  &:hover {
    background-color: #888;
  }
}

.close {
  position: absolute;
  top: 0;
  left: 0;
  height: 3em;
  padding: 1em;
  color: #999;
  text-decoration: none;

  &:hover {
    color: #000;
  }
}
