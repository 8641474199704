@import '../../landtrust.css';

.root {
  height: var(--s-11);
  @media (--screen-md) {
    height: var(--s-13);
  }
}

.lg {
  height: var(--s-12);
}
