@import '../../landtrust.css';

.filters {
  display: flex;
  align-items: center;
  gap: var(--s-3);
  width: 100%;
}

.filterButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: var(--gray-300);
  gap: 8px;
}

.filterButton:hover {
  background-color: #f3f6f3;
  color: var(--gray-900);
}

.filterButton:focus {
  outline: 2px solid var(--gray-900);
}

.filterButtonSelected {
  background-color: var(--gray-900);
  border-color: var(--gray-900);
  color: var(--white);
}

.filterButtonSelected:hover {
  background-color: var(--gray-900);
  border-color: var(--gray-900);
  color: var(--white);
}

.filterButtonSelected:focus {
  outline: none;
}

.filterButtonOpen {
  border-color: var(--gray-900);
}

.filterDropdown {
  position: relative;
  display: flex;
}
