@import '../../landtrust.css';

.packageButtonWrapper {
  margin-bottom: var(--s-8);
}

.packageButton {
  border: solid 1px var(--gray-900);
  padding: var(--s-2) var(--s-12) var(--s-2) var(--s-8);
  color: var(--gray-900);
  width: var(--s-67);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-xxxl);

  &:hover {
    background-color: var(--gray-100);
    color: var(--gray-900);
  }
}

.packagesWrapper {
  display: flex;
  flex-wrap: wrap;
}

.packageTabsWrapper {
  width: 100%;
}

.packagesTabsContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: var(--s-6);

  @media (--screen-md) {
    margin: var(--s-4) calc(-1 * var(--s-4)) 0 calc(-1 * var(--s-4));
  }
}

.buttonFlex {
  display: flex;
  align-items: center;
  font-weight: var(--font-bold);
}

.plusCircle {
  width: var(--s-7);
  height: var(--s-7);
  color: var(--gray-900);
  margin-right: var(--s-5);
}

.error {
  color: var(--red-500);
}
