@import '../../landtrust.css';

.root {
}

.heading {
  @apply --typography-heading-sans-serif;
  @apply --typography-heading-size-2xl;

  margin-bottom: var(--s-12);
}

.suggestionsHeading {
  margin-top: var(--s-12);

  @media (--viewportLarge) {
    margin-top: var(--s-16);
  }
}

.suggestions {
  margin-top: var(--s-6);
}
