@import '../../marketplace.css';
@import '../../landtrust.css';

.content {
  display: flex;
  flex-direction: column;
}

.withExtraSpace {
  margin-bottom: 16px;
}

.avatarContainer {
  position: relative;
}

.avatar {
  flex-shrink: 0;
  margin-right: var(--s-5);
  width: var(--s-20);
  height: var(--s-20);
}

.info {
  display: flex;
  flex: 1;
}

.heading {
  @apply --typography-heading-base;
  @apply --typography-heading-size-2xl;
  margin: 0;
  font-weight: var(--font-bold);
  margin-bottom: var(--s-1);

  & a {
    text-decoration: underline;
  }
}
.hostedContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hostedBy {
  margin: 0;
  line-height: var(--s-2);
  font-size: var(--text-sm);
  color: var(--gray-900);
}

.link {
  display: flex;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;
  @apply --typography-base

  @media (--screen-md) {
    margin: 18px 0 0 0;
  }
}

.mobileBio {
  @media (--screen-md) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--screen-md) {
    margin-top: 16px;
    display: block;
  }
}

.links {
  @apply --typography-base
  margin-top: var(--s-3);

  @media (--screen-md) {
    margin: var(--s-4) 0 0 0;
  }
}

.withBioMissingAbove {
  @media (--screen-md) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: column;

  @media (--screen-md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
  & a {
    @apply --font-base
    text-decoration: underline;
    font-weight: var(--font-bold);
  }
}

.editProfile {
  margin-bottom: 0;
  white-space: nowrap;
}

.contact {
  margin: 0;
  font-weight: var(--font-bold);
}

.contactHostQuestions {
  margin-top: var(--s-5);
  margin-bottom: var(--s-4);
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
}

.seeBioButton,
.seeBioMobile {
  margin-right: var(--s-4);
  white-space: nowrap;
}

.linkButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.flexContainer {
  display: flex;
  flex-direction: row;
}

.linkContainer,
.linkContainerMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.linkContainer {
  padding-bottom: var(--s-3);
}

.linkContainerMobile {
  padding: 0;
}

.fullProfileContact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--s-3) 0;

  @media (--screen-md) {
    padding: 0;
  }
}

.verifictionIcon {
  position: absolute;
  top: -10%;
  right: 0;
}

.linkDescription {
  font-family: var(--font-base);
  margin: var(--s-6) 0 var(--s-4);
  font-weight: var(--font-bold);
  color: var(--gray-900);
}

.reviewWrapper {
  display: flex;
  align-items: center;
}

.reviewLoaderWrapper {
  width: 100%;
  height: var(--s-6);
}

.reviewStarIcon {
  height: var(--s-4);
  width: var(--s-4);
  margin-right: var(--s-2);
}

.reviewAvg {
  font-weight: var(--font-bold);
  margin-right: var(--s-2);
}

.reviewTotal {
  font-size: var(--text-sm);
}

.responseRateWrapper {
  margin-top: var(--s-5);
  margin-bottom: var(--s-5);
}

.soldOut,
.bookingRange {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: var(--s-10);
  margin: var(--s-4) var(--s-1) var(--s-6) var(--s-1);
  padding: var(--s-1);
  border-radius: var(--border-radius-xl);
  font-size: var(--text-xs);
  font-weight: var(--font-bold);
}

.soldOut {
  background-color: var(--red-300);
  color: var(--red-500);
}
.bookingRange {
  background-color: var(--brown-100);
  color: var(--brown-900);
}
