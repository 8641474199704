@import '../../landtrust.css';

.root {
  width: 100%;
  color: var(--gray-900);
}

.header {
  @apply --typography-base;
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  color: var(--gray-900);
  display: flex;
  margin-top: var(--s-4);
  align-items: baseline;

  @media (--screen-md) {
    margin-top: 0;
  }
}

.headerLarge {
  composes: header;
  font-size: var(--text-xl);
}

.boxWrapper {
  position: relative;
  width: 100%;
  margin: var(--s-3) 0 var(--s-6) 0;
  padding: var(--s-1) var(--s-4) var(--s-6) var(--s-4);
  border-radius: var(--border-radius-xl);
  border: solid 1px var(--gray-300);

  @media (--screen-md) {
    margin: var(--s-3) 0 var(--s-8) 0;
  }
}

.credovaWrapper {
  display: flex;
  flex-direction: column;
  margin: var(--s-4) 0 0 0;
  background: var(--gray-200);
  border-radius: var(--border-radius);
  padding: var(--s-3);
}

.credovaWrapperLarge {
  composes: credovaWrapper;
  background: transparent;
  border-radius: 0;
  padding: 0;
}

.credovaHeading {
  @apply --typography-base;
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  color: var(--gray-900);
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--s-2);
  margin-top: 0;
  align-items: baseline;
}

.credovaHeadingSmall {
  display: none;
}

.credovaHeadingLarge {
  composes: credovaHeading;
  font-size: var(--text-base);
  margin-top: 0;
}

.credovaLogo {
  height: var(--s-4);
  position: relative;
  top: 1px;
}

.credovaLogoLarge {
  composes: credovaLogo;
  height: var(--s-5);
}

.credovaTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.credovaText {
  text-decoration: none;
  color: var(--gray-900);
  margin: var(--s-2) 0 0 0;
  background: var(--gray-200);

  :global(.crdv-text) {
    font-size: var(--text-sm);
    color: var(--gray-900);
    background: var(--gray-200);
  }
}

.credovaTextSmall {
  padding: 0;
  margin: 0;
  width: 100%;

  :global(.crdv-text) {
    font-size: var(--text-xs);
  }
}

.credovaTextLarge {
  composes: credovaText;
  background: transparent;

  :global(.crdv-text) {
    background: transparent;
    font-size: var(--text-base);
  }
}

.credovaPremade {
  text-decoration: none;
  color: var(--gray-900);
  margin: var(--s-6) 0 0 0;

  &:hover {
    text-decoration: none;
  }

  & :global(.crdv-button) {
    text-decoration: none;
    background-color: var(--credovaBlue);
    color: #ffffff;
    padding: var(--s-3);
    border: 1px solid transparent;
    font-weight: var(--font-bold);
    border-radius: var(--border-radius-xxxl);
    border-color: var(--gray-600);
    display: inline-block;
    cursor: pointer;
    font-family: var(--font-base);
    font-size: var(--text-base);
    width: 100%;
    text-align: center;

    &:disabled {
      opacity: var(--o-50);
      pointer-events: none;
    }

    &:hover {
      background-color: var(--white);
      color: var(--gray-900);
    }

    @media (--screen-md) {
      width: 60%;
    }
  }
}

.credovaButtonWrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.moreInfo {
  @apply --typography-base;
  font-size: var(--text-sm);
  color: var(--gray-900);
  text-decoration: underline;
  margin-top: var(--s-1);
}

.moreInfoLarge {
  composes: moreInfo;
  font-size: var(--text-base);
}

.smallDivider {
  width: 100%;
  height: 1px;
  margin: var(--s-3) 0;
  background-color: var(--gray-300);
}
