@import '../../landtrust.css';

.root {
}

.anchor {
  display: block;
  position: relative;
  top: calc(var(--s-20) * -1);
  visibility: hidden;
}

.agriLink {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
