@import '../../landtrust.css';

.root {
  border-radius: var(--border-radius-xl);
  box-shadow: var(--box-shadow-xl);
  padding: var(--s-8);
  background-color: var(--white);
}

.root,
.miniRoot {
  text-align: center;
}

.imageContainer {
  margin-bottom: var(--s-6);
}

.image {
  width: 108px;
  height: 108px;
  object-fit: contain;
}

.title {
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-6);
}

.cta {
  & a {
    text-decoration: underline;
  }
}

.name {
  font-size: var(--text-lg);
  font-weight: var(--font-bold);
}

.jobTitle {
  margin-bottom: var(--s-6);
}

.miniRoot {
  font-weight: var(--font-medium);
}

@media (--screen-md) {
  .rootHorizontal {
    padding: var(--s-20);
    display: flex;
    text-align: left;
  }

  .rootHorizontal .leftColumn {
    width: var(--w-1-3);
    padding-right: var(--s-8);
    border-right: 1px solid var(--gray-300);
    text-align: center;
  }

  .rootHorizontal .rightColumn {
    width: var(--w-2-3);
    padding-left: var(--s-14);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .rootHorizontal .jobTitle {
    margin-bottom: 0;
  }

  .image {
    width: 152px;
    height: 152px;
  }
}
