@import '../../landtrust.css';

.content {
  display: flex;
  flex-direction: column;
  color: var(--gray-900);
}

.header {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);
}

.avatarContainer {
  position: relative;
}

.avatar {
  flex-shrink: 0;
  margin-right: var(--s-5);
  width: var(--s-20);
  height: var(--s-20);
}

.userName {
  @apply --typography-heading-base;
  font-size: var(--text-lg);
  margin: 0;
  font-weight: var(--font-bold);
}

.hostedContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flexContainer {
  display: flex;
  flex-direction: row;
}

.verifictionIcon {
  height: var(--s-4);
  width: var(--s-4);
  margin-right: var(--s-2);
}

.reviewWrapper {
  display: flex;
  align-items: center;
}

.reviewStarIcon {
  height: var(--s-4);
  width: var(--s-4);
  margin-right: var(--s-2);
}

.reviewAvg {
  font-weight: var(--font-bold);
  margin-right: var(--s-2);
}

.reviewTotal {
  font-size: var(--text-sm);
}

.trustContainer {
  display: flex;
  flex-direction: column;
  padding: var(--s-5) 0 0 0;

  @media (--screen-md) {
    padding: var(--s-5) 0 0 0;
  }
}

.indvidualCheck {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--s-2);
}

.profileLink {
  display: flex;
  white-space: nowrap;
  text-decoration: underline;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }
}

.divider {
  height: 1px;
  background-color: var(--gray-300);
  margin: var(--s-5) 0;
}

.boxContainer {
  padding: var(--s-3) var(--s-7) var(--s-4) var(--s-3);
  border-radius: var(--border-radius);
  background-color: var(--gray-100);
  color: var(--gray-900);
  font-size: var(--text-sm);
}

.boxContainerWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--s-3);
  margin-top: var(--s-3);
}

.moreInfo {
  display: flex;
  align-items: center;
  text-decoration: underline;
  margin-top: var(--s-4);
}

.tooltipContent {
  font-size: var(--text-sm);
  min-width: var(--s-54);
  max-width: var(--s-64);
  margin-bottom: var(--s-7);
  padding: var(--s-4) var(--s-2);

  @media (--screen-md) {
    min-width: var(--s-64);
    max-width: var(--s-76);
  }
}
