@import '../../landtrust.css';

.root {
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: var(--transition-base);

  &:hover {
    text-decoration: none;
    transform: scale(1.02);
  }
}

.image {
  max-width: 100%;
  object-fit: contain;
}

.imageOverlay {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(26, 32, 44, 0.6));
}

.title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--white);
  font-size: var(--text-xl);
  font-weight: var(--font-medium);
  padding: var(--s-4);
  z-index: var(--z-10);

  @media (--screen-md) {
    padding: var(--s-6);
    font-size: var(--text-2xl);
  }
}
