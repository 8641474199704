@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap);
:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconAdd_root__1Pqp3 {
  fill: #3e704b;
  fill: #3e704b;
}

.MobileLogo_st0__YFRNB {
  fill: #ffffff;
}
.MobileLogo_st1__3OZRo {
  fill: #fd3713;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ImageCropper_modalWrapper__QS8GR {
  height: 100%;
  margin-top: 6rem;
  margin-top: 6rem
}

@media (min-width: 768px) {

.ImageCropper_modalWrapper__QS8GR {
    margin-top: 0
}
  }

.ImageCropper_app__8nluh {
  position: relative;
  width: 100%;
  max-width: 37.5rem;
  max-width: 37.5rem;
  margin: auto;
}

.ImageCropper_cropContainer__2YPXX {
  width: 100%;
  height: 21rem;
  height: 21rem;
  overflow: hidden
}

@media (min-width: 768px) {

.ImageCropper_cropContainer__2YPXX {
    margin-top: 3.25rem;
    margin-top: 3.25rem;
    height: 23.25rem;
    height: 23.25rem
}
  }

.ImageCropper_modalButtonContainer__3VhDQ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ImageCropper_saveButtonContainer__1RaEr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1.75rem;
  margin-left: 1.75rem;
  padding: 1rem 0.5rem 1rem 0.5rem;
  padding: 1rem 0.5rem 1rem 0.5rem;
  border-radius: 0.25rem;
  border-radius: 0.25rem;
}

.ImageCropper_saveButtonContainer__1RaEr:hover {
  background-color: #2d3748;
  background-color: #2d3748;
}

.ImageCropper_savePhotoButtonText__3qB1J {
  color: black;
  background-color: #fff;
  background-color: #fff;
  border-radius: 25px;
  border-radius: 25px;
  padding: 0.25rem 1.25rem 0.25rem 1.25rem;
  padding: 0.25rem 1.25rem 0.25rem 1.25rem;
  cursor: pointer;
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 600;
}

.ImageCropper_controls__21KFk {
  padding-top: 2rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-right: 1rem;
  padding-right: 1rem
}

@media (min-width: 768px) {

.ImageCropper_controls__21KFk {
    padding-left: 1rem;
    padding-left: 1rem;
    padding-right: 0
}
  }

.ImageCropper_sliderContainer__1w3nO {
  display: flex;
  flex: 1 1;
  align-items: center;
  margin-left: 0.75rem;
  margin-left: 0.75rem
}

@media (min-width: 768px) {

.ImageCropper_sliderContainer__1w3nO {
    margin-left: 2.5rem;
    margin-left: 2.5rem
}
  }

.ImageCropper_sliderLabel__pCx2M {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.125rem;
  font-size: 1.125rem;
  color: #fff;
  color: #fff;
}

.ImageCropper_slider__aTOeX {
  color: #fff !important;
  color: #fff !important;
  margin-left: 1rem;
  margin-left: 1rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* Small Avatar */

.Avatar_root__1unaK {
  /* Font is specific to this component, but defining it in marketplace.css makes it easier
   * to change font if brand look-and-feel needs such changes.
   */
  border-radius: 50%;

    /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

    /* Colors */
  background-color: #233933;
  background-color: #233933;
  color: #ffffff;
  color: #ffffff;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Avatar_root__1unaK:hover {
  text-decoration: none;
    }

.Avatar_root__1unaK {

  /* Layout */
  width: 40px;
  width: 40px;
  height: 40px;
  height: 40px;
}

.Avatar_initials__2vXtM {
  font-size: 14px;
  font-weight: 700;
  font-weight: 700;
}

.Avatar_avatarImage__w9A48 {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
}

/* Medium Avatar */

.Avatar_smallAvatar__-E3z8 {
  border-radius: 50%;

    /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

    /* Colors */
  background-color: #233933;
  background-color: #233933;
  color: #ffffff;
  color: #ffffff;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Avatar_smallAvatar__-E3z8:hover {
  text-decoration: none;
    }

.Avatar_smallAvatar__-E3z8 {

  /* Fixed dimensions */
  width: 25px;
  width: 25px;
  height: 25px;
  height: 25px;
}

.Avatar_mediumAvatar__2uzuS {
  border-radius: 50%;

    /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

    /* Colors */
  background-color: #233933;
  background-color: #233933;
  color: #ffffff;
  color: #ffffff;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Avatar_mediumAvatar__2uzuS:hover {
  text-decoration: none;
    }

.Avatar_mediumAvatar__2uzuS {

  /* Fixed dimensions */
  width: 60px;
  width: 60px;
  height: 60px;
  height: 60px;
}

.Avatar_mediumAvatar__2uzuS .Avatar_initials__2vXtM {
  font-size: 20px;
  font-weight: 600;
  font-weight: 600;
  padding-bottom: 5px;
}

/* Large Avatar */

.Avatar_largeAvatar__3utGG {
  border-radius: 50%;

    /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

    /* Colors */
  background-color: #233933;
  background-color: #233933;
  color: #ffffff;
  color: #ffffff;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Avatar_largeAvatar__3utGG:hover {
  text-decoration: none;
    }

.Avatar_largeAvatar__3utGG {

  /* Fixed dimensions */
  width: 96px;
  width: 96px;
  height: 96px;
  height: 96px;
}

.Avatar_largeAvatar__3utGG .Avatar_initials__2vXtM {
  font-size: 30px;
  font-weight: 600;
  font-weight: 600;
}

.Avatar_extraLargeAvatar__1PXLd {
  border-radius: 50%;

    /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

    /* Colors */
  background-color: #233933;
  background-color: #233933;
  color: #ffffff;
  color: #ffffff;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none
}

.Avatar_extraLargeAvatar__1PXLd:hover {
  text-decoration: none;
    }

.Avatar_extraLargeAvatar__1PXLd {

  width: 21rem;

  width: 21rem;
  height: 21rem;
  height: 21rem
}

@media (min-width: 768px) {

.Avatar_extraLargeAvatar__1PXLd {
    width: 25rem;
    width: 25rem;
    height: 25rem;
    height: 25rem;
    margin-top: 1rem;
    margin-top: 1rem
}
  }

.Avatar_extraLargeAvatar__1PXLd .Avatar_initials__2vXtM {
  font-size: 30px;
  font-weight: 600;
  font-weight: 600;
}

.Avatar_bannedUserIcon__2i1B6 {
  width: 100%;
  height: 100%;
}

.Avatar_plusIcon__IIO9u,
.Avatar_smallBadge__UjK3z,
.Avatar_mediumBadge__1oxwO,
.Avatar_largeBadge__2_S4l,
.Avatar_extraLargeBadge__1nsnC {
  position: absolute;
}

.Avatar_plusIcon__IIO9u {
  width: 1rem;
  width: 1rem;
  top: -0.5rem;
  right: -0.5rem;
}

.Avatar_smallBadge__UjK3z {
  width: 7px;
  width: 7px;
  top: -0.25rem;
  right: -0.5rem;
}

.Avatar_mediumBadge__1oxwO {
  width: 24px;
  width: 24px;
  top: -0.25rem;
  right: -0.5rem;
}

.Avatar_largeBadge__2_S4l {
  width: 38px;
  width: 38px;
  top: -0.25rem;
  right: -0.5rem;
}

.Avatar_extraLargeBadge__1nsnC {
  width: 38px;
  width: 38px;
  top: -0.25rem;
  right: -0.5rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Content is visible as modal layer */

.Modal_isOpen__kdKNq {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999

  /* scrollLayer is the .root for the Modal */
}

.Modal_isOpen__kdKNq .Modal_scrollLayer__3CoSr {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: none;
  }

.Modal_isOpen__kdKNq .Modal_container__2WZOG {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    max-width: 50rem;
    padding: 1.5rem;
    padding: 1.5rem;
    background-color: #fff;
    background-color: #fff;
    border-radius: 5px;
    border-radius: 5px;
    border-bottom: none;
    flex-grow: 0;
    min-height: auto;
    min-width: 75%;
    min-width: 75%;
    height: auto;
    overflow: hidden;
    margin: 0 1.5rem;
    margin: 0 1.5rem
  }

@media (min-width: 1024px) {

.Modal_isOpen__kdKNq .Modal_container__2WZOG {
      min-width: 40%;
      min-width: 40%
  }
    }

.Modal_content__2YQrg {
  overflow: auto;
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */

/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */

/* where desktop layout should not get any styling from Modal component.              */

.Modal_isClosed__8vJ5y {
  display: none;
}

.Modal_close__2qCAz {
  position: absolute;
  top: 1rem;
  top: 1rem;
  right: 1.5rem;
  right: 1.5rem;
  width: 2rem;
  width: 2rem;
  height: 2rem;
  height: 2rem;
  padding: 0.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  line-height: 1;
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
  z-index: 10;
}

.Modal_closeText__Yq-Vi {
}

.Modal_closeIcon__38d0W {
  height: 2rem;
  height: 2rem;
  width: 2rem;
  width: 2rem;
}

.Modal_closeLight__ZY7iT {
  background-color: #fff;
  background-color: #fff;
  color: #1a202c;
  color: #1a202c;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.UpdateAvatarForm_root__dsirv {
}

.UpdateAvatarForm_label__1GtGK {
  cursor: pointer;
}

.UpdateAvatarForm_uploadAvatarInput__1Ng_7 {
  display: none;
}

.UpdateAvatarForm_uploadAvatarWrapper__u4k5P {
  width: 100%;
}

.UpdateAvatarForm_avatarContainer__3MgqM {
  position: relative;
}

.UpdateAvatarForm_buttonContainer__zWhJw {
  position: relative;
  padding: 0.75rem 2.5rem;
  padding: 0.75rem 2.5rem;
  border: 1px solid transparent;
  font-weight: 600;
  font-weight: 600;
  border-radius: 25px;
  border-radius: 25px;
  border-color: #718096;
  border-color: #718096;
  background-color: #fff;
  background-color: #fff;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 0.875rem;
  font-size: 0.875rem;
  background-color: #3e704b;
  background-color: #3e704b;
  color: #fff;
  color: #fff;
  border-color: #3e704b;
  border-color: #3e704b
}

.UpdateAvatarForm_buttonContainer__zWhJw:disabled {
    opacity: 0.5;
    opacity: 0.5;
    pointer-events: none;
  }

.UpdateAvatarForm_buttonContainer__zWhJw:hover {
    text-decoration: none;
    background-color: #2a5e38;
    background-color: #2a5e38;
    border-color: #2a5e38;
    border-color: #2a5e38;
  }

.UpdateAvatarForm_pencilIconContainer__11FTP {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 6px;
  width: 2.25rem;
  width: 2.25rem;
  height: 2.25rem;
  height: 2.25rem;
  background-color: #3e704b;
  background-color: #3e704b;
  color: #fff;
  color: #fff;
  border: 0.25rem solid white;
  border: 0.25rem solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.UpdateAvatarForm_pencilIcon__1sqCa {
  width: 2rem;
  width: 2rem;
}

.UpdateAvatarForm_uploadingImage__r74nB {
  width: 6rem;
  width: 6rem;
  height: 6rem;
  height: 6rem;
  object-fit: cover;

  border-radius: 50%;
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;
}

.UpdateAvatarForm_uploadingImageOverlay__1CTeU {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #1a202c;
  background-color: #1a202c;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */

/* Default is 3:2 */

.UpdateAvatarForm_squareAspectRatio__3OGuW {
  padding-bottom: 100%;
}

.UpdateAvatarForm_avatarInvisible__2VoMy {
  visibility: hidden;
  top: -1000px;
  left: -1000px;
}

.UpdateAvatarForm_avatar__16TYV {
  width: 5.5rem;
  width: 5.5rem;
  height: 5.5rem;
  height: 5.5rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.UpdateAvatarForm_modalContainer__zUIDX {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 90%;
  padding: 0.5rem;
  padding: 0.5rem;
  background-color: #000;
  background-color: #000;
  border-radius: 5px;
  border-radius: 5px;
  border-bottom: none;
  flex-grow: 0;
  overflow: hidden;
  margin: 0
}

@media (min-width: 768px) {

.UpdateAvatarForm_modalContainer__zUIDX {
    padding: 1.5rem 3.5rem 0 1.5rem;
    padding: 1.5rem 3.5rem 0 1.5rem;
    height: auto;
    max-width: 50rem;
    max-width: 50rem;
    margin: 0 1.5rem;
    margin: 0 1.5rem
}
  }

.UpdateAvatarForm_closeButton__2qQIQ {
  position: absolute;
  top: 2.5rem;
  top: 2.5rem;
  right: 1.5rem;
  right: 1.5rem;
  width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  line-height: 1;
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
  z-index: 1
}

@media (min-width: 768px) {

.UpdateAvatarForm_closeButton__2qQIQ {
    top: 1rem;
    top: 1rem;
    width: 2rem;
    width: 2rem;
    height: 2rem;
    height: 2rem
}
  }

.UpdateAvatarForm_closeIcon__iJOWZ {
  color: white;
  transition: transform 0.3s ease;
  width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  height: 2.5rem;
}

.UpdateAvatarForm_closeIcon__iJOWZ:hover {
  transform: scale(1.1);
}

.UpdateAvatarForm_avatarModalContainer__2-6HS {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}

@media (min-width: 768px) {

.UpdateAvatarForm_avatarModalContainer__2-6HS {
    height: 100%;
    margin-bottom: 1rem;
    margin-bottom: 1rem
}
  }

.UpdateAvatarForm_modalButtonContainer__3lGV5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.UpdateAvatarForm_leftButtonGroup__1sXsE {
  display: flex;
  grid-gap: 4.25rem;
  gap: 4.25rem;
  grid-gap: 4.25rem;
  gap: 4.25rem
}

@media (min-width: 768px) {

.UpdateAvatarForm_leftButtonGroup__1sXsE {
    grid-gap: 0;
    gap: 0
}
  }

.UpdateAvatarForm_iconEditContainer__1SHve {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem 1rem 0;
  padding: 1rem 1rem 1rem 0;
  border-radius: 5px
}

@media (min-width: 768px) {

.UpdateAvatarForm_iconEditContainer__1SHve {
    margin-left: 1.75rem;
    margin-left: 1.75rem;
    padding-right: 0.5rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-left: 0.5rem
}
  }

.UpdateAvatarForm_iconAddPhotoContainer__34qxM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0 1rem 0.5rem;
  padding: 1rem 0 1rem 0.5rem;
  border-radius: 5px
}

@media (min-width: 768px) {

.UpdateAvatarForm_iconAddPhotoContainer__34qxM {
    margin-left: 1.75rem;
    margin-left: 1.75rem;
    padding-right: 0.5rem;
    padding-right: 0.5rem
}
  }

.UpdateAvatarForm_iconDeleteButtonContainer__1eNQX {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.5rem 1rem 0.5rem;
  padding: 1rem 0.5rem 1rem 0.5rem;
  border-radius: 5px
}

@media (min-width: 768px) {

.UpdateAvatarForm_iconDeleteButtonContainer__1eNQX {
    margin-left: 1.75rem;
    margin-left: 1.75rem
}
  }

.UpdateAvatarForm_iconEditContainer__1SHve:hover,
.UpdateAvatarForm_iconAddPhotoContainer__34qxM:hover,
.UpdateAvatarForm_iconDeleteButtonContainer__1eNQX:hover {
  background-color: #2d3748;
  background-color: #2d3748;
}

.UpdateAvatarForm_buttonIcons__3f6dV {
  font-size: 1.125rem;
  font-size: 1.125rem;
}

.UpdateAvatarForm_changePhoto__2h8nM {
  font-size: 1.125rem;
  font-size: 1.125rem;
  cursor: pointer;
  color: white;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 600;
}

.UpdateAvatarForm_editModalContainer__14uh5 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 80vh;
  background-color: #000;
  background-color: #000;
  border-radius: 5px;
  border-radius: 5px;
  border-bottom: none;
  flex-grow: 0;
  overflow: hidden;
  margin: 0
}

@media (min-width: 768px) {

.UpdateAvatarForm_editModalContainer__14uh5 {
    padding: 1.5rem 3.5rem 0 1.5rem;
    padding: 1.5rem 3.5rem 0 1.5rem;
    height: auto;
    max-width: 50rem;
    max-width: 50rem;
    margin: 0 1.5rem;
    margin: 0 1.5rem
}
  }

.UpdateAvatarForm_totalDivider__1E1Iq {
  width: 100%;
  height: 1px;
  margin: 0.75rem 0 0.75rem 0;
  margin: 0.75rem 0 0.75rem 0;
  border: none;
  background-color: #e2e8f0;
  background-color: #e2e8f0
}

@media (min-width: 768px) {

.UpdateAvatarForm_totalDivider__1E1Iq {
    margin-top: 2.5rem;
    margin-top: 2.5rem;
    margin-bottom: 0;
    margin-left: 1.75rem;
    margin-left: 1.75rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.BookingDatesForm_root__11QAQ {
  display: flex;
  flex-direction: column;
}

.BookingDatesForm_bookingDates__1d4D5 {
  flex-shrink: 0;
  margin-bottom: 10px;
}

.BookingDatesForm_priceBreakdownContainer__2AJpR {
  padding: 0 24px;
  margin-bottom: 40px
}

@media (min-width: 768px) {

.BookingDatesForm_priceBreakdownContainer__2AJpR {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px
}
  }

.BookingDatesForm_priceBreakdownTitle__3Y2X7 {
  /* Font */
  margin-top: 5px;
  margin-bottom: 14px
}

@media (min-width: 768px) {

.BookingDatesForm_priceBreakdownTitle__3Y2X7 {
    margin-top: 5px;
    margin-bottom: 26px
}
  }

.BookingDatesForm_receipt__2GToF {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.BookingDatesForm_error__1M07N {
  color: #ff0000;
  color: #ff0000;
  margin: 0 24px;
  display: inline-block;
}

.BookingDatesForm_feeError__2EzJv {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.BookingDatesForm_feeError__2EzJv {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.BookingDatesForm_feeError__2EzJv {
  color: #ff0000;
  color: #ff0000;
  margin: 5 5 5 5;
  text-align: center;
}

.BookingDatesForm_timeSlotsError__mZUZt {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.BookingDatesForm_timeSlotsError__mZUZt {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.BookingDatesForm_timeSlotsError__mZUZt {
  color: #ff0000;
  color: #ff0000;
  margin: 0 24px 12px 24px
}

@media (min-width: 768px) {

.BookingDatesForm_timeSlotsError__mZUZt {
    margin: 0 0 12px 0
}
  }

.BookingDatesForm_smallPrint__2sY3p {
  font-size: 13px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px
}

@media (min-width: 768px) {

.BookingDatesForm_smallPrint__2sY3p {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
}
    }

.BookingDatesForm_smallPrint__2sY3p {
  text-align: center;
  margin: auto 24px 0px 24px;
  flex-shrink: 0
}

@media (min-width: 768px) {

.BookingDatesForm_smallPrint__2sY3p {
    margin-top: auto;
    margin-bottom: 0px
}
  }

@media (min-width: 1024px) {

.BookingDatesForm_smallPrint__2sY3p {
    margin-top: 4px;
    margin-bottom: 0px
}
  }

.BookingDatesForm_smallPrintDark__34lV3 {
  font-size: 0.75rem;
  font-size: 0.75rem;
  text-align: center;
  margin: 0 0.75rem;
  margin: 0 0.75rem;
  flex-shrink: 0;
  font-weight: bolder
}

@media (min-width: 768px) {

.BookingDatesForm_smallPrintDark__34lV3 {
    margin: 0
}
  }

.BookingDatesForm_smallPrintAsterisk__17qHp {
  font-size: 13px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px
}

@media (min-width: 768px) {

.BookingDatesForm_smallPrintAsterisk__17qHp {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
}
    }

.BookingDatesForm_smallPrintAsterisk__17qHp {
  color: #4a5569;
  color: #4a5569;
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0
}

@media (min-width: 768px) {

.BookingDatesForm_smallPrintAsterisk__17qHp {
    margin-top: auto;
    margin-bottom: 20px
}
  }

@media (min-width: 1024px) {

.BookingDatesForm_smallPrintAsterisk__17qHp {
    margin-top: 4px;
    margin-bottom: 21px
}
  }

.BookingDatesForm_smallPrintDates__3ZcZX {
  font-size: 13px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px
}

@media (min-width: 768px) {

.BookingDatesForm_smallPrintDates__3ZcZX {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
}
    }

.BookingDatesForm_smallPrintDates__3ZcZX {
  color: #4a5569;
  color: #4a5569;
  margin: auto;
  flex-shrink: 0;
  margin-bottom: 1em
}

@media (min-width: 768px) {

.BookingDatesForm_smallPrintDates__3ZcZX {
    margin-top: auto
}
  }

.BookingDatesForm_submitButtonWrapper__3bI6Y {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%
}

@media (min-width: 768px) {

.BookingDatesForm_submitButtonWrapper__3bI6Y {
    padding: 0;
    width: 100%
}
  }

.BookingDatesForm_submitButton__2_tiM {
  width: 100%;
  font-size: 1rem;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.BookingDatesForm_cleaningFee__17rM3 {
  margin: 0 24px 38px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0
}

@media (min-width: 768px) {

.BookingDatesForm_cleaningFee__17rM3 {
    margin: 0 0 38px 0
}
  }

.BookingDatesForm_perDay__2MsNO {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.BookingDatesForm_perDay__2MsNO {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.BookingDatesForm_perDay__2MsNO {
  flex-shrink: 0;
  margin-top: 0;
  padding: 0 1.5rem;
  padding: 0 1.5rem
}

@media (min-width: 768px) {

.BookingDatesForm_perDay__2MsNO {
    padding: 0
}
  }

.BookingDatesForm_partySize__3uJpP {
  margin-bottom: 38px;
  display: block;
  flex-direction: row;
  padding: 0 1.5rem;
  padding: 0 1.5rem
}

@media (min-width: 768px) {

.BookingDatesForm_partySize__3uJpP {
    margin-bottom: 24px;
    padding: 0
}
  }

.BookingDatesForm_partySizeLabel__2Yeu0 {
  text-align: center;
}

.BookingDatesForm_bookingHelp__2JLky {
  padding: 0 1.5rem;
  padding: 0 1.5rem;
  color: #1a202c;
  color: #1a202c;
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: bold;
  font-size: 1.25rem;
  font-size: 1.25rem
}

@media (min-width: 768px) {

.BookingDatesForm_bookingHelp__2JLky {
    color: #1a202c;
    color: #1a202c;
    display: block;
    margin-top: 0.5em;
    margin-left: 0;
    font-size: 1.25rem;
    font-size: 1.25rem;
    padding: 0
}
  }

.BookingDatesForm_cleaningFeeAmount__1_JGU {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 0 0 10px
}

@media (min-width: 768px) {

.BookingDatesForm_cleaningFeeAmount__1_JGU {
    font-weight: 700;
    margin: 0 0 0 10px
}
  }

.BookingDatesForm_missingInformationModal__3frZh {
  flex-grow: 1;

    /* Create context so the close button can be positioned within it */
  position: relative;

    /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: 24px 24px 48px 24px;
  padding: 24px 24px 48px 24px;

  background-color: #ffffff;

  background-color: #ffffff;
  border-radius: 2px;
  border-radius: 2px;
  border-bottom: none;
}

@media (min-width: 768px) {

.BookingDatesForm_missingInformationModal__3frZh {
  flex-basis: 480px;
  flex-grow: 0;
      /* min-height: 568px; */
  padding: 55px 60px 55px 60px;
  padding: 55px 60px 55px 60px;
  margin-top: 12.5vh;
  margin-bottom: 12.5vh;
  border-bottom: 8px solid #3e704b;
  border-bottom: 8px solid #3e704b;
}
    }

.BookingDatesForm_missingInformationModal__3frZh {
  padding-top: 70px;
}

.BookingDatesForm_nowrap__3aYmn {
  font-size: 14px;
  text-align: center;
  margin-bottom: 15px;
}

.BookingDatesForm_calendarHelpText__3gDPF {
  white-space: nowrap;
  color: #fd3713;
  font-size: 1rem;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.BookingDatesForm_calendarProTip__3-bia {
  display: flex;
  border-radius: 10px;
  border-radius: 10px;
  padding: 1rem;
  padding: 1rem;
  background-color: #edf2f7;
  background-color: #edf2f7;
  border: 1px #e2e8f0 solid;
  border: 1px #e2e8f0 solid;
  margin: 0 1.5rem;
  margin: 0 1.5rem
}

@media (min-width: 768px) {

.BookingDatesForm_calendarProTip__3-bia {
    margin: 0;
    justify-content: space-between
}
  }

.BookingDatesForm_contentContainer__CEoHz {
  display: flex;
  align-items: center;
}

.BookingDatesForm_icon__6Xeo8 {
  display: flex;
  width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  height: 2.5rem
}

@media (min-width: 768px) {

.BookingDatesForm_icon__6Xeo8 {
    width: 2rem;
    width: 2rem;
    height: 2rem;
    height: 2rem;
    flex-shrink: 0
}
  }

.BookingDatesForm_estimateDisclaimer__1U1n8 {
  font-size: 0.75rem;
  font-size: 0.75rem;
}

.BookingDatesForm_requestContainer__26eY9 {
  display: flex;
  align-items: center;
}

.BookingDatesForm_requestInfo__97644 {
  display: flex;
  border-radius: 10px;
  border-radius: 10px;
  padding: 1rem;
  padding: 1rem;
  background-color: #edf2f7;
  background-color: #edf2f7;
  margin: auto 1.5rem 2.5rem 1.5rem;
  margin: auto 1.5rem 2.5rem 1.5rem
}

@media (min-width: 768px) {

.BookingDatesForm_requestInfo__97644 {
    margin: 1.5rem 0;
    margin: 1.5rem 0;
    justify-content: space-between
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ChangePasswordForm_root__28PHV {
}

.ChangePasswordForm_fieldLabel__beOkj {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.ChangePasswordForm_inputGroup__SEQB1 {
  position: relative;
}

.ChangePasswordForm_passwordSection__34ACr {
  margin-top: 1.5rem;
  margin-top: 1.5rem;
  position: relative;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.ChangePasswordForm_newPasswordSection__3JMj5 {
  position: relative;
}

.ChangePasswordForm_passwordIcon__2nqWi,
.ChangePasswordForm_oldPasswordIcon__3oQgj {
  position: absolute;
  right: 0.75rem;
  right: 0.75rem;
  width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
  color: #1a202c;
  color: #1a202c
}

.ChangePasswordForm_passwordIcon__2nqWi:hover, .ChangePasswordForm_oldPasswordIcon__3oQgj:hover {
    cursor: pointer;
  }

.ChangePasswordForm_passwordIcon__2nqWi {
  top: 2.75rem;
  top: 2.75rem;
}

.ChangePasswordForm_oldPasswordIcon__3oQgj {
  top: 6rem;
  top: 6rem
}

@media (min-width: 640px) {

.ChangePasswordForm_oldPasswordIcon__3oQgj {
    top: 4.75rem;
    top: 4.75rem
}
  }

.ChangePasswordForm_passwordIconError__38_pj {
  position: absolute;
  top: 2.5rem;
  top: 2.5rem;
  right: 0.75rem;
  right: 0.75rem;
  width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
  color: #1a202c;
  color: #1a202c
}

.ChangePasswordForm_passwordIconError__38_pj:hover {
    cursor: pointer;
  }

.ChangePasswordForm_confirmChangesSection__3mmAX {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  display: block;
}

.ChangePasswordForm_confirmChangesSectionVisible__2_ArE {
  display: block;
}

.ChangePasswordForm_confirmChangesTitle__3b8D3 {
  color: #1a202c;
  color: #1a202c;
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem
}

@media (min-width: 768px) {

.ChangePasswordForm_confirmChangesTitle__3b8D3 {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-bottom: 1rem;
    padding-top: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-bottom: 0.25rem
}
  }

.ChangePasswordForm_confirmChangesInfo__WFwjb {
  margin-top: 0;
  margin-bottom: 2.25rem;
  margin-bottom: 2.25rem
}

@media (min-width: 768px) {

.ChangePasswordForm_confirmChangesInfo__WFwjb {
    margin-bottom: 3rem;
    margin-bottom: 3rem
}
  }

.ChangePasswordForm_passwordChangedSuccess__229Az {
  display: block;
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  color: #4ca817;
  color: #4ca817;
  font-weight: 600;
  font-weight: 600;
}

.ChangePasswordForm_bottomWrapper__3kabc {
  margin-top: 2.75rem;
  margin-top: 2.75rem
}

@media (min-width: 768px) {

.ChangePasswordForm_bottomWrapper__3kabc {
    margin-top: 6rem;
    margin-top: 6rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ChangeCommissionForm_sectionContainer__Y1LBX {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.ChangeCommissionForm_fieldLabel__216QT {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.ChangeCommissionForm_fieldLabelDescription__2Y-PP {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

@media (min-width: 768px) {

.ChangeCommissionForm_selectContainer__Lpg3o {
}
  }

.ChangeCommissionForm_selectField__2Fwqh {
  margin-bottom: 3rem;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {

.ChangeCommissionForm_buttonContainer__13oRh {
    width: 75%;
    width: 75%
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.ContactDetailsForm_root__25zCC {
}

.ContactDetailsForm_contactDetailsSection__1jUVD {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 6px
}

@media (min-width: 768px) {

.ContactDetailsForm_contactDetailsSection__1jUVD {
    padding-top: 0
}
  }

.ContactDetailsForm_name__SL15Q {
  @apply --fieldBase;

  display: flex;
  justify-content: space-between;
}

.ContactDetailsForm_firstNameRoot__2Bz1T {
  flex-basis: 50%;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.ContactDetailsForm_lastNameRoot__3pzlT {
  flex-basis: 50%;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
}

.ContactDetailsForm_phone__1LXQf {
  margin-top: 14px;
}

.ContactDetailsForm_secondaryPhone__117w- {
  margin-top: 14px;
}

.ContactDetailsForm_email__2xQC9 {
  margin-top: 14px;
}

.ContactDetailsForm_rolesSection__2GLma, .ContactDetailsForm_smsConsentSection__18tGX {
  margin-top: 1.75rem;
  margin-top: 1.75rem;
  font-weight: 600;
  font-weight: 600;
}

.ContactDetailsForm_rolesSportsman__16wZc {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.ContactDetailsForm_fieldLabel__GmNWQ {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.ContactDetailsForm_inputGroup__1Q61z {
  position: relative;
}

.ContactDetailsForm_passwordSection__sLJpE {
  margin-top: 1.5rem;
  margin-top: 1.5rem;
  position: relative;
  margin-bottom: 16px;
}

.ContactDetailsForm_newPasswordSection__1pK3t {
  position: relative;
}

.ContactDetailsForm_passwordIcon__BINfh {
  position: absolute;
  top: 42px;
  right: 0.75rem;
  right: 0.75rem;
  width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
  color: #1a202c;
  color: #1a202c
}

.ContactDetailsForm_passwordIcon__BINfh:hover {
    cursor: pointer;
  }

.ContactDetailsForm_passwordIconError__ZMqcZ {
  position: absolute;
  top: 42px;
  right: 0.75rem;
  right: 0.75rem;
  width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
  color: #1a202c;
  color: #1a202c
}

.ContactDetailsForm_passwordIconError__ZMqcZ:hover {
    cursor: pointer;
  }

.ContactDetailsForm_confirmChangesSection__3649L {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
  padding: 0.75rem;
  display: none;
  background-color: #f7fafc;
  background-color: #f7fafc;
  border-radius: 10px;
  border-radius: 10px;
}

.ContactDetailsForm_confirmChangesSectionVisible__3-CXi {
  display: block;
}

.ContactDetailsForm_confirmChangesTitle__29Uy6 {
  color: #1a202c;
  color: #1a202c;
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem
}

@media (min-width: 768px) {

.ContactDetailsForm_confirmChangesTitle__29Uy6 {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-bottom: 1rem;
    padding-top: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-bottom: 0.25rem
}
  }

.ContactDetailsForm_confirmChangesInfo__kHAAD {
  margin-top: 0;
  margin-bottom: 2.25rem;
  margin-bottom: 2.25rem
}

@media (min-width: 768px) {

.ContactDetailsForm_confirmChangesInfo__kHAAD {
    margin-bottom: 3rem;
    margin-bottom: 3rem
}
  }

.ContactDetailsForm_emailVerified__2yLr8 {
  display: block;
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  color: #4ca817;
  color: #4ca817;
  font-weight: 600;
  font-weight: 600;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.ContactDetailsForm_emailUnverified__2A5xm {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.ContactDetailsForm_emailUnverified__2A5xm {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.ContactDetailsForm_emailUnverified__2A5xm {
  color: #2ecc71;
  color: #2ecc71;

  display: inline-block;
  padding-left: 18px;
  background-repeat: no-repeat;
  background-position: top 7px left;

  margin-top: 11px;
  margin-bottom: 0;
}

@media (min-width: 768px) {

.ContactDetailsForm_emailUnverified__2A5xm {
  margin-top: 14px;
  margin-bottom: 1px;
}
    }

.ContactDetailsForm_emailUnverified__2A5xm {
  color: #a1aec1;
  color: #a1aec1;
  padding-left: 0;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.ContactDetailsForm_pendingEmailUnverified__1e5wy {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.ContactDetailsForm_pendingEmailUnverified__1e5wy {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.ContactDetailsForm_pendingEmailUnverified__1e5wy {
  color: #2ecc71;
  color: #2ecc71;

  display: inline-block;
  padding-left: 18px;
  background-repeat: no-repeat;
  background-position: top 7px left;

  margin-top: 11px;
  margin-bottom: 0;
}

@media (min-width: 768px) {

.ContactDetailsForm_pendingEmailUnverified__1e5wy {
  margin-top: 14px;
  margin-bottom: 1px;
}
    }

.ContactDetailsForm_pendingEmailUnverified__1e5wy {
  padding-left: 0;
  color: #ff0000;
  color: #ff0000;
  width: 100%;
}

.ContactDetailsForm_passwordChangedSuccess__24CMP {
  display: block;
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  color: #4ca817;
  color: #4ca817;
  font-weight: 600;
  font-weight: 600;
}

.ContactDetailsForm_checkInbox__npPmW {
  color: #4a5569;
  color: #4a5569;
}

.ContactDetailsForm_emailStyle__11D2x {
  font-weight: 700;
  font-weight: 700;
  word-wrap: break-word;
}

.ContactDetailsForm_helperLink__3RnZD {
  color: #4a5569;
  color: #4a5569;
  border-bottom: solid 1px #4a5569;
  border-bottom: solid 1px #4a5569;
  line-height: 20px;
  margin: 0;
  cursor: pointer
}

@media (min-width: 768px) {

.ContactDetailsForm_helperLink__3RnZD {
    margin: 0
}
  }

.ContactDetailsForm_tooMany__hln2z {
  color: #a1aec1;
  color: #a1aec1;
}

.ContactDetailsForm_emailSent__1-YS_ {
  color: #4a5569;
  color: #4a5569;
}

.ContactDetailsForm_bottomWrapper__2b-IA {
  margin-top: 46px
}

@media (min-width: 768px) {

.ContactDetailsForm_bottomWrapper__2b-IA {
    margin-top: 96px
}
  }

.ContactDetailsForm_error__1x6x7 {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.ContactDetailsForm_error__1x6x7 {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.ContactDetailsForm_error__1x6x7 {
  color: #ff0000;
  color: #ff0000;
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 0;
}

.ContactDetailsForm_referralSection__2LO8o {
  margin-top: 1.75rem;
  margin-top: 1.75rem;
}

.ContactDetailsForm_referralOther__2eX4E {
  margin-top: 1.75rem;
  margin-top: 1.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IdpSignupForm_root__Ez-y2 {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  height: 100%;

  justify-content: flex-start
}

@media (min-width: 768px) {

.IdpSignupForm_root__Ez-y2 {
    justify-content: space-between
}
  }

.IdpSignupForm_name__K1GjF {
  display: none;
  justify-content: space-between;
  margin-top: 24px
}

@media (min-width: 768px) {

.IdpSignupForm_name__K1GjF {
    margin-top: 32px
}
  }

.IdpSignupForm_firstNameRoot__1EMbi {
  width: calc(34% - 9px);
}

.IdpSignupForm_lastNameRoot__3uKl1 {
  width: calc(66% - 9px);
}

.IdpSignupForm_password__12Hy6 {
    /* Leave space between the input and the button below when the
    viewport height is small */
  margin-top: 24px;
}

@media (min-width: 768px) {

.IdpSignupForm_password__12Hy6 {
  margin-top: 32px;
}
    }

.IdpSignupForm_bottomWrapper__3D_m0 {
  margin-top: 3.5rem;
  margin-top: 3.5rem;
}

.IdpSignupForm_bottomWrapperText__1ZlZw {
  text-align: center;
}

.IdpSignupForm_termsText__dktmi {
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #1a202c;
  color: #1a202c;
}

.IdpSignupForm_termsLink__VCTMu {
  text-decoration: underline
}

.IdpSignupForm_termsLink__VCTMu:hover {
    cursor: pointer;
  }

.IdpSignupForm_fieldLabel__icj4Q {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.IdpSignupForm_formSection__27hNu {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.IdpSignupForm_rolesSection__18glw {
  margin-top: 1.75rem;
  margin-top: 1.75rem;
  font-weight: 600;
  font-weight: 600;
}

.IdpSignupForm_rolesSportsman__2iEoU {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.IdpSignupForm_referralSection__3OQ70 {
  margin-top: 1.75rem;
  margin-top: 1.75rem;
}

.IdpSignupForm_referralOther__1JvHs {
  margin-top: 1.75rem;
  margin-top: 1.75rem;
}

.IdpSignupForm_signupWithIdpTitle__rYGwP {
  font-size: 1.5rem;
  font-size: 1.5rem
}

@media (min-width: 768px) {

.IdpSignupForm_signupWithIdpTitle__rYGwP {
  font-size: 2.25rem;
  font-size: 2.25rem
}
    }

.IdpSignupForm_signupWithIdpTitle__rYGwP {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1rem;
  padding-top: 1rem;
  color: #1a202c;
  color: #1a202c
}

@media (min-width: 768px) {

.IdpSignupForm_signupWithIdpTitle__rYGwP {
    margin-top: 6px
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.ManageAvailabilityCalendar_root__QewKp {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.ManageAvailabilityCalendar_root__QewKp {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.ManageAvailabilityCalendar_root__QewKp {
  margin-top: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  z-index: 0
}

.ManageAvailabilityCalendar_root__QewKp .CalendarMonth_caption {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.25rem;
  font-size: 1.25rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px
  }

@media (min-width: 768px) {

.ManageAvailabilityCalendar_root__QewKp .CalendarMonth_caption {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px
  }
    }

.ManageAvailabilityCalendar_root__QewKp .CalendarMonth_caption {
    font-weight: 500;
    font-weight: 500;
    padding-top: 18px;
    padding-bottom: 55px;
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0
  }

.ManageAvailabilityCalendar_root__QewKp .CalendarMonth_caption strong {
      font-weight: 500;
    }

.ManageAvailabilityCalendar_root__QewKp .DayPicker {
    margin: 0 auto;
    box-shadow: none;
  }

.ManageAvailabilityCalendar_root__QewKp .DayPicker__horizontal {
    background-color: transparent;
    margin-left: -18px;
  }

.ManageAvailabilityCalendar_root__QewKp .DayPicker_weekHeader {
    top: 80px;
    color: #1a202c;
    color: #1a202c;
    font-weight: 600
  }

.ManageAvailabilityCalendar_root__QewKp .DayPicker_weekHeader small {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
    }

@media (min-width: 768px) {

.ManageAvailabilityCalendar_root__QewKp .DayPicker_weekHeader small {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
    }
    }

.ManageAvailabilityCalendar_root__QewKp .DayPicker_weekHeader_li {
    text-align: left;
  }

.ManageAvailabilityCalendar_root__QewKp .DayPickerNavigation__horizontal {
    margin-left: 18px;
    margin-right: 18px;
    position: relative;
  }

.ManageAvailabilityCalendar_root__QewKp .DayPickerNavigation_button__horizontal {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    position: absolute;
    top: 13px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #e2e8f0;
    border: solid 1px #e2e8f0;
    background-color: #ffffff;
    background-color: #ffffff
  }

.ManageAvailabilityCalendar_root__QewKp .DayPickerNavigation_button__horizontal:hover {
      background-color: #edf2f7;
      background-color: #edf2f7
    }

.ManageAvailabilityCalendar_root__QewKp .DayPickerNavigation_button__horizontal:hover svg {
        fill: #1a202c;
        fill: #1a202c;
      }

.ManageAvailabilityCalendar_root__QewKp .DayPickerNavigation_button__horizontal:focus {
      outline: none;
      background-color: #edf2f7;
      background-color: #edf2f7
    }

.ManageAvailabilityCalendar_root__QewKp .DayPickerNavigation_button__horizontal:focus svg {
        fill: #1a202c;
        fill: #1a202c;
      }

.ManageAvailabilityCalendar_root__QewKp .DayPickerNavigation_button__horizontal:last-child {
      right: 0px;
      left: auto;
    }

.ManageAvailabilityCalendar_root__QewKp .CalendarMonth_table {
    border: 1px solid #a1aec1;
    border: 1px solid #a1aec1;
  }

.ManageAvailabilityCalendar_root__QewKp .CalendarMonthGrid {
    background-color: transparent;
  }

.ManageAvailabilityCalendar_root__QewKp .CalendarMonth {
    background-color: transparent;
  }

.ManageAvailabilityCalendar_root__QewKp .CalendarDay__default {
    border: 1px solid #a1aec1;
    border: 1px solid #a1aec1;
    padding: 0
  }

.ManageAvailabilityCalendar_root__QewKp .CalendarDay__default:hover {
      border: 1px solid #a1aec1;
      border: 1px solid #a1aec1;
      background-color: #a1aec1;
      background-color: #a1aec1;
    }

.ManageAvailabilityCalendar_root__QewKp .CalendarDay__selected {
    color: #1a202c;
    color: #1a202c;
    background-color: #e2e8f0;
    background-color: #e2e8f0;
  }

.ManageAvailabilityCalendar_root__QewKp .CalendarDay__blocked_out_of_range {
    background-color: #e2e8f0;
    background-color: #e2e8f0;
  }

.ManageAvailabilityCalendar_root__QewKp .DayPickerKeyboardShortcuts_show__bottomRight {
    right: -20px;
    right: 19px;
    bottom: -24px;
  }

.ManageAvailabilityCalendar_icon__OJVYE {
  width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  height: 1.5rem;
  color: #3e704b;
  color: #3e704b;
}

.ManageAvailabilityCalendar_dayWrapper__30E7t {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
}

.ManageAvailabilityCalendar_day__2PZ_B {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px
}

.ManageAvailabilityCalendar_day__2PZ_B:hover {
    background-color: #edf2f7;
    background-color: #edf2f7;
  }

.ManageAvailabilityCalendar_dayNumber__3SqnM {
  font-size: 14px;
  line-height: 14px;
}

.ManageAvailabilityCalendar_default__2gmF- {
  background-color: #fff;
  background-color: #fff;
}

.ManageAvailabilityCalendar_outsideRange__22-Rh {
  cursor: not-allowed;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  position: relative
}

.ManageAvailabilityCalendar_outsideRange__22-Rh:hover {
    background-color: #e2e8f0;
    background-color: #e2e8f0;
  }

.ManageAvailabilityCalendar_outsideRange__22-Rh:after {
    background: linear-gradient(
      to top left,
      #e2e8f0 calc(50% - 1px),
      #718096,
      #e2e8f0 calc(50% + 1px)
    );
    background: linear-gradient(
      to top left,
      #e2e8f0 calc(50% - 1px),
      #718096,
      #e2e8f0 calc(50% + 1px)
    );
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
    content: '';
    position: absolute;
    z-index: 1;
  }

.ManageAvailabilityCalendar_outsideRange__22-Rh .ManageAvailabilityCalendar_dayNumber__3SqnM {
    color: #1a202c;
    color: #1a202c;
    z-index: 2;
  }

.ManageAvailabilityCalendar_today__1FpyS {
  background-color: #fff;
  background-color: #fff
}

.ManageAvailabilityCalendar_today__1FpyS .ManageAvailabilityCalendar_dayNumber__3SqnM {
    position: relative;
    text-decoration: none

    /* underline */
  }

.ManageAvailabilityCalendar_today__1FpyS .ManageAvailabilityCalendar_dayNumber__3SqnM:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 100%;
      right: 0;
      bottom: -4px;
      left: 0;
      margin: 0 auto;
      background-color: #4a5569;
      background-color: #4a5569;
    }

.ManageAvailabilityCalendar_blocked__2H0vm {
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  position: relative
}

.ManageAvailabilityCalendar_blocked__2H0vm:hover {
    background-color: #e2e8f0;
    background-color: #e2e8f0
  }

.ManageAvailabilityCalendar_blocked__2H0vm:hover:after {
      background: linear-gradient(
        to top left,
        #edf2f7 calc(50% - 1px),
        #718096,
        #edf2f7 calc(50% + 1px)
      );
      background: linear-gradient(
        to top left,
        #edf2f7 calc(50% - 1px),
        #718096,
        #edf2f7 calc(50% + 1px)
      );
    }

.ManageAvailabilityCalendar_blocked__2H0vm:after {
    background: linear-gradient(
      to top left,
      #e2e8f0 calc(50% - 1px),
      #718096,
      #e2e8f0 calc(50% + 1px)
    );
    background: linear-gradient(
      to top left,
      #e2e8f0 calc(50% - 1px),
      #718096,
      #e2e8f0 calc(50% + 1px)
    );
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
    content: '';
    position: absolute;
    z-index: 1;
  }

.ManageAvailabilityCalendar_reserved__236Me {
  background-color: #233933;
  background-color: #233933
}

.ManageAvailabilityCalendar_reserved__236Me:hover {
    background-color: #233933;
    background-color: #233933;
  }

.ManageAvailabilityCalendar_inProgress__33QaC {
  width: 12px;
  height: 12px;
  stroke: #4a5569;
  stroke: #4a5569;
  stroke-width: 3px;
}

.ManageAvailabilityCalendar_exceptionError__3T0eH {
  opacity: 1;

  /* Animation */
  animation-name: ManageAvailabilityCalendar_errored__66-gS;
  animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

@keyframes ManageAvailabilityCalendar_errored__66-gS {
  30%,
  70% {
    background-color: #f59a89;
    background-color: #f59a89;
  }
}

.ManageAvailabilityCalendar_monthElement__1bMCz {
  display: flex;
  position: relative;
}

.ManageAvailabilityCalendar_monthString__41ep4 {
  font-weight: 600;
  font-weight: 600
}

.ManageAvailabilityCalendar_monthString__41ep4:first-letter {
    text-transform: uppercase;
  }

.ManageAvailabilityCalendar_monthInProgress__3c-yh {
  width: 12px;
  height: 12px;
  margin-top: 7px;
  margin-left: 8px;

  stroke: #3e704b;

  stroke: #3e704b;
  stroke-width: 4px;
}

.ManageAvailabilityCalendar_error__2vgmM {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.ManageAvailabilityCalendar_error__2vgmM {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.ManageAvailabilityCalendar_error__2vgmM {
  color: #ff0000;
  color: #ff0000;
  margin: 6px 0 24px 0;
}

.ManageAvailabilityCalendar_rootNextMonthIcon__1WLxN,
.ManageAvailabilityCalendar_rootPreviousMonthIcon__2R67X {
  stroke: #4a5569;
  stroke: #4a5569;
  fill: #4a5569;
  fill: #4a5569;
}

.ManageAvailabilityCalendar_legendWrapper__2ZJxi {
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.ManageAvailabilityCalendar_legendWrapper__2ZJxi {
    flex-direction: row
}
  }

.ManageAvailabilityCalendar_legend__2l-x1 {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.ManageAvailabilityCalendar_legendRow__11v66 {
  display: flex;
  flex-grow: row;
  line-height: 24px;
  margin-right: 18px;
  margin-bottom: 6px;
}

.ManageAvailabilityCalendar_legendColor__3HdqP {
  width: 20px;
  height: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  margin-top: 2px;
  margin-right: 8px;
}

.ManageAvailabilityCalendar_legendAvailableColor__1YhmB {
  background-color: #ffffff;
  background-color: #ffffff;
  border: solid 1px #a1aec1;
  border: solid 1px #a1aec1;
}

.ManageAvailabilityCalendar_legendReservedColor__1xrmF {
  background-color: #233933;
  background-color: #233933;
  border: solid 1px #a1aec1;
  border: solid 1px #a1aec1;
}

.ManageAvailabilityCalendar_legendBlockedColor__yOtiT {
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  border: solid 1px #a1aec1;
  border: solid 1px #a1aec1;
}

.ManageAvailabilityCalendar_legendText__1usvr {
  font-size: 0.875rem;
  font-size: 0.875rem;
  white-space: nowrap;
  margin: 0
}

@media (min-width: 768px) {

.ManageAvailabilityCalendar_legendText__1usvr {
    font-size: 1rem;
    font-size: 1rem
}
  }

.ManageAvailabilityCalendar_protipWrapper__3szVI {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-top: 2rem
}

@media (min-width: 768px) {

.ManageAvailabilityCalendar_protipWrapper__3szVI {
    font-size: 1rem;
    font-size: 1rem;
    margin-top: 0;
    justify-content: flex-start
}
  }

@media (min-width: 550px) {
  /* day aka table cell content should have bigger paddings when there's more space */
  .ManageAvailabilityCalendar_day__2PZ_B {
    padding: 8px;
  }
  .ManageAvailabilityCalendar_dayNumber__3SqnM {
    font-size: 16px;
    line-height: 16px;
  }
  .ManageAvailabilityCalendar_legend__2l-x1 {
    justify-content: flex-start;
  }
    .ManageAvailabilityCalendar_blocked__2H0vm:hover {
      background-color: #edf2f7;
      background-color: #edf2f7;
    }
}

/* Standard break points */

@media (min-width: 768px) {
  .ManageAvailabilityCalendar_root__QewKp {
    margin-top: 0;
    margin-bottom: 0
  }

    .ManageAvailabilityCalendar_root__QewKp .CalendarMonth_caption {
      font-size: 24px;
      padding-bottom: 40px;
    }
    .ManageAvailabilityCalendar_root__QewKp .DayPicker_weekHeader {
      top: 67px;
    }

  .ManageAvailabilityCalendar_inProgress__33QaC {
    width: 16px;
    height: 16px;
  }

  .ManageAvailabilityCalendar_monthInProgress__3c-yh {
    width: 16px;
    height: 16px;
    margin-top: 5px;
  }
  .ManageAvailabilityCalendar_error__2vgmM {
    margin: 8px 0 24px 0;
  }

  .ManageAvailabilityCalendar_legendRow__11v66 {
    margin-right: 24px;
    margin-bottom: 8px;
  }
  .ManageAvailabilityCalendar_legendText__1usvr {
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .ManageAvailabilityCalendar_legend__2l-x1 {
    margin: 0;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.EditListingAvailabilityForm_root__2HR9c {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding-top: 1px;
}

.EditListingAvailabilityForm_title__3Hrpi {
  margin-bottom: 24px;
}

.EditListingAvailabilityForm_error__1qwr- {
  color: #ff0000;
  color: #ff0000;
}

.EditListingAvailabilityForm_calendarWrapper__1vDSB {
  flex-grow: 1;
  position: relative;
  width: 100%;
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .EditListingAvailabilityForm_root__2HR9c {
    padding-top: 2px;
    margin-top: -16px;
  }
  .EditListingAvailabilityForm_title__3Hrpi {
    margin-bottom: 32px;
  }
}

@media (min-width: 1024px) {
  .EditListingAvailabilityForm_calendarWrapper__1vDSB {
    flex-grow: 0;
    margin-bottom: 24px;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.Tooltip_root__2Pf8z {
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  transition: opacity 0.3s;
  z-index: 60;
  z-index: 60;
  width: auto
}

@media (min-width: 768px) {

.Tooltip_root__2Pf8z {
    width: auto;
    border-radius: 5px;
    border-radius: 5px
}
  }

.Tooltip_tooltipArrow__Jsvi8 {
  height: 1rem;
  height: 1rem;
  position: absolute;
  width: 1rem;
  width: 1rem;
}

.Tooltip_tooltipArrow__Jsvi8::before {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.Tooltip_tooltipArrow__Jsvi8::after {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}

.Tooltip_tooltipArrow__Jsvi8[data-placement*='bottom'] {
  height: 1rem;
  height: 1rem;
  left: 0;
  margin-top: calc(-1 * 0.5rem);
  margin-top: calc(-1 * 0.5rem);
  top: 0;
  width: 1rem;
  width: 1rem;
}

.Tooltip_tooltipArrow__Jsvi8[data-placement*='bottom']::before {
  border-color: transparent transparent transparent transparent;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  position: absolute;
  top: -1px;
}

.Tooltip_tooltipArrow__Jsvi8[data-placement*='bottom']::after {
  border-color: transparent transparent white transparent;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.Tooltip_tooltipArrow__Jsvi8[data-placement*='top'] {
  bottom: 0;
  height: 1rem;
  height: 1rem;
  left: 0;
  margin-bottom: calc(-1 * 1rem);
  margin-bottom: calc(-1 * 1rem);
  width: 1rem;
  width: 1rem;
}

.Tooltip_tooltipArrow__Jsvi8[data-placement*='top']::before {
  border-color: transparent transparent transparent transparent;
  border-width: 0.5rem 0.5rem 0 0.5rem;
  border-width: 0.5rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}

.Tooltip_tooltipArrow__Jsvi8[data-placement*='top']::after {
  border-color: white transparent transparent transparent;
  border-width: 0.5rem 0.5rem 0 0.5rem;
  border-width: 0.5rem 0.5rem 0 0.5rem;
}

.Tooltip_tooltipArrow__Jsvi8[data-placement*='right'] {
  height: 1rem;
  height: 1rem;
  left: 0;
  margin-left: calc(-1 * 0.75rem);
  margin-left: calc(-1 * 0.75rem);
  width: 1rem;
  width: 1rem;
}

.Tooltip_tooltipArrow__Jsvi8[data-placement*='right']::before {
  border-color: transparent transparent transparent transparent;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.Tooltip_tooltipArrow__Jsvi8[data-placement*='right']::after {
  border-color: transparent white transparent transparent;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 6px;
  top: 0;
}

.Tooltip_tooltipArrow__Jsvi8[data-placement*='left'] {
  height: 1rem;
  height: 1rem;
  margin-right: calc(-1 * 0.75rem);
  margin-right: calc(-1 * 0.75rem);
  right: 0;
  width: 1rem;
  width: 1rem;
}

.Tooltip_tooltipArrow__Jsvi8[data-placement*='left']::before {
  border-color: transparent transparent transparent transparent;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.Tooltip_tooltipArrow__Jsvi8[data-placement*='left']::after {
  border-color: transparent transparent transparent white;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  left: 3px;
  top: 0;
}

.Tooltip_tooltipClose__CAOse {
  position: absolute;
  right: 1rem;
  right: 1rem;
  top: 1rem;
  top: 1rem;
  cursor: pointer;
  width: 2rem;
  width: 2rem;
  height: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  background: none;
  border: none;
  color: inherit;
  outline: none;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.MenuTooltip_tooltipContent__1UiPJ {
  min-width: 5.5rem;
  min-width: 5.5rem;
}

.MenuTooltip_tooltipLink__1o0tv {
  position: relative;
  bottom: -var(--s-2);
  left: 0;
  color: #1a202c;
  color: #1a202c;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PackageBadges_root__1KT-E {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem;
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem;
  grid-column-gap: 0.5rem;
  column-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  column-gap: 0.5rem;
}

.PackageBadges_badgeWrapper__3_0FC {
  display: flex;
  align-items: center;
  padding: 0.25rem 0;
  padding: 0.25rem 0;
}

.PackageBadges_icon__15D5z {
  height: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  width: 1.5rem;
}

.PackageBadges_infoSpan__1onKV {
  display: block;
  font-size: 0.75rem;
  font-size: 0.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PackageCardPreview_package__gQtKY {
  position: relative;
  border: solid 1px #e2e8f0;
  border: solid 1px #e2e8f0;
  padding: 1.25rem 1rem 1.75rem 1.25rem;
  padding: 1.25rem 1rem 1.75rem 1.25rem;
  color: #1a202c;
  color: #1a202c;
  width: 22rem;
  width: 22rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border-radius: 10px;
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem
}

@media (min-width: 768px) {

.PackageCardPreview_package__gQtKY {
    margin: 1rem;
    margin: 1rem
}
  }

.PackageCardPreview_packageLoadingWrapper__2o7XR {
  display: flex;
  flex-direction: column;
  max-height: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.PackageCardPreview_fetchingStatus__3dy5N {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  position: relative;
  bottom: 1.25rem;
  bottom: 1.25rem;
}

.PackageCardPreview_menuIconWrapper__1nWE2 {
  position: absolute;
  top: 0.25rem;
  top: 0.25rem;
  right: 1.25rem;
  right: 1.25rem;
  width: 1.5rem;
  width: 1.5rem;
}

.PackageCardPreview_menuItemsWrapper__O4nDi {
  font-weight: 600;
  font-weight: 600
}

.PackageCardPreview_menuItemsWrapper__O4nDi > li {
    margin-bottom: 0.25rem;
    margin-bottom: 0.25rem;
  }

.PackageCardPreview_menuItemLink__3PXf2 {
  text-decoration: none;
  color: #1a202c;
  color: #1a202c;
}

.PackageCardPreview_packageTitle__1sFS7 {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.PackageCardPreview_packageTitleEmptyState__2knEp {
  background-color: #edf2f7;
  background-color: #edf2f7;
  border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  height: 2rem;
  height: 2rem;
  width: 85%;
}

.PackageCardPreview_packageDaysEmptyState__1-Zs_ {
  background-color: #edf2f7;
  background-color: #edf2f7;
  border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  height: 1.25rem;
  height: 1.25rem;
  width: 65%;
}

.PackageCardPreview_packagePriceEmptyState__2PR7T {
  background-color: #edf2f7;
  background-color: #edf2f7;
  border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  height: 1.25rem;
  height: 1.25rem;
  width: 35%;
}

.PackageCardPreview_packagePotenatialEarningsEmptyState__11l1p {
  background-color: #edf2f7;
  background-color: #edf2f7;
  border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  height: 1.25rem;
  height: 1.25rem;
  width: 50%;
}

.PackageCardPreview_packagePotentialEarnings__2IjaZ {
  margin-top: 1.25rem;
  margin-top: 1.25rem;
}

.PackageCardPreview_packageDetails__12Rak {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  font-size: 0.875rem;
  width: 100%;
}

.PackageCardPreview_packageDescription__vOZwQ {
  display: block;
  color: #1a202c;
  color: #1a202c;
  text-decoration: underline;
  margin-top: 1.5rem;
  margin-top: 1.5rem;
}

.PackageCardPreview_chooseButton__dvAu0 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.75rem;
  width: 7.75rem;
  height: 3rem;
  height: 3rem;
  border-radius: 25px;
  border-radius: 25px;
  padding: 1rem 0.5rem;
  padding: 1rem 0.5rem;
}

.PackageCardPreview_bookingPackContainer__1rPYO {
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

@media (min-width: 768px) {

.PackageCardPreview_bookingPackContainer__1rPYO {
    flex-direction: row
}
  }

.PackageCardPreview_packageInfo__3j4-S {
  display: flex;
}

.PackageCardPreview_packageButton__ySJeG {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center
}

@media (min-width: 768px) {

.PackageCardPreview_packageButton__ySJeG {
    margin-bottom: 0
}
  }

.PackageCardPreview_protipWrapper__m5szB {
  margin: 1rem 0;
  margin: 1rem 0;
}

.PackageCardPreview_description__1x6V0 {
  display: flex;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.PackageCardPreview_hasLodging__2-KjQ {
  color: #1a202c;
  color: #1a202c;
  line-height: 1.25;
  line-height: 1.25;
  padding-top: 0.5rem;
  padding-top: 0.5rem;
}

.PackageCardPreview_packagePotenatialEarnings__5T_Ox {
  border-top: 1px solid #e2e8f0;
  border-top: 1px solid #e2e8f0;
  padding-top: 1.25rem;
  padding-top: 1.25rem;
  margin-top: 1.25rem;
  margin-top: 1.25rem;
}

.PackageCardPreview_badgesWrapper__14yl_ {
  margin: 1.25rem 0 1.5rem 0;
  margin: 1.25rem 0 1.5rem 0;
}

.PackageCardPreview_bookingPackageWrapper__tnwW0 {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {

.PackageCardPreview_mobileCalendar__3lMSM {
    display: none
}
  }

.PackageCardPreview_desktopCalendar__2DXCX {
  display: none
}

@media (min-width: 768px) {

.PackageCardPreview_desktopCalendar__2DXCX {
    display: flex
}
  }

.PackageCardPreview_packageModalBtn__3xtXb {
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-weight: 600;
  border: none;
  padding: 0;
  background: none;
  text-decoration: underline;
  cursor: pointer;
}

.PackageCardPreview_packageModalContainer__7F8pg {
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  padding: 1.5rem;
  background-color: #fff;
  background-color: #fff;
  border-bottom: none;
  flex-grow: 0;
  min-height: auto;
  border-radius: 0;
  overflow: hidden
}

@media (min-width: 768px) {

.PackageCardPreview_packageModalContainer__7F8pg {
  margin: auto;
  width: 50%;
  width: 50%;
  height: auto;
  border-radius: 5px;
  border-radius: 5px
}
}

.PackageCardPreview_packageModalContent__9QnEg {
  padding: 0.75rem;
  padding: 0.75rem;
}

.PackageCardPreview_packageModalHeader__36XLZ {
  font-weight: 600;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  width: 75%;
  width: 75%;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingCreatePackageForm_overlay__-lvY2 {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
}

.EditListingCreatePackageForm_overlayActive__30zn4 {
  z-index: 50;
  z-index: 50;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.EditListingCreatePackageForm_content__1dKPT {
  background: white;
  width: 100%;
  position: relative;
  overflow: auto;
  padding: 2.25rem 1.25rem;
  padding: 2.25rem 1.25rem;
  transition: width 0.3s ease-out
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_content__1dKPT {
    width: 0
}
  }

@media (min-width: 768px) {

.EditListingCreatePackageForm_contentActive__14Phd {
    width: 100%;
    padding: 5.75rem 5%;
    padding: 5.75rem 5%
}
  }

.EditListingCreatePackageForm_root__1EBIu {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_root__1EBIu {
    padding-top: 1px
}
  }

.EditListingCreatePackageForm_mainWrapper__3r8Sl {
  display: flex;
  flex-direction: column;
}

.EditListingCreatePackageForm_columnWrapper__3cq-u {
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_columnWrapper__3cq-u {
    flex-direction: row
}
  }

.EditListingCreatePackageForm_formColumn__1qvRo {
  width: 100%
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_formColumn__1qvRo {
    width: 50%;
    margin-right: 6.25rem;
    margin-right: 6.25rem
}
  }

.EditListingCreatePackageForm_previewColumn__-NqPx {
  width: 100%;
  margin-top: 1.5rem;
  margin-top: 1.5rem
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_previewColumn__-NqPx {
    margin-top: 0;
    width: calc(50% - 6.25rem);
    width: calc(50% - 6.25rem)
}
  }

.EditListingCreatePackageForm_formWrapper__2QmnE {
  margin: 0
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_formWrapper__2QmnE {
    margin: 0
}
  }

.EditListingCreatePackageForm_formError__18a7c {
  color: #ed3615;
  color: #ed3615;
}

.EditListingCreatePackageForm_submitButton__gEPn3 {
  margin-top: auto;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  flex-shrink: 0
}

@media (min-width: 1024px) {

.EditListingCreatePackageForm_submitButton__gEPn3 {
    display: inline-block;
    width: 15rem;
    width: 15rem;
    margin-top: 6.25rem;
    margin-top: 6.25rem
}
  }

.EditListingCreatePackageForm_buttonClose__2I0Mb {
  position: absolute;
  top: 1.5rem;
  top: 1.5rem;
  right: 1.5rem;
  right: 1.5rem;
  width: 3rem;
  width: 3rem;
  height: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: 0.25rem;
  padding: 0.25rem;
  background: #fff;
  background: #fff;
  border-color: transparent;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  z-index: 10;
  cursor: pointer
}

.EditListingCreatePackageForm_buttonClose__2I0Mb:hover {
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  }

.EditListingCreatePackageForm_header__13DV9 {
  font-weight: bold;
  margin-bottom: 2.75rem;
  margin-bottom: 2.75rem
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_header__13DV9 {
    margin-bottom: 0.5rem;
    margin-bottom: 0.5rem
}
  }

.EditListingCreatePackageForm_subheader__9EOUG {
  display: none
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_subheader__9EOUG {
    display: block;
    font-size: 0.875rem;
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    margin-top: 0
}
  }

.EditListingCreatePackageForm_packageDescription__2Ort_ {
  min-height: 9.5rem;
  min-height: 9.5rem;
}

.EditListingCreatePackageForm_loadingWrapper__2wy33 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.EditListingCreatePackageForm_loadingTitleWrapper__24yFM {
  position: absolute;
  top: calc(50% + 7.5rem);
  top: calc(50% + 7.5rem);
  transform: translateY(-50%);
  text-align: center;
}

.EditListingCreatePackageForm_loadingTitle__NwfqW {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_loadingTitle__NwfqW {
    font-size: 1.875rem;
    font-size: 1.875rem
}
  }

.EditListingCreatePackageForm_fieldHeader__272HT {
  font-size: 1.125rem;
  font-size: 1.125rem;
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_fieldHeader__272HT {
  font-size: 1.5rem;
  font-size: 1.5rem;
}
    }

.EditListingCreatePackageForm_fieldHeader__272HT {
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.EditListingCreatePackageForm_fieldSubHeader__3Qd-9 {
  display: none;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
  font-size: 0.875rem
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_fieldSubHeader__3Qd-9 {
    display: block
}
  }

.EditListingCreatePackageForm_fieldGuestsWrapper__LPYXq {
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_fieldGuestsWrapper__LPYXq {
    flex-direction: row
}

    .EditListingCreatePackageForm_fieldGuestsWrapper__LPYXq > span:first-of-type {
      margin-right: 2rem;
      margin-right: 2rem;
    }
  }

.EditListingCreatePackageForm_fieldGuests__24dgs label {
    margin-right: 1rem;
    margin-right: 1rem;
  }

.EditListingCreatePackageForm_fieldInputPrice__3CM6L input {
    width: 7.25rem;
    width: 7.25rem;
  }

.EditListingCreatePackageForm_packagePreview__1hNGd {
  margin: 0;
  width: 100%;
  padding: 2rem 1.25rem;
  padding: 2rem 1.25rem;
}

.EditListingCreatePackageForm_previewColumnWrapper__3_isp {
  position: -webkit-sticky;
  position: sticky;
  top: calc(-1 * 5.5rem);
  top: calc(-1 * 5.5rem);
}

.EditListingCreatePackageForm_listingSectionRoot__3P0fD {
  margin: 0;
}

.EditListingCreatePackageForm_submitButtonWrapper__3goNT {
  margin-top: 1.5rem;
  margin-top: 1.5rem
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_submitButtonWrapper__3goNT {
    margin-top: 1.75rem;
    margin-top: 1.75rem;
    width: 50%
}
  }

.EditListingCreatePackageForm_speciesListWrapper__1xnwV {
  display: grid;
  margin-top: 1.5rem;
  margin-top: 1.5rem;
  grid-gap: 1.5rem 2.5rem;
  grid-gap: 1.5rem 2.5rem;
  grid-template-columns: repeat(2, minmax(0, 50%));
  grid-template-columns: repeat(2, minmax(0, 50%));
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content)
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_speciesListWrapper__1xnwV {
    grid-template-columns: repeat(3, minmax(0, 33.333333%));
    grid-template-columns: repeat(3, minmax(0, 33.333333%))
}
  }

.EditListingCreatePackageForm_subspeciesListContainer__3FAbW {
  padding: 1rem 1.5rem;
  padding: 1rem 1.5rem;
  background-color: #f7fafc;
  background-color: #f7fafc;
  margin-top: 1.75rem;
  margin-top: 1.75rem;
  border-radius: 10px;
  border-radius: 10px;
}

.EditListingCreatePackageForm_subspeciesListWrapper__3aKyD {
  display: grid;
  margin-top: 1.5rem;
  margin-top: 1.5rem;
  grid-gap: 1.5rem 2.5rem;
  grid-gap: 1.5rem 2.5rem;
  grid-template-columns: repeat(2, minmax(0, 50%));
  grid-template-columns: repeat(2, minmax(0, 50%));
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content)
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_subspeciesListWrapper__3aKyD {
    grid-template-columns: repeat(3, minmax(0, 33.333333%));
    grid-template-columns: repeat(3, minmax(0, 33.333333%))
}
  }

.EditListingCreatePackageForm_activitiesListWrapper__1WRuf {
  display: flex;
  flex-direction: column;
}

.EditListingCreatePackageForm_showMore__1gO_F {
  cursor: pointer;
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  font-weight: 600;
  font-weight: 600;
  margin-top: 1.75rem;
  margin-top: 1.75rem;
  color: #3e704b;
  color: #3e704b;
  display: flex;
  align-items: baseline;
}

.EditListingCreatePackageForm_arrowIcon__3sXF5 {
  stroke: #3e704b;
  stroke: #3e704b;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
  transition: ease-in 0.2s;
  transition: ease-in 0.2s
}

.EditListingCreatePackageForm_arrowIcon__3sXF5 > path {
    /* Translate because the icons are annoyingly not centered in
       their viewbox which makes our rotation look bad */
    transform: translateY(1px);
  }

.EditListingCreatePackageForm_arrowIconDown___mWkX {
  /* Rotate instead of changing icon so we can animate it */
  transform: rotateZ(180deg);
}

.EditListingCreatePackageForm_radioWrapper__2m8eF {
  display: flex;
  flex-direction: column
}

.EditListingCreatePackageForm_radioWrapper__2m8eF > span {
    margin-right: 1.75rem;
    margin-right: 1.75rem;
    margin-bottom: 0;
  }

@media (min-width: 768px) {

.EditListingCreatePackageForm_radioWrapper__2m8eF {
    flex-direction: row
}
  }

.EditListingCreatePackageForm_radioButtonLodging__28jGC > label {
    font-weight: normal;
  }

.EditListingCreatePackageForm_photoLibraryContainer__1grlD {
  width: 100%;
  margin: 0;
  border-radius: 0;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding: 1rem;
  background-color: #fff;
  background-color: #fff;
  border-bottom: none;
  flex-grow: 0;
  min-height: auto;
  min-width: 75%;
  min-width: 75%;
  overflow: hidden
}

@media (min-width: 768px) {

.EditListingCreatePackageForm_photoLibraryContainer__1grlD {
    padding: 1.5rem;
    padding: 1.5rem
}
  }

.EditListingCreatePackageForm_photoPreviews__2TqZw {
  margin-top: 2rem;
  margin-top: 2rem;
}

.EditListingCreatePackageForm_datesFieldSection__3s0zM {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.EditListingCreatePackageForm_activityFieldCheckboxLabel__2Ok-H {
  font-weight: normal;
}

.EditListingCreatePackageForm_activityFieldCheckboxLabelSelected__190XK {
  font-weight: 700;
  font-weight: 700;
}

.EditListingCreatePackageForm_activityFieldInput__3_D0W {
  min-height: 5.25rem;
  min-height: 5.25rem;
  margin-left: 1.75rem;
  margin-left: 1.75rem;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  width: calc(100% - 1.75rem);
  width: calc(100% - 1.75rem);
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingPhotoLibraryForm_root__2AGRw {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.EditListingPhotoLibraryForm_error__L858E {
  color: #ed3615;
  color: #ed3615;
}

.EditListingPhotoLibraryForm_dropzoneDescription__2oqrF {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.EditListingPhotoLibraryForm_EditListingPhotoContainer__2zi93 {
  width: 100%
}

@media (min-width: 1024px) {

.EditListingPhotoLibraryForm_EditListingPhotoContainer__2zi93 {
    width: 80%;
    width: 80%
}
  }

.EditListingPhotoLibraryForm_PhotoFieldWrapper__204m8 {
  width: 100%
}

@media (min-width: 1024px) {

.EditListingPhotoLibraryForm_PhotoFieldWrapper__204m8 {
    width: 455px
}
  }

@media (min-width: 1280px) {

.EditListingPhotoLibraryForm_PhotoFieldWrapper__204m8 {
    width: 660px
}
  }

.EditListingPhotoLibraryForm_imagesLimitValidation__2XO_c {
  color: #ed3615;
  color: #ed3615;
}

.EditListingPhotoLibraryForm_dropzoneContainer__3qyMZ {
  position: relative;
}

.EditListingPhotoLibraryForm_dropzoneContainerActive__3J7OX {
}

.EditListingPhotoLibraryForm_dropzoneDropNotification__rMpLW {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: dashed 1px #cbd5e0;
  border: dashed 1px #cbd5e0;
  border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
}

.EditListingPhotoLibraryForm_dropzoneDropNotificationLabel__1u-qz {
  margin: 0;
}

.EditListingPhotoLibraryForm_imageCategories__WTdeT {
  display: grid;
  grid-gap: 1.25rem 1.5rem;
  grid-gap: 1.25rem 1.5rem;
  /* grid-template-columns: repeat(2, minmax(0, 50%)); */
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content)
}

@media (min-width: 768px) {

.EditListingPhotoLibraryForm_imageCategories__WTdeT {
    grid-gap: 1rem 1rem;
    grid-gap: 1rem 1rem
    /* grid-template-columns: repeat(4, minmax(0, 25%)); */
}
  }

.EditListingPhotoLibraryForm_imageCategory__zkklB {
  position: relative;
  cursor: pointer;
}

.EditListingPhotoLibraryForm_imageCategoryDisabled__1vYWd {
  position: relative;
  cursor: not-allowed;
}

.EditListingPhotoLibraryForm_imageCategoryInnerDisabled__2-S3F {
  opacity: 0.7;
}

.EditListingPhotoLibraryForm_imageCategoryInner__1OY06 {
  width: 100%;
  height: 100%;
  padding: 1.75rem 1.25rem;
  padding: 1.75rem 1.25rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: dashed 1px #cbd5e0;
  border: dashed 1px #cbd5e0;
  border-radius: 10px;
  border-radius: 10px;
  background: #fff;
  background: #fff;
}

.EditListingPhotoLibraryForm_imageCategoryIcon__1k99t {
  height: 2rem;
  height: 2rem;
  fill: #3e704b;
  fill: #3e704b;
}

.EditListingPhotoLibraryForm_imageCategoryLabel__13vyF {
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #1a202c;
  color: #1a202c;
  margin: 1rem 0 0 0;
  margin: 1rem 0 0 0;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}

.EditListingPhotoLibraryForm_progressBar__3Ucj2 {
  margin-top: 2rem;
  margin-top: 2rem;
}

.EditListingPhotoLibraryForm_photoPreviews__1PFon {
  margin-top: 2rem;
  margin-top: 2rem;
}

.EditListingPhotoLibraryForm_uploadRestrictions__3LZri {
  margin-bottom: 2.5rem;
  margin-bottom: 2.5rem;
}

.EditListingPhotoLibraryForm_uploadRestrictionText__70bHT {
  margin: 0;
  color: #4a5569;
  color: #4a5569;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-weight: 600;
}

.EditListingPhotoLibraryForm_tagCheckboxContainer__P_sTQ {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-top: 1rem;
}

.EditListingPhotoLibraryForm_tagCheckboxLabel__2CNIV {
  font-weight: normal;
}

.EditListingDescriptionForm_root__2GVNq {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.EditListingDescriptionForm_categoriesList__1c7Le {
  display: flex;
  flex-wrap: wrap;
}

.EditListingDescriptionForm_categoriesListItem__QJs7t {
  width: 50%;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingCampsiteForm_fieldSurfaceSizeWrapper__2tUFT {
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.EditListingCampsiteForm_fieldSurfaceSizeWrapper__2tUFT {
    flex-direction: row
}

    .EditListingCampsiteForm_fieldSurfaceSizeWrapper__2tUFT > span:first-of-type {
      margin-right: 2rem;
      margin-right: 2rem;
    }
  }

.EditListingCampsiteForm_fieldSurfaceSize__m46FA label {
    margin-right: 1rem;
    margin-right: 1rem;
    font-weight: normal;
  }

.EditListingCampsiteForm_addButtonWrapper__2SroF {
  margin-bottom: 2rem;
  margin-bottom: 2rem;
}

.EditListingCampsiteForm_addButton__LpX3q {
  border: solid 1px #1a202c;
  border: solid 1px #1a202c;
  padding: 0.5rem 3rem 0.5rem 2rem;
  padding: 0.5rem 3rem 0.5rem 2rem;
  color: #1a202c;
  color: #1a202c;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  border-radius: 25px
}

.EditListingCampsiteForm_addButton__LpX3q:hover {
    background-color: #f7fafc;
    background-color: #f7fafc;
    color: #1a202c;
    color: #1a202c;
  }

.EditListingCampsiteForm_buttonFlex__38Dj2 {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-weight: 700;
}

.EditListingCampsiteForm_plusCircle__j8vqJ {
  width: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  height: 1.75rem;
  color: #1a202c;
  color: #1a202c;
  margin-right: 1.25rem;
  margin-right: 1.25rem;
}

.EditListingCampsiteForm_fieldInputPrice__3yIno input {
    width: 7.25rem;
    width: 7.25rem;
  }

.EditListingCampsiteForm_removeCampsiteLink__19VYN {
  margin: 1.75rem 0;
  margin: 1.75rem 0;
  display: flex;
  width: 100%;
  justify-content: center;
  text-decoration: underline;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingLandownerStoryForm_root__1_kEz {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.EditListingLandownerStoryForm_categoriesList__2Ojlv {
  display: flex;
  flex-wrap: wrap;
}

.EditListingLandownerStoryForm_categoriesListItem__31vPR {
  width: 50%;
}

.EditListingLandownerStoryForm_uploadImageLabel__3zj_I {
  cursor: pointer;
  display: flex;
  margin-top: 20px;
}

.EditListingLandownerStoryForm_uploadAvatarInput__yGsIr {
  display: none;
}

.EditListingLandownerStoryForm_avatarContainer__1y3iY {
  position: relative;
}

.EditListingLandownerStoryForm_pencilIconContainer__20rfG {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 6px;
  width: 2.25rem;
  width: 2.25rem;
  height: 2.25rem;
  height: 2.25rem;
  background-color: #3e704b;
  background-color: #3e704b;
  color: #fff;
  color: #fff;
  border: 0.25rem solid white;
  border: 0.25rem solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.EditListingLandownerStoryForm_pencilIcon__LNmN1 {
  width: 2rem;
  width: 2rem;
}

.EditListingLandownerStoryForm_uploadingImage__2ISYS {
  width: 6rem;
  width: 6rem;
  height: 6rem;
  height: 6rem;
  object-fit: cover;

  border-radius: 50%;
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;
}

.EditListingLandownerStoryForm_uploadingImageOverlay__1zQuc {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #1a202c;
  background-color: #1a202c;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */

/* Default is 3:2 */

.EditListingLandownerStoryForm_squareAspectRatio__3IOxO {
  padding-bottom: 100%;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.EditListingGenericListForm_root__geRFI {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.EditListingGenericListForm_root__geRFI {
    padding-top: 1px
}
  }

.EditListingGenericListForm_features__3q9JU {
  margin-bottom: 24px
}

@media (min-width: 768px) {

.EditListingGenericListForm_features__3q9JU {
    margin-bottom: 32px
}
  }

.EditListingGenericListForm_error__Fi9Tt {
  color: #ff0000;
  color: #ff0000;
}

.EditListingGenericListForm_submitButton__3FAxd {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0
}

@media (min-width: 1024px) {

.EditListingGenericListForm_submitButton__3FAxd {
    display: inline-block;
    width: 241px;
    margin-top: 100px
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingHuntExperienceForm_pricingOptionSection__2Qtzk {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.EditListingHuntExperienceForm_pricingOptionSection__2Qtzk {
    margin-top: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

.EditListingHuntExperienceForm_pricingOptionSectionTitle__2UV9D {
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.EditListingHuntExperienceForm_iconWrapper__QGLA7 {
  width: 3rem;
  width: 3rem;
  height: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: 0.25rem;
  padding: 0.25rem;
  background: #fff;
  background: #fff;
  border-color: transparent;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  z-index: 10;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
}

.EditListingHuntExperienceForm_showMore__3GCQB {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  cursor: pointer;
  font-weight: 600;
  font-weight: 600;
  font-size: 1.125rem;
  font-size: 1.125rem;
  color: #3e704b;
  color: #3e704b;
  display: flex;
  align-items: center;
}

.EditListingHuntExperienceForm_arrowIcon__3Dkxc {
  stroke: #3e704b;
  stroke: #3e704b;

  transition: ease-in 0.2s;

  transition: ease-in 0.2s
}

.EditListingHuntExperienceForm_arrowIcon__3Dkxc > path {
    /* Translate because the icons are annoyingly not centered in
       their viewbox which makes our rotation look bad */
    transform: translateY(1px);
  }

.EditListingHuntExperienceForm_arrowIconDown__2GZfA {
  /* Rotate instead of changing icon so we can animate it */
  transform: rotateZ(180deg);
}

.EditListingHuntExperienceForm_features__1fZAl {
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.EditListingHuntExperienceForm_features__1fZAl {
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingFishExperienceForm_pricingOptionSection__1pjBR {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.EditListingFishExperienceForm_pricingOptionSection__1pjBR {
    margin-top: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

.EditListingFishExperienceForm_pricingOptionSectionTitle__2w_9K {
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.EditListingFishExperienceForm_iconWrapper__QsFdS {
  width: 3rem;
  width: 3rem;
  height: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: 0.25rem;
  padding: 0.25rem;
  background: #fff;
  background: #fff;
  border-color: transparent;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  z-index: 10;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
}

.EditListingFishExperienceForm_showMore__3NWvD {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  cursor: pointer;
  font-weight: 600;
  font-weight: 600;
  font-size: 1.125rem;
  font-size: 1.125rem;
  color: #3e704b;
  color: #3e704b;
  display: flex;
  align-items: center;
}

.EditListingFishExperienceForm_arrowIcon__39P6V {
  stroke: #3e704b;
  stroke: #3e704b;

  transition: ease-in 0.2s;

  transition: ease-in 0.2s
}

.EditListingFishExperienceForm_arrowIcon__39P6V > path {
    /* Translate because the icons are annoyingly not centered in
       their viewbox which makes our rotation look bad */
    transform: translateY(1px);
  }

.EditListingFishExperienceForm_arrowIconDown__2S3bY {
  /* Rotate instead of changing icon so we can animate it */
  transform: rotateZ(180deg);
}

.EditListingFishExperienceForm_features__2RIB9 {
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.EditListingFishExperienceForm_features__2RIB9 {
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingBirdExperienceForm_pricingOptionSection__25GvB {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.EditListingBirdExperienceForm_pricingOptionSection__25GvB {
    margin-top: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

.EditListingBirdExperienceForm_pricingOptionSectionTitle__3jf95 {
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.EditListingBirdExperienceForm_iconWrapper__EXmSz {
  width: 3rem;
  width: 3rem;
  height: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: 0.25rem;
  padding: 0.25rem;
  background: #fff;
  background: #fff;
  border-color: transparent;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  z-index: 10;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
}

.EditListingBirdExperienceForm_showMore__1RWwG {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  cursor: pointer;
  font-weight: 600;
  font-weight: 600;
  font-size: 1.125rem;
  font-size: 1.125rem;
  color: #3e704b;
  color: #3e704b;
  display: flex;
  align-items: center;
}

.EditListingBirdExperienceForm_arrowIcon__1T-Jp {
  stroke: #3e704b;
  stroke: #3e704b;

  transition: ease-in 0.2s;

  transition: ease-in 0.2s
}

.EditListingBirdExperienceForm_arrowIcon__1T-Jp > path {
    /* Translate because the icons are annoyingly not centered in
       their viewbox which makes our rotation look bad */
    transform: translateY(1px);
  }

.EditListingBirdExperienceForm_arrowIconDown__2bcW5 {
  /* Rotate instead of changing icon so we can animate it */
  transform: rotateZ(180deg);
}

.EditListingBirdExperienceForm_features__2Y7ps {
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.EditListingBirdExperienceForm_features__2Y7ps {
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingFarmExperienceForm_pricingOptionSection__2_kq2 {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.EditListingFarmExperienceForm_pricingOptionSection__2_kq2 {
    margin-top: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

.EditListingFarmExperienceForm_pricingOptionSectionTitle__2iaea {
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.EditListingFarmExperienceForm_iconWrapper__2MrX4 {
  width: 3rem;
  width: 3rem;
  height: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: 0.25rem;
  padding: 0.25rem;
  background: #fff;
  background: #fff;
  border-color: transparent;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  z-index: 10;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
}

.EditListingFarmExperienceForm_showMore__3x9ek {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  cursor: pointer;
  font-weight: 600;
  font-weight: 600;
  font-size: 1.125rem;
  font-size: 1.125rem;
  color: #3e704b;
  color: #3e704b;
  display: flex;
  align-items: center;
}

.EditListingFarmExperienceForm_arrowIcon__2ag3K {
  stroke: #3e704b;
  stroke: #3e704b;

  transition: ease-in 0.2s;

  transition: ease-in 0.2s
}

.EditListingFarmExperienceForm_arrowIcon__2ag3K > path {
    /* Translate because the icons are annoyingly not centered in
       their viewbox which makes our rotation look bad */
    transform: translateY(1px);
  }

.EditListingFarmExperienceForm_arrowIconDown__54Kc7 {
  /* Rotate instead of changing icon so we can animate it */
  transform: rotateZ(180deg);
}

.EditListingFarmExperienceForm_features__8cy8s {
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.EditListingFarmExperienceForm_features__8cy8s {
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingOutdoorRecreationExperienceForm_pricingOptionSection__2TOSY {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.EditListingOutdoorRecreationExperienceForm_pricingOptionSection__2TOSY {
    margin-top: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

.EditListingOutdoorRecreationExperienceForm_pricingOptionSectionTitle__GoFMV {
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.EditListingOutdoorRecreationExperienceForm_iconWrapper__3LtwZ {
  width: 3rem;
  width: 3rem;
  height: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: 0.25rem;
  padding: 0.25rem;
  background: #fff;
  background: #fff;
  border-color: transparent;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  z-index: 10;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
}

.EditListingOutdoorRecreationExperienceForm_showMore__aQP10 {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  cursor: pointer;
  font-weight: 600;
  font-weight: 600;
  font-size: 1.125rem;
  font-size: 1.125rem;
  color: #3e704b;
  color: #3e704b;
  display: flex;
  align-items: center;
}

.EditListingOutdoorRecreationExperienceForm_arrowIcon__3Z4dP {
  stroke: #3e704b;
  stroke: #3e704b;

  transition: ease-in 0.2s;

  transition: ease-in 0.2s
}

.EditListingOutdoorRecreationExperienceForm_arrowIcon__3Z4dP > path {
    /* Translate because the icons are annoyingly not centered in
       their viewbox which makes our rotation look bad */
    transform: translateY(1px);
  }

.EditListingOutdoorRecreationExperienceForm_arrowIconDown__3T-ug {
  /* Rotate instead of changing icon so we can animate it */
  transform: rotateZ(180deg);
}

.EditListingOutdoorRecreationExperienceForm_features__2riEm {
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.EditListingOutdoorRecreationExperienceForm_features__2riEm {
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingPhotosForm_root__351X0 {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.EditListingPhotosForm_error__2_YSs {
  color: #ed3615;
  color: #ed3615;
}

.EditListingPhotosForm_dropzoneContainer__2LIq_ {
  position: relative;
}

.EditListingPhotosForm_dropzoneContainerActive__240HN {
}

.EditListingPhotosForm_dropzoneDropNotification__1v1HY {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: dashed 1px #cbd5e0;
  border: dashed 1px #cbd5e0;
  border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
}

.EditListingPhotosForm_dropzoneDropNotificationLabel__5pDCv {
  margin: 0;
}

.EditListingPhotosForm_imageCategories__1mig- {
  display: grid;
  grid-gap: 1.25rem 1.5rem;
  grid-gap: 1.25rem 1.5rem;
  grid-template-columns: repeat(2, minmax(0, 50%));
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content)
}

@media (min-width: 768px) {

.EditListingPhotosForm_imageCategories__1mig- {
    grid-gap: 1rem 1rem;
    grid-gap: 1rem 1rem;
    grid-template-columns: repeat(4, minmax(0, 25%))
}
  }

.EditListingPhotosForm_imageCategory__3ZBGS {
  position: relative;
  cursor: pointer;
}

.EditListingPhotosForm_imageCategoryInner__35v8m {
  width: 100%;
  height: 100%;
  padding: 1.75rem 1.25rem;
  padding: 1.75rem 1.25rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: dashed 1px #cbd5e0;
  border: dashed 1px #cbd5e0;
  border-radius: 10px;
  border-radius: 10px;
  background: #fff;
  background: #fff;
}

.EditListingPhotosForm_imageCategoryIcon__PnYXl {
  height: 2rem;
  height: 2rem;
  fill: #3e704b;
  fill: #3e704b;
}

.EditListingPhotosForm_imageCategoryLabel__2IJid {
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #1a202c;
  color: #1a202c;
  margin: 1rem 0 0 0;
  margin: 1rem 0 0 0;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}

.EditListingPhotosForm_progressBar__17puT {
  margin-top: 2rem;
  margin-top: 2rem;
}

.EditListingPhotosForm_photoPreviews__3crTk {
  margin-top: 2rem;
  margin-top: 2rem;
}

.EditListingPhotosForm_uploadRestrictions__1ZVTj {
  margin-bottom: 2.5rem;
  margin-bottom: 2.5rem;
}

.EditListingPhotosForm_uploadRestrictionText__2_ClX {
  margin: 0;
  color: #4a5569;
  color: #4a5569;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-weight: 600;
}

.EditListingPropertyVisitForm_root__3kppB {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.EditListingPropertyVisitForm_categoriesList__NxK9M {
  display: flex;
  flex-wrap: wrap;
}

.EditListingPropertyVisitForm_categoriesListItem__2uXkT {
  width: 50%;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingArrivalForm_radioWrapper__kTtkY {
  display: grid;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(2, minmax(0, 100%));
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content)
}

@media (min-width: 768px) {

.EditListingArrivalForm_radioWrapper__kTtkY {
    grid-template-columns: repeat(4, minmax(0, 25%))
}
  }

.EditListingArrivalForm_lessMoreBtn__3ryo7 {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.EditListingSummaryForm_root__1C8Tr {
}

.EditListingSummaryForm_listing__1Kisg {
  display: flex;
  flex-direction: column;
}

.EditListingSummaryForm_listingItem__3Wb-1 {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.EditListingSummaryForm_link__16g4c {
  display: inline-flex;
  align-items: center;
  color: #1a202c;
  color: #1a202c;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: bold;
  text-decoration: underline;
}

.EditListingSummaryForm_previewLink__2yeRl {
  white-space: nowrap;
  text-decoration: underline;
}

.EditListingSummaryForm_linkLabel__34Ruy {
  color: #1a202c;
  color: #1a202c;
}

.EditListingSummaryForm_iconWrapper__2Yo8u {
  display: flex;
  width: 23px;
  justify-content: center;
  margin-right: 17px;
}

.EditListingSummaryForm_icon__2-Jnr {
  color: #3e704b;
  color: #3e704b;
}

@media (min-width: 768px) {
  .EditListingSummaryForm_listing__1Kisg {
    max-height: 360px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.EmailVerificationForm_root__E9dhN {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  height: 100%;

  justify-content: flex-start
}

@media (min-width: 768px) {

.EmailVerificationForm_root__E9dhN {
    justify-content: space-between
}
  }

.EmailVerificationForm_bottomWrapper__M2cFT {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;
}

@media (min-width: 768px) {

.EmailVerificationForm_bottomWrapper__M2cFT {
  margin-top: 96px;
}
    }

/* ================ Typography ================ */

/* Title of the modal */

.EmailVerificationForm_modalTitle__2juKP {
  font-weight: 600;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  font-weight: 700;
  font-weight: 700;
}

@media (min-width: 768px) {

.EmailVerificationForm_modalTitle__2juKP {
  font-weight: 600;
  font-weight: 600;
  line-height: 40px;
  margin: 0;
}
    }

/* Paragraph for the Modal */

.EmailVerificationForm_modalMessage__ajUl1 {
  margin: 18px 0 0 0;
}

@media (min-width: 768px) {

.EmailVerificationForm_modalMessage__ajUl1 {
  margin: 24px 0 0 0;
}
    }

.EmailVerificationForm_error__xjq9e {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.EmailVerificationForm_error__xjq9e {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.EmailVerificationForm_error__xjq9e {
  margin-top: 24px;
  color: #ff0000;
  color: #ff0000;
}

.EmailVerificationForm_submitButton__nlWJY {
  font-weight: 400;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.EmailVerificationForm_submitButton__nlWJY {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.EmailVerificationForm_submitButton__nlWJY {

    /* Dimensions */
  display: block;
  width: 100%;
  min-height: 59px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 16px 0 0 0;

    /* Borders */
  border-style: solid;
  border-color: #3e704b;
  border-color: #3e704b;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #3e704b;
  color: #3e704b;

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
}

.EmailVerificationForm_submitButton__nlWJY:hover,
    .EmailVerificationForm_submitButton__nlWJY:focus {
  text-decoration: none;
    }

@media (min-width: 768px) {

.EmailVerificationForm_submitButton__nlWJY {
  min-height: 60px;
}
    }

.EmailVerificationForm_submitButton__nlWJY {

  background-color: #3e704b;

  background-color: #3e704b;
  color: #fff;
  color: #fff;
  border-color: #3e704b;
  border-color: #3e704b;
}

.EmailVerificationForm_submitButton__nlWJY:hover,
    .EmailVerificationForm_submitButton__nlWJY:focus {
  background-color: #2a5e38;
  background-color: #2a5e38;
  border-color: #2a5e38;
  border-color: #2a5e38;
  color: #fff;
  color: #fff;
    }

.EmailVerificationForm_submitButton__nlWJY:disabled {
  background-color: #a1aec1;
  background-color: #a1aec1;
  color: #ffffff;
  color: #ffffff;
    }

.EmailVerificationForm_modalIcon__k7Ace {
  height: 48px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.EmailVerificationForm_modalIcon__k7Ace {
  height: 64px;
  margin-bottom: 23px;
}
    }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.EnquiryForm_root__SGP0C {
}

.EnquiryForm_icon__V6O8z {
  display: none
}

@media (min-width: 768px) {

.EnquiryForm_icon__V6O8z {
    display: block;
    margin-bottom: 36px
}
  }

.EnquiryForm_heading__3RTsc {
  font-weight: 600;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  font-weight: 700;
  font-weight: 700;
}

@media (min-width: 768px) {

.EnquiryForm_heading__3RTsc {
  font-weight: 600;
  font-weight: 600;
  line-height: 40px;
  margin: 0;
}
    }

.EnquiryForm_field__3vl89 {
  margin: 25px 0 24px 0
}

@media (min-width: 768px) {

.EnquiryForm_field__3vl89 {
    margin: 22px 0 24px 0
}
  }

.EnquiryForm_error__id00z {
  color: #ff0000;
  color: #ff0000;
  margin-top: 0
}

@media (min-width: 768px) {

.EnquiryForm_error__id00z {
    margin-top: 0
}
  }

.EnquiryForm_availabilityButton__6f0R- {
  font-weight: 400;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px
}

@media (min-width: 768px) {

.EnquiryForm_availabilityButton__6f0R- {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.EnquiryForm_availabilityButton__6f0R- {

    /* Dimensions */
  display: block;
  width: 100%;
  min-height: 59px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 16px 0 0 0;

    /* Borders */
  border-style: solid;
  border-color: #3e704b;
  border-color: #3e704b;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #3e704b;
  color: #3e704b;

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  cursor: pointer
}

.EnquiryForm_availabilityButton__6f0R-:hover,
    .EnquiryForm_availabilityButton__6f0R-:focus {
  text-decoration: none;
    }

@media (min-width: 768px) {

.EnquiryForm_availabilityButton__6f0R- {
  min-height: 60px
}
    }

.EnquiryForm_availabilityButton__6f0R- {
  padding: 0;
  margin-bottom: 25px
}

@media (min-width: 768px) {

.EnquiryForm_availabilityButton__6f0R- {
    display: none
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.FilterForm_root__oE2Z6 {
  outline: none;
}

.FilterForm_contentWrapper__yKt-Y {
  margin-bottom: 24px;
}

.FilterForm_buttonsWrapper__s0awD {
  display: flex;
  padding: 0 30px 16px 30px;
}

.FilterForm_clearButton__1EkfE {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.FilterForm_clearButton__1EkfE {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.FilterForm_clearButton__1EkfE {
  font-weight: 500;
  font-weight: 500;
  color: #a1aec1;
  color: #a1aec1;

  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer
}

.FilterForm_clearButton__1EkfE:focus,
  .FilterForm_clearButton__1EkfE:hover {
    color: #4a5569;
    color: #4a5569;
    transition: width ease-in-out 0.1s;
    transition: width ease-in-out 0.1s;
  }

.FilterForm_cancelButton__1YBeB {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.FilterForm_cancelButton__1YBeB {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.FilterForm_cancelButton__1YBeB {
  font-weight: 500;
  font-weight: 500;
  color: #a1aec1;
  color: #a1aec1;

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px
}

.FilterForm_cancelButton__1YBeB:focus,
  .FilterForm_cancelButton__1YBeB:hover {
    color: #4a5569;
    color: #4a5569;
    transition: width ease-in-out 0.1s;
    transition: width ease-in-out 0.1s;
  }

.FilterForm_submitButton__5Cqgf {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.FilterForm_submitButton__5Cqgf {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.FilterForm_submitButton__5Cqgf {
  font-weight: 500;
  font-weight: 500;
  color: #3e704b;
  color: #3e704b;

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer
}

.FilterForm_submitButton__5Cqgf:focus,
  .FilterForm_submitButton__5Cqgf:hover {
    color: #3e704b;
    color: #3e704b;
    transition: width ease-in-out 0.1s;
    transition: width ease-in-out 0.1s;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.LocationSearchForm_root__2t0CW {
  border-radius: 2px;
}

.LocationSearchForm_searchInputIcon__1E8QP {
  box-sizing: content-box;
  padding-left: 24px;
  margin-top: 2px;

  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid #3e704b;
  border-bottom: 2px solid #3e704b;
  background-color: #ffffff;
  background-color: #ffffff;
}

.LocationSearchForm_searchInput__x7QFA {
  flex-grow: 1;
  height: 69px;
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border-bottom-width: 2px

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
}

.LocationSearchForm_searchInput__x7QFA::-webkit-input-placeholder {
    line-height: normal;
  }

.LocationSearchForm_searchInput__x7QFA:hover,
  .LocationSearchForm_searchInput__x7QFA:focus {
    border-bottom-color: #3e704b;
    border-bottom-color: #3e704b;
    outline: none;
  }

.LocationSearchForm_searchInput__x7QFA:hover:-ms-input-placeholder, .LocationSearchForm_searchInput__x7QFA:focus:-ms-input-placeholder {
    color: #4a5569;
    color: #4a5569;
  }

.LocationSearchForm_searchInput__x7QFA:hover::placeholder,
  .LocationSearchForm_searchInput__x7QFA:focus::placeholder {
    color: #4a5569;
    color: #4a5569;
  }

.LocationSearchForm_searchInput__x7QFA:-ms-input-placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: 400;
    font-weight: 400;
    -ms-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s;
    -ms-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s;
  }

.LocationSearchForm_searchInput__x7QFA::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: 400;
    font-weight: 400;
    transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s;
  }

.LocationSearchForm_searchPredictions__1u3OD {
  position: absolute;
  width: 100%;
  padding-bottom: 8px;
  padding-bottom: 8px;
  top: 50px;
  left: 0;
  background-color: #3e704b;
  background-color: #3e704b;
  border-bottom-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-right-radius: 2px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  z-index: calc(50 + 1);
  z-index: calc(50 + 1);

  margin-top: 16px;
  margin-bottom: 102px
}

@media (min-width: 1921px) {

.LocationSearchForm_searchPredictions__1u3OD {
    margin-bottom: 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.LoginForm_root__2Vw2c {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  height: 100%;

  justify-content: flex-start
}

@media (min-width: 768px) {

.LoginForm_root__2Vw2c {
    justify-content: space-between
}
  }

.LoginForm_email__2PFha {
  margin-top: 1rem;
  margin-top: 1rem;
}

@media (min-width: 768px) {

.LoginForm_email__2PFha {
  margin-top: 2rem;
  margin-top: 2rem;
}
    }

.LoginForm_password__2R7Mp {
  margin-top: 1rem;
  margin-top: 1rem;
}

@media (min-width: 768px) {

.LoginForm_password__2R7Mp {
  margin-top: 2rem;
  margin-top: 2rem;
}
    }

.LoginForm_inputGroup__33DCI {
  position: relative;
}

.LoginForm_passwordIcon__19ite {
  position: absolute;
  top: calc(60% - (0.25rem / 2));
  top: calc(60% - (0.25rem / 2));
  right: 0.75rem;
  right: 0.75rem;
  width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
  color: #a1aec1;
  color: #a1aec1
}

.LoginForm_passwordIcon__19ite:hover {
    cursor: pointer;
  }

.LoginForm_passwordIconError__3TKD0 {
  position: absolute;
  top: calc(42% - (0.25rem / 2));
  top: calc(42% - (0.25rem / 2));
  right: 0.75rem;
  right: 0.75rem;
  width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
  color: #a1aec1;
  color: #a1aec1
}

.LoginForm_passwordIconError__3TKD0:hover {
    cursor: pointer;
  }

.LoginForm_bottomWrapperText__2Gktd {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: right;
  font-weight: 600;
  font-weight: 600
}

@media (min-width: 768px) {

.LoginForm_bottomWrapperText__2Gktd {
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

.LoginForm_recoveryLink__3yQvc {
  text-decoration: underline;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.PasswordRecoveryForm_root__3FYlg {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  height: 100%;
}

.PasswordRecoveryForm_email__2FGKV {
  margin-top: 37px
}

@media (min-width: 768px) {

.PasswordRecoveryForm_email__2FGKV {
    margin-top: 38px
}
  }

.PasswordRecoveryForm_bottomWrapper__2yYQ0 {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;
}

@media (min-width: 768px) {

.PasswordRecoveryForm_bottomWrapper__2yYQ0 {
  margin-top: 96px;
}
    }

/* Adjust the bottomWrapper's margin to match modals without the helper text */

.PasswordRecoveryForm_bottomWrapperText__3Rmxg {
  margin-top: -24px;
  padding-bottom: 8px;
}

@media (min-width: 768px) {

.PasswordRecoveryForm_bottomWrapperText__3Rmxg {
  padding-bottom: 0;
}
    }

/* Helper links */

.PasswordRecoveryForm_modalHelperLink__3EzmB {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.PasswordRecoveryForm_modalHelperLink__3EzmB {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.PasswordRecoveryForm_modalHelperLink__3EzmB {
  color: #4a5569;
  color: #4a5569;
  margin: 0;
}

@media (min-width: 768px) {

.PasswordRecoveryForm_modalHelperLink__3EzmB {
  margin: 0;
}
    }

/* Helper texts for the links, not the actual links */

.PasswordRecoveryForm_modalHelperText__1U7ay {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.PasswordRecoveryForm_modalHelperText__1U7ay {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.PasswordRecoveryForm_modalHelperText__1U7ay {
  color: #a1aec1;
  color: #a1aec1;
  margin: 0;
}

@media (min-width: 768px) {

.PasswordRecoveryForm_modalHelperText__1U7ay {
  margin: 0;
}
    }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.PasswordResetForm_root__2LnEi {
}

.PasswordResetForm_password__uty2J {
    /* Leave space between the input and the button below when the
    viewport height is small */
  margin-top: 24px;
}

@media (min-width: 768px) {

.PasswordResetForm_password__uty2J {
  margin-top: 32px;
}
    }

.PasswordResetForm_password__uty2J {
  margin-bottom: 24px;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.StripeCheckoutForm_paymentForm__AHz0B {
  width: 100%;
  align-self: center;
}

.StripeCheckoutForm_paymentContainer__8NrLQ {
  border: none;
  padding: 0;
  margin: 0
}

@media (min-width: 768px) {

.StripeCheckoutForm_paymentContainer__8NrLQ {
    border-radius: 10px;
    border-radius: 10px;
    border: solid 1px #e2e8f0;
    border: solid 1px #e2e8f0;
    padding: 1.25rem 0.75rem 1rem 0.75rem;
    padding: 1.25rem 0.75rem 1rem 0.75rem;
    margin-bottom: 0.75rem;
    margin-bottom: 0.75rem
}
  }

.StripeCheckoutForm_paymentMessage__gBltz {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  color: #2f6f0a;
  color: #2f6f0a;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #2f6f0a;
  border: 1px solid #2f6f0a;
  padding: 0.75rem;
  padding: 0.75rem;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem;
}

.StripeCheckoutForm_errorMessage__1AoKb {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  color: #ca2d10;
  color: #ca2d10;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #ca2d10;
  border: 1px solid #ca2d10;
  padding: 0.75rem;
  padding: 0.75rem;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem;
}

/* Buttons and links */

.StripeCheckoutForm_button__1XZ43 {
  border-radius: 25px;
  border-radius: 25px;
  margin: 1.5rem 0 0.75rem 0;
  margin: 1.5rem 0 0.75rem 0;
  width: 100%;
}

.StripeCheckoutForm_button__1XZ43:hover {
  filter: contrast(115%);
}

.StripeCheckoutForm_button__1XZ43:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */

.StripeCheckoutForm_spinner__3MQuz,
.StripeCheckoutForm_spinner__3MQuz:before,
.StripeCheckoutForm_spinner__3MQuz:after {
  border-radius: 50%;
}

.StripeCheckoutForm_spinner__3MQuz {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  transform: translateZ(0);
}

.StripeCheckoutForm_spinner__3MQuz:before,
.StripeCheckoutForm_spinner__3MQuz:after {
  position: absolute;
  content: '';
}

.StripeCheckoutForm_spinner__3MQuz:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  transform-origin: 10.4px 10.2px;
  animation: StripeCheckoutForm_loading__UE5v- 2s infinite ease 1.5s;
}

.StripeCheckoutForm_spinner__3MQuz:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  transform-origin: 0px 10.2px;
  animation: StripeCheckoutForm_loading__UE5v- 2s infinite ease;
}

@keyframes StripeCheckoutForm_loading__UE5v- {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.StripeCheckoutForm_card__3cPQ4 {
    /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 5px 0 5px 0;

    /* Borders */
  border: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #3e704b;
  border-bottom-color: #3e704b;
  border-radius: 0
}

.StripeCheckoutForm_card__3cPQ4:-ms-input-placeholder {
  color: #a1aec1;
  color: #a1aec1;
    }

.StripeCheckoutForm_card__3cPQ4::placeholder {
  color: #a1aec1;
  color: #a1aec1;
    }

.StripeCheckoutForm_card__3cPQ4 {

    /* Effects */

  transition: border-bottom-color ease-in 0.2s;

  transition: border-bottom-color ease-in 0.2s
}

.StripeCheckoutForm_card__3cPQ4:hover,
    .StripeCheckoutForm_card__3cPQ4:focus {
  border-bottom-color: #4a5569;
  border-bottom-color: #4a5569;
  outline: none;
    }

@media (min-width: 768px) {

.StripeCheckoutForm_card__3cPQ4 {
  padding: 4px 0 10px 0
}
    }

.StripeCheckoutForm_card__3cPQ4 {

  /* Layout */
  padding: 8px 0 6px 0;
  height: 36px;

  /* Border */
  border-bottom-color: #ffaa00;
  border-bottom-color: #ffaa00
}

@media (min-width: 768px) {

.StripeCheckoutForm_card__3cPQ4 {
    height: 38px
}
  }

@media (min-width: 1024px) {

.StripeCheckoutForm_card__3cPQ4 {
    height: 38px;
    padding: 6px 0 14px 0
}
  }

.StripeCheckoutForm_cardSuccess__1aIjY {
  border-bottom-color: #2ecc71;
  border-bottom-color: #2ecc71;
}

.StripeCheckoutForm_cardError__3yN0h {
  border-bottom-color: #ff0000;
  border-bottom-color: #ff0000;
}

.StripeCheckoutForm_error__3Lopo {
  color: #ff0000;
  color: #ff0000;
}

.StripeCheckoutForm_formHeading__2Zz4i {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
  margin: 1.5rem 0 0.25rem 0;
  margin: 1.5rem 0 0.25rem 0;
  color: #1a202c;
  color: #1a202c
}

@media (min-width: 768px) {

.StripeCheckoutForm_formHeading__2Zz4i {
    margin: 0 0 0.5rem 0;
    margin: 0 0 0.5rem 0
}
  }

.StripeCheckoutForm_formSubheading__hK1JE {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.StripeCheckoutForm_formSubcopy__3UXSZ {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  color: #1a202c;
  color: #1a202c
}

@media (min-width: 768px) {

.StripeCheckoutForm_formSubcopy__3UXSZ {
    margin-bottom: 0.75rem;
    margin-bottom: 0.75rem
}
  }

.StripeCheckoutForm_paymentMethodSelector__3kOYl {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.StripeCheckoutForm_saveForLaterUse__3rS0w {
  padding-top: 6px;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem
}

@media (min-width: 768px) {

.StripeCheckoutForm_saveForLaterUse__3rS0w {
    padding-top: 0.5rem;
    padding-top: 0.5rem;
    margin-top: 0px;
    margin-bottom: 0px
}
  }

.StripeCheckoutForm_saveForLaterUseCheckbox__1JsNX {
  display: inline-block;
  padding: 2px 0 3px 0
}

@media (min-width: 768px) {

.StripeCheckoutForm_saveForLaterUseCheckbox__1JsNX {
    padding: 1px 0 0px 0
}
  }

.StripeCheckoutForm_saveForLaterUseLabel__19wS_ {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.StripeCheckoutForm_saveForLaterUseLabel__19wS_ {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.StripeCheckoutForm_saveForLaterUseLabel__19wS_ {
  color: #1a202c;
  color: #1a202c;
  font-weight: bold;
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.StripeCheckoutForm_saveForLaterUseLegalInfo__qnzvD {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.StripeCheckoutForm_saveForLaterUseLegalInfo__qnzvD {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.StripeCheckoutForm_saveForLaterUseLegalInfo__qnzvD {
  color: #a1aec1;
  color: #a1aec1;
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px
}

@media (min-width: 768px) {

.StripeCheckoutForm_saveForLaterUseLegalInfo__qnzvD {
    margin-top: -1px;
    margin-bottom: 1px
}
  }

.StripeCheckoutForm_billingHeading__2OLeD {
  margin: 0 0 0.75rem 0;
  margin: 0 0 0.75rem 0;
  color: #1a202c;
  color: #1a202c;
  font-weight: 700;
  font-weight: 700;
  font-size: 1.5rem;
  font-size: 1.5rem
}

@media (min-width: 768px) {

.StripeCheckoutForm_billingHeading__2OLeD {
    margin: 0 0 1.5rem 0;
    margin: 0 0 1.5rem 0
}
  }

.StripeCheckoutForm_paymentLabel__16p9G {
  margin: 0
}

@media (min-width: 768px) {

.StripeCheckoutForm_paymentLabel__16p9G {
    margin-top: 0px;
    margin-bottom: 2px
}
  }

.StripeCheckoutForm_messageHeading__372Jb {
  font-size: 1rem;
  font-size: 1rem;
  margin: 0 0 12px 0;
  color: var(--var-900);
  margin: 12px 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px
}

@media (min-width: 768px) {

.StripeCheckoutForm_messageHeading__372Jb {
    margin: 12px 0 12px 0;
    padding-top: 4px;
    padding-bottom: 4px
}
  }

.StripeCheckoutForm_messageLabel__1tpm3 {
  margin: 0 0 5px 0
}

@media (min-width: 768px) {

.StripeCheckoutForm_messageLabel__1tpm3 {
    margin: 0 0 6px 0
}
  }

.StripeCheckoutForm_messageOptional__SfCRv {
  color: #a1aec1;
  color: #a1aec1;
}

.StripeCheckoutForm_message__Grovn {
  border-bottom-color: #a1aec1;
  border-bottom-color: #a1aec1;
}

.StripeCheckoutForm_submitContainer__353wF {
  margin-top: 1rem;
  margin-top: 1rem;
}

.StripeCheckoutForm_paymentInfo__3TDcF {
  color: #1a202c;
  color: #1a202c;
  font-size: 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  font-weight: 600;
  text-align: center;
  padding: 0 0.75rem;
  padding: 0 0.75rem;
  margin: 2rem 0 0 0;
  margin: 2rem 0 0 0
}

@media (min-width: 1024px) {

.StripeCheckoutForm_paymentInfo__3TDcF {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0
}
  }

.StripeCheckoutForm_submitButton__39RG0 {
  margin-top: 22px
}

@media (min-width: 768px) {

.StripeCheckoutForm_submitButton__39RG0 {
    margin-top: 26px
}
  }

@media (min-width: 1024px) {

.StripeCheckoutForm_submitButton__39RG0 {
    margin-top: 17px
}
  }

.StripeCheckoutForm_missingStripeKey__1q-jX {
  color: #ff0000;
  color: #ff0000;
}

.StripeCheckoutForm_cancelDisclaimer__z9mia {
  font-size: 0.875rem;
  font-size: 0.875rem;
  padding: 0.5rem 0 0.5rem 0;
  padding: 0.5rem 0 0.5rem 0;
  border-radius: 5px;
  border-radius: 5px;
}

.StripeCheckoutForm_sendBookingDisclaimer__3a2V9 {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-style: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  width: 80%;
  margin: auto;
}

.StripeCheckoutForm_characterCounter__3hu7v {
  text-align: left;
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #718096;
  color: #718096;
  margin-top: 0.25rem;
  margin-top: 0.25rem;
  padding-left: 0.25rem;
  padding-left: 0.25rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PaymentMethodsForm_root__3Uekm {
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  margin-top: 1.25rem;
}

.PaymentMethodsForm_errorMessage__2W8-Y {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  color: #ca2d10;
  color: #ca2d10;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #ca2d10;
  border: 1px solid #ca2d10;
  padding: 0.75rem;
  padding: 0.75rem;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem;
}

.PaymentMethodsForm_submitContainer__1U9d5 {
  margin-top: 3rem;
  margin-top: 3rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

.ProfileSettingsForm_root__2GswN {
  margin-top: 24px
}

@media (min-width: 768px) {

.ProfileSettingsForm_root__2GswN {
    margin-top: 32px
}
  }

.ProfileSettingsForm_sectionContainer__LbV3b {
  padding: 0;
  margin-bottom: 36px
}

@media (min-width: 768px) {

.ProfileSettingsForm_sectionContainer__LbV3b {
    padding: 0;
    margin-bottom: 56px
}
  }

.ProfileSettingsForm_sectionTitle__1CLhj {
  /* Font */
  color: #a1aec1;
  color: #a1aec1;

  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px
}

@media (min-width: 768px) {

.ProfileSettingsForm_sectionTitle__1CLhj {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px
}
  }

.ProfileSettingsForm_lastSection__1tH-h {
  margin-bottom: 69px
}

@media (min-width: 768px) {

.ProfileSettingsForm_lastSection__1tH-h {
    margin-bottom: 111px
}

    .ProfileSettingsForm_lastSection__1tH-h .ProfileSettingsForm_sectionTitle__1CLhj {
      margin-bottom: 16px;
    }
  }

.ProfileSettingsForm_uploadAvatarInput__1IShN {
  display: none;
}

.ProfileSettingsForm_uploadAvatarWrapper__2w8vB {
  margin-top: 18px;
  margin-bottom: 18px
}

@media (min-width: 768px) {

.ProfileSettingsForm_uploadAvatarWrapper__2w8vB {
    margin-top: 44px;
    margin-bottom: 20px
}
  }

.ProfileSettingsForm_label__2Ifb7 {
  width: 96px;
  width: 96px
}

@media (min-width: 768px) {

.ProfileSettingsForm_label__2Ifb7 {
    width: 240px;
    width: 240px
}
  }

.ProfileSettingsForm_avatarPlaceholder__35_I0,
.ProfileSettingsForm_avatarContainer__3R_o6 {
  /* Dimension */
  position: relative;
  width: 96px;
  width: 96px;
  height: 96px;
  height: 96px;

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: #ffffff;
  background-color: #ffffff;
  border-radius: calc(96px / 2);
  border-radius: calc(96px / 2);
  cursor: pointer
}

@media (min-width: 768px) {

.ProfileSettingsForm_avatarPlaceholder__35_I0,
.ProfileSettingsForm_avatarContainer__3R_o6 {
    width: 240px;
    width: 240px;
    height: 240px;
    height: 240px;
    border-radius: calc(240px / 2);
    border-radius: calc(240px / 2)
}
  }

.ProfileSettingsForm_avatarPlaceholder__35_I0 {
  /* Placeholder border */
  border-style: dashed;
  border-color: #a1aec1;
  border-color: #a1aec1;
  border-width: 2px;

  transition: ease-in-out 0.1s;

  transition: ease-in-out 0.1s
}

.ProfileSettingsForm_avatarPlaceholder__35_I0:hover {
    border-color: #a1aec1;
    border-color: #a1aec1;
  }

@media (min-width: 768px) {

.ProfileSettingsForm_avatarPlaceholderTextMobile__3yOqy {
    display: none
}
  }

.ProfileSettingsForm_avatarPlaceholderText__328hU {
  display: none
}

@media (min-width: 768px) {

.ProfileSettingsForm_avatarPlaceholderText__328hU {
    display: block;
    max-width: 130px;
    text-align: center
}
  }

.ProfileSettingsForm_avatarUploadError__3Br1j {
  /* Placeholder border */
  border-style: dashed;
  border-color: #ff0000;
  border-color: #ff0000;
  border-width: 2px;
}

.ProfileSettingsForm_error__eQj9n {
  /* Font */
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.ProfileSettingsForm_error__eQj9n {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.ProfileSettingsForm_error__eQj9n {
  color: #ff0000;
  color: #ff0000;
  margin-top: 18px;
  margin-bottom: 0
}

@media (min-width: 768px) {

.ProfileSettingsForm_error__eQj9n {
    margin-top: 22px;
    margin-bottom: 2px
}
  }

.ProfileSettingsForm_avatar__1FbOO {
  width: 100%;
  height: 100%;
}

.ProfileSettingsForm_changeAvatar__QqeDk {
  /* Font */
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.ProfileSettingsForm_changeAvatar__QqeDk {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.ProfileSettingsForm_changeAvatar__QqeDk {

  font-weight: 500;

  font-weight: 500;

  /* Dimensions */
  width: 105px;
  height: 41px;
  padding: 10px 10px 11px 35px;

  /* Look and feel (buttonish) */
  background-color: #ffffff;
  background-color: #ffffff;
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px 12px;
  border: solid 1px #a1aec1;
  border: solid 1px #a1aec1;
  border-radius: 2px;

  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 768px) {

.ProfileSettingsForm_changeAvatar__QqeDk {
    /* Position: under */
    bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s;
    padding: 7px 10px 11px 35px
}
  }

.ProfileSettingsForm_changeAvatar__QqeDk:hover {
    border: solid 1px #a1aec1;
    border: solid 1px #a1aec1;
  }

.ProfileSettingsForm_uploadingImage__1d3mL {
  /* Dimensions */
  width: 96px;
  width: 96px;
  height: 96px;
  height: 96px;

  /* Image fitted to container */
  object-fit: cover;
  background-color: #a1aec1;
  background-color: #a1aec1; /* Loading BG color */
  border-radius: calc(96px / 2);
  border-radius: calc(96px / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0
}

@media (min-width: 768px) {

.ProfileSettingsForm_uploadingImage__1d3mL {
    width: 240px;
    width: 240px;
    height: 240px;
    height: 240px;
    border-radius: calc(240px / 2);
    border-radius: calc(240px / 2)
}
  }

.ProfileSettingsForm_uploadingImageOverlay__IAouA {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: #ffffff;
  background-color: #ffffff;
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */

/* Default is 3:2 */

.ProfileSettingsForm_squareAspectRatio__GKDo2 {
  padding-bottom: 100%;
}

.ProfileSettingsForm_avatarInvisible__3xAmA {
  visibility: hidden;
  top: -1000px;
  left: -1000px;
}

.ProfileSettingsForm_tip__2cVAZ {
  font-weight: 500;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px
    /* No margins for default font */
}

@media (min-width: 768px) {

.ProfileSettingsForm_tip__2cVAZ {
  font-size: 20px;
  line-height: 24px
}
    }

.ProfileSettingsForm_tip__2cVAZ {
  color: #a1aec1;
  color: #a1aec1;
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px
}

@media (min-width: 768px) {

.ProfileSettingsForm_tip__2cVAZ {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px
}
  }

.ProfileSettingsForm_fileInfo__3dner {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.ProfileSettingsForm_fileInfo__3dner {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.ProfileSettingsForm_fileInfo__3dner {
  color: #a1aec1;
  color: #a1aec1;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px
}

@media (min-width: 768px) {

.ProfileSettingsForm_fileInfo__3dner {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px
}
  }

.ProfileSettingsForm_nameContainer__3Y9e_ {
  display: flex;
  justify-content: space-between;
  margin-top: 12px
}

@media (min-width: 768px) {

.ProfileSettingsForm_nameContainer__3Y9e_ {
    margin-top: 24px
}
  }

.ProfileSettingsForm_firstName__3hJTB {
  width: calc(34% - 9px);
}

.ProfileSettingsForm_lastName__1iWiR {
  width: calc(66% - 9px);
}

.ProfileSettingsForm_bioInfo__3At8d {
  color: #a1aec1;
  color: #a1aec1;
  margin-top: 11px
}

@media (min-width: 768px) {

.ProfileSettingsForm_bioInfo__3At8d {
    margin-top: 16px
}
  }

.ProfileSettingsForm_submitButton__F2nhw {
  margin-top: 24px;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.ReviewForm_root__fD_wz {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
}

.ReviewForm_error__1eFwp {
  color: #ff0000;
  color: #ff0000;
}

@media (min-width: 768px) {

.ReviewForm_errorPlaceholder__q3vMr {
    width: 100%;
    height: 32px
}
  }

.ReviewForm_reviewRating__Mmo_L {
  margin-bottom: 2.25rem;
  margin-bottom: 2.25rem
}

@media (min-width: 768px) {

.ReviewForm_reviewRating__Mmo_L {
    margin-bottom: 2.5rem;
    margin-bottom: 2.5rem
}
  }

.ReviewForm_reviewContent__2tbE7 {
  flex-shrink: 0;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.ReviewForm_reviewCheckboxes__y11WH {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem
}

@media (min-width: 768px) {

.ReviewForm_reviewCheckboxes__y11WH {
    margin-top: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 3rem;
    margin-bottom: 3rem
}
  }

.ReviewForm_submitButton__2cyNB {
  margin-top: auto;
  margin-bottom: 96px;
  flex-shrink: 0
}

@media (min-width: 768px) {

.ReviewForm_submitButton__2cyNB {
    display: inline-block;
    width: 100%;
    margin-bottom: 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SendMessageForm_root__2FDuQ {
  position: relative;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05)
}

@media (min-width: 1024px) {

.SendMessageForm_root__2FDuQ {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden
}
  }

.SendMessageForm_textAreaContainer__2VaRV {
  width: 100%;
  padding: 0.5rem 1rem;
  padding: 0.5rem 1rem;
  min-height: 50px;
  background-color: #fff;
  background-color: #fff;
  border: 2px #cbd5e0 solid;
  border: 2px #cbd5e0 solid;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  border-radius: 5px;
  color: #1a202c;
  color: #1a202c
}

.SendMessageForm_textAreaContainer__2VaRV.SendMessageForm_textAreaFocused__Izvhd {
    border-color: #1a202c;
    border-color: #1a202c;
  }

.SendMessageForm_textAreaContainer__2VaRV.SendMessageForm_inputError__1sRyj {
    border-color: #ed3615;
    border-color: #ed3615;
  }

.SendMessageForm_textarea__sBQdg {
  border: none;
  resize: none;
  padding: 0;
  min-height: 9.5rem;
  min-height: 9.5rem
}

@media (min-width: 768px) {

.SendMessageForm_textarea__sBQdg {
    min-height: 3rem;
    min-height: 3rem
}
  }

.SendMessageForm_submitContainer__3lqNZ {
  display: flex;
  flex-direction: column;
}

.SendMessageForm_submit__32TBa {
  align-self: flex-end;
}

.SendMessageForm_errorContainer__2s20a {
  display: block;
  flex: 1 1;
  text-align: right;
  padding: 26px 24px 0 0;
}

.SendMessageForm_error__Hoh2T {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.SendMessageForm_error__Hoh2T {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.SendMessageForm_error__Hoh2T {
  font-weight: 500;
  font-weight: 500;
  color: #ff0000;
  color: #ff0000;
  margin: 0;
}

.SendMessageForm_verificationContainer__2UGvd {
  display: flex;
  align-items: flex-start;
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}

.SendMessageForm_verificationIconContainer__1dLMr {
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.SendMessageForm_verifySectionLink__Di1HP {
  font-weight: 600;
  font-weight: 600;
  text-decoration: underline;
}

.SendMessageForm_characterCounter__1ivyy {
  text-align: left;
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #718096;
  color: #718096;
  margin-top: 0.25rem;
  margin-top: 0.25rem;
  padding-left: 0.25rem;
  padding-left: 0.25rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SelectMultipleFilterForm_root__2JqTl {
  /* By default hide the content */
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: 50;
  z-index: 50;

  /* Layout */
  margin-top: 7px;
  padding: 15px 30px 20px 30px;

  /* Borders */
  background-color: #ffffff;
  background-color: #ffffff;
  border-top: 1px solid #a1aec1;
  border-top: 1px solid #a1aec1;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
  outline: none;
}

.SelectMultipleFilterForm_isOpen__2G2ZP {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.SelectMultipleFilterForm_fieldGroup__2rwY0 {
  margin-bottom: 35px;
  white-space: nowrap;
}

.SelectMultipleFilterForm_buttonsWrapper__3dW5s {
  display: flex;
}

.SelectMultipleFilterForm_clearButton__1_Nvv {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.SelectMultipleFilterForm_clearButton__1_Nvv {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.SelectMultipleFilterForm_clearButton__1_Nvv {
  font-weight: 500;
  font-weight: 500;
  color: #a1aec1;
  color: #a1aec1;

  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer
}

.SelectMultipleFilterForm_clearButton__1_Nvv:focus,
  .SelectMultipleFilterForm_clearButton__1_Nvv:hover {
    color: #4a5569;
    color: #4a5569;
    transition: width ease-in-out 0.1s;
    transition: width ease-in-out 0.1s;
  }

.SelectMultipleFilterForm_cancelButton__3BtrE {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.SelectMultipleFilterForm_cancelButton__3BtrE {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.SelectMultipleFilterForm_cancelButton__3BtrE {
  font-weight: 500;
  font-weight: 500;
  color: #a1aec1;
  color: #a1aec1;

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px
}

.SelectMultipleFilterForm_cancelButton__3BtrE:focus,
  .SelectMultipleFilterForm_cancelButton__3BtrE:hover {
    color: #4a5569;
    color: #4a5569;
    transition: width ease-in-out 0.1s;
    transition: width ease-in-out 0.1s;
  }

.SelectMultipleFilterForm_submitButton__1iBfW {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.SelectMultipleFilterForm_submitButton__1iBfW {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.SelectMultipleFilterForm_submitButton__1iBfW {
  font-weight: 500;
  font-weight: 500;
  color: #3e704b;
  color: #3e704b;

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer
}

.SelectMultipleFilterForm_submitButton__1iBfW:focus,
  .SelectMultipleFilterForm_submitButton__1iBfW:hover {
    color: #3e704b;
    color: #3e704b;
    transition: width ease-in-out 0.1s;
    transition: width ease-in-out 0.1s;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SignupForm_root__3ECtq {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  height: 100%;

  justify-content: flex-start
}

@media (min-width: 768px) {

.SignupForm_root__3ECtq {
    justify-content: space-between
}
  }

.SignupForm_name__2j9_8 {
  margin-top: 1rem;
  margin-top: 1rem
}

@media (min-width: 768px) {

.SignupForm_name__2j9_8 {
  margin-top: 0.75rem;
  margin-top: 0.75rem
}
    }

@media (min-width: 768px) {

.SignupForm_name__2j9_8 {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-top: 0.5rem
}
  }

.SignupForm_firstNameRoot__idnoX {
  width: 100%
}

@media (min-width: 768px) {

.SignupForm_firstNameRoot__idnoX {
  margin-top: 1rem;
  margin-top: 1rem
}
  }

@media (min-width: 768px) and (min-width: 768px) {

.SignupForm_firstNameRoot__idnoX {
  margin-top: 0.75rem;
  margin-top: 0.75rem
}
    }

@media (min-width: 768px) {

.SignupForm_firstNameRoot__idnoX {
    width: calc(45% - 9px)
}
  }

.SignupForm_lastNameRoot__g1pwi {
  margin-top: 1rem;
  margin-top: 1rem
}

@media (min-width: 768px) {

.SignupForm_lastNameRoot__g1pwi {
  margin-top: 0.75rem;
  margin-top: 0.75rem
}
    }

.SignupForm_lastNameRoot__g1pwi {
  width: 100%
}

@media (min-width: 768px) {

.SignupForm_lastNameRoot__g1pwi {
    width: calc(55% - 9px)
}
  }

.SignupForm_email__2Au4l {
  margin-top: 1rem;
  margin-top: 1rem;
}

@media (min-width: 768px) {

.SignupForm_email__2Au4l {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}
    }

.SignupForm_password__3i8c5 {
  margin-top: 1rem;
  margin-top: 1rem;
}

@media (min-width: 768px) {

.SignupForm_password__3i8c5 {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}
    }

.SignupForm_phone__O5qOc {
  margin-top: 1rem;
  margin-top: 1rem;
}

@media (min-width: 768px) {

.SignupForm_phone__O5qOc {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}
    }

.SignupForm_role__2mJyE {
  margin-top: 1rem;
  margin-top: 1rem;
}

@media (min-width: 768px) {

.SignupForm_role__2mJyE {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}
    }

.SignupForm_bottomWrapper__vvIfl {
  margin-top: 2.5rem;
  margin-top: 2.5rem;
  margin-bottom: 4rem;
  margin-bottom: 4rem;
}

.SignupForm_bottomWrapperText__3dRcI {
  margin-top: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.SignupForm_termsText__2nbxd {
  font-size: 0.75rem;
  font-size: 0.75rem;
}

.SignupForm_termsLink__33jsb {
  font-size: 0.75rem;
  font-size: 0.75rem;
  text-decoration: underline
}

.SignupForm_termsLink__33jsb:hover {
    cursor: pointer;
  }

.SignupForm_inputGroup__2Ocwq {
  position: relative;
}

.SignupForm_passwordIcon__1hePk {
  position: absolute;
  top: calc(60% - (0.25rem / 2));
  top: calc(60% - (0.25rem / 2));
  right: 0.75rem;
  right: 0.75rem;
  width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
  color: #1a202c;
  color: #1a202c
}

.SignupForm_passwordIcon__1hePk:hover {
    cursor: pointer;
  }

.SignupForm_passwordIconError__3XUpj {
  position: absolute;
  top: calc(43% - (0.25rem / 2));
  top: calc(43% - (0.25rem / 2));
  right: 0.75rem;
  right: 0.75rem;
  width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
  color: #1a202c;
  color: #1a202c
}

.SignupForm_passwordIconError__3XUpj:hover {
    cursor: pointer;
  }

.SignupForm_rolesSection__1MHmM {
  margin-top: 1.75rem;
  margin-top: 1.75rem;
  font-weight: 600;
  font-weight: 600;
}

.SignupForm_rolesSportsman__1ojSf {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.SignupForm_activitiesSection__1VORc {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}

.SignupForm_fieldLabel__1Bn7q {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.SignupForm_inputGroup__2Ocwq {
  position: relative;
}

.SignupForm_formSection__1-Syx {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.SignupForm_formHeader__TZkPc {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 2.25rem;
  font-size: 2.25rem;
}

.SignupForm_formSubHeader__QNA-h {
  margin-top: 1rem;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

@media (min-width: 1024px) {

.SignupForm_coltonContainer__2LcgL {
}
  }

.SignupForm_referralOther__2A0NA {
  margin-top: 1.75rem;
  margin-top: 1.75rem;
}

.SignupForm_huntSpeciesContainer__3bHgg {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.SignupForm_huntSpeciesItem__1Xfxb {
  flex: 0 1 50%;
  padding-right: 15px;
  padding-left: 15px;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SpecialOfferForm_root__2q9mE {
  display: flex;
  flex-direction: column;
}

.SpecialOfferForm_bookingDatesDiv__2u_DJ {
  margin: 2rem 0;
  margin: 2rem 0;
  font-weight: 600;
  font-weight: 600;
  color: #1a202c;
  color: #1a202c;
}

.SpecialOfferForm_bookingDatesLabel__3uwIt {
  text-align: center;
}

.SpecialOfferForm_bookingDates__ZABBe {
  flex-shrink: 0;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.SpecialOfferForm_submitButton__3fD22 {
  float: left;
  text-align: center;
  height: 3rem;
  height: 3rem;
  border-radius: 5px;
}

.SpecialOfferForm_buttonContainer__2c8Ba {
  text-align: center;
}

.SpecialOfferForm_priceInput__tBPGE {
  width: 50%;
  height: 3rem;
  height: 3rem;
  margin-bottom: 3rem;
  margin-bottom: 3rem;
  font-weight: bold;
}

.SpecialOfferForm_priceBreakdownContainer__38DeV {
}

.SpecialOfferForm_offerMessage___66a3 {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.StripePaymentForm_root__2qodb {
  display: flex;
  flex-direction: column;
}

.StripePaymentForm_card__11gfw {
    /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 5px 0 5px 0;

    /* Borders */
  border: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #3e704b;
  border-bottom-color: #3e704b;
  border-radius: 0
}

.StripePaymentForm_card__11gfw:-ms-input-placeholder {
  color: #a1aec1;
  color: #a1aec1;
    }

.StripePaymentForm_card__11gfw::placeholder {
  color: #a1aec1;
  color: #a1aec1;
    }

.StripePaymentForm_card__11gfw {

    /* Effects */

  transition: border-bottom-color ease-in 0.2s;

  transition: border-bottom-color ease-in 0.2s
}

.StripePaymentForm_card__11gfw:hover,
    .StripePaymentForm_card__11gfw:focus {
  border-bottom-color: #4a5569;
  border-bottom-color: #4a5569;
  outline: none;
    }

@media (min-width: 768px) {

.StripePaymentForm_card__11gfw {
  padding: 4px 0 10px 0
}
    }

.StripePaymentForm_card__11gfw {

  /* Layout */
  padding: 8px 0 6px 0;
  height: 36px;

  /* Border */
  border-bottom-color: #ffaa00;
  border-bottom-color: #ffaa00
}

@media (min-width: 768px) {

.StripePaymentForm_card__11gfw {
    height: 38px
}
  }

@media (min-width: 1024px) {

.StripePaymentForm_card__11gfw {
    height: 38px;
    padding: 6px 0 14px 0
}
  }

.StripePaymentForm_cardSuccess__1pzPZ {
  border-bottom-color: #2ecc71;
  border-bottom-color: #2ecc71;
}

.StripePaymentForm_cardError__2hiG9 {
  border-bottom-color: #ff0000;
  border-bottom-color: #ff0000;
}

.StripePaymentForm_error__1DpSC {
  color: #ff0000;
  color: #ff0000;
}

.StripePaymentForm_errorMessage__EyH9i {
  margin-top: 24px;
  color: #ff0000;
  color: #ff0000;
}

.StripePaymentForm_paymentHeading__16_-T {
  font-size: 1rem;
  font-size: 1rem;
  margin: 0 0 12px 0;
  color: var(--var-900);

  padding-top: 4px;
  padding-bottom: 2px
}

@media (min-width: 768px) {

.StripePaymentForm_paymentHeading__16_-T {
    margin: 0 0 16px 0;
    padding-top: 4px;
    padding-bottom: 4px
}
  }

.StripePaymentForm_paymentMethodSelector__2t0OX {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.StripePaymentForm_saveForLaterUse__1tCgM {
  padding-top: 6px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.StripePaymentForm_saveForLaterUse__1tCgM {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 12px
}
  }

.StripePaymentForm_saveForLaterUseCheckbox__3vGUV {
  display: inline-block;
  padding: 2px 0 3px 0
}

@media (min-width: 768px) {

.StripePaymentForm_saveForLaterUseCheckbox__3vGUV {
    padding: 1px 0 0px 0
}
  }

.StripePaymentForm_saveForLaterUseLabel__Y9Psy {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.StripePaymentForm_saveForLaterUseLabel__Y9Psy {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.StripePaymentForm_saveForLaterUseLabel__Y9Psy {
  color: #4a5569;
  color: #4a5569;
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.StripePaymentForm_saveForLaterUseLegalInfo__zGmYU {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.StripePaymentForm_saveForLaterUseLegalInfo__zGmYU {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.StripePaymentForm_saveForLaterUseLegalInfo__zGmYU {
  color: #a1aec1;
  color: #a1aec1;
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px
}

@media (min-width: 768px) {

.StripePaymentForm_saveForLaterUseLegalInfo__zGmYU {
    margin-top: -1px;
    margin-bottom: 1px
}
  }

.StripePaymentForm_billingHeading__6jvGS {
  margin: 0 0 0.75rem 0;
  margin: 0 0 0.75rem 0;
  color: #1a202c;
  color: #1a202c;
  font-weight: 700;
  font-weight: 700;
  font-size: 1.5rem;
  font-size: 1.5rem
}

@media (min-width: 768px) {

.StripePaymentForm_billingHeading__6jvGS {
    margin: 0 0 1.5rem 0;
    margin: 0 0 1.5rem 0
}
  }

.StripePaymentForm_paymentLabel__1Ty1G {
  margin: 0
}

@media (min-width: 768px) {

.StripePaymentForm_paymentLabel__1Ty1G {
    margin-top: 0px;
    margin-bottom: 2px
}
  }

.StripePaymentForm_messageHeading__3gtP7 {
  font-size: 1rem;
  font-size: 1rem;
  margin: 0 0 12px 0;
  color: var(--var-900);
  margin: 12px 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px
}

@media (min-width: 768px) {

.StripePaymentForm_messageHeading__3gtP7 {
    margin: 12px 0 12px 0;
    padding-top: 4px;
    padding-bottom: 4px
}
  }

.StripePaymentForm_messageLabel__3B0FR {
  margin: 0 0 5px 0
}

@media (min-width: 768px) {

.StripePaymentForm_messageLabel__3B0FR {
    margin: 0 0 6px 0
}
  }

.StripePaymentForm_messageOptional__3blK_ {
  color: #a1aec1;
  color: #a1aec1;
}

.StripePaymentForm_message__JRQuN {
  border-bottom-color: #a1aec1;
  border-bottom-color: #a1aec1;
}

.StripePaymentForm_submitContainer__2YTDC {
  margin-top: auto
}

@media (min-width: 1024px) {

.StripePaymentForm_submitContainer__2YTDC {
    margin-top: 1rem;
    margin-top: 1rem
}
  }

.StripePaymentForm_paymentInfo__1DsaM {
  color: #1a202c;
  color: #1a202c;
  font-size: 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  font-weight: 600;
  text-align: center;
  padding: 0 0.75rem;
  padding: 0 0.75rem;
  margin: 2rem 0 0 0;
  margin: 2rem 0 0 0
}

@media (min-width: 1024px) {

.StripePaymentForm_paymentInfo__1DsaM {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0
}
  }

.StripePaymentForm_submitButton__2IhXU {
  margin-top: 22px
}

@media (min-width: 768px) {

.StripePaymentForm_submitButton__2IhXU {
    margin-top: 26px
}
  }

@media (min-width: 1024px) {

.StripePaymentForm_submitButton__2IhXU {
    margin-top: 17px
}
  }

.StripePaymentForm_missingStripeKey__140m7 {
  color: #ff0000;
  color: #ff0000;
}

.StripePaymentForm_cancelDisclaimer__1SBmu {
  background-color: #edf2f7;
  background-color: #edf2f7;
  font-size: 0.875rem;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border-radius: 5px;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.StripeConnectAccountForm_root__JXoB6 {
  margin-top: 24px;
}

@media (min-width: 768px) {
  .StripeConnectAccountForm_root__JXoB6 {
    margin-top: 48px;
  }
}

.StripeConnectAccountForm_disabled__3rJJ_ {
  opacity: 0.5;
}

.StripeConnectAccountForm_sectionContainer__k2fFh {
  margin-bottom: 35px
}

@media (min-width: 768px) {

.StripeConnectAccountForm_sectionContainer__k2fFh {
    margin-bottom: 56px
}
  }

.StripeConnectAccountForm_formRow__Kd-82 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.StripeConnectAccountForm_selectCountry__30kVH {
  margin-bottom: 24px;
}

.StripeConnectAccountForm_error__3UZia {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.StripeConnectAccountForm_error__3UZia {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.StripeConnectAccountForm_error__3UZia {
  margin-top: 24px;
  color: #ff0000;
  color: #ff0000;
}

.StripeConnectAccountForm_missingStripeKey__2JvEL {
  color: #ff0000;
  color: #ff0000;
}

.StripeConnectAccountForm_accountInformationTitle__1RtJF {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.25rem;
  font-size: 1.25rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;
}

@media (min-width: 768px) {

.StripeConnectAccountForm_accountInformationTitle__1RtJF {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px;
}
    }

.StripeConnectAccountForm_accountInformationTitle__1RtJF {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 1px;
}

@media (min-width: 768px) {
  .StripeConnectAccountForm_accountInformationTitle__1RtJF {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.StripeConnectAccountForm_savedCountry__2T7oc {
  padding-top: 6px;
  padding-bottom: 0px;
}

@media (min-width: 768px) {
  .StripeConnectAccountForm_savedCountry__2T7oc {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.StripeConnectAccountForm_savedInformation__MpFTQ {
  margin-bottom: 24px;
}

.StripeConnectAccountForm_savedBankAccount__1ly25 {
  width: 100%;
  border-bottom: 2px solid #2ecc71;
  border-bottom: 2px solid #2ecc71;
  margin-bottom: 24px;
  padding: 4px 0 10px 0;
  color: #4a5569;
  color: #4a5569;
  text-align: left
}

.StripeConnectAccountForm_savedBankAccount__1ly25:hover {
    cursor: text;
    text-decoration: none;
    border-color: #4a5569;
    border-color: #4a5569;
  }

.StripeConnectAccountForm_savedBankAccount__1ly25:focus {
    outline: none;
    border-color: #4a5569;
    border-color: #4a5569;
  }

.StripeConnectAccountForm_stripeLogoWrapper__34b2C {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 2.25rem;
  margin-bottom: 2.25rem;
}

.StripeConnectAccountForm_stripeLogo__fGQBf {
  display: block;
  margin: auto 0;
  width: 8rem;
  width: 8rem;
}

.StripeConnectAccountForm_buttonsContainer__3MO3I {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.StripeConnectAccountForm_submitButton__HGnDB {
  margin-inline-end: 0.4rem;
  white-space: nowrap;
  display: flex;
  align-items: baseline;
}

.StripeConnectAccountForm_bypassButton__3LsH7 {
  width: 12rem;
  height: 3.2rem;
  margin-inline-end: 0.4rem;
  white-space: nowrap;
  display: flex;
  border: none;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

:root {
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

@media (min-width: 768px) {

.EditListingBedFields_root__3M9JK {
    max-width: 60%;
    max-width: 60%
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingLodgingForm_fieldInputPrice__UVdlI input {
    width: 7.25rem;
    width: 7.25rem;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.MyInterestsForm_formSection___DMoL {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.MyInterestsForm_huntSpeciesWrapper__2JXxz {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(-1 * 0.5rem);
  margin-left: calc(-1 * 0.5rem);
  margin-right: calc(-1 * 0.5rem);
  margin-right: calc(-1 * 0.5rem);
  margin-top: calc(-1 * 0.5rem);
  margin-top: calc(-1 * 0.5rem);
  margin-bottom: calc(-1 * 0.5rem);
  margin-bottom: calc(-1 * 0.5rem);
}

.MyInterestsForm_huntSpeciesItem__1GSZN {
  width: calc(50% - 1rem);
  width: calc(50% - 1rem);
  margin-right: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-top: 0.5rem
}

@media (min-width: 768px) {

.MyInterestsForm_huntSpeciesItem__1GSZN {
    width: calc(33.3% - 1rem);
    width: calc(33.3% - 1rem)
}
  }

.MyInterestsForm_radioWrapper__2yjv0 {
  display: flex
}

.MyInterestsForm_radioWrapper__2yjv0 > span {
    margin-right: 1rem;
    margin-right: 1rem;
  }

.MyInterestsForm_weaponCheckboxes__2gNcz,
.MyInterestsForm_activityCheckboxes__1PGLp {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * 0.5rem);
  margin-top: calc(-1 * 0.5rem);
  margin-bottom: calc(-1 * 0.5rem);
  margin-bottom: calc(-1 * 0.5rem)
}

.MyInterestsForm_weaponCheckboxes__2gNcz > span, .MyInterestsForm_activityCheckboxes__1PGLp > span {
    flex-basis: 50%;
    margin-top: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }

.MyInterestsForm_activityLabelSubTitle__2ANKl {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: normal;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

@media (min-width: 1024px) {

.PartyMembersInfoForm_fieldContainer__3Jd9g {
    padding: 0 0.75rem 0 0;
    padding: 0 0.75rem 0 0
}
  }

.PartyMembersInfoForm_indForm__56R61 {
  position: relative;
  margin-top: var(--s-0);
  border-radius: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.75rem 0.75rem 1rem 0.75rem;
  padding: 0.75rem 0.75rem 1rem 0.75rem;
}

.PartyMembersInfoForm_buttonContainer__VvKBL {
  position: relative;
  margin-top: var(--s-0);
  border-radius: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.75rem 0.75rem 1rem 0.75rem;
  padding: 0.75rem 0.75rem 1rem 0.75rem;
  margin: 0 0 0.5rem 0;
  margin: 0 0 0.5rem 0
}

@media (min-width: 1024px) {

.PartyMembersInfoForm_buttonContainer__VvKBL {
    margin: 0 0 0 1rem;
    margin: 0 0 0 1rem
}
  }

.PartyMembersInfoForm_shareButton__1F9en {
  width: 100%;
}

.PartyMembersInfoForm_sportsmanWrapper__31Vzd {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(-1 * 1rem);
  top: calc(-1 * 1rem);
  font-size: 10px;
  text-align: center;
  margin: auto;
  width: 33.333333%;
  width: 33.333333%;
  padding: 0.25rem 1rem 0.25rem 0.5rem;
  padding: 0.25rem 1rem 0.25rem 0.5rem;
  border-radius: 0.75rem;
  border-radius: 0.75rem;
  background-color: #1a202c;
  background-color: #1a202c;
  color: #fff;
  color: #fff
}

@media (min-width: 1024px) {

.PartyMembersInfoForm_sportsmanWrapper__31Vzd {
    width: 20%;
    width: 20%
}
  }

@media (min-width: 1024px) {

.PartyMembersInfoForm_fieldsContainer__-BQRe {
    display: flex
}
  }

.PartyMembersInfoForm_fieldsWrapper__xIhpy {
  margin-top: 0.75rem;
  margin-top: 0.75rem
}

@media (min-width: 1024px) {

.PartyMembersInfoForm_fieldsWrapper__xIhpy {
    display: inline-block;
    margin: 0 0 0 1rem;
    margin: 0 0 0 1rem;
    width: 50%
}
  }

.PartyMembersInfoForm_requiredText__3dYZ6 {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.NotifyForAddedStateForm_subscribeFormSection__1qiFs {
  max-width: 40rem;
  max-width: 40rem;
  background-color: #f7fafc;
  background-color: #f7fafc;
  padding: 1.5rem 1rem 1.5rem 1rem;
  padding: 1.5rem 1rem 1.5rem 1rem;
  border-radius: 10px;
  border-radius: 10px;
  border: solid 1px #e2e8f0;
  border: solid 1px #e2e8f0;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  color: #1a202c;
  color: #1a202c;
}

.NotifyForAddedStateForm_subscribeFormText__1dUym {
  font-weight: 700;
  font-weight: 700;
}

.NotifyForAddedStateForm_subscribeFormSubmit__9L6Hf {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.EditListingGenericListPanel_root__21Klu {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.EditListingGenericListPanel_form__2SIgZ {
  flex-grow: 1;
}

.EditListingGenericListPanel_title__1wegz {
  margin-bottom: 19px
}

@media (min-width: 1024px) {

.EditListingGenericListPanel_title__1wegz {
    margin-bottom: 44px
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.EditListingAccessForm_root__3AGP7 {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-grow: 1
}

@media (min-width: 768px) {

.EditListingAccessForm_root__3AGP7 {
    padding-top: 2px
}
  }

.EditListingAccessForm_error__33CM4 {
  color: #ff0000;
  color: #ff0000;
}

.EditListingAccessForm_priceInput__2s4cu {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 25px;
}

.EditListingAccessForm_submitButton__1Cf-v {
  margin-top: auto;
  margin-bottom: 24px
}

@media (min-width: 1024px) {

.EditListingAccessForm_submitButton__1Cf-v {
    display: inline-block;
    width: 241px;
    margin-top: 100px
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.EditListingAccessPanel_root__PG-Rp {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.EditListingAccessPanel_form__16Rpg {
  flex-grow: 1;
}

.EditListingAccessPanel_title__2X3y9 {
  margin-bottom: 19px
}

@media (min-width: 768px) {

.EditListingAccessPanel_title__2X3y9 {
    margin-bottom: 38px;
    padding: 1px 0 7px 0
}
  }

.EditListingAccessPanel_priceCurrencyInvalid__3QPr8 {
  color: #ff0000;
  color: #ff0000;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconArrowHead_root__3HL-t {
  stroke: #4a5569;
  stroke: #4a5569;
  fill: #4a5569;
  fill: #4a5569;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconBannedUser_backgroundLight__3bqs0 {
  stop-color: #3e704b;
  stop-color: #3e704b;
}

.IconBannedUser_backgroundDark__2ZwhF {
  stop-color: #3e704b;
  stop-color: #3e704b;
}

.IconBannedUser_foregroundFill__2IIqo {
  fill: #ffffff;
  fill: #ffffff;
}

.IconBannedUser_foregroundStroke__2kV1C {
  stroke: #ffffff;
  stroke: #ffffff;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconCard_root__j33UJ {
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconCheckMark_root__1bhFD {
  width: 24px;
  height: 24px;
  stroke: #3e704b;
  stroke: #3e704b;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconClose_root__2uhXq {
  stroke: #4a5569;
  stroke: #4a5569;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconEdit_root__1H3X2,
.IconEdit_pencil__24G-d {
  stroke: #3e704b;
  stroke: #3e704b;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconEmailAttention_root__13HOW {
}

.IconEmailAttention_marketplaceStroke__1pG0o {
  stroke: #3e704b;
  stroke: #3e704b;
}

.IconEmailAttention_attentionStroke__b2xRm {
  stroke: #ffaa00;
  stroke: #ffaa00;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconEmailSent_root__3crHG {
  stroke: #3e704b;
  stroke: #3e704b;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconEmailSuccess_root__2fH_p {
}

.IconEmailSuccess_marketplaceStroke__2PZ6I {
  stroke: #3e704b;
  stroke: #3e704b;
}

.IconEmailSuccess_successFill__cMBH7 {
  fill: #2ecc71;
  fill: #2ecc71;
}

.IconEmailSuccess_checkStroke__39SRJ {
  stroke: #ffffff;
  stroke: #ffffff;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconEnquiry_root__3Ie94 {
}

.IconEnquiry_marketplaceColorStroke__3W9oh {
  stroke: #3e704b;
  stroke: #3e704b;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconKeys_strokeMarketplaceColor__2wv_O {
  stroke: #3e704b;
  stroke: #3e704b;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconKeysSuccess_strokeMarketplaceColor__3iWYn {
  stroke: #3e704b;
  stroke: #3e704b;
}

.IconKeysSuccess_fillSuccessColor__WOqeA {
  fill: #2ecc71;
  fill: #2ecc71;
}

.IconKeysSuccess_strokeLightColor__3_QN9 {
  stroke: #ffffff;
  stroke: #ffffff;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.IconReviewStar_root__1uypq {
  fill: #fff;
  fill: #fff;
  stroke: #1a202c;
  stroke: #1a202c;
}

.IconReviewStar_filled__3_1dq {
  fill: #1a202c;
  fill: #1a202c;
}

.IconReviewStar_goldFilled__1zTT9 {
  fill: #ffbb52;
  fill: #ffbb52;
}

.IconReviewStar_goldEmpty__1HKex {
  fill: #fff;
  fill: #fff;
  stroke: #ffbb52;
  stroke: #ffbb52;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconReviewUser_root__2pxDK {
  stroke: #3e704b;
  stroke: #3e704b;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconSearch_root__3rpUF {
  stroke: #3e704b;
  stroke: #3e704b;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconSocialMediaFacebook_root__2X9uo {
  fill: #4a5569;
  fill: #4a5569;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s
}

.IconSocialMediaFacebook_root__2X9uo:hover {
    fill: #3e704b;
    fill: #3e704b;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconSocialMediaInstagram_root__36ab8 {
  fill: #4a5569;
  fill: #4a5569;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s
}

.IconSocialMediaInstagram_root__36ab8:hover {
    fill: #3e704b;
    fill: #3e704b;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconSocialMediaTwitter_root__24iXV {
  fill: #4a5569;
  fill: #4a5569;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s
}

.IconSocialMediaTwitter_root__24iXV:hover {
    fill: #3e704b;
    fill: #3e704b;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconSpinner_root__2OyUl {
  /* While the viewbox of the icon is 30x30, the icon size is scaled to
   28x28 by default, which results in scaling the whole image and the
   strokes a bit.*/
  width: 28px;
  height: 28px;

  stroke: #3e704b;

  stroke: #3e704b;
  stroke-width: 3px;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.IconSuccess_root__1hTQO {
}

.IconSuccess_fillColor__1euA9 {
  fill: #2ecc71;
  fill: #2ecc71;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.Accordion_accordionItemContainer__2cMWE {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 1.75rem 0 1.75rem 0;
  padding: 1.75rem 0 1.75rem 0;
  border-bottom: 1px solid #e2e8f0;
  border-bottom: 1px solid #e2e8f0;
}

.Accordion_itemTitle__-P1g3 {
  font-weight: 700;
  font-weight: 700;
  padding-right: 0.75rem;
  padding-right: 0.75rem;
  cursor: pointer
}

.Accordion_itemTitle__-P1g3:hover {
    color: #718096;
    color: #718096;
  }

.Accordion_itemContent__3iZPE {
  margin-top: 1rem;
  margin-top: 1rem;
  padding-right: 2rem;
  padding-right: 2rem;
}

.Accordion_buttonIcon__3PGnr {
  color: #000;
  color: #000;
  font-weight: 700;
  font-weight: 700;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.Accordion_buttonIcon__3PGnr > svg {
  width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  height: 1.5rem;
  transition: 0.3s
}

.Accordion_buttonIcon__3PGnr > svg:hover {
    color: #718096;
    color: #718096;
  }

.Accordion_totalDivider__1sG2s {
  height: 1px;
  margin-top: 1.5rem;
  margin-top: 1.5rem;
  border: none;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
}
:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.Breadcrumbs_root__24W31 {
  display: flex;
  align-items: center;
  overflow-x: auto;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 0.875rem;
  font-size: 0.875rem
}

.Breadcrumbs_root__24W31::-webkit-scrollbar {
    display: none;
  }

.Breadcrumbs_breadcrumb__3NVQa {
  color: #718096;
  color: #718096;
  font-weight: normal
}

.Breadcrumbs_breadcrumb__3NVQa.Breadcrumbs_active__2HkO9 {
    color: #1a202c;
    color: #1a202c;
    font-weight: 700;
    font-weight: 700;
  }

.Breadcrumbs_arrow__3WfbD {
  height: 0.5rem;
  height: 0.5rem;
  min-width: 0.5rem;
  min-width: 0.5rem;
  margin: 0 0.75rem;
  margin: 0 0.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PackagesBreadcrumbs_root__1W76c {
}

.PackagesBreadcrumbs_breadcrumbLink__2MfGx {
  color: #718096;
  color: #718096;
}

.PackagesBreadcrumbs_statusTitle__3hkga {
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem
}

@media (min-width: 768px) {

.PackagesBreadcrumbs_statusTitle__3hkga {
    margin-top: 0.75rem;
    margin-top: 0.75rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.LimitedAccessBanner_root__1fNuX {
  background-color: #d9ff00;
  text-align: center;
  padding: 10px 20px 9px;
}

.LimitedAccessBanner_text__1YprW {
  margin: 0;
  display: inline-block;
  color: #ff00e0;

  font-size: 16px;
  margin-bottom: 16px;
  line-height: 20px;
}

.LimitedAccessBanner_button__sYbOM {
  background: #2a3d4b;
  margin: 0 16px;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  border: 0
}

.LimitedAccessBanner_button__sYbOM:hover {
    text-decoration: none;
    background: #364f61;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.Logo_root__110xT {
  height: 2.75rem;
  height: 2.75rem
}

@media (min-width: 768px) {

.Logo_root__110xT {
    height: 3.25rem;
    height: 3.25rem
}
  }

.Logo_lg__jbXCk {
  height: 3rem;
  height: 3rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.NamedBackLink_root__3GBmi {
  display: inline-flex;
  align-items: center;
  color: #3e704b;
  color: #3e704b;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: bold;
}

.NamedBackLink_icon__JjYgp {
  height: 20px;
  margin-right: 8px;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.NotificationBadge_root__zq8Qp {
  /* Font */
  font-weight: bold;
  font-size: 0.875rem;
  font-size: 0.875rem;
  line-height: 18px;
  text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.NotificationBadge_root__zq8Qp {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 6px
}
    }

.NotificationBadge_root__zq8Qp {
  color: #ffffff;
  color: #ffffff;
  text-align: center;

  background-color: #ff0000;

  background-color: #ff0000;

  /* Layout of a red dot */
  display: inline-block;
  width: auto;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  padding-right: 6px;
  padding-left: 7px;
  margin-left: 4px;

  /* Font margins off */
  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 768px) {

.NotificationBadge_root__zq8Qp {
    margin-top: 0;
    margin-bottom: 0;

    top: -10px;
    position: relative
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.OutsideClickHandler_root__tyjJ9 {
  display: inline-block;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PackageAvailabilityModal_container__zHKfq {
  position: absolute;
  bottom: 0;
  height: auto;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.75rem 1.5rem 2.5rem 1.5rem;
  padding: 2.75rem 1.5rem 2.5rem 1.5rem;
  background-color: #fff;
  background-color: #fff;
  border-bottom: none;
  border-top-left-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top-right-radius: 10px;
  flex-grow: 0;
  min-height: auto;
  overflow: hidden
}

@media (min-width: 768px) {

.PackageAvailabilityModal_container__zHKfq {
    position: relative;
    margin: auto;
    width: 50%;
    width: 50%;
    height: auto;
    border-radius: 5px;
    border-radius: 5px;
    padding: 7rem 0;
    padding: 7rem 0;
    align-items: center
}
  }

.PackageAvailabilityModal_modalContent__2RK4b {
  padding: 0.75rem;
  padding: 0.75rem;
}

.PackageAvailabilityModal_buttonWrapper__1nwXG {
  width: 100%
}

@media (min-width: 768px) {

.PackageAvailabilityModal_buttonWrapper__1nwXG {
    max-width: 21rem;
    max-width: 21rem
}
  }

.PackageAvailabilityModal_notifyButton__i2C9I {
  background-color: #1a202c;
  background-color: #1a202c;
  color: #fff;
  color: #fff;
}

.PackageAvailabilityModal_icon__lGkA4 {
  width: 1rem;
  width: 1rem;
  height: 1.25rem;
  height: 1.25rem;
}

.PackageAvailabilityModal_content___pfj4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center
}

@media (min-width: 768px) {

.PackageAvailabilityModal_content___pfj4 {
    max-width: 25.75rem;
    max-width: 25.75rem
}
  }

.PackageAvailabilityModal_title__1UeeL {
  font-size: 24px;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem
}

@media (min-width: 768px) {

.PackageAvailabilityModal_title__1UeeL {
    font-size: 30px
}
  }

.PackageAvailabilityModal_subtitle__2NSJp {
  font-size: 16px;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.PackageAvailabilityModal_subtitle__2NSJp {
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

.PackageAvailabilityModal_footer__KyRu- {
  font-size: 14px;
  margin-top: 0.75rem;
  margin-top: 0.75rem
}

@media (min-width: 768px) {

.PackageAvailabilityModal_footer__KyRu- {
    max-width: 19rem;
    max-width: 19rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PackageAvailabilityNotifyButton_buttonWrapper__jlPxa {
  page-break-inside: avoid;
  break-inside: avoid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PackageAvailabilityNotifyButton_icon__OeTP3 {
  width: 1rem;
  width: 1rem;
  height: 1.25rem;
  height: 1.25rem;
  margin-right: 0.25rem;
  margin-right: 0.25rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PackageQuantityBadge_baseBadge__rHC6- {
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  font-size: 0.75rem;
  font-weight: 700;
  font-weight: 700;
  padding: 0.25rem 1rem;
  padding: 0.25rem 1rem;
  border-radius: 7px;
}

.PackageQuantityBadge_baseBadgeLarge__3Qxiz {
  font-size: 0.875rem;
  font-size: 0.875rem;
  padding: 0.75rem 0.5rem;
  padding: 0.75rem 0.5rem
}

@media (min-width: 768px) {

.PackageQuantityBadge_baseBadgeLarge__3Qxiz {
    padding: 0.75rem 1.25rem;
    padding: 0.75rem 1.25rem
}
  }

.PackageQuantityBadge_successBadge__iwlDt {
  background-color: #dee4df;
  background-color: #dee4df;
  color: #3e704b;
  color: #3e704b;
}

.PackageQuantityBadge_dangerBadge__2_cKf {
  background-color: #ffe9e5;
  background-color: #ffe9e5;
  color: #ed3615;
  color: #ed3615;
}

.PackageQuantityBadge_warningBadge__6f6AJ {
  background-color: #f5f2ed;
  background-color: #f5f2ed;
  color: #a36731;
  color: #a36731;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PackageQuantityBadges_root__3P8Uh {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

.PropertyGroup_root__4c6t6 {
  margin: 0;
}

@media (min-width: 768px) {

.PropertyGroup_twoColumns__1SP8V {
    column-count: 2
}
  }

.PropertyGroup_item__3u1BQ {
  display: flex;
  align-items: center;
  padding: 3px 0
}

@media (min-width: 768px) {

.PropertyGroup_item__3u1BQ {
    padding: 4px 0
}
  }

.PropertyGroup_checkIcon__2i_Ya {
}

.PropertyGroup_hidden__4IfvA {
  visibility: hidden;
}

.PropertyGroup_marketplaceFill__3677s {
  fill: #3e704b;
  fill: #3e704b;
}

.PropertyGroup_iconWrapper__1g027 {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  height: 24px;
  height: 24px;
}

.PropertyGroup_labelWrapper__1ufg- {
  display: inline-block;
}

.PropertyGroup_selectedLabel__3BbPc,
.PropertyGroup_notSelectedLabel__3EExV {
  font-weight: 500;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
  line-height: 24px;
  line-height: 24px;
  margin: 0;
}

.PropertyGroup_selectedLabel__3BbPc {
  font-weight: 400;
  font-weight: 400;
}

.PropertyGroup_notSelectedLabel__3EExV {
  color: #a1aec1;
  color: #a1aec1;
  position: relative;

  /* line-through */
  background-image: linear-gradient(
    transparent calc(24px - 9px),
    #a1aec1 calc(24px - 9px),
    #a1aec1 calc(24px - 8px),
    transparent calc(24px - 8px));
  background-image: linear-gradient(
    transparent calc(24px - 9px),
    #a1aec1 calc(24px - 9px),
    #a1aec1 calc(24px - 8px),
    transparent calc(24px - 8px))
}

@media (min-width: 768px) {

.PropertyGroup_notSelectedLabel__3EExV {
    background-image: linear-gradient(
      transparent calc(24px - 7px),
      #a1aec1 calc(24px - 7px),
      #a1aec1 calc(24px - 6px),
      transparent calc(24px - 6px));
    background-image: linear-gradient(
      transparent calc(24px - 7px),
      #a1aec1 calc(24px - 7px),
      #a1aec1 calc(24px - 6px),
      transparent calc(24px - 6px))
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.Handle_rootTouchBuffer__1vvF0 {
  /* Position */
  position: absolute;
  top: 50%;
  margin-top: -22px;
  margin-left: -22px;

  /* Layout */
  width: 44px;
  height: 44px;
  padding: 0;
  border: 0;
}

.Handle_visibleHandle__PACdg {
  /* Position */
  position: absolute;
  top: 12px;
  margin-left: 12px;

  /* Layout */
  width: 20px;
  height: 20px;
  border: 2px solid #3e704b;
  border: 2px solid #3e704b;
  border-radius: 50%;
  background-color: #ffffff;
  background-color: #ffffff;
  cursor: pointer
}

.Handle_visibleHandle__PACdg:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  }

.Handle_dragged__3w0UX {
  transform: scale(1.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.Track_root__2Sy6p {
  position: relative;
  width: 100%;
  height: 100%;
}

.Track_track__NIon5 {
  /* Position */
  position: absolute;
  top: 50%;
  margin-top: -4px;
  margin-left: -10px;

  /* Layout */
  width: 100%;
  height: 8px;
  padding: 0 10px;

  box-sizing: content-box;
  background-color: #a1aec1;
  background-color: #a1aec1;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.Track_range__3K8UQ {
  position: absolute;
  top: 50%;
  margin-top: -4px;
  height: 8px;
  background-color: #3e704b;
  background-color: #3e704b;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.RangeSlider_root__1fJsU {
  position: relative;
  margin: 0 10px;
  width: calc(100% - 20px);
  height: 24px;
}

.RangeSlider_activeHandle__3apC2 {
  z-index: 1;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.ResponsiveImage_root__2WjRI {
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: 100%;
}

.ResponsiveImage_noImageContainer__1EVNu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #a1aec1;
  background-color: #a1aec1;
}

.ResponsiveImage_noImageWrapper__1Jghx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ResponsiveImage_noImageIcon__1XmSv {
  padding-top: 24px;
  box-sizing: content-box;
  stroke: #4a5569;
  stroke: #4a5569;
}

.ResponsiveImage_noImageText__2v7AC {
  font-weight: bold;
  font-size: 0.875rem;
  font-size: 0.875rem;
  line-height: 18px;
  text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.ResponsiveImage_noImageText__2v7AC {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 6px
}
    }

.ResponsiveImage_noImageText__2v7AC {
  color: #4a5569;
  color: #4a5569;
  padding-bottom: 24px;

  margin-bottom: 0
}

@media (min-width: 768px) {

.ResponsiveImage_noImageText__2v7AC {
    margin-bottom: 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ValidationError_root__1SXA9 {
  color: #ca2d10;
  color: #ca2d10;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  color: #ca2d10;
  color: #ca2d10;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #ca2d10;
  border: 1px solid #ca2d10;
  padding: 0.75rem;
  padding: 0.75rem;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PageSlider_overlay__2ZTjQ {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
}

.PageSlider_overlayActive__1HPN6 {
  z-index: 60;
  z-index: 60;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.PageSlider_content__3EDGe {
  background: white;
  width: 100%;
  position: relative;
  overflow: auto;
  padding: 2.25rem 1.25rem;
  padding: 2.25rem 1.25rem;
  transition: width 0.3s ease-out
}

@media (min-width: 768px) {

.PageSlider_content__3EDGe {
    width: 0
}
  }

@media (min-width: 768px) {

.PageSlider_contentActive__1rB9q {
    padding: 5.75rem 5%;
    padding: 5.75rem 5%
}
  }

@media (min-width: 768px) {

.PageSlider_contentFull__ZpYyj {
    width: 100%
}
  }

.PageSlider_contentHalf__1QVw0 {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 60%;
  min-height: 60%;
  border-top-left-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top-right-radius: 20px
}

@media (min-width: 768px) {

.PageSlider_contentHalf__1QVw0 {
    width: 50%;
    width: 50%;
    height: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 0
}
  }

.PageSlider_buttonClose__25I-B {
  position: absolute;
  top: 1.5rem;
  top: 1.5rem;
  right: 1.5rem;
  right: 1.5rem;
  width: 3rem;
  width: 3rem;
  height: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: 0.25rem;
  padding: 0.25rem;
  background: #fff;
  background: #fff;
  border-color: transparent;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  z-index: 10;
  cursor: pointer
}

.PageSlider_buttonClose__25I-B:hover {
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.Tags_container__1q3e6 {
  width: 100%;
  max-width: 35.5rem;
  max-width: 35.5rem;
}

.Tags_header__3u3q3 {
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.Tags_headerLarge__2VNng {
  font-size: 1.25rem;
  font-size: 1.25rem;
}

.Tags_items__2nhDW {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.Tags_itemBase__2_T1H {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-size: 0.75rem;
  border-radius: 20px;
  border-radius: 20px;
  border: 1px solid #cbd5e0;
  border: 1px solid #cbd5e0;
  padding: 0.5rem 0.75rem;
  padding: 0.5rem 0.75rem
}

.Tags_itemBase__2_T1H:hover {
    text-decoration: none;
  }

.Tags_item__2Yyge {
}

.Tags_itemSelected__1WuEe {

  background-color: #1a202c;

  background-color: #1a202c;
  color: #fff;
  color: #fff;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.Button_root__2H8cf {
  padding: 0.75rem 2.5rem;
  padding: 0.75rem 2.5rem;
  border: 1px solid transparent;
  font-weight: 600;
  font-weight: 600;
  border-radius: 25px;
  border-radius: 25px;
  border-color: #718096;
  border-color: #718096;
  background-color: #fff;
  background-color: #fff;
  display: inline-block;
  cursor: pointer;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 0.875rem;
  font-size: 0.875rem
}

.Button_root__2H8cf:disabled {
    opacity: 0.5;
    opacity: 0.5;
    pointer-events: none;
  }

.Button_root__2H8cf:hover {
  text-decoration: none;
}

.Button_small__3c3BB {
  padding: 0.5rem 2rem;
  padding: 0.5rem 2rem;
}

.Button_xsmall__3eC8b {
  padding: 0.5rem 1rem;
  padding: 0.5rem 1rem;
}

.Button_bold__3swJk {
  font-weight: 700;
  font-weight: 700;
}

.Button_inlineTextButtonRoot__2Fub8 {
  cursor: pointer;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}

.Button_primaryButton__14Y_N {
  background-color: #3e704b;
  background-color: #3e704b;
  color: #fff;
  color: #fff;
  border-color: #3e704b;
  border-color: #3e704b;
}

.Button_primaryButton__14Y_N:hover {
  background-color: #2a5e38;
  background-color: #2a5e38;
  border-color: #2a5e38;
  border-color: #2a5e38;
}

.Button_primaryAnimation__3H5yR {
  background: linear-gradient(-45deg, #3e704b, #72a982, #3e704b);
  background: linear-gradient(-45deg, #3e704b, #72a982, #3e704b);
  background-size: 400% 400%;
  animation: Button_gradient__15oy0 4s ease infinite;
  transition: none;
  border-width: 0;
  padding-top: 13px;
  padding-bottom: 13px
}

.Button_primaryAnimation__3H5yR:hover {
    animation: none;
    background-color: inherit;
  }

.Button_secondaryButton__2d-cU {
  color: #1a202c;
  color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;
}

.Button_secondaryButton__2d-cU:hover {
  background-color: #1a202c;
  background-color: #1a202c;
  color: #fff;
  color: #fff;
}

.Button_tertiaryButton__3BRTu {
  background-color: #1a202c;
  background-color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;
  color: #fff;
  color: #fff;
}

.Button_tertiaryButton__3BRTu:hover {
  background-color: #1a202c;
  background-color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;
  color: #fff;
  color: #fff;
}

.Button_quaternaryButton__2A8ox {
  background-color: #fad44e;
  background-color: #fad44e;
  border-color: #000;
  border-color: #000;
  color: #1a202c;
  color: #1a202c;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
}

.Button_quaternaryButton__2A8ox:hover {
  background-color: #edc94a;
  background-color: #edc94a;
  border-color: #000;
  border-color: #000;
  color: #1a202c;
  color: #1a202c;
}

.Button_quinaryButton__2nEXQ {
  border-color: #e2e8f0;
  border-color: #e2e8f0;
  color: #1a202c;
  color: #1a202c;
}

.Button_quinaryButton__2nEXQ:hover {
  background-color: #f3f6f3;
  color: #1a202c;
  color: #1a202c;
}

.Button_quinaryButton__2nEXQ:focus {
  outline: 2px solid #1a202c;
  outline: 2px solid #1a202c;
}

.Button_fullWidth__VHzNs {
  width: 100%;
}

.Button_inProgress__1Fin6 {
}

.Button_primaryButton__14Y_N.Button_inProgress__1Fin6:disabled {
}

.Button_secondaryButton__2d-cU.Button_inProgress__1Fin6:disabled {
}

.Button_ready___VQs6 {
}

.Button_primaryButton__14Y_N.Button_ready___VQs6:disabled {
}

.Button_secondaryButton__2d-cU.Button_ready___VQs6:disabled {
}

.Button_spinner__HQigT {
  width: 1.75rem;
  width: 1.75rem;
  height: 1.25rem;
  height: 1.25rem;
  stroke: #ffffff;
  stroke: #ffffff;
  stroke-width: 3px;
}

.Button_checkmark__2s4v7 {
  width: 24px;
  height: 24px;

  /* Animation */
  animation-name: Button_checkmarkAnimation__25zCW;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes Button_checkmarkAnimation__25zCW {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.Button_secondaryButton__2d-cU .Button_spinner__HQigT {
  stroke: #a1aec1;
  stroke: #a1aec1;
}

.Button_secondaryButton__2d-cU .Button_checkmark__2s4v7 {
  stroke: #a1aec1;
  stroke: #a1aec1;
}

/* Social logins && SSO buttons */

.Button_socialButtonRoot__16t_O {
  font-weight: 400;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px
}

@media (min-width: 768px) {

.Button_socialButtonRoot__16t_O {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.Button_socialButtonRoot__16t_O {

    /* Dimensions */
  display: block;
  width: 100%;
  min-height: 59px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 16px 0 0 0;

    /* Borders */
  border-style: solid;
  border-color: #3e704b;
  border-color: #3e704b;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #3e704b;
  color: #3e704b;

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  cursor: pointer
}

.Button_socialButtonRoot__16t_O:hover,
    .Button_socialButtonRoot__16t_O:focus {
  text-decoration: none;
    }

@media (min-width: 768px) {

.Button_socialButtonRoot__16t_O {
  min-height: 60px
}
    }

.Button_socialButtonRoot__16t_O {
  color: #1a202c;
  color: #1a202c;
  border: 1px solid #cbd5e0;
  border: 1px solid #cbd5e0;
  border-radius: 10px;
  border-radius: 10px;
  min-height: 3rem;
  min-height: 3rem;
  vertical-align: middle;
  padding: 0.75rem 2.5rem;
  padding: 0.75rem 2.5rem;
  font-weight: 600;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 0.875rem;
  font-size: 0.875rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);

  /* We need to add this to position the icon inside button */
  position: relative
}

@media (min-width: 768px) {

.Button_socialButtonRoot__16t_O {
    padding: 0
}
  }

.Button_socialButtonRoot__16t_O:hover,
  .Button_socialButtonRoot__16t_O:focus {
  }

.Button_socialButtonRoot__16t_O:disabled {
    background-color: #a1aec1;
    background-color: #a1aec1;
  }

.Button_facebookButton__1KLuo,
.Button_googleButton__s4dof {
  text-align: center;
}

.Button_facebookButton__1KLuo {
  background-color: #3b5998;
  color: #fff;
  color: #fff;
}

.Button_googleButton__s4dof {
  background-color: #fff;
  background-color: #fff;
  color: var(--gray900);
}

@keyframes Button_gradient__15oy0 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.CookieConsent_root__1fSg4 {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100vw;
  padding: 0.25rem 0 6rem 0;
  padding: 0.25rem 0 6rem 0;
  background-color: #fff;
  background-color: #fff;
  color: #1a202c;
  color: #1a202c;
  border-top: solid 1px #e2e8f0;
  border-top: solid 1px #e2e8f0
}

@media (min-width: 1024px) {

.CookieConsent_root__1fSg4 {
    width: 60vw;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, 0);
    padding: 0.25rem;
    padding: 0.25rem;
    border-radius: 0.75rem;
    border-radius: 0.75rem;
    box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e2e8f0;
    border: solid 1px #e2e8f0
}
  }

.CookieConsent_container__1RP4U {
  display: flex;
  align-items: center;
  flex-direction: column
}

@media (min-width: 1024px) {

.CookieConsent_container__1RP4U {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 1rem 1.5rem 2rem;
    padding: 0 1rem 1.5rem 2rem
}
  }

.CookieConsent_message__3TFSA {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  width: 75%;
  width: 75%;
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin: 0 auto 2rem auto;
  margin: 0 auto 2rem auto;
  line-height: 1rem;
  line-height: 1rem
}

@media (min-width: 1024px) {

.CookieConsent_message__3TFSA {
    display: flex;
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
    flex: 1 1;
    font-size: 1rem;
    font-size: 1rem;
    margin: 2rem 0.5rem;
    margin: 2rem 0.5rem;
    width: 75%
}
  }

.CookieConsent_cookieLink__3RvQ9 {
  color: #1a202c;
  color: #1a202c
}

.CookieConsent_cookieLink__3RvQ9:hover {
    text-decoration: none;
  }

.CookieConsent_continueBtn__3ZNq_ {
  /* Font */
  font-weight: 600;
  font-weight: 600;
  width: 75%;
  width: 75%;
  min-height: 3rem;
  min-height: 3rem;

  /* Dimensions */
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;

  background-color: #1a202c;

  background-color: #1a202c;
  color: #fff;
  color: #fff;
  border: none;
  cursor: pointer;

  transition: ease-in-out 0.1s;

  transition: ease-in-out 0.1s
}

.CookieConsent_continueBtn__3ZNq_:hover {
    background-color: #718096;
    background-color: #718096;
    transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s;
  }

@media (min-width: 1024px) {

.CookieConsent_continueBtn__3ZNq_ {
    width: 20%;
    width: 20%;
    margin: 0 2.5rem;
    margin: 0 2.5rem
}
  }

.CookieConsent_close__-EYE4 {
  width: 2rem;
  width: 2rem;
  height: 2rem;
  height: 2rem;
  padding: 0.5rem;
  padding: 0.5rem;
  background-color: #fff;
  background-color: #fff;
  color: #1a202c;
  color: #1a202c;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  line-height: 1;
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer
}

@media (min-width: 1024px) {

.CookieConsent_close__-EYE4 {
    justify-content: flex-end;
    flex: 0 1 100%;
    margin-left: 1rem;
    margin-left: 1rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.ImageCarousel_root__3OcRN {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ImageCarousel_contentContainer__BoCIc {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.ImageCarousel_imageWrapper__1guxI {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImageCarousel_image__phu_i {
  width: 100%;
  object-fit: contain;
  max-height: 350px
}

@media (min-width: 768px) {

.ImageCarousel_image__phu_i {
    max-height: 700px
}
  }

.ImageCarousel_controlsContainer__1kX-_ {
  margin-top: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ImageCarousel_imageIndex__3kwh0 {
  color: #fff;
  color: #fff;
  font-size: var(--text-md);
  font-weight: 600;
  font-weight: 600;
}

.ImageCarousel_imageLoading__2qpn8 {
  opacity: 0.5;
}

.ImageCarousel_prev__1CQUk,
.ImageCarousel_next__2kW5k {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  margin: 0 1.5rem;
  margin: 0 1.5rem;
  background-position: center;
}

.ImageCarousel_prev__1CQUk {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="%23FFF" d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path></svg>');
}

.ImageCarousel_next__2kW5k {
  color: white;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="%23FFF" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path></svg>');
}

.ImageCarousel_captionsContainer__PVQ4N {
  margin-top: 2rem;
  margin-top: 2rem;
  color: #fff;
  color: #fff;
  font-size: var(--text-md);
  text-align: center;
}

.ImageCarousel_loading__13F3p {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  opacity: 0;
}

.ImageCarousel_loadingVisible__1OmLa {
  opacity: 1;
  transition: opacity ease-in 0.2s;
  transition: opacity ease-in 0.2s;
}

.ImageCarousel_thumbnailCarousel__XtnI9 {
  margin: 2rem 0;
  margin: 2rem 0;
  max-width: 100%;
  display: block
}

@media (min-width: 768px) {

.ImageCarousel_thumbnailCarousel__XtnI9 {
    display: none
}
  }

.ImageCarousel_thumbnailStep__3EdKE {
  flex-basis: 5rem !important;
  flex-basis: 5rem !important;
  padding: 0.25rem;
  padding: 0.25rem;
  cursor: pointer;
}

.ImageCarousel_thumbnail__2pvyV {
  width: 5rem;
  width: 5rem;
  height: 5rem;
  height: 5rem;
  object-fit: cover;
  border: 2px transparent solid;
  border-radius: 5px;
  border-radius: 5px
}

.ImageCarousel_thumbnail__2pvyV.ImageCarousel_active__1MvnW {
    border-color: #a1aec1;
    border-color: #a1aec1;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* A simple technique I developed here: https://codesandbox.io/s/flexbox-with-gaps-simple-qu7wl
   These helpers can be used to construct a flexbox layout that supports grid gaps like css grid does.
   This is not as easy as it seems, especially with a responsive flex layout that can wrap, this solution adds
   margins to add flex items then uses negative margins to offset the margins at the edge so that they don't
   interfere with elements outside of this one

   Usage:

   JSX:

   <div className={css.filtersWrapper}>
     <div className={css.filtersInner}>
       <div className={css.filter}>Item 1</div>
       <div className={css.filter}>Item 2</div>
       <div className={css.filter}>Item 3</div>
     </div>
   </div>

   CSS:

   @import '../FlexHelper/FlexHelper.css';

   // Decorate the wrapper here as you wish but don't add padding
   .filtersWrapper {
     @apply --flexWrapper;
   }

   // Don't add much except flexbox configuration here
   .filtersInner {
     @apply --flexInner3;
     justify-content: center; // Or whatever flex stuff you want to add
   }

   // Decorate each item as you wish but don't add margins
   .filter {
     @apply --flexItem3;

     // You can add widths here for more of a grid-like look.
     // You need to use / add helpers though because the widths need to be calculated using the margin
     @apply --flexItem3Width50Percent;

     @media (--screen-md) {
       @apply --flexItem3Width25Percent;
     }
   }

   In this simple version the gaps cannot be configured to change at breakpoints but ...

   Extra classes for different configurations could be added here and reused elsewhere as needed

   Or it can be expended to support a nice Bootstrap-style grid: https://codesandbox.io/s/pmxn0pqpyq
   - This implementation can be responsive but every use on the app is the same

   Or it can be used like this when uncoupled from the limitations of CSS Modules: https://codesandbox.io/s/mz13k4mw4x
   - This implementation can change behavior on a per usage basis but it cannot practically be done in CSS Modules
     without using real CSS Custom Properties
*/

.ScrollingCarousel_root__3vF3l {
  position: relative;
}

.ScrollingCarousel_carousel__134qH {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none
}

.ScrollingCarousel_carousel__134qH::-webkit-scrollbar {
    display: none;
  }

.ScrollingCarousel_step__2SlJF {
  scroll-snap-align: center;
  flex-basis: 100%;
  flex-shrink: 0
}

.ScrollingCarousel_step__2SlJF:last-child {
    margin-right: 0;
  }

@media (min-width: 768px) {

.ScrollingCarousel_step__2SlJF {
    flex-basis: 100%
}
  }

.ScrollingCarousel_controls__3ma8n {
  display: none
}

@media (min-width: 768px) {

.ScrollingCarousel_controls__3ma8n {
    display: block
}
  }

.ScrollingCarousel_button__3afIA {
  position: absolute;
  top: calc(50% - (3rem / 2));
  top: calc(50% - (3rem / 2));
  width: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  padding: 0.25rem;
  padding: 0.25rem;
  background: #fff;
  background: #fff;
  border-color: transparent;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  z-index: 10;
  opacity: 0.9;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.ScrollingCarousel_button__3afIA:hover {
  transform: scale(1.07);
  opacity: 1;
}

.ScrollingCarousel_buttonLeft__1oEmm {
  left: 6px;
}

.ScrollingCarousel_buttonRight__2m7zL {
  right: 6px;
}

.ScrollingCarousel_icon__1A9T9 {
  width: 0.75rem;
  width: 0.75rem;
  height: 0.75rem;
  height: 0.75rem;
  color: #000;
  color: #000;
}

.ScrollingCarousel_dots__339Nq {
  position: absolute;
  bottom: 0.5rem;
  bottom: 0.5rem;
  left: 0;
  right: 0;
  z-index: 10;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScrollingCarousel_dotsInner__2A5ZJ {
  display: flex;
  overflow: hidden;
}

.ScrollingCarousel_dot__fRqyf {
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  transition: transform 500ms
}

.ScrollingCarousel_dot__fRqyf::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #fff;
    background-color: #fff;
    transition: opacity 500ms, transform 500ms;
    opacity: 0.75;
    transform: translate(-50%, -50%);
  }

.ScrollingCarousel_dotDistance2__2yz_L::after {
    transform: translate(-50%, -50%) scale(0.9);
  }

.ScrollingCarousel_dotDistance3__2976T::after {
    transform: translate(-50%, -50%) scale(0.8);
  }

.ScrollingCarousel_dotDistanceGreaterThan3__-ml5E::after {
    transform: translate(-50%, -50%) scale(0.7);
  }

.ScrollingCarousel_dotVisible__2Bp6Z::after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.ImageFromFile_root__1VBiP {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: #a1aec1;
  background-color: #a1aec1; /* Loading BG color */
}

.ImageFromFile_threeToTwoWrapper__aKmeO {
  position: relative;
}

.ImageFromFile_aspectWrapper__3D7sx {
  padding-bottom: calc(100% * (2 / 3));
}

.ImageFromFile_rootForImage__nG5Nz {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
  border-radius: 2px;
}

.ListingLink_longWord__3E_6W {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.PaginationLinks_root__2wL6n {
}

.PaginationLinks_nav__2MKz2 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3rem;
  min-height: 3rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.PaginationLinks_prev__2a02L,
.PaginationLinks_next__3--nq {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  width: 3rem;
  height: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.PaginationLinks_prev__2a02L {
  margin-right: 1rem;
  margin-right: 1rem;
}

.PaginationLinks_next__3--nq {
  margin-left: 1rem;
  margin-left: 1rem;
}

.PaginationLinks_arrowIcon__1SuCv {
  /* Color for svg icons */
  fill: #3e704b;
  fill: #3e704b;
  stroke: #3e704b;
  stroke: #3e704b
}

.PaginationLinks_arrowIcon__1SuCv:hover {
    fill: #3e704b;
    fill: #3e704b;
    stroke: #3e704b;
    stroke: #3e704b;
  }

.PaginationLinks_pageNumberList__1BpeF {
  display: flex;
}

.PaginationLinks_toPageLink__3PqMN {
  color: #1a202c;
  color: #1a202c;
  font-size: 1.125rem;
  font-size: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  min-width: 2.5rem
}

.PaginationLinks_toPageLink__3PqMN:hover {
    text-decoration: none;
  }

.PaginationLinks_currentPage__3HvGe {
  color: #3e704b;
  color: #3e704b;
  font-weight: bold;
}

.PaginationLinks_paginationGapWrapper__3B3dN {
}

.PaginationLinks_notCurrentPage__1hJ76,
.PaginationLinks_paginationGapWrapper__3B3dN {
  display: flex;
}

.PaginationLinks_paginationGap__2FoFv {
  transform: translateY(-20%);
}

.PaginationLinks_text__3Wo1F {
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #4a5569;
  color: #4a5569;
  font-weight: bold;
  text-align: center;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ProgressBar_root__1msLR {
  background-color: #c3cec4;
  background-color: #c3cec4;
  border-radius: calc(0.5rem / 2);
  border-radius: calc(0.5rem / 2);
  height: 0.5rem;
  height: 0.5rem;
  overflow: hidden;
}

.ProgressBar_bar__iOUJC {
  background-color: #222720;
  background-color: #222720;
  height: 100%;
  transform: translateX(-100%);
  transition: ease-in 0.2s;
  transition: ease-in 0.2s;
  border-top-right-radius: calc(0.5rem / 2);
  border-top-right-radius: calc(0.5rem / 2);
  border-bottom-right-radius: calc(0.5rem / 2);
  border-bottom-right-radius: calc(0.5rem / 2);
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ReviewRating_reviewStar__2fPq8 {
  margin-right: 0.5rem;
  margin-right: 0.5rem
}

.ReviewRating_reviewStar__2fPq8:last-child {
    margin-right: 0;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.LayoutSideNavigation_root__3YI6F {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Container for side and content */

.LayoutSideNavigation_container__7eyem {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(10 - 1);
  z-index: calc(10 - 1)
}

@media (min-width: 1024px) {

.LayoutSideNavigation_container__7eyem {
    flex-direction: row;
    justify-content: center
}
  }

.LayoutSideNavigation_layoutWrapperMain__2TfS9 {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 24px
}

@media (min-width: 1024px) {

.LayoutSideNavigation_layoutWrapperMain__2TfS9 {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 112px 36px 82px 59px;
    background-color: #ffffff;
    background-color: #ffffff;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #a1aec1;
    border-left-color: #a1aec1
}
  }

@media (min-width: 1128px) {

.LayoutSideNavigation_layoutWrapperMain__2TfS9 {
    padding: 112px calc((100% - 1056px) / 2) 82px 59px
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.LayoutSingleColumn_root__3cKhi {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.LayoutSingleColumn_layoutWrapperMain__1wf6G {
  /* Use all available space parent element (aka root) can give and don't shrink content */
  flex-grow: 1;
  flex-shrink: 0;

  /* Expand to the full remaining width of the viewport */
  display: flex;
  flex-direction: column;
  width: 100%;
}

.LayoutWrapperFooter_root__2SW4C {
  width: 100%;
  position: relative;
}

.LayoutWrapperMain_root__175HC {
  /* Expand to the full remaining width of the viewport */
  display: flex;
  flex-direction: column;
  width: 100%;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.LayoutWrapperSideNav_root__4JRou {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 24px;

  border-top-width: 1px;
  border-top-color: #a1aec1;
  border-top-color: #a1aec1;
  border-top-style: solid;
  background-color: #ffffff;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1)
}

@media (min-width: 1024px) {

.LayoutWrapperSideNav_root__4JRou {
    padding: 112px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent
}
  }

@media (min-width: 1128px) {

.LayoutWrapperSideNav_root__4JRou {
    padding: 112px 0 82px calc((100% - 1056px) / 2)
}
  }

.LayoutWrapperSideNav_tabs__3zzuI {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  padding-top: 2px
}

@media (min-width: 768px) {

.LayoutWrapperSideNav_tabs__3zzuI {
    min-height: 56px;
    padding-top: 10px
}
  }

@media (min-width: 1024px) {

.LayoutWrapperSideNav_tabs__3zzuI {
    min-height: auto;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 0
}
  }

.LayoutWrapperSideNav_tab__4r23v {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px
}

.LayoutWrapperSideNav_tab__4r23v:first-child {
    margin-left: 0;
  }

@media (min-width: 1024px) {

.LayoutWrapperSideNav_tab__4r23v {
    margin-left: 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.LayoutWrapperTopbar_root__3khlh {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 60;
  z-index: 60;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.Container_root__A-lLr {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 640px) {

.Container_root__A-lLr {
  max-width: 640px;
  max-width: 640px;
}
    }

@media (min-width: 768px) {

.Container_root__A-lLr {
  max-width: 768px;
  max-width: 768px;
}
    }

@media (min-width: 1024px) {

.Container_root__A-lLr {
  max-width: 1024px;
  max-width: 1024px;
}
    }

@media (min-width: 1280px) {

.Container_root__A-lLr {
  max-width: 1280px;
  max-width: 1280px;
}
    }

.Container_fullWidthRoot__TEcMx {
  max-width: 100%;
}

.MenuItem_root__2UM8H {
  height: 100%;
  width: 100%;
  margin: 0;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.MenuContent_root__3cIA2 {
  position: absolute;
  z-index: 10;
  z-index: 10;

  background-color: #fff;

  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-radius: 5px;
  transform: translateY(10px);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
}

.MenuContent_isClosed__3BnJL {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.MenuContent_isOpen__ocqpy {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.MenuContent_content__3TyJg {
}

/* Styles for arrow (if arrowPosition is defined) */

.MenuContent_arrowTop__3T1hs,
.MenuContent_arrowBelow__34GwL {
  content: ' ';
  position: absolute;
  bottom: 100%;
  height: 0;
  width: 0;
  border: solid transparent;
  pointer-events: none;
}

.MenuContent_arrowTop__3T1hs {
  border-bottom-color: #fff;
  border-bottom-color: #fff;
  border-width: 7px;
  margin-left: -7px;
}

.MenuContent_arrowBelow__34GwL {
  border-width: 9px;
  margin-left: -9px;
}

.MenuLabel_root__1uLId {
  height: 100%;
  width: 100%;
  border: 0;
  cursor: pointer;
  background-color: transparent
}

.MenuLabel_root__1uLId:hover {
    background-color: transparent;
    box-shadow: none;
  }

.MenuLabel_clickedWithMouse__1nitF:focus {
    outline: none;
  }

/* Default isOpen class - no styles yet */

.MenuLabel_isOpen__3C6Iz {
}

.Menu_root__1gX6s {
  width: auto;
  height: 100%;
  position: relative;
  outline: none;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

/* ModalInMobile is visible by default */

.ModalInMobile_root__1jVhi {
  width: 100%;
}

/* Content is hidden in Mobile layout */

.ModalInMobile_modalHidden__2JPI2 {
  display: none;
}

.ModalInMobile_modalContainer__55TyJ {
  flex-grow: 1;

    /* Create context so the close button can be positioned within it */
  position: relative;

    /* Display and dimensions */
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;

  background-color: #ffffff;

  background-color: #ffffff;
  border-radius: 2px;
  border-radius: 2px
}

@media (min-width: 768px) {

.ModalInMobile_modalContainer__55TyJ {
  flex-basis: 480px;
  flex-grow: 0;
  height: 100%;
  padding: 0;
  background-color: transparent;
  border-radius: 0
}
    }

@media (min-width: 768px) {

.ModalInMobile_modalContainer__55TyJ {
    flex-basis: 576px
}
  }

.ModalInMobile_modalContent__10JLT {
  width: 100%
}

@media (min-width: 768px) {

.ModalInMobile_modalContent__10JLT {
    height: 100%;

    /* ModalInMobile content should work with box-shadows etc. */
    overflow: visible
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ModalHeadings_heading__2KIYb {
  margin-top: 2rem;
  margin-top: 2rem;
  margin-bottom: 0;
}

.ModalHeadings_subHeading__QFL0D {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
  color: #1a202c;
  color: #1a202c;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.FieldBirthdayInput_fieldRoot__1fmj8 {
}

.FieldBirthdayInput_inputRoot__3HYBj {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.FieldBirthdayInput_selectWrapper__3zpmN {
  flex-basis: calc(33% - 12px);
}

.FieldBirthdayInput_select__jcfoW {
  display: inline-block;

  /* Border */
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #ffaa00;
  border-bottom-color: #ffaa00
}

.FieldBirthdayInput_select__jcfoW:hover,
  .FieldBirthdayInput_select__jcfoW:focus {
    border-bottom-color: #1a202c;
    border-bottom-color: #1a202c;
    outline: none;
  }

.FieldBirthdayInput_notSet__3MdBI {
  color: #a1aec1;
  color: #a1aec1;
}

.FieldBirthdayInput_selectSuccess__2Hgxj {
  color: #4a5569;
  color: #4a5569;
  border-bottom-color: #2ecc71;
  border-bottom-color: #2ecc71;
}

.FieldBirthdayInput_selectError__tUYZQ {
  border-bottom-color: #ff0000;
  border-bottom-color: #ff0000;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldCheckbox_root__2Thbp {
  position: relative;
}

.FieldCheckbox_input__3ltOm {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0

  /* Highlight the borders if the checkbox is hovered, focused or checked */
}

.FieldCheckbox_input__3ltOm:hover + label .FieldCheckbox_border__3BwSh,
  .FieldCheckbox_input__3ltOm:checked + label .FieldCheckbox_border__3BwSh {
    fill: #3e704b;
    fill: #3e704b;
  }

.FieldCheckbox_input__3ltOm {

  /* successColor version */
}

.FieldCheckbox_input__3ltOm:hover + label .FieldCheckbox_borderSuccess__T8nv7,
  .FieldCheckbox_input__3ltOm:focus + label .FieldCheckbox_borderSuccess__T8nv7,
  .FieldCheckbox_input__3ltOm:checked + label .FieldCheckbox_borderSuccess__T8nv7 {
    fill: #4ca817;
    fill: #4ca817;
  }

.FieldCheckbox_input__3ltOm {

  /* Display the "check" when checked */
}

.FieldCheckbox_input__3ltOm:checked + label .FieldCheckbox_checkedBackground__2SJYa {
    display: inline;
  }

.FieldCheckbox_input__3ltOm {

  /* Display the "check" when checked */
}

.FieldCheckbox_input__3ltOm:checked + label .FieldCheckbox_checkedBackgroundSuccess__juty5 {
    display: inline;
  }

.FieldCheckbox_label__1vHCf {
  display: flex;
  align-items: center;
  padding: 0;
}

.FieldCheckbox_checkboxWrapper__1ckb3 {
  /* This should follow line-height */
  height: 32px;
  margin-top: -1px;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.FieldCheckbox_checkedBackground__2SJYa {
  display: none;
  fill: #3e704b;
  fill: #3e704b;
}

.FieldCheckbox_checkedBackgroundSuccess__juty5 {
  display: none;
  fill: #4ca817;
  fill: #4ca817;
}

.FieldCheckbox_borderSuccess__T8nv7,
.FieldCheckbox_border__3BwSh {
  fill: #e2e8f0;
  fill: #e2e8f0;
}

.FieldCheckbox_text__3LvFt {
}

.FieldCheckbox_textRoot__3m0uL {
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldCheckboxList_root__1g5BV {
}

.FieldCheckboxList_listWithHeading__3I03x {
  margin-top: 1.25rem;
  margin-top: 1.25rem;
}

.FieldCheckboxList_heading__2iAQQ {
  color: #1a202c;
  color: #1a202c;
  font-weight: 600;
  font-weight: 600;
  margin: 0
}

@media (min-width: 768px) {

.FieldCheckboxList_heading__2iAQQ {
    font-size: 1.125rem;
    font-size: 1.125rem
}
  }

.FieldCheckboxList_subHeading__23KGu {
  color: #1a202c;
  color: #1a202c;
  margin: 0
}

@media (min-width: 768px) {

.FieldCheckboxList_subHeading__23KGu {
    font-size: 0.875rem;
    font-size: 0.875rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldCheckboxListItem_root__1amF5 {
  margin-bottom: 2.5rem;
  margin-bottom: 2.5rem
}

@media (min-width: 768px) {

.FieldCheckboxListItem_root__1amF5 {
    margin-bottom: 1.5rem;
    margin-bottom: 1.5rem
}
  }

.FieldCheckboxListItem_root__1amF5:last-child {
    margin-bottom: 0;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldCopy_root__21C8E {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldCurrencyInput_input__SXHbA {
}

.FieldCurrencyInput_inputSuccess__3T6bL {
}

.FieldCurrencyInput_inputError__2IXS- {
  border-color: #ed3615;
  border-color: #ed3615;
}

.FieldCurrencyInput_inputWithPostfix__3Hr0n {
  display: flex;
  align-items: center;
}

.FieldCurrencyInput_postfixLabel__2VBeh {
  font-weight: 700;
  font-weight: 700;
  margin-left: 0.75rem;
  margin-left: 0.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldLabel_root__12W9Y {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.FieldLabel_description__3TBnF {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: normal;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  display: block;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* Distance to top */
}

.DateInput_inputRoot__1V3Vx {
  /* Contain repainting to this component only */
  transform: translate3d(0, 0, 0)

  /* Override react-dates default styles to match input styles */
}

.DateInput_inputRoot__1V3Vx .SingleDatePicker {
    display: block;
  }

.DateInput_inputRoot__1V3Vx .SingleDatePicker_picker__directionLeft {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 36px !important;
    width: 100%;
    min-height: calc(100vh - 252px);
    min-height: calc(100vh - 252px);
    background-color: #3e704b;
    background-color: #3e704b
  }

@media (min-width: 768px) {

.DateInput_inputRoot__1V3Vx .SingleDatePicker_picker__directionLeft {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 40px !important;
      min-height: auto;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
      border-radius: 0 0 2px 2px
  }
    }

.DateInput_inputRoot__1V3Vx .DateInput_input {
  font-weight: 500;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px
    /* No margins for default font */
  }

@media (min-width: 768px) {

.DateInput_inputRoot__1V3Vx .DateInput_input {
  font-size: 20px;
  line-height: 24px
  }
    }

.DateInput_inputRoot__1V3Vx .DateInput_input {
    /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 5px 0 5px 0;

    /* Borders */
  border: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #3e704b;
  border-bottom-color: #3e704b;
  border-radius: 0
  }

.DateInput_inputRoot__1V3Vx .DateInput_input:-ms-input-placeholder {
  color: #a1aec1;
  color: #a1aec1;
    }

.DateInput_inputRoot__1V3Vx .DateInput_input::placeholder {
  color: #a1aec1;
  color: #a1aec1;
    }

.DateInput_inputRoot__1V3Vx .DateInput_input {

    /* Effects */

  transition: border-bottom-color ease-in 0.2s;

  transition: border-bottom-color ease-in 0.2s
  }

.DateInput_inputRoot__1V3Vx .DateInput_input:hover,
    .DateInput_inputRoot__1V3Vx .DateInput_input:focus {
  border-bottom-color: #4a5569;
  border-bottom-color: #4a5569;
  outline: none;
    }

@media (min-width: 768px) {

.DateInput_inputRoot__1V3Vx .DateInput_input {
  padding: 4px 0 10px 0
  }
    }

.DateInput_inputRoot__1V3Vx .DateInput_input {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #ffaa00;
    border-bottom-color: #ffaa00;
    transition: border-bottom-color ease-in 0.2s;
    transition: border-bottom-color ease-in 0.2s;
    transition: all 0.15s ease-out;
    background: none
  }

@media (min-width: 768px) {

.DateInput_inputRoot__1V3Vx .DateInput_input {
      padding: 4px 0 9px 0;
      border-bottom-width: 3px
  }
    }

.DateInput_inputRoot__1V3Vx .SingleDatePickerInput {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }

.DateInput_inputRoot__1V3Vx .DayPicker__horizontal {
    margin: 0 auto;
    background-color: #3e704b;
    background-color: #3e704b;
    box-shadow: none;
  }

.DateInput_inputRoot__1V3Vx .DayPickerNavigation__horizontal {
    position: relative;
    width: 100%;
  }

.DateInput_inputRoot__1V3Vx .DayPickerNavigation_button__horizontal {
    padding: 6px 9px;
    top: 19px;
    position: absolute;
    cursor: pointer;
    display: inline
  }

.DateInput_inputRoot__1V3Vx .DayPickerNavigation_button__horizontal:first-of-type {
      left: 24px;
    }

.DateInput_inputRoot__1V3Vx .DayPickerNavigation_button__horizontal:last-of-type {
      right: 24px;
    }

.DateInput_inputRoot__1V3Vx .DayPickerNavigation_button {
    color: #ffffff;
    color: #ffffff;
    border: 0;
  }

.DateInput_inputRoot__1V3Vx .CalendarMonth,
  .DateInput_inputRoot__1V3Vx .CalendarMonthGrid {
    background-color: transparent;
  }

.DateInput_inputRoot__1V3Vx .DateInput {
    display: block;
    width: 100%;
  }

.DateInput_inputRoot__1V3Vx .DayPicker_weekHeader {
    color: #ffffff;
    color: #ffffff;
    top: 57px;
  }

.DateInput_inputRoot__1V3Vx .DayPicker_weekHeader_li {
    font-weight: 400;
  }

.DateInput_inputRoot__1V3Vx .CalendarMonth_caption {
    color: #ffffff;
    color: #ffffff;
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px
  }

@media (min-width: 768px) {

.DateInput_inputRoot__1V3Vx .CalendarMonth_caption {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px
  }
    }

.DateInput_inputRoot__1V3Vx .CalendarMonth_caption {
    margin: 1px 0 14px;
    font-weight: 400
  }

@media (min-width: 768px) {

.DateInput_inputRoot__1V3Vx .CalendarMonth_caption {
      margin-top: 0;
      margin-bottom: 0
  }
    }

.DateInput_inputRoot__1V3Vx .CalendarDay__default {
    background-color: #3e704b;
    background-color: #3e704b;
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

.DateInput_inputRoot__1V3Vx .CalendarDay {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
  }

@media (min-width: 768px) {

.DateInput_inputRoot__1V3Vx .CalendarDay {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
  }
    }

.DateInput_inputRoot__1V3Vx .CalendarDay {
    color: #ffffff;
    color: #ffffff;
    border: 0;
    margin-top: 0;
    margin-bottom: 0
  }

@media (min-width: 768px) {

.DateInput_inputRoot__1V3Vx .CalendarDay {
      margin-top: 0;
      margin-bottom: 0
  }
    }

.DateInput_inputRoot__1V3Vx {

  /* Add an underline for '.renderedDay' */
}

.DateInput_inputRoot__1V3Vx .CalendarDay__today .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
    background-position: center 28px;
  }

.DateInput_inputRoot__1V3Vx {

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateInput_inputRoot__1V3Vx .CalendarDay__hovered_span,
  .DateInput_inputRoot__1V3Vx .CalendarDay__selected_span {
    background-image: transparent;
    background-color: transparent;
  }

.DateInput_inputRoot__1V3Vx .CalendarDay__hovered_span .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
  }

.DateInput_inputRoot__1V3Vx .CalendarDay__selected_span .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-color: #2ecc71;
    background-color: #2ecc71;
    transition: all 0.2s ease-out;
  }

.DateInput_inputRoot__1V3Vx {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateInput_inputRoot__1V3Vx .CalendarDay__selected {
    background-color: transparent;
    background-image: none;
  }

.DateInput_inputRoot__1V3Vx .CalendarDay__selected .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-color: #2ecc71;
    background-color: #2ecc71;
    border-radius: calc(36px / 2);
    border-radius: calc(36px / 2);
  }

.DateInput_inputRoot__1V3Vx {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateInput_inputRoot__1V3Vx .CalendarDay__after-hovered {
    background-color: transparent;
  }

.DateInput_inputRoot__1V3Vx .CalendarDay__after-hovered .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
  }

.DateInput_inputRoot__1V3Vx .CalendarDay:hover .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
  }

.DateInput_inputRoot__1V3Vx {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateInput_inputRoot__1V3Vx .CalendarDay__blocked_out_of_range,
  .DateInput_inputRoot__1V3Vx .CalendarDay__blocked_out_of_range:active,
  .DateInput_inputRoot__1V3Vx .CalendarDay__blocked_out_of_range:hover {
    background-color: transparent;
    color: #3e704b;
    color: #3e704b;
    border: 0;
  }

.DateInput_inputRoot__1V3Vx {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateInput_inputRoot__1V3Vx .CalendarDay__blocked_calendar,
  .DateInput_inputRoot__1V3Vx .CalendarDay__blocked_calendar:active,
  .DateInput_inputRoot__1V3Vx .CalendarDay__blocked_calendar:hover {
    background-color: transparent;
    color: #3e704b;
    color: #3e704b;
    border: 0;
  }

.DateInput_inputRoot__1V3Vx .CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay {
    background-color: transparent;
  }

.DateInput_inputRoot__1V3Vx .DateInput_fang {
    display: none;
  }

.DateInput_inputRoot__1V3Vx .CalendarMonth_caption {
    text-transform: capitalize;
  }

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */

.DateInput_withMobileMargins__2bTeE .SingleDatePickerInput {
    width: calc(100vw - 48px);
    margin: 0 24px
  }

@media (min-width: 768px) {

.DateInput_withMobileMargins__2bTeE .SingleDatePickerInput {
      width: 100%;
      margin: 0
  }
    }

.DateInput_withMobileMargins__2bTeE .SingleDatePicker_picker__directionLeft {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 34px !important;
    border-top: 2px solid #1a202c;
    border-top: 2px solid #1a202c;
    min-height: calc(100vh - 252px + 2px);
    min-height: calc(100vh - 252px + 2px)
  }

@media (min-width: 768px) {

.DateInput_withMobileMargins__2bTeE .SingleDatePicker_picker__directionLeft {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 40px !important;
      border-top: 0;
      min-height: auto
  }
    }

.DateInput_withMobileMargins__2bTeE {

  /* Create gutter between inputs */
}

.DateInput_withMobileMargins__2bTeE .DateInput {
    width: 100%;
    background: none;
  }

.DateInput_rootNextMonthIcon__3xNFD,
.DateInput_rootPreviousMonthIcon__2vHT2 {
  stroke: #ffffff;
  stroke: #ffffff;
  fill: #ffffff;
  fill: #ffffff;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.FieldDateInput_fieldRoot__3t8h9 {
}

.FieldDateInput_pickerSuccess__2H13N .DateInput_input {
    border-bottom-color: #2ecc71;
    border-bottom-color: #2ecc71;
  }

.FieldDateInput_pickerError__3hKCe .DateInput_input {
    border-bottom-color: #ff0000;
    border-bottom-color: #ff0000;
  }

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */

.FieldDateInput_mobileMargins__GxUJW {
  width: calc(100% - 48px);
  margin: 0 24px
}

@media (min-width: 768px) {

.FieldDateInput_mobileMargins__GxUJW {
    width: 100%;
    margin: 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

.DateRangeController_inputRoot__twWPF {
  /*
    Calendar component using react-dates has automatically padding so
    negative margin to left and right is needed for element to fit on smaller screens.
  */

  margin: 0px
}

@media (min-width: 768px) {

.DateRangeController_inputRoot__twWPF {
    margin: 0
}
  }

.DateRangeController_inputRoot__twWPF .DayPicker_transitionContainer__horizontal {
    /* NOTE: there's a bug on iPhone where on the initial render the dynamic
      height isn't calculated properly, but on refresh it is. Forcing a min-height.
    */
    min-height: 334px;
  }

.DateRangeController_inputRoot__twWPF .CalendarMonthGrid {
    background-color: transparent;
  }

.DateRangeController_inputRoot__twWPF .DayPicker__horizontal {
    margin: 0 auto;
    box-shadow: none;
    background-color: transparent;
  }

.DateRangeController_inputRoot__twWPF .DayPickerNavigation__horizontal {
    position: relative;
    width: 100%;
  }

.DateRangeController_inputRoot__twWPF .DayPickerNavigation_button__horizontal {
    padding: 6px 9px;
    top: 21px;
    position: absolute;
    cursor: pointer;
    display: inline
  }

.DateRangeController_inputRoot__twWPF .DayPickerNavigation_button__horizontal:first-of-type {
      left: 24px;
    }

.DateRangeController_inputRoot__twWPF .DayPickerNavigation_button__horizontal:last-of-type {
      right: 24px;
    }

.DateRangeController_inputRoot__twWPF .DayPickerNavigation_button {
    color: #4a5569;
    color: #4a5569;
    border: 0;
  }

.DateRangeController_inputRoot__twWPF .CalendarMonth,
  .DateRangeController_inputRoot__twWPF .CalendarMonthGrid {
    background-color: transparent;
  }

.DateRangeController_inputRoot__twWPF .DayPicker_weekHeader {
    color: #4a5569;
    color: #4a5569;
    top: 62px;
  }

.DateRangeController_inputRoot__twWPF .DayPicker_weekHeader_li {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
  }

@media (min-width: 768px) {

.DateRangeController_inputRoot__twWPF .DayPicker_weekHeader_li {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
  }
    }

.DateRangeController_inputRoot__twWPF .DayPicker_weekHeader_li {

    margin-top: 0;
    margin-bottom: 0
  }

@media (min-width: 768px) {

.DateRangeController_inputRoot__twWPF .DayPicker_weekHeader_li {
      margin-top: 0;
      margin-bottom: 0
  }
    }

.DateRangeController_inputRoot__twWPF .DayPicker_weekHeader_li small {
    font-size: 100%;
  }

.DateRangeController_inputRoot__twWPF .CalendarMonth_caption {
    color: #1a202c;
    color: #1a202c;
    font-weight: 400;
    line-height: 20px;
    padding-top: 31px;
    padding-bottom: 37px
  }

.DateRangeController_inputRoot__twWPF .CalendarMonth_caption::first-letter {
      text-transform: capitalize;
    }

@media (min-width: 768px) {

.DateRangeController_inputRoot__twWPF .CalendarMonth_caption {
      margin-top: 0;
      margin-bottom: 0
  }
    }

.DateRangeController_inputRoot__twWPF .CalendarDay__default {
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }

.DateRangeController_inputRoot__twWPF .CalendarDay {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
  }

@media (min-width: 768px) {

.DateRangeController_inputRoot__twWPF .CalendarDay {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
  }
    }

.DateRangeController_inputRoot__twWPF .CalendarDay {
    color: #4a5569;
    color: #4a5569;
    border: 0;
    margin-top: 0;
    margin-bottom: 0
  }

@media (min-width: 768px) {

.DateRangeController_inputRoot__twWPF .CalendarDay {
      margin-top: 0;
      margin-bottom: 0
  }
    }

.DateRangeController_inputRoot__twWPF {

  /* Add an underline for '.renderedDay' */
}

.DateRangeController_inputRoot__twWPF .CalendarDay__today .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%234A4A4A' fill-rule='evenodd'/></svg>");
    background-position: center 28px;
  }

.DateRangeController_inputRoot__twWPF .CalendarDay__today.CalendarDay__selected .renderedDay {
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
  }

.DateRangeController_inputRoot__twWPF .CalendarDay:hover .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    border: 1px solid #3e704b;
    border: 1px solid #3e704b;
  }

.DateRangeController_inputRoot__twWPF {

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeController_inputRoot__twWPF .CalendarDay__hovered_span,
  .DateRangeController_inputRoot__twWPF .CalendarDay__selected_span {
    background-image: transparent;
    background-color: transparent;
  }

.DateRangeController_inputRoot__twWPF .CalendarDay__hovered_span .renderedDay,
  .DateRangeController_inputRoot__twWPF .CalendarDay__selected_span .renderedDay,
  .DateRangeController_inputRoot__twWPF .CalendarDay__hovered_span:hover .renderedDay,
  .DateRangeController_inputRoot__twWPF .CalendarDay__selected_span:hover .renderedDay,
  .DateRangeController_inputRoot__twWPF .CalendarDay__hovered_span.CalendarDay__hovered_span .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-color: #dee4df;
    background-color: #dee4df;
    color: #000;
    color: #000;
    border-color: transparent;
  }

.DateRangeController_inputRoot__twWPF .CalendarDay__blocked_minimum_nights .renderedDay,
  .DateRangeController_inputRoot__twWPF .CalendarDay__blocked_minimum_nights:hover .renderedDay {
    background-color: transparent;
    color: #a1aec1;
    color: #a1aec1;
    border: 0;
    text-decoration: line-through
  }

.DateRangeController_inputRoot__twWPF .CalendarDay__blocked_minimum_nights .renderedDay > span, .DateRangeController_inputRoot__twWPF .CalendarDay__blocked_minimum_nights:hover .renderedDay > span {
      cursor: default !important;
    }

.DateRangeController_inputRoot__twWPF .CalendarDay__hovered_span.CalendarDay__blocked_minimum_nights .renderedDay {
    text-decoration: none;
  }

.DateRangeController_inputRoot__twWPF .CalendarDay__selected_start.CalendarDay__blocked_minimum_nights .renderedDay {
    text-decoration: none;
  }

.DateRangeController_inputRoot__twWPF {

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeController_inputRoot__twWPF .CalendarDay__selected_start {
    background-color: transparent;
    background-image: none;
  }

.DateRangeController_inputRoot__twWPF .CalendarDay__selected_start .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    color: #fff;
    color: #fff;
    border: 1px solid #3e704b;
    border: 1px solid #3e704b;
    background-color: #3e704b;
    background-color: #3e704b;
  }

.DateRangeController_inputRoot__twWPF .CalendarDay__selected_start .renderedDay.startDateBackground {
    background-color: #dee4df;
    background-color: #dee4df;
  }

.DateRangeController_inputRoot__twWPF {

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeController_inputRoot__twWPF .CalendarDay__selected_end {
    background-color: transparent;
  }

.DateRangeController_inputRoot__twWPF .CalendarDay__selected_end .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-color: #3e704b;
    background-color: #3e704b;
    color: #ffffff;
    color: #ffffff;
  }

.DateRangeController_inputRoot__twWPF .CalendarDay:hover.CalendarDay__selected_start .renderedDay,
  .DateRangeController_inputRoot__twWPF .CalendarDay:hover.CalendarDay__selected_span .renderedDay,
  .DateRangeController_inputRoot__twWPF .CalendarDay:hover.CalendarDay__selected_end .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-color: #3e704b;
    background-color: #3e704b;
    color: #ffffff;
    color: #ffffff;
  }

.DateRangeController_inputRoot__twWPF .CalendarDay__hovered_offset {
    background: #edf2f7;
    background: #edf2f7;
    color: inherit;
  }

.DateRangeController_inputRoot__twWPF {

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeController_inputRoot__twWPF .CalendarDay__blocked_calendar .renderedDay,
  .DateRangeController_inputRoot__twWPF .CalendarDay__blocked_out_of_range .renderedDay,
  .DateRangeController_inputRoot__twWPF .CalendarDay__blocked_calendar:hover .renderedDay,
  .DateRangeController_inputRoot__twWPF .CalendarDay__blocked_out_of_range:hover .renderedDay {
    background-color: transparent;
    color: #a1aec1;
    color: #a1aec1;
    text-decoration: line-through;
    border: 0;
  }

.DateRangeController_inputRootResponsive__3WDP- {
  /* Make react-dates full with and fluid
     Mostly from https://github.com/airbnb/react-dates/issues/108#issuecomment-422333866
     with a couple of additions to remove the left/right empty space */
}

.DateRangeController_inputRootResponsive__3WDP- .DayPicker,
  .DateRangeController_inputRootResponsive__3WDP- .DayPicker > div,
  .DateRangeController_inputRootResponsive__3WDP- .DayPicker > div > div,
  .DateRangeController_inputRootResponsive__3WDP- .DayPicker_transitionContainer,
  .DateRangeController_inputRootResponsive__3WDP- .CalendarMonth_table {
    width: 100% !important;
  }

.DateRangeController_inputRootResponsive__3WDP- .CalendarMonthGrid {
    width: 1000% !important;
  }

.DateRangeController_inputRootResponsive__3WDP- .CalendarMonthGrid_month__horizontal {
    width: calc(10%) !important;
  }

.DateRangeController_inputRootResponsive__3WDP- .DayPicker_weekHeader {
    width: calc(100%) !important;
    top: 44px;
  }

.DateRangeController_inputRootResponsive__3WDP- .CalendarDay,
  .DateRangeController_inputRootResponsive__3WDP- .DayPicker_weekHeader_li {
    width: calc(100% / 7) !important;
  }

.DateRangeController_inputRootResponsive__3WDP- .CalendarMonthGrid__horizontal {
    left: 0;
  }

.DateRangeController_inputRootResponsive__3WDP- .DayPicker_weekHeaders__horizontal {
    margin-left: 0;
  }

.DateRangeController_inputRootResponsive__3WDP- .DayPickerNavigation_button__horizontal {
    padding: 0 16px;
    top: 0
  }

.DateRangeController_inputRootResponsive__3WDP- .DayPickerNavigation_button__horizontal:first-of-type {
      left: 0;
    }

.DateRangeController_inputRootResponsive__3WDP- .DayPickerNavigation_button__horizontal:last-of-type {
      right: 0;
    }

.DateRangeController_inputRootResponsive__3WDP- .CalendarMonth_caption {
    line-height: inherit;
    padding-top: 0px;
    padding-bottom: 48px;
  }

.DateRangeController_arrowIcon__tb56f {
  stroke: #3e704b;
  stroke: #3e704b;
  fill: #3e704b;
  fill: #3e704b;
}

.DateRangeController_clearDatesLink__L6OCS {
  display: block;
  width: 100%;
  text-align: center;
  text-decoration: underline;
  color: #000;
  color: #000;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.DateRangeController_tooltipRoot__3pG_H {
  padding-top: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* Distance to top */
}

.DateRangeInput_inputRoot__FTtE_ {
  /* Contain repainting to this component only */
  transform: translate3d(0, 0, 0)

  /* Override react-dates default styles to match input styles */
}

.DateRangeInput_inputRoot__FTtE_ .DateRangePicker {
    display: block;
  }

.DateRangeInput_inputRoot__FTtE_ .DateRangePicker_picker__directionLeft {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 36px !important;
    width: 100%;
    min-height: calc(100vh - 252px);
    min-height: calc(100vh - 252px);
    background-color: #3e704b;
    background-color: #3e704b
  }

@media (min-width: 768px) {

.DateRangeInput_inputRoot__FTtE_ .DateRangePicker_picker__directionLeft {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 42px !important;
      min-height: auto;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
      border-radius: 0 0 2px 2px
  }
    }

.DateRangeInput_inputRoot__FTtE_ .DateInput_input {
    padding: 0;
    border: 0;
    background: none;
    text-align: center;
  }

.DateRangeInput_inputRoot__FTtE_ .DateRangePickerInput {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }

.DateRangeInput_inputRoot__FTtE_ .DayPicker__horizontal {
    margin: 0 auto;
    background-color: #3e704b;
    background-color: #3e704b;
    box-shadow: none;
  }

.DateRangeInput_inputRoot__FTtE_ .DayPickerNavigation__horizontal {
    position: relative;
    width: 100%;
  }

.DateRangeInput_inputRoot__FTtE_ .DayPickerNavigation_button__horizontal {
    padding: 6px 9px;
    top: 19px;
    position: absolute;
    cursor: pointer;
    display: inline
  }

.DateRangeInput_inputRoot__FTtE_ .DayPickerNavigation_button__horizontal:first-of-type {
      left: 24px;
    }

.DateRangeInput_inputRoot__FTtE_ .DayPickerNavigation_button__horizontal:last-of-type {
      right: 24px;
    }

.DateRangeInput_inputRoot__FTtE_ .DayPickerNavigation_button {
    color: #ffffff;
    color: #ffffff;
    border: 0;
  }

.DateRangeInput_inputRoot__FTtE_ .CalendarMonth,
  .DateRangeInput_inputRoot__FTtE_ .CalendarMonthGrid {
    background-color: transparent;
  }

.DateRangeInput_inputRoot__FTtE_ .DateInput {
    display: block;
    width: 100%;
    padding: 5px 0 2px 0
  }

@media (min-width: 768px) {

.DateRangeInput_inputRoot__FTtE_ .DateInput {
      padding: 6px 0 9px 0
  }
    }

.DateRangeInput_inputRoot__FTtE_ .DayPicker_weekHeader {
    color: #ffffff;
    color: #ffffff;
    top: 57px;
  }

.DateRangeInput_inputRoot__FTtE_ .DayPicker_weekHeader_li {
    font-weight: 400;
  }

.DateRangeInput_inputRoot__FTtE_ .DayPicker__withBorder {
    border-radius: 0;
  }

.DateRangeInput_inputRoot__FTtE_ .CalendarMonth_caption {
    color: #ffffff;
    color: #ffffff;
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px
  }

@media (min-width: 768px) {

.DateRangeInput_inputRoot__FTtE_ .CalendarMonth_caption {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px
  }
    }

.DateRangeInput_inputRoot__FTtE_ .CalendarMonth_caption {
    margin: 1px 0 14px;
    font-weight: 400
  }

@media (min-width: 768px) {

.DateRangeInput_inputRoot__FTtE_ .CalendarMonth_caption {
      margin-top: 0;
      margin-bottom: 0
  }
    }

.DateRangeInput_inputRoot__FTtE_ .CalendarDay__default {
    background-color: #3e704b;
    background-color: #3e704b;
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

.DateRangeInput_inputRoot__FTtE_ .CalendarDay {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
  }

@media (min-width: 768px) {

.DateRangeInput_inputRoot__FTtE_ .CalendarDay {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
  }
    }

.DateRangeInput_inputRoot__FTtE_ .CalendarDay {
    color: #ffffff;
    color: #ffffff;
    border: 0;
    margin-top: 0;
    margin-bottom: 0
  }

@media (min-width: 768px) {

.DateRangeInput_inputRoot__FTtE_ .CalendarDay {
      margin-top: 0;
      margin-bottom: 0
  }
    }

.DateRangeInput_inputRoot__FTtE_ {

  /* Add an underline for '.renderedDay' */
}

.DateRangeInput_inputRoot__FTtE_ .CalendarDay__today .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
    background-position: center 34px;
  }

.DateRangeInput_inputRoot__FTtE_ {

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeInput_inputRoot__FTtE_ .CalendarDay__hovered_span,
  .DateRangeInput_inputRoot__FTtE_ .CalendarDay__selected_span {
    background-image: transparent;
    background-color: transparent;
  }

.DateRangeInput_inputRoot__FTtE_ .CalendarDay__hovered_span .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
  }

.DateRangeInput_inputRoot__FTtE_ .CalendarDay__selected_span .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-color: #2ecc71;
    background-color: #2ecc71;
    transition: all 0.2s ease-out;
  }

.DateRangeInput_inputRoot__FTtE_ {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeInput_inputRoot__FTtE_ .CalendarDay__selected_start {
    background-color: transparent;
    background-image: none;
  }

.DateRangeInput_inputRoot__FTtE_ .CalendarDay__selected_start .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-color: #2ecc71;
    background-color: #2ecc71;
    border-top-left-radius: calc(36px / 2);
    border-top-left-radius: calc(36px / 2);
    border-bottom-left-radius: calc(36px / 2);
    border-bottom-left-radius: calc(36px / 2);
  }

.DateRangeInput_inputRoot__FTtE_ {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeInput_inputRoot__FTtE_ .CalendarDay__after-hovered_start {
    background-color: transparent;
  }

.DateRangeInput_inputRoot__FTtE_ .CalendarDay__after-hovered_start .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
  }

.DateRangeInput_inputRoot__FTtE_ {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeInput_inputRoot__FTtE_ .CalendarDay__selected_end {
    background-color: transparent;
  }

.DateRangeInput_inputRoot__FTtE_ .CalendarDay__selected_end .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-color: #2ecc71;
    background-color: #2ecc71;
    border-top-right-radius: calc(36px / 2);
    border-top-right-radius: calc(36px / 2);
    border-bottom-right-radius: calc(36px / 2);
    border-bottom-right-radius: calc(36px / 2);
    color: #ffffff;
    color: #ffffff;
  }

.DateRangeInput_inputRoot__FTtE_ .CalendarDay:hover .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
  }

.DateRangeInput_inputRoot__FTtE_ {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeInput_inputRoot__FTtE_ .CalendarDay__blocked_out_of_range,
  .DateRangeInput_inputRoot__FTtE_ .CalendarDay__blocked_out_of_range:active,
  .DateRangeInput_inputRoot__FTtE_ .CalendarDay__blocked_out_of_range:hover {
    background-color: transparent;
    color: #3e704b;
    color: #3e704b;
    border: 0;
    text-decoration: line-through;
  }

.DateRangeInput_inputRoot__FTtE_ {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeInput_inputRoot__FTtE_ .CalendarDay__blocked_calendar,
  .DateRangeInput_inputRoot__FTtE_ .CalendarDay__blocked_calendar:active,
  .DateRangeInput_inputRoot__FTtE_ .CalendarDay__blocked_calendar:hover {
    background-color: transparent;
    color: #3e704b;
    color: #3e704b;
    border: 0;
    text-decoration: line-through;
  }

.DateRangeInput_inputRoot__FTtE_ .CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay {
    background-color: transparent;
  }

.DateRangeInput_inputRoot__FTtE_ .DateInput_fang {
    display: none;
  }

.DateRangeInput_inputRoot__FTtE_ .CalendarMonth_caption {
    text-transform: capitalize;
  }

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */

.DateRangeInput_withMobileMargins__40r7b .DateRangePickerInput {
    width: calc(100vw - 48px);
    margin: 0 24px 3px 24px
  }

@media (min-width: 768px) {

.DateRangeInput_withMobileMargins__40r7b .DateRangePickerInput {
      width: 100%;
      margin: 0
  }
    }

.DateRangeInput_withMobileMargins__40r7b {

  /* Create gutter between inputs */
}

.DateRangeInput_withMobileMargins__40r7b .DateInput {
    width: calc(50% - 6px);
    background: none
  }

@media (min-width: 768px) {

.DateRangeInput_withMobileMargins__40r7b .DateInput {
      width: calc(50% - 12px)
  }
    }

.DateRangeInput_withMobileMargins__40r7b .DateInput:first-of-type {
    margin-right: 12px
  }

@media (min-width: 768px) {

.DateRangeInput_withMobileMargins__40r7b .DateInput:first-of-type {
      margin-right: 24px
  }
    }

.DateRangeInput_arrowIcon__1MmwL {
  stroke: #ffffff;
  stroke: #ffffff;
  fill: #ffffff;
  fill: #ffffff;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.FieldDateRangeInput_fieldRoot__3YBAz {
}

.FieldDateRangeInput_labels__c9t1C {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.FieldDateRangeInput_startDateLabel__hezw3,
.FieldDateRangeInput_endDateLabel__3cUVe {
  flex-basis: 50%;
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.FieldDateRangeInput_startDateLabel__hezw3,
.FieldDateRangeInput_endDateLabel__3cUVe {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.FieldDateRangeInput_startDateLabel__hezw3,
.FieldDateRangeInput_endDateLabel__3cUVe {
  font-weight: 600;
  color: #4a5569;
  color: #4a5569;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center
}

@media (min-width: 768px) {

.FieldDateRangeInput_startDateLabel__hezw3,
.FieldDateRangeInput_endDateLabel__3cUVe {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600
}
  }

@media (min-width: 768px) {

.FieldDateRangeInput_startDateLabel__hezw3,
.FieldDateRangeInput_endDateLabel__3cUVe {
    padding-bottom: 1px
}
  }

.FieldDateRangeInput_labelSuccess__VFhqE {
  color: #2ecc71;
  color: #2ecc71;
}

.FieldDateRangeInput_inputBorders__3iZQq {
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: all 0.15s ease-out;
}

.FieldDateRangeInput_input__32LG9 {
  flex-basis: 50%;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #ffaa00;
  border-bottom-color: #ffaa00;

  transition: border-bottom-color ease-in 0.2s;

  transition: border-bottom-color ease-in 0.2s;
  transition: all 0.15s ease-out
}

.FieldDateRangeInput_input__32LG9:hover,
  .FieldDateRangeInput_input__32LG9:focus {
    border-bottom-color: #4a5569;
    border-bottom-color: #4a5569;
    outline: none;
  }

@media (min-width: 768px) {

.FieldDateRangeInput_input__32LG9 {
    border-bottom-width: 3px
}
  }

.FieldDateRangeInput_inputSuccess__1Jztv {
  border-bottom-color: #2ecc71;
  border-bottom-color: #2ecc71;
}

.FieldDateRangeInput_inputError__7alln {
  border-bottom-color: #ff0000;
  border-bottom-color: #ff0000;
}

.FieldDateRangeInput_hover__3lvC5 {
  border-bottom-color: #1a202c;
  border-bottom-color: #1a202c;
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */

.FieldDateRangeInput_mobileMargins__1sPBG {
  /* Mobile Safari couldn't handle width: calc(100vw - 48px); */
  width: calc(100vw - 48px);
  margin: 0 24px

  /* Gutter between inputs (when calendar is not visible) */
}

.FieldDateRangeInput_mobileMargins__1sPBG .FieldDateRangeInput_startDateLabel__hezw3,
  .FieldDateRangeInput_mobileMargins__1sPBG .FieldDateRangeInput_endDateLabel__3cUVe {
    /* Mobile Safari couldn't handle width: calc(50% - 6px); */
    flex-basis: calc(50vw - 30px);
  }

.FieldDateRangeInput_mobileMargins__1sPBG .FieldDateRangeInput_input__32LG9 {
    /* Mobile Safari couldn't handle width: calc(50% - 6px); */
    flex-basis: calc(50vw - 30px);
    transition: all 0.15s ease-out;
  }

@media (min-width: 768px) {

.FieldDateRangeInput_mobileMargins__1sPBG {
    width: 100%;
    margin: 0

    /* Gutter between inputs (when calendar is not visible) */
}
    .FieldDateRangeInput_mobileMargins__1sPBG .FieldDateRangeInput_startDateLabel__hezw3,
    .FieldDateRangeInput_mobileMargins__1sPBG .FieldDateRangeInput_endDateLabel__3cUVe {
      flex-basis: calc(50% - 12px);
    }

    .FieldDateRangeInput_mobileMargins__1sPBG .FieldDateRangeInput_input__32LG9 {
      flex-basis: calc(50% - 12px);
      transition: all 0.15s ease-out;
    }
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldInstructions_root__-JP7k {
  color: #1a202c;
  color: #1a202c;
  margin: 0 0 1.25rem 0;
  margin: 0 0 1.25rem 0
}

@media (min-width: 768px) {

.FieldInstructions_root__-JP7k {
    font-weight: 600;
    font-weight: 600
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldTitle_root__2h8_c {
  color: #1a202c;
  color: #1a202c;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.125rem;
  font-size: 1.125rem;
}

@media (min-width: 768px) {

.FieldTitle_root__2h8_c {
  font-size: 1.5rem;
  font-size: 1.5rem;
}
    }

.FieldTitle_root__2h8_c {
  font-weight: 600;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldPromoCode_root__2VzVV {
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem;
}

.FieldPromoCode_inputWrapper__3frAB {
  position: relative;
  width: 100%
}

@media (min-width: 768px) {

.FieldPromoCode_inputWrapper__3frAB {
    width: 75%;
    width: 75%
}
  }

.FieldPromoCode_applyButton__2jeCG {
  position: absolute;
  top: 5px;
  right: 0.5rem;
  right: 0.5rem;
  z-index: 10;
}

.FieldPromoCode_headerLink__2gJD- {
  display: block;
  text-decoration: underline;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldTripInsurance_root__2hJDk {
  width: 100%
}

@media (min-width: 768px) {

.FieldTripInsurance_root__2hJDk {
    padding: 0.25rem 0 1.25rem 0;
    padding: 0.25rem 0 1.25rem 0
}
  }

.FieldTripInsurance_inputWrapper__3IjXP {
  position: relative;
  width: 100%;
}

.FieldTripInsurance_loaderWrapper__1qM5S {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  padding: 0.5rem 0;
}

.FieldTripInsurance_skeletonTop__e1C1O:empty {
  position: relative;
  height: 150px;
  background-color: #ffffff;
  border-radius: 11px;
  background-image: linear-gradient(#edf2f7 22px, transparent 0),
    linear-gradient(#edf2f7 22px, transparent 0), linear-gradient(#edf2f7 22px, transparent 0),
    linear-gradient(#edf2f7 32px, transparent 0);
  background-repeat: repeat-y;
  background-size: 358px 290px, 409px 290px, 447px 290px, 197px 290px;
  background-position: left 5px top 106px, left 5px top 75px, left 5px top 44px, left 5px top 5px;
}

.FieldTripInsurance_skeletonTop__e1C1O:empty:before {
  content: ' ';
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 150px;
  border-radius: 11px;
  -webkit-mask-image: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 80%
  );
  -webkit-mask-repeat: repeat-y;
  -webkit-mask-size: 50px 150px;
  -webkit-mask-position: -20% 0;
  background-image: linear-gradient(rgba(255, 255, 255, 1) 22px, transparent 0),
    linear-gradient(rgba(255, 255, 255, 1) 22px, transparent 0),
    linear-gradient(rgba(255, 255, 255, 1) 22px, transparent 0),
    linear-gradient(rgba(255, 255, 255, 1) 32px, transparent 0);
  background-repeat: repeat-y;
  background-size: 358px 290px, 409px 290px, 447px 290px, 197px 290px;
  background-position: left 5px top 106px, left 5px top 75px, left 5px top 44px, left 5px top 5px;
  animation: FieldTripInsurance_shineForSkeleton__vzjiS 2s infinite;
}

@keyframes FieldTripInsurance_shineForSkeleton__vzjiS {
  to {
    -webkit-mask-position: 120% 0;
  }
}

.FieldTripInsurance_skeletonRadio__1kOt8:empty {
  position: relative;
  height: 225px;
  background-color: #ffffff;
  border-radius: 0px 0px 0px 0px;
  background-image: linear-gradient(#edf2f7 37px, transparent 0),
    linear-gradient(#edf2f7 22px, transparent 0),
    radial-gradient(circle 12.5px at 12.5px 12.5px, #edf2f7 11.5px, transparent 12.5px),
    linear-gradient(#edf2f7 59px, transparent 0), linear-gradient(#edf2f7 22px, transparent 0),
    linear-gradient(#edf2f7 22px, transparent 0),
    radial-gradient(circle 12.5px at 12.5px 12.5px, #edf2f7 11.5px, transparent 12.5px);
  background-repeat: repeat-y;
  background-size: 391px 290px, 413px 290px, 25px 290px, 471px 290px, 190px 290px, 413px 290px,
    25px 290px;
  background-position: left 50px top 190px, left 50px top 152px, left 5px top 150px,
    left 50px top 75px, left 50px top 40px, left 50px top 7px, left 5px top 5px;
}

.FieldTripInsurance_skeletonRadio__1kOt8:empty:before {
  content: ' ';
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 225px;
  -webkit-mask-image: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 80%
  );
  -webkit-mask-repeat: repeat-y;
  -webkit-mask-size: 50px 290px;
  -webkit-mask-position: -20% 0;
  background-image: linear-gradient(rgba(255, 255, 255, 1) 37px, transparent 0),
    linear-gradient(rgba(255, 255, 255, 1) 22px, transparent 0),
    radial-gradient(
      circle 12.5px at 12.5px 12.5px,
      rgba(255, 255, 255, 1) 11.5px,
      transparent 12.5px
    ),
    linear-gradient(rgba(255, 255, 255, 1) 59px, transparent 0),
    linear-gradient(rgba(255, 255, 255, 1) 22px, transparent 0),
    linear-gradient(rgba(255, 255, 255, 1) 22px, transparent 0),
    radial-gradient(
      circle 12.5px at 12.5px 12.5px,
      rgba(255, 255, 255, 1) 11.5px,
      transparent 12.5px
    );
  background-repeat: repeat-y;
  background-size: 391px 290px, 413px 290px, 25px 290px, 471px 290px, 190px 290px, 413px 290px,
    25px 290px;
  background-position: left 50px top 190px, left 50px top 152px, left 5px top 150px,
    left 50px top 75px, left 50px top 40px, left 50px top 7px, left 5px top 5px;
  animation: FieldTripInsurance_shineForSkeleton-__1r1lj 2s infinite;
}

@keyframes FieldTripInsurance_shineForSkeleton-__1r1lj {
  to {
    -webkit-mask-position: 120% 0;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldTripInsuranceToggle_root__2FhCk {
  display: flex;
  flex-direction: column;
}

.FieldTripInsuranceToggle_formHeading__3G2aj {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
  margin: 0;
  color: #1a202c;
  color: #1a202c
}

@media (min-width: 768px) {

.FieldTripInsuranceToggle_formHeading__3G2aj {
    margin-bottom: 1rem;
    margin-bottom: 1rem
}
  }

.FieldTripInsuranceToggle_subHeading__2ncgi {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin: 0.5rem 0 1rem 0;
  margin: 0.5rem 0 1rem 0;
  color: #1a202c;
  color: #1a202c;
  font-weight: normal
}

@media (min-width: 768px) {

.FieldTripInsuranceToggle_subHeading__2ncgi {
    margin: 0 0 1rem 0;
    margin: 0 0 1rem 0
}
  }

.FieldTripInsuranceToggle_insuranceToggle__D2DYj {
  font-weight: 700;
  font-weight: 700;
}

.FieldTripInsuranceToggle_insuranceOptionWrapper__2GcqI,
.FieldTripInsuranceToggle_selected__2kuvO {
  border: none;
  font-weight: 700;
  font-weight: 700
}

@media (min-width: 768px) {

.FieldTripInsuranceToggle_insuranceOptionWrapper__2GcqI,
.FieldTripInsuranceToggle_selected__2kuvO {
    border-radius: 10px;
    border-radius: 10px;
    padding: 1rem 1.75rem 0.25rem 1rem;
    padding: 1rem 1.75rem 0.25rem 1rem;
    margin-bottom: 1rem;
    margin-bottom: 1rem
}
  }

@media (min-width: 768px) {

.FieldTripInsuranceToggle_insuranceOptionWrapper__2GcqI {
    border: 1px solid #e2e8f0;
    border: 1px solid #e2e8f0
}
  }

@media (min-width: 768px) {

.FieldTripInsuranceToggle_selected__2kuvO {
    border: 1px solid black
}
  }

.FieldTripInsuranceToggle_insuranceLink__1nMZY {
  font-size: 0.875rem;
  font-size: 0.875rem;
  text-decoration: underline;
  margin: 1.5rem 0 0.5rem 2rem;
  margin: 1.5rem 0 0.5rem 2rem;
  color: #1a202c;
  color: #1a202c
}

@media (min-width: 768px) {

.FieldTripInsuranceToggle_insuranceLink__1nMZY {
    display: block;
    margin: 0 0 0.75rem 2.25rem;
    margin: 0 0 0.75rem 2.25rem
}
  }

.FieldTripInsuranceToggle_subTextYes__3xhFl,
.FieldTripInsuranceToggle_subTextNo__2mC2K {
  margin: 0.5rem 0 1rem 2rem;
  margin: 0.5rem 0 1rem 2rem;
  color: #1a202c;
  color: #1a202c
}

@media (min-width: 768px) {

.FieldTripInsuranceToggle_subTextYes__3xhFl,
.FieldTripInsuranceToggle_subTextNo__2mC2K {
    margin-left: 2.25rem;
    margin-left: 2.25rem
}
  }

.FieldTripInsuranceToggle_subTextYes__3xhFl {
  font-size: 0.625rem;
  font-size: 0.625rem;
  font-weight: normal;
}

.FieldTripInsuranceToggle_subTextNo__2mC2K {
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin: 0 0 0.5rem 2.25rem;
  margin: 0 0 0.5rem 2.25rem;
}

.FieldTripInsuranceToggle_radioWrapper__1Q0UK > span {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  display: block;
}

@media (min-width: 768px) {
  .FieldTripInsuranceToggle_radioWrapper__1Q0UK {
    display: flex;
    flex-direction: column
  }

    .FieldTripInsuranceToggle_radioWrapper__1Q0UK > span {
      margin-right: 1.75rem;
      margin-right: 1.75rem;
      margin-bottom: 0;
    }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldRadioButton_root__3oEG5 {
  position: relative;
  display: inline-block;
}

.FieldRadioButton_input__Faub9 {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0

  /* Display the "check" when checked */
}

.FieldRadioButton_input__Faub9:checked + label .FieldRadioButton_checked__2ZHSn {
    display: inline;
  }

.FieldRadioButton_input__Faub9 {

  /* Highlight the borders if the checkbox is hovered, focused or checked */
}

.FieldRadioButton_input__Faub9:hover + label .FieldRadioButton_notChecked__2GDJZ,
  .FieldRadioButton_input__Faub9:hover + label .FieldRadioButton_required__3yvgx,
  .FieldRadioButton_input__Faub9:focus + label .FieldRadioButton_notChecked__2GDJZ,
  .FieldRadioButton_input__Faub9:focus + label .FieldRadioButton_required__3yvgx,
  .FieldRadioButton_input__Faub9:checked + label .FieldRadioButton_notChecked__2GDJZ,
  .FieldRadioButton_input__Faub9:checked + label .FieldRadioButton_required__3yvgx {
    stroke: #3e704b;
    stroke: #3e704b;
  }

.FieldRadioButton_input__Faub9 {

  /* Hightlight the text on checked, hover and focus */
}

.FieldRadioButton_input__Faub9:focus + label .FieldRadioButton_text__3TcwH,
  .FieldRadioButton_input__Faub9:hover + label .FieldRadioButton_text__3TcwH,
  .FieldRadioButton_input__Faub9:checked + label .FieldRadioButton_text__3TcwH {
    color: #1a202c;
    color: #1a202c;
  }

.FieldRadioButton_label__EaJKb {
  display: flex;
  align-items: center;
}

.FieldRadioButton_radioButtonWrapper__oDtzq {
  /* This should follow line-height */
  height: 32px;
  margin-top: -2px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.FieldRadioButton_checked__2ZHSn {
  display: none;
  fill: #3e704b;
  fill: #3e704b;
}

.FieldRadioButton_notChecked__2GDJZ {
  stroke: #e2e8f0;
  stroke: #e2e8f0;
}

.FieldRadioButton_required__3yvgx {
  stroke: var(--attentionColor);
}

.FieldRadioButton_text__3TcwH {
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.FieldReviewRating_ratingFieldSet__FNOft {
  border: none;
  padding: 0;
  margin: 0;
}

/* fieldset can't use flexbox hence this wrapper exists */

.FieldReviewRating_rating__Jx4_o {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.FieldReviewRating_rateInput__TX4ED {
  display: none;
}

.FieldReviewRating_label__3WM_5 {
  width: 2.25rem;
  width: 2.25rem;
  cursor: pointer;
  margin-right: 1rem;
  margin-right: 1rem
}

.FieldReviewRating_label__3WM_5:first-of-type {
    margin-right: 0;
  }

.FieldReviewRating_star__3gzzI {
  fill: #cbd5e0;
  fill: #cbd5e0;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
  width: 2rem;
  width: 2rem;
  height: 2rem;
  height: 2rem;
}

legend {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.25rem;
  font-size: 1.25rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;
}

@media (min-width: 768px) {

legend {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px;
}
    }

legend {
  color: #a1aec1;
  color: #a1aec1;
}

/***** CSS Magic to Highlight Stars on Hover *****/

/* Star order: reverse expected (5 -> 1) and also input before label */

/* show actived star when checked */

/* and actived star when hovering over a star */

/* and show previous stars also as activated */

.FieldReviewRating_rating__Jx4_o > .FieldReviewRating_rateInput__TX4ED:checked ~ .FieldReviewRating_label__3WM_5 > .FieldReviewRating_star__3gzzI,
.FieldReviewRating_rating__Jx4_o > .FieldReviewRating_label__3WM_5:hover > .FieldReviewRating_star__3gzzI,
.FieldReviewRating_rating__Jx4_o > .FieldReviewRating_label__3WM_5:hover ~ .FieldReviewRating_label__3WM_5 > .FieldReviewRating_star__3gzzI {
  fill: #ffbb52;
  fill: #ffbb52;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
}

/* Darken hovered star when changing rating i.e it already is active */

/* and darken hovered star too when changing rating (hovering inside current selection) */

/* and darken current selection inside hovered selection */

/* and darken hovered selection inside current selection */

.FieldReviewRating_rating__Jx4_o > .FieldReviewRating_rateInput__TX4ED:checked + .FieldReviewRating_label__3WM_5:hover > .FieldReviewRating_star__3gzzI,
.FieldReviewRating_rating__Jx4_o > .FieldReviewRating_rateInput__TX4ED:checked ~ .FieldReviewRating_label__3WM_5:hover > .FieldReviewRating_star__3gzzI,
.FieldReviewRating_rating__Jx4_o > .FieldReviewRating_label__3WM_5:hover ~ .FieldReviewRating_rateInput__TX4ED:checked ~ .FieldReviewRating_label__3WM_5 > .FieldReviewRating_star__3gzzI,
.FieldReviewRating_rating__Jx4_o > .FieldReviewRating_rateInput__TX4ED:checked ~ .FieldReviewRating_label__3WM_5:hover ~ .FieldReviewRating_label__3WM_5 > .FieldReviewRating_star__3gzzI {
  fill: #ffbb52;
  fill: #ffbb52;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldSelect_root__2Y5T5 {
}

.FieldSelect_select__3gblT {
  padding: 0.75rem;
  padding: 0.75rem;
}

.FieldSelect_selectSuccess__37Sx7 {
  border: 1px solid #cbd5e0;
  border: 1px solid #cbd5e0;
}

.FieldSelect_selectError__2OmAo {
  border-color: #ed3615;
  border-color: #ed3615;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SquareSpeciesImage_root__23uFZ {
  position: relative
}

.SquareSpeciesImage_root__23uFZ::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 27%, rgba(0, 0, 0, 0.66));
  }

.SquareSpeciesImage_image__2oKSN {
  max-width: 100%;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldSpeciesCheckbox_root__e2pkE {
  position: relative;
}

.FieldSpeciesCheckbox_input__R_48M {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0

  /* Highlight the borders if the checkbox is hovered, focused or checked */
}

.FieldSpeciesCheckbox_input__R_48M:hover + label::after,
  .FieldSpeciesCheckbox_input__R_48M:focus + label::after {
    /* This is --olive-900 with alpha. Can't use opacity because of children */
    background: rgba(136, 158, 138, 0.5);
  }

.FieldSpeciesCheckbox_input__R_48M:checked + label::after {
    /* This is --olive-900 with alpha. Can't use opacity because of children */
    background: rgba(136, 158, 138, 0.9);
  }

.FieldSpeciesCheckbox_input__R_48M:checked + label .FieldSpeciesCheckbox_checkmark__2AJJM {
    opacity: 1;
  }

.FieldSpeciesCheckbox_label__3gR1O {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  cursor: pointer;
  border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;

  /* Keep the aspect ratio as per design 162px x 152px */
  padding-bottom: calc((152% / 162) * 100)
}

.FieldSpeciesCheckbox_label__3gR1O::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: ease-in 0.2s;
    transition: ease-in 0.2s;
    background: transparent;
  }

.FieldSpeciesCheckbox_image__37T8g {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.FieldSpeciesCheckbox_text__2tC-r {
  position: absolute;
  z-index: 3;
  bottom: 1rem;
  bottom: 1rem;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-weight: 600;
  color: #fff;
  color: #fff;
  font-size: 1.125rem;
  font-size: 1.125rem
}

@media (min-width: 768px) {

.FieldSpeciesCheckbox_text__2tC-r {
    font-size: 1rem;
    font-size: 1rem
}
  }

.FieldSpeciesCheckbox_checkmark__2AJJM {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  stroke: #fff;
  stroke: #fff;
  stroke-width: 3px;
  transition: ease-in 0.2s;
  transition: ease-in 0.2s;
  opacity: 0;
}

@keyframes skeleton_react-loading-skeleton__3hQIS {
  100% {
    transform: translateX(100%);
  }
}

.skeleton_react-loading-skeleton__3hQIS {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.skeleton_react-loading-skeleton__3hQIS::after {
  content: ' ';
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    var(--highlight-color),
    var(--base-color)
  );
  transform: translateX(-100%);

  animation-name: skeleton_react-loading-skeleton__3hQIS;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion) {
  .skeleton_react-loading-skeleton__3hQIS {
    --pseudo-element-display: none; /* Disable animation */
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldTextInput_input__1vkY3 {
  min-height: 50px;
  border: 1px solid #e2e8f0;
  border: 1px solid #e2e8f0;
}

.FieldTextInput_inputError__GA57- {
  border-color: #ca2d10;
  border-color: #ca2d10;
}

.FieldTextInput_textarea__3JaET {
  min-height: 150px;
}

.FieldTextInput_helpWrapper__2XfF_ {
  display: flex;
  justify-content: flex-end;
}

.FieldTextInput_errorWrapper__22BK8 {
  flex-grow: 1;
}

.FieldTextInput_successWrapper__2xI1_ {
  flex-grow: 1;
  color: #3e704b;
  color: #3e704b;
}

.FieldTextInput_proTipWrapper__2MfhS {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.FieldTextInput_proTipTextArea__2GEQ9 {
  display: block;
}

.FieldTextInput_skeletonContainer__1RaPt {
  display: block;
  line-height: 1;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldPillInput_root__3F7is {
  position: relative;
}

.FieldPillInput_inputRoot__1qQCA {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0
}

.FieldPillInput_inputRoot__1qQCA:checked + label {
    background-color: #4a5569;
    background-color: #4a5569;
    color: #fff;
    color: #fff;
    border-color: #4a5569;
    border-color: #4a5569
  }

.FieldPillInput_inputRoot__1qQCA:checked + label:hover,
    .FieldPillInput_inputRoot__1qQCA:checked + label:focus {
      border-color: #4a5569;
      border-color: #4a5569;
    }

.FieldPillInput_inputRoot__1qQCA:hover + label,
  .FieldPillInput_inputRoot__1qQCA:focus + label {
    border-color: #718096;
    border-color: #718096;
  }

.FieldPillInput_inputLabel__3eHN0 {
  min-height: 35px;
  padding: 0 1rem;
  padding: 0 1rem;
  border: 1px solid #cbd5e0;
  border: 1px solid #cbd5e0;
  border-radius: 18px;
  background-color: #fff;
  background-color: #fff;
  font-weight: normal;
  font-size: 0.875rem;
  font-size: 0.875rem;
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldNumberInput_root__1dRz5 {
}

.FieldNumberInput_rootAsRow__9aBTI {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.FieldNumberInput_inputWrapper__3XuCS {
  position: relative;
}

.FieldNumberInput_inputRoot__zv_D6 {
  max-width: 8rem;
  max-width: 8rem;
  padding-left: 2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-right: 2.5rem;
  text-align: center;
  color: #1a202c;
  color: #1a202c;
  font-weight: 600;
  font-weight: 600;
}

.FieldNumberInput_inputRoot__zv_D6::-webkit-outer-spin-button,
  .FieldNumberInput_inputRoot__zv_D6::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

.FieldNumberInput_inputRoot__zv_D6 {
  -moz-appearance: textfield;
}

.FieldNumberInput_inputRootNoButtons__2rfYv {
  text-align: left;
  padding-left: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 1rem;
  padding-right: 1rem;
  max-width: 5rem;
  max-width: 5rem;
}

.FieldNumberInput_inputButton__pj-lu {
  position: absolute;
  top: calc(50% - 12px);
  min-width: 22px;
  padding: 0 0.25rem;
  padding: 0 0.25rem;
  color: #3e704b;
  color: #3e704b;
  cursor: pointer
}

.FieldNumberInput_inputButton__pj-lu:disabled {
    color: #889e8a;
    color: #889e8a;
  }

.FieldNumberInput_inputButtonIncrement__xgQzg {
  right: 1.25rem;
  right: 1.25rem;
}

.FieldNumberInput_inputButtonDecrement__uR36R {
  left: 1.25rem;
  left: 1.25rem;
}

.FieldNumberInput_inputError__2ibZE {
  border-color: #ed3615;
  border-color: #ed3615;
}

.FieldNumberInput_unit__1HyFx {
  position: absolute;
  right: 1rem;
  right: 1rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldTimeInput_root__1Xg7o {
}

.FieldTimeInput_select__39JPS {
  width: 10rem;
  width: 10rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.FieldCheckboxGroup_root__1xZjD {
  margin: 0;
  padding: 0;
  border: none;
}

.FieldCheckboxGroup_list__oXasR {
  margin: 0;
}

@media (min-width: 768px) {

.FieldCheckboxGroup_twoColumns__2z3Qk {
    columns: 2
}
  }

.FieldCheckboxGroup_item__Y-EaG {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid
}

@media (min-width: 768px) {

.FieldCheckboxGroup_item__Y-EaG {
    padding: 4px 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* A simple technique I developed here: https://codesandbox.io/s/flexbox-with-gaps-simple-qu7wl
   These helpers can be used to construct a flexbox layout that supports grid gaps like css grid does.
   This is not as easy as it seems, especially with a responsive flex layout that can wrap, this solution adds
   margins to add flex items then uses negative margins to offset the margins at the edge so that they don't
   interfere with elements outside of this one

   Usage:

   JSX:

   <div className={css.filtersWrapper}>
     <div className={css.filtersInner}>
       <div className={css.filter}>Item 1</div>
       <div className={css.filter}>Item 2</div>
       <div className={css.filter}>Item 3</div>
     </div>
   </div>

   CSS:

   @import '../FlexHelper/FlexHelper.css';

   // Decorate the wrapper here as you wish but don't add padding
   .filtersWrapper {
     @apply --flexWrapper;
   }

   // Don't add much except flexbox configuration here
   .filtersInner {
     @apply --flexInner3;
     justify-content: center; // Or whatever flex stuff you want to add
   }

   // Decorate each item as you wish but don't add margins
   .filter {
     @apply --flexItem3;

     // You can add widths here for more of a grid-like look.
     // You need to use / add helpers though because the widths need to be calculated using the margin
     @apply --flexItem3Width50Percent;

     @media (--screen-md) {
       @apply --flexItem3Width25Percent;
     }
   }

   In this simple version the gaps cannot be configured to change at breakpoints but ...

   Extra classes for different configurations could be added here and reused elsewhere as needed

   Or it can be expended to support a nice Bootstrap-style grid: https://codesandbox.io/s/pmxn0pqpyq
   - This implementation can be responsive but every use on the app is the same

   Or it can be used like this when uncoupled from the limitations of CSS Modules: https://codesandbox.io/s/mz13k4mw4x
   - This implementation can change behavior on a per usage basis but it cannot practically be done in CSS Modules
     without using real CSS Custom Properties
*/

.FieldPillInputGroup_root__326xD {
  margin: 0;
  padding: 0;
  border: none;
  display: inline-block;
  width: 100%;
}

.FieldPillInputGroup_list__1acl4 {
  display: flex;
  flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;

    /* Stop the negative margins preventing clicks part 1.
       This part makes the negative margins (and all children) unclickable */
  pointer-events: none;

    /* Give the margin top the top/left by default. Then swap the margin from left to right for rtl languages
       which should prevent overflow and scrollbars both ways */
  margin: calc(0px - 0.75rem) 0 0 calc(0px - 0.75rem);
  margin: calc(0px - 0.75rem) 0 0 calc(0px - 0.75rem);
}

.FieldPillInputGroup_list__1acl4:dir(rtl) {
  margin: calc(0px - 0.75rem) calc(0px - 0.75rem) 0 0;
  margin: calc(0px - 0.75rem) calc(0px - 0.75rem) 0 0;
    }

.FieldPillInputGroup_item__3MzXF {
    /* Stop the negative margins preventing clicks part 2.
       This part restores clicks for all the items and their children */
  pointer-events: auto;

    /* Give the margin top the top/left by default. Then swap the margin from left to right for rtl languages
       which should prevent overflow and scrollbars both ways */
  margin: 0.75rem 0 0 0.75rem;
  margin: 0.75rem 0 0 0.75rem;
}

.FieldPillInputGroup_item__3MzXF:dir(rtl) {
  margin: 0.75rem 0.75rem 0 0;
  margin: 0.75rem 0.75rem 0 0;
    }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldToggle_root__3_SZZ {
}

.FieldToggle_radioWrapper__hULTg > span {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  display: block;
}

@media (min-width: 768px) {
  .FieldToggle_radioWrapper__hULTg {
    display: flex
  }

    .FieldToggle_radioWrapper__hULTg > span {
      margin-right: 1.75rem;
      margin-right: 1.75rem;
      margin-bottom: 0;
    }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

:root {
}

.LocationAutocompleteInput_root__GeEgz {
  position: relative;
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.LocationAutocompleteInput_root__GeEgz {
    flex-direction: row
}
  }

.LocationAutocompleteInput_inputContainer__BKDHE {
  width: 100%;
  position: relative;
  display: flex;
  flex: 1 1;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 768px) {

.LocationAutocompleteInput_inputContainer__BKDHE {
    margin-bottom: 0
}
  }

.LocationAutocompleteInput_marginlessInputContainer__8iNF4 {
  margin-bottom: 0;
}

.LocationAutocompleteInput_inputGroup__1r-cp {
  position: relative;
  display: flex;
  flex: 1 1;
}

.LocationAutocompleteInput_inputError__iIDSD {
  border-color: #ed3615;
  border-color: #ed3615;
}

.LocationAutocompleteInput_inputWithIconRight__1vJZM {
  padding-right: 2.25rem;
  padding-right: 2.25rem;
  border-radius: 25px;
  border-radius: 25px;
}

.LocationAutocompleteInput_inputWithIconLeft__2kYxO {
  padding-left: 2.25rem;
  padding-left: 2.25rem;
}

.LocationAutocompleteInput_searchIcon__jPJbp {
  position: absolute;
  top: calc(50% - (1.25rem / 2));
  top: calc(50% - (1.25rem / 2));
  right: 0.5rem;
  right: 0.5rem;
  width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
  color: #fff;
  color: #fff;
}

.LocationAutocompleteInput_searchIconLeft__pJKdm {

  right: auto;
  left: 0.75rem;
  left: 0.75rem;
  color: #1a202c;
  color: #1a202c;
}

.LocationAutocompleteInput_searchIconWrapper__1ZLNr {
  position: absolute;
  top: calc(50% - (2.5rem / 2));
  top: calc(50% - (2.5rem / 2));
  right: 0.25rem;
  right: 0.25rem;
  width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  height: 2.5rem;
  padding: 0.75rem;
  padding: 0.75rem;
  border-radius: 25px;
  border-radius: 25px
}

.LocationAutocompleteInput_searchIconWrapper__1ZLNr:hover {
    cursor: default;
    background-color: #3e704b;
    background-color: #3e704b;
  }

.LocationAutocompleteInput_predictionsRoot__YDPo1 {
  position: absolute;
  width: calc(100% - 0.5rem);
  width: calc(100% - 0.5rem);
  top: 50px;
  left: 0;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 10;
  z-index: 10;
}

.LocationAutocompleteInput_predictions__3dj_x {
  margin: 0;
  padding: 1.5rem 0;
  padding: 1.5rem 0
}

.LocationAutocompleteInput_predictions__3dj_x li {
    margin: 0;
    padding: 0.25rem 2rem;
    padding: 0.25rem 2rem;
    border-left: 4px solid transparent;
    cursor: pointer
  }

.LocationAutocompleteInput_predictions__3dj_x li:hover,
    .LocationAutocompleteInput_predictions__3dj_x li.LocationAutocompleteInput_highlighted__ND54N {
      color: #3e704b;
      color: #3e704b;
      border-color: #3e704b;
      border-color: #3e704b;
    }

li.LocationAutocompleteInput_currentLocation__2GSwW:hover,
  li.LocationAutocompleteInput_currentLocation__2GSwW.LocationAutocompleteInput_highlighted__ND54N {
    border-color: transparent;
  }

li.LocationAutocompleteInput_notFound__X-Gjo {
  color: #ed3615;
  color: #ed3615;
  margin: 0.75rem 0;
  margin: 0.75rem 0;
  font-weight: 700;
  font-weight: 700;
  text-decoration: underline;
}

.LocationAutocompleteInput_currentLocationContainer__2UuKb {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  padding: 0.5rem 0;
  font-size: 1.125rem;
  font-size: 1.125rem;
}

.LocationAutocompleteInput_currentLocationIcon__3Rvkd {
  margin-left: 0.5rem;
  margin-left: 0.5rem;
}

.LocationAutocompleteInput_disabled__2SovZ {
  pointer-events: none;
  opacity: var(--opacity-50);
}

.LocationAutocompleteInput_popularStates__2yYAV {
  pointer-events: none;
}

.LocationAutocompleteInput_searchButton__2AxRx {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
  align-self: center
}

@media (min-width: 768px) {

.LocationAutocompleteInput_searchButton__2AxRx {
    margin-top: 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldMultipleLocationInput_items__ljCtc {
  display: flex;
  flex-wrap: wrap;
}

.FieldMultipleLocationInput_item__3NLD6 {
  display: flex;
  margin-top: 0.75rem;
  margin-top: 0.75rem;
  margin-right: 0.75rem;
  margin-right: 0.75rem;
  padding: 0.75rem 1rem;
  padding: 0.75rem 1rem;
  border-radius: 20px;
  border-radius: 20px;
  background-color: #1a202c;
  background-color: #1a202c;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-weight: 600;
  color: #fff;
  color: #fff;
}

.FieldMultipleLocationInput_itemText__1yMS0 {
  margin-right: 1.5rem;
  margin-right: 1.5rem;
}

.FieldMultipleLocationInput_itemIcon__2rAM9 {
  cursor: pointer
}

.FieldMultipleLocationInput_itemIcon__2rAM9 > svg > path {
    fill: #fff;
    fill: #fff;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldWysiwyg_editor__3t2tN {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  border: 2px solid #cbd5e0;
  border: 2px solid #cbd5e0;
  border-radius: 5px;
  border-radius: 5px;
  padding: 0 1rem;
  padding: 0 1rem;
  min-height: 9.5rem;
  min-height: 9.5rem;
  cursor: auto;
  cursor: initial;
}

.FieldWysiwyg_linkPopup__2NWQM {
  width: 300px
}

.FieldWysiwyg_linkPopup__2NWQM input {
    width: auto;
    width: initial;
    max-height: auto;
    min-height: auto;
  }

.FieldWysiwyg_proTipTextArea__29Owr {
  display: block;
}

.FieldWysiwyg_helpWrapper__1Id5w {
  display: flex;
  justify-content: flex-end;
}

.FieldWysiwyg_errorWrapper__3t983 {
  flex-grow: 1;
}

.FieldWysiwyg_proTipWrapper__12LJq {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.FieldPointsOfInterestsInput_root__336HZ {
  margin: 0;
  padding: 0;
  border: none;
  margin-bottom: 2rem;
  margin-bottom: 2rem
}

.FieldPointsOfInterestsInput_root__336HZ:last-of-type {
    margin-bottom: 0;
  }

.FieldPointsOfInterestsInput_label__35fJE {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 1.125rem;
  font-size: 1.125rem;
}

.FieldPointsOfInterestsInput_list__gbLfp {
  margin: 0;
}

.FieldPointsOfInterestsInput_item__2M7O0 {
  display: block;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.FieldPointsOfInterestsInput_fieldGroup__igsFc {
  flex: 1 1;
}

.FieldPointsOfInterestsInput_fieldWrapper__2qexN {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.FieldPointsOfInterestsInput_button__3DKAd {
  text-decoration: underline;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.FieldPointsOfInterestsInput_icon__19Tnh {
  margin-right: 0.5rem;
  margin-right: 0.5rem;
  color: #3e704b;
  color: #3e704b;
  width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  height: 1.5rem;
}

.FieldPointsOfInterestsInput_removeButton__1yFP1 {
  margin-left: 0.75rem;
  margin-left: 0.75rem;
}

.FieldPointsOfInterestsInput_removeButton__1yFP1 .FieldPointsOfInterestsInput_icon__19Tnh {
  color: #cbd5e0;
  color: #cbd5e0;
  margin: 0;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FieldDates_formFieldHeading__1fefu {
  font-size: 1rem;
  font-size: 1rem;
  margin: 0 0 0 0;
  color: var(--var-900);
  font-weight: 700;
  font-weight: 700;

  padding-top: 0.25rem;

  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-bottom: 0.25rem
}

@media (min-width: 768px) {

.FieldDates_formFieldHeading__1fefu {
    margin: 0 0 0 0;
    padding-top: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-bottom: 0.25rem
}
  }

.FieldDates_dateTooltip__1HXRq {
  padding: 0 1.5rem 1.5rem 1.5rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.FieldDates_datesButton__1DMGM {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  width: 100%;
  cursor: pointer;
  text-align: left;
  padding: 0.75rem;
  padding: 0.75rem;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #cbd5e0;
  border: 1px solid #cbd5e0;
  transition: all 0.2s ease-out;
  z-index: 50;
  z-index: 50;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #fff;
  background-color: #fff;
}

.FieldDates_datesButtonLabel__1KJPf {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0;
  background-color: #fff;
  background-color: #fff;
}

.FieldDates_iconContainer__33vaV {
  display: flex;
  align-items: center;
}

.FieldDates_dateText__1pS4B {
  margin-left: 1rem;
  margin-left: 1rem;
}

.FieldDates_iconArrow__31gYO {
  width: 15px;
  color: #4a5569;
  color: #4a5569;
}

.FieldDates_outsideHandler__13zR2 {
  display: inline;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.StripeBankAccountTokenInputField_unsupportedCountryError__czwrg {
  color: #ed3615;
  color: #ed3615;
}

.StripeBankAccountTokenInputField_root__1E-dx {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.StripeBankAccountTokenInputField_input__335sM {
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 24px
}

.StripeBankAccountTokenInputField_input__335sM:last-of-type {
    margin-bottom: 0;
  }

.StripeBankAccountTokenInputField_longForm__1om6K {
  width: 100%;
  margin-bottom: 24px;
}

@media (min-width: 640px) {
  .StripeBankAccountTokenInputField_longForm__1om6K {
    width: calc(50% - 9px);
  }
}

.StripeBankAccountTokenInputField_inputSuccess__3Y-5l {
}

.StripeBankAccountTokenInputField_inputError__2o33N {
  border-color: #ed3615;
  border-color: #ed3615;
}

.StripeBankAccountTokenInputField_error__1x6uK {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  color: #ed3615;
  color: #ed3615;
  overflow: hidden;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

.TabNav_root__1mTBs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.TabNav_tab__3akQd {
  margin-left: 16px
}

.TabNav_tab__3akQd:first-child {
    margin-left: 0;
  }

@media (min-width: 1024px) {

.TabNav_tab__3akQd {
    margin-left: 24px
}
  }

.TabNav_link__2_u5H {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  font-size: 1rem;
  font-size: 1rem
}

@media (min-width: 1024px) {

.TabNav_link__2_u5H {
  font-size: 1.125rem;
  font-size: 1.125rem
}
    }

.TabNav_link__2_u5H {

  color: #4a5569;

  color: #4a5569;
  padding-bottom: 10px;
  padding-top: 10px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: ease-in-out 0.1s;

  transition: ease-in-out 0.1s;
  background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
  background-position: calc(240px + 4px) center;
  background-position: calc(240px + 4px) center /* SelectedLink's width (200px) + border thickness (4px) = 204px */
}

.TabNav_link__2_u5H:hover {
    text-decoration: none;
    color: #1a202c;
    color: #1a202c;
  }

@media (min-width: 1024px) {

.TabNav_link__2_u5H {
    width: 240px;
    width: 240px;
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 16px;

    padding-top: 6px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 2px
}

    .TabNav_link__2_u5H:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
      background-position: right center;
    }
  }

.TabNav_selectedLink__MGk4z {
  border-bottom-color: #1a202c;
  border-bottom-color: #1a202c;
  color: #1a202c;
  color: #1a202c
}

@media (min-width: 1024px) {

.TabNav_selectedLink__MGk4z {
    width: 240px;
    width: 240px;
    background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
    background-position: right center
}

    .TabNav_selectedLink__MGk4z:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
      background-position: right center;
    }
  }

.TabNav_disabled__3MzhE {
  pointer-events: none;
  color: #a1aec1;
  color: #a1aec1;
  text-decoration: none;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.TabNavHorizontal_root__2TyI4 {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.TabNavHorizontal_darkSkin__2zaTI {
  background-color: #4a5569;
  background-color: #4a5569;
}

.TabNavHorizontal_tab__1u8bW {
  margin-left: 16px
}

.TabNavHorizontal_tab__1u8bW:first-child {
    margin-left: 0;
  }

@media (min-width: 1024px) {

.TabNavHorizontal_tab__1u8bW {
    margin-left: 24px
}
  }

.TabNavHorizontal_tabContent__2YXrW {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: #1a202c;
  color: #1a202c;

    /* Text size should be inherited */
  text-decoration: none
}

.TabNavHorizontal_tabContent__2YXrW:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.TabNavHorizontal_tabContent__2YXrW {
  display: flex;
  justify-content: center;
  height: 100%;
  text-align: center;
  width: 100%;

  /* Font */
  font-size: 1rem;
  font-size: 1rem;

  color: #4a5569;

  color: #4a5569;
  padding-bottom: 10px;

  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e2e8f0;
  border-bottom-color: #e2e8f0;

  transition: ease-in-out 0.1s;

  transition: ease-in-out 0.1s
}

.TabNavHorizontal_tabContent__2YXrW:hover,
  .TabNavHorizontal_tabContent__2YXrW:focus {
    text-decoration: none;
    color: #1a202c;
    color: #1a202c;
  }

@media (min-width: 768px) {

.TabNavHorizontal_tabContent__2YXrW {
    padding: 16px 10px 14px 10px
}
  }

.TabNavHorizontal_button__3n4GS {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.TabNavHorizontal_selectedTabContent__2d_CA {
  border-bottom-width: 2px;
  border-bottom-color: #1a202c;
  border-bottom-color: #1a202c;
  color: #1a202c;
  color: #1a202c;
}

.TabNavHorizontal_disabled__L_Cyb {
  pointer-events: none;
  color: #a1aec1;
  color: #a1aec1;
  text-decoration: none;
}

/* Dark skin */

.TabNavHorizontal_tabContentDarkSkin__1yEtV {
  color: #a1aec1;
  color: #a1aec1
}

.TabNavHorizontal_tabContentDarkSkin__1yEtV:hover {
    color: #ffffff;
    color: #ffffff;
  }

.TabNavHorizontal_selectedTabContentDarkSkin__3meew {
  border-bottom-color: #ffffff;
  border-bottom-color: #ffffff;
  color: #ffffff;
  color: #ffffff;
}

.TabNavHorizontal_disabledDarkSkin__CKjVM {
  color: #1a202c;
  color: #1a202c;
  opacity: 0.3;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.Tabs_root__1p6Hi {
  position: relative;
  border-bottom: 1px solid #e2e8f0;
  border-bottom: 1px solid #e2e8f0;
}

.Tabs_list__20O9h {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0px;
  overflow: auto;
}

.Tabs_tab__1gD1X {
  font-family: inherit;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1.25rem;
  padding: 0 1.25rem;
  border: none;
  background: none;
  outline: none;
  color: #424d57;
  cursor: pointer;
  transition: color 0.25s;
  margin: 0 0px 1rem 0px;
  margin: 0 0px 1rem 0px;
  overflow: visible;
  white-space: nowrap
}

.Tabs_tab__1gD1X:first-child {
    padding: 0 1.25rem;
    padding: 0 1.25rem
  }

@media (min-width: 768px) {

.Tabs_tab__1gD1X:first-child {
      padding: 0 0.5rem 0 0;
      padding: 0 0.5rem 0 0
  }
    }

.Tabs_tab__1gD1X:before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: block;
    content: '';
    background-color: #1a202c;
    background-color: #1a202c;
    height: 0;
  }

.Tabs_tab__vertical__2sb4M:first-child {
    padding: 0 1.25rem;
    padding: 0 1.25rem;
  }

.Tabs_tab__selected__2MFAy {
  font-weight: 700;
  font-weight: 700
}

.Tabs_tab__selected__2MFAy:before {
    height: 3px;
    bottom: calc(-1 * 1rem);
    bottom: calc(-1 * 1rem);
  }

.Tabs_tab__selected__vertical__3_E1H {
  font-weight: 700;
  font-weight: 700
}

.Tabs_tab__selected__vertical__3_E1H:before {
    height: 100%;
    width: 3px;
    left: 3px;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InboxListTypeDropdown_filterButton__y0_Ej {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: #e2e8f0;
  border-color: #e2e8f0;
  grid-gap: 8px;
  gap: 8px;
  padding: 8px 16px;
  flex-grow: 1;
  flex-shrink: 0;
}

.InboxListTypeDropdown_filterButton__y0_Ej:hover {
  background-color: #f3f6f3;
  color: #1a202c;
  color: #1a202c;
}

.InboxListTypeDropdown_filterButton__y0_Ej:focus {
  outline: 2px solid #1a202c;
  outline: 2px solid #1a202c;
}

.InboxListTypeDropdown_filterButtonSelected__2uoi3 {
  background-color: #1a202c;
  background-color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;
  color: #fff;
  color: #fff;
}

.InboxListTypeDropdown_filterButtonSelected__2uoi3:hover {
  background-color: #1a202c;
  background-color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;
  color: #fff;
  color: #fff;
}

.InboxListTypeDropdown_filterButtonSelected__2uoi3:focus {
  outline: none;
}

.InboxListTypeDropdown_filterButtonOpen__1trBO {
  border-color: #1a202c;
  border-color: #1a202c;
}

.InboxListTypeDropdown_filterDropdownWrapper__-0FFU {
  flex-grow: 1;
  flex-shrink: 0;
}

.InboxListTypeDropdown_filterDropdown__sJSIv {
  position: relative;
  display: flex;
}

.InboxListTypeDropdown_dropdown__2J7HQ {
  position: absolute;
  width: 100%;
  top: calc(100% + 2px);
  right: 0;
  padding: 1rem;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e8f0;
  border: 1px solid #e2e8f0;
  background-color: #fff;
  background-color: #fff;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.InboxListTypeDropdown_iconArrow__psGdM {
  width: 12px;
  color: #1a202c;
  color: #1a202c;
}

.InboxListTypeDropdown_iconArrowOpen__ANzP9 {
  transform: rotate(180deg);
}

.InboxListTypeDropdown_typeOption__1S6xh {
  font-size: 0.875rem;
  font-size: 0.875rem;
  cursor: pointer;
  text-decoration: none;
}

.InboxListTypeDropdown_typeOption__1S6xh:hover {
  text-decoration: none;
}

.InboxListTypeDropdown_selectedType__1WZgf {
  font-weight: 700;
  font-weight: 700;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.ActivityFeed_root__33grG {
  margin: 0;
  @apply --typography-base

  /* Clearfix */
  @apply --clearfix;
}

.ActivityFeed_messageItem__2Z3Gj {
  margin-bottom: 1rem;
  margin-bottom: 1rem

  /* Clearfix */
}

.ActivityFeed_messageItem__2Z3Gj::after {
  content: '';
  display: table;
  clear: both;
    }

@media (min-width: 768px) {

.ActivityFeed_messageItem__2Z3Gj {
    margin-bottom: 17px
}
  }

.ActivityFeed_messageItem__2Z3Gj:last-of-type {
    margin-bottom: 0;
  }

.ActivityFeed_message__1Ar4a,
.ActivityFeed_ownMessage__3q1D7 {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  max-width: 27rem;
  max-width: 27rem;
}

.ActivityFeed_message__1Ar4a {
  display: flex;
  flex-direction: column;
}

.ActivityFeed_ownMessage__3q1D7 {
  float: right;
}

.ActivityFeed_ownInquiryMessage__1TJ0c {
  display: flex;
  justify-content: flex-end;
}

.ActivityFeed_avatarWrapper__2RPPs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0 -4px 0.25rem;
  margin: 1rem 0 -4px 0.25rem;
  z-index: 10;
  z-index: 10;
}

.ActivityFeed_avatar__3EpCO {
  flex-shrink: 0;
  margin: 0px 0.5rem 0 0;
  margin: 0px 0.5rem 0 0;
  border: solid 3px #fff;
  border: solid 3px #fff;
}

.ActivityFeed_ownMessageContentWrapper__10KNM::after {
  content: '';
  display: table;
  clear: both;
    }

.ActivityFeed_ownMessageContentWrapper__10KNM {
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.ActivityFeed_messageContent__VQjyq,
.ActivityFeed_ownMessageContent__2SpDm {
  font-size: 1rem;
  font-size: 1rem;

  display: inline-block;
  margin: 0;
  padding: 8.5px 14px 8.5px 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1)
}

@media (min-width: 768px) {

.ActivityFeed_messageContent__VQjyq,
.ActivityFeed_ownMessageContent__2SpDm {
    padding: 8.5px 14px 8.5px 14px;
    margin: 0
}
  }

.ActivityFeed_messageContent__VQjyq {
  flex: 1 1;
  background-color: #edf2f7;
  background-color: #edf2f7;
  border-radius: 10px 10px 10px 2px;
  border-radius: 10px 10px 10px 2px;
  color: #2d3748;
  color: #2d3748;
}

.ActivityFeed_ownMessageContent__2SpDm {
  background-color: #2d3748;
  background-color: #2d3748;
  border-radius: 10px 10px 2px 10px;
  border-radius: 10px 10px 2px 10px;
  color: #ffffff;
  color: #ffffff;
  float: right;
}

.ActivityFeed_messageDate__UJrUa,
.ActivityFeed_ownMessageDate__3qGy6 {
  color: #718096;
  color: #718096;
  font-weight: 600;
  font-weight: 600;
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin: 0.25rem 0 0 0;
  margin: 0.25rem 0 0 0
}

@media (min-width: 768px) {

.ActivityFeed_messageDate__UJrUa,
.ActivityFeed_ownMessageDate__3qGy6 {
    margin: 0.25rem 0;
    margin: 0.25rem 0
}
  }

.ActivityFeed_ownMessageDate__3qGy6 {
  text-align: right;
}

.ActivityFeed_showOlderWrapper__hhtQ2 {
  text-align: center;
  margin-bottom: 1px
}

@media (min-width: 768px) {

.ActivityFeed_showOlderWrapper__hhtQ2 {
    margin-bottom: 0
}
  }

.ActivityFeed_firstName__3BxfA {
  color: var(--gray700);
  font-weight: 700;
  font-weight: 700;
  margin: 0;
}

.ActivityFeed_inquiryContainer__1kp4F {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  font-weight: normal;
}

.ActivityFeed_ownInquiry__3LlmZ {
  background-color: #2d3748;
  background-color: #2d3748;
  color: #fff;
  color: #fff;
  border-radius: 10px 10px 2px 10px;
  border-radius: 10px 10px 2px 10px;
}

.ActivityFeed_inquiry__1uwLc {
  background-color: #edf2f7;
  background-color: #edf2f7;
  color: #2d3748;
  color: #2d3748;
  border-radius: 10px 10px 10px 2px;
  border-radius: 10px 10px 10px 2px;
}

.ActivityFeed_inquiryMessage__3ML0Z {
  white-space: pre-line;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.AuthenticationPageReview_quoteIcon__2djUU {
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem;
}

.AuthenticationPageReview_content__i_cwk {
  font-size: 1.5rem;
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.AuthenticationPageReview_content__i_cwk {
    font-size: 1.5rem;
    font-size: 1.5rem
}
  }

.AuthenticationPageReview_ratingWrapper__2n8Z3 {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.AuthenticationPageReview_starIcon__3o0qu {
  height: 1rem;
  height: 1rem;
  width: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
  margin-right: 0.25rem;
}

.AuthenticationPageReview_authorWrapper__1JXy3 {
  display: flex;
  align-items: center;
  margin-bottom: 2.25rem;
  margin-bottom: 2.25rem;
}

.AuthenticationPageReview_author__3lb-1 {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
}

.AuthenticationPageReview_authorVerified__1VivE {
  display: flex;
  align-items: center;
  font-style: italic;
}

.AuthenticationPageReview_authorVerifiedIcon__3NgMJ {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #264136;
  background-color: #264136;
  border-radius: 50%;
  font-size: 0.75rem;
  font-size: 0.75rem;
  width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.AuthenticationPageReview_footer__2I5Rf {
  color: #3e704b;
  color: #3e704b;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.BookingStatusInquiry_inquiryContainer__3_9v1 {
  margin: 0;
  padding: 0.5rem 1rem 0.5rem 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 0.5rem 0.5rem 0 0.5rem;
  border-radius: 0.5rem 0.5rem 0 0.5rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  font-weight: normal;
}

.BookingStatusInquiry_ownInquiry__2Wn9T {
  background-color: #2d3748;
  background-color: #2d3748;
  color: #fff;
  color: #fff;
  font-size: 1rem;
  font-size: 1rem;
}

.BookingStatusInquiry_inquiry__q36Cm {
  background-color: #edf2f7;
  background-color: #edf2f7;
  color: #2d3748;
  color: #2d3748;
}

.BookingStatusInquiry_inquiryMessage__39h48 {
  white-space: pre-line;
  margin-top: 1rem;
  margin-top: 1rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.BookingStatusInquiryExpired_inquiryExpiredWrapper__kV3yj {
  display: flex;
  width: 100%
}

@media (min-width: 768px) {

.BookingStatusInquiryExpired_inquiryExpiredWrapper__kV3yj {
    width: 80%;
    width: 80%
}
  }

.BookingStatusInquiryExpired_inquiryGatedWrapper__3U69k {
  display: flex;
  justify-content: flex-end;
}

.BookingStatusInquiryExpired_guestInquiryGatedWrapper__KDqCO {
  justify-content: flex-start;
}

.BookingStatusInquiryExpired_inquiryGated__14E-g {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end
}

@media (min-width: 768px) {

.BookingStatusInquiryExpired_inquiryGated__14E-g {
    width: 75%;
    width: 75%
}
  }

.BookingStatusInquiryExpired_guestInquiryGated__vhvUw {
  align-items: flex-start;
}

.BookingStatusInquiryExpired_inquiryGatedTitle__3XPjI {
  font-weight: normal;
  color: #718096;
  color: #718096;
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.BookingStatusInquiryExpired_inquiryGatedTitleWithAuthor__2AQSn {
  margin-bottom: 0;
  margin-top: 1rem;
  margin-top: 1rem;
}

.BookingStatusInquiryExpired_inquiryGatedAuthor__2ysS6 {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  color: #1a202c;
  color: #1a202c;
  margin: 0 0.5rem 0 0.5rem;
  margin: 0 0.5rem 0 0.5rem;
}

.BookingStatusInquiryExpired_bookingStatus__E0NAr {
  margin: auto;
  width: 100%;
  padding: 1.5rem 1rem 1.5rem 1.25rem;
  padding: 1.5rem 1rem 1.5rem 1.25rem;
  border-radius: 0.75rem;
  border-radius: 0.75rem;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem
}

@media (min-width: 768px) {

.BookingStatusInquiryExpired_bookingStatus__E0NAr {
    margin: 0 0 1.25rem 0;
    margin: 0 0 1.25rem 0
}
  }

.BookingStatusInquiryExpired_bookingStatusHeader__vy1wt {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.BookingStatusInquiryExpired_bookingStatusBody__nBxT7 {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.BookingStatusInquiryExpired_bookingStatusDate__3a_t0 {
  font-size: 0.75rem;
  font-size: 0.75rem;
  font-weight: normal;
}

.BookingStatusInquiryExpired_avatar__3ZTN7 {
  width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  height: 2.5rem;
  margin-left: 1rem;
  margin-left: 1rem;
  position: relative;
  top: 6px;
  border: 3px solid #fff;
  border: 3px solid #fff;
}

.BookingStatusInquiryExpired_divider___2mwH {
  height: 1px;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  margin: 1.25rem 0;
  margin: 1.25rem 0;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.BookingStatus_root__pLMuE {
  margin: 0;
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
}

.BookingStatus_transitionItem__3E1ub {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.BookingStatus_transitionItem__3E1ub {
    margin-bottom: 1rem;
    margin-bottom: 1rem
}
  }

.BookingStatus_transitionItem__3E1ub:last-of-type {
    margin-bottom: 0;
  }

.BookingStatus_transition__l-GPw {
  display: flex;
  flex-direction: row;
  border-radius: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 768px) {

.BookingStatus_transition__l-GPw {
    padding: 0
}
  }

.BookingStatus_transitionInquiry__33sjo {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem
}

.BookingStatus_transitionInquiry__33sjo:last-of-type {
    margin-bottom: 0;
  }

.BookingStatus_transitionDateRight__2_WAm {
  color: #718096;
  color: #718096;
  font-weight: 600;
  font-weight: 600;
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin: 7px 0 0 0
}

@media (min-width: 768px) {

.BookingStatus_transitionDateRight__2_WAm {
    margin: -1px 0 1px 0
}
  }

.BookingStatus_transitionContent__1VK3O,
.BookingStatus_transitionContentInquiry__NOUMS {
  font-weight: 700;
  font-weight: 700
}

@media (min-width: 768px) {

.BookingStatus_transitionContent__1VK3O,
.BookingStatus_transitionContentInquiry__NOUMS {
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
    font-weight: 700;
    font-weight: 700
}
  }

.BookingStatus_transitionContent__1VK3O {
  font-size: 1.25rem;
  font-size: 1.25rem;
  margin: 0 auto
}

@media (min-width: 768px) {

.BookingStatus_transitionContent__1VK3O {
    font-size: 1.125rem;
    font-size: 1.125rem
}
  }

.BookingStatus_transitionContentInquiry__NOUMS {
  margin: auto;
}

.BookingStatus_transitionDate__CAeTR,
.BookingStatus_transitionDateInquiry__1zTf9 {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  color: #1a202c;
  color: #1a202c;
  font-size: 0.75rem;
  font-size: 0.75rem;
  text-align: left;
  margin: 0.5rem 0 0 0;
  margin: 0.5rem 0 0 0
}

@media (min-width: 768px) {

.BookingStatus_transitionDate__CAeTR,
.BookingStatus_transitionDateInquiry__1zTf9 {
    margin: -1px 0 1px 0
}
  }

.BookingStatus_transitionDate__CAeTR {
  text-align: left;
}

.BookingStatus_transitionDateInquiry__1zTf9 {
  text-align: right;
}

.BookingStatus_bullet__3V7MR {
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.BookingStatus_reviewContent__3MwjB {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.BookingStatus_reviewContent__3MwjB {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.BookingStatus_reviewContent__3MwjB {
  font-style: italic;
  white-space: pre-line;
  margin: 0.5rem 0 0 0;
  margin: 0.5rem 0 0 0
}

@media (min-width: 768px) {

.BookingStatus_reviewContent__3MwjB {
    max-width: 500px
}
  }

.BookingStatus_reviewStars__3pVra {
  display: block;
  margin-top: 0.25rem;
  margin-top: 0.25rem
}

@media (min-width: 768px) {

.BookingStatus_reviewStars__3pVra {
    margin: 0.25rem 0;
    margin: 0.25rem 0
}
  }

.BookingStatus_reviewStar__1hUgR {
  width: 0.75rem;
  width: 0.75rem;
  height: 0.75rem;
  height: 0.75rem;
  margin-right: 2px;
  font-style: normal;
  font-style: initial;
}

.BookingStatus_showOlderWrapper__353x6 {
  text-align: center;
  margin-bottom: 1px
}

@media (min-width: 768px) {

.BookingStatus_showOlderWrapper__353x6 {
    margin-bottom: 0
}
  }

.BookingStatus_reviewButton__9rgYi {
  font-weight: 700;
  font-weight: 700;
  margin: 1.25rem auto;
  margin: 1.25rem auto
}

@media (min-width: 768px) {

.BookingStatus_reviewButton__9rgYi {
    display: flex;
    margin: 1rem 0 0.5rem 0;
    margin: 1rem 0 0.5rem 0
}
  }

.BookingStatus_firstName__3CYZq {
  color: var(--gray700);
  font-weight: 600;
  font-weight: 600;
  margin: 0;
}

.BookingStatus_circle__3uyc9 {
  background: black;
  border-radius: 50%;
  border-radius: 50%;
  width: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  height: 0.5rem;
  margin: 0.5rem 0.5rem 0.5rem auto;
  margin: 0.5rem 0.5rem 0.5rem auto;
}

.BookingStatus_status__3gPgp,
.BookingStatus_circle__3uyc9 {
  float: left;
}

.BookingStatus_status__3gPgp {
  font-weight: 700;
  font-weight: 700;
}

.BookingStatus_float__1mZ0R {
  display: inline-block;
}

.BookingStatus_actionButtons__1brja {
  display: block;
  margin: 0.75rem auto 0 auto;
  margin: 0.75rem auto 0 auto;
}

.BookingStatus_mainStatus__1_m6W {
  margin: 0 0 0.75rem 0;
  margin: 0 0 0.75rem 0;
}

.BookingStatus_subtext__SIzs9 {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
}

.BookingStatus_inquiryContainer__2W3te {
  margin: 0;
  padding: 0.5rem 1rem 0.5rem 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  font-weight: normal;
  background-color: #edf2f7;
  background-color: #edf2f7;
  color: #2d3748;
  color: #2d3748;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.BookingBreakdown_root__17w5i {
  display: flex;
  flex-direction: column;
}

.BookingBreakdown_nowrap__1_GQo {
  white-space: nowrap;
}

.BookingBreakdown_lineItem__2c-y4 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.BookingBreakdown_subTotalLineItem__2mKUr {
  font-weight: 700;
  font-weight: 700;
  font-size: 0.875rem;
  font-size: 0.875rem;

  margin: 0;
}

@media (min-width: 768px) {

.BookingBreakdown_subTotalLineItem__2mKUr {
    margin: 0;
}
  }

.BookingBreakdown_subTotalLineItem__2mKUr {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.BookingBreakdown_itemLabel__1-fe5 {
  flex: 1 1;
  margin-top: 1px; /* align with baseline */
}

.BookingBreakdown_itemValue__3Pv0P {
  margin: 0 0 0 10px
}

@media (min-width: 768px) {

.BookingBreakdown_itemValue__3Pv0P {
    margin: 0 0 0 10px
}
  }

.BookingBreakdown_totalDivider__1zAPX {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 13px 0 4px 0;

  border: none;
  background-color: #e2e8f0;
  background-color: #e2e8f0
}

@media (min-width: 768px) {

.BookingBreakdown_totalDivider__1zAPX {
    margin: 2px 0 14px 0
}
  }

.BookingBreakdown_totalLabel__yPcJ8 {
  font-weight: 700;
  font-weight: 700;

  /* Move so that baseline aligns with the total price */
  padding-top: 6px
}

@media (min-width: 768px) {

.BookingBreakdown_totalLabel__yPcJ8 {
    padding-top: 8px
}
  }

.BookingBreakdown_totalPrice__vrSBb {
  font-weight: 700;
  font-weight: 700;
  margin: 0 0 0 10px;
  padding-top: 4px
}

@media (min-width: 768px) {

.BookingBreakdown_totalPrice__vrSBb {
    margin: 2px 0 0 10px;
    padding-top: 0
}
  }

.BookingBreakdown_feeInfo__9LcpZ {
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
  flex-shrink: 0;
  color: var(--gray900)
}

@media (min-width: 768px) {

.BookingBreakdown_feeInfo__9LcpZ {
    margin-top: auto
}
  }

@media (min-width: 1024px) {

.BookingBreakdown_feeInfo__9LcpZ {
    margin-top: 0.25rem;
    margin-top: 0.25rem;
    margin-bottom: 0.75rem;
    margin-bottom: 0.75rem
}
  }

.BookingBreakdown_title__PmfHY {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700;
}

.BookingBreakdown_subtitle__2XmKz {
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.BookingBreakdown_datesWrapper__1wbS6 {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.BookingBreakdown_calendarIcon__2_QT2 {
  margin-right: 1rem;
  margin-right: 1rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.BookingDateRangeFilter_popupSize__3OAI9 {
  padding: 1.75rem 1.75rem 0 1.75rem;
  padding: 1.75rem 1.75rem 0 1.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.BookingPanel_packagesContainer__14uJq {
  display: none
}

@media (min-width: 1024px) {

.BookingPanel_packagesContainer__14uJq {
    display: flex;
    flex-direction: column;
    width: 100%
}
  }

.BookingPanel_modalHeading__3iBe7 {
  margin-top: 0px;
  margin-bottom: 36px;
  padding: 0 24px
}

@media (min-width: 768px) {

.BookingPanel_modalHeading__3iBe7 {
    padding: 0
}
  }

@media (min-width: 1024px) {

.BookingPanel_modalHeading__3iBe7 {
    display: none
}
  }

.BookingPanel_title__1AYc1 {
  /* Font */
  font-size: 1.5rem;
  font-size: 1.5rem
}

@media (min-width: 768px) {

.BookingPanel_title__1AYc1 {
  font-size: 1.875rem;
  font-size: 1.875rem
}
    }

.BookingPanel_title__1AYc1 {

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 768px) {

.BookingPanel_title__1AYc1 {
    margin-top: 0;
    margin-bottom: 5px
}
  }

.BookingPanel_author__2DbpO {
  width: 100%;
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.BookingPanel_author__2DbpO {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.BookingPanel_author__2DbpO {
  margin-top: 7px;
  margin-bottom: 0
}

@media (min-width: 768px) {

.BookingPanel_author__2DbpO {
    margin-top: 0;
    margin-bottom: 0;
    color: #4a5569;
    color: #4a5569
}
  }

.BookingPanel_bookingHeading__1YkK0 {
  display: none
}

@media (min-width: 1024px) {

.BookingPanel_bookingHeading__1YkK0 {
    display: block;
    margin-top: -2px
}
  }

.BookingPanel_bookingTitle__1RDXi {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.125rem;
  font-size: 1.125rem;
}

@media (min-width: 768px) {

.BookingPanel_bookingTitle__1RDXi {
  font-size: 1.5rem;
  font-size: 1.5rem;
}
    }

.BookingPanel_bookingTitle__1RDXi {
  color: #1a202c;
  color: #1a202c;
  margin-top: 0;
  margin-bottom: 0;
}

.BookingPanel_bookingHelp__5EXiv {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.BookingPanel_bookingHelp__5EXiv {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.BookingPanel_bookingHelp__5EXiv {
  color: #4a5569;
  color: #4a5569
}

@media (min-width: 768px) {

.BookingPanel_bookingHelp__5EXiv {
    color: #4a5569;
    color: #4a5569;
    display: block;
    margin-top: 0
}
  }

.BookingPanel_bookingForm__3H0Kc {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0 6rem 0;
  margin: 0 0 6rem 0
}

@media (min-width: 768px) {

.BookingPanel_bookingForm__3H0Kc {
    display: none;
    margin: 0;
    min-height: 400px
}
  }

@media (min-width: 1024px) {

.BookingPanel_bookingForm__3H0Kc {
    max-width: 350px;
    min-height: auto
}
  }

.BookingPanel_bookingDatesSubmitButtonWrapper__3Zmj_ {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  background-color: #ffffff;
  text-align: center
}

@media (min-width: 768px) {

.BookingPanel_bookingDatesSubmitButtonWrapper__3Zmj_ {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent
}
  }

.BookingPanel_openBookingForm__1Ousr {
  /* Ensure that mobile button is over Footer too */
  z-index: 60;
  z-index: 60;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1rem 1rem 0 1rem;
  padding: 1rem 1rem 0 1rem;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0)
}

@media (min-width: 768px) {

.BookingPanel_openBookingForm__1Ousr {
    padding: 18px 60px 18px 60px
}
  }

@media (min-width: 1024px) {

.BookingPanel_openBookingForm__1Ousr {
    display: none
}
  }

.BookingPanel_availLink__2XOjy {
  width: 100%;
}

.BookingPanel_bookButton__1pmJj {
  border-radius: 25px;
  border-radius: 25px;
  margin-bottom: 1em;
}

.BookingPanel_priceContainer__2-ubR {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.BookingPanel_priceValue__16Xwa {
  /* Font */
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px
}

@media (min-width: 768px) {

.BookingPanel_priceValue__16Xwa {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px
}
    }

.BookingPanel_priceValue__16Xwa {
  color: #4a5569;
  color: #4a5569;

  margin-top: 0;
  margin-bottom: 0px
}

@media (min-width: 768px) {

.BookingPanel_priceValue__16Xwa {
    margin-top: 0;
    margin-bottom: 0
}
  }

.BookingPanel_perUnit__3fRyE {
  /* Font */
  font-size: 13px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px
}

@media (min-width: 768px) {

.BookingPanel_perUnit__3fRyE {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
}
    }

.BookingPanel_perUnit__3fRyE {
  color: #4a5569;
  color: #4a5569;

  margin-top: 0;
  margin-bottom: 0px
}

@media (min-width: 768px) {

.BookingPanel_perUnit__3fRyE {
    margin-top: 0;
    margin-bottom: 0
}
  }

.BookingPanel_closedListingButton__3DJ3Z {
  border-left: 1px solid #a1aec1;
  border-left: 1px solid #a1aec1;
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.Discussion_messageDate__J5dGp {
  color: #bbb;
  display: block;
  text-align: center;
  margin-right: 1rem;
  font-size: 0.8rem;
}

.Discussion_messageText__7Atcy {
  background-color: #eee;
  padding: 1rem;
  margin: 0 1rem;
}

.Discussion_messageAuthor__1I3vR {
  color: #666;
  display: block;
  text-align: right;
  margin-right: 1rem;
}

.Discussion_sendMessageForm__2Q3B8 {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #eee;
  padding: 1rem;
}

.Discussion_sendMessageInput__AJmwX {
  display: inline-block;
  width: calc(100% - 6rem);
  padding: 0.5rem;
  border-right: none;
  background-color: #fff;
}

.Discussion_sendMessageButton__3QkmE {
  display: inline-block;
  width: 6rem;
  margin: 0;
  background-color: #aaa;
  color: #fff;
  border: 1px solid #aaa
}

.Discussion_sendMessageButton__3QkmE:hover,
  .Discussion_sendMessageButton__3QkmE:active {
    background-color: #999;
    border-color: #999;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.FilterPlain_root__ZfvqY {
  position: relative;
  padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid #a1aec1;
  border-bottom: 1px solid #a1aec1;
}

.FilterPlain_filterLabel__2Y7Rt,
.FilterPlain_filterLabelSelected__21Siq {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.25rem;
  font-size: 1.25rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;
}

@media (min-width: 768px) {

.FilterPlain_filterLabel__2Y7Rt,
.FilterPlain_filterLabelSelected__21Siq {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px;
}
    }

.FilterPlain_filterLabel__2Y7Rt,
.FilterPlain_filterLabelSelected__21Siq {

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
}

.FilterPlain_filterLabel__2Y7Rt {
  color: #1a202c;
  color: #1a202c;
}

.FilterPlain_filterLabelSelected__21Siq {
  color: #3e704b;
  color: #3e704b;
}

.FilterPlain_labelButton__1x4-e {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.FilterPlain_clearButton__3dtKR {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.FilterPlain_clearButton__3dtKR {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.FilterPlain_clearButton__3dtKR {
  font-weight: 500;
  font-weight: 500;
  color: #a1aec1;
  color: #a1aec1;

  /* Layout */
  display: inline;
  float: right;
  margin-top: 6px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none
}

.FilterPlain_clearButton__3dtKR:focus,
  .FilterPlain_clearButton__3dtKR:hover {
    color: #4a5569;
    color: #4a5569;
  }

.FilterPlain_plain__12Klu {
  width: 100%;
  display: none;
}

.FilterPlain_isOpen__QyIAl {
  display: block;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.FilterPopup_root__1qe9b {
  position: relative;
  display: inline-block;
}

.FilterPopup_popup__1k4Y5 {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: 50;
  z-index: 50;

  /* Layout */
  min-width: 300px;
  margin-top: 7px;
  background-color: #ffffff;
  background-color: #ffffff;

  /* Borders */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
}

.FilterPopup_popupSize__7GOmE {
  padding: 15px 30px 17px 30px;
}

.FilterPopup_isOpen__2RsHE {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.FilterPopup_modalContent__2iJ8l {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.FilterPopup_showResultsButton__1xFG1 {
  margin-top: auto;
  flex-shrink: 0;
}

.FilterPopup_clearButton__ahPKl {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  padding: 1rem;
  padding: 1rem;
  flex-shrink: 0;
  color: #718096;
  color: #718096;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FilterButton_root__pT58U {
}

.FilterButton_button__17o1I {
  font-family: inherit;
  padding: 0.375rem 1rem 0.375rem 1rem;
  padding: 0.375rem 1rem 0.375rem 1rem;
  font-size: 0.875rem;
  font-size: 0.875rem;
  line-height: 1.5;
  line-height: 1.5;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 0.25rem;
  border-radius: 0.25rem;
  color: #2d3748;
  color: #2d3748;
  border: 1px solid #2d3748;
  border: 1px solid #2d3748;
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer
}

.FilterButton_button__17o1I:hover,
  .FilterButton_button__17o1I:focus {
    outline: none;
  }

.FilterButton_buttonSelected__2QNHL {
  box-shadow: inset 0 0 0 1px #2d3748;
  box-shadow: inset 0 0 0 1px #2d3748;
  font-weight: bold;
}

.FilterButton_buttonEllipsis__LBcMR {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.FilterButton_icon__3Aque {
  margin-right: 0.5rem;
  margin-right: 0.5rem;
  display: inline-block;
  max-height: 1.25rem;
  max-height: 1.25rem;
  width: auto;
  fill: currentColor;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FavoriteListing_root__2sv-g {
  position: relative;
}

.FavoriteListing_circleFav__gmSRW {
  cursor: pointer;
  width: 2.25rem;
  width: 2.25rem;
  height: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  color: initial;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FavoriteListing_icon__2Z2zS {
  width: 1rem;
  width: 1rem;
}

.FavoriteListing_iconFull__3RW_1 {
  fill: #ca2d10;
  fill: #ca2d10
}

.FavoriteListing_iconFull__3RW_1 > path {
    fill: #ca2d10;
    fill: #ca2d10;
  }

.FavoriteListing_favButton__2zv0y {
  text-decoration: underline
}

@media (min-width: 768px) {

.FavoriteListing_favButton__2zv0y {
    margin-left: 1.75rem;
    margin-left: 1.75rem
}
  }

.FavoriteListing_favButton__2zv0y .FavoriteListing_icon__2Z2zS {
    width: 1rem;
    width: 1rem
  }

@media (min-width: 768px) {

.FavoriteListing_favButton__2zv0y .FavoriteListing_icon__2Z2zS {
      height: 1rem;
      height: 1rem;
      width: auto;
      width: initial
  }
    }

.FavoriteListing_favButtonIconFull__2ld_4 {
  fill: #fff;
  fill: #fff
}

.FavoriteListing_favButtonIconFull__2ld_4 > path {
    fill: #fff;
    fill: #fff;
  }

.FavoriteListing_buttonBody__3izqG {
  display: flex;
  align-items: center
}

@media (min-width: 768px) {

.FavoriteListing_buttonBody__3izqG > svg {
      margin-right: 0.5rem;
      margin-right: 0.5rem
  }
    }

.FavoriteListing_buttonLabel__3C0Dv {
  display: none
}

@media (min-width: 768px) {

.FavoriteListing_buttonLabel__3C0Dv {
    display: block
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.Toaster_toaster__3J30n {
  margin-top: 2.5rem;
  margin-top: 2.5rem;
}

.Toaster_toast__hS2ax {
  border-radius: 35px !important;
  border-radius: 35px !important;
}

.Toaster_toast__hS2ax a {
  font-weight: 700;
  font-weight: 700;
  color: #3e704b;
  color: #3e704b;
  text-decoration: underline;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ListingCard_root__2Z5_e {
  display: block;
  color: #1a202c;
  color: #1a202c;
  transition: ease-in 0.2s;
  transition: ease-in 0.2s;
  border-radius: 5px;
  border-radius: 5px
}

.ListingCard_root__2Z5_e:hover {
    text-decoration: none;
    transform: scale(1.02);
  }

.ListingCard_rootResponsive__Sj5J- {
}

@media (min-width: 1024px) {

.ListingCard_rootResponsive__Sj5J- {
    display: flex;
    align-items: center;
    background-color: #fff;
    background-color: #fff
}
  }

.ListingCard_imageWrapper__2Tn6U {
  display: block;
  width: 100%;
  position: relative;
}

.ListingCard_imageInner__1qqtN {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
}

.ListingCard_imageRoot__1Lfdh {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 5px;
  border-radius: 5px;
}

.ListingCard_imageWrapperResponsive__1TbaI {
}

@media (min-width: 1024px) {

.ListingCard_imageWrapperResponsive__1TbaI {
    width: 42%;
    max-width: 19rem;
    max-width: 19rem;
    flex-shrink: 0;
    align-self: stretch
}
  }

.ListingCard_imageRootResponsive__a0Qrp {
}

@media (min-width: 1024px) {

.ListingCard_imageRootResponsive__a0Qrp {
    position: static;
    width: 100%;
    height: 100%;
    object-fit: cover
}
  }

.ListingCard_infoContainer__sqKcK {
  display: flex;
  justify-content: space-between;
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}

.ListingCard_infoContainerResponsive__12ON- {
}

@media (min-width: 1024px) {

.ListingCard_infoContainerResponsive__12ON- {
    width: 58%;
    margin: 2rem 1.5rem 2rem 1.5rem;
    margin: 2rem 1.5rem 2rem 1.5rem;
    flex-direction: column
}
  }

.ListingCard_infoMain__2bj7y {
  display: flex;
  flex-direction: column;
  margin-right: 0.75rem;
  margin-right: 0.75rem;
}

.ListingCard_infoMainResponsive__1kbRN {
}

.ListingCard_infoHeader__A9bfw {
  font-size: 0.75rem;
  font-size: 0.75rem;
  color: #718096;
  color: #718096;
}

.ListingCard_infoHeaderResponsive__2e6oz {
}

@media (min-width: 1024px) {

.ListingCard_infoHeaderResponsive__2e6oz {
    font-size: 0.875rem;
    font-size: 0.875rem
}
  }

.ListingCard_infoTitle__1ZuCf {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}

.ListingCard_infoTitleResponsive__hZe1x {
}

@media (min-width: 1024px) {

.ListingCard_infoTitleResponsive__hZe1x {
    font-size: 1.5rem;
    font-size: 1.5rem
}
  }

.ListingCard_priceInfo__2iWjE {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
}

.ListingCard_priceInfoResponsive__VKSAb {
}

@media (min-width: 1024px) {

.ListingCard_priceInfoResponsive__VKSAb {
    align-items: center;
    flex-direction: row
}
  }

.ListingCard_priceInfoLabel__1O3_z {
  margin-bottom: 0;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.ListingCard_priceInfoLabelResponsive__23wrO {
}

@media (min-width: 1024px) {

.ListingCard_priceInfoLabelResponsive__23wrO {
    font-size: 1rem;
    font-size: 1rem
}
  }

.ListingCard_priceAmount__1Y8fu {
  color: #1a202c;
  color: #1a202c;
  font-size: 0.875rem;
  font-size: 0.875rem
}

.ListingCard_priceAmount__1Y8fu span:first-of-type {
    font-weight: 700;
    font-weight: 700;
  }

.ListingCard_priceAmountResponsive__1PNA6 {
}

@media (min-width: 1024px) {
    .ListingCard_priceAmountResponsive__1PNA6 span:first-of-type {
      font-size: 1rem;
      font-size: 1rem;
      font-weight: 700;
      font-weight: 700;
    }
  }

.ListingCard_infoFooter__3S5vH {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.ListingCard_infoFooterResponsive__3Bvsd {
}

@media (min-width: 1024px) {

.ListingCard_infoFooterResponsive__3Bvsd {
    font-size: 1rem;
    font-size: 1rem
}
  }

.ListingCard_favoriteWrapper__2j5Yg {
  position: absolute;
  top: 0.75rem;
  top: 0.75rem;
  right: 0.75rem;
  right: 0.75rem;
  z-index: 1;
}

.ListingCard_panelHostMobile__3D33j {
  display: contents
}

@media (min-width: 1024px) {

.ListingCard_panelHostMobile__3D33j {
    display: none
}
  }

.ListingCard_firstToBook__Zfdv6 {
  font-size: 0.875rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

.ListingCard_clockIcon__2cuf- {
  color: #3e704b;
  color: #3e704b;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
  height: 1rem;
  height: 1rem;
  width: 1rem;
  width: 1rem;
}

.ListingCard_ratingWrapper__2z_ne {
  margin-top: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 1024px) {

.ListingCard_ratingWrapper__2z_ne {
    margin-top: 0.75rem;
    margin-top: 0.75rem
}
  }

.ListingCard_reviewRating__2196o {
  display: inline-flex;
  white-space: nowrap;
}

.ListingCard_reviewRatingStar__3aLg8 {
  height: 1rem;
  height: 1rem;
  width: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
  margin-right: 0.25rem
}

.ListingCard_reviewRatingStar__3aLg8:last-of-type {
    margin-right: 0;
  }

.ListingCard_badgeWrapper__2ePOi {
  display: flex;
  background-color: #f7fafc;
  background-color: #f7fafc;
  padding: 0.5rem 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border-radius: 5px;
  border: solid 1px #edf2f7;
  border: solid 1px #edf2f7;
}

.ListingCard_icon__1npOZ {
  height: 1rem;
  height: 1rem;
  width: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.ListingCard_infoSpan__XNx1_ {
  display: block;
  font-size: 0.75rem;
  font-size: 0.75rem;
  font-weight: 700;
  font-weight: 700;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.ManageListingCard_root__i9ISJ {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: relative

  /* Remove link's hover effect */
}

.ManageListingCard_root__i9ISJ:hover {
    text-decoration: none;
  }

.ManageListingCard_menuOverlayWrapper__23lpV {
  /* Positioning */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ManageListingCard_menuOverlay__1IIv9 {
  /* Positioning */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay */
  background-color: #a1aec1;
  background-color: #a1aec1;
  mix-blend-mode: multiply;
  opacity: 0;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
}

.ManageListingCard_menuOverlayContent__1z8eN {
  /* Positioning */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* Fonts */
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.ManageListingCard_menuOverlayContent__1z8eN {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.ManageListingCard_menuOverlayContent__1z8eN {
  font-weight: 600;
  font-weight: 600;
  color: #ffffff;
  color: #ffffff;

  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0
}

@media (min-width: 768px) {

.ManageListingCard_menuOverlayContent__1z8eN {
    font-weight: 600;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0
}
  }

.ManageListingCard_threeToTwoWrapper__3JeZt {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
  cursor: pointer
}

.ManageListingCard_threeToTwoWrapper__3JeZt:hover {
    transform: scale(1.02);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }

/* Firefox doesn't support image aspect ratio inside flexbox */

.ManageListingCard_aspectWrapper__2zjqp {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: #a1aec1;
  background: #a1aec1; /* Loading BG color */
  margin-bottom: 1px; /* Fix 1px bug */
}

.ManageListingCard_rootForImage__1h7WL {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 2px;
}

.ManageListingCard_menubarWrapper__3_7jf {
  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42px;
}

.ManageListingCard_menubarGradient__3IWO3 {
  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Gradient */
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAqCAYAAACUV/o4AAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAALEwAACxMBAJqcGAAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAGhJREFUKBVjZGBgKANibiAGga8sQAKEf4N4QMAC4vwH4r8gHgiABOAcmMAfEAMGsKrA0DIqAAsvHGFKkwACRRQjzGJQRP2DcUA0LOZAEQ5S9R8mAJIEA6wCyIYSqYVSz4FcxwR1E5gCAD7HLabS/HFUAAAAAElFTkSuQmCC');
  background-repeat: repeat;
  opacity: 0.5;
}

.ManageListingCard_menubar__1AiLb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.ManageListingCard_menuLabel__1GlMI {
  padding: 0px 9px 0 8px;
}

.ManageListingCard_iconWrapper__1VlNK {
  padding: 0px 10px 1px 10px;
  color: #ffffff;
  color: #ffffff;
  border-radius: 4px;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s
}

.ManageListingCard_iconWrapper__1VlNK:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

.ManageListingCard_listingMenuIsOpen__HmMzr .ManageListingCard_iconWrapper__1VlNK {
    background-color: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    color: #ffffff;
  }

.ManageListingCard_menuContent__3euCE {
  position: absolute;
  right: 0;
  z-index: 50;
  z-index: 50;

  background-color: #4a5569;

  background-color: #4a5569;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
}

.ManageListingCard_menuItem__2MZuq {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: #1a202c;
  color: #1a202c;

    /* Text size should be inherited */
  text-decoration: none
}

.ManageListingCard_menuItem__2MZuq:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.ManageListingCard_menuItem__2MZuq {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.ManageListingCard_menuItem__2MZuq {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.ManageListingCard_menuItem__2MZuq {
  color: #ffffff;
  color: #ffffff;
  font-weight: 500;
  font-weight: 500;
  white-space: nowrap;
  text-align: left;
  padding: 8px 14px;
  border-radius: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s
}

.ManageListingCard_menuItem__2MZuq:hover {
    text-decoration: none;
    background-color: #ff0000;
    background-color: #ff0000;
  }

@media (min-width: 768px) {

.ManageListingCard_menuItem__2MZuq {
    margin-top: 0;
    margin-bottom: 0
}
  }

.ManageListingCard_menuItemDisabled__3kscv {
  color: #a1aec1;
  color: #a1aec1
}

.ManageListingCard_menuItemDisabled__3kscv:hover {
    color: #a1aec1;
    color: #a1aec1;
  }

.ManageListingCard_info__lOk3n {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 8px 0 2px 0;
}

.ManageListingCard_price__2SgDL {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-shrink: 0;

  padding-top: 4px;
  padding-bottom: 2px
}

@media (min-width: 768px) {

.ManageListingCard_price__2SgDL {
    padding-top: 5px;
    padding-bottom: 3px
}
  }

.ManageListingCard_priceValue__3-K2t {
  /* Font */
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.ManageListingCard_priceValue__3-K2t {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.ManageListingCard_priceValue__3-K2t {
  color: #3e704b;
  color: #3e704b;
  font-weight: 600;
  font-weight: 600;

  margin-right: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 768px) {

.ManageListingCard_priceValue__3-K2t {
    margin-top: 0;
    margin-bottom: 0
}
  }

.ManageListingCard_perUnit__3ZoR3 {
  /* Font */
  font-size: 13px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
}

@media (min-width: 768px) {

.ManageListingCard_perUnit__3ZoR3 {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }

.ManageListingCard_perUnit__3ZoR3 {
  color: #4a5569;
  color: #4a5569;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .ManageListingCard_perUnit__3ZoR3 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.ManageListingCard_noPrice__1grtI {
  padding: 5px 0 3px 0;
}

.ManageListingCard_mainInfo__GNBWm {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ManageListingCard_titleWrapper__R6YzS {
}

.ManageListingCard_title__3jFEV {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: #1a202c;
  color: #1a202c;

    /* Text size should be inherited */
  text-decoration: none
}

.ManageListingCard_title__3jFEV:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.ManageListingCard_title__3jFEV {
  /* Font */
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.25rem;
  font-size: 1.25rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px
}

@media (min-width: 768px) {

.ManageListingCard_title__3jFEV {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px
}
    }

.ManageListingCard_title__3jFEV {
  color: #4a5569;
  color: #4a5569;
  text-align: left;

  padding-top: 3px;
  padding-bottom: 3px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 768px) {

.ManageListingCard_title__3jFEV {
    padding-top: 4px;
    padding-bottom: 4px;

    margin-top: 0;
    margin-bottom: 0
}
  }

.ManageListingCard_titlePending__3P_X5 {
  color: #ffaa00;
  color: #ffaa00;
}

.ManageListingCard_titleDraft__1s5TK {
  margin-right: 8px;
}

/* Solid gray background for draft listings without image */

.ManageListingCard_draftNoImage__2TwTc {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #4a5569;
  background-color: #4a5569;
}

.ManageListingCard_edit__3x3-j {
  font-weight: 400;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.ManageListingCard_edit__3x3-j {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.ManageListingCard_edit__3x3-j {

    /* Dimensions */
  display: block;
  width: 100%;
  min-height: 59px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 16px 0 0 0;

    /* Borders */
  border-style: solid;
  border-color: #3e704b;
  border-color: #3e704b;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #3e704b;
  color: #3e704b;

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
}

.ManageListingCard_edit__3x3-j:hover,
    .ManageListingCard_edit__3x3-j:focus {
  text-decoration: none;
    }

@media (min-width: 768px) {

.ManageListingCard_edit__3x3-j {
  min-height: 60px;
}
    }

.ManageListingCard_edit__3x3-j {

  color: #1a202c;

  color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;

    /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */
  padding: 18px 0 0 0;

  border-width: 1px;
  border-style: solid;
  border-color: #a1aec1;
  border-color: #a1aec1;
}

.ManageListingCard_edit__3x3-j:hover,
    .ManageListingCard_edit__3x3-j:focus {
  background-color: #1a202c;
  background-color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;
  color: #fff;
  color: #fff;
    }

.ManageListingCard_edit__3x3-j:disabled {
  background-color: #a1aec1;
  background-color: #a1aec1;
  color: #ffffff;
  color: #ffffff;
  border-color: #a1aec1;
  border-color: #a1aec1;
    }

.ManageListingCard_edit__3x3-j {

  /* TODO: Odd font-size */
  font-size: 14px;

  /* Reset min-height from button styles */
  min-height: 0;

  /* Reserve space for button */
  flex-shrink: 0;
  width: auto;
  height: 41px;
  padding: 7px 14px 7px 35px;

  /* Add edit icon as a background image */
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px center;
}

.ManageListingCard_openListingButton__nEuVo,
.ManageListingCard_finishListingDraftLink__3GPxD {
  font-weight: 400;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.ManageListingCard_openListingButton__nEuVo,
.ManageListingCard_finishListingDraftLink__3GPxD {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.ManageListingCard_openListingButton__nEuVo,
.ManageListingCard_finishListingDraftLink__3GPxD {

    /* Dimensions */
  display: block;
  width: 100%;
  min-height: 59px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 16px 0 0 0;

    /* Borders */
  border-style: solid;
  border-color: #3e704b;
  border-color: #3e704b;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #3e704b;
  color: #3e704b;

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
}

.ManageListingCard_openListingButton__nEuVo:hover,
    .ManageListingCard_openListingButton__nEuVo:focus,
    .ManageListingCard_finishListingDraftLink__3GPxD:hover,
    .ManageListingCard_finishListingDraftLink__3GPxD:focus {
  text-decoration: none;
    }

@media (min-width: 768px) {

.ManageListingCard_openListingButton__nEuVo,
.ManageListingCard_finishListingDraftLink__3GPxD {
  min-height: 60px;
}
    }

.ManageListingCard_openListingButton__nEuVo,
.ManageListingCard_finishListingDraftLink__3GPxD {

  background-color: #3e704b;

  background-color: #3e704b;
  color: #fff;
  color: #fff;
  border-color: #3e704b;
  border-color: #3e704b;
}

.ManageListingCard_openListingButton__nEuVo:hover,
    .ManageListingCard_openListingButton__nEuVo:focus,
    .ManageListingCard_finishListingDraftLink__3GPxD:hover,
    .ManageListingCard_finishListingDraftLink__3GPxD:focus {
  background-color: #2a5e38;
  background-color: #2a5e38;
  border-color: #2a5e38;
  border-color: #2a5e38;
  color: #fff;
  color: #fff;
    }

.ManageListingCard_openListingButton__nEuVo:disabled, .ManageListingCard_finishListingDraftLink__3GPxD:disabled {
  background-color: #a1aec1;
  background-color: #a1aec1;
  color: #ffffff;
  color: #ffffff;
    }

.ManageListingCard_openListingButton__nEuVo,
.ManageListingCard_finishListingDraftLink__3GPxD {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.ManageListingCard_openListingButton__nEuVo,
.ManageListingCard_finishListingDraftLink__3GPxD {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.ManageListingCard_openListingButton__nEuVo,
.ManageListingCard_finishListingDraftLink__3GPxD {

  /* Reset min-height from button styles */
  min-height: 0;

  width: 114px;
  padding: 8px;
  border-radius: 4px;
}

.ManageListingCard_menu__WvVV4 {
  display: none;
}

.ManageListingCard_cardIsOpen__1O73E {
  display: block;
}

.ManageListingCard_manageLinks__1Nm6g {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.ManageListingCard_manageLinks__1Nm6g {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.ManageListingCard_manageLinks__1Nm6g {
  line-height: 18px;
  padding-top: 2px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 768px) {

.ManageListingCard_manageLinks__1Nm6g {
    line-height: 24px;
    padding-top: 0;
    padding-bottom: 0;

    margin-top: 0;
    margin-bottom: 0
}
  }

.ManageListingCard_manageLink__1Y4n1 {
  text-decoration: none;
  color: #4a5569;
  color: #4a5569;
  white-space: pre
}

.ManageListingCard_manageLink__1Y4n1:hover {
    text-decoration: underline;
    color: #1a202c;
    color: #1a202c;
  }

.ManageListingCard_manageLinksSeparator__2PKGZ {
  margin: 0 3px 0 3px;
}

.ManageListingCard_shareButtonContainer__2NBrS {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}

.ManageListingCard_shareButton__38dbO {
  border: solid 1px #1a202c;
  border: solid 1px #1a202c;
  color: #1a202c;
  color: #1a202c;
  border-radius: 10px;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  padding: 0.5rem 1rem
}

.ManageListingCard_shareButton__38dbO:hover {
    background-color: #f7fafc;
    background-color: #f7fafc;
    color: #1a202c;
    color: #1a202c;
  }

.ManageListingCard_buttonFlex__3OdIX {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-weight: 700;
}

.ManageListingCard_facebookCircle__zJnPo {
  width: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  height: 1.75rem;
  color: #1a202c;
  color: #1a202c;
  margin-right: 1.25rem;
  margin-right: 1.25rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.Overlay_root__6RozK {
  /* Positioning */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Overlay_overlay__26QTz {
  /* Positioning */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay background style */
  background-color: #ffffff;
  background-color: #ffffff;
  opacity: 0.9;
}

.Overlay_overlayContent__2oN33 {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.Overlay_overlayContent__2oN33 {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.Overlay_overlayContent__2oN33 {
  color: #4a5569;
  color: #4a5569;

  /* Positioning */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .Overlay_overlayContent__2oN33 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.Overlay_message__1-xIB {
  max-width: 220px;
  text-align: center;
}

.Overlay_errorMessage__2JzEz {
  color: #ff0000;
  color: #ff0000;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.Map_root__1V4ah {
  width: 100%;
  height: 100%;
  background-color: #a1aec1;
  background-color: #a1aec1;
}

.Map_mapRoot__1LXKA {
  width: 100%;
  height: 100%;
}

.MapPanel_mapContainer__1lWWY {
  height: calc(100vh - 237px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ddd;
  color: #fff;
  font-size: 2rem;
}
.MapPanel_mapListingsContainer__1JIDd {
  height: 237px;
  width: auto;
  overflow-x: scroll;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 1rem;
}
.MapPanel_toFiltersButton__3GicE {
  position: fixed;
  top: calc(70vh - 80px);
  left: 50%;
  width: 200px;
  margin-left: -100px;
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  padding: 0.5rem;
  color: #fff;
  background-color: #9b9b9b;
  cursor: pointer
}
.MapPanel_toFiltersButton__3GicE:hover {
    background-color: #888;
  }
.MapPanel_close__BmPmq {
  position: absolute;
  top: 0;
  left: 0;
  height: 3em;
  padding: 1em;
  color: #999;
  text-decoration: none
}
.MapPanel_close__BmPmq:hover {
    color: #000;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.NoSearchResults_root__1BcFW {
}

.NoSearchResults_heading__3CR0J {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.125rem;
  font-size: 1.125rem;
}

@media (min-width: 768px) {

.NoSearchResults_heading__3CR0J {
  font-size: 1.5rem;
  font-size: 1.5rem;
}
    }

.NoSearchResults_heading__3CR0J {

  margin-bottom: 3rem;

  margin-bottom: 3rem;
}

.NoSearchResults_suggestionsHeading__2LWhT {
  margin-top: 3rem;
  margin-top: 3rem
}

@media (min-width: 1024px) {

.NoSearchResults_suggestionsHeading__2LWhT {
    margin-top: 4rem;
    margin-top: 4rem
}
  }

.NoSearchResults_suggestions__1R6C7 {
  margin-top: 1.5rem;
  margin-top: 1.5rem;
}

.Page_root__lwqX9 {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Page_content__33tYG {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.Page_bodyNoScroll__25QxW {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PartyMembers_circle__1SFPh {
  background: black;
  border-radius: 50%;
  width: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  height: 0.5rem;
  margin: 0.5rem 0.5rem 0.5rem auto;
  margin: 0.5rem 0.5rem 0.5rem auto;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PricingToolTip_tooltipTitle__2mLpR,
.PricingToolTip_tooltipHeader__2hEtu {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  display: flex;
  align-items: center;
  color: #1a202c;
  color: #1a202c;
  font-weight: bold;
}

.PricingToolTip_tooltipTitle__2mLpR {
  font-size: 1.875rem;
  font-size: 1.875rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.PricingToolTip_tooltipHeader__2hEtu {
  font-size: 1rem;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.PricingToolTip_tooltipContent__oNj2R {
  font-size: 0.875rem;
  font-size: 0.875rem;
  min-width: 13.5rem;
  min-width: 13.5rem;
  max-width: 16rem;
  max-width: 16rem;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem
}

@media (min-width: 768px) {

.PricingToolTip_tooltipContent__oNj2R {
    min-width: 16rem;
    min-width: 16rem;
    max-width: 19rem;
    max-width: 19rem
}
  }

.PricingToolTip_tooltipLink__3tKR2 {
  position: relative;
  bottom: 2px;
  left: 0.5rem;
  left: 0.5rem;
}

.PricingToolTip_toolTipIcon__2JaNu:hover {
  cursor: pointer;
  text-decoration: underline;
}

.PricingToolTip_proTipTextArea__2LG9- {
  display: block;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.LineItems_root__2PyDt {
  display: flex;
  flex-direction: column;
}

.LineItems_nowrap__1bmlv {
  white-space: nowrap;
}

.LineItems_lineItem__E2Agu,
.LineItems_lineItemRefund__1RQgD {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1rem;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.LineItems_lineItemRefund__1RQgD {
  font-weight: 700;
  font-weight: 700;
}

.LineItems_subTotalLineItem__1-djm {
  font-weight: 700;
  font-weight: 700;
  font-size: 1rem;
  font-size: 1rem;

  margin: 0;
}

@media (min-width: 768px) {

.LineItems_subTotalLineItem__1-djm {
    margin: 0;
}
  }

.LineItems_subTotalLineItem__1-djm {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.LineItems_itemLabel__sJRUj {
  flex: 1 1;
  cursor: pointer;
  text-decoration: underline;
}

.LineItems_itemValue__21Mri {
  margin: 0 0 0 0.5rem;
  margin: 0 0 0 0.5rem
}

@media (min-width: 768px) {

.LineItems_itemValue__21Mri {
    margin: 0 0 0 0.5rem;
    margin: 0 0 0 0.5rem
}
  }

.LineItems_totalDivider__132YF {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 0.75rem 0 0.25rem 0;
  margin: 0.75rem 0 0.25rem 0;

  border: none;
  background-color: #e2e8f0;
  background-color: #e2e8f0
}

@media (min-width: 768px) {

.LineItems_totalDivider__132YF {
    margin: 2px 0 0.75rem 0;
    margin: 2px 0 0.75rem 0
}
  }

.LineItems_totalLabel__ttqCp {
  font-weight: 700;
  font-weight: 700;

  /* Move so that baseline aligns with the total price */
  padding-top: 0.25rem;
  padding-top: 0.25rem;
}

.LineItems_totalPrice__3eLSN {
  font-weight: 700;
  font-weight: 700;
  margin: 0 0 0 0.5rem;
  margin: 0 0 0 0.5rem;
  padding-top: 0.25rem;
  padding-top: 0.25rem
}

@media (min-width: 768px) {

.LineItems_totalPrice__3eLSN {
    margin: 2px 0 0 0.5rem;
    margin: 2px 0 0 0.5rem;
    padding-top: 0
}
  }

.LineItems_feeInfo__vaTMv {
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
  flex-shrink: 0;
  color: var(--gray900)
}

@media (min-width: 768px) {

.LineItems_feeInfo__vaTMv {
    margin-top: auto
}
  }

@media (min-width: 1024px) {

.LineItems_feeInfo__vaTMv {
    margin-top: 0.25rem;
    margin-top: 0.25rem;
    margin-bottom: 0.75rem;
    margin-bottom: 0.75rem
}
  }

.LineItems_title__HA3E2 {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700;
}

.LineItems_subtitle__j006g {
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.LineItems_disclaimer__3LFvm {
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PricingDetails_root__2Imy_ {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  color: #1a202c;
  color: #1a202c
}

@media (min-width: 768px) {

.PricingDetails_root__2Imy_ {
    margin: 1rem 0;
    margin: 1rem 0
}
  }

.PricingDetails_header__3Cv_I {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  color: #1a202c;
  color: #1a202c;
}

.PricingDetails_disclaimer__vyIOI {
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.PricingDetails_totalDivider__19Qi3 {
  width: 100%;
  height: 1px;
  margin: 0.75rem 0 0.75rem 0;
  margin: 0.75rem 0 0.75rem 0;

  border: none;
  background-color: #e2e8f0;
  background-color: #e2e8f0
}

@media (min-width: 768px) {

.PricingDetails_totalDivider__19Qi3 {
    margin: 0.5rem 0 0.25rem 0;
    margin: 0.5rem 0 0.25rem 0
}
  }

.PricingDetails_valueUnderline__3U06c {
  text-decoration: underline;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.Reviews_root__TM34j {
  margin: 0;
}

.Reviews_review__33fM2 {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-top: 2rem;
  margin-top: 2rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.Reviews_reviewHeadingsWrapper__D0Gpq {
  display: flex;
  flex-flow: column;
}

.Reviews_reviewHeadings__33GFf {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.Reviews_reviewAuthor__19h9k {
  margin-right: 0.25rem;
  margin-right: 0.25rem;
  color: #1a202c;
  color: #1a202c;
  font-weight: 600;
  font-weight: 600;
}

.Reviews_reviewDate__1_NOD {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #718096;
  color: #718096;
  line-height: normal;

  padding: 0;
}

.Reviews_avatar__mmdNZ {
  flex-shrink: 0;
  margin-right: 1rem;
  margin-right: 1rem;
}

.Reviews_reviewContentWrapper__NNCHY {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.Reviews_reviewContent__3bYvY {
  margin: 0;
  white-space: pre-line;
  color: #1a202c;
  color: #1a202c;
}

.Reviews_reviewsInfo__2tWQF {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.Reviews_reviewContent__3bYvY {
  margin: 0;
  white-space: pre-line;
  color: #1a202c;
  color: #1a202c;
}

.Reviews_reviewDateWrapper__zXytT {
  color: #718096;
  color: #718096;
  font-size: 0.75rem;
  font-size: 0.75rem;
}

.Reviews_mobileReviewRating__2S55W {
  display: block
}

@media (min-width: 768px) {

.Reviews_mobileReviewRating__2S55W {
    display: none
}
  }

.Reviews_desktopReviewRatingWrapper__1if10 {
  display: none
}

@media (min-width: 768px) {

.Reviews_desktopReviewRatingWrapper__1if10 {
    display: inline
}
  }

.Reviews_desktopReviewRating__3639Q {
  display: inline-flex;
  white-space: nowrap;
}

.Reviews_reviewRatingStar__1rkJh {
  height: 0.75rem;
  height: 0.75rem;
  width: 0.75rem;
  width: 0.75rem;
  margin-right: 0.25rem;
  margin-right: 0.25rem
}

.Reviews_reviewRatingStar__1rkJh:last-of-type {
    margin-right: 0;
  }

.Reviews_sectionLandowner__26BDE {
  margin-left: 2rem;
  margin-left: 2rem;
  padding-left: 1.25rem;
  padding-left: 1.25rem;
  border-left: 2px solid #e2e8f0;
  border-left: 2px solid #e2e8f0;
}

.Reviews_reviewHarvest__1p7ME {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  font-size: 0.625rem;
  font-size: 0.625rem;
  font-weight: 700;
  font-weight: 700;
  border-radius: 6px;
  background-color: #edf2f7;
  background-color: #edf2f7;
  padding: 2px 4px 2px 2px;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;

  > svg {
    height: 18px;
    width: 18px;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.ListingPage_pageRoot__1y5K8 {
  display: flex;
  padding-bottom: 90px
}

@media (min-width: 1024px) {

.ListingPage_pageRoot__1y5K8 {
    padding-bottom: 0
}
  }

.ListingPage_loadingText__2pYnD {
  margin: 24px;
}

.ListingPage_errorText__2oEXv {
  color: #ff0000;
  color: #ff0000;
  margin: 24px;
}

.ListingPage_sectionImagesContainer__9FNsY {
  position: relative;
  padding-bottom: 0;
}

.ListingPage_sectionHeadingContainer__3KljA {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 768px) {

.ListingPage_sectionHeadingContainer__3KljA {
    margin-bottom: 1rem;
    margin-bottom: 1rem
}
  }

.ListingPage_desktopLayout__1_3-3 {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {

.ListingPage_desktopLayout__1_3-3 .ListingPage_sectionHeadingContainer__3KljA {
    order: 2
}
  }

@media (min-width: 768px) {

.ListingPage_desktopLayout__1_3-3 .ListingPage_sectionKeyListingInfo__B9vS2 {
    order: 1
}
  }

.ListingPage_sectionImages__q7YQ5 {
  position: relative
}

@media (min-width: 1024px) {

.ListingPage_sectionImages__q7YQ5 {
    margin-top: 1.5rem;
    margin-top: 1.5rem
}
  }

.ListingPage_gridPhotos__16D2r {
  display: none;
  position: relative;
  height: calc(0.41 * 100vw);
  max-height: min(50vh, 41.5rem);
  max-height: min(50vh, 41.5rem)
}

@media (min-width: 768px) {

.ListingPage_gridPhotos__16D2r {
    display: block
}
  }

.ListingPage_collage__12Jg_ {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  background-color: #fff;
  background-color: #fff;
}

.ListingPage_collage1Photo__1E0Kb {
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.ListingPage_collage2Photos__2GieX {
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.ListingPage_collage3Photos__dD6T4 {
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.ListingPage_collage4Photos__2e2Gi {
  grid-template-columns: minmax(0, 0.25fr) minmax(0, 0.25fr) minmax(0, 0.5fr);
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.ListingPage_collage4PhotosItem__174HE:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

.ListingPage_collage4PhotosItem__174HE:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }

.ListingPage_collage4PhotosItem__174HE:nth-child(3) {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }

.ListingPage_collage4PhotosItem__174HE:nth-child(4) {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

.ListingPage_collage5PlusPhotos__5rVzK {
  grid-template-columns: minmax(0, 0.5fr) minmax(0, 0.25fr) minmax(0, 0.25fr);
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.ListingPage_collage5PlusPhotosItem__14kpj:nth-child(1) {
    grid-column: 1;
    grid-row: 1 / 3;
  }

.ListingPage_collage5PlusPhotosItem__14kpj:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  }

.ListingPage_collage5PlusPhotosItem__14kpj:nth-child(3) {
    grid-column: 2;
    grid-row: 2;
  }

.ListingPage_collage5PlusPhotosItem__14kpj:nth-child(4) {
    position: relative;
    grid-column: 3;
    grid-row: 1;
  }

.ListingPage_collage5PlusPhotosItem__14kpj:nth-child(5) {
    position: relative;
    grid-column: 3;
    grid-row: 2;
  }

.ListingPage_collageImage__2rDBO {
  width: 100%;
  height: 100%;
  background-color: #a1aec1;
  background-color: #a1aec1; /* Loading BG color */
  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
  object-fit: cover;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
  border-radius: 5px;
  border-radius: 5px
}

.ListingPage_collageImage__2rDBO:hover {
    transform: scale(1.005);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  }

@media (min-width: 768px) {

.ListingPage_carousel__3__Us {
    display: none
}
  }

.ListingPage_step__3Sj5R {
  position: relative;
  flex-basis: 100%;
  margin-right: 1rem;
  margin-right: 1rem
}

@media (min-width: 768px) {

.ListingPage_step__3Sj5R {
    flex-basis: 100%
}
  }

.ListingPage_step__3Sj5R {

  /* Do the aspect ratio trick in a psuedo-element because it doesn't work in a flex child */
}

.ListingPage_step__3Sj5R::after {
    content: '';
    display: block;
    padding-bottom: 66.6667%;
    height: 0;
  }

.ListingPage_carouselResponsiveImage__3c3J4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #a1aec1;
  background-color: #a1aec1; /* Loading BG color */
  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.ListingPage_actionBar__3pTbs {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: #a1aec1;
  color: #a1aec1;
  background-color: #4a5569;
  background-color: #4a5569;
  z-index: 1; /* bring on top of mobile image */
  border-radius: 2px;
  border-radius: 2px;
}

.ListingPage_ownListingText__WvmPQ {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.ListingPage_ownListingText__WvmPQ {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.ListingPage_ownListingText__WvmPQ {
  margin: 14px 12px 11px 24px
}

@media (min-width: 768px) {

.ListingPage_ownListingText__WvmPQ {
    margin: 25px 12px 22px 24px
}
  }

.ListingPage_ownListingTextPendingApproval___jYKC {
  color: #ffaa00;
  color: #ffaa00;
}

.ListingPage_closedListingText__3bp5P {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.ListingPage_closedListingText__3bp5P {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.ListingPage_closedListingText__3bp5P {
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%
}

@media (min-width: 768px) {

.ListingPage_closedListingText__3bp5P {
    margin: 25px 12px 22px 24px
}
  }

.ListingPage_editListingLink__llj0b {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.ListingPage_editListingLink__llj0b {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.ListingPage_editListingLink__llj0b {
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: #a1aec1;
  color: #a1aec1;

  transition: ease-in-out 0.1s;

  transition: ease-in-out 0.1s
}

.ListingPage_editListingLink__llj0b:hover {
    color: #ffffff;
    color: #ffffff;
    text-decoration: none;
  }

@media (min-width: 768px) {

.ListingPage_editListingLink__llj0b {
    margin: 0;
    padding: 25px 24px 22px 12px
}
  }

.ListingPage_editIcon__3m0jL {
  margin: -6px 7px 0 0;
}

.ListingPage_share__3WWCH {
  display: none
}

@media (min-width: 768px) {

.ListingPage_share__3WWCH {
    display: flex
}
  }

@media (min-width: 768px) {

.ListingPage_shareButton__3ZHmS {
    text-decoration: underline
}
  }

.ListingPage_viewImagesWrapper__rn_Po {
  display: none
}

@media (min-width: 1024px) {

.ListingPage_viewImagesWrapper__rn_Po {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center
}
  }

.ListingPage_viewPhotosBlurWrapper__1ectj {
  display: none
}

@media (min-width: 1024px) {

.ListingPage_viewPhotosBlurWrapper__1ectj {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center
}
  }

.ListingPage_viewPhotosBlur__3GEV4 {
  display: none
}

@media (min-width: 1024px) {

.ListingPage_viewPhotosBlur__3GEV4 {
    background: url(/static/media/rectangle.f90a1a2f.png);
    background-color: rgba(21, 21, 21, 0.6);
    filter: blur(43px);
    width: 12rem;
    width: 12rem;
    height: 4rem;
    height: 4rem;
    display: block
}
  }

.ListingPage_viewPhotos__2LD8N {
  display: none
}

@media (min-width: 1024px) {

.ListingPage_viewPhotos__2LD8N {
  font-size: 1.5rem;
  font-size: 1.5rem
}
  }

@media (min-width: 1024px) and (min-width: 768px) {

.ListingPage_viewPhotos__2LD8N {
  font-size: 1.875rem;
  font-size: 1.875rem
}
    }

@media (min-width: 1024px) {

.ListingPage_viewPhotos__2LD8N {
    width: 100%;
    transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s;
    color: #fff;
    color: #fff;
    font-weight: 700;
    font-weight: 700;
    display: block;
    z-index: 10;
    z-index: 10
}
  }

.ListingPage_viewPhotosText__2yy3L {
  line-height: var(--line-height-medium);
  font-size: 2.25rem;
  font-size: 2.25rem;
}

.ListingPage_carouselModalScrollLayer__SLJWA {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.ListingPage_carouselModalContainer__CbqGa {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  padding: 1.5rem;
}

.ListingPage_contentContainer__BNmMV {
  display: flex;
  position: relative
}

@media (min-width: 1024px) {

.ListingPage_contentContainer__BNmMV {
    margin: 0 auto 3.75rem;
    margin: 0 auto 3.75rem;
    justify-content: space-between
}
  }

.ListingPage_mainContent__myyZY {
  flex-basis: 100%;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%
}

@media (min-width: 768px) {

.ListingPage_mainContent__myyZY {
    width: auto;
    margin-bottom: 3.25rem;
    margin-bottom: 3.25rem
}
  }

@media (min-width: 1024px) {

.ListingPage_mainContent__myyZY {
    flex-basis: 60%;
    margin-bottom: 0px;
    padding-right: 1.5rem;
    padding-right: 1.5rem
}
  }

@media (min-width: 1024px) {

.ListingPage_secondaryContent__19wc4 {
    flex-basis: 40%
}
  }

@media (min-width: 1024px) {

.ListingPage_bookingPanel__2NKOO {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 6.25rem;
    bottom: 6.25rem;
    padding: 1.75rem 1.25rem 3rem 1.75rem;
    padding: 1.75rem 1.25rem 3rem 1.75rem;
    border-radius: 10px;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e2e8f0;
    border: solid 1px #e2e8f0;
    background-color: #fff;
    background-color: #fff;
    padding-left: 2rem;
    padding-left: 2rem;
    min-width: 20rem;
    min-width: 20rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-basis: 40%;
    flex-basis: 40%;
    flex-shrink: 1;
    flex-grow: 1
}
  }

.ListingPage_panelHost__2mgix {
  display: none
}

@media (min-width: 1024px) {

.ListingPage_panelHost__2mgix {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 1.5rem;
    bottom: 1.5rem;
    padding: 3rem 1.75rem 3rem 2rem;
    padding: 3rem 1.75rem 3rem 2rem;
    border-radius: 10px;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    border: none;
    background-color: #fff;
    background-color: #fff;
    min-width: 20rem;
    min-width: 20rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-basis: 40%;
    flex-basis: 40%;
    flex-shrink: 1;
    flex-grow: 1
}
  }

@media (min-width: 1280px) {

.ListingPage_panelHost__2mgix {
    bottom: 4.75rem;
    bottom: 4.75rem
}
  }

.ListingPage_desktopPriceContainer__2dnt4 {
  display: none
}

@media (min-width: 1024px) {

.ListingPage_desktopPriceContainer__2dnt4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    padding: 0
}
  }

.ListingPage_desktopPriceValue__1JC88 {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px
}

@media (min-width: 768px) {

.ListingPage_desktopPriceValue__1JC88 {
  font-size: 1.875rem;
  font-size: 1.875rem;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px
}
    }

.ListingPage_desktopPriceValue__1JC88 {
  color: #3e704b;
  color: #3e704b
}

@media (min-width: 768px) {

.ListingPage_desktopPriceValue__1JC88 {
    margin-top: 0;
    margin-bottom: 6px
}
  }

.ListingPage_desktopPerUnit__3zIS- {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.ListingPage_desktopPerUnit__3zIS- {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.ListingPage_desktopPerUnit__3zIS- {
  color: #4a5569;
  color: #4a5569
}

@media (min-width: 768px) {

.ListingPage_desktopPerUnit__3zIS- {
    margin-top: 0;
    margin-bottom: 0
}
  }

@media (min-width: 768px) {

.ListingPage_headingContainer__30yFh {
    margin-bottom: 0.25rem;
    margin-bottom: 0.25rem
}
  }

.ListingPage_heading__2mrdp {
  margin-bottom: 33px;
  padding: 0 24px
}

@media (min-width: 768px) {

.ListingPage_heading__2mrdp {
    margin-bottom: 0;
    padding: 0
}
  }

.ListingPage_title__fgwUk {
  font-size: 1.5rem;
  font-size: 1.5rem;
}

@media (min-width: 768px) {

.ListingPage_title__fgwUk {
  font-size: 1.875rem;
  font-size: 1.875rem;
}
    }

.ListingPage_title__fgwUk {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 700;
  font-weight: 700;
  color: #1a202c;
  color: #1a202c;
}

.ListingPage_descriptionHeader__1P7bz {
  font-size: 1.5rem;
  font-size: 1.5rem
}

@media (min-width: 768px) {

.ListingPage_descriptionHeader__1P7bz {
  font-size: 1.875rem;
  font-size: 1.875rem
}
    }

.ListingPage_descriptionHeader__1P7bz {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: bold;
  color: #1a202c;
  color: #1a202c;
  display: flex
}

@media (min-width: 1024px) {

.ListingPage_descriptionHeader__1P7bz {
    margin: 0 0 0.25rem;
    margin: 0 0 0.25rem
}
  }

.ListingPage_subTitle__A7cor {
  font-size: 1.125rem;
  font-size: 1.125rem;
}

@media (min-width: 768px) {

.ListingPage_subTitle__A7cor {
  font-size: 1.5rem;
  font-size: 1.5rem;
}
    }

.ListingPage_subTitle__A7cor {
  font-size: 1rem;
  font-size: 1rem;
  color: #1a202c;
  color: #1a202c;
  font-weight: normal;
}

.ListingPage_sectionHeadingDesktop__3wx8J {
  display: none
}

@media (min-width: 1024px) {

.ListingPage_sectionHeadingDesktop__3wx8J {
    display: block
}
  }

.ListingPage_author__7yPQy {
  width: 100%;
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.ListingPage_author__7yPQy {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.ListingPage_author__7yPQy {
  margin-top: 7px;
  margin-bottom: 0
}

@media (min-width: 768px) {

.ListingPage_author__7yPQy {
    margin-top: 0;
    margin-bottom: 0;
    color: #4a5569;
    color: #4a5569
}
  }

.ListingPage_authorNameLink__3R6QG {
  color: #4a5569;
  color: #4a5569;
}

.ListingPage_contactWrapper__1Gw0G {
  display: inline
}

@media (min-width: 768px) {

.ListingPage_contactWrapper__1Gw0G {
    display: inline
}
  }

.ListingPage_separator__1hgQt {
  margin: 0 6px;
}

.ListingPage_contactLink__9VLSO {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: #1a202c;
  color: #1a202c;

    /* Text size should be inherited */
  text-decoration: none;
}

.ListingPage_contactLink__9VLSO:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.ListingPage_contactLink__9VLSO {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.ListingPage_contactLink__9VLSO {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.ListingPage_contactLink__9VLSO {
  margin: 0;
  font-weight: bold;
}

.ListingPage_closeButton__1KCqY {
  position: relative;
  top: 0.25rem;
  top: 0.25rem;
  right: 1.5rem;
  right: 1.5rem;
  width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  line-height: 1;
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
  z-index: 1
}

@media (min-width: 768px) {

.ListingPage_closeButton__1KCqY {
    margin-bottom: 0.75rem;
    margin-bottom: 0.75rem;
    margin-top: 0.5rem;
    margin-top: 0.5rem;
    top: 1rem;
    top: 1rem;
    width: 2rem;
    width: 2rem;
    height: 2rem;
    height: 2rem
}
  }

.ListingPage_modalContent__1V7ym {
  height: 100vh;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  overflow-y: auto
}

@media (min-width: 768px) {

.ListingPage_modalContent__1V7ym {
    max-height: 60vh;
    margin-bottom: 0
}
  }

.ListingPage_modalContent__1V7ym a {
  text-decoration: underline;
}

.ListingPage_containerStyles__18dXb {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  max-width: 50rem;
  padding: 3rem 1.5rem 0 1.5rem;
  padding: 3rem 1.5rem 0 1.5rem;
  border: 2px solid transparent;
  background-color: #fff;
  background-color: #fff;
  flex-grow: 0;
  min-width: 100vw;
  height: 100%;
  overflow: hidden
}

@media (min-width: 1024px) {

.ListingPage_containerStyles__18dXb {
    height: auto;
    border-radius: 12px;
    padding: 1.5rem;
    padding: 1.5rem;
    min-width: 40%;
    min-width: 40%
}
  }

.ListingPage_contentWrapper__kzj_z {
  padding-top: 2.25rem;
  padding-top: 2.25rem;
}

.ListingPage_contentHeader__1pvD_ {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
}

.ListingPage_sectionDescription__hZLa1 {
  padding: 0.75rem 1rem 1rem 1.5rem;
  padding: 0.75rem 1rem 1rem 1.5rem;
  margin-bottom: 0
}

@media (min-width: 768px) {

.ListingPage_sectionDescription__hZLa1 {
    margin-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 0
}
  }

.ListingPage_descriptionContent__2KO8x {
  max-height: 174px;
  overflow: hidden;
  position: relative
}

.ListingPage_descriptionContent__2KO8x a {
    color: #000;
    color: #000;
    text-decoration: underline;
  }

.ListingPage_descriptionContent__2KO8x ul {
    margin: 1rem 0;
    margin: 1rem 0;
    padding-left: 2.5rem;
    padding-left: 2.5rem;
    list-style-type: disc;
  }

.ListingPage_descriptionContent__2KO8x {
  > div > p {
    margin-top: 0;
  }
}

.ListingPage_showMoreButton__3ETTh {
  cursor: pointer;
  font-size: 1rem;
  font-size: 1rem;
  margin: 0;
  text-decoration: underline;
  color: #1a202c;
  color: #1a202c;
  position: absolute;
  bottom: 0;
}

.ListingPage_showMoreContainer__3ddqF {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 7.5rem 0 0;
  padding: 7.5rem 0 0;
  background: linear-gradient(to bottom, transparent, white 80%);
  text-align: left;
}

.ListingPage_descriptionTitle__2L4eu {
  color: #1a202c;
  color: #1a202c;
  font-size: 1.125rem;
  font-size: 1.125rem
}

@media (min-width: 768px) {

.ListingPage_descriptionTitle__2L4eu {
  font-size: 1.5rem;
  font-size: 1.5rem
}
    }

.ListingPage_descriptionTitle__2L4eu {
  color: #1a202c;
  color: #1a202c;
  margin-top: 0;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.ListingPage_descriptionTitle__2L4eu {
    margin-top: 0;
    margin-bottom: 1.25rem;
    margin-bottom: 1.25rem
}
  }

.ListingPage_descriptionShowMoreLink__2JBYG {
  display: block;
  font-weight: 600;
  font-weight: 600;
  margin: 0.5rem 0 0.5rem;
  margin: 0.5rem 0 0.5rem;

  /* The only way of overriding the link styles with !important */
  color: #ed3615 !important;
  color: #ed3615 !important;
  text-decoration: none !important;
}

.ListingPage_sectionFeatures__2zEWe {
  padding: 0 24px;
  margin-bottom: 32px
}

@media (min-width: 768px) {

.ListingPage_sectionFeatures__2zEWe {
    padding: 0;
    margin-bottom: 52px
}
  }

.ListingPage_featuresTitle__PODzb {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.25rem;
  font-size: 1.25rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px
}

@media (min-width: 768px) {

.ListingPage_featuresTitle__PODzb {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px
}
    }

.ListingPage_featuresTitle__PODzb {
  color: #1a202c;
  color: #1a202c;
  margin-top: 0;
  margin-bottom: 16px
}

@media (min-width: 768px) {

.ListingPage_featuresTitle__PODzb {
    margin-top: 0;
    margin-bottom: 20px
}
  }

.ListingPage_genericListSection__1lnXN {
  padding: 0 24px;
  margin-bottom: 32px
}

@media (min-width: 768px) {

.ListingPage_genericListSection__1lnXN {
    padding: 0;
    margin-bottom: 52px
}
  }

.ListingPage_genericListTitle__SB4kg {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.25rem;
  font-size: 1.25rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px
}

@media (min-width: 768px) {

.ListingPage_genericListTitle__SB4kg {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px
}
    }

.ListingPage_genericListTitle__SB4kg {
  color: #1a202c;
  color: #1a202c;
  margin-top: 0;
  margin-bottom: 16px
}

@media (min-width: 768px) {

.ListingPage_genericListTitle__SB4kg {
    margin-top: 0;
    margin-bottom: 20px
}
  }

.ListingPage_readMore__3pma2 {
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  color: #1a202c;
  color: #1a202c;
  font-weight: 600;
  font-weight: 600;
  margin: 0 0 1.25rem;
  margin: 0 0 1.25rem
}

@media (min-width: 1024px) {

.ListingPage_readMore__3pma2 {
    display: none
}
  }

.ListingPage_descriptors__VBL2- {
  font-size: 0.75rem;
  font-size: 0.75rem;
}

.ListingPage_descriptorTitle__3cDnn {
  font-weight: 600;
  font-weight: 600;
  display: inline;
}

.ListingPage_sectionMap__LD4ju {
  padding: 0 1.5rem;
  padding: 0 1.5rem;
  margin-bottom: 2.25rem;
  margin-bottom: 2.25rem
}

@media (min-width: 768px) {

.ListingPage_sectionMap__LD4ju {
    padding: 0;
    margin-bottom: 0;
    margin-top: 3rem;
    margin-top: 3rem
}
  }

.ListingPage_locationTitle__1pjvL {
  margin-bottom: 20px
}

@media (min-width: 768px) {

.ListingPage_locationTitle__1pjvL {
    margin-bottom: 28px
}
  }

.ListingPage_sectionReviews__2yxQD {
  padding: 0;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem
}

@media (min-width: 768px) {

.ListingPage_sectionReviews__2yxQD {
    margin-bottom: 2rem;
    margin-bottom: 2rem;
    padding: 0
}
  }

.ListingPage_reviewsHeading__2aRU9 {
  margin: 52px 0 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f5f2ed;
  background-color: #f5f2ed;
  padding: 30px 0
}

@media (min-width: 768px) {

.ListingPage_reviewsHeading__2aRU9 {
    border-radius: 8px;
    margin: 53px 0 27px 0
}
  }

.ListingPage_reviewRating__3JyKm {
  font-size: 1.875rem;
  font-size: 1.875rem;
  font-weight: bold;
}

.ListingPage_reviewsOverall__1F2Cu {
  font-size: 0.75rem;
  font-size: 0.75rem;
  font-weight: normal;
  color: #1a202c;
  color: #1a202c;
}

.ListingPage_starIcon__n9g_J {
  margin: 0 0.5rem 0 0.5rem;
  margin: 0 0.5rem 0 0.5rem;
  height: 1rem;
  height: 1rem;
  width: 1rem;
  width: 1rem;
}

.ListingPage_reviewsList__2TPrQ {
  padding: 0 1.25rem;
  padding: 0 1.25rem
}

@media (min-width: 768px) {

.ListingPage_reviewsList__2TPrQ {
    padding: 0
}
  }

.ListingPage_sectionHost__20N_x {
  padding: 0 1.5rem;
  padding: 0 1.5rem;
  margin-bottom: 2rem;
  margin-bottom: 2rem
}

@media (min-width: 1024px) {

.ListingPage_sectionHost__20N_x {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0
}
  }

.ListingPage_yourHostHeading__2ASzn {
  margin: 54px 0 23px 0
}

@media (min-width: 768px) {

.ListingPage_yourHostHeading__2ASzn {
    margin: 53px 0 27px 0
}
  }

@media (min-width: 1024px) {

.ListingPage_yourHostHeading__2ASzn {
    margin: 53px 0 27px 0
}
  }

.ListingPage_enquiryModalContent__1eaM1 {
  flex-grow: 1;
  display: flex;
}

.ListingPage_enquiryForm__1vMFq {
  flex-grow: 1;
  margin: 70px 0 100px 0
}

@media (min-width: 768px) {

.ListingPage_enquiryForm__1vMFq {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0
}
  }

.ListingPage_enquirySubmitButtonWrapper__3KA5G {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: #ffffff;
  background-color: #ffffff
}

@media (min-width: 768px) {

.ListingPage_enquirySubmitButtonWrapper__3KA5G {
    position: static;
    padding: 0;
    margin-top: auto
}
  }

.ListingPage_mapWrapper__m_GfJ {
  padding-top: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-bottom: 1.25rem;
}

.ListingPage_map__VXNyH {
  height: 14.5rem;
  height: 14.5rem;
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer
}

@media (min-width: 768px) {

.ListingPage_map__VXNyH {
    height: 75vh
}
  }

@media (min-width: 1024px) {

.ListingPage_map__VXNyH {
    height: 417px
}
  }

.ListingPage_longWord__2gEjE {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.ListingPage_activityFeedContainer__WaSjf {
  margin-top: 0.75rem;
  margin-top: 0.75rem
}

@media (min-width: 768px) {

.ListingPage_activityFeedContainer__WaSjf {
    margin-top: 1.5rem;
    margin-top: 1.5rem
}
  }

.ListingPage_sectionHeader__Vj-Hi {
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: bold;
  color: #1a202c;
  color: #1a202c;
}

.ListingPage_sectionSubHeading__3OlTP {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: bold;
  color: #1a202c;
  color: #1a202c;
  display: flex
}

@media (min-width: 1024px) {

.ListingPage_sectionSubHeading__3OlTP {
    margin: 0 0 0.25rem;
    margin: 0 0 0.25rem
}
  }

.ListingPage_logingCardsWrapper__3PkpH {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-top: 1rem
}

@media (min-width: 768px) {

.ListingPage_logingCardsWrapper__3PkpH {
    margin-top: 1.25rem;
    margin-top: 1.25rem
}
  }

.ListingPage_landownerHeader__2pIgz {
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.ListingPage_amenitiesHeader__3JkTZ {
  font-weight: 700;
  font-weight: 700;
  margin-top: 2.25rem;
  margin-top: 2.25rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.ListingPage_amenitiesWrapper__1xZaS {
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.ListingPage_amenitiesWrapper__1xZaS {
    flex-wrap: wrap;
    flex-direction: row
}
  }

.ListingPage_amenitiesModalWrapper__2xba- {
  display: flex;
  flex-direction: column;
  width: 50%;
  width: 50%
}

@media (min-width: 768px) {

.ListingPage_amenitiesModalWrapper__2xba- {
    flex-wrap: wrap;
    flex-direction: row
}
  }

.ListingPage_amenitiesItem__2FpPA {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 768px) {

.ListingPage_amenitiesItem__2FpPA {
    width: 33.333333%;
    width: 33.333333%
}
  }

.ListingPage_amenitiesModalHeader__2JdW6 {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  font-weight: 700;
  font-weight: 700;
}

.ListingPage_amenitiesModalSectionHeader__10rvr {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-weight: 700;
}

.ListingPage_amenitiesModalItem__3ZkqB {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 768px) {

.ListingPage_amenitiesModalItem__3ZkqB {
    width: 50%;
    width: 50%
}
  }

.ListingPage_rulesList__3WFhR {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 1rem;
  margin-top: 1rem
}

@media (min-width: 768px) {

.ListingPage_rulesList__3WFhR {
    margin-top: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
    margin-bottom: 0.75rem
}
  }

@media (min-width: 1024px) {

.ListingPage_rulesList__3WFhR {
    max-height: 15rem;
    max-height: 15rem
}
  }

.ListingPage_rulesListItem__areX9 {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.ListingPage_detailsQuestionWrapper__2hLX9 {
  margin-top: 2.75rem;
  margin-top: 2.75rem
}

@media (min-width: 768px) {

.ListingPage_detailsQuestionWrapper__2hLX9 {
    margin-top: 2rem;
    margin-top: 2rem
}
  }

.ListingPage_tooltipHeader__mcU9e {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-size: 1rem;
  color: #1a202c;
  color: #1a202c;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.ListingPage_tooltipHeaderIcon__DwxLc {
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.ListingPage_tooltipContent__uHRla {
  max-width: 16rem;
  max-width: 16rem
}

@media (min-width: 768px) {

.ListingPage_tooltipContent__uHRla {
    max-width: 19rem;
    max-width: 19rem
}
  }

.ListingPage_favoriteWrapper__nERZ- {
  display: none
}

@media (min-width: 768px) {

.ListingPage_favoriteWrapper__nERZ- {
    display: block;
    position: absolute;
    top: 1rem;
    top: 1rem;
    right: 1rem;
    right: 1rem
}

    .ListingPage_favoriteWrapper__nERZ- > button {
      height: 2.75rem;
      height: 2.75rem;
      width: 2.75rem;
      width: 2.75rem
    }

      .ListingPage_favoriteWrapper__nERZ- > button > svg {
        width: 1.5rem;
        width: 1.5rem;
      }
  }

@media (min-width: 1024px) {

.ListingPage_favoriteWrapper__nERZ- {
    top: 2.5rem;
    top: 2.5rem
}
  }

.ListingPage_mobileSecondaryWrapper__2d7Mr {
  display: flex;
  align-items: baseline
}

.ListingPage_mobileSecondaryWrapper__2d7Mr > button {
    margin-right: 0.5rem;
    margin-right: 0.5rem;
    height: 2.75rem;
    height: 2.75rem;
    width: 2.75rem;
    width: 2.75rem
  }

.ListingPage_mobileSecondaryWrapper__2d7Mr > button > svg {
      width: 1.5rem;
      width: 1.5rem;
    }

.ListingPage_helpfulResources__2TcO- {
  padding: 0 1.5rem;
  padding: 0 1.5rem;
  margin-bottom: 2rem;
  margin-bottom: 2rem
}

@media (min-width: 768px) {

.ListingPage_helpfulResources__2TcO- {
    padding: 1.25rem;
    padding: 1.25rem;
    border-radius: 10px;
    border-radius: 10px;
    border: solid 1px #e2e8f0;
    border: solid 1px #e2e8f0
}
  }

.ListingPage_genericSection__Tvadq {
  padding: 0 1.5rem;
  padding: 0 1.5rem;
  margin-bottom: 2rem;
  margin-bottom: 2rem
}

@media (min-width: 768px) {

.ListingPage_genericSection__Tvadq {
    padding: 0;
    margin-bottom: 2.5rem;
    margin-bottom: 2.5rem
}
  }

.ListingPage_linkContainer__3SJ_2 {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem
}

@media (min-width: 768px) {

.ListingPage_linkContainer__3SJ_2 {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    margin-top: 0.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: flex-start
}
  }

.ListingPage_linkDecor__3mVZW {
  color: #000;
  color: #000;
  text-decoration: underline;
}

.ListingPage_locationLinkDecor__ElfTO {
  color: #000;
  color: #000;
  font-size: 0.875rem;
  font-size: 0.875rem
}

@media (min-width: 768px) {

.ListingPage_locationLinkDecor__ElfTO {
    font-size: 1rem;
    font-size: 1rem
}
  }

.ListingPage_links__1lLOk {
  margin: 0.5rem 0;
  margin: 0.5rem 0
}

@media (min-width: 768px) {

.ListingPage_links__1lLOk {
    margin: 0
}
  }

.ListingPage_favoritesSection__1zd96 {
  font-weight: bold;
  border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: dashed 1px #cbd5e0;
  border: dashed 1px #cbd5e0;
  padding: 1.5rem 0.5rem 1.75rem 0.75rem;
  padding: 1.5rem 0.5rem 1.75rem 0.75rem
}

@media (min-width: 768px) {

.ListingPage_favoritesSection__1zd96 {
    padding: 1.5rem 1rem 1.75rem 1rem;
    padding: 1.5rem 1rem 1.75rem 1rem
}
  }

.ListingPage_favoriteIconWrapper__11nE2 {
  display: flex;
  align-items: center
}

.ListingPage_favoriteIconWrapper__11nE2 > button {
    width: auto;
    margin-right: 1rem;
    margin-right: 1rem
  }

.ListingPage_favoriteIconWrapper__11nE2 > button > svg {
      width: 1.5rem;
      width: 1.5rem;
      color: #ed3615;
      color: #ed3615;
    }

.ListingPage_listingActions__2pPd1 {
  display: flex;
  padding: 1.25rem 1.5rem 0 1.5rem;
  padding: 1.25rem 1.5rem 0 1.5rem
}

@media (min-width: 768px) {

.ListingPage_listingActions__2pPd1 {
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    margin-top: 2rem
}
  }

.ListingPage_shareContainer__207Km {
  display: none
}

@media (min-width: 1024px) {

.ListingPage_shareContainer__207Km {
    display: flex
}
  }

.ListingPage_headerReviewsContainer__3md5D {
  font-size: 0.875rem;
  font-size: 0.875rem;
  display: flex;
  margin-bottom: 0;
  align-items: center
}

@media (min-width: 768px) {

.ListingPage_headerReviewsContainer__3md5D {
    font-size: 1rem;
    font-size: 1rem
}
  }

.ListingPage_headerReviewLoader__20Ap7 {
  height: 1.5rem;
  height: 1.5rem;
}

.ListingPage_keyInfoIcon__2v25w {
  display: flex;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.ListingPage_listingRating__3NMuM {
  color: #1a202c;
  color: #1a202c;
  display: flex;
  font-size: 0.875rem;
  font-size: 0.875rem;
  border-radius: 5px;
  border-radius: 5px;
  white-space: nowrap;
  align-items: center;
  margin-left: 0.25rem;
  margin-left: 0.25rem
}

@media (min-width: 768px) {

.ListingPage_listingRating__3NMuM {
    font-size: 1rem;
    font-size: 1rem
}
  }

.ListingPage_listingRating__3NMuM a {
  text-decoration: underline;
  color: #1a202c;
  color: #1a202c;
}

.ListingPage_ratingSpan__2Es8Q {
  display: flex;
  color: #1a202c;
  color: #1a202c;
}

.ListingPage_infoSeparator__3dAtt {
  margin: 0 0.5rem;
  margin: 0 0.5rem;
}

.ListingPage_buttonWrapper__1dnG2 {
  margin-left: 1.5rem;
  margin-left: 1.5rem;
  padding: 0;
  border: none;
  font-weight: normal;
  display: inline-block;
  cursor: pointer;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 0.875rem;
  font-size: 0.875rem;
  text-decoration: underline
}

@media (min-width: 768px) {

.ListingPage_buttonWrapper__1dnG2 {
    margin-left: 0
}
  }

.ListingPage_showButtons__2b8C- {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  text-decoration: underline;
  font-size: 1rem;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.ListingPage_panelHostMobile__2xyvA {
  display: contents
}

@media (min-width: 1024px) {

.ListingPage_panelHostMobile__2xyvA {
    display: none
}
  }

.ListingPage_sectionActivities__2aawv {
  margin-top: 2rem;
  margin-top: 2rem
}

@media (min-width: 768px) {

.ListingPage_sectionActivities__2aawv {
    margin-top: 1.25rem;
    margin-top: 1.25rem
}
  }

.ListingPage_totalDivider__3QlZU {
  height: 1px;
  margin: 1.5rem;
  margin: 1.5rem;
  border: none;
  background-color: #e2e8f0;
  background-color: #e2e8f0
}

@media (min-width: 1024px) {

.ListingPage_totalDivider__3QlZU {
    display: none
}
  }

.ListingPage_sectionSubHeader__vq4iL {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: bold;
}

.ListingPage_sectionDetailsIcon__2fot0 {
  margin-right: 0.25rem;
  margin-right: 0.25rem;
}

.ListingPage_sectionValues__2_K_G {
  font-weight: bold;
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  font-size: 1rem;
}

.ListingPage_modalContentContainer__1TEOu {
  height: 100%;
}

.ListingPage_amenitiesModalBtn__1PVFx {
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  border: none;
  padding: 0;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}

.ListingPage_policiesModalBtn__1by7F {
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  border: none;
  padding: 0;
  background: none;
  text-decoration: underline;
  cursor: pointer;
}

.ListingPage_amenitiesModalContainer__2nd4J {
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  padding: 1.5rem;
  background-color: #fff;
  background-color: #fff;
  border-bottom: none;
  flex-grow: 0;
  min-height: auto;
  border-radius: 0;
  overflow: scroll
}

@media (min-width: 768px) {

.ListingPage_amenitiesModalContainer__2nd4J {
    margin: auto;
    width: 50%;
    width: 50%;
    height: auto;
    border-radius: 5px;
    border-radius: 5px
}
  }

.ListingPage_amenitiesModalContent__1BDmv {
  padding: 0.75rem;
  padding: 0.75rem;
}

.ListingPage_amenitiesModalInfo__270Tc {
  margin-top: 0.25rem;
  margin-top: 0.25rem;
}

.ListingPage_amenitiesModalHeader__2JdW6 {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  font-weight: 700;
  font-weight: 700;
}

.ListingPage_amenitiesModalSectionHeader__10rvr {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-weight: 700;
}

.ListingPage_amenitiesModalItem__3ZkqB {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 768px) {

.ListingPage_amenitiesModalItem__3ZkqB {
    width: 50%;
    width: 50%
}
  }

.ListingPage_badgesWrapper__VqRzE {
  display: flex;
}

.ListingPage_badge__1b5Zy {
  background-color: #e1ddd5;
  background-color: #e1ddd5;
  padding: 0.5rem 1rem;
  padding: 0.5rem 1rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  border-radius: 5px;
  border-radius: 5px;
}

.ListingPage_propertyVisitRoot__1_OIU {
  background-color: #f5f2ed;
  background-color: #f5f2ed;
}

.ListingPage_activityFeedRoot__1WW3n {
  background-color: inherit
}

@media (min-width: 768px) {

.ListingPage_activityFeedRoot__1WW3n {
    background-color: #f7fafc;
    background-color: #f7fafc
}
  }

.ListingPage_additionalLodging__2klk5 {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-top: 1rem;
}

.ListingPage_policiesContainer__2D1Ee {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-top: 0.5rem
}

@media (min-width: 768px) {

.ListingPage_policiesContainer__2D1Ee {
    flex-wrap: wrap;
    max-height: 6.25rem;
    max-height: 6.25rem
}
  }

.ListingPage_allows__vQWTE {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.ListingPage_reviewPhotos__1HkAY {
  display: flex;
  flex-flow: row wrap;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.ListingPage_reviewPhotoWrapper__3GG7h {
  border-radius: 5px;
  border-radius: 5px;
  width: calc(33% - 8px);
  position: relative;
}

.ListingPage_reviewPhotoWrapperOne__3YO3P {
  width: 100%;
}

.ListingPage_reviewPhotoWrapperTwo__1ZP2j {
  width: calc(50% - 8px);
}

.ListingPage_reviewPhoto__26n2l {
  border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.ListingPage_reviewStars__3ZUUN {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.ListingPage_reviewRatingStar__2Yp_N {
  height: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  width: 1.5rem;
}

.ListingPage_reviewImagesWrapper__2NxnN {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListingPage_reviewPhotosBlurWrapper__2bqs3 {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListingPage_reviewPhotosBlur___un5t {
  background: url(/static/media/rectangle.f90a1a2f.png);
  background-color: rgba(21, 21, 21, 0.6);
  filter: blur(43px);
  width: 12rem;
  width: 12rem;
  height: 4rem;
  height: 4rem;
  display: block;
}

.ListingPage_reviewPhotosButton__2pjAV {
  font-size: 1.5rem;
  font-size: 1.5rem;
}

@media (min-width: 768px) {

.ListingPage_reviewPhotosButton__2pjAV {
  font-size: 1.875rem;
  font-size: 1.875rem;
}
    }

.ListingPage_reviewPhotosButton__2pjAV {
  width: 100%;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
  color: #fff;
  color: #fff;
  font-weight: 700;
  font-weight: 700;
  display: block;
  z-index: 10;
  z-index: 10;
}

.ListingPage_reviewLoadingWrapper__3JSxs {
  margin-top: 2rem;
  margin-top: 2rem;
}

.ListingPage_reviewLoadingHeader__tmAPJ {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.ListingPage_reviewLoadingContent__TbjOn {
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.ReviewsImageCarousel_root__46wrS {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ReviewsImageCarousel_contentContainer__IXQx- {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.ReviewsImageCarousel_imageWrapper__38num {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReviewsImageCarousel_image__1hikQ {
  width: 100%;
  object-fit: contain;
  max-height: 350px
}

@media (min-width: 768px) {

.ReviewsImageCarousel_image__1hikQ {
    max-height: 700px
}
  }

.ReviewsImageCarousel_controlsContainer__1rn_N {
  margin-top: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReviewsImageCarousel_imageIndex__8slc0 {
  color: #fff;
  color: #fff;
  font-size: var(--text-md);
  font-weight: 600;
  font-weight: 600;
}

.ReviewsImageCarousel_imageLoading__3pkeQ {
  opacity: 0.5;
}

.ReviewsImageCarousel_prev__1W8sZ,
.ReviewsImageCarousel_next__1ktEV {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  margin: 0 1.5rem;
  margin: 0 1.5rem;
  background-position: center;
}

.ReviewsImageCarousel_prev__1W8sZ {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="%23FFF" d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path></svg>');
}

.ReviewsImageCarousel_next__1ktEV {
  color: white;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="%23FFF" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path></svg>');
}

.ReviewsImageCarousel_captionsContainer__2gZGV {
  margin-top: 2rem;
  margin-top: 2rem;
  color: #fff;
  color: #fff;
  font-size: var(--text-md);
  text-align: center;
}

.ReviewsImageCarousel_loading__2LZh4 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  opacity: 0;
}

.ReviewsImageCarousel_loadingVisible__2QQsI {
  opacity: 1;
  transition: opacity ease-in 0.2s;
  transition: opacity ease-in 0.2s;
}

.ReviewsImageCarousel_thumbnailCarousel__2pGJ6 {
  margin: 2rem 0;
  margin: 2rem 0;
  max-width: 100%;
  display: block
}

@media (min-width: 768px) {

.ReviewsImageCarousel_thumbnailCarousel__2pGJ6 {
    display: none
}
  }

.ReviewsImageCarousel_thumbnailStep__eM5gW {
  flex-basis: 5rem !important;
  flex-basis: 5rem !important;
  padding: 0.25rem;
  padding: 0.25rem;
  cursor: pointer;
}

.ReviewsImageCarousel_thumbnail__3uoY9 {
  width: 5rem;
  width: 5rem;
  height: 5rem;
  height: 5rem;
  object-fit: cover;
  border: 2px transparent solid;
  border-radius: 5px;
  border-radius: 5px
}

.ReviewsImageCarousel_thumbnail__3uoY9.ReviewsImageCarousel_active__2Wf2S {
    border-color: #a1aec1;
    border-color: #a1aec1;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SavedCardDetails_root__EWfCk {
}

.SavedCardDetails_cardHeading__2upPT {
  margin: 0 0 0.5rem 0;
  margin: 0 0 0.5rem 0;
  color: #1a202c;
  color: #1a202c;
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
}

.SavedCardDetails_savedPaymentMethodTitle__2U_RP {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.SavedCardDetails_savedPaymentMethodTitle__2U_RP {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.SavedCardDetails_savedPaymentMethodTitle__2U_RP {
  font-weight: bold;
  cursor: pointer;
}

.SavedCardDetails_savedPaymentMethod__3JZp4 {
  display: inline-block;
  margin-right: auto;
}

.SavedCardDetails_paymentMethodPlaceholderDesktop__3Js8u {
  display: none;
}

.SavedCardDetails_paymentMethodPlaceholderMobile__1Rm1h {
  display: inline-block;
}

@media (min-width: 550px) {
  .SavedCardDetails_paymentMethodPlaceholderDesktop__3Js8u {
    display: inline-block;
  }

  .SavedCardDetails_paymentMethodPlaceholderMobile__1Rm1h {
    display: none;
  }
}

.SavedCardDetails_cardIcon__3buFb {
  margin: 0 12px 0 4px;
  width: 24px;
  height: 16px;
  display: inline-block;
}

.SavedCardDetails_closeIcon__3ougj {
  display: inline-block;
  padding: 0;
  box-sizing: content-box;
  margin-left: 0;
  margin-right: 5px;
}

.SavedCardDetails_iconCheckmark__1P3Nh {
  stroke: #2ecc71;
  stroke: #2ecc71;
  display: inline-block;
  margin: 12px 0 0 24px;
}

.SavedCardDetails_iconCheckmarkHidden__OM4C9 {
  visibility: hidden;
  display: inline-block;
  margin: 0 0 0 24px;
}

.SavedCardDetails_expirationDate__eDcWT {
  padding-left: 24px;
}

.SavedCardDetails_expirationDateExpired__3SrpQ {
  padding-left: 24px;
  color: #ff0000;
  color: #ff0000;
}

.SavedCardDetails_cardExpiredText__2dJ1Y {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.SavedCardDetails_cardExpiredText__2dJ1Y {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.SavedCardDetails_cardExpiredText__2dJ1Y {
  color: #ff0000;
  color: #ff0000;
  font-weight: 600;
  font-weight: 600;
  margin-top: 13px;
  margin-bottom: 11px;
}

.SavedCardDetails_menu__34rgH {
  max-width: 100%;
  height: auto;
}

.SavedCardDetails_menuLabel__4aC25 {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  text-align: left;
  font-weight: 500;
  padding: 0;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease-out;
  z-index: calc(50 + 1);
  z-index: calc(50 + 1);
  position: relative
}

.SavedCardDetails_menuLabel__4aC25:hover {
    background-color: #ffffff;
    background-color: #ffffff;
    border: 1px solid #a1aec1;
    border: 1px solid #a1aec1;
  }

.SavedCardDetails_menuLabel__4aC25:focus {
    outline: none;
    background-color: #ffffff;
    background-color: #ffffff;
    border: 1px solid #a1aec1;
    border: 1px solid #a1aec1;
    text-decoration: none;
    border-radius: 4px 4px 0px 0px;
  }

.SavedCardDetails_menuLabelWrapper__2jjut,
.SavedCardDetails_menuLabelWrapperExpired__1FalT {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 1rem 1.5rem 1rem 1rem;
  padding: 1rem 1.5rem 1rem 1rem;
}

.SavedCardDetails_menuLabelWrapperExpired__1FalT {
  border: 1px solid #ff0000;
  border: 1px solid #ff0000;
}

.SavedCardDetails_menuContent__3j3dE {
  position: absolute;
  z-index: 50;
  z-index: 50;

  background-color: #ffffff;

  background-color: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid #a1aec1;
  border: 1px solid #a1aec1;

  transition: all 0.15s ease-out;

  /* When the dropdown is open, we need to hack the box-shadow's position
    to fit the whole dropdown menu component with padding-top

    MenuLabel height 56px + original padding-top 7px results to 63px
  */
  padding: 63px 0 15px 0;
  top: 0px;
  width: 100%;
}

.SavedCardDetails_menuItem__3r66z {
  color: #4a5569;
  color: #4a5569
}

.SavedCardDetails_menuItem__3r66z:hover {
    background-color: #f2f2f2;
  }

.SavedCardDetails_menuText__R57iF,
.SavedCardDetails_menuTextReplaceCard__E5sVu {
  font-size: 1rem;
  font-size: 1rem;
  padding: 12px 8px;
  color: #4a5569;
  color: #4a5569;
  text-align: left;
  font-weight: 500;
  width: 80%
}

.SavedCardDetails_menuText__R57iF:hover, .SavedCardDetails_menuTextReplaceCard__E5sVu:hover {
    text-decoration: none;
  }

.SavedCardDetails_menuText__R57iF:focus, .SavedCardDetails_menuTextReplaceCard__E5sVu:focus {
    outline: 0;
    background-color: #f2f2f2;
  }

.SavedCardDetails_menuTextReplaceCard__E5sVu svg {
  position: relative;
  top: -2px;
  width: 22px;
  margin: 0 10px 0 4px;
}

.SavedCardDetails_menuDivider__2-y_W {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.SavedCardDetails_menuDivider__2-y_W {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.SavedCardDetails_menuDivider__2-y_W {
  color: #a1aec1;
  color: #a1aec1;
  padding: 14px 24px 2px 24px;
  margin: 0;
}

.SavedCardDetails_iconArrow__Pgtpl {
  stroke: #4a5569;
  stroke: #4a5569;
  fill: #4a5569;
  fill: #4a5569;
  transition: all 0.2s ease-out;
}

.SavedCardDetails_IconArrowAnimation__3Rtr6 {
  transform: rotate(180deg);
}

.SavedCardDetails_savedPaymentMethodDeleteWrapper__jsP0p {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
  margin-top: 0.5rem;
}

.SavedCardDetails_savedPaymentMethodDelete__3ORQv {
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #1a202c;
  color: #1a202c;
  cursor: pointer;
  text-decoration: underline
}

.SavedCardDetails_savedPaymentMethodDelete__3ORQv:focus {
    outline: none;
  }

.SavedCardDetails_modalButtonsWrapper__3-Jd6 {
  padding-top: 5rem;
  padding-top: 5rem;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

/* Title of the modal */

.SavedCardDetails_modalTitle__gwBCW {
  font-weight: 600;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  font-weight: 700;
  font-weight: 700;
}

@media (min-width: 768px) {

.SavedCardDetails_modalTitle__gwBCW {
  font-weight: 600;
  font-weight: 600;
  line-height: 40px;
  margin: 0;
}
    }

.SavedCardDetails_modalTitle__gwBCW {
  padding-top: 36px;
}

/* Paragraph for the Modal */

.SavedCardDetails_modalMessage__3eVwO {
  margin: 18px 0 0 0;
}

@media (min-width: 768px) {

.SavedCardDetails_modalMessage__3eVwO {
  margin: 24px 0 0 0;
}
    }

.SavedCardDetails_modalMessage__3eVwO {
  min-height: 200px;
}

.SavedCardDetails_cancelCardDelete__22oZL {
  color: #a1aec1;
  color: #a1aec1;
  cursor: pointer;
  width: 50%;
  text-align: right;
  padding-right: 64px
}

.SavedCardDetails_cancelCardDelete__22oZL:hover {
    color: #4a5569;
    color: #4a5569;
  }

.SavedCardDetails_removeCardModalHeader__WCRqb {
  display: flex;
  text-align: center;
  color: #1a202c;
  color: #1a202c;
  font-weight: 700;
  font-weight: 700;
  font-size: 1.25rem;
  font-size: 1.25rem;
}

.SavedCardDetails_removeCardModalButtonYes__2HMfs {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* A simple technique I developed here: https://codesandbox.io/s/flexbox-with-gaps-simple-qu7wl
   These helpers can be used to construct a flexbox layout that supports grid gaps like css grid does.
   This is not as easy as it seems, especially with a responsive flex layout that can wrap, this solution adds
   margins to add flex items then uses negative margins to offset the margins at the edge so that they don't
   interfere with elements outside of this one

   Usage:

   JSX:

   <div className={css.filtersWrapper}>
     <div className={css.filtersInner}>
       <div className={css.filter}>Item 1</div>
       <div className={css.filter}>Item 2</div>
       <div className={css.filter}>Item 3</div>
     </div>
   </div>

   CSS:

   @import '../FlexHelper/FlexHelper.css';

   // Decorate the wrapper here as you wish but don't add padding
   .filtersWrapper {
     @apply --flexWrapper;
   }

   // Don't add much except flexbox configuration here
   .filtersInner {
     @apply --flexInner3;
     justify-content: center; // Or whatever flex stuff you want to add
   }

   // Decorate each item as you wish but don't add margins
   .filter {
     @apply --flexItem3;

     // You can add widths here for more of a grid-like look.
     // You need to use / add helpers though because the widths need to be calculated using the margin
     @apply --flexItem3Width50Percent;

     @media (--screen-md) {
       @apply --flexItem3Width25Percent;
     }
   }

   In this simple version the gaps cannot be configured to change at breakpoints but ...

   Extra classes for different configurations could be added here and reused elsewhere as needed

   Or it can be expended to support a nice Bootstrap-style grid: https://codesandbox.io/s/pmxn0pqpyq
   - This implementation can be responsive but every use on the app is the same

   Or it can be used like this when uncoupled from the limitations of CSS Modules: https://codesandbox.io/s/mz13k4mw4x
   - This implementation can change behavior on a per usage basis but it cannot practically be done in CSS Modules
     without using real CSS Custom Properties
*/

.SearchFilters_root__12Klr {
  position: relative;
}

.SearchFilters_filtersWrapper__3NpEP {
  display: inline-block;
  width: 100%;
}

.SearchFilters_filtersInner__lsCEu {
  display: flex;
  flex-wrap: wrap;

    /* Stop the negative margins preventing clicks part 1.
       This part makes the negative margins (and all children) unclickable */
  pointer-events: none;

    /* Give the margin top the top/left by default. Then swap the margin from left to right for rtl languages
       which should prevent overflow and scrollbars both ways */
  margin: calc(0px - 0.75rem) 0 0 calc(0px - 0.75rem);
  margin: calc(0px - 0.75rem) 0 0 calc(0px - 0.75rem);
}

.SearchFilters_filtersInner__lsCEu:dir(rtl) {
  margin: calc(0px - 0.75rem) calc(0px - 0.75rem) 0 0;
  margin: calc(0px - 0.75rem) calc(0px - 0.75rem) 0 0;
    }

.SearchFilters_filter__2Il5m {
    /* Stop the negative margins preventing clicks part 2.
       This part restores clicks for all the items and their children */
  pointer-events: auto;

    /* Give the margin top the top/left by default. Then swap the margin from left to right for rtl languages
       which should prevent overflow and scrollbars both ways */
  margin: 0.75rem 0 0 0.75rem;
  margin: 0.75rem 0 0 0.75rem;
}

.SearchFilters_filter__2Il5m:dir(rtl) {
  margin: 0.75rem 0.75rem 0 0;
  margin: 0.75rem 0.75rem 0 0;
    }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SearchFiltersPanel_root__2HQwZ {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  background-color: #ffffff;
}

.SearchFiltersPanel_filtersWrapper__16UKQ {
}

.SearchFiltersPanel_footer__3TZIu {
  display: flex;
}

.SearchFiltersPanel_resetAllButton__cUROM {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: #1a202c;
  color: #1a202c;

    /* Text size should be inherited */
  text-decoration: none
}

.SearchFiltersPanel_resetAllButton__cUROM:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.SearchFiltersPanel_resetAllButton__cUROM {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.SearchFiltersPanel_resetAllButton__cUROM {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.SearchFiltersPanel_resetAllButton__cUROM {
  font-weight: 500;
  font-weight: 500;
  color: #a1aec1;
  color: #a1aec1;

  /* Layout */
  margin: 0 auto 0 0;

  /* Override button styles */
  outline: none
}

.SearchFiltersPanel_resetAllButton__cUROM:focus,
  .SearchFiltersPanel_resetAllButton__cUROM:hover {
    color: #4a5569;
    color: #4a5569;
  }

.SearchFiltersPanel_cancelButton__2ue6V {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: #1a202c;
  color: #1a202c;

    /* Text size should be inherited */
  text-decoration: none
}

.SearchFiltersPanel_cancelButton__2ue6V:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.SearchFiltersPanel_cancelButton__2ue6V {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.SearchFiltersPanel_cancelButton__2ue6V {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.SearchFiltersPanel_cancelButton__2ue6V {
  font-weight: 500;
  font-weight: 500;
  color: #a1aec1;
  color: #a1aec1;

  /* Layout */
  margin: 0 24px 0 24px;

  /* Override button styles */
  outline: none
}

.SearchFiltersPanel_cancelButton__2ue6V:focus,
  .SearchFiltersPanel_cancelButton__2ue6V:hover {
    color: #4a5569;
    color: #4a5569;
  }

.SearchFiltersPanel_applyButton__3Xul5 {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: #1a202c;
  color: #1a202c;

    /* Text size should be inherited */
  text-decoration: none
}

.SearchFiltersPanel_applyButton__3Xul5:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.SearchFiltersPanel_applyButton__3Xul5 {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.SearchFiltersPanel_applyButton__3Xul5 {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.SearchFiltersPanel_applyButton__3Xul5 {
  font-weight: 500;
  font-weight: 500;

  /* Layout */
  margin: 0;

  /* Override button styles */
  outline: none
}

.SearchFiltersPanel_applyButton__3Xul5:focus,
  .SearchFiltersPanel_applyButton__3Xul5:hover {
    color: #3e704b;
    color: #3e704b;
  }

.SearchMapWithMapbox_fullArea__3ntS2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.SearchMapWithMapbox_activeLabel__3oc2b {
  z-index: 1;
}

.SearchMapWithMapbox_labelContainer__31dYL:hover {
    z-index: 1;
  }

.SearchMapWithMapbox_labelContainer__31dYL:focus {
    outline: none;
  }

.SearchMapWithMapbox_infoCardContainer__2UbDh {
  z-index: 1;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SearchMap_root__1t7_K {
  width: 100%;
  height: 100%;
  background-color: #a1aec1;
  background-color: #a1aec1;
}

.SearchMap_mapRoot__3sZLm {
  width: 100%;
  height: 100%;
}

.SearchMap_reusableMap__21llo {
  width: 100%;
  height: 100%;
}

.SearchMap_defaultMapLayout__3hNTi {
  position: fixed;
  top: 0;
  right: 0;
  width: 50vw;
  height: 100vh;
}

/**
 * When reusable map is attached right to the body it's hidden.
 * Specificity rule is added to overwrite positioning coming from props.className
 */

body > .SearchMap_reusableMapHidden__3svfL {
  position: absolute;
  top: -1000px;
  left: -1000px;
  visibility: hidden;
  opacity: 0
}

@media (min-width: 768px) {

body > .SearchMap_reusableMapHidden__3svfL {
    top: -1000px;
    left: -1000px;
    right: auto
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SearchMapGroupLabel_root__v1Wmk {
  /* Size from content */
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  border: 0
}

.SearchMapGroupLabel_root__v1Wmk:hover .SearchMapGroupLabel_details__2n3Pb,
    .SearchMapGroupLabel_root__v1Wmk:hover .SearchMapGroupLabel_caret__3HOHl {
      cursor: pointer;

      /* Same as active */
      background-color: #3e704b;
      background-color: #3e704b;
      border-color: #3e704b;
      border-color: #3e704b;
      color: #ffffff;
      color: #ffffff;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
    }

.SearchMapGroupLabel_details__2n3Pb {
  /**
   * Since caret is absolutely positioned,
   * label must have relative to be included to the same rendering layer
   */
  position: relative;

  /* Font */
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.SearchMapGroupLabel_details__2n3Pb {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.SearchMapGroupLabel_details__2n3Pb {
  font-weight: 600;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;

  padding: 6px 14px;

  /* Coloring */
  background-color: #ffffff;
  background-color: #ffffff;
  color: #4a5569;
  color: #4a5569;

  /* Borders */
  border-radius: 17px;
  border-style: solid;
  border-color: #a1aec1;
  border-color: #a1aec1;
  border-width: 1px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);

  transition: ease-in-out 0.1s;

  transition: ease-in-out 0.1s

  /* Overwrite dimensions from font styles */
}

@media (min-width: 768px) {

.SearchMapGroupLabel_details__2n3Pb {
    margin-top: 0;
    margin-bottom: 0
}
  }

.SearchMapGroupLabel_detailsActive__3s5Jf {
  /* Same as hover */
  background-color: #3e704b;
  background-color: #3e704b;
  border-color: #3e704b;
  border-color: #3e704b;
  color: #ffffff;
  color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
}

.SearchMapGroupLabel_caretShadow__1tZth {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  /* Caret should have same box-shadow as label */
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.SearchMapGroupLabel_caret__3HOHl {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  /* Caret should have same bg-color as label */
  background-color: #ffffff;
  background-color: #ffffff;

  transition: ease-in-out 0.1s;

  transition: ease-in-out 0.1s;
}

.SearchMapGroupLabel_caretActive__32eo4 {
  /* Same as hover */
  background-color: #3e704b;
  background-color: #3e704b;
  border-color: #3e704b;
  border-color: #3e704b;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SearchMapInfoCard_root__2amco {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  display: block;
  color: #1a202c;
  color: #1a202c;
  transition: ease-in 0.2s;
  transition: ease-in 0.2s;
  border-radius: 5px;
  border-radius: 5px;
  width: 21rem;
  width: 21rem;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1)
}

.SearchMapInfoCard_root__2amco:hover {
    text-decoration: none;
  }

.SearchMapInfoCard_rootResponsive__1Xkvj {
}

@media (min-width: 1024px) {

.SearchMapInfoCard_rootResponsive__1Xkvj {
    display: flex;
    align-items: center;
    background-color: #fff;
    background-color: #fff
}
  }

.SearchMapInfoCard_imageWrapper__1L1n7 {
  display: block;
  width: 100%;
  position: relative;
}

.SearchMapInfoCard_imageInner__3q16T {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
}

.SearchMapInfoCard_imageRoot__1TCFn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 5px;
  border-radius: 5px;
}

.SearchMapInfoCard_infoContainer__55tYO {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  background-color: #fff;
  padding: 0.75rem 1rem 0 1rem;
  padding: 0.75rem 1rem 0 1rem;
  border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.SearchMapInfoCard_infoMain__4-2s8 {
  display: flex;
  flex-direction: column;
  margin-right: 0.75rem;
  margin-right: 0.75rem;
}

.SearchMapInfoCard_infoHeader__hc864 {
  font-size: 0.75rem;
  font-size: 0.75rem;
  color: #718096;
  color: #718096;
}

.SearchMapInfoCard_infoTitle__1D_Tk {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}

.SearchMapInfoCard_infoTitleResponsive__1ZumY {
}

@media (min-width: 1024px) {

.SearchMapInfoCard_infoTitleResponsive__1ZumY {
    font-size: 1.5rem;
    font-size: 1.5rem
}
  }

.SearchMapInfoCard_priceInfo__24bJF {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
}

.SearchMapInfoCard_priceInfoLabel__1pWQ0 {
  margin-bottom: 0;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.SearchMapInfoCard_priceAmount__22ZfI {
  color: #1a202c;
  color: #1a202c;
  font-size: 0.875rem;
  font-size: 0.875rem
}

.SearchMapInfoCard_priceAmount__22ZfI span:first-of-type {
    font-weight: 700;
    font-weight: 700;
  }

.SearchMapInfoCard_infoFooter__1cDgI {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.SearchMapInfoCard_favoriteWrapper__faYoP {
  position: absolute;
  top: 0.75rem;
  top: 0.75rem;
  right: 0.75rem;
  right: 0.75rem;
  z-index: 1;
}

.SearchMapInfoCard_panelHostMobile__2YvOZ {
  display: contents
}

@media (min-width: 1024px) {

.SearchMapInfoCard_panelHostMobile__2YvOZ {
    display: none
}
  }

.SearchMapInfoCard_firstToBook__3qiSv {
  font-size: 0.875rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

.SearchMapInfoCard_clockIcon__1sdAP {
  color: #3e704b;
  color: #3e704b;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
  height: 1rem;
  height: 1rem;
  width: 1rem;
  width: 1rem;
}

.SearchMapInfoCard_ratingWrapper__OHCFV {
  margin-top: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 1024px) {

.SearchMapInfoCard_ratingWrapper__OHCFV {
    margin-top: 0.75rem;
    margin-top: 0.75rem
}
  }

.SearchMapInfoCard_reviewRating__bF4nj {
  display: inline-flex;
  white-space: nowrap;
}

.SearchMapInfoCard_reviewRatingStar__3cyJx {
  height: 1rem;
  height: 1rem;
  width: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
  margin-right: 0.25rem
}

.SearchMapInfoCard_reviewRatingStar__3cyJx:last-of-type {
    margin-right: 0;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SearchMapPriceLabel_root__EoMPF {
  position: relative;
  width: auto;
  height: auto;
  border: 0;
  padding: 0
}

.SearchMapPriceLabel_root__EoMPF:hover .SearchMapPriceLabel_priceLabel__2ICwe,
    .SearchMapPriceLabel_root__EoMPF:hover .SearchMapPriceLabel_caret__2_Q7_ {
      cursor: pointer;

      /* Same as active */
      background-color: #3e704b;
      background-color: #3e704b;
      border-color: #3e704b;
      border-color: #3e704b;
    }

.SearchMapPriceLabel_priceLabel__2ICwe {
  position: relative;
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  background-color: #2d3748;
  background-color: #2d3748;
  border-color: #2d3748;
  border-color: #2d3748;
  color: #fff;
  color: #fff;
  border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  padding: 0.5rem;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
}

.SearchMapPriceLabel_priceLabelActive__3nQwn {
  /* Same as hover */
  background-color: #3e704b;
  background-color: #3e704b;
  border-color: #3e704b;
  border-color: #3e704b;
}

.SearchMapPriceLabel_caretShadow__ioevP {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  /* Caret should have same box-shadow as label */
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
}

.SearchMapPriceLabel_caret__2_Q7_ {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;

  /* Caret should have same bg-color and border as label */
  background-color: #2d3748;
  background-color: #2d3748;
  border-right: 1px solid #2d3748;
  border-right: 1px solid #2d3748;
  border-bottom: 1px solid #2d3748;
  border-bottom: 1px solid #2d3748;
}

.SearchMapPriceLabel_caretActive__1q5Qk {
  /* Same as hover */
  border-color: #3e704b;
  border-color: #3e704b;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SearchResultsPanel_root__2aUei {
}

.SearchResultsPanel_container__G0G1Y {
  padding: 0 1.25rem 0 1.25rem;
  padding: 0 1.25rem 0 1.25rem
}

@media (min-width: 1024px) {

.SearchResultsPanel_container__G0G1Y {
    padding: 0 1.5rem 0 3rem;
    padding: 0 1.5rem 0 3rem
}
  }

.SearchResultsPanel_listingCards__1MN3r {
  margin-bottom: 2.25rem;
  margin-bottom: 2.25rem
}

@media (min-width: 768px) {

.SearchResultsPanel_listingCards__1MN3r {
    margin-bottom: 4rem;
    margin-bottom: 4rem
}
  }

.SearchResultsPanel_listingCard__V7oJl {
  margin-bottom: 2.25rem;
  margin-bottom: 2.25rem
}

@media (min-width: 768px) {

.SearchResultsPanel_listingCard__V7oJl {
    margin-bottom: 1.25rem;
    margin-bottom: 1.25rem
}
  }

.SearchResultsPanel_pagination__2ONkf {
  margin-bottom: 2rem;
  margin-bottom: 2rem
}

@media (min-width: 768px) {

.SearchResultsPanel_pagination__2ONkf {
    margin-bottom: 4rem;
    margin-bottom: 4rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SelectMultipleFilter_root__eraOo {
  position: relative;
  display: inline-block;
}

.SelectMultipleFilter_label__38_-5 {
  font-weight: 600;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.SelectMultipleFilter_label__38_-5 {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.SelectMultipleFilter_label__38_-5 {

    /* Dimensions */
  width: 100%;
  min-height: 30px;
  margin: 0;
  white-space: nowrap;

  padding: 4px 20px;

    /* Borders */
  border-style: solid;
  border-width: 1px;
  border-color: #e2e8f0;
  border-color: #e2e8f0;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #1a202c;
  color: #1a202c;

    /* Text */
  text-align: center;
  text-decoration: none;

  cursor: pointer;
}

.SelectMultipleFilter_label__38_-5:hover,
    .SelectMultipleFilter_label__38_-5:focus {
  border-color: #cbd5e0;
  border-color: #cbd5e0;
  outline: none;
    }

.SelectMultipleFilter_label__38_-5 {
  width: 100px;
  height: 30px;
  min-height: 0;
  margin: 4px 4px 4px 0px;
}

.SelectMultipleFilter_labelSelected__1NmQ0 {
  font-weight: 600;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.SelectMultipleFilter_labelSelected__1NmQ0 {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.SelectMultipleFilter_labelSelected__1NmQ0 {

    /* Dimensions */
  width: 100%;
  min-height: 30px;
  margin: 0;
  white-space: nowrap;

  padding: 4px 20px;

    /* Borders */
  border-style: solid;
  border-width: 1px;
  border-color: #e2e8f0;
  border-color: #e2e8f0;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #1a202c;
  color: #1a202c;

    /* Text */
  text-align: center;
  text-decoration: none;

  cursor: pointer;
}

.SelectMultipleFilter_labelSelected__1NmQ0:hover,
    .SelectMultipleFilter_labelSelected__1NmQ0:focus {
  border-color: #cbd5e0;
  border-color: #cbd5e0;
  outline: none;
    }

.SelectMultipleFilter_labelSelected__1NmQ0 {
  font-weight: 600;
  font-weight: 600;
  border-color: var(--ltActiveBackgroundColor);
  background-color: var(--ltActiveBackgroundColor);
  color: var(--ltActiveForegroundColor);
}

.SelectMultipleFilter_labelSelected__1NmQ0:hover,
    .SelectMultipleFilter_labelSelected__1NmQ0:focus {
  border-color: var(--ltActiveBackgroundColor);
    }

.SelectMultipleFilter_labelSelected__1NmQ0 {
  width: 100px;
  height: 30px;
  min-height: 0;
  margin: 4px 4px 4px 0px;
}

.SelectMultipleFilter_fieldGroup__2NNQ8 {
  margin: 0;
  padding: 0;
  border: none;
}

.SelectMultipleFilter_fieldGroupPlain__18tTl {
  margin: 0;
  padding-left: 20px;
  padding-bottom: 30px;
  border: none;
}

.SelectMultipleFilter_list__2pa2T {
  margin: 0;
}

.SelectMultipleFilter_item__3WSPn {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid
}

@media (min-width: 768px) {

.SelectMultipleFilter_item__3WSPn {
    padding: 4px 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SelectSingleFilterPlain_root__beP8E {
  padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid #a1aec1;
  border-bottom: 1px solid #a1aec1;
}

.SelectSingleFilterPlain_filterLabel__dKHZi,
.SelectSingleFilterPlain_filterLabelSelected__4rAxL {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.25rem;
  font-size: 1.25rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;
}

@media (min-width: 768px) {

.SelectSingleFilterPlain_filterLabel__dKHZi,
.SelectSingleFilterPlain_filterLabelSelected__4rAxL {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px;
}
    }

.SelectSingleFilterPlain_filterLabel__dKHZi,
.SelectSingleFilterPlain_filterLabelSelected__4rAxL {

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
}

.SelectSingleFilterPlain_filterLabel__dKHZi {
  color: #1a202c;
  color: #1a202c;
}

.SelectSingleFilterPlain_filterLabelSelected__4rAxL {
  color: #3e704b;
  color: #3e704b;
}

.SelectSingleFilterPlain_labelButton__1Hr6s {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.SelectSingleFilterPlain_optionsContainerOpen__3Enmt {
  height: auto;
  padding-bottom: 30px;
}

.SelectSingleFilterPlain_optionsContainerClosed__3Gck2 {
  height: 0;
  overflow: hidden;
}

.SelectSingleFilterPlain_hasBullets__3pwz5 {
  padding-left: 26px;
}

@media (min-width: 768px) {

.SelectSingleFilterPlain_twoColumns__3j5n8 {
    column-count: 2
}
  }

.SelectSingleFilterPlain_optionBorder__2rH52,
.SelectSingleFilterPlain_optionBorderSelected__1qSxX {
  position: absolute;
  height: calc(100% - 12px);
  top: 4px;
  left: -24px;
  transition: width ease-in-out 0.1s;
  transition: width ease-in-out 0.1s;
}

/* left animated "border" like hover element */

.SelectSingleFilterPlain_optionBorder__2rH52 {
  width: 0;
  background-color: #1a202c;
  background-color: #1a202c;
}

/* left static border for selected element */

.SelectSingleFilterPlain_optionBorderSelected__1qSxX {
  width: 8px;
  background-color: #1a202c;
  background-color: #1a202c;
}

.SelectSingleFilterPlain_optionBullet__goU_X,
.SelectSingleFilterPlain_optionBulletSelected__1eNe2 {
  position: absolute;
  left: -5px;
  top: 13px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #3e704b;
  background-color: #3e704b;
  transition: opacity ease-in-out 0.1s;
  transition: opacity ease-in-out 0.1s;
}

/* left animated "border" like hover element */

.SelectSingleFilterPlain_optionBullet__goU_X {
  opacity: 0;
}

/* left static border for selected element */

.SelectSingleFilterPlain_optionBulletSelected__1eNe2 {
  opacity: 1;
}

.SelectSingleFilterPlain_option__3ioZD {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.SelectSingleFilterPlain_option__3ioZD {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.SelectSingleFilterPlain_option__3ioZD {
  font-weight: 500;
  font-weight: 500;
  font-size: 18px;
  color: #4a5569;
  color: #4a5569;

  /* Layout */
  display: block;
  position: relative;
  margin: 0;
  padding: 4px 0 8px 20px;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer
}

.SelectSingleFilterPlain_option__3ioZD:focus,
  .SelectSingleFilterPlain_option__3ioZD:hover {
    color: #1a202c;
    color: #1a202c;
  }

.SelectSingleFilterPlain_option__3ioZD:hover .SelectSingleFilterPlain_menuItemBorder__1Ke1r {
    width: 6px;
  }

.SelectSingleFilterPlain_optionSelected__10nl9 {
  color: #1a202c;
  color: #1a202c;
}

.SelectSingleFilterPlain_clearButton__2qN41 {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.SelectSingleFilterPlain_clearButton__2qN41 {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.SelectSingleFilterPlain_clearButton__2qN41 {
  font-weight: 500;
  font-weight: 500;
  color: #a1aec1;
  color: #a1aec1;

  /* Layout */
  display: inline;
  float: right;
  margin-top: 6px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none
}

.SelectSingleFilterPlain_clearButton__2qN41:focus,
  .SelectSingleFilterPlain_clearButton__2qN41:hover {
    color: #4a5569;
    color: #4a5569;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SelectSingleFilterPopup_root__1rERL {
  display: inline-block
}

.SelectSingleFilterPopup_root__1rERL:last-of-type {
    padding-right: 0;
  }

.SelectSingleFilterPopup_menuLabel__p5dgZ {
  font-weight: 400;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px
}

@media (min-width: 768px) {

.SelectSingleFilterPopup_menuLabel__p5dgZ {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.SelectSingleFilterPopup_menuLabel__p5dgZ {

    /* Dimensions */
  display: block;
  width: 100%;
  min-height: 59px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 16px 0 0 0;

    /* Borders */
  border-style: solid;
  border-color: #3e704b;
  border-color: #3e704b;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #3e704b;
  color: #3e704b;

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  cursor: pointer
}

.SelectSingleFilterPopup_menuLabel__p5dgZ:hover,
    .SelectSingleFilterPopup_menuLabel__p5dgZ:focus {
  text-decoration: none;
    }

@media (min-width: 768px) {

.SelectSingleFilterPopup_menuLabel__p5dgZ {
  min-height: 60px
}
    }

.SelectSingleFilterPopup_menuLabel__p5dgZ {

  color: #1a202c;

  color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;

    /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */
  padding: 18px 0 0 0;

  border-width: 1px;
  border-style: solid;
  border-color: #a1aec1;
  border-color: #a1aec1
}

.SelectSingleFilterPopup_menuLabel__p5dgZ:hover,
    .SelectSingleFilterPopup_menuLabel__p5dgZ:focus {
  background-color: #1a202c;
  background-color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;
  color: #fff;
  color: #fff;
    }

.SelectSingleFilterPopup_menuLabel__p5dgZ:disabled {
  background-color: #a1aec1;
  background-color: #a1aec1;
  color: #ffffff;
  color: #ffffff;
  border-color: #a1aec1;
  border-color: #a1aec1;
    }

.SelectSingleFilterPopup_menuLabel__p5dgZ {
  font-weight: 600;
  font-weight: 600;
  font-size: 0.875rem;
  font-size: 0.875rem
}

@media (min-width: 768px) {

.SelectSingleFilterPopup_menuLabel__p5dgZ {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem
}
    }

.SelectSingleFilterPopup_menuLabel__p5dgZ {

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px
}

.SelectSingleFilterPopup_menuLabel__p5dgZ:focus {
    outline: none;
    background-color: #ffffff;
    background-color: #ffffff;
    border-color: transparent;
    text-decoration: none;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  }

.SelectSingleFilterPopup_menuLabelSelected__2AOFV {
  font-weight: 400;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px
}

@media (min-width: 768px) {

.SelectSingleFilterPopup_menuLabelSelected__2AOFV {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.SelectSingleFilterPopup_menuLabelSelected__2AOFV {

    /* Dimensions */
  display: block;
  width: 100%;
  min-height: 59px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 16px 0 0 0;

    /* Borders */
  border-style: solid;
  border-color: #3e704b;
  border-color: #3e704b;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #3e704b;
  color: #3e704b;

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  cursor: pointer
}

.SelectSingleFilterPopup_menuLabelSelected__2AOFV:hover,
    .SelectSingleFilterPopup_menuLabelSelected__2AOFV:focus {
  text-decoration: none;
    }

@media (min-width: 768px) {

.SelectSingleFilterPopup_menuLabelSelected__2AOFV {
  min-height: 60px
}
    }

.SelectSingleFilterPopup_menuLabelSelected__2AOFV {
  font-weight: 600;
  font-weight: 600;
  font-size: 0.875rem;
  font-size: 0.875rem
}

@media (min-width: 768px) {

.SelectSingleFilterPopup_menuLabelSelected__2AOFV {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem
}
    }

.SelectSingleFilterPopup_menuLabelSelected__2AOFV {
  font-weight: 600;
  font-weight: 600;

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid #3e704b;
  border: 1px solid #3e704b
}

.SelectSingleFilterPopup_menuLabelSelected__2AOFV:hover,
  .SelectSingleFilterPopup_menuLabelSelected__2AOFV:focus {
    border: 1px solid #3e704b;
    border: 1px solid #3e704b;
  }

.SelectSingleFilterPopup_menuContent__xSERX {
  margin-top: 7px;
  padding-top: 13px;
  min-width: 300px;
  border-radius: 4px;
}

/* left animated "border" like hover element */

.SelectSingleFilterPopup_menuItemBorder__3sIcl {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: #3e704b;
  background-color: #3e704b;
  transition: width ease-in-out 0.1s;
  transition: width ease-in-out 0.1s;
}

/* left static border for selected element */

.SelectSingleFilterPopup_menuItemBorderSelected__3rLuO {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: #1a202c;
  background-color: #1a202c;
}

.SelectSingleFilterPopup_menuItem__M1uCR {
  font-size: 1.125rem;
  font-size: 1.125rem;
  color: #4a5569;
  color: #4a5569;

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer
}

.SelectSingleFilterPopup_menuItem__M1uCR:focus,
  .SelectSingleFilterPopup_menuItem__M1uCR:hover {
    color: #1a202c;
    color: #1a202c;
  }

.SelectSingleFilterPopup_menuItem__M1uCR:hover .SelectSingleFilterPopup_menuItemBorder__3sIcl {
    width: 6px;
  }

.SelectSingleFilterPopup_clearMenuItem__2F5kK {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.SelectSingleFilterPopup_clearMenuItem__2F5kK {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.SelectSingleFilterPopup_clearMenuItem__2F5kK {
  font-weight: 500;
  font-weight: 500;
  color: #a1aec1;
  color: #a1aec1;

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width ease-in-out 0.1s;
  transition: width ease-in-out 0.1s
}

.SelectSingleFilterPopup_clearMenuItem__2F5kK:focus,
  .SelectSingleFilterPopup_clearMenuItem__2F5kK:hover {
    color: #4a5569;
    color: #4a5569;
    transition: width ease-in-out 0.1s;
    transition: width ease-in-out 0.1s;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

/* Booking form inside modalContainer needs special handling */

/* since "inMobile" breakpoint is actually --viewportLarge */

.SpecialOfferPanel_modalContainer__1-oIR {
  flex: 1 1;
  background-color: #fff;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  padding: 1.75rem 1.25rem 0 1.25rem;
  padding: 1.75rem 1.25rem 0 1.25rem;
  min-height: 100vh
}

@media (min-width: 768px) {

.SpecialOfferPanel_modalContainer__1-oIR {
    padding: 0;
    min-height: auto
}
  }

.SpecialOfferPanel_modalHeading__1dCMI {
  margin-top: 2.25rem;
  margin-top: 2.25rem;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem
}

@media (min-width: 768px) {

.SpecialOfferPanel_modalHeading__1dCMI {
    display: none
}
  }

.SpecialOfferPanel_title__CFo4E {
  /* Font */
  font-size: 1.5rem;
  font-size: 1.5rem
}

@media (min-width: 768px) {

.SpecialOfferPanel_title__CFo4E {
  font-size: 1.875rem;
  font-size: 1.875rem
}
    }

.SpecialOfferPanel_title__CFo4E {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  color: #4a5569;
  color: #4a5569;

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 768px) {

.SpecialOfferPanel_title__CFo4E {
    margin-top: 0;
    margin-bottom: 0.25rem;
    margin-bottom: 0.25rem
}
  }

.SpecialOfferPanel_author__-3ZkA {
  width: 100%;
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0
}

@media (min-width: 768px) {

.SpecialOfferPanel_author__-3ZkA {
    margin-top: 0;
    margin-bottom: 0;
    color: #4a5569;
    color: #4a5569
}
  }

.SpecialOfferPanel_bookingHeading__3PqQ6 {
  display: none
}

@media (min-width: 1024px) {

.SpecialOfferPanel_bookingHeading__3PqQ6 {
    display: block
}
  }

.SpecialOfferPanel_bookingTitle__jEXRf {
  /* Font */
  color: #4a5569;
  color: #4a5569;

  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.SpecialOfferPanel_bookingHelp__3D2ig {
  text-size: 0.75rem;
  text-size: 0.75rem;
  color: #4a5569;
  color: #4a5569
}

@media (min-width: 768px) {

.SpecialOfferPanel_bookingHelp__3D2ig {
    color: #4a5569;
    color: #4a5569;
    display: block;
    margin-top: 0
}
  }

.SpecialOfferPanel_bookingForm__3_UDD {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0 5rem 0;
  margin: 0 0 5rem 0
}

@media (min-width: 768px) {

.SpecialOfferPanel_bookingForm__3_UDD {
    margin: 0;
    min-height: 24rem;
    min-height: 24rem
}
  }

@media (min-width: 1024px) {

.SpecialOfferPanel_bookingForm__3_UDD {
    min-width: 312px;
    min-height: auto
}
  }

.SpecialOfferPanel_bookingDatesSubmitButtonWrapper__2b_EN {
  flex-shrink: 0;
  padding: 0 1.5rem 1.5rem 1.5rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  background-color: #fff
}

@media (min-width: 768px) {

.SpecialOfferPanel_bookingDatesSubmitButtonWrapper__2b_EN {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent
}
  }

.SpecialOfferPanel_openBookingForm__2Jwsg {
  /* Ensure that mobile button is over Footer too */
  z-index: 60;
  z-index: 60;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1rem 1rem 0 1rem;
  padding: 1rem 1rem 0 1rem;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0)
}

@media (min-width: 768px) {

.SpecialOfferPanel_openBookingForm__2Jwsg {
    padding: 1rem 4rem 1rem 4rem;
    padding: 1rem 4rem 1rem 4rem
}
  }

@media (min-width: 1024px) {

.SpecialOfferPanel_openBookingForm__2Jwsg {
    display: none
}
  }

.SpecialOfferPanel_priceContainer__9edE3 {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 1.5rem;
  margin-right: 1.5rem;
  padding: 0.25rem 0.75rem;
  padding: 0.25rem 0.75rem;
}

.SpecialOfferPanel_priceValue__1htjA {
  /* Font */
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px
}

@media (min-width: 768px) {

.SpecialOfferPanel_priceValue__1htjA {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px
}
    }

.SpecialOfferPanel_priceValue__1htjA {
  color: #4a5569;
  color: #4a5569;

  margin-top: 0;
  margin-bottom: 0px
}

@media (min-width: 768px) {

.SpecialOfferPanel_priceValue__1htjA {
    margin-top: 0;
    margin-bottom: 0
}
  }

.SpecialOfferPanel_perUnit__2raWK {
  /* Font */
  font-size: 13px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px
}

@media (min-width: 768px) {

.SpecialOfferPanel_perUnit__2raWK {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
}
    }

.SpecialOfferPanel_perUnit__2raWK {
  color: #4a5569;
  color: #4a5569;
  margin-top: 0;
  margin-bottom: 0px
}

@media (min-width: 768px) {

.SpecialOfferPanel_perUnit__2raWK {
    margin-top: 0;
    margin-bottom: 0
}
  }

.SpecialOfferPanel_bookButton__2WsYl {
  width: 100%;
  border-radius: 25px;
  border-radius: 25px;
  margin-bottom: 1em;
}

.SpecialOfferPanel_closedListingButton__IYg8X {
  border-left: 1px solid #a1aec1;
  border-left: 1px solid #a1aec1;
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.SpecialOfferPanel_sportsmanInfo__nAddK {
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
  color: #1a202c;
  color: #1a202c
}

@media (min-width: 768px) {

.SpecialOfferPanel_sportsmanInfo__nAddK {
    display: block
}
  }

.SpecialOfferPanel_sportsmanSubInfo__2uHfr {
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #1a202c;
  color: #1a202c;
  margin: 0.5rem auto 1rem auto;
  margin: 0.5rem auto 1rem auto
}

@media (min-width: 768px) {

.SpecialOfferPanel_sportsmanSubInfo__2uHfr {
    display: block
}
  }

.SpecialOfferPanel_orderError__1SPkJ {
  color: #3e704b;
  color: #3e704b;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.StripeConnectAccountStatusBox_verifySection__21GPX {
  background-color: #f7fafc;
  background-color: #f7fafc;
  padding: 1.5rem 1rem 1.5rem 1rem;
  padding: 1.5rem 1rem 1.5rem 1rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  color: #1a202c;
  color: #1a202c;
  margin-left: calc(-1 * 1.25rem);
  margin-left: calc(-1 * 1.25rem);
  margin-right: calc(-1 * 1.25rem);
  margin-right: calc(-1 * 1.25rem);
}

.StripeConnectAccountStatusBox_verifySectionHeader__1D0Te {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

.StripeConnectAccountStatusBox_verifySectionHeader__1D0Te > svg {
    height: 1.75rem;
    height: 1.75rem;
    margin-left: calc(-1 * 0.5rem);
    margin-left: calc(-1 * 0.5rem);
  }

.StripeConnectAccountStatusBox_verifySectionContent__1c0Bx {
  font-size: 1rem;
  font-size: 1rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.StripeConnectAccountStatusBox_linkText__33vta {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 600;
  font-weight: 600;
}

.StripeConnectAccountStatusBox_getVerifiedButton__1hWuo {
  width: 100%
}

@media (min-width: 768px) {

.StripeConnectAccountStatusBox_getVerifiedButton__1hWuo {
    width: auto
}
  }

.StripeConnectAccountStatusBox_editVerificationButton__3m0Wu {
  display: flex;
  align-items: center
}

.StripeConnectAccountStatusBox_editVerificationButton__3m0Wu > svg {
    margin-right: 0.5rem;
    margin-right: 0.5rem;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.StripePaymentAddress_root__2dXzb {
  display: flex;
  flex-direction: column;
}

.StripePaymentAddress_paymentAddressField__37xbd {
  padding-top: 38px;
}

.StripePaymentAddress_formRow__xTBz3 {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap
}

@media (min-width: 550px) {

.StripePaymentAddress_formRow__xTBz3 {
    flex-wrap: no-wrap
}
  }

.StripePaymentAddress_field__2slj0 {
  width: 100%;
  margin-top: 24px
}

@media (min-width: 550px) {

.StripePaymentAddress_field__2slj0 {
    width: calc(50% - 12px)
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ContactLandownerButton_root__1zHgL {
}

.ContactLandownerButton_contactButton__3La4B {
  margin-right: 1rem;
  margin-right: 1rem;
  white-space: nowrap;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.UserCard_content__2nos9 {
  display: flex;
  flex-direction: column;
}

.UserCard_withExtraSpace__3nyaP {
  margin-bottom: 16px;
}

.UserCard_avatarContainer__2Mpzn {
  position: relative;
}

.UserCard_avatar__3SKwM {
  flex-shrink: 0;
  margin-right: 1.25rem;
  margin-right: 1.25rem;
  width: 5rem;
  width: 5rem;
  height: 5rem;
  height: 5rem;
}

.UserCard_info__7sa6x {
  display: flex;
  flex: 1 1;
}

.UserCard_heading__IzJL8 {
  color: #1a202c;
  color: #1a202c;
  font-size: 1.125rem;
  font-size: 1.125rem
}

@media (min-width: 768px) {

.UserCard_heading__IzJL8 {
  font-size: 1.5rem;
  font-size: 1.5rem
}
    }

.UserCard_heading__IzJL8 {
  margin: 0;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem
}

.UserCard_heading__IzJL8 a {
    text-decoration: underline;
  }

.UserCard_hostedContainer__2ztRK {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.UserCard_hostedBy__1Ml_j {
  margin: 0;
  line-height: 0.5rem;
  line-height: 0.5rem;
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #1a202c;
  color: #1a202c;
}

.UserCard_link__38bfK {
  display: flex;
  white-space: nowrap
}

.UserCard_link__38bfK:hover {
    text-decoration: none;
  }

.UserCard_mobileBio__1x8e3,
.UserCard_desktopBio__2V82d {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;
  @apply --typography-base

  @media (--screen-md) {
    margin: 18px 0 0 0;
  }
}

@media (min-width: 768px) {

.UserCard_mobileBio__1x8e3 {
    display: none
}
  }

.UserCard_desktopBio__2V82d {
  display: none
}

@media (min-width: 768px) {

.UserCard_desktopBio__2V82d {
    margin-top: 16px;
    display: block
}
  }

.UserCard_links__LBAoB {
  @apply --typography-base
  margin-top: var(--s-3)
}

@media (min-width: 768px) {

.UserCard_links__LBAoB {
    margin: 1rem 0 0 0;
    margin: 1rem 0 0 0
}
  }

@media (min-width: 768px) {

.UserCard_withBioMissingAbove__1oECX {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px
}
  }

.UserCard_linkSeparator__3KEu- {
  margin: 0 10px;
  color: #3e704b;
  color: #3e704b;
}

.UserCard_headingRow__39Nn4 {
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.UserCard_headingRow__39Nn4 {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline
}
  }

.UserCard_headingRow__39Nn4 a {
    @apply --font-base
    text-decoration: underline;
    font-weight: 700;
    font-weight: 700;
  }

.UserCard_editProfile__kQeTp {
  margin-bottom: 0;
  white-space: nowrap;
}

.UserCard_contact__1a1Hu {
  margin: 0;
  font-weight: 700;
  font-weight: 700;
}

.UserCard_contactHostQuestions__2A3rY {
  margin-top: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
}

.UserCard_seeBioButton__2MMOU,
.UserCard_seeBioMobile__3dwA4 {
  margin-right: 1rem;
  margin-right: 1rem;
  white-space: nowrap;
}

.UserCard_linkButtons__2dAoH {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.UserCard_flexContainer__1Y-c3 {
  display: flex;
  flex-direction: row;
}

.UserCard_linkContainer__1AR8g,
.UserCard_linkContainerMobile__1zXgR {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.UserCard_linkContainer__1AR8g {
  padding-bottom: 0.75rem;
  padding-bottom: 0.75rem;
}

.UserCard_linkContainerMobile__1zXgR {
  padding: 0;
}

.UserCard_fullProfileContact__38p1z {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.75rem 0;
  padding: 0.75rem 0
}

@media (min-width: 768px) {

.UserCard_fullProfileContact__38p1z {
    padding: 0
}
  }

.UserCard_verifictionIcon__3OZ1r {
  position: absolute;
  top: -10%;
  right: 0;
}

.UserCard_linkDescription__6b-RV {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  margin: 1.5rem 0 1rem;
  margin: 1.5rem 0 1rem;
  font-weight: 700;
  font-weight: 700;
  color: #1a202c;
  color: #1a202c;
}

.UserCard_reviewWrapper__2Exck {
  display: flex;
  align-items: center;
}

.UserCard_reviewLoaderWrapper__3J1KR {
  width: 100%;
  height: 1.5rem;
  height: 1.5rem;
}

.UserCard_reviewStarIcon__1DjXj {
  height: 1rem;
  height: 1rem;
  width: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.UserCard_reviewAvg__2kEQV {
  font-weight: 700;
  font-weight: 700;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.UserCard_reviewTotal__1PL6E {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.UserCard_responseRateWrapper__3eEHb {
  margin-top: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.UserCard_soldOut__23bkS,
.UserCard_bookingRange__3Ftno {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 2.5rem;
  height: 2.5rem;
  margin: 1rem 0.25rem 1.5rem 0.25rem;
  margin: 1rem 0.25rem 1.5rem 0.25rem;
  padding: 0.25rem;
  padding: 0.25rem;
  border-radius: 10px;
  border-radius: 10px;
  font-size: 0.75rem;
  font-size: 0.75rem;
  font-weight: 700;
  font-weight: 700;
}

.UserCard_soldOut__23bkS {
  background-color: #ffe9e5;
  background-color: #ffe9e5;
  color: #ed3615;
  color: #ed3615;
}

.UserCard_bookingRange__3Ftno {
  background-color: #f5f2ed;
  background-color: #f5f2ed;
  color: #a36731;
  color: #a36731;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.TripInsuranceDetails_root__2eKIh {
  position: relative;
  color: #1a202c;
  color: #1a202c;
}

.TripInsuranceDetails_titleContainer__ler49 {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.TripInsuranceDetails_subtitleContainer__2FA9l {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.TripInsuranceDetails_insuranceItemWrapper__JZeNp {
  display: flex;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.TripInsuranceDetails_insuranceIcon__33MKT {
  width: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
  color: #a1aec1;
  color: #a1aec1;
  overflow: visible !important;
}

.TripInsuranceDetails_insuranceItem__2m9F7 {
  display: flex;
}

.TripInsuranceDetails_declinedWrapper__2JG0x {
  display: flex;
  flex-direction: column;
}

.TripInsuranceDetails_cancellationLink__36Q73 {
  margin: 1rem 0;
  margin: 1rem 0;
  color: #1a202c;
  color: #1a202c;
  text-decoration: underline;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.TrustCard_content__3VYUM {
  display: flex;
  flex-direction: column;
  color: #1a202c;
  color: #1a202c;
}

.TrustCard_header__1XOHy {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.TrustCard_avatarContainer__37Ikx {
  position: relative;
}

.TrustCard_avatar__1KHOa {
  flex-shrink: 0;
  margin-right: 1.25rem;
  margin-right: 1.25rem;
  width: 5rem;
  width: 5rem;
  height: 5rem;
  height: 5rem;
}

.TrustCard_userName__1kE59 {
  color: #1a202c;
  color: #1a202c;
  font-size: 1.125rem;
  font-size: 1.125rem;
  margin: 0;
  font-weight: 700;
  font-weight: 700;
}

.TrustCard_hostedContainer__IPugs {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TrustCard_flexContainer__3U3Ab {
  display: flex;
  flex-direction: row;
}

.TrustCard_verifictionIcon__2NBsp {
  height: 1rem;
  height: 1rem;
  width: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.TrustCard_reviewWrapper___iZls {
  display: flex;
  align-items: center;
}

.TrustCard_reviewStarIcon__1xjz5 {
  height: 1rem;
  height: 1rem;
  width: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.TrustCard_reviewAvg__2909b {
  font-weight: 700;
  font-weight: 700;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.TrustCard_reviewTotal__1nFDY {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.TrustCard_trustContainer__1tVTr {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0 0 0;
  padding: 1.25rem 0 0 0
}

@media (min-width: 768px) {

.TrustCard_trustContainer__1tVTr {
    padding: 1.25rem 0 0 0;
    padding: 1.25rem 0 0 0
}
  }

.TrustCard_indvidualCheck__2zHJ8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.TrustCard_profileLink__vNeeq {
  display: flex;
  white-space: nowrap;
  text-decoration: underline;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content
}

.TrustCard_profileLink__vNeeq:hover {
    text-decoration: underline;
  }

.TrustCard_divider__34fae {
  height: 1px;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  margin: 1.25rem 0;
  margin: 1.25rem 0;
}

.TrustCard_boxContainer__YqQ7z {
  padding: 0.75rem 1.75rem 1rem 0.75rem;
  padding: 0.75rem 1.75rem 1rem 0.75rem;
  border-radius: 5px;
  border-radius: 5px;
  background-color: #f7fafc;
  background-color: #f7fafc;
  color: #1a202c;
  color: #1a202c;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.TrustCard_boxContainerWrapper__e0RHQ {
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}

.TrustCard_moreInfo__YxDsp {
  display: flex;
  align-items: center;
  text-decoration: underline;
  margin-top: 1rem;
  margin-top: 1rem;
}

.TrustCard_tooltipContent__1an6R {
  font-size: 0.875rem;
  font-size: 0.875rem;
  min-width: 13.5rem;
  min-width: 13.5rem;
  max-width: 16rem;
  max-width: 16rem;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem;
  padding: 1rem 0.5rem;
  padding: 1rem 0.5rem
}

@media (min-width: 768px) {

.TrustCard_tooltipContent__1an6R {
    min-width: 16rem;
    min-width: 16rem;
    max-width: 19rem;
    max-width: 19rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.CredovaPlugin_root__3IDmd {
  width: 100%;
  color: #1a202c;
  color: #1a202c;
}

.CredovaPlugin_header__2S-eQ {
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  color: #1a202c;
  color: #1a202c;
  display: flex;
  margin-top: 1rem;
  margin-top: 1rem;
  align-items: baseline
}

@media (min-width: 768px) {

.CredovaPlugin_header__2S-eQ {
    margin-top: 0
}
  }

.CredovaPlugin_headerLarge__1A-Rj {
  font-size: 1.25rem;
  font-size: 1.25rem;
}

.CredovaPlugin_boxWrapper__PjxrF {
  position: relative;
  width: 100%;
  margin: 0.75rem 0 1.5rem 0;
  margin: 0.75rem 0 1.5rem 0;
  padding: 0.25rem 1rem 1.5rem 1rem;
  padding: 0.25rem 1rem 1.5rem 1rem;
  border-radius: 10px;
  border-radius: 10px;
  border: solid 1px #e2e8f0;
  border: solid 1px #e2e8f0
}

@media (min-width: 768px) {

.CredovaPlugin_boxWrapper__PjxrF {
    margin: 0.75rem 0 2rem 0;
    margin: 0.75rem 0 2rem 0
}
  }

.CredovaPlugin_credovaWrapper__32Yze {
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0 0;
  margin: 1rem 0 0 0;
  background: #edf2f7;
  background: #edf2f7;
  border-radius: 5px;
  border-radius: 5px;
  padding: 0.75rem;
  padding: 0.75rem;
}

.CredovaPlugin_credovaWrapperLarge__29MKe {
  background: transparent;
  border-radius: 0;
  padding: 0;
}

.CredovaPlugin_credovaHeading__3mtp2 {
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  color: #1a202c;
  color: #1a202c;
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 0.5rem;
  column-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  column-gap: 0.5rem;
  margin-top: 0;
  align-items: baseline;
}

.CredovaPlugin_credovaHeadingSmall__2SrRt {
  display: none;
}

.CredovaPlugin_credovaHeadingLarge__3dScx {
  font-size: 1rem;
  font-size: 1rem;
  margin-top: 0;
}

.CredovaPlugin_credovaLogo__gujag {
  height: 1rem;
  height: 1rem;
  position: relative;
  top: 1px;
}

.CredovaPlugin_credovaLogoLarge__sHJ0F {
  height: 1.25rem;
  height: 1.25rem;
}

.CredovaPlugin_credovaTextWrapper__3zPps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.CredovaPlugin_credovaText__1vc2g {
  text-decoration: none;
  color: #1a202c;
  color: #1a202c;
  margin: 0.5rem 0 0 0;
  margin: 0.5rem 0 0 0;
  background: #edf2f7;
  background: #edf2f7;

  .crdv-text {
    font-size: 0.875rem;
    font-size: 0.875rem;
    color: #1a202c;
    color: #1a202c;
    background: #edf2f7;
    background: #edf2f7;
  }
}

.CredovaPlugin_credovaTextSmall__1J3yy {
  padding: 0;
  margin: 0;
  width: 100%;

  .crdv-text {
    font-size: 0.75rem;
    font-size: 0.75rem;
  }
}

.CredovaPlugin_credovaTextLarge__32viw {
  background: transparent;

  .crdv-text {
    background: transparent;
    font-size: 1rem;
    font-size: 1rem;
  }
}

.CredovaPlugin_credovaPremade__2QvQR {
  text-decoration: none;
  color: #1a202c;
  color: #1a202c;
  margin: 1.5rem 0 0 0;
  margin: 1.5rem 0 0 0
}

.CredovaPlugin_credovaPremade__2QvQR:hover {
    text-decoration: none;
  }

.CredovaPlugin_credovaPremade__2QvQR .crdv-button {
    text-decoration: none;
    background-color: #243547;
    background-color: #243547;
    color: #ffffff;
    padding: 0.75rem;
    padding: 0.75rem;
    border: 1px solid transparent;
    font-weight: 700;
    font-weight: 700;
    border-radius: 25px;
    border-radius: 25px;
    border-color: #718096;
    border-color: #718096;
    display: inline-block;
    cursor: pointer;
    font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
    font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1rem;
    font-size: 1rem;
    width: 100%;
    text-align: center
  }

.CredovaPlugin_credovaPremade__2QvQR .crdv-button:disabled {
      opacity: 0.5;
      opacity: 0.5;
      pointer-events: none;
    }

.CredovaPlugin_credovaPremade__2QvQR .crdv-button:hover {
      background-color: #fff;
      background-color: #fff;
      color: #1a202c;
      color: #1a202c;
    }

@media (min-width: 768px) {

.CredovaPlugin_credovaPremade__2QvQR .crdv-button {
      width: 60%
  }
    }

.CredovaPlugin_credovaButtonWrapper__2GfJq {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.CredovaPlugin_moreInfo__QN5tk {
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #1a202c;
  color: #1a202c;
  text-decoration: underline;
  margin-top: 0.25rem;
  margin-top: 0.25rem;
}

.CredovaPlugin_moreInfoLarge__38ub_ {
  font-size: 1rem;
  font-size: 1rem;
}

.CredovaPlugin_smallDivider__3mzJ_ {
  width: 100%;
  height: 1px;
  margin: 0.75rem 0;
  margin: 0.75rem 0;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.CredovaModal_root__140yQ {
  color: #1a202c;
  color: #1a202c;
  width: 100%;
  padding: 1.25rem;
  padding: 1.25rem
}

@media (min-width: 768px) {

.CredovaModal_root__140yQ {
    width: auto;
    padding: 2.25rem;
    padding: 2.25rem
}
  }

.CredovaModal_container__1JiwF {
  display: flex;
  flex-direction: column;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  align-items: flex-start;
}

.CredovaModal_headerContainer__34uax {
  display: flex;
  flex-direction: column;
}

.CredovaModal_headerImage__8T5e5 {
  width: 14rem;
  width: 14rem
}

@media (min-width: 768px) {

.CredovaModal_headerImage__8T5e5 {
    width: 13rem;
    width: 13rem
}
  }

.CredovaModal_header__2M_Z1 {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
  width: 100%
}

@media (min-width: 768px) {

.CredovaModal_header__2M_Z1 {
    width: 28.75rem;
    width: 28.75rem;
    font-size: 1.875rem;
    font-size: 1.875rem;
    line-height: 35px
}
  }

.CredovaModal_content__2Igx7 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

.CredovaModal_description__2v86v {
  font-size: 0.875rem;
  font-size: 0.875rem
}

@media (min-width: 768px) {

.CredovaModal_description__2v86v {
    font-size: 1rem;
    font-size: 1rem
}
  }

.CredovaModal_subHeader__30Z36 {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700;
}

.CredovaModal_listWrapper__2xDnX {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.CredovaModal_list__1fEtZ {
  list-style: disc;
  padding-left: 1.25rem;
  padding-left: 1.25rem
}

.CredovaModal_list__1fEtZ li {
    margin-bottom: 1rem;
    margin-bottom: 1rem;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.ModalMissingInformation_root__1KvDp {
  max-width: 1100px
}

@media (min-width: 768px) {

.ModalMissingInformation_root__1KvDp {
    min-height: 640px
}
  }

.ModalMissingInformation_twoColumnContainer__RvCrh {
  margin-top: 2rem;
  margin-top: 2rem
}

@media (min-width: 768px) {

.ModalMissingInformation_twoColumnContainer__RvCrh {
    margin-top: 0;
    display: flex
}
  }

.ModalMissingInformation_twoColumnContainer__RvCrh .ModalMissingInformation_firstColumn__2zr1D {
  width: 40%;
  width: 40%;
  background-position: center center;
  background-size: cover;
  display: none
}

@media (min-width: 768px) {

.ModalMissingInformation_twoColumnContainer__RvCrh .ModalMissingInformation_firstColumn__2zr1D {
    display: block
}
  }

.ModalMissingInformation_twoColumnContainer__RvCrh .ModalMissingInformation_secondColumn__20vDZ {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  padding: 1rem
}

@media (min-width: 768px) {

.ModalMissingInformation_twoColumnContainer__RvCrh .ModalMissingInformation_secondColumn__20vDZ {
    width: 60%;
    width: 60%;
    padding: 0
}
  }

@media (min-width: 768px) {

.ModalMissingInformation_innerContentContainer__3Az8Z {
    max-width: 75%;
    max-width: 75%
}
  }

.ModalMissingInformation_IDModalContent__9j3Fm {
  display: flex;
  flex-direction: column;
  align-items: center
}

@media (min-width: 768px) {

.ModalMissingInformation_IDModalContent__9j3Fm {
    max-width: 75%;
    max-width: 75%
}
  }

.ModalMissingInformation_modalContainer__3-HXP {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: #fff;
  background-color: #fff;
  border-radius: 5px;
  border-radius: 5px;
  border-bottom: none;
  flex-grow: 0;
  min-height: auto;
  height: auto;
  overflow: hidden;
  margin: 0 1.5rem;
  margin: 0 1.5rem;
  align-self: center;
}

/* Title of the modal */

.ModalMissingInformation_modalTitle__Xy9Er {
  color: #1a202c;
  color: #1a202c;
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-size: 1.5rem;
  font-size: 1.5rem;
}

@media (min-width: 768px) {

.ModalMissingInformation_modalTitle__Xy9Er {
  font-size: 2.25rem;
  font-size: 2.25rem;
}
    }

.ModalMissingInformation_modalTitle__Xy9Er {
  font-weight: 700;
  font-weight: 700;
}

/* Paragraph for the Modal */

.ModalMissingInformation_modalMessage__2DepG {
}

/* Make the email pop */

.ModalMissingInformation_email__3U5pY {
}

.ModalMissingInformation_helperLink__3Q_01 {
}

.ModalMissingInformation_helperText__2hJ6P {
}

.ModalMissingInformation_error__2BGLe {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.ModalMissingInformation_error__2BGLe {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.ModalMissingInformation_error__2BGLe {
  margin-top: 24px;
  color: #ff0000;
  color: #ff0000;
}

.ModalMissingInformation_bottomWrapper__VPClK {
  margin-top: 3rem;
  margin-top: 3rem;
}

.ModalMissingInformation_modalIcon__1cKh- {
}

.ModalMissingInformation_reminderModalLinkButton__q6-xA {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  padding: 0.75rem 2rem;
  padding: 0.75rem 2rem;
  background-color: #233933;
  background-color: #233933;
  color: #fff;
  color: #fff;
  font-weight: 600;
  font-weight: 600;
  border-radius: 20px;
  border-radius: 20px;
  height: 3rem;
  height: 3rem;
  text-align: center
}

.ModalMissingInformation_reminderModalLinkButton__q6-xA:hover {
    text-decoration: none;
  }

.ModalMissingInformation_reminderModalLinkButton__q6-xA > svg {
  width: 21px;
  height: 23px;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.ModalMissingInformation_stripeCTAWrapper__3yU5Q {
  text-align: center
}

@media (min-width: 768px) {

.ModalMissingInformation_stripeCTAWrapper__3yU5Q {
    display: inline-block
}
  }

.ModalMissingInformation_stripeLogoWrapper__2Sm3v {
  margin-top: 1.5rem;
  margin-top: 1.5rem
}

@media (min-width: 768px) {

.ModalMissingInformation_stripeLogoWrapper__2Sm3v {
    display: inline-block
}
  }

.ModalMissingInformation_stripeLogoWrapper__2Sm3v > img {
  width: 130px;
  height: auto;
}

.ModalMissingInformation_ctaLink__f041N {
  color: #1a202c;
  color: #1a202c;
  text-decoration: underline;
  font-weight: 600;
  font-weight: 600;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.PrivacyPolicy_disc__3QtOF {
  list-style-type: disc;
  margin-left: 1rem;
  margin-left: 1rem;
}

.PrivacyPolicy_title__ma7bE {
  font-size: 1.25rem;
  font-size: 1.25rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.LandownerReferral_root__2hQ_q p {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
  }

@media (min-width: 768px) {

.LandownerReferral_root__2hQ_q p {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
  }
    }

.LandownerReferral_root__2hQ_q h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 23px 0 19px 0
  }

@media (min-width: 768px) {

.LandownerReferral_root__2hQ_q h2 {
      margin: 24px 0 24px 0
  }
    }

.LandownerReferral_root__2hQ_q .LandownerReferral_lastUpdated__18jm7 {
  font-weight: 500;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
    margin-top: 0;
    margin-bottom: 55px
  }

@media (min-width: 768px) {

.LandownerReferral_root__2hQ_q .LandownerReferral_lastUpdated__18jm7 {
      margin-top: 0;
      margin-bottom: 54px
  }
    }

.LandownerReferral_disc__1QevY {
  list-style-type: disc;
  font-size: 17px !important;
  line-height: 24px !important;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.TermsOfService_root__52TBK p {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
  }

@media (min-width: 768px) {

.TermsOfService_root__52TBK p {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
  }
    }

.TermsOfService_root__52TBK h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 23px 0 19px 0
  }

@media (min-width: 768px) {

.TermsOfService_root__52TBK h2 {
      margin: 24px 0 24px 0
  }
    }

.TermsOfService_root__52TBK .TermsOfService_lastUpdated__1cplt {
  font-weight: 500;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
    margin-top: 0;
    margin-bottom: 55px
  }

@media (min-width: 768px) {

.TermsOfService_root__52TBK .TermsOfService_lastUpdated__1cplt {
      margin-top: 0;
      margin-bottom: 54px
  }
    }

.TermsOfService_disc__1U9w_ {
  list-style-type: disc;
  font-size: 17px !important;
  line-height: 24px !important;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.MobileTermsOfService_root__317MG p {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
  }

@media (min-width: 768px) {

.MobileTermsOfService_root__317MG p {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
  }
    }

.MobileTermsOfService_root__317MG h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 23px 0 19px 0
  }

@media (min-width: 768px) {

.MobileTermsOfService_root__317MG h2 {
      margin: 24px 0 24px 0
  }
    }

.MobileTermsOfService_root__317MG .MobileTermsOfService_lastUpdated__1wbD3 {
  font-weight: 500;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
    margin-top: 0;
    margin-bottom: 55px
  }

@media (min-width: 768px) {

.MobileTermsOfService_root__317MG .MobileTermsOfService_lastUpdated__1wbD3 {
      margin-top: 0;
      margin-bottom: 54px
  }
    }

.MobileTermsOfService_disc__21aWB {
  list-style-type: disc;
  font-size: 17px !important;
  line-height: 24px !important;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.AgritourismStatutes_root__2A3rZ {
}

.AgritourismStatutes_anchor__FyjuE {
  display: block;
  position: relative;
  top: calc(5rem * -1);
  top: calc(5rem * -1);
  visibility: hidden;
}

.AgritourismStatutes_agriLink__xehou {
  text-decoration: underline
}

.AgritourismStatutes_agriLink__xehou:hover {
    text-decoration: none;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingSection_root__3tRGK {
  margin: 0 1.25rem;
  margin: 0 1.25rem
}

@media (min-width: 768px) {

.EditListingSection_root__3tRGK {
    margin: 0
}
  }

@media (min-width: 1024px) {

.EditListingSection_rootWithMaxWidth__ZsVAR {
    max-width: 75%
}
  }

.EditListingSection_title__771qn {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.EditListingSection_hasBackground__3x65q {
  background-color: #f7fafc;
  background-color: #f7fafc;
  padding: 0.75rem 1.25rem;
  padding: 0.75rem 1.25rem;
}

.EditListingSection_hasTopMargin__I3nrg {
  margin-top: 2rem;
  margin-top: 2rem;
}

.EditListingSection_hasBottomMargin__2XObN {
  margin-bottom: 2rem;
  margin-bottom: 2rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingActions_root__iB5tj {
  /* We expect to be used in a flex column where we can push to the bottom */
  margin-top: auto;

  /* Start: Get the margin/padding/border sorted */
  display: flex;
  position: relative;

  /* This is the total size of visible space above and below the border */
  /* We don't use margin-top because we want that to be auto */
  /* Think of this as margin-top + padding-top */
  padding-top: calc(
    2.25rem + 2.25rem);
  padding-top: calc(
    2.25rem + 2.25rem);
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem
}

.EditListingActions_root__iB5tj::before {
    content: '';
    display: block;
    border-top: 1px solid #e2e8f0;
    border-top: 1px solid #e2e8f0;
    position: absolute;
    width: 100%;
    /* Think of this as margin-top */
    top: 2.25rem;
    top: 2.25rem
  }

@media (min-width: 1024px) {

.EditListingActions_root__iB5tj::before {
      width: 31.5rem;
      width: 31.5rem
  }
    }

@media (min-width: 1280px) {

.EditListingActions_root__iB5tj::before {
      width: 43.75rem;
      width: 43.75rem
  }
    }

@media (min-width: 768px) {

.EditListingActions_root__iB5tj {
    /* Think of this as margin-top + padding-top */
    padding-top: calc(
      3rem + 1.5rem);
    padding-top: calc(
      3rem + 1.5rem);
    margin-bottom: 3rem;
    margin-bottom: 3rem
}

    .EditListingActions_root__iB5tj::before {
      /* Think of this as margin-top */
      top: 3rem;
      top: 3rem;
    }
  }

.EditListingActions_root__iB5tj {
  /* End: Get the margin/padding/border sorted */

  /* Start: How to display the children */
  flex-direction: column;
  align-items: center
}

@media (min-width: 768px) {

.EditListingActions_root__iB5tj {
    flex-direction: row
}
  }

.EditListingActions_root__iB5tj > .EditListingActions_backAction__11aJo {
    display: none;
  }

.EditListingActions_root__iB5tj > .EditListingActions_skipAction__BMETG {
    order: 2;
    margin-top: 1.5rem;
    margin-top: 1.5rem;
  }

.EditListingActions_root__iB5tj > .EditListingActions_forwardAction__1kkW2 {
    order: 1;
  }

@media (min-width: 768px) {
    .EditListingActions_root__iB5tj > .EditListingActions_backAction__11aJo {
      display: block;
      order: 1;
    }

    .EditListingActions_root__iB5tj > .EditListingActions_skipAction__BMETG {
      order: 2;
      margin-top: 0;
      margin-left: auto;
      margin-right: 1.5rem;
      margin-right: 1.5rem;
    }

    .EditListingActions_root__iB5tj > .EditListingActions_forwardAction__1kkW2 {
      order: 3;
    }

    .EditListingActions_root__iB5tj.EditListingActions_withoutSkipAction__1OEEn > .EditListingActions_forwardAction__1kkW2 {
      margin-left: auto;
    }
  }

.EditListingActions_root__iB5tj {
  /* End: How to display the children */
}

@media (min-width: 1024px) {

.EditListingActions_forwardAction__1kkW2 {
    margin-right: 7.75rem;
    margin-right: 7.75rem
}
  }

@media (min-width: 1280px) {

.EditListingActions_forwardAction__1kkW2 {
    margin-right: 11rem;
    margin-right: 11rem
}
  }

.EditListingActions_backAction__11aJo {
}

.EditListingActions_skipAction__BMETG {
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.EditListingAvailabilityPanel_root__2oJW3 {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.EditListingAvailabilityPanel_form__3S5yz {
  flex-grow: 1;
}

.EditListingAvailabilityPanel_title__1ggDm {
  margin-bottom: 19px;
}

@media (min-width: 1024px) {
  .EditListingAvailabilityPanel_title__1ggDm {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingError_error__2i-bT {
  color: #ed3615;
  color: #ed3615;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingFieldWrapper_root__1zXlG {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.EditListingFieldWrapper_root__1zXlG {
    margin-top: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 1.25rem;
    margin-bottom: 1.25rem
}
  }

.EditListingFieldWrapper_root__1zXlG:last-child {
    margin-bottom: 0;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingNamedSkipLink_root__2HUY5 {
  display: inline-flex;
  align-items: center;
  color: #718096;
  color: #718096;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 600;
  font-weight: 600;
  text-decoration: underline;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingPanel_root__2nmp0 {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingPanelHeading_root__2X8N1 {
  color: #1a202c;
  color: #1a202c;
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-size: 1.5rem;
  font-size: 1.5rem;
}

@media (min-width: 768px) {

.EditListingPanelHeading_root__2X8N1 {
  font-size: 2.25rem;
  font-size: 2.25rem;
}
    }

.EditListingPanelHeading_root__2X8N1 {
  margin: 0;
}

.EditListingPanelHeading_headingSpacing__1dP-d {
  margin: 0 0 1.5rem 0;
  margin: 0 0 1.5rem 0;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingPanelSubheading_root__1m-7M {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 600;
  font-weight: 600;
  color: #1a202c;
  color: #1a202c;

  margin: 1rem 0 1.25rem 0;

  margin: 1rem 0 1.25rem 0
}

@media (min-width: 768px) {

.EditListingPanelSubheading_root__1m-7M {
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingPhotoPreviews_root__2ihk2 {
}

.EditListingPhotoPreviews_rootIsSorting__ipEFj * {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

.EditListingPhotoPreviews_photoPreviewContainer__2VK6B {
  z-index: 60;
  background: #fff;
  background: #fff;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem;
  margin: 0 calc(-1 * 0.5rem);
  margin: 0 calc(-1 * 0.5rem)
}

.EditListingPhotoPreviews_photoPreviewContainer__2VK6B:last-child {
    margin-bottom: 0;
  }

.EditListingPhotoPreviews_photoPreviewContainerInner__234Mf {
  padding: 0.5rem;
  padding: 0.5rem;
  display: flex;
}

.EditListingPhotoPreviews_photoPreviewContainerIsSorting__30LoP {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  transform: scale(1.05)
}

.EditListingPhotoPreviews_photoPreviewContainerIsSorting__30LoP .EditListingPhotoPreviews_photoPreviewContainerInner__234Mf {
  }

.EditListingPhotoPreviews_photoPreviewImageContainer__3jRdl {
  position: relative;
  width: 86px;
  height: 86px;
  flex-grow: 0;
  flex-shrink: 0;
}

.EditListingPhotoPreviews_photoPreviewImage__1NEKp {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  border-radius: 5px;
  font-size: 0.75rem;
  font-size: 0.75rem;
}

.EditListingPhotoPreviews_photoPreviewImageFade__2OrfG {
  opacity: 0.5;
}

.EditListingPhotoPreviews_photoPreviewDeleteImage__3pW4a {
  cursor: pointer;
  position: absolute;
  top: calc(0px - (1.75rem / 2));
  top: calc(0px - (1.75rem / 2));
  right: calc(0px - (1.75rem / 2));
  right: calc(0px - (1.75rem / 2));
  background: #fff;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  width: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.EditListingPhotoPreviews_photoPreviewDeleteImageIcon__2HN7Z {
  width: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  height: 0.5rem;
}

.EditListingPhotoPreviews_photoPreviewSpinner__1Xhis {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.EditListingPhotoPreviews_photoPreviewCaptionContainer__2ziTG {
  flex-grow: 1;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
  align-self: stretch;
}

.EditListingPhotoPreviews_photoPreviewCaptionContainerWithTags__3dD0Q {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.EditListingPhotoPreviews_photoPreviewCaptionInputWrapper__2Z-hp {
  height: 100%;
}

.EditListingPhotoPreviews_photoPreviewCaptionInputWrapperWithTags__cMbY- {
  height: 5.25rem;
  height: 5.25rem;
}

.EditListingPhotoPreviews_photoPreviewCaptionInput__2bjpL {
  display: block;
  min-height: auto;
  height: 100%;
}

.EditListingPhotoPreviews_sortHandleContainer__1-yXm {
  flex-grow: 0;
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
}

.EditListingPhotoPreviews_sortHandleContainerWithTags__3pHSO {
  height: 5.25rem;
  height: 5.25rem;
}

.EditListingPhotoPreviews_sortHandle__3vgJ_ {
  cursor: pointer
}

.EditListingPhotoPreviews_sortHandle__3vgJ_ g {
    stroke: #cbd5e0;
    stroke: #cbd5e0;
  }

.EditListingPhotoPreviews_error__2BAVd {
  color: #ed3615;
  color: #ed3615;
  margin: 0.5rem 0 0 0;
  margin: 0.5rem 0 0 0;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.EditListingPhotoPreviews_photoPreviewTagsContainer__3QfwA {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  margin-top: 0.5rem;
  margin-top: 0.5rem;
}

.EditListingPhotoPreviews_photoPreviewTagsItem__T1ztX {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-size: 0.75rem;
  color: #1a202c;
  color: #1a202c;
  padding: 0.25rem 1rem;
  padding: 0.25rem 1rem;
  border-radius: 10px;
  border-radius: 10px;
  background-color: #edf2f7;
  background-color: #edf2f7;
  white-space: nowrap;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingPhotoLibraryPreviews_root__3mc-A {
}

.EditListingPhotoLibraryPreviews_rootIsSorting__LFe9B * {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

.EditListingPhotoLibraryPreviews_photoPreviewContainer__3iO-G {
  background: #fff;
  background: #fff;
  margin: 0 calc(-1 * 0.5rem);
  margin: 0 calc(-1 * 0.5rem);
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

.EditListingPhotoLibraryPreviews_photoPreviewContainer__3iO-G:last-child {
    margin-bottom: 0;
  }

.EditListingPhotoLibraryPreviews_photoPreviewContainerInner__3dLr- {
  padding: 0.5rem;
  padding: 0.5rem;
  display: flex;
}

.EditListingPhotoLibraryPreviews_photoPreviewContainerIsSorting__hWEKe {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  transform: scale(1.05)
}

.EditListingPhotoLibraryPreviews_photoPreviewContainerIsSorting__hWEKe .EditListingPhotoLibraryPreviews_photoPreviewContainerInner__3dLr- {
  }

.EditListingPhotoLibraryPreviews_photoPreviewImageContainer__1R704 {
  position: relative;
  width: 5.25rem;
  width: 5.25rem;
  height: 5.25rem;
  height: 5.25rem;
  flex-grow: 0;
  flex-shrink: 0;
}

.EditListingPhotoLibraryPreviews_photoPreviewImage__2youe {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  border-radius: 5px;
  font-size: 0.75rem;
  font-size: 0.75rem;
}

.EditListingPhotoLibraryPreviews_photoPreviewImageFade__1qzPz {
  opacity: 0.5;
}

.EditListingPhotoLibraryPreviews_photoPreviewImageLink__2nR9- {
  text-decoration: none;
}

.EditListingPhotoLibraryPreviews_photoPreviewDeleteImage__10dif {
  cursor: pointer;
  position: absolute;
  top: calc(0px - (1.75rem / 2));
  top: calc(0px - (1.75rem / 2));
  right: calc(0px - (1.75rem / 2));
  right: calc(0px - (1.75rem / 2));
  background: #fff;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  width: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.EditListingPhotoLibraryPreviews_photoPreviewDeleteImageIcon__3OAG8 {
  width: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  height: 0.5rem;
}

.EditListingPhotoLibraryPreviews_photoPreviewSpinner__1rhnn {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.EditListingPhotoLibraryPreviews_photoPreviewCaptionContainer__35FBp {
  flex-grow: 1;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.EditListingPhotoLibraryPreviews_photoPreviewCaptionInputWrapper__3k-X9 {
  height: 5.25rem;
  height: 5.25rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.EditListingPhotoLibraryPreviews_photoPreviewCaptionInput__INKtL {
  display: block;
  min-height: auto;
  height: 100%;
}

.EditListingPhotoLibraryPreviews_sortHandleContainer__14FwF {
  flex-grow: 0;
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
  height: 5.25rem;
  height: 5.25rem;
}

.EditListingPhotoLibraryPreviews_sortHandle__3KDww {
  cursor: pointer
}

.EditListingPhotoLibraryPreviews_sortHandle__3KDww g {
    stroke: #cbd5e0;
    stroke: #cbd5e0;
  }

.EditListingPhotoLibraryPreviews_error__OZWWk {
  color: #ed3615;
  color: #ed3615;
  margin: 0.5rem 0 0 0;
  margin: 0.5rem 0 0 0;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.EditListingPhotoLibraryPreviews_photoPreviewTagsContainer__3_CuA {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.EditListingPhotoLibraryPreviews_photoPreviewTagsLinkContainer__3oJYW {
  display: flex;
  align-items: center;
}

.EditListingPhotoLibraryPreviews_photoPreviewTagsIcon__2sw8L {
  height: 1rem;
  height: 1rem;
  width: 1rem;
  width: 1rem;
}

.EditListingPhotoLibraryPreviews_photoPreviewTagsLink__2sxjJ {
  text-decoration: underline;
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-left: 0.25rem;
  margin-left: 0.25rem;
}

.EditListingPhotoLibraryPreviews_tagsTooltipItemBase__1-B6s {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-size: 0.75rem;
  border-radius: 20px;
  border-radius: 20px;
  border: 1px solid #cbd5e0;
  border: 1px solid #cbd5e0;
  padding: 0.5rem 0.75rem;
  padding: 0.5rem 0.75rem;
  white-space: nowrap
}

.EditListingPhotoLibraryPreviews_tagsTooltipItemBase__1-B6s:hover {
    text-decoration: none;
  }

.EditListingPhotoLibraryPreviews_photoPreviewTagsItem__2CTn- {
  color: #fff;
  color: #fff;
  background-color: #1a202c;
  background-color: #1a202c;
}

.EditListingPhotoLibraryPreviews_photoPreviewTagsItemClose__1Liyo {
  cursor: pointer
}

.EditListingPhotoLibraryPreviews_photoPreviewTagsItemClose__1Liyo > svg > path {
    fill: #fff;
    fill: #fff;
  }

.EditListingPhotoLibraryPreviews_photoPreviewTagsCloseIcon__2clRC {
  width: 10px;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
}

.EditListingPhotoLibraryPreviews_carouselModalScrollLayer__2t-H4 {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.EditListingPhotoLibraryPreviews_carouselModalContainer__3hXQu {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  padding: 1.5rem;
}

.EditListingPhotoLibraryPreviews_contentContainer__2GCnp {
  display: flex;
  position: relative
}

@media (min-width: 1024px) {

.EditListingPhotoLibraryPreviews_contentContainer__2GCnp {
    margin: 0 auto 3.75rem;
    margin: 0 auto 3.75rem;
    justify-content: space-between
}
  }

@media (min-width: 768px) {

.EditListingPhotoLibraryPreviews_carouselModalImage__2QSVe {
    max-height: calc(100vh - 10rem);
    max-height: calc(100vh - 10rem)
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.EditListingPhotosPanel_root__2LAoJ {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.EditListingPhotosPanel_form__3O2Pw {
  flex-grow: 1;
}

/* ================ Modal ================ */

.EditListingPhotosPanel_modalHeaderWrapper__R9AMV {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0
}

@media (min-width: 768px) {

.EditListingPhotosPanel_modalHeaderWrapper__R9AMV {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0
}
  }

/* ================ Typography ================ */

/* Title of the modal */

.EditListingPhotosPanel_modalTitle__30iB2 {
  font-weight: 600;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  font-weight: 700;
  font-weight: 700;
}

@media (min-width: 768px) {

.EditListingPhotosPanel_modalTitle__30iB2 {
  font-weight: 600;
  font-weight: 600;
  line-height: 40px;
  margin: 0;
}
    }

/* Paragraph for the Modal */

.EditListingPhotosPanel_modalMessage__1DZz7 {
  margin: 18px 0 0 0;
}

@media (min-width: 768px) {

.EditListingPhotosPanel_modalMessage__1DZz7 {
  margin: 24px 0 0 0;
}
    }

/* This is the title for the Edit Photos component */

.EditListingPhotosPanel_title__2djMe {
  margin-bottom: 19px
}

@media (min-width: 1024px) {

.EditListingPhotosPanel_title__2djMe {
    margin-bottom: 38px;
    padding: 1px 0 7px 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.EditListingPhotoLibraryPanel_root__MaMIh {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.EditListingPhotoLibraryPanel_form__2mFhT {
  flex-grow: 1;
}

/* ================ Modal ================ */

.EditListingPhotoLibraryPanel_modalHeaderWrapper__1SSqg {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0
}

@media (min-width: 768px) {

.EditListingPhotoLibraryPanel_modalHeaderWrapper__1SSqg {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0
}
  }

/* ================ Typography ================ */

/* Title of the modal */

.EditListingPhotoLibraryPanel_modalTitle__2Vk3k {
  font-weight: 600;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  font-weight: 700;
  font-weight: 700;
}

@media (min-width: 768px) {

.EditListingPhotoLibraryPanel_modalTitle__2Vk3k {
  font-weight: 600;
  font-weight: 600;
  line-height: 40px;
  margin: 0;
}
    }

/* Paragraph for the Modal */

.EditListingPhotoLibraryPanel_modalMessage__35GEb {
  margin: 18px 0 0 0;
}

@media (min-width: 768px) {

.EditListingPhotoLibraryPanel_modalMessage__35GEb {
  margin: 24px 0 0 0;
}
    }

/* This is the title for the Edit Photos component */

.EditListingPhotoLibraryPanel_title__2iTcJ {
  margin-bottom: 19px
}

@media (min-width: 1024px) {

.EditListingPhotoLibraryPanel_title__2iTcJ {
    margin-bottom: 38px;
    padding: 1px 0 7px 0
}
  }

.EditListingPhotoLibraryPanel_uploadRestrictions__3Ghy4 {
  margin-bottom: 2.5rem;
  margin-bottom: 2.5rem;
}

.EditListingPhotoLibraryPanel_uploadRestrictionText__3aCz- {
  margin: 0;
  color: #4a5569;
  color: #4a5569;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-weight: 600;
}

.EditListingPhotoLibraryPanel_tabContainer__1QMBb {
  display: flex;
  align-items: center;
}

.EditListingPhotoLibraryPanel_tabCount__3nz_L {
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin-left: 0.25rem;
  margin-left: 0.25rem;
}

.EditListingPhotoLibraryPanel_imagesContainer__14xwC {
  margin-top: 1.25rem;
  margin-top: 1.25rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingProgressBar_root__32XIy {
}

.EditListingProgressBar_labels__2CSlR {
  display: flex;
  justify-content: space-between;
  align-items: center
}

@media (min-width: 1024px) {

.EditListingProgressBar_labels__2CSlR {
    align-items: initial
}
  }

.EditListingProgressBar_pageLabel__KXdui {
  font-size: 1rem;
  font-size: 1rem;
  color: #1a202c;
  color: #1a202c;
  font-weight: bold;
  margin-top: 0.75rem;
  margin-top: 0.75rem
}

@media (min-width: 1024px) {

.EditListingProgressBar_pageLabel__KXdui {
    font-size: 1.125rem;
    font-size: 1.125rem;
    margin-top: 1.25rem;
    margin-top: 1.25rem
}
  }

.EditListingProgressBar_percentLabel__x26iF {
  font-size: 0.75rem;
  font-size: 0.75rem;
  color: #4a5569;
  color: #4a5569;
  font-weight: bold;
  margin-top: 0.75rem;
  margin-top: 0.75rem
}

@media (min-width: 1024px) {

.EditListingProgressBar_percentLabel__x26iF {
    font-size: 0.875rem;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    margin-top: 0.25rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingAccessPhotoLibrary_fieldHeader__Ley8z {
  font-size: 1.125rem;
  font-size: 1.125rem;
}

@media (min-width: 768px) {

.EditListingAccessPhotoLibrary_fieldHeader__Ley8z {
  font-size: 1.5rem;
  font-size: 1.5rem;
}
    }

.EditListingAccessPhotoLibrary_fieldHeader__Ley8z {
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.EditListingAccessPhotoLibrary_subheader__oHzoy {
  display: none
}

@media (min-width: 768px) {

.EditListingAccessPhotoLibrary_subheader__oHzoy {
    display: block;
    font-size: 0.875rem;
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    margin-top: 0
}
  }

.EditListingAccessPhotoLibrary_photoLibraryContainer__xqYG8 {
  width: 100%;
  margin: 0;
  border-radius: 0;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding: 1rem;
  background-color: #fff;
  background-color: #fff;
  border-bottom: none;
  flex-grow: 0;
  min-height: auto;
  min-width: 75%;
  min-width: 75%;
  overflow: hidden
}

@media (min-width: 768px) {

.EditListingAccessPhotoLibrary_photoLibraryContainer__xqYG8 {
    padding: 1.5rem;
    padding: 1.5rem
}
  }

.EditListingAccessPhotoLibrary_photoPreviews__3lqIm {
  margin-top: 2rem;
  margin-top: 2rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingSectionHeading_root__2v_G6 {
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.EditListingSectionHeading_description__1EImP {
  margin: 0 0 0.75rem 0;
  margin: 0 0 0.75rem 0;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionPackageCard_root__I4L8U {
  display: block;
  position: relative;
  color: #1a202c;
  color: #1a202c;
  transition: ease-in 0.2s;
  transition: ease-in 0.2s;
  border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 2.5rem;
  margin-bottom: 2.5rem
}

.SectionPackageCard_root__I4L8U:hover {
    text-decoration: none;
  }

.SectionPackageCard_rootResponsive__r9WtA {
}

@media (min-width: 768px) {

.SectionPackageCard_rootResponsive__r9WtA {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    background-color: #fff;
    background-color: #fff
}

    .SectionPackageCard_rootResponsive__r9WtA:hover {
      box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    }
  }

.SectionPackageCard_linkRoot__3HvD0:hover {
    text-decoration: none;
  }

.SectionPackageCard_imageInner__3CM2I {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  overflow: hidden;
  max-height: 200px;
  position: relative
}

.SectionPackageCard_imageInner__3CM2I::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.16),
      rgba(0, 0, 0, 0.13),
      rgba(0, 0, 0, 0.09),
      rgba(0, 0, 0, 0.06),
      rgba(0, 0, 0, 0.04),
      rgba(0, 0, 0, 0.02),
      rgba(0, 0, 0, 0.01),
      rgba(0, 0, 0, 0)
    );
    border-radius: inherit;
    pointer-events: none;
    z-index: 1;
  }

.SectionPackageCard_imageInnerBox__3N1HN {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: auto;
  width: initial;
  height: auto;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;
}

.SectionPackageCard_imageRoot__1zrgU {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border-radius: 10px;
  object-fit: cover;
}

.SectionPackageCard_imageWrapper__1xcDj {
  border-radius: 10px;
  border-radius: 10px;
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.SectionPackageCard_imageWrapperResponsive__3ZBUd {
}

@media (min-width: 768px) {

.SectionPackageCard_imageWrapperResponsive__3ZBUd {
    width: 41%;
    max-width: 19rem;
    max-width: 19rem;
    max-height: 200px;
    flex-shrink: 0;
    align-self: stretch
}
  }

.SectionPackageCard_imageRootResponsive__342Hk {
}

@media (min-width: 768px) {

.SectionPackageCard_imageRootResponsive__342Hk {
    position: static;
    width: 100%;
    height: 100%;
    object-fit: cover
}
  }

.SectionPackageCard_infoContainer__2D2Rn {
  display: flex;
  justify-content: space-between;
  margin-top: 0.75rem;
  margin-top: 0.75rem;
  flex-wrap: wrap;
  position: relative;
}

.SectionPackageCard_infoContainerResponsive__13amF {
}

@media (min-width: 768px) {

.SectionPackageCard_infoContainerResponsive__13amF {
    flex-wrap: nowrap;
    width: calc(100% - 3rem);
    width: calc(100% - 3rem);
    margin: 0.5rem 1rem;
    margin: 0.5rem 1rem;
    flex-direction: column
}
  }

.SectionPackageCard_infoMain__170fI {
  display: flex;
  flex-direction: column;
}

.SectionPackageCard_infoMainResponsive__C7P6R {
}

@media (min-width: 768px) {

.SectionPackageCard_infoMainResponsive__C7P6R {
    margin-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    flex-basis: auto
}
  }

.SectionPackageCard_infoHeader__1mn8J {
  font-size: 0.75rem;
  font-size: 0.75rem;
  color: #718096;
  color: #718096;
}

.SectionPackageCard_infoHeaderResponsive__3sgNE {
}

@media (min-width: 768px) {

.SectionPackageCard_infoHeaderResponsive__3sgNE {
    font-size: 0.875rem;
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
    margin-bottom: 0.75rem
}
  }

.SectionPackageCard_infoTitle__2cZx7 {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}

.SectionPackageCard_infoTitleResponsive__1kcbQ {
}

@media (min-width: 768px) {

.SectionPackageCard_infoTitleResponsive__1kcbQ {
    font-size: 1.125rem;
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
    margin-bottom: 0.25rem
}
  }

.SectionPackageCard_priceInfo__P-cSJ {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
  flex-basis: 30%;
}

.SectionPackageCard_priceInfoResponsive__azvqz {
}

@media (min-width: 768px) {

.SectionPackageCard_priceInfoResponsive__azvqz {
    flex-basis: auto;
    align-items: center;
    flex-direction: row;
    margin-bottom: 1.5rem;
    margin-bottom: 1.5rem
}
  }

.SectionPackageCard_priceInfoLabel__11IS0 {
  margin-bottom: 0;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.SectionPackageCard_priceInfoLabelResponsive__1x5Rm {
}

@media (min-width: 768px) {

.SectionPackageCard_priceInfoLabelResponsive__1x5Rm {
    font-size: 0.875rem;
    font-size: 0.875rem
}
  }

.SectionPackageCard_priceAmount__3gFik {
  color: #1a202c;
  color: #1a202c;
  font-size: 0.875rem;
  font-size: 0.875rem
}

.SectionPackageCard_priceAmount__3gFik span:first-of-type {
    font-weight: 700;
    font-weight: 700;
  }

.SectionPackageCard_priceAmountResponsive__13JCf {
}

@media (min-width: 768px) {

.SectionPackageCard_priceAmountResponsive__13JCf {
    font-size: 0.875rem;
    font-size: 0.875rem
}
  }

.SectionPackageCard_infoFooter__1fJcg {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.SectionPackageCard_infoFooterResponsive__1A4ot {
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem
}

@media (min-width: 768px) {

.SectionPackageCard_infoFooterResponsive__1A4ot {
    font-size: 0.875rem;
    font-size: 0.875rem
}
  }

.SectionPackageCard_badgesContainer__3gaNg {
  flex-basis: 100%;
  margin-top: 0.25rem;
  margin-top: 0.25rem
}

@media (min-width: 768px) {

.SectionPackageCard_badgesContainer__3gaNg {
    flex-basis: auto;
    margin-top: 0
}
  }

.SectionPackageCard_menuLink__3ZRUB {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0.5rem;
  right: 0.5rem;
  top: 0.5rem;
  top: 0.5rem;
  width: 2rem;
  width: 2rem;
  height: 2rem;
  height: 2rem;
  color: #1a202c;
  color: #1a202c;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  border-radius: 50%;
  z-index: 10;
  z-index: 10
}

.SectionPackageCard_menuLink__3ZRUB > svg {
    width: 1.5rem;
    width: 1.5rem;
  }

@media (min-width: 768px) {

.SectionPackageCard_menuLink__3ZRUB {
    background-color: transparent
}
  }

.SectionPackageCard_menuIconWrapper__36dm_ {
  position: absolute;
  top: 0.25rem;
  top: 0.25rem;
  right: 1.25rem;
  right: 1.25rem;
  width: 1.5rem;
  width: 1.5rem;
  z-index: 10;
  z-index: 10;
}

.SectionPackageCard_mainInfoLink__2xylj {
  width: 100%
}

.SectionPackageCard_mainInfoLink__2xylj:hover {
    text-decoration: none;
  }

.SectionPackageCard_menuItemsWrapper__1ztI8 {
  font-weight: 600;
  font-weight: 600
}

.SectionPackageCard_menuItemsWrapper__1ztI8 > li {
    margin-bottom: 0.25rem;
    margin-bottom: 0.25rem;
  }

.SectionPackageCard_menuItemLink__1HLQm {
  text-decoration: none;
  color: #1a202c;
  color: #1a202c;
}

.SectionPackageCard_packageLoadingWrapper__W4lz- {
  display: flex;
  flex-direction: column;
  max-height: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.SectionPackageCard_fetchingStatus__3b-76 {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  position: relative;
  bottom: 1.25rem;
  bottom: 1.25rem;
}

.SectionPackageCard_titleLink__1tyMO {
  text-decoration: underline;
}

.SectionPackageCard_favoriteWrapper__18KfO {
  position: absolute;
  top: 0.75rem;
  top: 0.75rem;
  right: 0.75rem;
  right: 0.75rem;
  z-index: 1;
}

.SectionPackageCard_availablePackageWrapper__RJvbp {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SectionPackageCard_availablePackageBlurWrapper__Lmw4r {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SectionPackageCard_availablePackageBlur__2UwiA {
  background: url(/static/media/rectangle.f90a1a2f.png);
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border-radius: 10px;
  display: block;
}

.SectionPackageCard_availablePackageText__2ETCU {
  font-size: 1.125rem;
  font-size: 1.125rem
}

@media (min-width: 768px) {

.SectionPackageCard_availablePackageText__2ETCU {
  font-size: 1.5rem;
  font-size: 1.5rem
}
    }

.SectionPackageCard_availablePackageText__2ETCU {
  width: 100%;
  transition: ease-in-out 0.1s;
  color: #fff;
  color: #fff;
  display: flex;
  justify-content: center;
  z-index: 10;
  z-index: 10;
  text-align: center
}

.SectionPackageCard_availablePackageText__2ETCU > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.SectionPackageCard_carouselBackIcon__34UzY {
  transform: translateX(100%);
}

.SectionPackageCard_carouselNextIcon__3BY4e {
  transform: translateX(-100%);
}

.SectionPackageCard_earlyAccessBadge__2q1Ca {
  color: #1a202c;
  color: #1a202c;
  background-color: #fad44e;
  background-color: #fad44e;
  border-radius: 20px;
  border-radius: 20px;
  font-size: 0.625rem;
  font-size: 0.625rem;
  font-weight: 700;
  font-weight: 700;
  padding: 0.25rem 0.75rem;
  padding: 0.25rem 0.75rem;
  z-index: 15;
  z-index: 15;
  position: absolute;
  right: 0.75rem;
  right: 0.75rem;
  bottom: 0.75rem;
  bottom: 0.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FavoritePackage_root__3S_Wz {
  position: relative;
}

.FavoritePackage_circleFav__1M51z {
  cursor: pointer;
  width: 2.25rem;
  width: 2.25rem;
  height: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  color: initial;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FavoritePackage_boldHeading__SG2zN {
  font-weight: bold;
  padding-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.FavoritePackage_tooltipButton__2SJAi {
  color: #1a202c;
  font-size: 1rem;
  font-size: 1rem;
  font-weight: bold;
  width: 9.5rem;
  width: 9.5rem;
  height: 2.5rem;
  height: 2.5rem;
  margin: 1.5rem 11.5rem 0 0;
  margin: 1.5rem 11.5rem 0 0;
  padding: 0.5rem 3rem 0.75rem;
  padding: 0.5rem 3rem 0.75rem;
}

.FavoritePackage_icon__18b2d {
  width: 1rem;
  width: 1rem;
}

.FavoritePackage_iconFull__3DRjy {
  fill: #ca2d10;
  fill: #ca2d10
}

.FavoritePackage_iconFull__3DRjy > path {
    fill: #ca2d10;
    fill: #ca2d10;
  }

.FavoritePackage_favButton__39TYK {
  text-decoration: underline
}

@media (min-width: 768px) {

.FavoritePackage_favButton__39TYK {
    margin-left: 1.75rem;
    margin-left: 1.75rem
}
  }

.FavoritePackage_favButton__39TYK .FavoritePackage_icon__18b2d {
    width: 1rem;
    width: 1rem
  }

@media (min-width: 768px) {

.FavoritePackage_favButton__39TYK .FavoritePackage_icon__18b2d {
      height: 1rem;
      height: 1rem;
      width: auto;
      width: initial
  }
    }

.FavoritePackage_favButtonIconFull__2DjOl {
  fill: #fff;
  fill: #fff
}

.FavoritePackage_favButtonIconFull__2DjOl > path {
    fill: #fff;
    fill: #fff;
  }

.FavoritePackage_buttonBody__dgaQX {
  display: flex;
  align-items: center
}

@media (min-width: 768px) {

.FavoritePackage_buttonBody__dgaQX > svg {
      margin-right: 0.5rem;
      margin-right: 0.5rem
  }
    }

.FavoritePackage_buttonLabel__14ZJV {
  display: none
}

@media (min-width: 768px) {

.FavoritePackage_buttonLabel__14ZJV {
    display: block
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FavoritePackageTooltip_root__2rbbf {
  position: absolute;
  max-width: 24.25rem;
  max-width: 24.25rem;
  opacity: 0;
  transition: opacity 0.15s linear;
  background-color: #1a202c;
  color: #ffffff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  border-radius: 0.25rem;
  padding-top: 2.5rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-bottom: 2.5rem;
  padding-left: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 2.5rem;
  padding-right: 2.5rem;
  z-index: 60;
  z-index: 60
}

@media (min-width: 768px) {

.FavoritePackageTooltip_root__2rbbf {
    position: fixed;
    max-width: 23.25rem;
    max-width: 23.25rem
}
  }

.FavoritePackageTooltip_root__2rbbf::before {
  content: '';
  position: absolute;
  top: 0;
  left: 86.7%; /* default for mobile */
  transform: translateX(-50%);
  margin-top: -9px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #1a202c;
  border-bottom: 10px solid #1a202c
}

@media (min-width: 768px) {

.FavoritePackageTooltip_root__2rbbf::before {
    left: 78%
}
  }

.FavoritePackageTooltip_root__2rbbf.FavoritePackageTooltip_tooltipVisible__2-htu {
  opacity: 1;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingPackagesSection_packageButtonWrapper__JZExC {
  margin-bottom: 2rem;
  margin-bottom: 2rem;
}

.EditListingPackagesSection_packageButton__7lDTH {
  border: solid 1px #1a202c;
  border: solid 1px #1a202c;
  padding: 0.5rem 3rem 0.5rem 2rem;
  padding: 0.5rem 3rem 0.5rem 2rem;
  color: #1a202c;
  color: #1a202c;
  width: 16.75rem;
  width: 16.75rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  border-radius: 25px
}

.EditListingPackagesSection_packageButton__7lDTH:hover {
    background-color: #f7fafc;
    background-color: #f7fafc;
    color: #1a202c;
    color: #1a202c;
  }

.EditListingPackagesSection_packagesWrapper__DcGgK {
  display: flex;
  flex-wrap: wrap;
}

.EditListingPackagesSection_packageTabsWrapper__TGqHE {
  width: 100%;
}

.EditListingPackagesSection_packagesTabsContent__18vr_ {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.5rem;
  margin-top: 1.5rem
}

@media (min-width: 768px) {

.EditListingPackagesSection_packagesTabsContent__18vr_ {
    margin: 1rem calc(-1 * 1rem) 0 calc(-1 * 1rem);
    margin: 1rem calc(-1 * 1rem) 0 calc(-1 * 1rem)
}
  }

.EditListingPackagesSection_buttonFlex__waCpx {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-weight: 700;
}

.EditListingPackagesSection_plusCircle__2KjG6 {
  width: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  height: 1.75rem;
  color: #1a202c;
  color: #1a202c;
  margin-right: 1.25rem;
  margin-right: 1.25rem;
}

.EditListingPackagesSection_error__2dDfj {
  color: #ed3615;
  color: #ed3615;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EditListingUploadProgressBar_root__3Zr72 {
}

.EditListingUploadProgressBar_labels__1Bq2c {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.EditListingUploadProgressBar_label__1ue-g {
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #1a202c;
  color: #1a202c;
  font-weight: 600;
  font-weight: 600;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

@media (min-width: 768px) {

.BookingListingBreakdownSection_breakdownWrapper__3TEzW {
    padding: 1.25rem 1.25rem 1.25rem 0;
    padding: 1.25rem 1.25rem 1.25rem 0;
    flex-basis: 38%
}
  }

.BookingListingBreakdownSection_breakdown__3tqhd {
  position: relative;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem
}

@media (min-width: 768px) {

.BookingListingBreakdownSection_breakdown__3tqhd {
    margin-bottom: 0;
    padding: 1.75rem 1.25rem 1.75rem 1.5rem;
    padding: 1.75rem 1.25rem 1.75rem 1.5rem;
    border-radius: 25px;
    border-radius: 25px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e2e8f0;
    border: solid 1px #e2e8f0
}
  }

@media (min-width: 768px) {

.BookingListingBreakdownSection_aspectWrapper__2gRdJ {
    display: none
}
  }

.BookingListingBreakdownSection_detailsAspectWrapper__2J6K9 {
  display: none
}

@media (min-width: 768px) {

.BookingListingBreakdownSection_detailsAspectWrapper__2J6K9 {
    display: block;
    position: relative;
    padding-bottom: 66.666667%;
    padding-bottom: 66.666667%;
    background-color: #a1aec1;
    background-color: #a1aec1;
    border-radius: 10px;
    border-radius: 10px;
    overflow: hidden
}
  }

.BookingListingBreakdownSection_rootForImage__2zOuV {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover
}

@media (min-width: 768px) {

.BookingListingBreakdownSection_rootForImage__2zOuV {
    border-radius: 2px 2px 0 0
}
  }

.BookingListingBreakdownSection_avatarWrapper__3QHb0 {
  margin-left: 1.5rem;
  margin-left: 1.5rem;
  margin-top: calc(-1 * 2rem);
  margin-top: calc(-1 * 2rem);
  position: relative;
  display: block
}

@media (min-width: 768px) {

.BookingListingBreakdownSection_avatarWrapper__3QHb0 {
    margin-left: 0.75rem;
    margin-left: 0.75rem
}
  }

@media (min-width: 768px) {

.BookingListingBreakdownSection_avatarMobile__3OBp9 {
    display: none
}
  }

.BookingListingBreakdownSection_avatarDesktop__hHNYP {
  display: none
}

@media (min-width: 768px) {

.BookingListingBreakdownSection_avatarDesktop__hHNYP {
    display: block
}
  }

.BookingListingBreakdownSection_detailsHeadings__3E2th {
  display: none
}

@media (min-width: 768px) {

.BookingListingBreakdownSection_detailsHeadings__3E2th {
    display: block;
    font-weight: 700;
    font-weight: 700;
    font-size: 1.5rem;
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    margin-bottom: 1.25rem
}
  }

.BookingListingBreakdownSection_detailsTitle__1c9Sj {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  display: block;
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
  margin-top: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.BookingListingBreakdownSection_detailsLandownerName__3Wz2W {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: normal;
}

.BookingListingBreakdownSection_imageWrapper__7Whva {
  padding-bottom: calc(147 / 335 * 100%);
  position: relative;
  border-radius: 0.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.BookingListingBreakdownSection_imageWrapper__7Whva {
    border-radius: 1rem;
    border-radius: 1rem;
    padding-bottom: calc(211 / 488 * 100%)
}
  }

.BookingListingBreakdownSection_image__2Q8m1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.BookingListingBreakdownSection_authorWrapper__Rpby0 {
  display: flex;
  margin: 1rem 0 0 0;
  margin: 1rem 0 0 0
}

@media (min-width: 768px) {

.BookingListingBreakdownSection_authorWrapper__Rpby0 {
    margin: 0 0 0.5rem 0;
    margin: 0 0 0.5rem 0
}
  }

.BookingListingBreakdownSection_avatar__3UCtH {
  width: 3.25rem;
  width: 3.25rem;
  height: 3.25rem;
  height: 3.25rem;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.BookingListingBreakdownSection_authorNameWrapper__31xJM {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.BookingListingBreakdownSection_authorLabel__35tzc {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.BookingListingBreakdownSection_authorName__1t4TR {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-weight: 700;
}

.BookingListingBreakdownSection_starWrapper__21ol- {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
}

.BookingListingBreakdownSection_starIcon__13r8Z {
  height: 0.75rem;
  height: 0.75rem;
  width: 0.75rem;
  width: 0.75rem;
  margin-right: 0.25rem;
  margin-right: 0.25rem;
}

.BookingListingBreakdownSection_listingTitleLink__3nnjO {
  color: #1a202c;
  color: #1a202c;
  font-size: 1.25rem;
  font-size: 1.25rem;
}

.BookingListingBreakdownSection_titleInfoWrapper__1f3yP {
  display: none
}

@media (min-width: 768px) {

.BookingListingBreakdownSection_titleInfoWrapper__1f3yP {
    display: block;
    margin-bottom: 1.25rem;
    margin-bottom: 1.25rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.Footer_root__1b7pO {
  padding: 2rem 1rem;
  padding: 2rem 1rem;
  background-color: #f7fafc;
  background-color: #f7fafc;
  font-size: 0.75rem;
  font-size: 0.75rem
}

@media (min-width: 768px) {

.Footer_root__1b7pO {
    padding: 8rem 0;
    padding: 8rem 0;
    font-size: 0.875rem;
    font-size: 0.875rem
}
  }

.Footer_grid__2G2H7 {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-top: 1rem
}

@media (min-width: 768px) {

.Footer_grid__2G2H7 {
    flex-direction: row;
    justify-content: space-between
}
  }

.Footer_gridItem__3gYXp:nth-child(1) {
  order: 1
}

@media (min-width: 768px) {

.Footer_gridItem__3gYXp:nth-child(1) {
    order: -1;
    white-space: nowrap
}
  }

.Footer_links__2gjfv {
  display: grid;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(3, minmax(0, 150px));
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content);
  font-size: 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  font-weight: 600
}

@media (min-width: 768px) {

.Footer_links__2gjfv {
    margin: 0 3rem;
    margin: 0 3rem
}
  }

@media (min-width: 1024px) {

.Footer_links__2gjfv {
    font-size: 1rem;
    font-size: 1rem
}
  }

.Footer_link__8up7a {
  color: #2d3748;
  color: #2d3748;
}

.Footer_otherLinks__1CgNh {
  width: 8rem;
  width: 8rem;
  margin-top: 1rem;
  margin-top: 1rem;
  flex-shrink: 0;
}

.Footer_socialLinks__23e2o {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.Footer_description__3gCj4 {
  color: #1a202c;
  color: #1a202c;
  margin-top: 1rem;
  margin-top: 1rem
}

@media (min-width: 768px) {

.Footer_description__3gCj4 {
    margin-top: 0
}
  }

.Footer_icon__AHq_O {
  width: 1.5rem;
  width: 1.5rem
}

@media (min-width: 768px) {

.Footer_icon__AHq_O {
    width: 2rem;
    width: 2rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SpeciesCard_root__12FIB {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: ease-in 0.2s;
  transition: ease-in 0.2s;
  flex: 1 1
}

.SpeciesCard_root__12FIB:hover {
    text-decoration: none;
    transform: scale(1.02);
  }

.SpeciesCard_contentContainer__1a_Cu {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
}

.SpeciesCard_imageContainer__2Htj2 {
  position: relative;
  display: flex;
  align-items: center
}

@media (min-width: 768px) {

.SpeciesCard_imageContainer__2Htj2 {
    height: 196px
}
  }

.SpeciesCard_bg__elXow {
  width: 8rem;
  width: 8rem;
  height: 8rem;
  height: 8rem
}

@media (min-width: 768px) {

.SpeciesCard_bg__elXow {
    width: auto;
    width: initial;
    height: auto;
    height: initial
}
  }

.SpeciesCard_image__3ZxpE {
  position: absolute;
  top: calc(50% - (6rem / 2));
  top: calc(50% - (6rem / 2));
  left: calc(50% - (6rem / 2));
  left: calc(50% - (6rem / 2));
  width: 6rem;
  width: 6rem;
  height: 6rem;
  height: 6rem;
  z-index: 10;
  z-index: 10
}

@media (min-width: 768px) {

.SpeciesCard_image__3ZxpE {
    top: calc(50% - (10rem / 2));
    top: calc(50% - (10rem / 2));
    left: calc(50% - (10rem / 2));
    left: calc(50% - (10rem / 2));
    width: 10rem;
    width: 10rem;
    height: 10rem;
    height: 10rem
}
  }

.SpeciesCard_title__1EA7D {
  padding: 1.25rem;
  padding: 1.25rem;
  font-weight: 600;
  font-weight: 600;
  font-size: 1rem;
  font-size: 1rem;
  color: #1a202c;
  color: #1a202c;
  line-height: 1.25;
  line-height: 1.25;
  text-align: center
}

@media (min-width: 768px) {

.SpeciesCard_title__1EA7D {
    padding: 0.5rem;
    padding: 0.5rem;
    font-size: 1.5rem;
    font-size: 1.5rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* A simple technique I developed here: https://codesandbox.io/s/flexbox-with-gaps-simple-qu7wl
   These helpers can be used to construct a flexbox layout that supports grid gaps like css grid does.
   This is not as easy as it seems, especially with a responsive flex layout that can wrap, this solution adds
   margins to add flex items then uses negative margins to offset the margins at the edge so that they don't
   interfere with elements outside of this one

   Usage:

   JSX:

   <div className={css.filtersWrapper}>
     <div className={css.filtersInner}>
       <div className={css.filter}>Item 1</div>
       <div className={css.filter}>Item 2</div>
       <div className={css.filter}>Item 3</div>
     </div>
   </div>

   CSS:

   @import '../FlexHelper/FlexHelper.css';

   // Decorate the wrapper here as you wish but don't add padding
   .filtersWrapper {
     @apply --flexWrapper;
   }

   // Don't add much except flexbox configuration here
   .filtersInner {
     @apply --flexInner3;
     justify-content: center; // Or whatever flex stuff you want to add
   }

   // Decorate each item as you wish but don't add margins
   .filter {
     @apply --flexItem3;

     // You can add widths here for more of a grid-like look.
     // You need to use / add helpers though because the widths need to be calculated using the margin
     @apply --flexItem3Width50Percent;

     @media (--screen-md) {
       @apply --flexItem3Width25Percent;
     }
   }

   In this simple version the gaps cannot be configured to change at breakpoints but ...

   Extra classes for different configurations could be added here and reused elsewhere as needed

   Or it can be expended to support a nice Bootstrap-style grid: https://codesandbox.io/s/pmxn0pqpyq
   - This implementation can be responsive but every use on the app is the same

   Or it can be used like this when uncoupled from the limitations of CSS Modules: https://codesandbox.io/s/mz13k4mw4x
   - This implementation can change behavior on a per usage basis but it cannot practically be done in CSS Modules
     without using real CSS Custom Properties
*/

.SectionBySpecies_root__3NAyd {
  background-color: #f7fafc;
  background-color: #f7fafc;
  border-radius: 5px;
  border-radius: 5px;
  padding: 2.5rem 0;
  padding: 2.5rem 0;
}

.SectionBySpecies_title__SExtg {
  padding: 0 1rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.SectionBySpecies_title__SExtg {
    padding: 0;
    text-align: center
}
  }

.SectionBySpecies_carousel__22PDQ {
  overflow: hidden;
  padding: 1rem 0;
  padding: 1rem 0;
  margin: 0
}

@media (min-width: 768px) {

.SectionBySpecies_carousel__22PDQ {
    padding: 0 6rem;
    padding: 0 6rem;
    overflow: visible
}
  }

.SectionBySpecies_step__4420L {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-basis: 25%;
  padding: 0.5rem;
  padding: 0.5rem
}

@media (min-width: 768px) {

.SectionBySpecies_step__4420L {
    flex-basis: 25%;
    flex-basis: 25%
}
  }

.SectionBySpecies_boxesRoot__1KPNz {
  display: inline-block;
  width: 100%;
}

.SectionBySpecies_boxesInner__3FhF- {
  display: flex;
  flex-wrap: wrap;

    /* Stop the negative margins preventing clicks part 1.
       This part makes the negative margins (and all children) unclickable */
  pointer-events: none;

    /* Give the margin top the top/left by default. Then swap the margin from left to right for rtl languages
       which should prevent overflow and scrollbars both ways */
  margin: calc(0px - 0.75rem) 0 0 calc(0px - 0.75rem);
  margin: calc(0px - 0.75rem) 0 0 calc(0px - 0.75rem)
}

.SectionBySpecies_boxesInner__3FhF-:dir(rtl) {
  margin: calc(0px - 0.75rem) calc(0px - 0.75rem) 0 0;
  margin: calc(0px - 0.75rem) calc(0px - 0.75rem) 0 0;
    }

.SectionBySpecies_boxesInner__3FhF- {

  justify-content: center
}

@media (min-width: 768px) {

.SectionBySpecies_boxesInner__3FhF- {
    justify-content: start
}
  }

.SectionBySpecies_box__2ljqH {
  display: block;
    /* Stop the negative margins preventing clicks part 2.
       This part restores clicks for all the items and their children */
  pointer-events: auto;

    /* Give the margin top the top/left by default. Then swap the margin from left to right for rtl languages
       which should prevent overflow and scrollbars both ways */
  margin: 0.75rem 0 0 0.75rem;
  margin: 0.75rem 0 0 0.75rem
}

.SectionBySpecies_box__2ljqH:dir(rtl) {
  margin: 0.75rem 0.75rem 0 0;
  margin: 0.75rem 0.75rem 0 0;
    }

.SectionBySpecies_box__2ljqH {

  max-width: 130px;
  width: calc(50% - 0.75rem);
  width: calc(50% - 0.75rem)
}

@media (min-width: 640px) {

.SectionBySpecies_box__2ljqH {
  width: calc(25% - 0.75rem);
  width: calc(25% - 0.75rem)
}
  }

@media (min-width: 768px) {

.SectionBySpecies_box__2ljqH {
  width: calc(50% - 0.75rem);
  width: calc(50% - 0.75rem)
}
  }

@media (min-width: 1024px) {

.SectionBySpecies_box__2ljqH {
  width: calc(25% - 0.75rem);
  width: calc(25% - 0.75rem)
}
  }

.SectionBySpecies_boxInner__2o20A {
  position: relative;
  width: 100%;

  /* Keep the aspect ratio as per design 130px x 122px */
  padding-bottom: calc((122% / 130) * 100);
}

.SectionBySpecies_boxImage__2YpZr {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.SectionBySpecies_boxTitle__3z4lP {
  position: absolute;
  bottom: 1rem;
  bottom: 1rem;
  width: 100%;
  text-align: center;

  font-weight: 600;

  font-weight: 600;
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #fff;
  color: #fff;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionHero_root__2o9mx {
  background-color: #f7fafc;
  background-color: #f7fafc;
}

.SectionHero_content__3qUqI {
  display: flex;
  flex-direction: column
}

@media (min-width: 1024px) {

.SectionHero_content__3qUqI {
    flex-direction: row
}
  }

.SectionHero_column__1FI0f {
  width: 100%
}

@media (min-width: 1024px) {

.SectionHero_column__1FI0f {
    width: 50%;
    width: 50%
}
  }

.SectionHero_sloganColumn__2vK9t {
  padding: 1rem;
  padding: 1rem;
  order: 1
}

@media (min-width: 768px) {

.SectionHero_sloganColumn__2vK9t {
    padding: 2rem;
    padding: 2rem
}
  }

@media (min-width: 1024px) {

.SectionHero_sloganColumn__2vK9t {
    padding: 10rem 0;
    padding: 10rem 0;
    margin-right: 1.5rem;
    margin-right: 1.5rem;
    order: 0
}
  }

.SectionHero_slogan__3ET-j {
  font-weight: var(--font-normal);
  font-size: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.25;
  line-height: 1.25;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 1024px) {

.SectionHero_slogan__3ET-j {
    font-size: 2.25rem;
    font-size: 2.25rem;
    line-height: 1.5;
    line-height: 1.5;
    padding-right: 8rem;
    padding-right: 8rem
}
  }

.SectionHero_featuredColumn__2JY6j {
  position: relative;
  background-repeat: no-repeat;
  background-position: 0 33.333333%;
  background-position: 0 33.333333%;
  background-size: cover;
  min-height: 10rem;
  min-height: 10rem;
  order: 0
}

@media (min-width: 1024px) {

.SectionHero_featuredColumn__2JY6j {
    order: 1;
    background-position: center center
}
  }

.SectionHero_featuredListingContainer__2b9eV {
  position: absolute;
  bottom: 2rem;
  bottom: 2rem;
  left: 2rem;
  left: 2rem;
  line-height: 1;
  line-height: 1;
  color: #fff;
  color: #fff;
  font-size: 0.875rem;
  font-size: 0.875rem;
  align-items: center;
  display: none
}

@media (min-width: 1024px) {

.SectionHero_featuredListingContainer__2b9eV {
    display: flex
}
  }

.SectionHero_featuredListingContainer__2b9eV:hover {
  text-decoration: none;
}

.SectionHero_featuredAvatar__1-Vuj {
  width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.SectionHero_authorName__2AA9u {
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionConservationDonation_conservationContainer__1O099 {
  margin-top: 2.5rem;
  margin-top: 2.5rem;
  border-radius: 10px;
  border-radius: 10px;
  padding: 2.75rem 1.5rem 1.5rem 1.25rem;
  padding: 2.75rem 1.5rem 1.5rem 1.25rem;
  font-size: 0.875rem;
  font-size: 0.875rem;
  position: relative;
}

.SectionConservationDonation_conservationIconBase__3HcO8 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 4rem;
  width: 4rem;
  height: 4rem;
  height: 4rem;
  border: solid 3px white;
  border-radius: 50%;
  background-color: #edf2f7;
  background-color: #edf2f7;
}

.SectionConservationDonation_conservationIconPackageWrapper__a5OAw {
  position: absolute;
  top: calc(-1 * 1.5rem);
  top: calc(-1 * 1.5rem);
  left: 4px;
  right: 0;
  display: flex;
  justify-content: center;
}

.SectionConservationDonation_conservationIconContainer__1yzv3 {
  top: calc(-1 * 1.5rem);
  top: calc(-1 * 1.5rem);
}

.SectionConservationDonation_conservationFarmersIconContainer__VOpCw {
  top: calc(-1 * 1.5rem);
  top: calc(-1 * 1.5rem);
}

.SectionConservationDonation_conservationIconPackageFirst__15vF_ {
  position: relative;
  left: 4px;
  z-index: 4;
}

.SectionConservationDonation_conservationIconPackageSecond__2SUn1 {
  position: relative;
  right: 2px;
  z-index: 3;
}

.SectionConservationDonation_conservationIconPackageThird__1eMvp {
  position: relative;
  right: 8px;
  z-index: 2;
}

.SectionConservationDonation_conservationIconPackageFourth__2DI4M {
  position: relative;
  right: 14px;
  z-index: 1;
}

.SectionConservationDonation_conservationIconImage__jcKfZ {
  max-width: 2.5rem;
  max-width: 2.5rem;
  max-height: 2.75rem;
  max-height: 2.75rem;
}

.SectionConservationDonation_conservationFarmersIconImage__1QU2R {
  max-width: 4rem;
  max-width: 4rem;
  max-height: 4rem;
  max-height: 4rem;
}

.SectionConservationDonation_conservationTitle__3WOfA {
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionFeaturedListings_header__3Eoa1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.SectionFeaturedListings_showAllLink__2nBtC {
  font-weight: 600;
  font-weight: 600;
  display: none
}

@media (min-width: 768px) {

.SectionFeaturedListings_showAllLink__2nBtC {
    display: block
}
  }

.SectionFeaturedListings_showAllMobileButtonContainer__3tR2Q {
  text-align: center;
}

@media (min-width: 768px) {

.SectionFeaturedListings_showAllMobileButton__3Rili {
    display: none
}
  }

.SectionFeaturedListings_carousel__1eK2a {
  padding: 1rem 0;
  padding: 1rem 0;
  margin: 0 calc(-1 * 0.5rem);
  margin: 0 calc(-1 * 0.5rem);
}

.SectionFeaturedListings_step__24Vlq {
  padding: 0.5rem;
  padding: 0.5rem
}

@media (min-width: 768px) {

.SectionFeaturedListings_step__24Vlq {
    flex-basis: 25%;
    flex-basis: 25%
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ReviewCard_root__2Itxr {
  background-color: #2d3748;
  background-color: #2d3748;
  min-height: 18rem;
  min-height: 18rem;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  border-radius: 5px;
  border-radius: 5px;
  overflow: hidden
}

@media (min-width: 768px) {

.ReviewCard_root__2Itxr {
    flex-direction: row
}
  }

.ReviewCard_imageContainer__1uHoL {
  width: 100%;
  overflow: hidden;
  max-height: 10rem;
  max-height: 10rem
}

@media (min-width: 768px) {

.ReviewCard_imageContainer__1uHoL {
    width: 50%;
    width: 50%;
    max-height: 100%
}
  }

.ReviewCard_image__n_bX2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ReviewCard_reviewContainer__3Uach {
  color: #fff;
  color: #fff;
  padding: 1rem 1rem;
  padding: 1rem 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 1
}

@media (min-width: 768px) {

.ReviewCard_reviewContainer__3Uach {
    width: 50%;
    width: 50%;
    padding: 2.5rem 6rem;
    padding: 2.5rem 6rem;
    padding-bottom: 3rem;
    padding-bottom: 3rem
}
  }

.ReviewCard_reviewBody__3XPlT {
}

.ReviewCard_reviewHeader__7fGLf {
  text-align: center;
}

.ReviewCard_reviewRating__6HgF4 {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  display: block
}

@media (min-width: 768px) {

.ReviewCard_reviewRating__6HgF4 {
    margin-bottom: 2.25rem;
    margin-bottom: 2.25rem
}
  }

.ReviewCard_reviewRatingStar__2MAjf {
  width: 0.75rem;
  width: 0.75rem;
  height: 0.75rem;
  height: 0.75rem
}

@media (min-width: 768px) {

.ReviewCard_reviewRatingStar__2MAjf {
    width: auto;
    width: initial;
    height: auto;
    height: initial
}
  }

.ReviewCard_reviewTitle__OY-nF {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 600;
  font-weight: 600;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem
}

@media (min-width: 768px) {

.ReviewCard_reviewTitle__OY-nF {
    font-size: 1.875rem;
    font-size: 1.875rem;
    margin-bottom: 0.5rem;
    margin-bottom: 0.5rem
}
  }

.ReviewCard_reviewLocation__33dpa {
  font-size: 0.75rem;
  font-size: 0.75rem;
}

.ReviewCard_reviewQuotes__1lLWS {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: calc(-1 * 0.75rem);
  margin-bottom: calc(-1 * 0.75rem);
  text-align: center;
  font-size: 4rem;
  font-size: 4rem;
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  line-height: 1;
  line-height: 1;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ReviewCard_reviewContent__226-e {
  margin-bottom: 2rem;
  margin-bottom: 2rem;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.ReviewCard_reviewCta__3APWp {
  text-align: center;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

@media (min-width: 768px) {

.SectionFeaturedReviews_title__jylyp {
    text-align: center;
    margin-bottom: 1.5rem;
    margin-bottom: 1.5rem
}
  }

.SectionFeaturedReviews_carousel__1Lfmd {
  padding: 1rem 0;
  padding: 1rem 0;
  margin: 0 calc(-1 * 0.5rem);
  margin: 0 calc(-1 * 0.5rem);
}

.SectionFeaturedReviews_step__3NGX_ {
  display: flex;
  padding: 0.5rem;
  padding: 0.5rem;
  flex-basis: 90%
}

@media (min-width: 768px) {

.SectionFeaturedReviews_step__3NGX_ {
    flex-basis: 100%
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionListYourProperty_root__3tW_a {
  position: relative;
}

.SectionListYourProperty_header__Se5WA {
  display: flex;
}

.SectionListYourProperty_titleContainer__3dwYb {
  width: 50%;
  width: 50%;
  padding-top: 6rem;
  padding-top: 6rem;
  padding-right: 0.5rem;
  padding-right: 0.5rem;
}

.SectionListYourProperty_imageContainer__2MNoE {
  position: relative;
  width: 50%;
  width: 50%;
}

.SectionListYourProperty_image__2KI5O {
  position: absolute;
  height: 100%;
  display: block;
  border-radius: 5px;
  border-radius: 5px;
}

.SectionListYourProperty_content__1fbMh {
  margin-top: 1.25rem;
  margin-top: 1.25rem;
}

.SectionListYourProperty_content__1fbMh p {
  margin: 0;
}

.SectionListYourProperty_cta__dq8Ne {
  margin-top: 1.25rem;
  margin-top: 1.25rem;
}

.SectionListYourProperty_testimonial__2bCIq {
  position: relative;
  width: 100%;
  margin-top: 4rem;
  margin-top: 4rem;
  padding: 2rem;
  padding: 2rem;
  background-color: #fff;
  background-color: #fff;
  border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 600;
  font-weight: 600;
  color: #1a202c;
  color: #1a202c;
  display: block
}

.SectionListYourProperty_testimonial__2bCIq:hover {
    text-decoration: none;
  }

.SectionListYourProperty_testimonialHeader__3ZDY2 {
  display: flex;
}

.SectionListYourProperty_testimonialHeaderContent__3SXZ0 {
  width: 100%;
  text-align: center;
}

.SectionListYourProperty_testimonialAvatar__1xFHp {
  position: absolute;
  top: calc(-1 * (3.5rem / 2));
  top: calc(-1 * (3.5rem / 2));
  left: calc(50% - (3.5rem / 2));
  left: calc(50% - (3.5rem / 2));
  width: 3.5rem;
  width: 3.5rem;
  height: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  object-fit: cover;
}

.SectionListYourProperty_testimonialAuthor__1Kp8a {
  font-size: 1rem;
  font-size: 1rem;
}

.SectionListYourProperty_testimonialListing__1rU8Z {
  font-size: 0.75rem;
  font-size: 0.75rem;
  font-weight: normal;
}

.SectionListYourProperty_testimonialQuotes__2d3E0 {
  margin-bottom: calc(-1 * 0.75rem);
  margin-bottom: calc(-1 * 0.75rem);
  text-align: center;
  font-size: 4rem;
  font-size: 4rem;
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  line-height: 1;
  line-height: 1;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media (min-width: 768px) {
  .SectionListYourProperty_root__3tW_a {
    margin-bottom: 16rem;
    margin-bottom: 16rem;
  }

  .SectionListYourProperty_titleContainer__3dwYb {
    width: 40%;
    width: 40%;
    padding-top: 5em;
    padding-right: 3em;
  }

  .SectionListYourProperty_imageContainer__2MNoE {
    position: absolute;
    top: 0;
    left: 40%;
    height: 100%;
  }

  .SectionListYourProperty_content__1fbMh {
    width: 40%;
    width: 40%;
    padding-right: 2rem;
    padding-right: 2rem;
  }

  .SectionListYourProperty_cta__dq8Ne {
    /* to force image to align with the container */
    margin-bottom: 226px;
  }

  .SectionListYourProperty_testimonial__2bCIq {
    position: absolute;
    bottom: calc(-1 * 12rem);
    bottom: calc(-1 * 12rem);
    left: calc(50% - (18rem / 2));
    left: calc(50% - (18rem / 2));
    padding-left: 3.5rem;
    padding-left: 3.5rem;
    max-width: 18rem;
    max-width: 18rem;
    font-size: 1.25rem;
    font-size: 1.25rem;
    z-index: 10;
    z-index: 10;
  }

  .SectionListYourProperty_testimonialHeaderContent__3SXZ0 {
    text-align: left;
  }

  .SectionListYourProperty_testimonialQuotes__2d3E0 {
    text-align: left;
  }

  .SectionListYourProperty_testimonialAvatar__1xFHp {
    width: 4.5rem;
    width: 4.5rem;
    height: 4.5rem;
    height: 4.5rem;
    top: 1.5rem;
    top: 1.5rem;
    left: calc(-1 * (4.5rem / 2));
    left: calc(-1 * (4.5rem / 2));
  }

  .SectionListYourProperty_testimonialAuthor__1Kp8a {
    font-size: 1.125rem;
    font-size: 1.125rem;
  }

  .SectionListYourProperty_testimonialListing__1rU8Z {
    font-size: 0.875rem;
    font-size: 0.875rem;
    font-weight: normal;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SectionLandowners_title__IN1fX {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px
}

@media (min-width: 768px) {

.SectionLandowners_title__IN1fX {
  font-size: 1.875rem;
  font-size: 1.875rem;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px
}
    }

.SectionLandowners_title__IN1fX {

  margin-top: 0;
  max-width: 735px
}

@media (min-width: 768px) {

.SectionLandowners_title__IN1fX {
    margin-bottom: 23px
}
  }

.SectionLandowners_columns__3BtN3 {
  display: flex;
  flex-direction: column;
  margin-top: 32px
}

@media (min-width: 768px) {

.SectionLandowners_columns__3BtN3 {
    flex-direction: row;
    margin-top: 57px
}
  }

.SectionLandowners_column__16b1V {
  width: 100%;
  margin-top: 25px

  /* Remove link's hover effect */
}

.SectionLandowners_column__16b1V:hover {
    text-decoration: none;
  }

@media (min-width: 768px) {

.SectionLandowners_column__16b1V {
    margin-top: 0;
    margin-right: 40px;
    margin-bottom: 0
}
  }

@media (min-width: 768px) {

.SectionLandowners_column__16b1V:last-of-type {
    margin-right: 0
}
  }

/* A wrapper for a responsive image that holds it's aspect ratio */

.SectionLandowners_imageWrapper__3N1-_ {
  position: relative;
  width: 100%;
}

/* An image wrapper that defines the images aspect ratio */

.SectionLandowners_aspectWrapper__aX996 {
  padding-bottom: calc(6 / 13 * 100%) /* 13:6 Aspect Ratio */
}

@media (min-width: 768px) {

.SectionLandowners_aspectWrapper__aX996 {
    padding-bottom: calc(2 / 3 * 100%) /* 3:2 Aspect Ratio */
}
  }

.SectionLandowners_locationImage__p176d {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border-radius: 8px;
  object-fit: cover;
}

.SectionLandowners_linkText__3nypH {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px
}

@media (min-width: 768px) {

.SectionLandowners_linkText__3nypH {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px
}
    }

.SectionLandowners_linkText__3nypH {
  margin-top: 15px;
  margin-bottom: 0
}

@media (min-width: 768px) {

.SectionLandowners_linkText__3nypH {
    margin-top: 21px
}
  }

.SectionLandowners_descText__17_sP {
  font-weight: 500;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

.SectionLandowners_faqButton__24doc {
  font-weight: 600;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.SectionLandowners_faqButton__24doc {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.SectionLandowners_faqButton__24doc {

    /* Dimensions */
  width: 100%;
  min-height: 30px;
  margin: 0;
  white-space: nowrap;

  padding: 4px 20px;

    /* Borders */
  border-style: solid;
  border-width: 1px;
  border-color: #e2e8f0;
  border-color: #e2e8f0;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #1a202c;
  color: #1a202c;

    /* Text */
  text-align: center;
  text-decoration: none;

  cursor: pointer;
}

.SectionLandowners_faqButton__24doc:hover,
    .SectionLandowners_faqButton__24doc:focus {
  border-color: #cbd5e0;
  border-color: #cbd5e0;
  outline: none;
    }

.SectionLandowners_faqButton__24doc {
  font-weight: 500;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
  border-color: #1a202c;
  border-color: #1a202c;
  width: 120px;
}

.SectionLandowners_signupButton__3ABms {
  font-weight: 600;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.SectionLandowners_signupButton__3ABms {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.SectionLandowners_signupButton__3ABms {

    /* Dimensions */
  width: 100%;
  min-height: 30px;
  margin: 0;
  white-space: nowrap;

  padding: 4px 20px;

    /* Borders */
  border-style: solid;
  border-width: 1px;
  border-color: #e2e8f0;
  border-color: #e2e8f0;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #1a202c;
  color: #1a202c;

    /* Text */
  text-align: center;
  text-decoration: none;

  cursor: pointer;
}

.SectionLandowners_signupButton__3ABms:hover,
    .SectionLandowners_signupButton__3ABms:focus {
  border-color: #cbd5e0;
  border-color: #cbd5e0;
  outline: none;
    }

.SectionLandowners_signupButton__3ABms {
  font-weight: 600;
  font-weight: 600;
  border-color: #3e704b;
  border-color: #3e704b;
  color: #3e704b;
  color: #3e704b;
}

.SectionLandowners_signupButton__3ABms:hover,
    .SectionLandowners_signupButton__3ABms:focus {
  border-color: var(--ltActiveBackgroundColor);
    }

.SectionLandowners_signupButton__3ABms {
  font-weight: 500;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
  width: 120px;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionPackages_root__2QQQ4 {
  /*  */
}

.SectionPackages_sectionPackages__2rfj2 {
  margin-bottom: 2rem;
  margin-bottom: 2rem
}

@media (min-width: 768px) {

.SectionPackages_sectionPackages__2rfj2 {
    padding: 0;
    margin-bottom: 3.5rem;
    margin-bottom: 3.5rem
}
  }

.SectionPackages_listingBookingPanel__Ls56j {
  display: none
}

@media (min-width: 768px) {

.SectionPackages_listingBookingPanel__Ls56j {
    display: block;
    padding: 0
}
  }

.SectionPackages_sectionTitle__13Stg {
  padding-top: 1.25rem;
  padding-top: 1.25rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700
}

@media (min-width: 768px) {

.SectionPackages_sectionTitle__13Stg {
    padding-top: 0
}
  }

.SectionPackages_bookingPanelPackages__2_LCt {
  display: flex;
  border-radius: 25px;
  border-radius: 25px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e2e8f0;
  border: solid 1px #e2e8f0;
  flex-direction: column;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  padding: 0.75rem 1.25rem 1.25rem 1.25rem;
  padding: 0.75rem 1.25rem 1.25rem 1.25rem
}

@media (min-width: 768px) {

.SectionPackages_bookingPanelPackages__2_LCt {
    margin-bottom: 1.75rem;
    margin-bottom: 1.75rem;
    padding: 1.75rem 1.25rem 0.5rem 1.25rem;
    padding: 1.75rem 1.25rem 0.5rem 1.25rem
}
  }

.SectionPackages_viewAll__i8eQX {
  display: flex;
  justify-content: center;
  color: #ed3615;
  color: #ed3615
}

@media (min-width: 768px) {

.SectionPackages_viewAll__i8eQX {
    margin: 2.25rem 0;
    margin: 2.25rem 0
}
  }

.SectionPackages_viewAllText__dpEUz {
  font-weight: 700;
  font-weight: 700;
}

.SectionPackages_packageTitle__3hqMU {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.SectionPackages_packageDetails__9ydiJ {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.SectionPackages_packageDescription__Y6vE0 {
  color: #1a202c;
  color: #1a202c;
  text-decoration: underline;
}

.SectionPackages_chooseButton__3ue8N {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.75rem;
  width: 7.75rem;
  height: 3rem;
  height: 3rem;
  border-radius: 25px;
  border-radius: 25px;
  padding: 1rem 0.5rem;
  padding: 1rem 0.5rem;
}

.SectionPackages_bookingPackContainer__3VG_r {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem
}

@media (min-width: 768px) {

.SectionPackages_bookingPackContainer__3VG_r {
    flex-direction: row;
    margin-bottom: 1.25rem;
    margin-bottom: 1.25rem
}
  }

.SectionPackages_packageInfo__2oS6z {
  display: flex;
}

.SectionPackages_packageButton__2Ccaj {
  display: flex
}

@media (min-width: 768px) {

.SectionPackages_packageButton__2Ccaj {
    display: none
}
  }

.SectionPackages_packageDivider__yfgH6 {
  display: none
}

@media (min-width: 768px) {

.SectionPackages_packageDivider__yfgH6 {
    display: block;
    width: 100%;
    height: 1px;
    margin-bottom: 0.75rem;
    margin-bottom: 0.75rem;

    border: none;
    background-color: #edf2f7;
    background-color: #edf2f7
}
  }

.SectionPackages_protipWrapper__2OaDZ {
  margin: 1rem 0;
  margin: 1rem 0;
}

.SectionPackages_packageButtonMobile__1D6x9 {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center
}

@media (min-width: 768px) {

.SectionPackages_packageButtonMobile__1D6x9 {
    margin-bottom: 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionPropertyVisit_sectionContainer__1AswT {
  position: relative;
  padding: 3rem 1.25rem 2rem;
  padding: 3rem 1.25rem 2rem;
  border-radius: 10px;
  border-radius: 10px;
  margin: 2rem 0;
  margin: 2rem 0
}

@media (min-width: 1024px) {

.SectionPropertyVisit_sectionContainer__1AswT {
    margin-bottom: 3.25rem;
    margin-bottom: 3.25rem;
    margin-top: 0;
    padding: 3.75rem 3.75rem 5rem 0;
    padding: 3.75rem 3.75rem 5rem 0
}
  }

.SectionPropertyVisit_breakoutContainer__1-JBx {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  z-index: 10
}

@media (min-width: 1024px) {

.SectionPropertyVisit_breakoutContainer__1-JBx {
    border-radius: 10px;
    border-radius: 10px;
    left: -50vw
}
  }

.SectionPropertyVisit_content__1XVD_ {
  position: relative;
  z-index: 20;
  z-index: 20;
}

.SectionPropertyVisit_flexContainer__1_nZe {
  display: flex;
  flex-direction: row;
  margin: 2.25rem 0;
  margin: 2.25rem 0
}

@media (min-width: 1024px) {

.SectionPropertyVisit_flexContainer__1_nZe {
    align-items: center;
    margin: 1rem 0;
    margin: 1rem 0
}
  }

.SectionPropertyVisit_visitHeading__3Rcx2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SectionPropertyVisit_descriptionHeader__25DLW {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: #1a202c;
  color: #1a202c;
  display: flex;
  margin-left: 0.5rem;
  margin-left: 0.5rem
}

@media (min-width: 1024px) {

.SectionPropertyVisit_descriptionHeader__25DLW {
    margin: 0 0 0.25rem 0.5rem;
    margin: 0 0 0.25rem 0.5rem
}
  }

.SectionPropertyVisit_avatarImage__VxPsV {
  max-width: 4.75rem;
  max-width: 4.75rem;
  max-height: 4.75rem;
  max-height: 4.75rem;
}

.SectionPropertyVisit_fieldRepContainer__3KQe2 {
  display: flex;
  flex-direction: column;
  margin-left: 0.75rem;
  margin-left: 0.75rem;
}

.SectionPropertyVisit_fieldRepInfoContainer__39thM {
  display: flex;
  flex-direction: column
}

@media (min-width: 1024px) {

.SectionPropertyVisit_fieldRepInfoContainer__39thM {
    flex-direction: row
}
  }

.SectionPropertyVisit_desktopPipe__1kKaS {
  display: none
}

@media (min-width: 1024px) {

.SectionPropertyVisit_desktopPipe__1kKaS {
    display: flex;
    margin: 0 0.25rem;
    margin: 0 0.25rem
}
  }

.SectionPropertyVisit_fieldRepName__1bItO {
  margin: 0;
}

.SectionPropertyVisit_visitDate__7Aztr {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionFAQ_sectionContainer__1BWFs {
  position: relative;
  padding: 0 1.5rem;
  padding: 0 1.5rem;
  border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 2.5rem;
  margin-bottom: 2.5rem
}

@media (min-width: 1024px) {

.SectionFAQ_sectionContainer__1BWFs {
    margin: 2.25rem 0;
    margin: 2.25rem 0;
    padding: 0
}
  }

.SectionFAQ_sectionHeader__3Kytv {
  font-size: 1.5rem;
  font-size: 1.5rem;
  display: flex;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: bold;
  color: #1a202c;
  color: #1a202c;
  padding-right: 2rem;
  padding-right: 2rem
}

@media (min-width: 1024px) {

.SectionFAQ_sectionHeader__3Kytv {
    margin: 0 0 0.25rem;
    margin: 0 0 0.25rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* A simple technique I developed here: https://codesandbox.io/s/flexbox-with-gaps-simple-qu7wl
   These helpers can be used to construct a flexbox layout that supports grid gaps like css grid does.
   This is not as easy as it seems, especially with a responsive flex layout that can wrap, this solution adds
   margins to add flex items then uses negative margins to offset the margins at the edge so that they don't
   interfere with elements outside of this one

   Usage:

   JSX:

   <div className={css.filtersWrapper}>
     <div className={css.filtersInner}>
       <div className={css.filter}>Item 1</div>
       <div className={css.filter}>Item 2</div>
       <div className={css.filter}>Item 3</div>
     </div>
   </div>

   CSS:

   @import '../FlexHelper/FlexHelper.css';

   // Decorate the wrapper here as you wish but don't add padding
   .filtersWrapper {
     @apply --flexWrapper;
   }

   // Don't add much except flexbox configuration here
   .filtersInner {
     @apply --flexInner3;
     justify-content: center; // Or whatever flex stuff you want to add
   }

   // Decorate each item as you wish but don't add margins
   .filter {
     @apply --flexItem3;

     // You can add widths here for more of a grid-like look.
     // You need to use / add helpers though because the widths need to be calculated using the margin
     @apply --flexItem3Width50Percent;

     @media (--screen-md) {
       @apply --flexItem3Width25Percent;
     }
   }

   In this simple version the gaps cannot be configured to change at breakpoints but ...

   Extra classes for different configurations could be added here and reused elsewhere as needed

   Or it can be expended to support a nice Bootstrap-style grid: https://codesandbox.io/s/pmxn0pqpyq
   - This implementation can be responsive but every use on the app is the same

   Or it can be used like this when uncoupled from the limitations of CSS Modules: https://codesandbox.io/s/mz13k4mw4x
   - This implementation can change behavior on a per usage basis but it cannot practically be done in CSS Modules
     without using real CSS Custom Properties
*/

.SectionSteps_root__1Ciq4 {
  padding: 3rem 2rem;
  padding: 3rem 2rem;
}

.SectionSteps_root--brown__1vgmJ {
  background-color: #e2caa5;
  background-color: #e2caa5;
}

.SectionSteps_root--olive__3gVMP {
  background-color: #3e704b;
  background-color: #3e704b;
}

.SectionSteps_root--gray__2ar1X {
  background-color: #edf2f7;
  background-color: #edf2f7;
}

.SectionSteps_title__5Ck-8 {
  text-align: center;
}

.SectionSteps_description__3o8aZ {
  text-align: center;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
}

.SectionSteps_ctaContainer__3_MbR {
  text-align: center;
}

.SectionSteps_stepsWrapper__3gUCQ {
  display: inline-block;
  width: 100%;
}

.SectionSteps_stepsInner__1hf7r {
  display: flex;
  flex-wrap: wrap;

    /* Stop the negative margins preventing clicks part 1.
       This part makes the negative margins (and all children) unclickable */
  pointer-events: none;

  margin: calc(0px - 2.5rem) 0 0 calc(0px - 2.5rem);

  margin: calc(0px - 2.5rem) 0 0 calc(0px - 2.5rem);
}

.SectionSteps_stepsInner__1hf7r:dir(rtl) {
  margin: calc(0px - 2.5rem) calc(0px - 2.5rem) 0 0;
  margin: calc(0px - 2.5rem) calc(0px - 2.5rem) 0 0;
    }

.SectionSteps_stepRoot__1g3bT {
    /* Stop the negative margins preventing clicks part 2.
       This part restores clicks for all the items and their children */
  pointer-events: auto;

  margin: 2.5rem 0 0 2.5rem;

  margin: 2.5rem 0 0 2.5rem;
}

.SectionSteps_stepRoot__1g3bT:dir(rtl) {
  margin: 2.5rem 2.5rem 0 0;
  margin: 2.5rem 2.5rem 0 0;
    }

.SectionSteps_stepRoot__1g3bT {
  margin-bottom: 3rem;
  margin-bottom: 3rem;
}

.SectionSteps_stepHeader__3nhNG {
  display: inline-block;
}

.SectionSteps_stepIconContainer__2IR0L {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  color: #1a202c;
  color: #1a202c;
}

.SectionSteps_stepTitle__8h0Y4 {
  font-size: 1.5rem;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.SectionSteps_stepBody__3MiOd {
  margin: 0;
}

@media (min-width: 1024px) {
  .SectionSteps_root__1Ciq4 {
    padding: 5rem 2rem;
    padding: 5rem 2rem;
  }

  .SectionSteps_description__3o8aZ {
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    width: 40%;
    font-size: 1.5rem;
    font-size: 1.5rem;
  }

  .SectionSteps_stepsWrapper__3gUCQ {
    margin-top: 4rem;
    margin-top: 4rem;
  }

  .SectionSteps_stepRoot__1g3bT {
  width: calc(33.333333% - 2.5rem);
  width: calc(33.333333% - 2.5rem);
  }

  .SectionSteps_stepBody__3MiOd {
    font-size: 0.875rem;
    font-size: 0.875rem;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SectionThumbnailLinks_root__K1CIg {
}

.SectionThumbnailLinks_heading__1Mvwp {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px
}

@media (min-width: 768px) {

.SectionThumbnailLinks_heading__1Mvwp {
  font-size: 1.875rem;
  font-size: 1.875rem;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px
}
    }

.SectionThumbnailLinks_heading__1Mvwp {
  margin: 0 0 18px 0
}

@media (min-width: 768px) {

.SectionThumbnailLinks_heading__1Mvwp {
    margin: 0 0 23px 0
}
  }

.SectionThumbnailLinks_subHeading__3Cww_ {
  margin: 0 0 57px 0
}

@media (min-width: 768px) {

.SectionThumbnailLinks_subHeading__3Cww_ {
    margin: 0 0 57px 0
}
  }

.SectionThumbnailLinks_links__32vMn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap
}

@media (min-width: 768px) {

.SectionThumbnailLinks_links__32vMn {
    flex-direction: row
}
  }

.SectionThumbnailLinks_link__3DX3c {
  width: 100%;
  margin-top: 25px
}

.SectionThumbnailLinks_link__3DX3c:hover {
    text-decoration: none;
  }

.SectionThumbnailLinks_link__3DX3c {

  /* First link should not have top margin */
}

.SectionThumbnailLinks_link__3DX3c:nth-of-type(1) {
    margin-top: 0;
  }

@media (min-width: 768px) {

.SectionThumbnailLinks_link2Columns__1OWyh {
    width: calc(50% - 20px)

    /* First row should not have top margin */
}
    .SectionThumbnailLinks_link2Columns__1OWyh:nth-of-type(1),
    .SectionThumbnailLinks_link2Columns__1OWyh:nth-of-type(2) {
      margin-top: 0;
    }
  }

@media (min-width: 768px) {

.SectionThumbnailLinks_link3Columns__1qquD {
    width: calc(33.333% - 20px)

    /* First row should not have top margin */
}
    .SectionThumbnailLinks_link3Columns__1qquD:nth-of-type(1),
    .SectionThumbnailLinks_link3Columns__1qquD:nth-of-type(2),
    .SectionThumbnailLinks_link3Columns__1qquD:nth-of-type(3) {
      margin-top: 0;
    }
  }

.SectionThumbnailLinks_imageWrapper__2UYAf {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s
}

.SectionThumbnailLinks_imageWrapper__2UYAf:hover {
    transform: scale(1.02);
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  }

.SectionThumbnailLinks_aspectWrapper__21Es_ {
  padding-bottom: calc(6 / 13 * 100%) /* 13:6 Aspect Ratio */
}

@media (min-width: 768px) {

.SectionThumbnailLinks_aspectWrapper__21Es_ {
    padding-bottom: calc(2 / 3 * 100%) /* 3:2 Aspect Ratio */
}
  }

.SectionThumbnailLinks_image__1ns0B {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.SectionThumbnailLinks_text__y0qj8 {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px
}

@media (min-width: 768px) {

.SectionThumbnailLinks_text__y0qj8 {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px
}
    }

.SectionThumbnailLinks_text__y0qj8 {
  color: #4a5569;
  color: #4a5569;
  margin-top: 15px;
  margin-bottom: 0
}

@media (min-width: 768px) {

.SectionThumbnailLinks_text__y0qj8 {
    margin-top: 21px
}
  }

.SectionForLandowners_root__3KQ58 {
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SelectCategoryFilter_filterContainer__J9sJa {
  display: flex;
  flex-direction: row;
}

.SelectCategoryFilter_filterButton__2Ek3a {
  font-weight: 600;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.SelectCategoryFilter_filterButton__2Ek3a {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.SelectCategoryFilter_filterButton__2Ek3a {

    /* Dimensions */
  width: 100%;
  min-height: 30px;
  margin: 0;
  white-space: nowrap;

  padding: 4px 20px;

    /* Borders */
  border-style: solid;
  border-width: 1px;
  border-color: #e2e8f0;
  border-color: #e2e8f0;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #1a202c;
  color: #1a202c;

    /* Text */
  text-align: center;
  text-decoration: none;

  cursor: pointer;
}

.SelectCategoryFilter_filterButton__2Ek3a:hover,
    .SelectCategoryFilter_filterButton__2Ek3a:focus {
  border-color: #cbd5e0;
  border-color: #cbd5e0;
  outline: none;
    }

.SelectCategoryFilter_filterButton__2Ek3a {
  width: 100px;
  height: 30px;
  min-height: 0;
  margin: 4px 4px 4px 0px;
}

.SelectCategoryFilter_filterButtonSelected__zjqdb {
  font-weight: 600;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.SelectCategoryFilter_filterButtonSelected__zjqdb {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.SelectCategoryFilter_filterButtonSelected__zjqdb {

    /* Dimensions */
  width: 100%;
  min-height: 30px;
  margin: 0;
  white-space: nowrap;

  padding: 4px 20px;

    /* Borders */
  border-style: solid;
  border-width: 1px;
  border-color: #e2e8f0;
  border-color: #e2e8f0;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #1a202c;
  color: #1a202c;

    /* Text */
  text-align: center;
  text-decoration: none;

  cursor: pointer;
}

.SelectCategoryFilter_filterButtonSelected__zjqdb:hover,
    .SelectCategoryFilter_filterButtonSelected__zjqdb:focus {
  border-color: #cbd5e0;
  border-color: #cbd5e0;
  outline: none;
    }

.SelectCategoryFilter_filterButtonSelected__zjqdb {
  font-weight: 600;
  font-weight: 600;
  border-color: var(--ltActiveBackgroundColor);
  background-color: var(--ltActiveBackgroundColor);
  color: var(--ltActiveForegroundColor);
}

.SelectCategoryFilter_filterButtonSelected__zjqdb:hover,
    .SelectCategoryFilter_filterButtonSelected__zjqdb:focus {
  border-color: var(--ltActiveBackgroundColor);
    }

.SelectCategoryFilter_filterButtonSelected__zjqdb {
  width: 100px;
  height: 30px;
  min-height: 0;
  margin: 4px 4px 4px 0px;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SelectMultipleFilterPlain_root__1Xd3r {
  padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid #a1aec1;
  border-bottom: 1px solid #a1aec1;
}

.SelectMultipleFilterPlain_filterLabel__1Jk_R,
.SelectMultipleFilterPlain_filterLabelSelected__1S6K9 {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.25rem;
  font-size: 1.25rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;
}

@media (min-width: 768px) {

.SelectMultipleFilterPlain_filterLabel__1Jk_R,
.SelectMultipleFilterPlain_filterLabelSelected__1S6K9 {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px;
}
    }

.SelectMultipleFilterPlain_filterLabel__1Jk_R,
.SelectMultipleFilterPlain_filterLabelSelected__1S6K9 {

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
}

.SelectMultipleFilterPlain_filterLabel__1Jk_R {
  color: #1a202c;
  color: #1a202c;
}

.SelectMultipleFilterPlain_filterLabelSelected__1S6K9 {
  color: #3e704b;
  color: #3e704b;
}

.SelectMultipleFilterPlain_labelButton__1gcac {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.SelectMultipleFilterPlain_optionsContainerOpen__7iaiy {
  height: auto;
  padding-left: 20px;
  padding-bottom: 30px;
}

.SelectMultipleFilterPlain_optionsContainerClosed__2KcC3 {
  height: 0;
  overflow: hidden;
}

.SelectMultipleFilterPlain_columnLayout__ANIf- {
}

.SelectMultipleFilterPlain_clearButton__2i_Un {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.SelectMultipleFilterPlain_clearButton__2i_Un {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.SelectMultipleFilterPlain_clearButton__2i_Un {
  font-weight: 500;
  font-weight: 500;
  color: #a1aec1;
  color: #a1aec1;

  /* Layout */
  display: inline;
  float: right;
  margin-top: 6px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none
}

.SelectMultipleFilterPlain_clearButton__2i_Un:focus,
  .SelectMultipleFilterPlain_clearButton__2i_Un:hover {
    color: #4a5569;
    color: #4a5569;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.SelectSingleFilterPlain_root__1esud {
  padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid #a1aec1;
  border-bottom: 1px solid #a1aec1;
}

.SelectSingleFilterPlain_filterLabel__3xhmv,
.SelectSingleFilterPlain_filterLabelSelected__37a7t {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.25rem;
  font-size: 1.25rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;
}

@media (min-width: 768px) {

.SelectSingleFilterPlain_filterLabel__3xhmv,
.SelectSingleFilterPlain_filterLabelSelected__37a7t {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px;
}
    }

.SelectSingleFilterPlain_filterLabel__3xhmv,
.SelectSingleFilterPlain_filterLabelSelected__37a7t {

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
}

.SelectSingleFilterPlain_filterLabel__3xhmv {
  color: #1a202c;
  color: #1a202c;
}

.SelectSingleFilterPlain_filterLabelSelected__37a7t {
  color: #3e704b;
  color: #3e704b;
}

.SelectSingleFilterPlain_labelButton__Ce_vd {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.SelectSingleFilterPlain_optionsContainerOpen__S4mBI {
  height: auto;
  padding-bottom: 30px;
}

.SelectSingleFilterPlain_optionsContainerClosed__2eMny {
  height: 0;
  overflow: hidden;
}

.SelectSingleFilterPlain_hasBullets__3MhLF {
  padding-left: 26px;
}

@media (min-width: 768px) {

.SelectSingleFilterPlain_twoColumns__VgsJb {
    column-count: 2
}
  }

.SelectSingleFilterPlain_optionBorder__3a8Th,
.SelectSingleFilterPlain_optionBorderSelected__xdwY2 {
  position: absolute;
  height: calc(100% - 12px);
  top: 4px;
  left: -24px;
  transition: width ease-in-out 0.1s;
  transition: width ease-in-out 0.1s;
}

/* left animated "border" like hover element */

.SelectSingleFilterPlain_optionBorder__3a8Th {
  width: 0;
  background-color: #1a202c;
  background-color: #1a202c;
}

/* left static border for selected element */

.SelectSingleFilterPlain_optionBorderSelected__xdwY2 {
  width: 8px;
  background-color: #1a202c;
  background-color: #1a202c;
}

.SelectSingleFilterPlain_optionBullet__1d4Rh,
.SelectSingleFilterPlain_optionBulletSelected__2WDdM {
  position: absolute;
  left: -5px;
  top: 13px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #3e704b;
  background-color: #3e704b;
  transition: opacity ease-in-out 0.1s;
  transition: opacity ease-in-out 0.1s;
}

/* left animated "border" like hover element */

.SelectSingleFilterPlain_optionBullet__1d4Rh {
  opacity: 0;
}

/* left static border for selected element */

.SelectSingleFilterPlain_optionBulletSelected__2WDdM {
  opacity: 1;
}

.SelectSingleFilterPlain_option__WiNjW {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.SelectSingleFilterPlain_option__WiNjW {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.SelectSingleFilterPlain_option__WiNjW {
  font-weight: 500;
  font-weight: 500;
  font-size: 18px;
  color: #4a5569;
  color: #4a5569;

  /* Layout */
  display: block;
  position: relative;
  margin: 0;
  padding: 4px 0 8px 20px;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer
}

.SelectSingleFilterPlain_option__WiNjW:focus,
  .SelectSingleFilterPlain_option__WiNjW:hover {
    color: #1a202c;
    color: #1a202c;
  }

.SelectSingleFilterPlain_option__WiNjW:hover .SelectSingleFilterPlain_menuItemBorder__2LYdL {
    width: 6px;
  }

.SelectSingleFilterPlain_optionSelected__2YKhA {
  color: #1a202c;
  color: #1a202c;
}

.SelectSingleFilterPlain_clearButton__3wSeI {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.SelectSingleFilterPlain_clearButton__3wSeI {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.SelectSingleFilterPlain_clearButton__3wSeI {
  font-weight: 500;
  font-weight: 500;
  color: #a1aec1;
  color: #a1aec1;

  /* Layout */
  display: inline;
  float: right;
  margin-top: 6px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none
}

.SelectSingleFilterPlain_clearButton__3wSeI:focus,
  .SelectSingleFilterPlain_clearButton__3wSeI:hover {
    color: #4a5569;
    color: #4a5569;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

/* Content is visible as modal layer */

.Topbar_isOpen__3OTXb {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999

  /* scrollLayer is the .root for the Modal */
}

.Topbar_isOpen__3OTXb .Topbar_scrollLayer__yFbGS {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: none;
  }

.Topbar_isOpen__3OTXb .Topbar_container__3L1bX {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    max-width: 50rem;
    padding: 1.5rem;
    padding: 1.5rem;
    background-color: #fff;
    background-color: #fff;
    border-radius: 5px;
    border-radius: 5px;
    border-bottom: none;
    flex-grow: 0;
    min-height: auto;
    min-width: 75%;
    min-width: 75%;
    height: auto;
    overflow: hidden;
    margin: 0 1.5rem;
    margin: 0 1.5rem
  }

@media (min-width: 1024px) {

.Topbar_isOpen__3OTXb .Topbar_container__3L1bX {
      min-width: 40%;
      min-width: 40%
  }
    }

.Topbar_content__3YYK8 {
  overflow: auto;
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */

/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */

/* where desktop layout should not get any styling from Modal component.              */

.Topbar_isClosed__1pr3D {
  display: none;
}

.Topbar_close__HqN5U {
  position: absolute;
  top: 1rem;
  top: 1rem;
  right: 1.5rem;
  right: 1.5rem;
  width: 2rem;
  width: 2rem;
  height: 2rem;
  height: 2rem;
  padding: 0.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  line-height: 1;
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
  z-index: 10;
}

.Topbar_closeText__3HBoa {
}

.Topbar_closeIcon__6k3ks {
  height: 2rem;
  height: 2rem;
  width: 2rem;
  width: 2rem;
}

.Topbar_closeLight__2R_DQ {
  background-color: #fff;
  background-color: #fff;
  color: #1a202c;
  color: #1a202c;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
}

.Topbar_root__4g5B5 {
  width: 100%;
}

.Topbar_container__3L1bX {
  height: 4.5rem;
  height: 4.5rem;
  padding: 0 1.25rem;
  padding: 0 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1)
}

@media (min-width: 768px) {

.Topbar_container__3L1bX {
    display: none
}
  }

.Topbar_burgerMenu__1yvBn {
  color: #1a202c;
  color: #1a202c
}

.Topbar_burgerMenu__1yvBn > svg {
    display: block;
  }

.Topbar_mapToggle__3tQMC {
  margin-left: 1.25rem;
  margin-left: 1.25rem
}

.Topbar_mapToggle__3tQMC svg {
    display: block;
    fill: #1a202c;
    fill: #1a202c;
  }

.Topbar_logo__fYoth {
  position: absolute;
}

.Topbar_searchForm__2233m {
  width: 100%;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
  flex: 1 1;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  visibility: hidden;
}

.Topbar_searchFormVisible__2Fh4B {
  visibility: visible;
  opacity: 1;
  opacity: 1;
}

.Topbar_modalContainer__CzrPz {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  padding: 1.5rem;
  background-color: #fff;
  background-color: #fff;
  border-bottom: none;
  flex-grow: 0;
  min-height: auto;
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin: 0;
  overflow: hidden;
}

.Topbar_activityLink__3jTL_ {
  position: relative;
  margin-left: 1rem;
  margin-left: 1rem;
}

.Topbar_activityBellIcon__3p1UO {
  width: 1.5rem;
  width: 1.5rem;
  fill: currentColor;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ActivityDot_root__2zLzG {
  position: absolute;
  top: -8px;
  right: -8px;

  width: 1.25rem;

  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;

  background-color: #ed3615;

  background-color: #ed3615;
  border: 2px solid #fff;
  border: 2px solid #fff;
  color: #fff;
  color: #fff;

  font-size: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.TopbarDesktop_root__3FgFx {
  width: 100%;
  height: 4.5rem;
  height: 4.5rem;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0;
  display: none
}

@media (min-width: 768px) {

.TopbarDesktop_root__3FgFx {
    display: flex
}
  }

.TopbarDesktop_rootFullWidth__2PXXL {
  padding: 0 1.25rem;
  padding: 0 1.25rem
}

@media (min-width: 1024px) {

.TopbarDesktop_rootFullWidth__2PXXL {
    padding: 0 3rem;
    padding: 0 3rem
}
  }

.TopbarDesktop_content__1TApm {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TopbarDesktop_skeletonLinks__3uFZD {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  grid-gap: 2.5rem;
  gap: 2.5rem;
}

.TopbarDesktop_links__3JLtE {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
}

.TopbarDesktop_link__3whUZ {
  position: relative;
  color: #1a202c;
  color: #1a202c;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-weight: 600;
  flex-shrink: 0;
}

.TopbarDesktop_link__3whUZ:hover {
  color: #718096;
  color: #718096;
  text-decoration: none;
}

.TopbarDesktop_linkMargin__1lBG_ {
  margin-right: 1rem;
  margin-right: 1rem
}

@media (min-width: 1024px) {

.TopbarDesktop_linkMargin__1lBG_ {
    margin-right: 3rem;
    margin-right: 3rem
}
  }

.TopbarDesktop_dropdownMenu__3rclY {
  padding: 1rem;
  padding: 1rem;
  font-size: 0.875rem;
  font-size: 0.875rem;
  min-width: 10rem;
  min-width: 10rem;
}

.TopbarDesktop_dropdownLinkItem__3KWrA {
  color: #1a202c;
  color: #1a202c;
  font-weight: 600;
  font-weight: 600;
}

.TopbarDesktop_dropdownLinkItem__3KWrA:hover {
  text-decoration: none;
  color: #718096;
  color: #718096;
}

.TopbarDesktop_currentPage__1u-s2 {
  color: #718096;
  color: #718096;
}

.TopbarDesktop_searchForm__9gM7P {
  margin-right: 1rem;
  margin-right: 1rem;
  margin-left: 2rem;
  margin-left: 2rem;
  max-width: 33%;
  flex: 1 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease-in-out
}

@media (min-width: 1024px) {

.TopbarDesktop_searchForm__9gM7P {
    margin-right: auto
}
  }

.TopbarDesktop_searchFormVisible__2G7Kr {
  opacity: 1;
  opacity: 1;
  visibility: visible;
}

.TopbarDesktop_addPropertyButton__2euu7 {
  flex-shrink: 0;
}

.TopbarDesktop_newFlag__1ephi {
  margin: 2.25rem;
  margin: 2.25rem;
  padding: 0.25rem 0.75rem;
  padding: 0.25rem 0.75rem;
  border-radius: 10px;
  border-radius: 10px;
  border: solid 1px #3e704b;
  border: solid 1px #3e704b;
  background-color: #dee4df;
  background-color: #dee4df;
  font-size: 10px;
}

.TopbarDesktop_bellIcon__174RJ {
  width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
  fill: currentColor;
}

.TopbarDesktop_signupLink__1WD5s {
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: white;
  font-weight: 600;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.TopbarMobile_root__3GxMh {
  color: #1a202c;
  color: #1a202c;
}

.TopbarMobile_rootMenu__220Ra {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 70px;
  width: 100%;
  z-index: 99998;
  padding: 1.5rem;
  padding: 1.5rem;
}

.TopbarMobile_rootTrayIcon__GjXuv {
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 16px;
  z-index: 99999
}

@media (min-width: 768px) {

.TopbarMobile_rootTrayIcon__GjXuv {
    display: none
}
  }

.TopbarMobile_menuHeader__2vm06 {
  font-size: 20px;
  font-weight: 700;
  font-weight: 700;
  padding-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #e2e8f0;
  border-bottom: 1px solid #e2e8f0;
}

.TopbarMobile_menuList__o1bjZ {
  padding-top: 1.25rem;
  padding-top: 1.25rem;
  display: flex;
  flex-direction: column;
  grid-gap: 22px;
  gap: 22px;
}

.TopbarMobile_menuItem__2nZnV {
  font-weight: 600;
  font-weight: 600;
}

.TopbarMobile_menuLink__1dFLy {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  align-items: center;
  font-weight: 600;
  font-weight: 600
}

.TopbarMobile_menuLink__1dFLy:hover {
    color: #718096;
    color: #718096;
    text-decoration: none;
  }

.TopbarMobile_menuIcon__17suO {
  width: 24px;
  height: 24px;
}

.TopbarMobile_link__2QTRD {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  font-size: 10px;
  color: #1a202c;
  color: #1a202c;
  padding: 0 12px;
  grid-gap: 3px;
  gap: 3px;
}

.TopbarMobile_activeLink__1Eg6d {
  color: #3e704b;
  color: #3e704b;
  font-weight: bold;
}

.TopbarMobile_label__15jWW {
  white-space: nowrap;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.TopbarMobileMenu_header__1K-0F {
  padding: 2rem 0;
  padding: 2rem 0;
}

.TopbarMobileMenu_headerCenter__2lYiS {
  text-align: center;
}

.TopbarMobileMenu_links__2v1u8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.TopbarMobileMenu_link__1MEXl {
  position: relative;
  color: #1a202c;
  color: #1a202c;
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 600;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.TopbarMobileMenu_linkSecondary__32o7k {
  color: #a1aec1;
  color: #a1aec1;
}

.TopbarMobileMenu_avatarContainer__hGTq0 {
  padding: 1rem 0;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TopbarMobileMenu_avatar__cAxCH {
  width: 5rem;
  width: 5rem;
  height: 5rem;
  height: 5rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.TopbarMobileMenu_avatarDisplayName__ix5Z_ {
  font-weight: 600;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.TopbarMobileMenu_currentPage__1TXFQ {
  color: #718096;
  color: #718096;
}

.TopbarMobileMenu_newFlag__1nPaI {
  margin: 2.25rem;
  margin: 2.25rem;
  padding: 0.25rem 0.75rem;
  padding: 0.25rem 0.75rem;
  border-radius: 10px;
  border-radius: 10px;
  border: solid 1px #3e704b;
  border: solid 1px #3e704b;
  background-color: #dee4df;
  background-color: #dee4df;
  font-size: 10px;
}

.TopbarMobileMenu_activityDot__nUiM6 {
  top: -4px;
  right: -18px;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.TransactionPanel_root__1Yqlz {
  position: relative;
}

.TransactionPanel_container__1Mp1L {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-bottom: 2rem
}

@media (min-width: 768px) {

.TransactionPanel_container__1Mp1L {
    width: 100%;
    max-width: 80rem;
    max-width: 80rem;
    flex-direction: row;
    padding: 0 1.25rem;
    padding: 0 1.25rem;
    margin: 3.5rem auto 1.75rem auto;
    margin: 3.5rem auto 1.75rem auto
}
  }

@media (min-width: 1024px) {

.TransactionPanel_container__1Mp1L {
    padding: 0
}
  }

.TransactionPanel_txInfo__2g9RM {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  margin-bottom: 3rem;
  padding: 1.25rem;
  padding: 1.25rem
}

@media (min-width: 768px) {

.TransactionPanel_txInfo__2g9RM {
    flex-basis: 62%;
    padding-right: 6.5rem;
    padding-right: 6.5rem;
    margin-bottom: 7.25rem;
    margin-bottom: 7.25rem;
    padding-top: 0
}
  }

.TransactionPanel_transactionListingHeadingMobile__jL91E {
  display: block
}

@media (min-width: 768px) {

.TransactionPanel_transactionListingHeadingMobile__jL91E {
    display: none
}
  }

.TransactionPanel_transactionListingHeadingDesktop__1B6Xv {
  display: none
}

@media (min-width: 768px) {

.TransactionPanel_transactionListingHeadingDesktop__1B6Xv {
    display: block
}
  }

.TransactionPanel_genericError__s8Zgp {
  color: #ff0000;
  color: #ff0000;
  margin: 24px 24px 0 24px
}

@media (min-width: 768px) {

.TransactionPanel_genericError__s8Zgp {
    margin: 32px 24px 0 24px
}
  }

@media (min-width: 1024px) {

.TransactionPanel_genericError__s8Zgp {
    margin: 32px 0 0 0
}
  }

/* DetailCardImage subcomponent */

.TransactionPanel_detailCardImageWrapper___5iFl {
  /* Layout */
  display: none;
  width: 100%;
  position: relative
}

@media (min-width: 1024px) {

.TransactionPanel_detailCardImageWrapper___5iFl {
    display: block;
    max-height: 268px;
    overflow-y: hidden
}
  }

.TransactionPanel_imageHeading__t5kA_ {
  display: none
}

@media (min-width: 768px) {

.TransactionPanel_imageHeading__t5kA_ {
    display: block;
    position: relative;
    padding-bottom: 66.666667%;
    padding-bottom: 66.666667%;
    background-color: #a1aec1;
    background-color: #a1aec1;
    border-radius: 10px;
    border-radius: 10px;
    overflow: hidden
}
  }

.TransactionPanel_aspectWrapper__K_wKp {
  padding-bottom: 33.333333%;
  padding-bottom: 33.333333%;
  background-color: #fff;
  background-color: #fff
}

@media (min-width: 640px) {

.TransactionPanel_aspectWrapper__K_wKp {
    padding-bottom: 20%;
    padding-bottom: 20%
}
  }

@media (min-width: 1024px) {

.TransactionPanel_aspectWrapper__K_wKp {
    display: none
}
  }

.TransactionPanel_rootForImage__2sjtC {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%
}

@media (min-width: 768px) {

.TransactionPanel_rootForImage__2sjtC {
    border-radius: 2px 2px 0 0
}
  }

.TransactionPanel_avatarWrapper__1lxn9 {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -30px;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px
}

@media (min-width: 768px) {

.TransactionPanel_avatarWrapper__1lxn9 {
    margin-top: -32px;
    padding: 2px 0
}
  }

@media (min-width: 1024px) {

.TransactionPanel_avatarWrapper__1lxn9 {
    margin-left: 48px;
    width: unset;
    padding: 2px 0
}
  }

/* TODO:match the style with AirBNB */

/* Passed-in props for DetailCardImage subcomponent */

.TransactionPanel_imageWrapperMobile__19y6_ {
  display: none;
  position: relative
}

@media (min-width: 1024px) {

.TransactionPanel_imageWrapperMobile__19y6_ {
    display: none
}
  }

.TransactionPanel_avatarWrapperMobile__2TmRN {
}

@media (min-width: 1024px) {

.TransactionPanel_avatarWrapperMobile__2TmRN {
    display: none
}
  }

.TransactionPanel_avatarWrapperDesktop__2ojyJ {
  display: none
}

@media (min-width: 1024px) {

.TransactionPanel_avatarWrapperDesktop__2ojyJ {
    display: block
}
  }

.TransactionPanel_avatarWrapperProviderDesktop__gvF9N {
  display: none
}

@media (min-width: 1024px) {

.TransactionPanel_avatarWrapperProviderDesktop__gvF9N {
    display: block;
    margin-top: 20px
}
  }

@media (min-width: 1024px) {

.TransactionPanel_avatarDesktop__3Zcnp {
    display: flex
}
  }

.TransactionPanel_profileFlexContainer__eFXIS {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.TransactionPanel_profileFlexContainer__eFXIS {
    margin-bottom: 1rem;
    margin-bottom: 1rem
}
  }

.TransactionPanel_avatar__3f5r_ {
  width: 4.75rem;
  width: 4.75rem;
  height: 4.75rem;
  height: 4.75rem;
}

.TransactionPanel_guestNameStyles__2gvXO {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 0.875rem;
  font-size: 0.875rem;
  padding: 0;
  margin: 0;
  line-height: 1;
}

.TransactionPanel_profileInfo__1zMP9 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  margin-left: 1rem;
}

.TransactionPanel_profileName__1wUQC {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700
}

@media (min-width: 768px) {

.TransactionPanel_profileName__1wUQC {
    font-size: 1.25rem;
    font-size: 1.25rem
}
  }

.TransactionPanel_profileLink__31vv9 {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-weight: 600;
  text-decoration: underline;
}

/* PanelHeadings subcomponent */

.TransactionPanel_headingOrder__3xV5Z {
  margin: 29px 24px 0 24px
}

@media (min-width: 768px) {

.TransactionPanel_headingOrder__3xV5Z {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0
}
  }

@media (min-width: 1024px) {

.TransactionPanel_headingOrder__3xV5Z {
    max-width: 100%;
    margin: 152px 0 0 0;
    padding: 0
}
  }

.TransactionPanel_headingSale__1F1Ip {
  margin: 18px 24px 0 24px;
  padding: 5px 0 1px 0
}

@media (min-width: 768px) {

.TransactionPanel_headingSale__1F1Ip {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0
}
  }

@media (min-width: 1024px) {

.TransactionPanel_headingSale__1F1Ip {
    max-width: 100%;
    margin: 42px 0 0 0;
    padding: 0
}
  }

.TransactionPanel_mainTitle__272DR {
  display: block;
}

.TransactionPanel_transactionInfoMessage__34P52 {
  margin: 18px 24px 0 24px
}

@media (min-width: 768px) {

.TransactionPanel_transactionInfoMessage__34P52 {
    margin: 23px 24px 0 24px
}
  }

@media (min-width: 1024px) {

.TransactionPanel_transactionInfoMessage__34P52 {
    margin: 23px 0 0 0
}
  }

/* Container for booking details in mobile layout */

.TransactionPanel_pricingDetailsMobile__14YG4 {
  margin-top: 0.75rem;
  margin-top: 0.75rem
}

@media (min-width: 768px) {

.TransactionPanel_pricingDetailsMobile__14YG4 {
    display: none
}
  }

.TransactionPanel_bookingDetailsMobile__sbFwS {
  display: block;
  margin-top: 0.25rem;
  margin-top: 0.25rem
}

@media (min-width: 768px) {

.TransactionPanel_bookingDetailsMobile__sbFwS {
    display: none
}
  }

.TransactionPanel_pricingDetailsDesktop__2D1rO {
  display: none
}

@media (min-width: 768px) {

.TransactionPanel_pricingDetailsDesktop__2D1rO {
    display: block
}
  }

.TransactionPanel_pricingPackagePanel__2z0lr {
  display: none
}

@media (min-width: 768px) {

.TransactionPanel_pricingPackagePanel__2z0lr {
    display: block
}
  }

.TransactionPanel_bookingDetailsDesktop__2Fcm- {
  display: none;
  margin-top: 0.75rem;
  margin-top: 0.75rem
}

@media (min-width: 768px) {

.TransactionPanel_bookingDetailsDesktop__2Fcm- {
    display: block
}
  }

/* "aside" section in desktop layout */

@media (min-width: 768px) {

.TransactionPanel_asideDesktopContainer__uzihp {
    flex-basis: 38%
}
  }

/* "aside" section in desktop layout */

@media (min-width: 768px) {

.TransactionPanel_asideDesktop__1tojb {
    padding: 1.25rem;
    padding: 1.25rem;
    border-radius: 25px;
    border-radius: 25px;
    border: 1px solid #e2e8f0;
    border: 1px solid #e2e8f0;
    box-shadow: 0 40px 32px -24px rgba(15, 15, 15, 0.12)
}
  }

.TransactionPanel_mobileSection__2JXSM {
  display: none;
}

@media (min-width: 1024px) {

.TransactionPanel_detailCard__2UWtV {
    z-index: 1
}
  }

/* DetailCardHeadingsMaybe subcomponent */

.TransactionPanel_detailCardHeadings__1UjhW {
  display: none
}

@media (min-width: 1024px) {

.TransactionPanel_detailCardHeadings__1UjhW {
    display: block;
    margin: 0 3rem 0.75rem 2rem;
    margin: 0 3rem 0.75rem 2rem
}
  }

.TransactionPanel_detailCardTitle__o2FM8 {
  margin-bottom: 10px
}

@media (min-width: 1024px) {

.TransactionPanel_detailCardTitle__o2FM8 {
    margin-top: 14px;
    margin-bottom: 0;
    font-size: 1.25rem;
    font-size: 1.25rem
}
  }

.TransactionPanel_detailCardSubtitle__2XCJq {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.TransactionPanel_detailCardSubtitle__2XCJq {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.TransactionPanel_detailCardSubtitle__2XCJq {

  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 1024px) {

.TransactionPanel_detailCardSubtitle__2XCJq {
    margin-top: 9px;
    margin-bottom: 0
}
  }

/* AddressLinkMaybe subcomponent */

.TransactionPanel_addressProp__34sVm {
  color: #1a202c;
  color: #1a202c;
  text-align: left;
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

.TransactionPanel_addressMobile__1N13M {
  padding: 0 1.25rem 0.75rem 1.25rem;
  padding: 0 1.25rem 0.75rem 1.25rem
}

@media (min-width: 1024px) {

.TransactionPanel_addressMobile__1N13M {
    display: none
}
  }

.TransactionPanel_address__x6tHo {
  color: #1a202c;
  color: #1a202c;
  text-decoration: underline;
  text-align: left
}

@media (min-width: 1024px) {

.TransactionPanel_address__x6tHo {
    text-align: left
}
  }

.TransactionPanel_bookingBreakdownTitle__1E6IO {
  /* Font */
  color: #1a202c;
  color: #1a202c;
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  margin: 0 1.5rem 0 1.5rem;
  margin: 0 1.5rem 0 1.5rem
}

@media (min-width: 1024px) {

.TransactionPanel_bookingBreakdownTitle__1E6IO {
    font-size: 1rem;
    font-size: 1rem;
    margin: 0 3rem 0.25rem 2rem;
    margin: 0 3rem 0.25rem 2rem
}
  }

.TransactionPanel_pricingExplained__h0tcL {
  color: #1a202c;
  color: #1a202c;
  margin: 0 1.5rem 0 1.5rem;
  margin: 0 1.5rem 0 1.5rem
}

@media (min-width: 1024px) {

.TransactionPanel_pricingExplained__h0tcL {
    font-size: 0.875rem;
    font-size: 0.875rem;
    margin: 0 3rem 0.25rem 2rem;
    margin: 0 3rem 0.25rem 2rem;
    padding: 0.25rem 0 0.25rem 0;
    padding: 0.25rem 0 0.25rem 0
}
  }

.TransactionPanel_breakdownContainer__2WPXz {
  display: none
}

@media (min-width: 1024px) {

.TransactionPanel_breakdownContainer__2WPXz {
    display: block
}
  }

.TransactionPanel_breakdown__10VkT {
  margin: 14px 24px 0 24px
}

@media (min-width: 1024px) {

.TransactionPanel_breakdown__10VkT {
    margin: 0;
    padding: 6px 0 2px 0
}
  }

.TransactionPanel_breakdownContainer__2WPXz {
  display: none
}

@media (min-width: 1024px) {

.TransactionPanel_breakdownContainer__2WPXz {
    display: block
}
  }

.TransactionPanel_feed__1kGOe {
  margin-top: 20px;
}

.TransactionPanel_messageError__3hXjN {
  color: #ff0000;
  color: #ff0000;
  margin: 13px 0 22px 0
}

@media (min-width: 768px) {

.TransactionPanel_messageError__3hXjN {
    margin: 13px 0 23px 0
}
  }

@media (min-width: 1024px) {

.TransactionPanel_messageError__3hXjN {
    margin: 12px 0 23px 0
}
  }

/* Passed-in props for FeedSection subcomponent */

.TransactionPanel_feedContainer__2l12G {
  margin: 0
}

@media (min-width: 768px) {

.TransactionPanel_feedContainer__2l12G {
    margin-bottom: 1.25rem;
    margin-bottom: 1.25rem
}
  }

/* Prop to be passed to SendMessageForm component */

.TransactionPanel_sendMessageForm__2AIsj {
  position: relative;
  margin: 3rem 0 1rem 0;
  margin: 3rem 0 1rem 0
}

@media (min-width: 768px) {

.TransactionPanel_sendMessageForm__2AIsj {
    border-top: 0
}
  }

@media (min-width: 1024px) {

.TransactionPanel_sendMessageForm__2AIsj {
    margin: 47px 0 0 0
}
  }

.TransactionPanel_sendingMessageNotAllowed__39XPL {
  color: #a1aec1;
  color: #a1aec1;

  position: relative;
  margin: 46px 24px 0 24px
}

@media (min-width: 768px) {

.TransactionPanel_sendingMessageNotAllowed__39XPL {
    margin: 49px 24px 0 24px;
    border-top: 0
}
  }

@media (min-width: 1024px) {

.TransactionPanel_sendingMessageNotAllowed__39XPL {
    margin: 47px 0 0 0
}
  }

.TransactionPanel_cancelButtonWrapper__kfISn {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.TransactionPanel_cancelButtons__3iJtN {
  width: 100%;
}

.TransactionPanel_cancelButton__fSAU3 {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

.TransactionPanel_actionButtonWrapper__RvoUj {
  width: 100%;
  display: flex;
  flex-direction: row
}

.TransactionPanel_actionButtonWrapper__RvoUj button:first-child {
    margin: 0 0.75rem 0 0;
    margin: 0 0.75rem 0 0;
  }

.TransactionPanel_actionError__2DXbF {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.TransactionPanel_actionError__2DXbF {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.TransactionPanel_actionError__2DXbF {
  color: #ff0000;
  color: #ff0000;
  margin: 0 0 11px 0
}

@media (min-width: 768px) {

.TransactionPanel_actionError__2DXbF {
    margin: 0 0 10px 0
}
  }

@media (min-width: 1024px) {

.TransactionPanel_actionError__2DXbF {
    margin: 0
}
  }

.TransactionPanel_actionErrors__7XgdH {
  width: 100%;
  text-align: center
}

@media (min-width: 1024px) {

.TransactionPanel_actionErrors__7XgdH {
    position: absolute;
    top: 151px
}
  }

.TransactionPanel_cancelButtonIcon__1XUcI {
  width: 6px;
  color: #1a202c;
  color: #1a202c;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
}

.TransactionPanel_declineButton__1eNep {
  display: flex;
  justify-content: center;
  background: none;
}

.TransactionPanel_acceptButton__1M7Ql {
  width: 50%;
  width: 50%
}

@media (min-width: 768px) {

.TransactionPanel_acceptButton__1M7Ql {
    width: 40%;
    width: 40%
}
  }

/* BookingPanel subcompnent */

.TransactionPanel_bookingPanel__IlJXj {
  margin: 1rem 0;
  margin: 1rem 0;
}

.TransactionPanel_bookingTitle__-H6M9 {
  /* Font */
  color: #4a5569;
  color: #4a5569;

  margin-top: 0;
  margin-bottom: 1px;
}

.TransactionPanel_invalidPriceMessage__DUrhg {
  color: #ff0000;
  color: #ff0000;

  margin: 16px 24px 0 24px
}

@media (min-width: 1024px) {

.TransactionPanel_invalidPriceMessage__DUrhg {
    margin: 0 48px 32px 48px;
    padding: 5px 0 0 0
}
  }

.TransactionPanel_listingTitleFeed__1XJ8m {
  display: none;
  font-size: 1.5rem;
  font-size: 1.5rem
}

@media (min-width: 768px) {

.TransactionPanel_listingTitleFeed__1XJ8m {
  font-size: 1.875rem;
  font-size: 1.875rem
}
    }

.TransactionPanel_listingTitleFeed__1XJ8m {
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.TransactionPanel_listingTitleFeed__1XJ8m {
    display: block
}
  }

.TransactionPanel_listingTitleLink__3AFkJ {
  color: #1a202c;
  color: #1a202c;
  font-size: 1.25rem;
  font-size: 1.25rem;
}

.TransactionPanel_landownerName__2wPq5 {
  height: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  font-size: 1rem;
  color: #1a202c;
  color: #1a202c;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  margin-right: 1.25rem
}

@media (min-width: 1024px) {

.TransactionPanel_landownerName__2wPq5 {
    display: none
}
  }

.TransactionPanel_landownerDesk__GAC6k {
  display: none
}

@media (min-width: 1024px) {

.TransactionPanel_landownerDesk__GAC6k {
    display: block
}
  }

.TransactionPanel_paymentForm__2MLfW {
  display: flex;
  flex-grow: 1;
  margin: 0;
}

.TransactionPanel_cancelledMessage__1MeOj {
  font-size: 1.125rem;
  font-size: 1.125rem;
}

@media (min-width: 768px) {

.TransactionPanel_cancelledMessage__1MeOj {
  font-size: 1.5rem;
  font-size: 1.5rem;
}
    }

.TransactionPanel_cancelledMessage__1MeOj {
  color: #3e704b;
  color: #3e704b;
  text-align: center;
  font-weight: 600;
  font-weight: 600;
}

.TransactionPanel_partyFormDiv__Ywmmk {
  text-align: center;
}

.TransactionPanel_currentTransactionTransition__1Awr6 {
  text-align: center;
}

.TransactionPanel_bookingStatus__3W4VO {
  margin: auto;
  width: 100%;
  padding: 0 1rem 0 0;
  padding: 0 1rem 0 0;
  border-radius: 0.75rem;
  border-radius: 0.75rem
}

@media (min-width: 768px) {

.TransactionPanel_bookingStatus__3W4VO {
    margin: 0;
    padding: 0 1rem 0.5rem 0;
    padding: 0 1rem 0.5rem 0
}
  }

.TransactionPanel_partyMembers__1KOjM,
.TransactionPanel_partyMembersComplete__FFI8u {
  margin: 0 auto 2rem auto;
  margin: 0 auto 2rem auto;
  width: 100%;
  padding: 1rem 1rem 1.5rem 1.25rem;
  padding: 1rem 1rem 1.5rem 1.25rem;
  border-radius: 0.75rem;
  border-radius: 0.75rem;
  background-color: #f7fafc;
  background-color: #f7fafc
}

@media (min-width: 768px) {

.TransactionPanel_partyMembers__1KOjM,
.TransactionPanel_partyMembersComplete__FFI8u {
    margin: 0 0 1.25rem 0;
    margin: 0 0 1.25rem 0
}
  }

.TransactionPanel_partyMembers__1KOjM {
  background-color: #f7fafc;
  background-color: #f7fafc;
  background-size: contain;
  background-position: inherit;
  background-repeat: repeat-x;
}

.TransactionPanel_iconWrapper__8hzig {
  display: flex;
  justify-content: center;
}

.TransactionPanel_verificationHeader__1HnDy {
  font-weight: bold;
}

.TransactionPanel_inviteSent__3asum {
  text-align: center;
}

@media (min-width: 1024px) {

.TransactionPanel_offer__3zVnf {
    font-size: 14px;
    margin-top: 1.5rem;
    margin-top: 1.5rem
}
  }

.TransactionPanel_comm__2ATiz {
  color: #4ca817;
  color: #4ca817;
}

@media (min-width: 1024px) {

.TransactionPanel_packagesContainer__3hgkM {
    display: none
}
  }

.TransactionPanel_submitClassName__2LdR3 {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  padding: 1rem;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 60;
  z-index: 60
}

.TransactionPanel_submitClassName__2LdR3 > button {
    margin-top: 0;
  }

.TransactionPanel_submitClassName__2LdR3 > p {
    margin-top: 0.25rem;
    margin-top: 0.25rem;
  }

@media (min-width: 768px) {

.TransactionPanel_submitClassName__2LdR3 {
    position: relative;
    padding: 0;
    background-color: transparent;
    box-shadow: none
}
  }

.TransactionPanel_potentialEarnings__1Jn8F {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  text-align: center
}

@media (min-width: 768px) {

.TransactionPanel_potentialEarnings__1Jn8F {
    margin-top: 0.75rem;
    margin-top: 0.75rem;
    text-align: right
}
  }

.TransactionPanel_desktopBreadcrumbs__3ONso {
  display: none
}

@media (min-width: 768px) {

.TransactionPanel_desktopBreadcrumbs__3ONso {
    display: block
}
  }

.TransactionPanel_mobileBreadcrumbs__1L5M1 {
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem
}

@media (min-width: 768px) {

.TransactionPanel_mobileBreadcrumbs__1L5M1 {
    display: none
}
  }

.TransactionPanel_modalContainer__3MCEP {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  background-color: #fff;
  min-height: 66.666667%;
  min-height: 66.666667%;
  width: 100%;
  overflow: hidden;
  border-radius: 25px 25px 0 0;
  border-radius: 25px 25px 0 0
}

@media (min-width: 768px) {

.TransactionPanel_modalContainer__3MCEP {
    right: 0;
    min-height: 100%;
    width: 60%;
    width: 60%;
    border-radius: 25px 0 0 25px;
    border-radius: 25px 0 0 25px
}
  }

.TransactionPanel_innerIDModal__18Wl5 {
  display: flex;
  justify-content: center;
}

.TransactionPanel_mobileCheckAvailability__2BvuT {
  z-index: 60;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1.25rem;
  padding: 1.25rem;
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 10%);
  display: flex
}

@media (min-width: 768px) {

.TransactionPanel_mobileCheckAvailability__2BvuT {
    display: none
}
  }

.TransactionPanel_mobileCheckAvailabilityPrice__As5Cp {
  font-size: 0.875rem;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.TransactionPanel_bookButton__3X7HD {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

.TransactionPanel_pricingPanel__1UgPE {
  display: none
}

@media (min-width: 768px) {

.TransactionPanel_pricingPanel__1UgPE {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 0 2.5rem 0;
    padding: 0 0 2.5rem 0;
    background-color: #fff;
    background-color: #fff;
    min-width: 20rem;
    min-width: 20rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-basis: 40%;
    flex-basis: 40%;
    flex-shrink: 1;
    flex-grow: 1
}
  }

.TransactionPanel_divider__27Qs0,
.TransactionPanel_dividerTop__1mbAk {
  height: 1px;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  margin: 1.25rem 0;
  margin: 1.25rem 0;
}

.TransactionPanel_dividerTop__1mbAk {
  display: none
}

@media (min-width: 768px) {

.TransactionPanel_dividerTop__1mbAk {
    display: block
}
  }

.TransactionPanel_listingTitle__2eBa5 {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 768px) {

.TransactionPanel_listingTitle__2eBa5 {
    display: none
}
  }

.TransactionPanel_isInquiryInfo__1Kohw {
  margin: 0.5rem 0 1rem 0;
  margin: 0.5rem 0 1rem 0
}

@media (min-width: 768px) {

.TransactionPanel_isInquiryInfo__1Kohw {
    display: none
}
  }

.TransactionPanel_notifyMobileContainer__b--dP {
  z-index: 60;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1.25rem;
  padding: 1.25rem;
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.TransactionPanel_notifyMobileContainer__b--dP {
    display: none
}
  }

.TransactionPanel_notifyButton__28JYl {
  background-color: #1a202c;
  background-color: #1a202c;
  color: #fff;
  color: #fff;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.TransactionPanel_icon__3dzER {
  width: 1rem;
  width: 1rem;
  height: 1.25rem;
  height: 1.25rem;
}

.TransactionPanel_getNotified__2Gqzd {
  color: #1a202c;
  color: #1a202c;
  font-size: 0.875rem;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-wrap: nowrap;
}

.TransactionPanel_availabilityBadgesWrapper__2Xw0f {
  margin: 1rem 0 0.25rem 0;
  margin: 1rem 0 0.25rem 0;
  padding: 0.75rem;
  padding: 0.75rem
}

@media (min-width: 768px) {

.TransactionPanel_availabilityBadgesWrapper__2Xw0f {
    display: none
}
  }

.TransactionPanel_linkIconsWrapper__1JMDB {
  display: flex;
  flex-direction: column;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.BookingDetails_root__2o5GJ {
  position: relative;
  color: #1a202c;
  color: #1a202c;
}

.BookingDetails_header__ExNPo {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.BookingDetails_title__2tTdT {
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.BookingDetails_description__aHuAX {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.BookingDetails_description__aHuAX {
    margin-bottom: 1.75rem;
    margin-bottom: 1.75rem
}
  }

.BookingDetails_description__aHuAX > p {
    margin: 0;
  }

.BookingDetails_bookingDatesIcon__1G0qK {
  width: 2.5rem;
  width: 2.5rem;
  color: #a1aec1;
  color: #a1aec1;
}

.BookingDetails_bookingDatesIconWrapper__38MZm {
  width: 2.5rem;
  width: 2.5rem;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.BookingDetails_bookingDatesItemWrapper__3asLF {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.BookingDetails_bookingDatesItem__1wF7a {
  display: flex;
  flex-direction: column;
}

.BookingDetails_bookingDatesItemLabel__3AX8I {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.BookingDetails_bookingDatesItemValue__2SseJ {
  font-weight: 700;
  font-weight: 700;
}

.BookingDetails_divider__3xsmA {
  height: 1px;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  margin: 1.25rem 0;
  margin: 1.25rem 0;
}

.BookingDetails_addressWrapper__3HOJ9 {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.BookingDetails_address__3dbVU {
  margin: 0;
}

.BookingDetails_waypoints__2DbEK {
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.BookingDetails_waypointLink__3P6o8 {
  text-decoration: underline;
  color: #1a202c;
  color: #1a202c;
}

.BookingDetails_badgesContainer__1dCo5 {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.BookingDetails_packageTitleContainer__1qd4Y {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.BookingDetails_itemTitle__2111m {
  padding-bottom: 0.75rem;
  padding-bottom: 0.75rem;
  cursor: pointer;
  text-decoration: underline;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content
}

.BookingDetails_itemTitle__2111m:hover {
    color: #718096;
    color: #718096;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.TransactionListingHeading_root__NgEiZ {
  position: relative;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0;
}

.TransactionListingHeading_imageWrapper__3Qvrt {
  padding-bottom: calc(147 / 335 * 100%);
  position: relative;
  border-radius: 0.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.TransactionListingHeading_imageWrapper__3Qvrt {
    border-radius: 1rem;
    border-radius: 1rem;
    padding-bottom: calc(211 / 488 * 100%)
}
  }

.TransactionListingHeading_image__ahtSw {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
}

.TransactionListingHeading_listingTitle__38pA0 {
  display: none
}

@media (min-width: 768px) {

.TransactionListingHeading_listingTitle__38pA0 {
    display: block;
    font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
    font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    font-weight: 700;
    margin-top: 1rem;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    margin-bottom: 0.25rem
}
  }

.TransactionListingHeading_listingTitleMobileVisible__BKdvM {
  display: block;
}

.TransactionListingHeading_listingTitleLink__3tP20 {
  font-weight: 700;
  font-weight: 700;
  font-size: 1.25rem;
  font-size: 1.25rem;
  color: #1a202c;
  color: #1a202c;
}

.TransactionListingHeading_packageTitleWrapper__1myDH {
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}

.TransactionListingHeading_packageTitleListing__3m3Jj {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: normal;
}

.TransactionListingHeading_packageTitleListingLink__2Wsfn {
  text-decoration: none;
}

.TransactionListingHeading_listingTitleInquiry__2UtqQ {
  display: block;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
  margin-top: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.TransactionListingHeading_authorWrapper__LZAkr {
  display: flex
}

@media (min-width: 768px) {

.TransactionListingHeading_authorWrapper__LZAkr {
    margin-bottom: 0.5rem;
    margin-bottom: 0.5rem
}
  }

.TransactionListingHeading_avatar__B7vv- {
  width: 3.25rem;
  width: 3.25rem;
  height: 3.25rem;
  height: 3.25rem;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.TransactionListingHeading_authorNameWrapper__RqIWG {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TransactionListingHeading_authorLabel__YPHkr {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.TransactionListingHeading_authorName__2LEmh {
  font-weight: 700;
  font-weight: 700;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.TripInsuranceBadges_root__GxHLM {
  position: absolute;
  z-index: 20;
  z-index: 20;
  display: flex;
  bottom: 0.75rem;
  bottom: 0.75rem;
  right: 0.75rem;
  right: 0.75rem;
}

.TripInsuranceBadges_baseBadge__1bkxj {
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  font-size: 0.75rem;
  font-weight: 700;
  font-weight: 700;
  padding: 0.25rem 1rem;
  padding: 0.25rem 1rem;
  border-radius: 7px;
}

.TripInsuranceBadges_acceptedBadge__231KD {
  background-color: #dee4df;
  background-color: #dee4df;
  color: #3e704b;
  color: #3e704b;
}

.TripInsuranceBadges_declinedBadge__aGyjb {
  background-color: #f7fafc;
  background-color: #f7fafc;
  color: #1a202c;
  color: #1a202c;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.CustomerExperienceCard_root__8AfWw {
  border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem;
  padding: 2rem;
  background-color: #fff;
  background-color: #fff;
}

.CustomerExperienceCard_root__8AfWw,
.CustomerExperienceCard_miniRoot__2yd19 {
  text-align: center;
}

.CustomerExperienceCard_imageContainer__25re4 {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.CustomerExperienceCard_image__1L03p {
  width: 108px;
  height: 108px;
  object-fit: contain;
}

.CustomerExperienceCard_title__2l6d5 {
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.CustomerExperienceCard_cta__1er_Y a {
    text-decoration: underline;
  }

.CustomerExperienceCard_name__10Lyw {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700;
}

.CustomerExperienceCard_jobTitle__35VEP {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.CustomerExperienceCard_miniRoot__2yd19 {
  font-weight: 600;
  font-weight: 600;
}

@media (min-width: 768px) {
  .CustomerExperienceCard_rootHorizontal__3fSUl {
    padding: 5rem;
    padding: 5rem;
    display: flex;
    text-align: left;
  }

  .CustomerExperienceCard_rootHorizontal__3fSUl .CustomerExperienceCard_leftColumn__23gig {
    width: 33.333333%;
    width: 33.333333%;
    padding-right: 2rem;
    padding-right: 2rem;
    border-right: 1px solid #e2e8f0;
    border-right: 1px solid #e2e8f0;
    text-align: center;
  }

  .CustomerExperienceCard_rootHorizontal__3fSUl .CustomerExperienceCard_rightColumn__26mHp {
    width: 66.666667%;
    width: 66.666667%;
    padding-left: 3.5rem;
    padding-left: 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .CustomerExperienceCard_rootHorizontal__3fSUl .CustomerExperienceCard_jobTitle__35VEP {
    margin-bottom: 0;
  }

  .CustomerExperienceCard_image__1L03p {
    width: 152px;
    height: 152px;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.IDVerification_root__1n_fK {
}

.IDVerification_IDModalContent__3ge28 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1.25rem;
  padding: 2rem 1.25rem
}

@media (min-width: 768px) {

.IDVerification_IDModalContent__3ge28 {
    max-width: 75%;
    max-width: 75%
}
  }

.IDVerification_verifyProcessing__2UyML {
  color: #3e704b;
  color: #3e704b;
}

.IDVerification_modalTitle__1ARYi {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-size: 1.5rem;
  font-size: 1.5rem;
}

@media (min-width: 768px) {

.IDVerification_modalTitle__1ARYi {
  font-size: 1.875rem;
  font-size: 1.875rem;
}
    }

.IDVerification_modalTitle__1ARYi {
  font-weight: 700;
  font-weight: 700;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  margin-top: 1.75rem;
  margin-top: 1.75rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  text-align: center;
}

.IDVerification_modalMessage__31lLk {
  text-align: center;
}

.IDVerification_bottomWrapper__3agDd {
  margin-top: 3rem;
  margin-top: 3rem;
}

.IDVerification_verifyTime__1cTRm {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-top: 1rem;
  margin-top: 1rem;
}

.IDVerification_idIcon__Witff {
  width: 6rem;
  width: 6rem;
  height: 4.75rem;
  height: 4.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PackagePricingPanel_root__1W0ts {
  display: none
}

@media (min-width: 768px) {

.PackagePricingPanel_root__1W0ts {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 1.5rem;
    padding: 1.5rem;
    border-radius: 1.5rem;
    border-radius: 1.5rem;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    border: none;
    background-color: #fff;
    background-color: #fff;
    min-width: 20rem;
    min-width: 20rem;
    flex-basis: 40%;
    flex-basis: 40%;
    flex-shrink: 1;
    flex-grow: 1
}
  }

@media (min-width: 768px) {

.PackagePricingPanel_rootSticky__2JUT0 {
    position: -webkit-sticky;
    position: sticky;
    top: 5.5rem;
    top: 5.5rem;
    bottom: 9.5rem;
    bottom: 9.5rem
}
  }

.PackagePricingPanel_formFieldWrapper__3lohU {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}

.PackagePricingPanel_priceWrapper__3EPs4 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 1.25rem;
  font-size: 1.25rem;
}

.PackagePricingPanel_formFieldHeading__1mjOr {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.PackagePricingPanel_formFieldHeadingPackageDetails__1C3UB {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.PackagePricingPanel_minPartyDisclaimer__1tMnS {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.PackagePricingPanel_minPartyDisclaimer__1tMnS {
    font-size: 0.75rem;
    font-size: 0.75rem
}
  }

.PackagePricingPanel_priceContainer__3gWcD {
  display: flex;
  align-items: center;
}

.PackagePricingPanel_priceText__1vAPP {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.PackagePricingPanel_perGuestText__2oKcG {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-left: 0.25rem;
  margin-left: 0.25rem;
}

.PackagePricingPanel_dateInfo__1wFac {
  padding: 0 0.5rem;
  padding: 0 0.5rem;
}

.PackagePricingPanel_dateInfoTitle__1fCKD {
  color: #1a202c;
  color: #1a202c;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: center;
}

.PackagePricingPanel_dateInfoBody__3X5cg {
  margin: 0;
  color: #1a202c;
  color: #1a202c;
  font-size: 0.75rem;
  font-size: 0.75rem;
}

.PackagePricingPanel_error__33Xa5 {
  color: #ed3615;
  color: #ed3615;
}

.PackagePricingPanel_availableDates__16dvA {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-top: 1rem;
  font-size: 0.875rem;
  font-size: 0.875rem
}

@media (min-width: 768px) {

.PackagePricingPanel_availableDates__16dvA {
    margin-top: 0.5rem;
    margin-top: 0.5rem
}
  }

.PackagePricingPanel_verificationIcon__1qTcd {
  margin-right: 0.75rem;
  margin-right: 0.75rem;
  min-width: 2rem;
  min-width: 2rem;
}

.PackagePricingPanel_lodgingContainer__23lqy {
  font-size: 1rem;
  font-size: 1rem;
  margin: 0;
  color: var(--var-900);
  font-weight: 700;
  font-weight: 700;

  padding-top: 0.25rem;

  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-bottom: 0.25rem
}

@media (min-width: 768px) {

.PackagePricingPanel_lodgingContainer__23lqy {
    margin: 0;
    padding-top: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-bottom: 0.25rem
}
  }

.PackagePricingPanel_lodging__dfsn3 {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 600;
  font-weight: 600;
  font-size: 1rem;
  font-size: 1rem;
  margin-top: 0.25rem;
  margin-top: 0.25rem;
}

.PackagePricingPanel_packageBadges__1mmwu {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}

.PackagePricingPanel_soldOut__1CnlC,
.PackagePricingPanel_bookingRange__3pM0T {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 2.5rem;
  height: 2.5rem;
  margin: 1rem 0.25rem 1.5rem 0.25rem;
  margin: 1rem 0.25rem 1.5rem 0.25rem;
  padding: 0.25rem;
  padding: 0.25rem;
  border-radius: 10px;
  border-radius: 10px;
  font-size: 0.75rem;
  font-size: 0.75rem;
  font-weight: 700;
  font-weight: 700;
}

.PackagePricingPanel_soldOut__1CnlC {
  background-color: #ffe9e5;
  background-color: #ffe9e5;
  color: #ed3615;
  color: #ed3615;
}

.PackagePricingPanel_bookingRange__3pM0T {
  background-color: #f5f2ed;
  background-color: #f5f2ed;
  color: #a36731;
  color: #a36731;
}

.PackagePricingPanel_notifyButton__1dyIB {
  background-color: #1a202c;
  background-color: #1a202c;
  color: #fff;
  color: #fff;
}

.PackagePricingPanel_icon__2WLoF {
  width: 1rem;
  width: 1rem;
  height: 1.25rem;
  height: 1.25rem;
}

.PackagePricingPanel_badgeWrapper__2BKh2 {
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem;
}

.PackagePricingPanel_getNotified__6EO7U {
  color: #1a202c;
  color: #1a202c;
  font-size: 0.875rem;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: nowrap;
  margin: 0.75rem 0;
  margin: 0.75rem 0;
}

.PackagePricingPanel_footer__1CtIU {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-style: italic;
  background-color: #edf2f7;
  background-color: #edf2f7;
  border-radius: 5px;
  border-radius: 5px;
  padding: 0.75rem 0;
  padding: 0.75rem 0;
}

.PackagePricingPanel_divider__1i2Un {
  height: 1px;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  margin: 1.25rem 0;
  margin: 1.25rem 0;
}

.PackagePricingPanel_lightningIcon__2HffJ {
  color: #1a202c;
  color: #1a202c;
}

.PackagePricingPanel_formWrapper__2p-a7 {
  display: flex;
  flex-direction: column;
}

.PackagePricingPanel_packageIncludes__1gxI3 {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.PackagePricingPanel_packageIncludesHeading__1tP1O {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.Protip_root__2Ufcx {
  display: flex;
  flex-direction: column;
}

.Protip_header__1CXKD {
  font-weight: 600;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  width: 75%;
  width: 75%;
}

.Protip_icon__Ws1t8 {
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.Protip_content__1ipQ5 {
  max-width: 19rem;
  max-width: 19rem;
  color: #1a202c;
  color: #1a202c;
  overflow: hidden
}

.Protip_content__1ipQ5 a {
    text-decoration: underline;
    color: #000;
    color: #000;
  }

.Protip_content__1ipQ5 ul {
    list-style: disc;
    padding-left: 2.5rem;
    padding-left: 2.5rem;
  }

.Protip_link__2Aduf {
  white-space: nowrap;
  color: #1a202c;
  color: #1a202c;
  font-weight: 600;
  font-weight: 600;
  display: flex;
  align-items: center
}

.Protip_link__2Aduf:hover {
  }

.Protip_linkAlt__2SuqI {
  white-space: nowrap;
  color: black;
  text-decoration: underline;
  font-weight: 600;
  font-weight: 600
}

@media (min-width: 768px) {

.Protip_linkAlt__2SuqI {
    display: flex;
    align-items: center
}
  }

.Protip_linkAlt__2SuqI:hover {
    text-decoration: none;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FormValidationTooltip_root__1tT8k {
  display: flex;
  flex-direction: column;
  min-width: 16rem;
  min-width: 16rem;
}

.FormValidationTooltip_header__3_WkX {
  font-weight: 600;
  font-weight: 600;
  white-space: nowrap;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  width: 75%;
  width: 75%;
}

.FormValidationTooltip_icon__yyPHD {
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.FormValidationTooltip_content__B_UA2 {
  max-width: 19rem;
  max-width: 19rem;
  color: #1a202c;
  color: #1a202c;
  overflow: hidden
}

.FormValidationTooltip_content__B_UA2 a {
    text-decoration: underline;
    color: #000;
    color: #000;
  }

.FormValidationTooltip_content__B_UA2 ul {
    list-style: disc;
    padding-left: 1.25rem;
    padding-left: 1.25rem;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.HasTruncation_readMoreLess__1APXN {
  font-size: 1rem;
  font-size: 1rem;
  margin: 0.5rem 0 0.5rem;
  margin: 0.5rem 0 0.5rem;
  text-decoration: underline;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InquiryWizardPanel_root__2QBDA {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InquiryWizardSection_root__1xauj {
  margin: 0 1.25rem;
  margin: 0 1.25rem
}

@media (min-width: 768px) {

.InquiryWizardSection_root__1xauj {
    margin: 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InquiryWizardStepActivities_sectionContainer__2pSiS {
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.InquiryWizardStepActivities_sectionContainer__2pSiS {
    max-width: 60%;
    max-width: 60%
}
  }

.InquiryWizardStepActivities_sectionActivities__2AyTK {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.InquiryWizardStepActivities_titleStylesMobile__iHJjw {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: bold;
  width: 100%;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InquiryWizardStepPackages_speciesCheckBoxGroup__2SDfL {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.InquiryWizardStepPackages_sectionPackages__1kao3 {
  padding: 0;
  margin-bottom: 2rem;
  margin-bottom: 2rem
}

@media (min-width: 768px) {

.InquiryWizardStepPackages_sectionPackages__1kao3 {
    margin-bottom: 3.5rem;
    margin-bottom: 3.5rem
}
  }

.InquiryWizardStepPackages_titleStyles__3AXYg {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: bold;
  width: 100%;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}


:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InquiryWizardStepSpecies_panelContainer__2EuhK {
  margin-bottom: 2.25rem;
  margin-bottom: 2.25rem;
}

.InquiryWizardStepSpecies_speciesCheckBoxGroup__1tL7d {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InquiryWizardStepParty_panelContainer__1B4kT {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.InquiryWizardStepParty_maxPartyLabel__GlVFB {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.InquiryWizardStepParty_fieldContainer__3t8wM {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.InquiryWizardStepParty_fieldContainer__3t8wM {
    max-width: 23.25rem;
    max-width: 23.25rem
}
  }

.InquiryWizardStepParty_partySizeSelect__1PeIo {
  width: 100%;
}

.InquiryWizardStepParty_selectedDays__3V70b {
  text-align: center;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
  text-decoration: none;
  font-weight: 600;
  font-weight: 600;
}

.InquiryWizardStepParty_bookingDatesButton__3viwW {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  width: 100%;
  cursor: pointer;
  text-align: left;
  padding: 0;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #a1aec1;
  border: 1px solid #a1aec1;
  transition: all 0.2s ease-out;
  z-index: 50;
  z-index: 50;
  position: relative;
}

.InquiryWizardStepParty_bookingDatesButtonLabel__12F_8 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 1rem 1.5rem 1rem 1rem;
  padding: 1rem 1.5rem 1rem 1rem;
}

.InquiryWizardStepParty_bookingDateText__1c0kr {
  margin-left: 1rem;
  margin-left: 1rem;
}

.InquiryWizardStepParty_iconArrow__3Dcgt {
  stroke: var(--matterColor);
  fill: var(--matterColor);
  transition: all 0.2s ease-out;
}

.InquiryWizardStepParty_formGroupHeading__3ytti {
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.InquiryWizardStepParty_formFieldHeading__1L0_V {
  font-size: 1rem;
  font-size: 1rem;
  margin: 0 0 0 0;
  color: #1a202c;
  color: #1a202c;
  font-weight: 700;
  font-weight: 700;

  padding-top: 0.25rem;

  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-bottom: 0.25rem
}

@media (min-width: 768px) {

.InquiryWizardStepParty_formFieldHeading__1L0_V {
    font-size: 1.125rem;
    font-size: 1.125rem;
    margin: 0 0 0 0;
    padding-top: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-bottom: 0.25rem
}
  }

.InquiryWizardStepParty_availableDates__3mE6v {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  padding: 0.5rem 1rem;
  background-color: #f7fafc;
  background-color: #f7fafc;
  border: 1px solid #e2e8f0;
  border: 1px solid #e2e8f0;
  margin-top: 1rem;
  margin-top: 1rem;
  font-size: 0.875rem;
  font-size: 0.875rem
}

@media (min-width: 768px) {

.InquiryWizardStepParty_availableDates__3mE6v {
    margin-top: 0.5rem;
    margin-top: 0.5rem;
    max-width: 35.5rem;
    max-width: 35.5rem
}
  }

.InquiryWizardStepParty_formFieldWrapper__3KZ5G {
  margin-top: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.InquiryWizardStepParty_formFieldWrapper__3KZ5G {
    max-width: 23.25rem;
    max-width: 23.25rem
}
  }

.InquiryWizardStepParty_packageLodgingAddOn__1PDgt {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-top: 0.5rem
}

@media (min-width: 768px) {

.InquiryWizardStepParty_packageLodgingAddOn__1PDgt {
    font-size: 1.125rem;
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
    margin-bottom: 0.25rem
}
  }

.InquiryWizardStepParty_verificationIcon__3ZswJ {
  margin-right: 0.75rem;
  margin-right: 0.75rem;
}

.InquiryWizardStepParty_dateInfo__2uKfK {
  padding: 0 0.5rem;
  padding: 0 0.5rem;
}

.InquiryWizardStepParty_dateInfoTitle__xUoQT {
  color: #1a202c;
  color: #1a202c;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: center;
}

.InquiryWizardStepParty_lodging__2vaIy {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 600;
  font-weight: 600;
  font-size: 1rem;
  font-size: 1rem;
  margin-top: 0.25rem;
  margin-top: 0.25rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InquiryTopics_topicsHeaderWrapper__aRWch {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.InquiryTopics_topicsHeader__28jHx {
  margin-left: 0.5rem;
  margin-left: 0.5rem;
}

.InquiryTopics_topicsList__13wQX {
  list-style: disc;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
}

.InquiryTopics_topicsItem__2QWrl {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InquiryBreakdown_root__MjvKl {
  margin-top: 1rem;
  margin-top: 1rem;
  text-align: left
}

@media (min-width: 768px) {

.InquiryBreakdown_root__MjvKl {
    margin-top: 2rem;
    margin-top: 2rem
}
  }

.InquiryBreakdown_title__1Odi8 {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-size: 1rem
}

@media (min-width: 768px) {

.InquiryBreakdown_title__1Odi8 {
    font-size: 1.25rem;
    font-size: 1.25rem
}
  }

.InquiryBreakdown_subtitle__3gUl0 {
  margin: 0;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.75rem;
  font-size: 0.75rem;
}

.InquiryBreakdown_row__2mUZk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.InquiryBreakdown_rowSubtotal__34P9i {
  margin-top: 1rem;
  margin-top: 1rem;
  padding: 1rem 0;
  padding: 1rem 0;
  border-top: 1px #e2e8f0 solid;
  border-top: 1px #e2e8f0 solid;
  font-weight: 600;
  font-weight: 600;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InquiryWizardStepSummary_heading__2kTKj {
  margin-top: 2rem;
  margin-top: 2rem;
}

.InquiryWizardStepSummary_bookingDetailsTitle__1BeUi {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  margin-left: 1.25rem;
  margin-left: 1.25rem
}

@media (min-width: 768px) {

.InquiryWizardStepSummary_bookingDetailsTitle__1BeUi {
    margin-left: 0
}
  }

@media (min-width: 768px) {

.InquiryWizardStepSummary_detailsGroup__3pVIu {
    display: grid;
    grid-gap: 0.5rem;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(2, minmax(0, 100%));
    grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
    grid-auto-rows: minmax(min-content, max-content)
}
  }

.InquiryWizardStepSummary_detailsSection__3q4s3 {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.InquiryWizardStepSummary_detailsSectionLabel__w0pq3 {
  font-weight: bold;
}

.InquiryWizardStepSummary_summaryContainer__2ce6X {
  order: 1
}

@media (min-width: 768px) {

.InquiryWizardStepSummary_summaryContainer__2ce6X {
    order: 1
}
  }

.InquiryWizardStepSummary_messageContainer__1rtl- {
  order: 4;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem
}

@media (min-width: 768px) {

.InquiryWizardStepSummary_messageContainer__1rtl- {
    order: 2;
    width: 25rem;
    width: 25rem
}
  }

@media (min-width: 1024px) {

.InquiryWizardStepSummary_messageContainer__1rtl- {
    order: 2;
    width: 35.5rem;
    width: 35.5rem
}
  }

.InquiryWizardStepSummary_breakdownContainer__2Y2ve {
  order: 2;
  border-top: 1px solid #e2e8f0;
  border-top: 1px solid #e2e8f0
}

@media (min-width: 768px) {

.InquiryWizardStepSummary_breakdownContainer__2Y2ve {
    display: none
}
  }

.InquiryWizardStepSummary_topicsContainer__2YYs2 {
  order: 3;
  margin-top: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  padding: 1.5rem 0.75rem;
  padding: 1.5rem 0.75rem;
  border-radius: 25px;
  border-radius: 25px;
  border: 1px solid #e2e8f0;
  border: 1px solid #e2e8f0
}

@media (min-width: 768px) {

.InquiryWizardStepSummary_topicsContainer__2YYs2 {
    display: none
}
  }

.InquiryWizardStepSummary_icon__3-_-P {
  width: 2.25rem;
  width: 2.25rem;
  margin-right: 0.75rem;
  margin-right: 0.75rem;
  color: #a1aec1;
  color: #a1aec1;
}

.InquiryWizardStepSummary_iconSectionWrapper__3GVmf {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.InquiryWizardStepSummary_iconSection__23bI- {
  display: flex;
  flex-direction: column;
}

.InquiryWizardStepSummary_iconSectionLabel__35W8n {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.InquiryWizardStepSummary_iconSectionValue__2ASy6 {
  font-weight: 700;
  font-weight: 700;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.InquirySignupForm_root__11lNk {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  height: 100%;

  justify-content: flex-start
}

@media (min-width: 768px) {

.InquirySignupForm_root__11lNk {
    justify-content: space-between
}
  }

.InquirySignupForm_name__2AhVg {
  margin-top: 1rem;
  margin-top: 1rem
}

@media (min-width: 768px) {

.InquirySignupForm_name__2AhVg {
  margin-top: 0.75rem;
  margin-top: 0.75rem
}
    }

@media (min-width: 768px) {

.InquirySignupForm_name__2AhVg {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-top: 0.5rem
}
  }

.InquirySignupForm_firstNameRoot__3EJVr {
  width: 100%
}

@media (min-width: 768px) {

.InquirySignupForm_firstNameRoot__3EJVr {
  margin-top: 1rem;
  margin-top: 1rem
}
  }

@media (min-width: 768px) and (min-width: 768px) {

.InquirySignupForm_firstNameRoot__3EJVr {
  margin-top: 0.75rem;
  margin-top: 0.75rem
}
    }

@media (min-width: 768px) {

.InquirySignupForm_firstNameRoot__3EJVr {
    width: calc(50% - 9px)
}
  }

.InquirySignupForm_lastNameRoot__FuShr {
  margin-top: 1rem;
  margin-top: 1rem
}

@media (min-width: 768px) {

.InquirySignupForm_lastNameRoot__FuShr {
  margin-top: 0.75rem;
  margin-top: 0.75rem
}
    }

.InquirySignupForm_lastNameRoot__FuShr {
  width: 100%
}

@media (min-width: 768px) {

.InquirySignupForm_lastNameRoot__FuShr {
    width: calc(50% - 9px)
}
  }

.InquirySignupForm_email__3jcFE {
  margin-top: 1rem;
  margin-top: 1rem;
}

@media (min-width: 768px) {

.InquirySignupForm_email__3jcFE {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}
    }

.InquirySignupForm_password__11hQx {
  margin-top: 1rem;
  margin-top: 1rem;
}

@media (min-width: 768px) {

.InquirySignupForm_password__11hQx {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}
    }

.InquirySignupForm_phone__2r9Gc {
  margin-top: 1rem;
  margin-top: 1rem;
}

@media (min-width: 768px) {

.InquirySignupForm_phone__2r9Gc {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}
    }

.InquirySignupForm_role__1z5U7 {
  margin-top: 1rem;
  margin-top: 1rem;
}

@media (min-width: 768px) {

.InquirySignupForm_role__1z5U7 {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}
    }

.InquirySignupForm_bottomWrapper__2KLG2 {
  margin-top: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem
}

@media (min-width: 768px) {

.InquirySignupForm_bottomWrapper__2KLG2 {
    margin-top: 2.5rem;
    margin-top: 2.5rem
}
  }

.InquirySignupForm_bottomWrapperText__28My0 {
  margin-top: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.InquirySignupForm_termsText__2voQs {
  font-size: 0.75rem;
  font-size: 0.75rem;
}

.InquirySignupForm_termsLink__WnHfo {
  font-size: 0.75rem;
  font-size: 0.75rem;
  text-decoration: underline
}

.InquirySignupForm_termsLink__WnHfo:hover {
    cursor: pointer;
  }

.InquirySignupForm_inputGroup__2iWPQ {
  position: relative;
}

.InquirySignupForm_passwordIcon__2-6VN {
  position: absolute;
  top: calc(60% - (0.25rem / 2));
  top: calc(60% - (0.25rem / 2));
  right: 0.75rem;
  right: 0.75rem;
  width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
  color: #1a202c;
  color: #1a202c
}

.InquirySignupForm_passwordIcon__2-6VN:hover {
    cursor: pointer;
  }

.InquirySignupForm_passwordIconError__TKXfl {
  position: absolute;
  top: calc(43% - (0.25rem / 2));
  top: calc(43% - (0.25rem / 2));
  right: 0.75rem;
  right: 0.75rem;
  width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
  color: #1a202c;
  color: #1a202c
}

.InquirySignupForm_passwordIconError__TKXfl:hover {
    cursor: pointer;
  }

.InquirySignupForm_rolesSection__R-r95 {
  margin-top: 1.75rem;
  margin-top: 1.75rem;
  font-weight: 600;
  font-weight: 600;
}

.InquirySignupForm_rolesSportsman__14yy4 {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.InquirySignupForm_activitiesSection__2oahr {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}

.InquirySignupForm_fieldLabel__2xk6T {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.InquirySignupForm_inputGroup__2iWPQ {
  position: relative;
}

.InquirySignupForm_formSection__3gRG3 {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.InquirySignupForm_formHeader__3kukd {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 2.25rem;
  font-size: 2.25rem;
}

.InquirySignupForm_formSubHeader__3AnPv {
  margin-top: 1rem;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

@media (min-width: 1024px) {

.InquirySignupForm_coltonContainer__21uBF {
}
  }

.InquirySignupForm_referralOther__2dAbF {
  margin-top: 1.75rem;
  margin-top: 1.75rem;
}

.InquirySignupForm_huntSpeciesContainer__1GFX7 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.InquirySignupForm_huntSpeciesItem__2CwQY {
  flex: 0 1 50%;
  padding-right: 15px;
  padding-left: 15px;
}

.InquirySignupForm_nextBtnDesktop__SfkIj {
  display: inline-block;
  min-width: 21rem;
  min-width: 21rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.InquiryAuthenticationPage_layoutWrapperMain__2NYYP {
  margin: 0;
  padding: 0;
}

.InquiryAuthenticationPage_root__2Qa3y {
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.InquiryAuthenticationPage_content__3lxNu {
  background-color: transparent;
  margin-top: 10px
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_content__3lxNu {
    max-width: 40rem;
    max-width: 40rem;
    min-width: 37.5rem;
    min-width: 37.5rem;
    border-bottom: none
}
  }

/* ================ Tabs & Form ================ */

.InquiryAuthenticationPage_tab__3BRc0 {
  flex-grow: 1;
}

.InquiryAuthenticationPage_bottomWrapper__2ufiG {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_bottomWrapper__2ufiG {
  margin-top: 96px;
}
    }

.InquiryAuthenticationPage_bottomWrapper__2ufiG {

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* Terms of Service modal*/

.InquiryAuthenticationPage_termsWrapper__1wfvk {
  width: 100%;
  padding-top: 10px;
  padding-left: 1rem;
  padding-left: 1rem;
  padding-right: 2rem;
  padding-right: 2rem;
  max-height: 35rem;
  max-height: 35rem
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_termsWrapper__1wfvk {
    width: 37.75rem;
    width: 37.75rem
}
  }

.InquiryAuthenticationPage_termsHeading__pjRo_ {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_termsHeading__pjRo_ {
  font-size: 1.875rem;
  font-size: 1.875rem;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px
}
    }

.InquiryAuthenticationPage_termsHeading__pjRo_ {
  margin: 0 0 19px 0
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_termsHeading__pjRo_ {
    margin: 0 0 19px 0
}
  }

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.InquiryAuthenticationPage_hideOnMobile__2nzoq {
  display: none
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_hideOnMobile__2nzoq {
    display: block
}
  }

/* ================ Close icon ================ */

.InquiryAuthenticationPage_verifyClose__3S9cB {
    /* Position inside modal */
  position: fixed;
  top: 0;
  right: 0;

    /* Some content (like map) is positioning itself on top of close button without z-index handling */
  z-index: calc(100 + 1);
  z-index: calc(100 + 1);

    /* Safari didn't know how to baseline-align svg icon and text */
  display: flex;
  align-items: center;
  width: auto;

    /* Sizing (touch area) */
  padding: 24px;
  margin: 0;
  border: 0;
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_verifyClose__3S9cB {
  padding: 27px 30px;
  position: absolute;
}
    }

.InquiryAuthenticationPage_verifyClose__3S9cB {

    /* Colors */
  background-color: transparent;
  color: #4a5569;
  color: #4a5569;

  cursor: pointer;
}

.InquiryAuthenticationPage_verifyClose__3S9cB:enabled:hover {
  background-color: transparent;
  color: #1a202c;
  color: #1a202c;
  box-shadow: none;
  text-decoration: none;
    }

.InquiryAuthenticationPage_verifyClose__3S9cB:enabled:active {
  background-color: transparent;
  color: #1a202c;
  color: #1a202c;
    }

.InquiryAuthenticationPage_verifyClose__3S9cB:disabled {
  background-color: transparent;
    }

.InquiryAuthenticationPage_verifyClose__3S9cB {

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.InquiryAuthenticationPage_closeText__2GZRu {
    /* Font */
  font-weight: bold;
  font-size: 0.875rem;
  font-size: 0.875rem;
  line-height: 18px;
  text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_closeText__2GZRu {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 6px;
}
    }

.InquiryAuthenticationPage_closeText__2GZRu {
  margin: -2.5px 0 0 0;
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_closeText__2GZRu {
  margin-top: -1px;
}
    }

.InquiryAuthenticationPage_closeIcon__1iSu0 {
  display: inline-block;
  margin-left: 8px;
  padding: 2px 0 4px;
  box-sizing: content-box;
}

/* ================ Typography ================ */

/* Title of the modal */

.InquiryAuthenticationPage_modalTitle__30EGV {
  font-weight: 600;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  font-weight: 700;
  font-weight: 700;
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_modalTitle__30EGV {
  font-weight: 600;
  font-weight: 600;
  line-height: 40px;
  margin: 0;
}
    }

/* Paragraph for the Modal */

.InquiryAuthenticationPage_modalMessage__2vKGn {
  margin: 18px 0 0 0;
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_modalMessage__2vKGn {
  margin: 24px 0 0 0;
}
    }

/* Make the email pop */

.InquiryAuthenticationPage_email__VZlYz {
  font-weight: 700;
  font-weight: 700;
}

/* Helper links */

.InquiryAuthenticationPage_modalHelperLink__17iTe {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: #1a202c;
  color: #1a202c;

    /* Text size should be inherited */
  text-decoration: none;
}

.InquiryAuthenticationPage_modalHelperLink__17iTe:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.InquiryAuthenticationPage_modalHelperLink__17iTe {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_modalHelperLink__17iTe {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.InquiryAuthenticationPage_modalHelperLink__17iTe {
  color: #4a5569;
  color: #4a5569;
  margin: 0;
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_modalHelperLink__17iTe {
  margin: 0;
}
    }

/* Helper texts for the links, not the actual links */

.InquiryAuthenticationPage_modalHelperText__2sk-D {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_modalHelperText__2sk-D {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.InquiryAuthenticationPage_modalHelperText__2sk-D {
  color: #a1aec1;
  color: #a1aec1;
  margin: 0;
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_modalHelperText__2sk-D {
  margin: 0;
}
    }

.InquiryAuthenticationPage_modalIcon__1SPqt {
  height: 48px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_modalIcon__1SPqt {
  height: 64px;
  margin-bottom: 23px
}
    }

.InquiryAuthenticationPage_modalIcon__1SPqt {

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_modalIcon__1SPqt {
    margin-top: 0
}
  }

/* ================ Errors ================ */

.InquiryAuthenticationPage_error__1EZ5x {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_error__1EZ5x {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.InquiryAuthenticationPage_error__1EZ5x {
  margin-top: 24px;
  color: #ff0000;
  color: #ff0000;
}

/* ================ Social logins & SSO ================ */

.InquiryAuthenticationPage_signupWithIdpTitle__3hsLl {
  font-size: 1.5rem;
  font-size: 1.5rem
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_signupWithIdpTitle__3hsLl {
  font-size: 2.25rem;
  font-size: 2.25rem
}
    }

.InquiryAuthenticationPage_signupWithIdpTitle__3hsLl {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1rem;
  padding-top: 1rem;
  color: #1a202c;
  color: #1a202c
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_signupWithIdpTitle__3hsLl {
    margin-top: 6px
}
  }

.InquiryAuthenticationPage_socialButtonWrapper__1HV-D {
  margin-top: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InquiryAuthenticationPage_confirmInfoText__3h3NV {
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
}

.InquiryAuthenticationPage_buttonIcon__-IzXs {
  position: absolute;
  left: 0;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
}

.InquiryAuthenticationPage_socialButtonsOr__2O4RI {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-size: 0.875rem;
  width: 100%;
  height: 2rem;
  height: 2rem;
  margin: 1rem 0 0 0;
  margin: 1rem 0 0 0;
  text-align: center;
  position: relative;
  background-color: #fff;
  background-color: #fff
}

.InquiryAuthenticationPage_socialButtonsOr__2O4RI:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #cbd5e0;
    border-bottom: solid 1px #cbd5e0;
    position: absolute;
    left: 0;
    top: 50%;
    top: 50%;
    z-index: 1;
  }

@media (min-width: 768px) {
  .InquiryAuthenticationPage_socialButtonsOr__2O4RI {
    height: 2rem;
    height: 2rem;
    margin: 1rem 0 0.5rem 0;
    margin: 1rem 0 0.5rem 0;
  }
}

.InquiryAuthenticationPage_socialButtonsOrText___nuxJ {
  background-color: #fff;
  background-color: #fff;
  z-index: 3;
  padding: 0 1.25rem 0 1.25rem;
  padding: 0 1.25rem 0 1.25rem;
  position: relative;
  margin: 0;
  font-weight: 700;
  font-weight: 700;
}

.InquiryAuthenticationPage_headerWrapper__1y-8P {
  font-size: 2.25rem;
  font-size: 2.25rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  text-align: center;
  margin: 0 auto 1.5rem auto;
  margin: 0 auto 1.5rem auto;
  width: 100%
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_headerWrapper__1y-8P {
    margin: auto
}
  }

.InquiryAuthenticationPage_topbar__3ixFJ {
  width: 100%;
  height: 4.5rem;
  height: 4.5rem;
  background-color: #fff;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_topbar__3ixFJ {
    display: none
}
  }

.InquiryAuthenticationPage_logoMobile__2f2Bm {
  display: block
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_logoMobile__2f2Bm {
    display: none
}
  }

.InquiryAuthenticationPage_logoDesktop__AwR9r {
  display: none
}

@media (min-width: 768px) {

.InquiryAuthenticationPage_logoDesktop__AwR9r {
    display: block
}
  }

.InquiryAuthenticationPage_tabContent__2Tjb- {
  font-size: 1rem;
  font-size: 1rem;
}

.InquiryAuthenticationPage_reviewWrapper__uslkB {
  display: flex;
  width: 100%;
  padding-top: 6.25rem;
  padding-top: 6.25rem;
  justify-content: center;
  flex-grow: 2;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InquiryWizardStepSignup_titleStylesMobile__3IFRn {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: bold;
  min-width: 100%
}

@media (min-width: 768px) {

.InquiryWizardStepSignup_titleStylesMobile__3IFRn {
    display: none
}
  }

.InquiryWizardStepSignup_titleStylesDesktop__1cvxk {
  display: none
}

@media (min-width: 768px) {

.InquiryWizardStepSignup_titleStylesDesktop__1cvxk {
    display: block;
    font-weight: 800;
    font-size: 1.5rem;
    font-size: 1.5rem
}
  }

.InquiryWizardStepSignup_stepStyles__xEmA1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-size: 1rem
}

@media (min-width: 768px) {

.InquiryWizardStepSignup_stepStyles__xEmA1 {
    display: none
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InquiryWizardPanelHeading_root__g-rLM {
  margin-bottom: 0
}

@media (min-width: 768px) {

.InquiryWizardPanelHeading_root__g-rLM {
    margin-bottom: 0.75rem;
    margin-bottom: 0.75rem
}
  }

.InquiryWizardPanelHeading_title__3xwNy {
  display: none
}

@media (min-width: 768px) {

.InquiryWizardPanelHeading_title__3xwNy {
    display: block;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1.25rem;
    font-size: 1.25rem;
    font-weight: 700;
    font-weight: 700
}
  }

.InquiryWizardPanelHeading_subtitle__1ujdW {
  margin: 0;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InquiryWizard_header__oxlSb {
  display: none
}

@media (min-width: 768px) {

.InquiryWizard_header__oxlSb {
    display: block
}
  }

.InquiryWizard_title__rZmkD {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.125rem;
  font-size: 1.125rem;
}

@media (min-width: 768px) {

.InquiryWizard_title__rZmkD {
  font-size: 1.5rem;
  font-size: 1.5rem;
}
    }

.InquiryWizard_viewPropertyLink__3PHqn {
  font-weight: 600;
  font-weight: 600
}

.InquiryWizard_viewPropertyLink__3PHqn:hover {
    text-decoration: none;
  }

.InquiryWizard_statusTitle__2u1bp {
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem
}

@media (min-width: 768px) {

.InquiryWizard_statusTitle__2u1bp {
    margin-top: 0.75rem;
    margin-top: 0.75rem
}
  }

.InquiryWizard_controlsContainer__1V9ji {
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  margin: 0 2rem;
  margin-bottom: 3rem;
  margin-bottom: 3rem
}

@media (min-width: 768px) {

.InquiryWizard_controlsContainer__1V9ji {
    margin-left: 0;
    margin-right: 0
}
  }

.InquiryWizard_listingHeadingWrapper__kKQ3m {
  display: none
}

@media (min-width: 768px) {

.InquiryWizard_listingHeadingWrapper__kKQ3m {
    display: block
}
  }

.InquiryWizard_controls__1WqWB {
  margin-top: 0.75rem;
  margin-top: 0.75rem
}

@media (min-width: 768px) {

.InquiryWizard_controls__1WqWB {
    margin-top: 0
}
  }

.InquiryWizard_errorMessage__11GdV {
  color: #ed3615;
  color: #ed3615;
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}

.InquiryWizard_controlsFooter__3ia4Y {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-size: 0.875rem
}

@media (min-width: 768px) {

.InquiryWizard_controlsFooter__3ia4Y {
    order: -1;
    padding: 1rem 0;
    padding: 1rem 0;
    margin-top: 0;
    margin-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-top: 1px #e2e8f0 solid;
    border-top: 1px #e2e8f0 solid;
    font-size: 0.75rem;
    font-size: 0.75rem;
    max-width: 35.5rem;
    max-width: 35.5rem
}
  }

.InquiryWizard_formContainer__1sLPb {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.InquiryWizard_formInnerContainer__1hIVI {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
  padding: 1.75rem 0;
  padding: 1.75rem 0
}

@media (min-width: 768px) {

.InquiryWizard_formInnerContainer__1hIVI {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    margin: 2.75rem auto 1.75rem auto;
    margin: 2.75rem auto 1.75rem auto
}
  }

.InquiryWizard_wizardElementsContainer__VjgyC {
  display: flex;
  flex-direction: column;
  margin-bottom: 7.25rem;
  margin-bottom: 7.25rem;
  padding: 1.25rem 0;
  padding: 1.25rem 0;
  order: 2
}

@media (min-width: 768px) {

.InquiryWizard_wizardElementsContainer__VjgyC {
    order: 1;
    width: 62%;
    padding-right: 8rem;
    padding-right: 8rem;
    padding-top: 0
}
  }

.InquiryWizard_stepsContainer__3QFNl {
  flex: 1 1;
}

.InquiryWizard_sidebarContainer__gRgfi {
  display: block;
  order: 1
}

@media (min-width: 768px) {

.InquiryWizard_sidebarContainer__gRgfi {
    order: 2;
    width: 38%
}
  }

@media (min-width: 768px) {

.InquiryWizard_sidebarPanel__1QR8a {
    padding: 1.25rem;
    padding: 1.25rem;
    border-radius: 25px;
    border-radius: 25px;
    border: 1px solid #e2e8f0;
    border: 1px solid #e2e8f0;
    box-shadow: 0 40px 32px -24px rgba(15, 15, 15, 0.12);
    margin-bottom: 1.75rem;
    margin-bottom: 1.75rem
}
  }

.InquiryWizard_desktopWizardStatus__4y--N {
  display: none
}

@media (min-width: 768px) {

.InquiryWizard_desktopWizardStatus__4y--N {
    display: block
}
  }

.InquiryWizard_mobileWizardStatus__qM9ak {
  display: block
}

@media (min-width: 768px) {

.InquiryWizard_mobileWizardStatus__qM9ak {
    display: none
}
  }

@media (min-width: 768px) {

.InquiryWizard_nextBtnMobile__uJQDc {
    display: none
}
  }

.InquiryWizard_nextBtnDesktop__2zfK_ {
  display: none
}

@media (min-width: 768px) {

.InquiryWizard_nextBtnDesktop__2zfK_ {
    display: inline-block;
    min-width: 21rem;
    min-width: 21rem
}
  }

.InquiryWizard_breadcrumbLink__3pZ4f {
  color: #718096;
  color: #718096;
}

.InquiryWizard_inquirySectionDesktop__VmWsW {
  display: none
}

@media (min-width: 768px) {

.InquiryWizard_inquirySectionDesktop__VmWsW {
    display: block
}
  }

.InquiryWizard_modalContent__3ASj6 {
  height: 100vh;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  overflow-y: auto
}

@media (min-width: 768px) {

.InquiryWizard_modalContent__3ASj6 {
    max-height: 60vh;
    margin-bottom: 0
}
  }

.InquiryWizard_modalContent__3ASj6 a {
  text-decoration: underline;
}

.InquiryWizard_containerStyles__1P3Yk {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  max-width: 50rem;
  padding: 3rem 1.5rem 0 1.5rem;
  padding: 3rem 1.5rem 0 1.5rem;
  border: 2px solid transparent;
  background-color: #fff;
  background-color: #fff;
  flex-grow: 0;
  min-width: 100vw;
  height: 100%;
  overflow: hidden
}

@media (min-width: 1024px) {

.InquiryWizard_containerStyles__1P3Yk {
    height: auto;
    border-radius: 12px;
    padding: 1.5rem;
    padding: 1.5rem;
    min-width: 40%;
    min-width: 40%
}
  }

.InquiryWizard_contentWrapper__eYXwI {
  padding-top: 2.25rem;
  padding-top: 2.25rem;
}

.InquiryWizard_contentHeader__1xIQl {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
}

.InquiryWizard_descriptionWrapper__JCHlJ {
  margin: 0 0 1.5rem 0;
  margin: 0 0 1.5rem 0
}

.InquiryWizard_descriptionWrapper__JCHlJ a {
    color: #000;
    color: #000;
    text-decoration: underline;
  }

.InquiryWizard_descriptionWrapper__JCHlJ ul {
    margin: 1rem 0;
    margin: 1rem 0;
    padding-left: 2.5rem;
    padding-left: 2.5rem;
    list-style-type: disc;
  }

.InquiryWizard_descriptionWrapper__JCHlJ {
  > div > p {
    margin-top: 0;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.Badge_root__KRlIX {
  background-color: white;
  opacity: 0.8;
  border-radius: 5px;
  border-radius: 5px;
  font-size: 0.75rem;
  font-size: 0.75rem;
  padding: 0.25rem;
  padding: 0.25rem;
  position: absolute;
  bottom: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem;
  left: 0.5rem;
  color: #2b313d;
  font-weight: 600;
  font-weight: 600;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.AccountDetails_verifySection__24aGE {
  background-color: #f7fafc;
  background-color: #f7fafc;
  padding: 1.5rem 1rem 1.5rem 1rem;
  padding: 1.5rem 1rem 1.5rem 1rem;
  border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  color: #1a202c;
  color: #1a202c;
}

.AccountDetails_verifySectionHeader__2thQk {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.AccountDetails_verifySectionIcon__1Ubpj {
  color: #3e704b;
  color: #3e704b;
}

.AccountDetails_verifySectionHeaderText__1v1T2 {
  margin-left: 0.75rem;
  margin-left: 0.75rem;
}

.AccountDetails_verifySectionContent__1reiX {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.AccountDetails_verifySectionLink__2co0v {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-weight: 600;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PaymentMethods_root__1aoZP {
  color: #1a202c;
  color: #1a202c;
}

.PaymentMethods_divider__zlMkA {
  height: 1px;
  width: 100%;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  margin: 1rem 0;
  margin: 1rem 0;
}

.PaymentMethods_paymentMethodFormWrapper__dDnoP {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.PaymentMethods_helpWrapper__3C-Xt {
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;
  margin-top: 0.25rem;
}

.PaymentMethods_helpHeader__1On-_ {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.PaymentMethods_helpText__2gW9o a {
    text-decoration: underline;
  }

.PaymentMethods_cardHeading__1FhPq {
  margin: 0 0 0.5rem 0;
  margin: 0 0 0.5rem 0;
  color: #1a202c;
  color: #1a202c;
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.YourMembership_root__3QGlX {
  color: #1a202c;
  color: #1a202c;
}

.YourMembership_banner__26ccB {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f7fafc;
  background-color: #f7fafc;
  border-radius: 10px;
  border-radius: 10px;
  padding: 2rem 1rem;
  padding: 2rem 1rem;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.YourMembership_bannerLogoImage__2FPom {
  width: 13rem;
  width: 13rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.YourMembership_bannerDescription__ZTYxt {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.YourMembership_membershipDetailsWrapper__3cm8V {
  display: flex;
  flex-direction: column;
}

.YourMembership_membershipHeader__n9Ugg {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.YourMembership_membershipDetails__3-Jbp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  grid-gap: 1rem;
  gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem
}

@media (min-width: 768px) {

.YourMembership_membershipDetails__3-Jbp {
    flex-direction: row
}
  }

.YourMembership_membershipTypeWrapper__3x-JC {
  display: flex;
  flex-direction: column;
}

.YourMembership_membershipType__1xOIi {
  font-weight: 700;
  font-weight: 700;
}

.YourMembership_membershipPrice__Bnb01 {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.YourMembership_membershipAction__1VldL {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.YourMembership_membershipActionButton__PnWtt {
  text-decoration: underline;
}

.YourMembership_membershipStatusWrapper__HDRiP {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-weight: 700;
  font-size: 0.75rem;
  font-size: 0.75rem;
  border-radius: 5px;
  border-radius: 5px;
  background-color: #fffae2;
  background-color: #fffae2;
  border-left: 6px solid #fad44e;
  border-left: 6px solid #fad44e;
  padding: 0.75rem;
  padding: 0.75rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.CancelMembershipModal_header__1sKLe {
  display: flex;
  text-align: center;
  color: #1a202c;
  color: #1a202c;
  font-weight: 700;
  font-weight: 700;
  font-size: 1.25rem;
  font-size: 1.25rem;
}

.CancelMembershipModal_buttonYes__1ZK_2 {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

.CancelMembershipModal_errorMessage__2ubrO {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  color: #ca2d10;
  color: #ca2d10;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #ca2d10;
  border: 1px solid #ca2d10;
  padding: 0.75rem;
  padding: 0.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ForLandownersCard_root__ytpon {
  border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem;
  padding: 2rem;
  background-color: #fff;
  background-color: #fff;
  border: 1px #e2e8f0 solid;
  border: 1px #e2e8f0 solid
}

@media (min-width: 768px) {

.ForLandownersCard_root__ytpon {
    display: flex;
    align-items: center;
    justify-content: space-between
}
  }

@media (min-width: 768px) {

.ForLandownersCard_contentContainer__1SrR1 {
    max-width: 66.666667%;
    max-width: 66.666667%;
    display: flex
}
  }

.ForLandownersCard_icon__2e6tF {
  width: 4.5rem;
  width: 4.5rem;
  height: 4.5rem;
  height: 4.5rem
}

@media (min-width: 768px) {

.ForLandownersCard_icon__2e6tF {
    width: 6rem;
    width: 6rem;
    height: 6rem;
    height: 6rem;
    flex-shrink: 0
}
  }

.ForLandownersCard_title__1rUWr {
  text-transform: uppercase;
  font-weight: lighter;
  color: #718096;
  color: #718096;
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin: 0.5rem 0;
  margin: 0.5rem 0;
}

.ForLandownersCard_subtitle__1looR {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.ForLandownersCard_ctaContainer__A8fYS {
  margin-top: 2rem;
  margin-top: 2rem;
  text-align: center
}

@media (min-width: 768px) {

.ForLandownersCard_ctaContainer__A8fYS {
    margin-top: 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ActivityCard_root__1IavZ {
  position: relative;
  border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  transition: ease-in 0.2s;
  transition: ease-in 0.2s
}

.ActivityCard_root__1IavZ:hover {
    cursor: pointer;
    text-decoration: none;
    transform: scale(1.02);
  }

.ActivityCard_container__1sMqe {
  display: flex;
  flex-direction: column;
}

.ActivityCard_imageContainer__34gyg {
  padding-top: calc(148 / 145 * 100%);
  overflow: hidden;
  display: block;
  box-sizing: border-box;
}

.ActivityCard_image__q0X1A {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.ActivityCard_title__2w7uD {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  text-align: center;
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  padding: 0.5rem;
  padding: 0.5rem;
  z-index: 10;
  z-index: 10;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionAvailableActivities_root__dUyHU {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3.75rem;
  margin-bottom: 3.75rem;
  padding: 1.5rem;
  padding: 1.5rem
}

@media (min-width: 768px) {

.SectionAvailableActivities_root__dUyHU {
    padding: 0
}
  }

.SectionAvailableActivities_openBookingForm__2zBEm {
  /* Ensure that mobile button is over Footer too */
  z-index: 60;
  z-index: 60;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1rem 1rem 0 1rem;
  padding: 1rem 1rem 0 1rem;
  background-color: #fff;
  background-color: #fff;
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0)
}

@media (min-width: 768px) {

.SectionAvailableActivities_openBookingForm__2zBEm {
    padding: 18px 60px 18px 60px
}
  }

@media (min-width: 1024px) {

.SectionAvailableActivities_openBookingForm__2zBEm {
    display: none
}
  }

.SectionAvailableActivities_availLink__2qJ1r {
  width: 100%;
}

.SectionAvailableActivities_bookButton__3H1el {
  border-radius: 25px;
  border-radius: 25px;
  margin-bottom: 1em;
}

.SectionAvailableActivities_closedListingButton__37vGU {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.SectionAvailableActivities_title___f98P {
  font-size: 1.125rem;
  font-size: 1.125rem
}

@media (min-width: 768px) {

.SectionAvailableActivities_title___f98P {
  font-size: 1.5rem;
  font-size: 1.5rem
}
    }

.SectionAvailableActivities_title___f98P {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #1a202c;
  color: #1a202c;
  display: flex;
  margin-bottom: 2rem;
  margin-bottom: 2rem
}

@media (min-width: 768px) {

.SectionAvailableActivities_title___f98P {
    margin-bottom: 1.25rem;
    margin-bottom: 1.25rem
}
  }

.SectionAvailableActivities_activities__3YIjw {
  display: grid;
  grid-gap: 1rem;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, minmax(9rem, 50%));
  grid-template-columns: repeat(2, minmax(9rem, 50%));
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content)
}

@media (min-width: 768px) {

.SectionAvailableActivities_activities__3YIjw {
    grid-gap: 2.75rem;
    grid-gap: 2.75rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionAvailablePackages_root__1bjGu {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem 1.5rem 0 1.5rem;
  padding: 1.5rem 1.5rem 0 1.5rem
}

@media (min-width: 768px) {

.SectionAvailablePackages_root__1bjGu {
    padding: 0;
    margin-bottom: 1.25rem;
    margin-bottom: 1.25rem
}
  }

.SectionAvailablePackages_title__2w9HK {
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: bold;
  color: #1a202c;
  color: #1a202c;
  display: flex;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.SectionAvailablePackages_title__2w9HK {
    margin-bottom: 1.25rem;
    margin-bottom: 1.25rem
}
  }

.SectionAvailablePackages_activityTitle__34U8P {
  font-size: 1.125rem;
  font-size: 1.125rem
}

@media (min-width: 768px) {

.SectionAvailablePackages_activityTitle__34U8P {
  font-size: 1.5rem;
  font-size: 1.5rem
}
    }

.SectionAvailablePackages_activityTitle__34U8P {
  color: #1a202c;
  color: #1a202c;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem
}

@media (min-width: 768px) {

.SectionAvailablePackages_activityTitle__34U8P {
    margin-bottom: 1rem;
    margin-bottom: 1rem
}
  }

.SectionAvailablePackages_tabWrapper__25Kgq {
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem
}

@media (min-width: 768px) {

.SectionAvailablePackages_tabWrapper__25Kgq {
    margin-bottom: 1.25rem;
    margin-bottom: 1.25rem
}
  }

.SectionAvailablePackages_openBookingForm__7wHyM {
  /* Ensure that mobile button is over Footer too */
  z-index: 50;
  z-index: 50;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1rem 1rem 0 1rem;
  padding: 1rem 1rem 0 1rem;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 10%);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0)
}

@media (min-width: 768px) {

.SectionAvailablePackages_openBookingForm__7wHyM {
    padding: 18px 60px 18px 60px
}
  }

@media (min-width: 1024px) {

.SectionAvailablePackages_openBookingForm__7wHyM {
    display: none
}
  }

.SectionAvailablePackages_availLink__1KMPP {
  width: 100%;
}

.SectionAvailablePackages_bookButton__1JyFy {
  border-radius: 25px;
  border-radius: 25px;
  margin-bottom: 1em;
}

.SectionAvailablePackages_closedListingButton__3LaxS {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SpeciesFormItems_root__3oZMi {
}

.SpeciesFormItems_title__2I_FC {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  font-weight: 700;
  font-weight: 700;
}

.SpeciesFormItems_popularList__2PqeJ {
  display: grid;
  grid-gap: 1.25rem 0.75rem;
  grid-gap: 1.25rem 0.75rem;
  grid-template-columns: repeat(2, minmax(0, 50%));
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content)
}

@media (min-width: 768px) {

.SpeciesFormItems_popularList__2PqeJ {
    grid-gap: 2.5rem 2rem;
    grid-gap: 2.5rem 2rem;
    grid-template-columns: repeat(4, minmax(0, 25%))
}
  }

.SpeciesFormItems_popularListItem__33znJ {
}

.SpeciesFormItems_otherSpeciesColumns__1a2aT {
  margin-top: 1.5rem;
  margin-top: 1.5rem;
  display: grid;
  grid-gap: 1.5rem 2.5rem;
  grid-gap: 1.5rem 2.5rem;
  grid-template-columns: repeat(1, minmax(0, 100%));
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content)
}

@media (min-width: 768px) {

.SpeciesFormItems_otherSpeciesColumns__1a2aT {
    grid-gap: 1.5rem 2.5rem;
    grid-gap: 1.5rem 2.5rem;
    grid-template-columns: repeat(4, minmax(0, 25%))
}
  }

.SpeciesFormItems_otherSpeciesColumn__12-dM {
}

.SpeciesFormItems_otherSpeciesItem__24eLW {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem
}

.SpeciesFormItems_otherSpeciesItem__24eLW:last-child {
    margin-bottom: 0;
  }

.SpeciesFormItems_otherSpeciesItemActive__7qsqU {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

.SpeciesFormItems_otherSpeciesItemActive__7qsqU:last-child {
    margin-bottom: 0;
  }

.SpeciesFormItems_fieldCheckboxLabel__26lF6 {
  margin-bottom: 0;
}

.SpeciesFormItems_showMore__2psze {
  cursor: pointer;
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  font-weight: 600;
  font-weight: 600;
  margin-top: 3rem;
  margin-top: 3rem;
  color: #3e704b;
  color: #3e704b;
  display: flex;
  align-items: baseline;
}

.SpeciesFormItems_arrowIcon__1pseU {
  stroke: #3e704b;
  stroke: #3e704b;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
  transition: ease-in 0.2s;
  transition: ease-in 0.2s
}

.SpeciesFormItems_arrowIcon__1pseU > path {
    /* Translate because the icons are annoyingly not centered in
       their viewbox which makes our rotation look bad */
    transform: translateY(1px);
  }

.SpeciesFormItems_arrowIconDown__3KjaU {
  /* Rotate instead of changing icon so we can animate it */
  transform: rotateZ(180deg);
}

.SpeciesFormItems_priceInput__2XNO4 {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FishFormItems_root__3-TJH {
}

.FishFormItems_title__h3106 {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  font-weight: 700;
  font-weight: 700;
}

.FishFormItems_columns__Uaj4V {
  margin-top: 2rem;
  margin-top: 2rem;
  display: grid;
  grid-gap: 1.5rem 2.5rem;
  grid-gap: 1.5rem 2.5rem;
  grid-template-columns: repeat(1, minmax(0, 100%));
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content)
}

@media (min-width: 768px) {

.FishFormItems_columns__Uaj4V {
    grid-gap: 1.5rem 2.5rem;
    grid-gap: 1.5rem 2.5rem;
    grid-template-columns: repeat(4, minmax(0, 25%))
}
  }

.FishFormItems_column__23vLY {
}

.FishFormItems_item__3EyTY {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem
}

.FishFormItems_item__3EyTY:last-child {
    margin-bottom: 0;
  }

.FishFormItems_itemActive__22l-4 {
}

.FishFormItems_fieldCheckboxLabel__Srpsr {
  margin-bottom: 0;
}

@media (min-width: 768px) {

.FishFormItems_fieldPriceInput__2O4Td {
    max-width: 14rem;
    max-width: 14rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.LessMore_button__IO8w7 {
  color: #3e704b;
  color: #3e704b;
  font-weight: 700;
  font-weight: 700;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.LessMore_button__IO8w7 > svg {
  width: 1rem;
  width: 1rem;
  height: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PhotoLibrary_root__1gxla {
  width: 100%;
  display: flex;
  justify-content: center;
}

.PhotoLibrary_container__1XDC7 {
  width: 100%;
  padding-bottom: 8rem;
  padding-bottom: 8rem
}

@media (min-width: 768px) {

.PhotoLibrary_container__1XDC7 {
    max-width: 66.666667%;
    max-width: 66.666667%;
    padding: 0 0 6.25rem 0;
    padding: 0 0 6.25rem 0
}
  }

.PhotoLibrary_header__1Zhu2 {
  font-weight: 700;
  font-weight: 700;
  font-size: 1.5rem;
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.PhotoLibrary_header__1Zhu2 {
    font-size: 1.875rem;
    font-size: 1.875rem
}
  }

.PhotoLibrary_imagesContainer__3jP1g {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(-1 * 0.25rem);
  margin: 0 calc(-1 * 0.25rem)
}

@media (min-width: 768px) {

.PhotoLibrary_imagesContainer__3jP1g {
    margin: 0 calc(-1 * 0.75rem);
    margin: 0 calc(-1 * 0.75rem)
}
  }

.PhotoLibrary_imageContainer__32qve {
  padding: 0.25rem 0.25rem 1.25rem 0.25rem;
  padding: 0.25rem 0.25rem 1.25rem 0.25rem;
  cursor: pointer;
  width: 50%;
  width: 50%
}

@media (min-width: 768px) {

.PhotoLibrary_imageContainer__32qve {
    width: 33.333333%;
    width: 33.333333%;
    padding: 0.75rem;
    padding: 0.75rem
}
  }

.PhotoLibrary_imageWrapper__ujMGu {
  display: block;
  width: 100%;
  position: relative;
}

.PhotoLibrary_imageBox__2vu6S {
  position: relative;
  padding-bottom: 66.666667%;
  padding-bottom: 66.666667%;
}

.PhotoLibrary_imageSpan__3dzad {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: auto;
  width: initial;
  height: auto;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;
}

.PhotoLibrary_image__2GnFJ {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 5px;
  border-radius: 5px;
}

.PhotoLibrary_tagContainer__2tsUe {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.PhotoLibrary_tagLabel__2hl8- {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-weight: 700;
}

.PhotoLibrary_tagBadges__gGt9M {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.PhotoLibrary_tagBadge__3ctig {
  color: #1a202c;
  color: #1a202c;
  padding: 0.25rem 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 10px;
  border-radius: 10px;
  background-color: #edf2f7;
  background-color: #edf2f7;
  white-space: nowrap
}

@media (min-width: 768px) {

.PhotoLibrary_tagBadge__3ctig {
    padding: 0.25rem 1rem;
    padding: 0.25rem 1rem
}
  }

.PhotoLibrary_checkIconWrapper__1WPvu {
  position: absolute;
  left: 0.5rem;
  left: 0.5rem;
  top: 0.5rem;
  top: 0.5rem;
  width: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  height: 1.75rem;
  border: 2px solid #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  opacity: 0.75
}

@media (min-width: 768px) {

.PhotoLibrary_checkIconWrapper__1WPvu {
    left: 0.75rem;
    left: 0.75rem;
    top: 0.75rem;
    top: 0.75rem
}
  }

.PhotoLibrary_checkIconWrapperSelected__2bVUd {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3e704b;
  background-color: #3e704b;
}

.PhotoLibrary_checkIcon__RGiDp > path {
    fill: #fff;
    fill: #fff;
  }

.PhotoLibrary_saveBarContainer__1Rjuv {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  background-color: #fff;
  padding: 0.75rem;
  padding: 0.75rem;
  display: flex;
  justify-content: center
}

@media (min-width: 768px) {

.PhotoLibrary_saveBarContainer__1Rjuv {
    padding: 1.5rem;
    padding: 1.5rem
}
  }

.PhotoLibrary_saveBar__2vI-N {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PhotoLibrary_saveBarText__13Cp8 {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  margin-top: 0.5rem;
}

.PhotoLibrary_dropzoneDescription__1eiCd {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.PhotoLibrary_imagesLimitValidation__GKW6l {
  color: #ed3615;
  color: #ed3615;
}

.PhotoLibrary_dropzoneContainer__1IAff {
  position: relative;
  margin-top: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.PhotoLibrary_dropzoneDropNotification__rT2yb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: dashed 1px #cbd5e0;
  border: dashed 1px #cbd5e0;
  border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
}

.PhotoLibrary_dropzoneDropNotificationLabel__1-j3l {
  margin: 0;
}

.PhotoLibrary_imageCategories__3JokO {
  display: grid;
  grid-gap: 1.25rem 1.5rem;
  grid-gap: 1.25rem 1.5rem;
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content)
}

@media (min-width: 768px) {

.PhotoLibrary_imageCategories__3JokO {
    grid-gap: 1rem 1rem;
    grid-gap: 1rem 1rem
}
  }

.PhotoLibrary_progressBar__1bGFY {
  margin-top: 2rem;
  margin-top: 2rem;
}

.PhotoLibrary_photoPreviewTagsContainer__1FEGu {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.PhotoLibrary_photoPreviewTagsLinkContainer__ntaIr {
  display: flex;
  align-items: center;
}

.PhotoLibrary_photoPreviewTagsIcon__NDI-L {
  height: 1rem;
  height: 1rem;
  width: 1rem;
  width: 1rem;
}

.PhotoLibrary_photoPreviewTagsLink__2EnsU {
  text-decoration: underline;
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-left: 0.25rem;
  margin-left: 0.25rem;
}

.PhotoLibrary_photoPreviewTagsItem__26ur7 {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-size: 0.75rem;
  border-radius: 20px;
  border-radius: 20px;
  border: 1px solid #cbd5e0;
  border: 1px solid #cbd5e0;
  padding: 0.5rem;
  padding: 0.5rem;
  white-space: nowrap;
  color: #fff;
  color: #fff;
  background-color: #1a202c;
  background-color: #1a202c
}

.PhotoLibrary_photoPreviewTagsItem__26ur7:hover {
    text-decoration: none;
  }

.PhotoLibrary_photoPreviewTagsItemClose__BiGf0 {
  cursor: pointer
}

.PhotoLibrary_photoPreviewTagsItemClose__BiGf0 > svg > path {
    fill: #fff;
    fill: #fff;
  }

.PhotoLibrary_photoPreviewTagsCloseIcon__3Kxrq {
  width: 10px;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
}

.PhotoLibrary_photoPreviewTagsTooltip__rBpQ9 {
  max-height: 100vh;
  overflow: auto;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FilterDropdown_filterButton__2ecVc {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #e2e8f0;
  border-color: #e2e8f0;
  grid-gap: 8px;
  gap: 8px;
  padding: 8px 16px;
}

.FilterDropdown_filterButtonSelected__1tMgj {
  background-color: #1a202c;
  background-color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;
  color: #fff;
  color: #fff;
}

.FilterDropdown_filterButtonSelected__1tMgj:hover {
  background-color: #1a202c;
  background-color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;
  color: #fff;
  color: #fff;
}

.FilterDropdown_filterButtonOpen__oT4fV {
  border-color: #1a202c;
  border-color: #1a202c;
}

.FilterDropdown_dropdown__1l2WK {
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  padding: 1rem;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e8f0;
  border: 1px solid #e2e8f0;
  background-color: #fff;
  background-color: #fff;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.FilterDropdown_filterLabel__1y5H6 {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: normal;
  color: #1a202c;
  color: #1a202c;
  white-space: nowrap;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InboxFilters_filters__vmcHV {
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  width: 100%;
}

.InboxFilters_filterButton__3T5Zu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #e2e8f0;
  border-color: #e2e8f0;
  grid-gap: 8px;
  gap: 8px;
}

.InboxFilters_filterButton__3T5Zu:hover {
  background-color: #f3f6f3;
  color: #1a202c;
  color: #1a202c;
}

.InboxFilters_filterButton__3T5Zu:focus {
  outline: 2px solid #1a202c;
  outline: 2px solid #1a202c;
}

.InboxFilters_filterButtonSelected__TBxAT {
  background-color: #1a202c;
  background-color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;
  color: #fff;
  color: #fff;
}

.InboxFilters_filterButtonSelected__TBxAT:hover {
  background-color: #1a202c;
  background-color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;
  color: #fff;
  color: #fff;
}

.InboxFilters_filterButtonSelected__TBxAT:focus {
  outline: none;
}

.InboxFilters_filterButtonOpen__V8pua {
  border-color: #1a202c;
  border-color: #1a202c;
}

.InboxFilters_filterDropdown__3Na2R {
  position: relative;
  display: flex;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InboxList_root__2nmtA {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%
}

@media (min-width: 768px) {

.InboxList_root__2nmtA {
    overflow: hidden
}
  }

.InboxList_headerContainer__3EEY- {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  padding: 2rem 1.25rem 0 1.25rem;
  padding: 2rem 1.25rem 0 1.25rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem
}

@media (min-width: 768px) {

.InboxList_headerContainer__3EEY- {
    flex-wrap: wrap;
    padding: 2rem 0.5rem 0 0.75rem;
    padding: 2rem 0.5rem 0 0.75rem
}
  }

.InboxList_header__3ykm_ {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700
}

@media (min-width: 768px) {

.InboxList_header__3ykm_ {
    width: 100%;
    font-size: 1.25rem;
    font-size: 1.25rem
}
  }

.InboxList_typeDropdownContainer__IVeve {
  position: relative;
  flex-grow: 2;
}

.InboxList_switch__2CO64 {
  display: flex;
  border-radius: 20px;
  border-radius: 20px;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  padding: 3px;
  margin: 0 1.25rem 0.75rem 1.25rem;
  margin: 0 1.25rem 0.75rem 1.25rem
}

@media (min-width: 768px) {

.InboxList_switch__2CO64 {
    margin: 0 0.5rem 0.75rem 0.75rem;
    margin: 0 0.5rem 0.75rem 0.75rem
}
  }

.InboxList_switchItem__1DPVF {
  font-size: 0.75rem;
  font-size: 0.75rem;
  color: #718096;
  color: #718096;
  width: 50%;
  padding: 0.25rem 0;
  padding: 0.25rem 0;
  text-align: center;
  cursor: pointer
}

.InboxList_switchItem__1DPVF:hover {
    text-decoration: none;
  }

.InboxList_switchItemSelected__1MAR4 {
  color: #1a202c;
  color: #1a202c;
  font-weight: 700;
  font-weight: 700;
  background-color: #fff;
  background-color: #fff;
  border-radius: 20px;
  border-radius: 20px;
}

.InboxList_content__2B6nw {
  overflow: visible;
  height: 100%;
  padding: 0 1.25rem 0 1.25rem;
  padding: 0 1.25rem 0 1.25rem;
  margin-bottom: 40px;
  min-height: 50vh
}

@media (min-width: 768px) {

.InboxList_content__2B6nw {
    min-height: auto;
    margin-bottom: 0;
    overflow: auto;
    padding: 0 0.5rem 0 0.75rem;
    padding: 0 0.5rem 0 0.75rem
}
  }

.InboxList_contentLoading__270dS {
  display: flex;
  align-items: center;
  justify-content: center;
}

.InboxList_loadingMore__3NFae {
  font-weight: 700;
  font-weight: 700;
  font-size: 0.875rem;
  font-size: 0.875rem;
  text-align: center;
  padding: 0.75rem 0;
  padding: 0.75rem 0;
}

.InboxList_sentinel__tbAaW {
  height: 20px;
}

.InboxList_filters__WIU6l {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  margin: 0 1.25rem 0.75rem 1.25rem
}

@media (min-width: 768px) {

.InboxList_filters__WIU6l {
    margin: 0 0.5rem 0.75rem 0.75rem
}
  }

.InboxList_filterDropdownWrapper__14Sk7 {
  position: relative;
}

.InboxList_filterDropdown__1tsND {
  position: relative;
}

.InboxList_noTransactions__3lhsS {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  color: #1a202c;
  color: #1a202c;
  margin-top: 100px;
  grid-gap: 16px;
  gap: 16px
}

@media (min-width: 768px) {

.InboxList_noTransactions__3lhsS {
    height: auto;
    justify-content: flex-start
}
  }

.InboxList_clearFiltersButton__3Qwam {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
}

.InboxList_clearFiltersButton__3Qwam:hover {
  background-color: #f3f6f3;
  color: #1a202c;
  color: #1a202c;
}

.InboxList_loadingContainer__2GjWz {
  margin-top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden
}

@media (min-width: 768px) {

.InboxList_loadingContainer__2GjWz {
    margin-left: -1rem;
    margin-top: -1rem;
    width: calc(100% + 1rem)
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InboxListItem_root__3TdJ0 {
  display: flex;
  color: #1a202c;
  color: #1a202c;
  cursor: pointer;
  text-decoration: none
}

.InboxListItem_root__3TdJ0:hover {
    text-decoration: none;
  }

@media (min-width: 768px) {

.InboxListItem_root__3TdJ0 {
    padding-right: 1rem;
    padding-right: 1rem;
    padding-left: 1.5rem;
    padding-left: 1.5rem;
    border-left: 4px solid transparent
}

    .InboxListItem_root__3TdJ0:hover {
      background-color: #f7fafc;
      background-color: #f7fafc;
      border-radius: 0.75rem;
      border-radius: 0.75rem;
    }
  }

@media (min-width: 768px) {

.InboxListItem_rootSelected__zYCBn {
    background-color: #f7fafc;
    background-color: #f7fafc;
    border-radius: 0.75rem;
    border-radius: 0.75rem
}
  }

.InboxListItem_container__194v5 {
  display: flex;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  flex-grow: 1;
  padding-top: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.25rem;
  padding-bottom: 1.25rem;
}

.InboxListItem_avatarContainer__1s0UQ {
  position: absolute;
  top: 2.25rem;
  top: 2.25rem;
  right: -4px;
}

.InboxListItem_avatar__1rhjL {
  width: 2rem;
  width: 2rem;
  height: 2rem;
  height: 2rem;
  border: solid 1px #fff;
  border: solid 1px #fff;
}

.InboxListItem_itemContainer__1FWxB {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.InboxListItem_itemHeader__3dfY9 {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  line-height: 14px;
}

.InboxListItem_itemHeaderBold__1sJW- {
  font-weight: 700;
  font-weight: 700;
}

.InboxListItem_itemHeaderDate__3VgJP {
  font-size: 0.625rem;
  font-size: 0.625rem;
  color: #4a5569;
  color: #4a5569;
  font-weight: normal;
}

.InboxListItem_itemTitle__2IbQd {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.InboxListItem_itemTitleBold__w-3EI {
  font-weight: 700;
  font-weight: 700;
}

.InboxListItem_itemDate__sdZkv {
  font-size: 0.75rem;
  font-size: 0.75rem;
  color: #718096;
  color: #718096;
}

.InboxListItem_itemFooter__3YT4z {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.InboxListItem_itemStateLabel__1RNUe {
  font-size: 0.75rem;
  font-size: 0.75rem;
  color: #1a202c;
  color: #1a202c;
}

.InboxListItem_itemBadge__2WIXL {
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  font-size: 0.625rem;
  font-size: 0.625rem;
  font-weight: 700;
  font-weight: 700;
  padding: 0.25rem;
  padding: 0.25rem;
  border-radius: 5px;
  border-radius: 5px;
  white-space: nowrap;
}

.InboxListItem_badgeYellow__2wN1Y {
  background-color: #fff0a4;
  background-color: #fff0a4;
}

.InboxListItem_badgeGreen__1km_V {
  background-color: #c3cec4;
  background-color: #c3cec4;
}

.InboxListItem_badgeRed__Bd-y5 {
  background-color: #f59a89;
  background-color: #f59a89;
}

.InboxListItem_imageWrapper__20G8M {
  position: relative;
}

.InboxListItem_image__3-cON {
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 2px;
}

.InboxListItem_clockIcon__3oIpL {
  height: 1rem;
  height: 1rem;
  width: 1rem;
  width: 1rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InboxPageV2_pageRoot__ls9Ug {
  overflow: hidden
}

@media (min-width: 768px) {

.InboxPageV2_pageRoot__ls9Ug {
    overflow-x: visible;
    overflow-x: initial
}
  }

.InboxPageV2_root__PHjzD {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  min-height: 100vh;
  color: #1a202c;
  color: #1a202c
}

@media (min-width: 768px) {

.InboxPageV2_root__PHjzD {
    max-height: auto;
    min-height: 100vh
}
  }

.InboxPageV2_topbarContainer__2J3Xt {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 60;
}

.InboxPageV2_listContainer__3SwVP {
  order: 1;
  width: 100%;
  overflow: hidden
}

@media (min-width: 768px) {

.InboxPageV2_listContainer__3SwVP {
    flex: 0 0 25%;
    overflow: auto;
    height: auto
}
  }

.InboxPageV2_listContainerLoader__Dzmqm {
  padding: 0;
  min-height: 100vh
}

@media (min-width: 768px) {

.InboxPageV2_listContainerLoader__Dzmqm {
    min-height: auto;
    padding: 1rem 0 1rem 1rem;
    padding: 1rem 0 1rem 1rem
}
  }

.InboxPageV2_messagesContainer__2_e5d {
  order: 3
}

@media (min-width: 768px) {

.InboxPageV2_messagesContainer__2_e5d {
    order: 2;

    flex: 1 1;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e2e8f0;
    border-left: 1px solid #e2e8f0;
    padding: 0
}
  }

.InboxPageV2_messagesContainerLoader__1GdHt {
  border-left: none
}

@media (min-width: 768px) {

.InboxPageV2_messagesContainerLoader__1GdHt {
    padding: 1rem;
    padding: 1rem
}
  }

.InboxPageV2_actionContainer__335Mp {
  order: 2
}

@media (min-width: 768px) {

.InboxPageV2_actionContainer__335Mp {
    order: 3;

    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    border: 1px solid #e2e8f0;
    border: 1px solid #e2e8f0;
    box-shadow: 0 20px 32px -24px rgba(15, 15, 15, 0.12);
    height: calc(100% - 1.75rem);
    height: calc(100% - 1.75rem);
    overflow: auto;
    margin: 1.75rem 1.5rem 0 1.5rem;
    margin: 1.75rem 1.5rem 0 1.5rem
}
  }

.InboxPageV2_actionContainerLoader__2GJLE {
  border: none;
  margin-left: 0;
  margin-top: 1rem;
  margin-top: 1rem;
}

.InboxPageV2_containerLoader__3J_UG {
  display: block;
  width: 100%;
  height: 100%;
}

.InboxPageV2_mainContainer__17D64 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 9;
  overflow-x: auto
}

@media (min-width: 768px) {

.InboxPageV2_mainContainer__17D64 {
    flex-shrink: 0;
    width: 100%;
    max-width: 1920px;
    height: calc(100vh - 4.5rem - 5.25rem);
    height: calc(100vh - 4.5rem - 5.25rem);
    flex-direction: row;
    padding: 0;
    margin: 0 auto 1.75rem auto;
    margin: 0 auto 1.75rem auto
}
  }

.InboxPageV2_mainContainerLoader__y6EPC {
  padding: 1rem;
  padding: 1rem;
  background-color: #fff;
  background-color: #fff
}

@media (min-width: 768px) {

.InboxPageV2_mainContainerLoader__y6EPC {
    padding: 0
}
  }

.InboxPageV2_emptyStateContainer__3brC4 {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
  height: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
  padding: 1.25rem
}

@media (min-width: 768px) {

.InboxPageV2_emptyStateContainer__3brC4 {
    padding: 0
}
  }

.InboxPageV2_emptyStateImage__RfjxB {
  width: 100%
}

@media (min-width: 768px) {

.InboxPageV2_emptyStateImage__RfjxB {
    max-width: 23.25rem;
    max-width: 23.25rem
}
  }

.InboxPageV2_emptyStateTextSection__2xLA0 {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.InboxPageV2_emptyStateTitle__144Tm {
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 800;
  font-weight: 800;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InboxTransitionBase_root__-kfcm {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.InboxTransitionBase_container__wVl87 {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.InboxTransitionBase_containerLeft__3pDpm {
  width: 80%;
  align-self: flex-start;
}

.InboxTransitionBase_containerRight__2K7EO {
  width: 80%;
  align-self: flex-end;
}

.InboxTransitionBase_user__3kGdP {
  display: flex;
  align-items: center;
  position: relative;
  top: 0.25rem;
  top: 0.25rem;
}

.InboxTransitionBase_avatar__1MEVL {
  width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  height: 2.5rem;
  margin-left: 1rem;
  margin-left: 1rem;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
  border: 3px solid #fff;
  border: 3px solid #fff;
}

.InboxTransitionBase_userName__3fYp4 {
  font-weight: 700;
  font-weight: 700;
}

.InboxTransitionBase_message__12SUI {
  padding: 0.75rem 1.25rem 1.25rem 1.75rem;
  padding: 0.75rem 1.25rem 1.25rem 1.75rem;
  border-radius: 15px;
  background-color: #edf2f7;
  background-color: #edf2f7;
  color: #1a202c;
  color: #1a202c;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.InboxTransitionBase_messageDark__1h3-V {
  background-color: #2d3748;
  background-color: #2d3748;
  color: #fff;
  color: #fff;
}

.InboxTransitionBase_messageRight__2kHdd {
  border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.InboxTransitionBase_messageLeft__1gV1x {
  border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.InboxTransitionBase_messageButton__2RP2I {
  background-color: #fff;
  background-color: #fff;
  padding: 0;
}

.InboxTransitionBase_messageTransition__2nlsO {
  color: #fff;
  color: #fff;
  background-color: transparent;
  padding: 0 0 1.25rem 0;
  padding: 0 0 1.25rem 0
}

@media (min-width: 768px) {

.InboxTransitionBase_messageTransition__2nlsO {
    width: 100%
}
  }

.InboxTransitionBase_messageHeader__3bXMY {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.InboxTransitionBase_date__1-8tE {
  color: #718096;
  color: #718096;
  font-size: 0.75rem;
  font-size: 0.75rem;
}

.InboxTransitionBase_dateLeft__3rFCh {
  align-self: flex-start;
}

.InboxTransitionBase_dateRight__3KzIa {
  align-self: flex-end;
}

.InboxTransitionBase_buttons__3Q4Ye {
  position: fixed;
  width: 30%;
  bottom: 1.75rem;
  bottom: 1.75rem;
  right: 1.5rem;
  right: 1.5rem;
  padding: 1.25rem;
  padding: 1.25rem;
  margin: 0;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 -4px 10px 0 rgba(57, 58, 59, 0.1);
  border-bottom-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  border: 1px solid #e2e8f0;
  border: 1px solid #e2e8f0
}

.InboxTransitionBase_buttons__3Q4Ye > div button:only-child {
    width: 100%;
  }

.InboxTransitionBase_buttonsMobile__1116j {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.25rem;
  padding: 1.25rem;
  margin: 0;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 -4px 10px 0 rgba(57, 58, 59, 0.1);
  border: 1px solid #e2e8f0;
  border: 1px solid #e2e8f0
}

.InboxTransitionBase_buttonsMobile__1116j > div button:only-child {
    width: 100%;
  }

.InboxTransitionBase_buttonsTitle__226Ej {
  color: #1a202c;
  color: #1a202c;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  text-align: center;
  padding-bottom: 1.25rem;
  padding-bottom: 1.25rem;
}

.InboxTransitionBase_link__3euSs {
  color: #fff;
  color: #fff;
  text-decoration: underline;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.Buttons_actionButtonWrapper__3zJgC {
  display: flex;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  width: 100%
}

.Buttons_actionButtonWrapper__3zJgC > button {
    flex-grow: 1;
  }

@media (min-width: 768px) {

.Buttons_actionButtonWrapper__3zJgC {
    width: auto
}
  }

.Buttons_acceptButton__1hq_N {
  flex-basis: 60%;
}

.Buttons_declineButton___seUW {
  flex-basis: 40%;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InboxDetails_root__2pPC4 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.InboxDetails_headerContainer__3urNr {
  display: flex;
  flex-direction: column;
  width: 100%;

  color: #fff;

  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative
}

.InboxDetails_headerContainer__3urNr::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(34, 39, 32, 0.7);
    z-index: 1;
    pointer-events: none;
  }

.InboxDetails_headerContainerLoading__72_Pm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.InboxDetails_header__Amdno {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e2e8f0;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700;
  padding: 2rem 1.5rem 0.5rem 1.5rem;
  padding: 2rem 1.5rem 0.5rem 1.5rem;
}

.InboxDetails_content__1H6N4 {
  display: flex;
  align-items: center;
  overflow: auto;
  height: 100%;
  position: relative;
  padding: 2.5rem 1.25rem;
  padding: 2.5rem 1.25rem;
  z-index: 2;
  min-height: 22rem;
  min-height: 22rem
}

@media (min-width: 768px) {

.InboxDetails_content__1H6N4 {
    min-height: 14rem;
    min-height: 14rem;
    padding: 1rem;
    padding: 1rem
}
  }

.InboxDetails_contentLoading__33Tw9 {
  display: flex;
  align-items: center;
  justify-content: center
}

@media (min-width: 768px) {

.InboxDetails_contentLoading__33Tw9 {
    position: relative;
    top: -4px;
    padding: 0
}
  }

.InboxDetails_sendMessageContainer__17k5e {
  padding: 0 1.5rem;
  padding: 0 1.5rem;
}

.InboxDetails_sendMessageForm__1nDil {
  margin-top: 1.25rem;
  margin-top: 1.25rem;
}

.InboxDetails_textAreaMessageForm__26Zs- {
  min-height: 4.75rem;
  min-height: 4.75rem;
}

.InboxDetails_genericError__g66-g {
  color: #ed3615;
  color: #ed3615;
  margin: 1.5rem 0 0 0;
  margin: 1.5rem 0 0 0;
}

.InboxDetails_buttonClose__2QjUB {
  position: absolute;
  top: 1.5rem;
  top: 1.5rem;
  right: 1.5rem;
  right: 1.5rem;
  border-color: transparent;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  outline: none;
  display: flex;
  z-index: 10;
  z-index: 10;
  cursor: pointer
}

.InboxDetails_buttonClose__2QjUB > svg {
    color: #fff;
    color: #fff;
    width: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    height: 1.5rem;
  }

.InboxDetails_footer__1Lrxp {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  padding: 0 1.25rem;
}

.InboxDetails_hiddenImage__3kJXC {
  display: none;
}

.InboxDetails_loaderContainer__14bPY {
  display: block;
  width: 100%;
  height: 100%;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.LinkWithIcon_root__2kcYC {
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.LinkWithIcon_link__1osvy {
  text-decoration: underline;
}

.LinkWithIcon_icon__2EOVm {
  width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  height: 1.5rem;
  fill: #1a202c;
  fill: #1a202c
}

.LinkWithIcon_icon__2EOVm > path {
    fill: #1a202c;
    fill: #1a202c;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InboxAction_root__2sb82 {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  margin: 16px 20px
}

@media (min-width: 768px) {

.InboxAction_root__2sb82 {
    margin-bottom: 5rem;
    margin-bottom: 5rem;
    display: block
}
  }

@media (min-width: 768px) {

.InboxAction_rootConfirmPayment__2FKlA {
    margin-bottom: 9.25rem;
    margin-bottom: 9.25rem
}
  }

.InboxAction_divider__qT436 {
  height: 1px;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  margin: 1.25rem 0;
  margin: 1.25rem 0;
}

.InboxAction_bookingPanel__nvEQn {
  margin: 1rem 0;
  margin: 1rem 0;
}

.InboxAction_bookingTitle__GuuyS {
  margin: 0;
}

.InboxAction_sendMessageForm__ImVnR {
  position: relative;
  margin: 3rem 0 1rem 0;
  margin: 3rem 0 1rem 0
}

@media (min-width: 768px) {

.InboxAction_sendMessageForm__ImVnR {
    border-top: 0
}
  }

.InboxAction_linkIconsWrapper__2wQ3S {
  display: flex;
  flex-direction: column;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

.InboxAction_pricingPanel__2YVtJ {
  display: none
}

@media (min-width: 768px) {

.InboxAction_pricingPanel__2YVtJ {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
    border: none;
    background-color: #fff;
    background-color: #fff;
    min-width: 20rem;
    min-width: 20rem;
    flex-basis: 40%;
    flex-basis: 40%;
    flex-shrink: 1;
    flex-grow: 1
}
  }

.InboxAction_bookingDetails__pNcqQ {
  display: block;
}

.InboxAction_bookingDetailsDesktop__3cU2k {
  display: none
}

@media (min-width: 768px) {

.InboxAction_bookingDetailsDesktop__3cU2k {
    display: block
}
  }

.InboxAction_mobileCheckAvailability__23BLj {
  z-index: 60;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1.25rem;
  padding: 1.25rem;
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 10%);
  display: flex
}

@media (min-width: 768px) {

.InboxAction_mobileCheckAvailability__23BLj {
    display: none
}
  }

.InboxAction_mobileCheckAvailabilityPrice__9duVL {
  font-size: 0.875rem;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.InboxAction_isInquiryInfo__1EBV2 {
  margin: 0.5rem 0 1rem 0;
  margin: 0.5rem 0 1rem 0
}

@media (min-width: 768px) {

.InboxAction_isInquiryInfo__1EBV2 {
    display: none
}
  }

.InboxAction_listingTitle__1B9UE {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.InboxAction_listingTitleFeed__2AUnI {
  display: none;
  font-size: 1.5rem;
  font-size: 1.5rem
}

@media (min-width: 768px) {

.InboxAction_listingTitleFeed__2AUnI {
  font-size: 1.875rem;
  font-size: 1.875rem
}
    }

.InboxAction_listingTitleFeed__2AUnI {
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.InboxAction_listingTitleFeed__2AUnI {
    display: block
}
  }

.InboxAction_listingTitleLink__1cYR5 {
  color: #1a202c;
  color: #1a202c;
  font-size: 1.25rem;
  font-size: 1.25rem;
}

.InboxAction_profileFlexContainer__k8GXS {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.InboxAction_profileFlexContainer__k8GXS {
    margin-bottom: 1rem;
    margin-bottom: 1rem
}
  }

.InboxAction_avatar__34r9r {
  width: 4.75rem;
  width: 4.75rem;
  height: 4.75rem;
  height: 4.75rem;
}

.InboxAction_guestNameStyles__2uZ67 {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 0.875rem;
  font-size: 0.875rem;
  padding: 0;
  margin: 0;
  line-height: 1;
}

.InboxAction_profileInfo__1z62d {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  margin-left: 1rem;
}

.InboxAction_profileName__1t6Dm {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700
}

@media (min-width: 768px) {

.InboxAction_profileName__1t6Dm {
    font-size: 1.25rem;
    font-size: 1.25rem
}
  }

.InboxAction_profileLink__1JfO- {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-weight: 600;
  text-decoration: underline;
}

.InboxAction_notifyMobileContainer__1UYCe {
  z-index: 60;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1.25rem;
  padding: 1.25rem;
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.InboxAction_notifyMobileContainer__1UYCe {
    display: none
}
  }

.InboxAction_authorWrapper__2zbPA {
  display: flex
}

@media (min-width: 768px) {

.InboxAction_authorWrapper__2zbPA {
    display: none
}
  }

.InboxAction_avatar__34r9r {
  width: 3.25rem;
  width: 3.25rem;
  height: 3.25rem;
  height: 3.25rem;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.InboxAction_authorNameWrapper__2PubR {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.InboxAction_authorLabel__1gsvu {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.InboxAction_authorName__1BXkA {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-weight: 700;
  grid-gap: 12px;
  gap: 12px;
}

.InboxAction_rating__rRniw {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InboxFeed_root__3D_d3 {
  position: relative;
}

.InboxFeed_messageError__3bpmx {
  color: #ed3615;
  color: #ed3615;
  margin: 1rem 0;
  margin: 1rem 0;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InboxMessages_root__1wYgY {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  flex-grow: 2;
}

.InboxMessages_header__1-_wm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e2e8f0;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700;
  padding: 2rem 1.5rem 0.5rem 1.5rem;
  padding: 2rem 1.5rem 0.5rem 1.5rem;
}

.InboxMessages_content__3oDcD {
  overflow: auto;
  height: 100%;
  position: relative;
  padding: 0 1.5rem;
  padding: 0 1.5rem;
}

.InboxMessages_contentLoading__1Aw_Y {
  display: flex;
  align-items: center;
  justify-content: center;
}

.InboxMessages_sendMessageForm__1W6Hq {
  margin-top: 1.25rem;
  margin-top: 1.25rem;
}

.InboxMessages_textAreaMessageForm__Z6mTY {
  min-height: 4.75rem;
  min-height: 4.75rem;
}

.InboxMessages_genericError__2aMod {
  color: #ed3615;
  color: #ed3615;
  margin: 1.5rem 0 0 0;
  margin: 1.5rem 0 0 0;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InboxDetailsSendMessage_sendMessageForm__1FIGs {
}

.InboxDetailsSendMessage_textAreaMessageForm__OV7Oh {
  min-height: 4.75rem;
  min-height: 4.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.BookingChanges_root__1Wwoi {
  display: flex;
  width: 100%;
  margin: 0 auto
}

@media (min-width: 768px) {

.BookingChanges_root__1Wwoi {
    margin-top: 2.25rem;
    margin-top: 2.25rem
}
  }

.BookingChanges_formContainer__3bT2b {
  display: none
}

@media (min-width: 768px) {

.BookingChanges_formContainer__3bT2b {
    flex-basis: 62%;
    display: block
}
  }

.BookingChanges_pricingDetailsContainer__27ZYC {
  padding-bottom: 0
}

@media (min-width: 768px) {

.BookingChanges_pricingDetailsContainer__27ZYC {
    flex-basis: 38%;
    padding: 1.25rem;
    padding: 1.25rem;
    padding-bottom: 1.25rem;
    padding-bottom: 1.25rem;
    border-radius: 25px;
    border-radius: 25px;
    border: 1px solid #e2e8f0;
    border: 1px solid #e2e8f0;
    box-shadow: 0 40px 32px -24px rgba(15, 15, 15, 0.12)
}
  }

.BookingChanges_pricingDetailsMobileHeader__z34Jk {
  display: block;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.BookingChanges_pricingDetailsMobileHeader__z34Jk {
    display: none
}
  }

.BookingChanges_formContainerHeader__1d7iP {
  display: none
}

@media (min-width: 768px) {

.BookingChanges_formContainerHeader__1d7iP {
    display: block;
    max-width: 75%;
    max-width: 75%
}
  }

.BookingChanges_headerContainer__26c7B {
  display: flex;
  flex-direction: column;
}

.BookingChanges_headerText__1LwCo {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem
}

@media (min-width: 768px) {

.BookingChanges_headerText__1LwCo {
    font-size: 1.875rem;
    font-size: 1.875rem
}
  }

.BookingChanges_divider__1u_g2 {
  height: 1px;
  width: 90%;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  margin: 0.5rem 0;
  margin: 0.5rem 0;
}

@media (min-width: 768px) {

.BookingChanges_bookingDetails__3wf-v {
    max-width: 66.666667%;
    max-width: 66.666667%;
    margin-top: 1rem;
    margin-top: 1rem
}
  }

.BookingChanges_bookingDetailsContainer__2zkmB {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.BookingChanges_bookingDetailsHeader__2vKo4 {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
}

.BookingChanges_bookingDetailsMobile__1I1Re {
  display: block
}

@media (min-width: 768px) {

.BookingChanges_bookingDetailsMobile__1I1Re {
    display: none
}
  }

.BookingChanges_formFieldHelper__T5S_G {
  margin-top: 0.25rem;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #718096;
  color: #718096;
}

.BookingChanges_formFieldValue__1rkRB {
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.BookingChanges_formFieldWrapper__14GQZ {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.BookingChanges_formFieldButtonWrapper__3SON2 {
  background-color: #fff;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1.75rem;
  margin-top: 1.75rem
}

@media (min-width: 768px) {

.BookingChanges_formFieldButtonWrapper__3SON2 {
    margin-top: 2rem;
    margin-top: 2rem;
    padding: 0
}
  }

.BookingChanges_acceptButton__3-eAo {
  flex-basis: 66.666667%;
  flex-basis: 66.666667%;
}

.BookingChanges_pricingDetails__2WfX5 {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.BookingChanges_pricingDetailsHeader__knsgQ {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: 700;
}

.BookingChanges_pricingDetailsItem__1UEv9 {
  display: flex;
  justify-content: space-between;
}

.BookingChanges_dateInfo__3lAEG {
  width: 100%;
  text-align: center;
}

.BookingChanges_priceDetailsDesktop__34-Io {
  display: none
}

@media (min-width: 768px) {

.BookingChanges_priceDetailsDesktop__34-Io {
    display: block
}
  }

.BookingChanges_priceDetailsMobile__2yR5S {
  display: block
}

@media (min-width: 768px) {

.BookingChanges_priceDetailsMobile__2yR5S {
    display: none
}
  }

.BookingChanges_errorMessage__2JaS0 {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  color: #ca2d10;
  color: #ca2d10;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #ca2d10;
  border: 1px solid #ca2d10;
  padding: 0.75rem;
  padding: 0.75rem;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem;
  margin-top: 1.75rem;
  margin-top: 1.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.DeclineModal_header__j2Q2r {
  display: flex;
  text-align: center;
  color: #1a202c;
  color: #1a202c;
  font-weight: 700;
  font-weight: 700;
  font-size: 1.25rem;
  font-size: 1.25rem;
}

.DeclineModal_buttonYes__1pjne {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ResponsiveModal_root__1WsV7 {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 3.5rem 1.5rem 3rem 1.5rem;
  padding: 3.5rem 1.5rem 3rem 1.5rem;
  background-color: #fff;
  background-color: #fff;
  border-radius: 10px;
  border-radius: 10px;
  border-bottom: none;
  flex-basis: 30rem;
  flex-basis: 30rem;
  position: absolute;
  max-height: 100dvh;
  overflow: auto;
  bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

@media (min-width: 768px) {

.ResponsiveModal_root__1WsV7 {
    position: relative;
    flex-basis: 35.5rem;
    flex-basis: 35.5rem;
    flex-grow: 0;
    padding: 3.75rem;
    padding: 3.75rem;
    border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px
}
  }

.ResponsiveModal_modalContent__2RyQu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.ResponsiveModal_container__3bhmi {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

.ResponsiveModal_closeButton__pnsbr {
  text-decoration: underline;
  color: #1a202c;
  color: #1a202c;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.LandtrustPlusModal_root___epbP {
  background-color: #fffdf7;
  background-color: #fffdf7;
  color: #1a202c;
  color: #1a202c;
  width: 100%;
  padding: 1.25rem;
  padding: 1.25rem;
  height: 90vh
}

@media (min-width: 768px) {

.LandtrustPlusModal_root___epbP {
    width: auto;
    flex-basis: 43.75rem;
    flex-basis: 43.75rem;
    padding: 2.25rem;
    padding: 2.25rem;
    height: 70vh
}
  }

.LandtrustPlusModal_container__3LYmv {
  grid-gap: 0.5rem;
  gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.LandtrustPlusModal_headerContainer__3xrwd {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: 1.25rem;
  font-size: 1.25rem;
}

.LandtrustPlusModal_headerImage__1CPln {
  width: 11.5rem;
  width: 11.5rem
}

@media (min-width: 768px) {

.LandtrustPlusModal_headerImage__1CPln {
    width: 13rem;
    width: 13rem
}
  }

.LandtrustPlusModal_intro__W3Sxh {
  font-size: 0.75rem;
  font-size: 0.75rem;
  letter-spacing: 4.29px;
  text-transform: uppercase;
  margin-top: 0.5rem;
  margin-top: 0.5rem;
}

.LandtrustPlusModal_logoImage__1wqVx {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  width: 9.25rem;
  width: 9.25rem;
}

.LandtrustPlusModal_header__nNa3C {
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 800;
  font-weight: 800;
  width: 100%
}

@media (min-width: 768px) {

.LandtrustPlusModal_header__nNa3C {
    width: 28.75rem;
    width: 28.75rem
}
  }

.LandtrustPlusModal_content__1s7Fh {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center
}

@media (min-width: 768px) {

.LandtrustPlusModal_content__1s7Fh {
    width: 30rem;
    width: 30rem
}
  }

@media (min-width: 768px) {

.LandtrustPlusModal_description__2OElf {
    display: none
}
  }

.LandtrustPlusModal_list__2Azyb {
  margin-top: 0.75rem;
  margin-top: 0.75rem
}

.LandtrustPlusModal_list__2Azyb > li {
    display: flex;
    grid-gap: 0.75rem;
    gap: 0.75rem;
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }

@media (min-width: 768px) {

.LandtrustPlusModal_list__2Azyb {
    margin-top: 0
}
  }

.LandtrustPlusModal_checkIcon__AVqlB {
  color: #4ca817;
  color: #4ca817;
}

.LandtrustPlusModal_buttonLearnMore__PmAD_ {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
  width: 21rem;
  width: 21rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.CountdownTimer_root__2hZwt {
  display: flex;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EarlyAccessBar_root__1W8P5 {
  display: flex;
  flex-direction: column;
}

.EarlyAccessBar_container__3W4gX {
  display: flex;
  justify-content: center;
  width: 100%;
  color: #fff;
  color: #fff;
  background-color: #4b514d;
  background-color: #4b514d;
  padding: 0.5rem;
  padding: 0.5rem;
}

.EarlyAccessBar_progressBarContainer__3smZ_ {
  height: 0.5rem;
  height: 0.5rem;
  width: 100%;
}

.EarlyAccessBar_progressBar__2BD5q {
  border-radius: 0
}

@media (min-width: 768px) {

.EarlyAccessBar_progressBar__2BD5q {
    border-radius: 0
}
  }

.EarlyAccessBar_content__2f3gk {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.EarlyAccessBar_counter__79P85 {
  font-weight: 700;
  font-weight: 700;
}

.EarlyAccessBar_notificationLabelDesktop__12fLv {
  display: none
}

@media (min-width: 768px) {

.EarlyAccessBar_notificationLabelDesktop__12fLv {
    display: block
}
  }

.EarlyAccessBar_notificationLabelMobile__3x-Kn {
  display: block
}

@media (min-width: 768px) {

.EarlyAccessBar_notificationLabelMobile__3x-Kn {
    display: none
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.CountdownWithProgressBar_root__1Q0vb {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.CountdownWithProgressBar_progressBarContainer__3rdyV {
  height: 0.5rem;
  height: 0.5rem;
  width: 100%;
}

.CountdownWithProgressBar_container__2WZ93 {
  padding-top: 0.5rem;
  padding-top: 0.5rem;
}

.CountdownWithProgressBar_content__1mBcP {
  display: flex;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  flex-direction: column;
  align-items: center
}

@media (min-width: 768px) {

.CountdownWithProgressBar_content__1mBcP {
    flex-direction: row
}
  }

.CountdownWithProgressBar_counter__3_Ou_ {
  font-weight: 700;
  font-weight: 700;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.EarlyAccessButtonWithTimer_earlyAccess__1M2_5 {
  display: flex;
  flex-direction: column;
}

.EarlyAccessButtonWithTimer_packageAvailableTimer__2VPL7 {
  display: flex;
  justify-content: center;
  margin: 0.75rem 0;
  margin: 0.75rem 0;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ArchiveButton_button__12nnc {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #1a202c;
  border-color: #1a202c;
  grid-gap: 8px;
  gap: 8px;
  padding: 8px 16px;
}

.ArchiveButton_button__12nnc:hover {
  background-color: #f3f6f3;
  color: #1a202c;
  color: #1a202c;
}

.ArchiveButton_button__12nnc:focus {
  outline: 2px solid #1a202c;
  outline: 2px solid #1a202c;
}

.ArchiveButton_icon__3lE2x {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ArchiveModal_header__2EMa2 {
  display: flex;
  text-align: center;
  color: #1a202c;
  color: #1a202c;
  font-weight: 700;
  font-weight: 700;
  font-size: 1.25rem;
  font-size: 1.25rem;
}

.ArchiveModal_buttonYes__V1YAz {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

/* heroContainer gives the height for SectionHero */

/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */

/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */

.AboutPage_heroContainer__1Fifm {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: url(/static/media/about-us-header.014b35b6.jpg);

  /* Cover the whole screen with the background image */
  background-size: cover;

  /* Align the image within the container */
  background-position: center center;

  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  height: 67.5vh;
  max-height: 400px
}

@media (min-width: 768px) {

.AboutPage_heroContainer__1Fifm {
    margin-bottom: 20px
}
  }

.AboutPage_hero__1GaqD {
  flex-grow: 1;
  justify-content: center;
  padding-bottom: 32px;
}

.AboutPage_heroContent__1RPE0 {
  width: 100%;
  padding: 40px 30px
}

@media (min-width: 768px) {

.AboutPage_heroContent__1RPE0 {
    padding: 60px 7.5vw 60px 7.5vw
}
  }

@media (min-width: 1024px) {

.AboutPage_heroContent__1RPE0 {
    max-width: calc(1200px + 36px + 36px);
    margin: 60px auto;
    padding: 0 36px
}
  }

.AboutPage_heroMainTitle__315i- {
  font-weight: 700;
  font-weight: 700;
  font-size: 42px;
  line-height: 42px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 25px;
  margin-bottom: 20px
}

@media (min-width: 768px) {

.AboutPage_heroMainTitle__315i- {
  font-size: 72px;
  line-height: 72px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 25px;
  margin-bottom: 31px
}
    }

@media (min-width: 1681px) {

.AboutPage_heroMainTitle__315i- {
  font-size: 84px;
  line-height: 84px
}
    }

.AboutPage_heroMainTitle__315i- {
  color: #ffffff;
  color: #ffffff;
  margin-bottom: 0px
}

@media (min-width: 768px) {

.AboutPage_heroMainTitle__315i- {
    max-width: var(--SectionHero_desktopTitleMaxWidth)
}
  }

.AboutPage_sections__2kQF- {
  margin: 0;
}

.AboutPage_sectionContent__2c9Yq {
  margin: 51px 24px
}

@media (min-width: 768px) {

.AboutPage_sectionContent__2c9Yq {
    margin: 40px 7.5vw
}
  }

@media (min-width: 1024px) {

.AboutPage_sectionContent__2c9Yq {
    max-width: calc(1200px + 36px + 36px);
    margin: 40px auto;
    padding: 0 36px
}
  }

.AboutPage_sectionTitle__3b7KY {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;
}

@media (min-width: 768px) {

.AboutPage_sectionTitle__3b7KY {
  font-size: 1.875rem;
  font-size: 1.875rem;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px;
}
    }

.AboutPage_sectionTitle__3b7KY {
  margin: 30px 0px 20px 0px;
  padding: 0px 10px;
}

.AboutPage_sectionSteps__3hVSt {
  display: flex;
  flex-direction: column;
}

.AboutPage_sectionStep__mS5Ah,
.AboutPage_sectionStepReverse__1gn1v {
  display: flex;
  flex-wrap: wrap;
}

.AboutPage_sectionStepReverse__1gn1v {
  flex-wrap: wrap-reverse;
}

.AboutPage_sectionStepDescriptionContainer__xgrW3,
.AboutPage_sectionStepDescriptionContainerThirds__3zzHN {
  display: flex;
  flex: 50% 1;
  flex-direction: column;
  min-width: 300px;
  padding: 0px 10px;
}

.AboutPage_sectionStepDescriptionContainerThirds__3zzHN {
  flex: 33% 1;
}

.AboutPage_sectionStepDescriptionText__2bEwq {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

.AboutPage_sectionStepTitle__1NFMq {
  padding: 10px 0px 0px 0px;

  border-top: solid;
  border-top-color: #cbd5e0;
  border-top-color: #cbd5e0;
  border-width: 1px;
  margin-top: 20px;
}

/* A wrapper for a responsive image that holds it's aspect ratio */

.AboutPage_imageWrapper__2jwi6 {
  flex: 50% 1;
  position: relative;
  width: 100%;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
}

.AboutPage_imageWrapper2__3Buta {
  position: relative;
  height: 300px;
  width: 100%;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
  margin-bottom: 20px;
}

/* An image wrapper that defines the images aspect ratio */

.AboutPage_aspectWrapper__3gDZY {
  padding-bottom: calc(6 / 13 * 100%) /* 13:6 Aspect Ratio */
}

@media (min-width: 768px) {

.AboutPage_aspectWrapper__3gDZY {
    padding-bottom: calc(2 / 3 * 100%) /* 3:2 Aspect Ratio */
}
  }

.AboutPage_locationImage__1gqG- {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;

  padding: 20px 10px;
}

.AboutPage_locationImage2__3TSsj {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: -0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0px 10px;
}

.AboutPage_helpButton__2cVlb {
  font-weight: 600;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.AboutPage_helpButton__2cVlb {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.AboutPage_helpButton__2cVlb {

    /* Dimensions */
  width: 100%;
  min-height: 30px;
  margin: 0;
  white-space: nowrap;

  padding: 4px 20px;

    /* Borders */
  border-style: solid;
  border-width: 1px;
  border-color: #e2e8f0;
  border-color: #e2e8f0;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #1a202c;
  color: #1a202c;

    /* Text */
  text-align: center;
  text-decoration: none;

  cursor: pointer;
}

.AboutPage_helpButton__2cVlb:hover,
    .AboutPage_helpButton__2cVlb:focus {
  border-color: #cbd5e0;
  border-color: #cbd5e0;
  outline: none;
    }

.AboutPage_helpButton__2cVlb {
  font-weight: 500;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
  border-color: #1a202c;
  border-color: #1a202c;
  width: 140px;
}

.AboutPage_boldH4__2U4YC {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.AboutPage_boldH4__2U4YC {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.AboutPage_boldH4__2U4YC {
  font-weight: 600;
  font-weight: 600;
  margin: 0px;
}

.AboutPage_email__X9Zye {
  font-weight: 700;
  font-weight: 700;
  color: #3e704b;
  color: #3e704b;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.AgritourismStatutesPage_heading__c9Qj9 {
  margin: 5px 0 18px 0
}

@media (min-width: 768px) {

.AgritourismStatutesPage_heading__c9Qj9 {
    margin: 8px 0 24px 0
}
  }

@media (min-width: 1024px) {

.AgritourismStatutesPage_content__3oZyL {
    max-width: 563px
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.AuthenticationPage_layoutWrapperMain__3mUz4 {
  min-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px)
}

@media (min-width: 768px) {

.AuthenticationPage_layoutWrapperMain__3mUz4 {
    min-height: 100vh;
    flex-direction: row
}
  }

.AuthenticationPage_root__1WjSE {
  justify-content: flex-start;
}

.AuthenticationPage_content__p8jyG {
  flex-grow: 1;

    /* Create context so the close button can be positioned within it */
  position: relative;

    /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: 24px 24px 48px 24px;
  padding: 24px 24px 48px 24px;

  background-color: #ffffff;

  background-color: #ffffff;
  border-radius: 2px;
  border-radius: 2px;
  border-bottom: none
}

@media (min-width: 768px) {

.AuthenticationPage_content__p8jyG {
  flex-basis: 480px;
  flex-grow: 0;
      /* min-height: 568px; */
  padding: 55px 60px 55px 60px;
  padding: 55px 60px 55px 60px;
  margin-top: 12.5vh;
  margin-bottom: 12.5vh;
  border-bottom: 8px solid #3e704b;
  border-bottom: 8px solid #3e704b
}
    }

.AuthenticationPage_content__p8jyG {
  background-color: transparent;
  padding-top: 0
}

@media (min-width: 768px) {

.AuthenticationPage_content__p8jyG {
    margin-top: 4rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-bottom: 4rem;
    padding-top: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0;
    padding-left: 9rem;
    padding-left: 9rem;
    max-width: 38rem;
    max-width: 38rem;
    border-bottom: none
}
  }

.AuthenticationPage_colLeft__C9JAF {
  order: 2;
  background-color: #233933;
  background-color: #233933;
  padding: 4.25rem 1.5rem;
  padding: 4.25rem 1.5rem;
  color: #fff;
  color: #fff
}

@media (min-width: 768px) {

.AuthenticationPage_colLeft__C9JAF {
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 1;
    flex-grow: 1;
    flex-basis: 50%;
    padding-top: 1rem;
    padding-top: 1rem
}
  }

.AuthenticationPage_colLeftWrapper__2SUOV {
  max-width: 31.75rem;
  max-width: 31.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.AuthenticationPage_colRight__1m631,
.AuthenticationPage_colRightConfirm__2e4gn {
  order: 1;
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media (min-width: 768px) {

.AuthenticationPage_colRight__1m631 {
    order: 2;
    justify-content: center
}
  }

/* ================ Tabs & Form ================ */

.AuthenticationPage_tab__1SAh5 {
  flex-grow: 1;
}

.AuthenticationPage_bottomWrapper__2U7kd {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;
}

@media (min-width: 768px) {

.AuthenticationPage_bottomWrapper__2U7kd {
  margin-top: 96px;
}
    }

.AuthenticationPage_bottomWrapper__2U7kd {

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* Terms of Service modal*/

.AuthenticationPage_termsWrapper__1KMDZ {
  width: 100%;
  padding-top: 10px;
  padding-left: 1rem;
  padding-left: 1rem;
  padding-right: 2rem;
  padding-right: 2rem;
  max-height: 560px
}

@media (min-width: 768px) {

.AuthenticationPage_termsWrapper__1KMDZ {
    width: 604px
}
  }

.AuthenticationPage_termsHeading__2fJh5 {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px
}

@media (min-width: 768px) {

.AuthenticationPage_termsHeading__2fJh5 {
  font-size: 1.875rem;
  font-size: 1.875rem;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px
}
    }

.AuthenticationPage_termsHeading__2fJh5 {
  margin: 0 0 19px 0
}

@media (min-width: 768px) {

.AuthenticationPage_termsHeading__2fJh5 {
    margin: 0 0 19px 0
}
  }

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.AuthenticationPage_hideOnMobile__2nJyA {
  display: none
}

@media (min-width: 768px) {

.AuthenticationPage_hideOnMobile__2nJyA {
    display: block
}
  }

/* ================ Close icon ================ */

.AuthenticationPage_verifyClose__1K4ti {
    /* Position inside modal */
  position: fixed;
  top: 0;
  right: 0;

    /* Some content (like map) is positioning itself on top of close button without z-index handling */
  z-index: calc(100 + 1);
  z-index: calc(100 + 1);

    /* Safari didn't know how to baseline-align svg icon and text */
  display: flex;
  align-items: center;
  width: auto;

    /* Sizing (touch area) */
  padding: 24px;
  margin: 0;
  border: 0;
}

@media (min-width: 768px) {

.AuthenticationPage_verifyClose__1K4ti {
  padding: 27px 30px;
  position: absolute;
}
    }

.AuthenticationPage_verifyClose__1K4ti {

    /* Colors */
  background-color: transparent;
  color: #4a5569;
  color: #4a5569;

  cursor: pointer;
}

.AuthenticationPage_verifyClose__1K4ti:enabled:hover {
  background-color: transparent;
  color: #1a202c;
  color: #1a202c;
  box-shadow: none;
  text-decoration: none;
    }

.AuthenticationPage_verifyClose__1K4ti:enabled:active {
  background-color: transparent;
  color: #1a202c;
  color: #1a202c;
    }

.AuthenticationPage_verifyClose__1K4ti:disabled {
  background-color: transparent;
    }

.AuthenticationPage_verifyClose__1K4ti {

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.AuthenticationPage_closeText__3opdM {
    /* Font */
  font-weight: bold;
  font-size: 0.875rem;
  font-size: 0.875rem;
  line-height: 18px;
  text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.AuthenticationPage_closeText__3opdM {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 6px;
}
    }

.AuthenticationPage_closeText__3opdM {
  margin: -2.5px 0 0 0;
}

@media (min-width: 768px) {

.AuthenticationPage_closeText__3opdM {
  margin-top: -1px;
}
    }

.AuthenticationPage_closeIcon__1-j2V {
  display: inline-block;
  margin-left: 8px;
  padding: 2px 0 4px;
  box-sizing: content-box;
}

/* ================ Typography ================ */

/* Title of the modal */

.AuthenticationPage_modalTitle__OaJth {
  font-weight: 600;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  font-weight: 700;
  font-weight: 700;
}

@media (min-width: 768px) {

.AuthenticationPage_modalTitle__OaJth {
  font-weight: 600;
  font-weight: 600;
  line-height: 40px;
  margin: 0;
}
    }

/* Paragraph for the Modal */

.AuthenticationPage_modalMessage__1trXA {
  margin: 18px 0 0 0;
}

@media (min-width: 768px) {

.AuthenticationPage_modalMessage__1trXA {
  margin: 24px 0 0 0;
}
    }

/* Make the email pop */

.AuthenticationPage_email__1T6fB {
  font-weight: 700;
  font-weight: 700;
}

/* Helper links */

.AuthenticationPage_modalHelperLink__3QuVS {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: #1a202c;
  color: #1a202c;

    /* Text size should be inherited */
  text-decoration: none;
}

.AuthenticationPage_modalHelperLink__3QuVS:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.AuthenticationPage_modalHelperLink__3QuVS {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.AuthenticationPage_modalHelperLink__3QuVS {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.AuthenticationPage_modalHelperLink__3QuVS {
  color: #4a5569;
  color: #4a5569;
  margin: 0;
}

@media (min-width: 768px) {

.AuthenticationPage_modalHelperLink__3QuVS {
  margin: 0;
}
    }

/* Helper texts for the links, not the actual links */

.AuthenticationPage_modalHelperText__1gNvu {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.AuthenticationPage_modalHelperText__1gNvu {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.AuthenticationPage_modalHelperText__1gNvu {
  color: #a1aec1;
  color: #a1aec1;
  margin: 0;
}

@media (min-width: 768px) {

.AuthenticationPage_modalHelperText__1gNvu {
  margin: 0;
}
    }

.AuthenticationPage_modalIcon__3MTXm {
  height: 48px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.AuthenticationPage_modalIcon__3MTXm {
  height: 64px;
  margin-bottom: 23px
}
    }

.AuthenticationPage_modalIcon__3MTXm {

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px
}

@media (min-width: 768px) {

.AuthenticationPage_modalIcon__3MTXm {
    margin-top: 0
}
  }

/* ================ Errors ================ */

.AuthenticationPage_error__2igEg {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.AuthenticationPage_error__2igEg {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.AuthenticationPage_error__2igEg {
  margin-top: 24px;
  color: #ff0000;
  color: #ff0000;
}

/* ================ Social logins & SSO ================ */

.AuthenticationPage_signupWithIdpTitle__3A1qH {
  font-size: 1.5rem;
  font-size: 1.5rem
}

@media (min-width: 768px) {

.AuthenticationPage_signupWithIdpTitle__3A1qH {
  font-size: 2.25rem;
  font-size: 2.25rem
}
    }

.AuthenticationPage_signupWithIdpTitle__3A1qH {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1rem;
  padding-top: 1rem;
  color: #1a202c;
  color: #1a202c
}

@media (min-width: 768px) {

.AuthenticationPage_signupWithIdpTitle__3A1qH {
    margin-top: 6px
}
  }

.AuthenticationPage_socialButtonWrapper__oHjsk {
  margin-top: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AuthenticationPage_confirmInfoText__1rt1v {
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
}

.AuthenticationPage_buttonIcon__2BskG {
  position: absolute;
  left: 0;
  margin-left: 1.25rem;
  margin-left: 1.25rem;
}

.AuthenticationPage_socialButtonsOr__3MS9O {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-size: 0.875rem;
  width: 100%;
  height: 2rem;
  height: 2rem;
  margin: 1rem 0 0 0;
  margin: 1rem 0 0 0;
  text-align: center;
  position: relative;
  background-color: #fff;
  background-color: #fff
}

.AuthenticationPage_socialButtonsOr__3MS9O:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #cbd5e0;
    border-bottom: solid 1px #cbd5e0;
    position: absolute;
    left: 0;
    top: 50%;
    top: 50%;
    z-index: 1;
  }

@media (min-width: 768px) {
  .AuthenticationPage_socialButtonsOr__3MS9O {
    height: 2rem;
    height: 2rem;
    margin: 1rem 0 0.5rem 0;
    margin: 1rem 0 0.5rem 0;
  }
}

.AuthenticationPage_socialButtonsOrText__1fhMy {
  background-color: #fff;
  background-color: #fff;
  z-index: 3;
  padding: 0 1.25rem 0 1.25rem;
  padding: 0 1.25rem 0 1.25rem;
  position: relative;
  margin: 0;
  font-weight: 700;
  font-weight: 700;
}

.AuthenticationPage_headerWrapper__2tvMC {
  font-size: 2.25rem;
  font-size: 2.25rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  text-align: center;
  margin: 0 auto 1.5rem auto;
  margin: 0 auto 1.5rem auto;
  width: 100%
}

@media (min-width: 768px) {

.AuthenticationPage_headerWrapper__2tvMC {
    margin: auto
}
  }

.AuthenticationPage_topbar__12gWn {
  width: 100%;
  height: 4.5rem;
  height: 4.5rem;
  background-color: #fff;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center
}

@media (min-width: 768px) {

.AuthenticationPage_topbar__12gWn {
    display: none
}
  }

.AuthenticationPage_logoMobile__3wwt- {
  display: block
}

@media (min-width: 768px) {

.AuthenticationPage_logoMobile__3wwt- {
    display: none
}
  }

.AuthenticationPage_logoDesktop__1qaUb {
  display: none
}

@media (min-width: 768px) {

.AuthenticationPage_logoDesktop__1qaUb {
    display: block
}
  }

.AuthenticationPage_tabContent__j4H8q {
  font-size: 1rem;
  font-size: 1rem;
}

.AuthenticationPage_reviewWrapper__17sxa {
  display: flex;
  width: 100%;
  padding-top: 100px;
  justify-content: center;
  flex-grow: 2;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* Dummy Topbar */

.CheckoutPage_topbar__3A-JY {
  width: 100%;
  height: 4.5rem;
  height: 4.5rem;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center
}

@media (min-width: 768px) {

.CheckoutPage_topbar__3A-JY {
    justify-content: left;
    padding: 0 4.25rem;
    padding: 0 4.25rem
}
  }

.CheckoutPage_logoMobile__1fhab {
  display: block
}

@media (min-width: 768px) {

.CheckoutPage_logoMobile__1fhab {
    display: none
}
  }

.CheckoutPage_logoDesktop__9kAwy {
  display: none
}

@media (min-width: 768px) {

.CheckoutPage_logoDesktop__9kAwy {
    display: block
}
  }

/* /Dummy Topbar */

/**
 * Content: mobile flow vs desktop 2 columns
 * desktop has reduced version of bookListingContainer (incl. title and Stripe paymentForm).
 * priceBreakdown is inside separate column (detailsContainerDesktop)
 */

.CheckoutPage_contentContainer__1lDS3 {
  display: flex;
  flex-direction: column;
  margin-bottom: 6.25rem;
  margin-bottom: 6.25rem;
  padding: 1.25rem;
  padding: 1.25rem
}

@media (min-width: 768px) {

.CheckoutPage_contentContainer__1lDS3 {
    width: 100%;
    max-width: 80rem;
    max-width: 80rem;
    flex-direction: row;
    margin: 0.5rem auto 1.75rem auto;
    margin: 0.5rem auto 1.75rem auto
}
  }

.CheckoutPage_bookListingContainer__3fYje {
  display: flex;
  flex-direction: column;
  padding: 0
}

@media (min-width: 768px) {

.CheckoutPage_bookListingContainer__3fYje {
    flex-basis: 62%;
    padding: 1.25rem 8rem 1.25rem 1.25rem;
    padding: 1.25rem 8rem 1.25rem 1.25rem
}
  }

.CheckoutPage_aspectWrapper__2PFt0 {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: #a1aec1;
  background-color: #a1aec1 /* Loading BG color */
}

@media (min-width: 1024px) {

.CheckoutPage_aspectWrapper__2PFt0 {
    display: none
}
  }

.CheckoutPage_rootForImage__2btR8 {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover
}

@media (min-width: 1024px) {

.CheckoutPage_rootForImage__2btR8 {
    border-radius: 2px 2px 0 0
}
  }

.CheckoutPage_avatarWrapper__26V9s {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Layout */
  display: block
}

@media (min-width: 1024px) {

.CheckoutPage_avatarWrapper__26V9s {
    margin-left: 48px
}
  }

@media (min-width: 1024px) {

.CheckoutPage_avatarMobile__3abAi {
    display: none
}
  }

.CheckoutPage_heading__1_Iee {
  margin-bottom: 2rem;
  margin-bottom: 2rem;
}

.CheckoutPage_listingButton__3NPyw {
  width: 35%;
  margin-bottom: 1em;
  margin-top: 1em;
}

.CheckoutPage_listingText__raknd {
  text-decoration: underline;
}

.CheckoutPage_listingText__raknd :hover,
.CheckoutPage_backToListing__1XwXj :hover {
  color: #ff0000;
  color: #ff0000;
}

.CheckoutPage_title__108Iz {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  width: 100%;
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  color: #000;
  color: #000
}

@media (min-width: 768px) {

.CheckoutPage_title__108Iz {
    margin-top: 1.75rem;
    margin-top: 1.75rem;
    margin-bottom: 0.25rem;
    margin-bottom: 0.25rem
}
  }

.CheckoutPage_author__1vMvS {
  font-size: 0.875rem;
  font-size: 0.875rem
}

@media (min-width: 768px) {

.CheckoutPage_author__1vMvS {
    display: none
}
  }

.CheckoutPage_totalDivider__1O-ZF {
  height: 1px;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  border: none;
  background-color: #e2e8f0;
  background-color: #e2e8f0
}

@media (min-width: 768px) {

.CheckoutPage_totalDivider__1O-ZF {
    display: none
}
  }

.CheckoutPage_priceBreakdownContainer__3MxNt {
  padding: 0 24px;
  margin-bottom: 37px
}

@media (min-width: 768px) {

.CheckoutPage_priceBreakdownContainer__3MxNt {
    margin-bottom: 38px
}
  }

@media (min-width: 1024px) {

.CheckoutPage_priceBreakdownContainer__3MxNt {
    /* we don't show price breakdown since it will be visible within 2nd column (detailsContainerDesktop) */
    display: none
}
  }

.CheckoutPage_priceBreakdownTitle__6hhtl {
  font-size: 1rem;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 768px) {

.CheckoutPage_priceBreakdownTitle__6hhtl {
    margin-top: 0;
    margin-bottom: 18px
}
  }

@media (min-width: 1024px) {

.CheckoutPage_priceBreakdownTitle__6hhtl {
    margin-top: 0;
    margin-bottom: 14px
}
  }

.CheckoutPage_priceBreakdownSubtitle__EQJES {
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.CheckoutPage_priceBreakdownLoading__gZi1Y {
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.CheckoutPage_paymentContainer__1r-uX {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 0
}

@media (min-width: 768px) {

.CheckoutPage_paymentContainer__1r-uX {
    margin-top: 2px
}
  }

@media (min-width: 1024px) {

.CheckoutPage_paymentContainer__1r-uX {
    margin-top: 4px;
    padding: 0
}
  }

.CheckoutPage_orderError__38MYM,
.CheckoutPage_notFoundError__1V3Kp {
  margin: 11px 0 12px 0
}

@media (min-width: 768px) {

.CheckoutPage_orderError__38MYM,
.CheckoutPage_notFoundError__1V3Kp {
    margin: 10px 0 12px 0
}
  }

.CheckoutPage_orderError__38MYM {
  /* color: var(--failColor); */
}

.CheckoutPage_notFoundError__1V3Kp {
  color: #ffaa00;
  color: #ffaa00;
}

.CheckoutPage_speculateError__nW-go {
  color: #ff0000;
  color: #ff0000;
  margin: 19px 0 1px 0
}

@media (min-width: 1024px) {

.CheckoutPage_speculateError__nW-go {
    margin: 28px 48px 0 48px
}
  }

.CheckoutPage_paymentForm__1QVYv {
  flex-grow: 1;
}

/* Details column is visible only on desktop */

.CheckoutPage_detailsContainerDesktop__1JqvI {
  display: none
}

@media (min-width: 768px) {

.CheckoutPage_detailsContainerDesktop__1JqvI {
    flex-shrink: 0;
    display: block;
    /* Dimensions */
    width: 409px;
    padding-bottom: 55px;

    /* Position */
    margin: 1.75rem 0 24px 0;
    margin: 1.75rem 0 24px 0;

    /* Coloring */
    background-color: #ffffff;
    background-color: #ffffff;
    border: 1px solid #a1aec1;
    border: 1px solid #a1aec1;
    border-radius: 2px
}
  }

.CheckoutPage_detailsAspectWrapper__1z55z {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: #a1aec1;
  background-color: #a1aec1; /* Loading BG color */
}

.CheckoutPage_detailsHeadings__u-ylV {
  display: none
}

@media (min-width: 1024px) {

.CheckoutPage_detailsHeadings__u-ylV {
    display: block;
    margin: 0 48px
}
  }

.CheckoutPage_detailsTitle__pupti {
  margin-bottom: 10px
}

@media (min-width: 1024px) {

.CheckoutPage_detailsTitle__pupti {
    margin-top: 17px;
    margin-bottom: 9px
}
  }

.CheckoutPage_detailsSubtitle__AlEKV {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.CheckoutPage_detailsSubtitle__AlEKV {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.CheckoutPage_detailsSubtitle__AlEKV {

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 1024px) {

.CheckoutPage_detailsSubtitle__AlEKV {
    margin-top: 0;
    margin-bottom: 0
}
  }

.CheckoutPage_bookingBreakdownTitle__1N45b {
  font-size: 1rem;
  font-size: 1rem;
  /* Layout spacing */
  margin: 5px 24px 0px 24px
}

@media (min-width: 1024px) {

.CheckoutPage_bookingBreakdownTitle__1N45b {
    margin: 37px 48px 0px 48px
}
  }

.CheckoutPage_bookingBreakdownSubtitle__3nsne {
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin: 0px 1.5rem 1.5rem 1.5rem;
  margin: 0px 1.5rem 1.5rem 1.5rem
}

@media (min-width: 1024px) {

.CheckoutPage_bookingBreakdownSubtitle__3nsne {
    margin: 0px 3rem 1.5rem 3rem;
    margin: 0px 3rem 1.5rem 3rem
}
  }

.CheckoutPage_bookingBreakdownLoading__JghS5 {
  font-size: 0.75rem;
  font-size: 0.75rem;
  margin: 0px 1.5rem 1.5rem 1.5rem;
  margin: 0px 1.5rem 1.5rem 1.5rem
}

@media (min-width: 1024px) {

.CheckoutPage_bookingBreakdownLoading__JghS5 {
    margin: 0px 3rem 1.5rem 3rem;
    margin: 0px 3rem 1.5rem 3rem
}
  }

.CheckoutPage_bookingDatesContainer__zx9St {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  padding: 0 1.5rem;
  margin-bottom: 2.25rem;
  margin-bottom: 2.25rem
}

@media (min-width: 768px) {

.CheckoutPage_bookingDatesContainer__zx9St {
    margin-top: 0.25rem;
    margin-top: 0.25rem;
    padding: 0
}
  }

.CheckoutPage_bookingDatesButton__2MU7t {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  width: 100%;
  cursor: pointer;
  text-align: left;
  padding: 0;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #a1aec1;
  border: 1px solid #a1aec1;
  transition: all 0.2s ease-out;
  z-index: 50;
  z-index: 50;
  position: relative;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.CheckoutPage_bookingDatesButtonLabel__1u-Zf {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 1rem 1.5rem 1rem 1rem;
  padding: 1rem 1.5rem 1rem 1rem;
}

.CheckoutPage_bookingDateText__3MNDr {
  margin-left: 1rem;
  margin-left: 1rem;
}

.CheckoutPage_iconArrow__bhP-q {
  stroke: #4a5569;
  stroke: #4a5569;
  fill: #4a5569;
  fill: #4a5569;
  transition: all 0.2s ease-out;
}

.CheckoutPage_formGroupHeading__tTz5_ {
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.CheckoutPage_formFieldHeading__30TCN {
  font-size: 1rem;
  font-size: 1rem;
  margin: 0 0 0 0;
  color: var(--var-900);
  font-weight: 700;
  font-weight: 700;

  padding-top: 4px;
  padding-bottom: 2px
}

@media (min-width: 768px) {

.CheckoutPage_formFieldHeading__30TCN {
    margin: 0 0 0 0;
    padding-top: 4px;
    padding-bottom: 4px
}
  }

.CheckoutPage_packageInfo__1baaw {
  margin-top: 1.25rem;
  margin-top: 1.25rem
}

@media (min-width: 768px) {

.CheckoutPage_packageInfo__1baaw {
    margin-top: 0
}
  }

.CheckoutPage_packageInfoHeading__36_DX {
  font-weight: 700;
  font-weight: 700;
  font-size: 1.125rem;
  font-size: 1.125rem
}

@media (min-width: 768px) {

.CheckoutPage_packageInfoHeading__36_DX {
    font-size: 1.5rem;
    font-size: 1.5rem
}
  }

.CheckoutPage_partySizeWrapper__2RGME {
  margin-bottom: 2.5rem;
  margin-bottom: 2.5rem;
}

.CheckoutPage_partySize__1M7Xz {
  margin-bottom: 38px;
  display: block;
  flex-direction: row;
  padding: 0 1.5rem;
  padding: 0 1.5rem
}

@media (min-width: 768px) {

.CheckoutPage_partySize__1M7Xz {
    margin-bottom: 24px;
    padding: 0
}
  }

.CheckoutPage_partySizeLabel__Sjb5- {
  text-align: center;
}

.CheckoutPage_speciesHeadingSubtitle__eWG9c {
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.CheckoutPage_speciesFee__d_tDC {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.CheckoutPage_speciesFeeCheckboxLabel__nP2hw {
  margin-bottom: 0;
}

.CheckoutPage_submitClassName__1OuYz {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  padding: 1rem;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 60;
  z-index: 60
}

.CheckoutPage_submitClassName__1OuYz > button {
    margin-top: 0;
  }

.CheckoutPage_submitClassName__1OuYz > p {
    margin-top: 0.25rem;
    margin-top: 0.25rem;
  }

@media (min-width: 768px) {

.CheckoutPage_submitClassName__1OuYz {
    position: relative;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    z-index: 20;
    z-index: 20
}
  }

.CheckoutPage_modalContainer__1t34_ {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  background-color: #fff;
  min-height: 66.666667%;
  min-height: 66.666667%;
  width: 100%;
  overflow: hidden;
  border-radius: 25px 25px 0 0;
  border-radius: 25px 25px 0 0
}

@media (min-width: 768px) {

.CheckoutPage_modalContainer__1t34_ {
    right: 0;
    min-height: 100%;
    width: 60%;
    width: 60%;
    border-radius: 25px 0 0 25px;
    border-radius: 25px 0 0 25px
}
  }

.CheckoutPage_innerIDModal__2y2GE {
  display: flex;
  justify-content: center;
}

.CheckoutPage_leftLoadingColumn__CkSSL {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  padding: 1.25rem
}

@media (min-width: 768px) {

.CheckoutPage_leftLoadingColumn__CkSSL {
    flex-basis: 55%;
    padding-right: 6.25rem;
    padding-right: 6.25rem;
    height: calc(100vh - 7.5rem);
    height: calc(100vh - 7.5rem)
}
  }

.CheckoutPage_leftLoader__Y-S6s {
  position: relative;
  box-sizing: content-box;
  height: 784px;
  background-color: #ffffff;
  border-radius: 0px 0px 0px 0px;
  background-image: linear-gradient(#f6f8fa 16px, transparent 0),
    linear-gradient(#f6f8fa 16px, transparent 0), linear-gradient(#f6f8fa 16px, transparent 0),
    linear-gradient(#f6f8fa 27px, transparent 0), linear-gradient(#f6f8fa 180px, transparent 0),
    linear-gradient(#f6f8fa 16px, transparent 0), linear-gradient(#f6f8fa 63px, transparent 0),
    linear-gradient(#f6f8fa 16px, transparent 0), linear-gradient(#f6f8fa 16px, transparent 0),
    linear-gradient(#f6f8fa 33px, transparent 0), linear-gradient(#f6f8fa 197px, transparent 0);
  background-repeat: repeat-y;
  background-size: 350px 784px, 350px 784px, 350px 784px, 170px 784px, 350px 784px, 141px 784px,
    350px 784px, 141px 784px, 141px 784px, 220px 784px, 350px 784px;
  background-position: 0px 766px, 0px 738px, 0px 711px, 0px 666px, 0px 466px, 0px 440px, 0px 346px,
    0px 320px, 0px 258px, 0px 214px, 0px 0px
}

.CheckoutPage_leftLoader__Y-S6s:before {
    content: ' ';
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 784px;
    -webkit-mask-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    );
    -webkit-mask-repeat: repeat-y;
    -webkit-mask-size: 50px 784px;
    -webkit-mask-position: -20% 0;
    background-image: linear-gradient(rgba(255, 255, 255, 1) 16px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 16px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 16px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 27px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 180px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 16px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 63px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 16px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 16px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 33px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 197px, transparent 0);
    background-repeat: repeat-y;
    background-size: 350px 784px, 350px 784px, 350px 784px, 170px 784px, 350px 784px, 141px 784px,
      350px 784px, 141px 784px, 141px 784px, 220px 784px, 350px 784px;
    background-position: 0px 766px, 0px 738px, 0px 711px, 0px 666px, 0px 466px, 0px 440px, 0px 346px,
      0px 320px, 0px 258px, 0px 214px, 0px 0px;
    animation: CheckoutPage_loading__2UmkX 2s infinite;
  }

@media (min-width: 768px) {

.CheckoutPage_leftLoader__Y-S6s {
    height: 604px;
    background-color: #ffffff;
    border-radius: 0px 0px 0px 0px;
    background-image: linear-gradient(#f6f8fa 51px, transparent 0),
      linear-gradient(#f6f8fa 197px, transparent 0), linear-gradient(#f6f8fa 26px, transparent 0),
      linear-gradient(#f6f8fa 50px, transparent 0), linear-gradient(#f6f8fa 26px, transparent 0),
      linear-gradient(#f6f8fa 43px, transparent 0);
    background-repeat: repeat-y;
    background-size: 570px 625px, 568px 625px, 233px 625px, 568px 625px, 178px 625px, 245px 625px;
    background-position: 0px 552px, 0px 219px, 0px 185px, 0px 108px, 0px 75px, 0px 0px
}

    .CheckoutPage_leftLoader__Y-S6s:before {
      content: ' ';
      position: absolute;
      z-index: 1000;
      width: 100%;
      height: 604px;
      -webkit-mask-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      );
      -webkit-mask-repeat: repeat-y;
      -webkit-mask-size: 50px 604px;
      -webkit-mask-position: -20% 0;
      background-image: linear-gradient(rgba(255, 255, 255, 1) 51px, transparent 0),
        linear-gradient(rgba(255, 255, 255, 1) 197px, transparent 0),
        linear-gradient(rgba(255, 255, 255, 1) 26px, transparent 0),
        linear-gradient(rgba(255, 255, 255, 1) 50px, transparent 0),
        linear-gradient(rgba(255, 255, 255, 1) 26px, transparent 0),
        linear-gradient(rgba(255, 255, 255, 1) 43px, transparent 0);
      background-repeat: repeat-y;
      background-size: 570px 625px, 568px 625px, 233px 625px, 568px 625px, 178px 625px, 245px 625px;
      background-position: 0px 552px, 0px 219px, 0px 185px, 0px 108px, 0px 75px, 0px 0px;
      animation: CheckoutPage_loading__2UmkX 2s infinite;
    }
  }

@keyframes CheckoutPage_loading__2UmkX {
  to {
    -webkit-mask-position: 120% 0;
  }
}

.CheckoutPage_rightLoadingColumn__2Xx32 {
  display: none
}

@media (min-width: 768px) {

.CheckoutPage_rightLoadingColumn__2Xx32 {
    display: block;
    padding-left: 2rem;
    padding-left: 2rem;
    border-left: 1px solid #e2e8f0;
    border-left: 1px solid #e2e8f0;
    flex-basis: 45%
}
  }

.CheckoutPage_rightLoader__13gfb {
  position: relative;
  box-sizing: content-box;
  height: 604px;
  background-color: #ffffff;
  border-radius: 0px 0px 0px 0px;
  background-image: linear-gradient(#f6f8fa 21px, transparent 0),
    linear-gradient(#f6f8fa 21px, transparent 0), linear-gradient(#f6f8fa 21px, transparent 0),
    linear-gradient(#f6f8fa 21px, transparent 0), linear-gradient(#f6f8fa 21px, transparent 0),
    linear-gradient(#f6f8fa 21px, transparent 0), linear-gradient(#f6f8fa 43px, transparent 0),
    linear-gradient(#f6f8fa 229px, transparent 0);
  background-repeat: repeat-y;
  background-size: 372px 604px, 372px 604px, 372px 604px, 372px 604px, 178px 604px, 178px 604px,
    245px 604px, 372px 604px;
  background-position: 0px 538px, 0px 509px, 0px 480px, 0px 451px, 0px 364px, 0px 318px, 0px 267px,
    0px 0px
}

.CheckoutPage_rightLoader__13gfb:before {
    content: ' ';
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 604px;
    -webkit-mask-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    );
    -webkit-mask-repeat: repeat-y;
    -webkit-mask-size: 50px 604px;
    -webkit-mask-position: -20% 0;
    background-image: linear-gradient(rgba(255, 255, 255, 1) 21px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 21px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 21px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 21px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 21px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 21px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 43px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 229px, transparent 0);
    background-repeat: repeat-y;
    background-size: 372px 604px, 372px 604px, 372px 604px, 372px 604px, 178px 604px, 178px 604px,
      245px 604px, 372px 604px;
    background-position: 0px 538px, 0px 509px, 0px 480px, 0px 451px, 0px 364px, 0px 318px, 0px 267px,
      0px 0px;
    animation: CheckoutPage_loading__2UmkX 2s infinite;
  }

.CheckoutPage_infoWrapper__1AWdU {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1.25rem 1rem 1.25rem 0;
  margin: 1.25rem 1rem 1.25rem 0
}

@media (min-width: 768px) {

.CheckoutPage_infoWrapper__1AWdU {
    margin: 1.25rem 1rem 1.25rem 0;
    margin: 1.25rem 1rem 1.25rem 0
}
  }

.CheckoutPage_desktopBreadcrumbs__1CpDi {
  display: none
}

@media (min-width: 768px) {

.CheckoutPage_desktopBreadcrumbs__1CpDi {
    display: block
}
  }

.CheckoutPage_mobileBreadcrumbs__3Dme1 {
  margin-bottom: 0
}

@media (min-width: 768px) {

.CheckoutPage_mobileBreadcrumbs__3Dme1 {
    display: none
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.EditListingPage_page__V0T-e {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}

.EditListingPage_topbar__lairk {
}

.EditListingPage_topbarMobile__1ovrZ {
  box-shadow: none;
}

.EditListingPage_container__2a2CO {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column
}

@media (min-width: 1024px) {

.EditListingPage_container__2a2CO {
    flex-direction: row
}
  }

.EditListingPage_formContainer__3NbLh {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.EditListingPage_formContainer__3NbLh {
    margin-top: 2rem;
    margin-top: 2rem
}
  }

@media (min-width: 1024px) {

.EditListingPage_formContainer__3NbLh {
    margin-top: 4rem;
    margin-top: 4rem
}
  }

@media (min-width: 1024px) {

.EditListingPage_coltonContainer__3CyLJ {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    width: 350px;
    margin-left: 3rem;
    margin-left: 3rem;
    margin-top: 6rem;
    margin-top: 6rem
}
  }

.EditListingPage_fullColton__3yhkl {
  display: none
}

@media (min-width: 1024px) {

.EditListingPage_fullColton__3yhkl {
    display: block
}
  }

.EditListingPage_miniColton__3GRLV {
  margin-bottom: 2rem;
  margin-bottom: 2rem
}

@media (min-width: 1024px) {

.EditListingPage_miniColton__3GRLV {
    display: none
}
  }

.EditListingPage_topBarExitButton__1N9hk {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: bold;
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #1a202c;
  color: #1a202c;
  cursor: pointer;
}

.EditListingPage_progressBarSection__14w0P {
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.Quotes_root__3Z9VX {
  display: inline-block;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.Quotes_light__38G3B {
  color: #fff;
  color: #fff;
}

.Quotes_dark__1fKIV {
  color: #000;
  color: #000;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.Rating_rootStyles__QNzsS {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fad44e;
  color: #fad44e;
}

.Rating_rating__2Ip_q {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fad44e;
  color: #fad44e;
}

.Rating_rating__2Ip_q svg {
  margin-right: 0;
}

.Rating_rating__2Ip_q svg:last-child {
  margin-right: 0.25rem;
  margin-right: 0.25rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.TestimonialCard_containerStyle__2bn7f {
  flex: 1 1;
  border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #edf2f7;
  border: 1px solid #edf2f7;
  padding: 0.5rem 1rem 1.5rem 1.25rem;
  padding: 0.5rem 1rem 1.5rem 1.25rem;
  position: relative;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.TestimonialCard_containerStyle__2bn7f {
    margin-bottom: 0;
    padding: 1.5rem 1rem 1.5rem 1.5rem;
    padding: 1.5rem 1rem 1.5rem 1.5rem
}
  }

.TestimonialCard_reviewTextStyles__2eDME {
  width: 78.66vw;
  font-size: 1rem;
  font-size: 1rem;
  margin-bottom: 2.5rem;
  margin-bottom: 2.5rem
}

@media (min-width: 768px) {

.TestimonialCard_reviewTextStyles__2eDME {
    width: auto
}
  }

.TestimonialCard_contentWrapper__3hSFd {
  display: flex;
}

.TestimonialCard_statusStyles__1jeAn {
  font-size: 0.75rem;
  font-size: 0.75rem;
  font-weight: normal;
  margin-bottom: 2px;
}

.TestimonialCard_quotesStyles__tGPb5 {
  width: 3rem;
  width: 3rem;
  height: 3rem;
  height: 3rem;
  margin-top: -16px;
  margin-bottom: -8px
}

@media (min-width: 768px) {

.TestimonialCard_quotesStyles__tGPb5 {
    margin-top: -32px
}
  }

.TestimonialCard_sportsmanStyles__NGKeL {
  font-size: 1rem;
  font-size: 1rem;
}

.TestimonialCard_sportsmanImage__2_sa2 {
  width: 3.25rem;
  width: 3.25rem;
  height: 4rem;
  height: 4rem;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.TestimonialCard_sportsmanContainer__1hQ56 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.PlusTestimonials_sectionContainerStyle__19SnY {
  overflow-x: visible;
  padding: 1.25rem;
  padding: 1.25rem;
  width: 100%
}

@media (min-width: 768px) {

.PlusTestimonials_sectionContainerStyle__19SnY {
    width: 77%
}
  }

.PlusTestimonials_headerContainerStyles__2_Mvb {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center
}

@media (min-width: 768px) {

.PlusTestimonials_headerContainerStyles__2_Mvb {
    max-width: 60%
}
  }

.PlusTestimonials_headingStyles__3Btgi {
  width: 100%;
  font-weight: 800;
  text-align: center;
  font-size: 1.5rem;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  margin-bottom: 2rem
}

@media (min-width: 768px) {

.PlusTestimonials_headingStyles__3Btgi {
    font-size: 2.25rem;
    font-size: 2.25rem;
    margin-bottom: 3rem;
    margin-bottom: 3rem
}
  }

.PlusTestimonials_wrapperStyles__3Odl4 {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  width: 100%
}

@media (min-width: 768px) {

.PlusTestimonials_wrapperStyles__3Odl4 {
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    grid-gap: 1rem
}
  }

.PlusTestimonials_quoteWrapper__1EZ1R {
  display: flex;
  flex-direction: column;
  align-items: center;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.MembershipSignupPage_contentStyles__1IlFP {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4.25rem;
  margin-top: 4.25rem;
}

.MembershipSignupPage_titleStyles__3_HIJ {
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  text-align: center
}

@media (min-width: 768px) {

.MembershipSignupPage_titleStyles__3_HIJ {
    width: 100%
}
  }

.MembershipSignupPage_bannerLogoImage__XBQKd {
  width: 13rem;
  width: 13rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.MembershipSignupPage_testimonialWrapper__1X6at {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  margin-bottom: 5rem;
}

.MembershipSignupPage_authComponentWrapper__2PNsQ {
  margin: 0 auto;
  width: 90%
}

@media (min-width: 768px) {

.MembershipSignupPage_authComponentWrapper__2PNsQ {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.EmailVerificationPage_layoutWrapperMain__3V5P7 {
  min-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px)
}

@media (min-width: 768px) {

.EmailVerificationPage_layoutWrapperMain__3V5P7 {
    min-height: calc(100vh - 72px);
    min-height: calc(100vh - 72px)
}
  }

.EmailVerificationPage_root__8N5om {
    /* Page is using flex: AuthenticationPage's .root takes all available space */
  flex-grow: 1;

    /* AuthenticationPage's root uses flexbox */
  display: flex
}

@media (min-width: 768px) {

.EmailVerificationPage_root__8N5om {
  justify-content: center;
  align-items: flex-start
}
    }

@media (min-width: 768px) {

.EmailVerificationPage_root__8N5om {
    /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
      url(/static/media/landowner-header.97202e7b.jpg);

    /* Add loading color for the div */
  background-color: #4a5569;
  background-color: #4a5569;

    /* Cover the whole screen with the background image */
  background-size: cover;

    /* Align the image within the container */
  background-position: 40% 30%
}
  }

@media (min-width: 768px) and (min-width: 768px) {

.EmailVerificationPage_root__8N5om {
  background-position: center center
}
    }

.EmailVerificationPage_content__3Jt6i {
  flex-grow: 1;

    /* Create context so the close button can be positioned within it */
  position: relative;

    /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: 24px 24px 48px 24px;
  padding: 24px 24px 48px 24px;

  background-color: #ffffff;

  background-color: #ffffff;
  border-radius: 2px;
  border-radius: 2px;
  border-bottom: none;
}

@media (min-width: 768px) {

.EmailVerificationPage_content__3Jt6i {
  flex-basis: 480px;
  flex-grow: 0;
      /* min-height: 568px; */
  padding: 55px 60px 55px 60px;
  padding: 55px 60px 55px 60px;
  margin-top: 12.5vh;
  margin-bottom: 12.5vh;
  border-bottom: 8px solid #3e704b;
  border-bottom: 8px solid #3e704b;
}
    }

.EmailVerificationPage_error__1gdHn {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.EmailVerificationPage_error__1gdHn {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.EmailVerificationPage_error__1gdHn {
  margin-top: 24px;
  color: #ff0000;
  color: #ff0000;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.IDVerificationPage_container__wcS4t {
  padding: 3rem 1rem;
  padding: 3rem 1rem;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column
}

@media (min-width: 1024px) {

.IDVerificationPage_container__wcS4t {
    padding: 6rem 0;
    padding: 6rem 0;
    flex-direction: row
}
  }

.IDVerificationPage_verificationContainer__1jF94 {
}

.IDVerificationPage_coltonContainer__nNWbr {
  display: none
}

@media (min-width: 1024px) {

.IDVerificationPage_coltonContainer__nNWbr {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    width: 350px;
    margin-left: 6rem;
    margin-left: 6rem
}
  }

.IDVerificationPage_frameContainer__1KAPh {
  margin: 2rem 0;
  margin: 2rem 0;
}

.IDVerificationPage_frame__22O2y {
  min-height: 18rem;
  min-height: 18rem;
}

.IDVerificationPage_separator__d7VRN {
  margin: 2rem 0;
  margin: 2rem 0;
  border-top: 1px #e2e8f0 solid;
  border-top: 1px #e2e8f0 solid;
}

.IDVerificationPage_topBarExitButton__QiZL7 {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: bold;
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #1a202c;
  color: #1a202c;
  cursor: pointer;
}

.IDVerificationPage_backLink__3iPrm {
  font-weight: 600;
  font-weight: 600;
}

.IDVerificationPage_sharingConsentContainer__27BlK {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.IDVerificationPage_lockIcon__2KM4V {
  width: 2rem;
  width: 2rem;
  color: currentColor;
  margin-right: 0.5rem;
  margin-right: 0.5rem
}

@media (min-width: 768px) {

.IDVerificationPage_lockIcon__2KM4V {
    width: 1rem;
    width: 1rem
}
  }

.IDVerificationPage_statusBannerRoot__3id8U {
  padding: 1rem;
  padding: 1rem;
  background-color: #f7fafc;
  background-color: #f7fafc;
  border: 2px solid transparent;
  border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.IDVerificationPage_statusBannerRoot--warning__3qL1Y {
  border-color: #ffbb52;
  border-color: #ffbb52;
}

.IDVerificationPage_statusBannerRoot--success__Rh0rR {
  border-color: #a9ce93;
  border-color: #a9ce93;
}

.IDVerificationPage_statusBannerRoot--error__1pk5m {
  border-color: #ed3615;
  border-color: #ed3615;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FAQSectionHero_root__1qJZo {
  background-color: #f7fafc;
  background-color: #f7fafc;
}

.FAQSectionHero_wrapper__IimTh {
  display: flex;
  flex-direction: column;
}

.FAQSectionHero_heroImageColumn__38Jvk {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 12rem;
  min-height: 12rem;
}

.FAQSectionHero_column__2gXKM {
  padding: 1.75rem 2rem;
  padding: 1.75rem 2rem;
}

.FAQSectionHero_title__2Onaq {
  line-height: 1.25;
  line-height: 1.25;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
}

.FAQSectionHero_cta__O6Vb4 {
  margin: 2rem 0;
  margin: 2rem 0;
  text-align: center;
}

@media (min-width: 1024px) {
  .FAQSectionHero_root__1qJZo {
    padding-top: 6rem;
    padding-top: 6rem;
    margin-bottom: 3rem;
    margin-bottom: 3rem;
  }

  .FAQSectionHero_wrapper__IimTh {
    flex-direction: row;
  }

  .FAQSectionHero_heroImageColumn__38Jvk {
    width: 60%;
    width: 60%;
    border-radius: 5px;
    border-radius: 5px;
    order: 1;
    margin-bottom: calc(-1 * 3rem);
    margin-bottom: calc(-1 * 3rem);
  }

  .FAQSectionHero_contentColumn__18l4p {
    width: 40%;
    width: 40%;
    font-size: 1.125rem;
    font-size: 1.125rem;
  }

  .FAQSectionHero_cta__O6Vb4 {
    text-align: left;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.FAQLandownersPage_section__8ooDZ {
  margin-bottom: 4.5rem;
  margin-bottom: 4.5rem;
  padding: 0 2rem;
  padding: 0 2rem
}

.FAQLandownersPage_section__8ooDZ:first-child {
    margin-top: 4.5rem;
    margin-top: 4.5rem;
  }

@media (min-width: 768px) {

.FAQLandownersPage_section__8ooDZ {
    padding: 0
}
  }

.FAQLandownersPage_sectionFullWidth__27W9P {
  padding: 0;
}

.FAQLandownersPage_sectionMarginless__pm4x6 {
  margin-bottom: 0;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FAQSectionSpecies_title__1j0Xg {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 600;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.FAQSectionSpecies_description__H5-6b {
  margin: 0;
  font-size: 1.125rem;
  font-size: 1.125rem;
}

.FAQSectionSpecies_wrapper__3DUp4 {
  display: flex;
  flex-direction: column;
}

.FAQSectionSpecies_imageColumn__1GPYV {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FAQSectionSpecies_imageInner__2F0yq {
  display: flex;
  align-items: center;
  margin-left: calc(-1 * 0.5rem);
  margin-left: calc(-1 * 0.5rem);
}

.FAQSectionSpecies_deerImage__1SMcG {
  max-width: 200px;
  margin-left: -75px;
}

.FAQSectionSpecies_turkeyImage__2EK71 {
  max-width: 134px;
  margin-top: 130px;
  border: 0.5rem solid #fff;
  border: 0.5rem solid #fff;
  border-radius: 50%;
  z-index: 1;
}

@media (min-width: 1024px) {
  .FAQSectionSpecies_root__1dX5l {
    padding-top: 6rem;
    padding-top: 6rem;
  }

  .FAQSectionSpecies_wrapper__3DUp4 {
    flex-direction: row;
  }

  .FAQSectionSpecies_column__1m22y {
    width: 60%;
    width: 60%;
  }

  .FAQSectionSpecies_contentColumn__3GrWA {
    width: 40%;
    width: 40%;
    padding-top: 4rem;
    padding-top: 4rem;
    padding-right: 2rem;
    padding-right: 2rem;
  }

  .FAQSectionSpecies_title__1j0Xg {
    font-size: 1.875rem;
    font-size: 1.875rem;
    font-weight: bold;
  }

  .FAQSectionSpecies_imageColumn__1GPYV {
    justify-content: left;
    padding-left: 8rem;
    padding-left: 8rem;
  }

  .FAQSectionSpecies_imageInner__2F0yq {
    align-items: flex-start;
  }

  .FAQSectionSpecies_deerImage__1SMcG {
    max-width: 356px;
    margin-left: -120px;
  }

  .FAQSectionSpecies_turkeyImage__2EK71 {
    margin-top: 190px;
    max-width: 236px;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FAQSectionPlatforms_wrapper__2zT-x {
  display: flex;
  flex-direction: column;
}

.FAQSectionPlatforms_platformLogos__2aAm0 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.FAQSectionPlatforms_platformLogo__1FZmX {
  max-height: 100%;
  object-fit: contain;
}

.FAQSectionPlatforms_platformAirBnB__weurC {
  height: 2rem;
  height: 2rem;
}

.FAQSectionPlatforms_platformVrbo__2iSYx {
  height: 1.25rem;
  height: 1.25rem;
}

.FAQSectionPlatforms_platformHomeAway__2gqsA {
  height: 1.5rem;
  height: 1.5rem;
}

.FAQSectionPlatforms_title__1F9Zz {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 600;
  font-weight: 600;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 22px;
}

.FAQSectionPlatforms_content__m_1N1 {
  font-size: 1.125rem;
  font-size: 1.125rem;
  margin: 0;
}

.FAQSectionPlatforms_phoneColumn__3IM6D {
  overflow: hidden;
  max-height: 350px;
  margin-top: 3rem;
  margin-top: 3rem;
}

.FAQSectionPlatforms_phoneImage__Stzqz {
  max-width: 100%;
}

@media (min-width: 1024px) {
  .FAQSectionPlatforms_wrapper__2zT-x {
    flex-direction: row;
  }

  .FAQSectionPlatforms_platformLogos__2aAm0 {
    justify-content: left;
  }

  .FAQSectionPlatforms_platformLogo__1FZmX {
    margin-right: 1.5rem;
    margin-right: 1.5rem;
  }

  .FAQSectionPlatforms_phoneColumn__3IM6D {
    width: 40%;
    width: 40%;
    max-height: 640px;
    order: 0;
  }

  .FAQSectionPlatforms_phoneImage__Stzqz {
    max-width: 500px;
  }

  .FAQSectionPlatforms_contentColumn__29gn5 {
    width: 50%;
    width: 50%;
    padding-top: 10rem;
    padding-top: 10rem;
    padding-left: 8rem;
    padding-left: 8rem;
    padding-right: 2rem;
    padding-right: 2rem;
    order: 1;
  }

  .FAQSectionPlatforms_title__1F9Zz {
    font-size: 1.875rem;
    font-size: 1.875rem;
    font-weight: bold;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* A simple technique I developed here: https://codesandbox.io/s/flexbox-with-gaps-simple-qu7wl
   These helpers can be used to construct a flexbox layout that supports grid gaps like css grid does.
   This is not as easy as it seems, especially with a responsive flex layout that can wrap, this solution adds
   margins to add flex items then uses negative margins to offset the margins at the edge so that they don't
   interfere with elements outside of this one

   Usage:

   JSX:

   <div className={css.filtersWrapper}>
     <div className={css.filtersInner}>
       <div className={css.filter}>Item 1</div>
       <div className={css.filter}>Item 2</div>
       <div className={css.filter}>Item 3</div>
     </div>
   </div>

   CSS:

   @import '../FlexHelper/FlexHelper.css';

   // Decorate the wrapper here as you wish but don't add padding
   .filtersWrapper {
     @apply --flexWrapper;
   }

   // Don't add much except flexbox configuration here
   .filtersInner {
     @apply --flexInner3;
     justify-content: center; // Or whatever flex stuff you want to add
   }

   // Decorate each item as you wish but don't add margins
   .filter {
     @apply --flexItem3;

     // You can add widths here for more of a grid-like look.
     // You need to use / add helpers though because the widths need to be calculated using the margin
     @apply --flexItem3Width50Percent;

     @media (--screen-md) {
       @apply --flexItem3Width25Percent;
     }
   }

   In this simple version the gaps cannot be configured to change at breakpoints but ...

   Extra classes for different configurations could be added here and reused elsewhere as needed

   Or it can be expended to support a nice Bootstrap-style grid: https://codesandbox.io/s/pmxn0pqpyq
   - This implementation can be responsive but every use on the app is the same

   Or it can be used like this when uncoupled from the limitations of CSS Modules: https://codesandbox.io/s/mz13k4mw4x
   - This implementation can change behavior on a per usage basis but it cannot practically be done in CSS Modules
     without using real CSS Custom Properties
*/

.FAQSectionBenefits_root__iqy1P {
  background-color: #f7fafc;
  background-color: #f7fafc;
  padding: 4rem 2rem;
  padding: 4rem 2rem;
}

.FAQSectionBenefits_title__14bHi {
  text-align: center;
  line-height: 1.25;
  line-height: 1.25;
  margin-bottom: 4.5rem;
  margin-bottom: 4.5rem;
}

.FAQSectionBenefits_cardsWrapper__3H1i9 {
  display: inline-block;
  width: 100%;
}

.FAQSectionBenefits_cardsInner__1n9S2 {
  display: flex;
  flex-wrap: wrap;

    /* Stop the negative margins preventing clicks part 1.
       This part makes the negative margins (and all children) unclickable */
  pointer-events: none;

  margin: calc(0px - 2.5rem) 0 0 calc(0px - 2.5rem);

  margin: calc(0px - 2.5rem) 0 0 calc(0px - 2.5rem);
}

.FAQSectionBenefits_cardsInner__1n9S2:dir(rtl) {
  margin: calc(0px - 2.5rem) calc(0px - 2.5rem) 0 0;
  margin: calc(0px - 2.5rem) calc(0px - 2.5rem) 0 0;
    }

.FAQSectionBenefits_cardIconContainer__3Vo4s {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.FAQSectionBenefits_cardIcon__3c3-q {
  height: 47px;
  color: #3e704b;
  color: #3e704b;
  fill: currentColor;
}

.FAQSectionBenefits_cardRoot__1PXmb {
    /* Stop the negative margins preventing clicks part 2.
       This part restores clicks for all the items and their children */
  pointer-events: auto;

  margin: 2.5rem 0 0 2.5rem;

  margin: 2.5rem 0 0 2.5rem
}

.FAQSectionBenefits_cardRoot__1PXmb:dir(rtl) {
  margin: 2.5rem 2.5rem 0 0;
  margin: 2.5rem 2.5rem 0 0;
    }

.FAQSectionBenefits_cardRoot__1PXmb {
  padding: 1.25rem;
  padding: 1.25rem;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #e2e8f0;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  border-radius: 10px;
  min-height: 300px;
  max-height: 300px
}

.FAQSectionBenefits_cardRoot__1PXmb:last-child {
    margin-bottom: 0;
  }

.FAQSectionBenefits_cardTitle__31Ty4 {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.FAQSectionBenefits_cardBody__1iM-H {
  text-align: center;
}

@media (min-width: 1024px) {
  .FAQSectionBenefits_root__iqy1P {
    padding-top: 8rem;
    padding-top: 8rem;
  }

  .FAQSectionBenefits_title__14bHi {
    max-width: 50%;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .FAQSectionBenefits_cardRoot__1PXmb {
  width: calc(33.333333% - 2.5rem);
  width: calc(33.333333% - 2.5rem);
    padding-top: 2.5rem;
    padding-top: 2.5rem;
  }

  .FAQSectionBenefits_cardTitle__31Ty4 {
    margin-bottom: 0.25rem;
    margin-bottom: 0.25rem;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FAQSectionConservation_root__2Oqru {
}

.FAQSectionConservation_iconContainer__13aKn {
  text-align: center;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
}

.FAQSectionConservation_title__3aZLn {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.5rem;
  font-size: 1.5rem;
}

@media (min-width: 768px) {

.FAQSectionConservation_title__3aZLn {
  font-size: 1.875rem;
  font-size: 1.875rem;
}
    }

.FAQSectionConservation_title__3aZLn {

  padding: 0 0.75rem;

  padding: 0 0.75rem;
  text-align: center;
}

.FAQSectionConservation_content__1uPCO {
  display: none;
}

@media (min-width: 1024px) {
  .FAQSectionConservation_root__2Oqru {
    padding: 8rem 0;
    padding: 8rem 0;
    width: 66.666667%;
    width: 66.666667%;
    margin: 0 auto;
  }

  .FAQSectionConservation_content__1uPCO {
    padding: 0 3rem;
    padding: 0 3rem;
    display: block;
    text-align: center;
    font-size: 1.125rem;
    font-size: 1.125rem;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FAQSectionLandownerQuote_root__1jXWs {
}

.FAQSectionLandownerQuote_imageContainer__2ClS7 {
  position: relative;
  padding: 0 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
  margin-left: calc(-1 * 1.25rem);
  margin-left: calc(-1 * 1.25rem)
}

.FAQSectionLandownerQuote_imageContainer__2ClS7:before {
    position: absolute;
    top: 1.25rem;
    top: 1.25rem;
    left: 2.5rem;
    left: 2.5rem;
    bottom: calc(-1 * 1.25rem);
    bottom: calc(-1 * 1.25rem);
    right: 0;
    border-radius: 10px;
    border-radius: 10px;
    background-color: #cbd5e0;
    background-color: #cbd5e0;
    content: '';
    z-index: -1;
  }

.FAQSectionLandownerQuote_image__1bHNr {
  max-width: 100%;
  border-radius: 10px;
  border-radius: 10px;
}

.FAQSectionLandownerQuote_contentContainer__1zGoY {
  text-align: center;
  padding: 0 0.75rem;
  padding: 0 0.75rem;
}

.FAQSectionLandownerQuote_quotes__2fveP {
  margin-bottom: calc(-1 * 0.75rem);
  margin-bottom: calc(-1 * 0.75rem);
  text-align: center;
  font-size: 5rem;
  font-size: 5rem;
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  line-height: 1;
  line-height: 1;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.FAQSectionLandownerQuote_body__1aKge {
  font-size: 1.125rem;
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.FAQSectionLandownerQuote_author__GC7Y9 {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 600;
  font-weight: 600;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

@media (min-width: 1024px) {
  .FAQSectionLandownerQuote_root__1jXWs {
    padding: 8rem 0;
    padding: 8rem 0;
    display: flex;
  }

  .FAQSectionLandownerQuote_imageContainer__2ClS7 {
    width: var(--s-1-2);
    margin-right: 3rem;
    margin-right: 3rem;
    padding: 0 2rem
  }

    .FAQSectionLandownerQuote_imageContainer__2ClS7:before {
      left: 3rem;
      left: 3rem;
    }

  .FAQSectionLandownerQuote_image__1bHNr {
    max-width: 575px;
  }

  .FAQSectionLandownerQuote_quotes__2fveP {
    text-align: left;
    font-size: 6rem;
    font-size: 6rem;
    line-height: 0.5;
  }

  .FAQSectionLandownerQuote_contentContainer__1zGoY {
    padding: 6rem 0;
    padding: 6rem 0;
    width: var(--s-1-2);
    text-align: left;
  }

  .FAQSectionLandownerQuote_body__1aKge {
    font-size: 1.5rem;
    font-size: 1.5rem;
  }

  .FAQSectionLandownerQuote_author__GC7Y9 {
    font-size: 1.5rem;
    font-size: 1.5rem;
  }

  .FAQSectionLandownerQuote_location__2Nh2d {
    font-size: 1.25rem;
    font-size: 1.25rem;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* A simple technique I developed here: https://codesandbox.io/s/flexbox-with-gaps-simple-qu7wl
   These helpers can be used to construct a flexbox layout that supports grid gaps like css grid does.
   This is not as easy as it seems, especially with a responsive flex layout that can wrap, this solution adds
   margins to add flex items then uses negative margins to offset the margins at the edge so that they don't
   interfere with elements outside of this one

   Usage:

   JSX:

   <div className={css.filtersWrapper}>
     <div className={css.filtersInner}>
       <div className={css.filter}>Item 1</div>
       <div className={css.filter}>Item 2</div>
       <div className={css.filter}>Item 3</div>
     </div>
   </div>

   CSS:

   @import '../FlexHelper/FlexHelper.css';

   // Decorate the wrapper here as you wish but don't add padding
   .filtersWrapper {
     @apply --flexWrapper;
   }

   // Don't add much except flexbox configuration here
   .filtersInner {
     @apply --flexInner3;
     justify-content: center; // Or whatever flex stuff you want to add
   }

   // Decorate each item as you wish but don't add margins
   .filter {
     @apply --flexItem3;

     // You can add widths here for more of a grid-like look.
     // You need to use / add helpers though because the widths need to be calculated using the margin
     @apply --flexItem3Width50Percent;

     @media (--screen-md) {
       @apply --flexItem3Width25Percent;
     }
   }

   In this simple version the gaps cannot be configured to change at breakpoints but ...

   Extra classes for different configurations could be added here and reused elsewhere as needed

   Or it can be expended to support a nice Bootstrap-style grid: https://codesandbox.io/s/pmxn0pqpyq
   - This implementation can be responsive but every use on the app is the same

   Or it can be used like this when uncoupled from the limitations of CSS Modules: https://codesandbox.io/s/mz13k4mw4x
   - This implementation can change behavior on a per usage basis but it cannot practically be done in CSS Modules
     without using real CSS Custom Properties
*/

.FAQSectionSteps_root__1AfRM {
  padding: 3rem 2rem;
  padding: 3rem 2rem;
}

.FAQSectionSteps_root--brown__DvGKn {
  background-color: #e2caa5;
  background-color: #e2caa5;
}

.FAQSectionSteps_root--olive__3vvI4 {
  background-color: #3e704b;
  background-color: #3e704b;
}

.FAQSectionSteps_title__1xJfL {
  text-align: center;
}

.FAQSectionSteps_description__uNcZa {
  text-align: center;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
}

.FAQSectionSteps_ctaContainer__3cmWe {
  text-align: center;
}

.FAQSectionSteps_stepsWrapper__j7Ykl {
  display: inline-block;
  width: 100%;
}

.FAQSectionSteps_stepsInner__1UiUB {
  display: flex;
  flex-wrap: wrap;

    /* Stop the negative margins preventing clicks part 1.
       This part makes the negative margins (and all children) unclickable */
  pointer-events: none;

  margin: calc(0px - 2.5rem) 0 0 calc(0px - 2.5rem);

  margin: calc(0px - 2.5rem) 0 0 calc(0px - 2.5rem);
}

.FAQSectionSteps_stepsInner__1UiUB:dir(rtl) {
  margin: calc(0px - 2.5rem) calc(0px - 2.5rem) 0 0;
  margin: calc(0px - 2.5rem) calc(0px - 2.5rem) 0 0;
    }

.FAQSectionSteps_stepRoot__LUC-w {
    /* Stop the negative margins preventing clicks part 2.
       This part restores clicks for all the items and their children */
  pointer-events: auto;

  margin: 2.5rem 0 0 2.5rem;

  margin: 2.5rem 0 0 2.5rem;
}

.FAQSectionSteps_stepRoot__LUC-w:dir(rtl) {
  margin: 2.5rem 2.5rem 0 0;
  margin: 2.5rem 2.5rem 0 0;
    }

.FAQSectionSteps_stepRoot__LUC-w {
  margin-bottom: 3rem;
  margin-bottom: 3rem;
}

.FAQSectionSteps_stepHeader__3IeWH {
  display: inline-block;
}

.FAQSectionSteps_stepIconContainer__1aWWF {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  color: #1a202c;
  color: #1a202c;
}

.FAQSectionSteps_stepTitle__2qBaR {
  font-size: 1.5rem;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.FAQSectionSteps_stepBody__2La8E {
  margin: 0;
}

@media (min-width: 1024px) {
  .FAQSectionSteps_root__1AfRM {
    padding: 5rem 2rem;
    padding: 5rem 2rem;
  }

  .FAQSectionSteps_description__uNcZa {
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    width: 40%;
    font-size: 1.5rem;
    font-size: 1.5rem;
  }

  .FAQSectionSteps_stepsWrapper__j7Ykl {
    margin-top: 4rem;
    margin-top: 4rem;
  }

  .FAQSectionSteps_stepRoot__LUC-w {
  width: calc(33.333333% - 2.5rem);
  width: calc(33.333333% - 2.5rem);
  }

  .FAQSectionSteps_stepBody__2La8E {
    font-size: 0.875rem;
    font-size: 0.875rem;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FAQSectionHelp_root__3dKcL {
}

.FAQSectionHelp_title__oHJ0F {
  display: none;
}

@media (min-width: 1024px) {
  .FAQSectionHelp_root__3dKcL {
    padding: 5rem 2rem;
    padding: 5rem 2rem;
  }

  .FAQSectionHelp_title__oHJ0F {
    margin-bottom: 5rem;
    margin-bottom: 5rem;
    text-align: center;
    display: block;
  }

  .FAQSectionHelp_cardContainer__3owza {
    margin: 0 auto;
    width: 900px;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FAQSectionFooter_root__3sXG_ {
  min-height: 360px;
  background-image: url(/static/media/footer.041e856c.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding: 3.5rem 2rem;
  padding: 3.5rem 2rem;
}

.FAQSectionFooter_content__3_ev7 {
  margin-bottom: 2.5rem;
  margin-bottom: 2.5rem;
}

@media (min-width: 1024px) {
  .FAQSectionFooter_root__3sXG_ {
    background-image: url(/static/media/footer@3x.652981f2.jpg);
    padding: 5rem 2rem;
    padding: 5rem 2rem;
    min-height: 480px;
  }

  .FAQSectionFooter_content__3_ev7 {
    width: 50%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 5rem;
    padding: 0 5rem;
    font-size: 1.5rem;
    font-size: 1.5rem;
  }
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

/* heroContainer gives the height for SectionHero */

/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */

/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */

.FAQLandownersInfoPage_heroContainer__3Hfhk {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
    /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
      url(/static/media/landowner-header.97202e7b.jpg);

    /* Add loading color for the div */
  background-color: #4a5569;
  background-color: #4a5569;

    /* Cover the whole screen with the background image */
  background-size: cover;

    /* Align the image within the container */
  background-position: 40% 30%;
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_heroContainer__3Hfhk {
  background-position: center center;
}
    }

.FAQLandownersInfoPage_heroContainer__3Hfhk {

  /* Cover the whole screen with the background image */
  background-size: cover;

  /* Align the image within the container */
  background-position: center center;

  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  height: 33.3vh;
  max-height: 400px;
}

.FAQLandownersInfoPage_hero__1wpU2 {
  flex-grow: 1;
  justify-content: center;
  padding-bottom: 32px;
}

.FAQLandownersInfoPage_heroContent__3HpmO {
  width: 100%;
  padding: 40px 30px
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_heroContent__3HpmO {
    padding: 60px 7.5vw 60px 7.5vw
}
  }

@media (min-width: 1024px) {

.FAQLandownersInfoPage_heroContent__3HpmO {
    max-width: calc(1200px + 36px + 36px);
    margin: 60px auto;
    padding: 0 36px
}
  }

.FAQLandownersInfoPage_heroMainTitle__2Jdjq {
  font-weight: 700;
  font-weight: 700;
  font-size: 42px;
  line-height: 42px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 25px;
  margin-bottom: 20px
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_heroMainTitle__2Jdjq {
  font-size: 72px;
  line-height: 72px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 25px;
  margin-bottom: 31px
}
    }

@media (min-width: 1681px) {

.FAQLandownersInfoPage_heroMainTitle__2Jdjq {
  font-size: 84px;
  line-height: 84px
}
    }

.FAQLandownersInfoPage_heroMainTitle__2Jdjq {
  color: #ffffff;
  color: #ffffff;
  margin-bottom: 0px
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_heroMainTitle__2Jdjq {
    max-width: var(--SectionHero_desktopTitleMaxWidth)
}
  }

.FAQLandownersInfoPage_heroSubTitle__1w3ki {
  font-weight: 500;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
    /* No margins for default font */
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_heroSubTitle__1w3ki {
  font-size: 20px;
  line-height: 24px;
}
    }

.FAQLandownersInfoPage_heroSubTitle__1w3ki {
  font-size: 24px;
  color: #ffffff;
  color: #ffffff;
}

.FAQLandownersInfoPage_sections__1DqAA {
  margin: 0;
}

.FAQLandownersInfoPage_sectionContent__1V2aY {
  font-size: 16px;
  margin: 0 24px
}

@media (min-width: 1024px) {

.FAQLandownersInfoPage_sectionContent__1V2aY {
    max-width: calc(1200px + 36px + 36px);
    margin: 20px auto 60px auto;
    padding: 0 36px 0 36px
}
  }

.FAQLandownersInfoPage_sectionCentered__36eJH {
  text-align: center;
}

.FAQLandownersInfoPage_sectionTitle__3kcqF {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_sectionTitle__3kcqF {
  font-size: 1.875rem;
  font-size: 1.875rem;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px;
}
    }

.FAQLandownersInfoPage_sectionTitle__3kcqF {
  margin: 30px 0px;
  padding: 0;
}

.FAQLandownersInfoPage_sectionSteps__2QO0J {
  display: flex;
  flex-direction: column;
}

.FAQLandownersInfoPage_sectionStep__33gun,
.FAQLandownersInfoPage_sectionStepReverse__1yPII {
  display: flex;
  flex-wrap: wrap;
}

.FAQLandownersInfoPage_sectionStepReverse__1yPII {
  flex-wrap: wrap-reverse;
  margin: 0px 0px 40px 0px;
}

.FAQLandownersInfoPage_sectionStepDescriptionContainer__1TsIZ {
  display: flex;
  flex: 50% 1;
  flex-direction: column;
  min-width: 300px;
  padding: 0;
}

/* A wrapper for a responsive image that holds it's aspect ratio */

.FAQLandownersInfoPage_imageWrapper__3IE4l {
  flex: 50% 1;
  position: relative;
  width: 100%;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
}

.FAQLandownersInfoPage_imageWrapper2__2PQ-i {
  position: relative;
  height: 300px;
  width: 100%;
  transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
  margin-bottom: 20px;
}

/* An image wrapper that defines the images aspect ratio */

.FAQLandownersInfoPage_aspectWrapper__3Dpdh {
  padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
}

.FAQLandownersInfoPage_locationImage__3mQmG {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
}

.FAQLandownersInfoPage_locationImage2__1yVZC {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: -0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0px 10px;
}

.FAQLandownersInfoPage_helpButton__2gjsP {
  font-weight: 600;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_helpButton__2gjsP {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.FAQLandownersInfoPage_helpButton__2gjsP {

    /* Dimensions */
  width: 100%;
  min-height: 30px;
  margin: 0;
  white-space: nowrap;

  padding: 4px 20px;

    /* Borders */
  border-style: solid;
  border-width: 1px;
  border-color: #e2e8f0;
  border-color: #e2e8f0;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #1a202c;
  color: #1a202c;

    /* Text */
  text-align: center;
  text-decoration: none;

  cursor: pointer;
}

.FAQLandownersInfoPage_helpButton__2gjsP:hover,
    .FAQLandownersInfoPage_helpButton__2gjsP:focus {
  border-color: #cbd5e0;
  border-color: #cbd5e0;
  outline: none;
    }

.FAQLandownersInfoPage_helpButton__2gjsP {
  font-weight: 500;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
  border-color: #1a202c;
  border-color: #1a202c;
  width: 140px;
}

.FAQLandownersInfoPage_boldH4__9P3xV {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_boldH4__9P3xV {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.FAQLandownersInfoPage_boldH4__9P3xV {
  font-weight: 600;
  font-weight: 600;
  margin: 0px;
}

.FAQLandownersInfoPage_highlightSection__33jg2 {
  margin: 0 24px;
}

.FAQLandownersInfoPage_highlightSectionContent__G80yp {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_highlightSectionContent__G80yp {
  font-size: 1.875rem;
  font-size: 1.875rem;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px
}
    }

.FAQLandownersInfoPage_highlightSectionContent__G80yp {
  line-height: 1.5;
  text-align: center
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_highlightSectionContent__G80yp {
    margin: 40px 7.5vw
}
  }

@media (min-width: 1024px) {

.FAQLandownersInfoPage_highlightSectionContent__G80yp {
    max-width: calc(1200px + 36px + 36px);
    margin: 40px auto
}
  }

.FAQLandownersInfoPage_textHighlight__20Spm {
  padding: 0 8px;
  color: #ffffff;
  color: #ffffff;
  background-color: #3e704b;
  background-color: #3e704b;
}

.FAQLandownersInfoPage_coverageDetails__2X1MV {
  list-style: disc;
  padding: 16px;
}

.FAQLandownersInfoPage_coverageDetails__2X1MV > li {
  font-size: 16px;
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_whyGroup__2M1UB {
    display: flex
}
  }

.FAQLandownersInfoPage_whyGroupItem__Vurs2 {
  margin-bottom: 24px
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_whyGroupItem__Vurs2 {
    width: 33%;
    margin-right: 24px
}

    .FAQLandownersInfoPage_whyGroupItem__Vurs2:last-child {
      margin-right: 0;
    }
  }

.FAQLandownersInfoPage_signupCTA__L93MN {
  max-width: 240px;
  margin: 24px auto;
}

.FAQLandownersInfoPage_title__2kDw7 {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_title__2kDw7 {
  font-size: 1.875rem;
  font-size: 1.875rem;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px
}
    }

.FAQLandownersInfoPage_title__2kDw7 {

  margin-top: 0;
  max-width: 735px
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_title__2kDw7 {
    margin-bottom: 23px
}
  }

.FAQLandownersInfoPage_cardContainer__KtI5L {
  margin: auto
}

@media (--viewport) {

.FAQLandownersInfoPage_cardContainer__KtI5L {
    width: 33.33%
}
  }

.FAQLandownersInfoPage_landownerFrame__2ZgUa {
  width: 100%;
  border: none
}

@media (min-width: 768px) {

.FAQLandownersInfoPage_landownerFrame__2ZgUa {
    padding-left: 20%;
    padding-right: 20%
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.InboxPage_topbar__1lUVb {
  z-index: 1; /* ensure that Topbar shadow overlays content background */
}

.InboxPage_mobileTopbar__13pQm {
  /* Size */
  width: 100%;
  height: 60px;
  height: 60px;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: #ffffff;
  background-color: #ffffff;

  /* shadows */
  box-shadow: none
}

@media (min-width: 768px) {

.InboxPage_mobileTopbar__13pQm {
    display: none
}
  }

.InboxPage_desktopTopbar__cMdu6,
.InboxPage_mobileTopbar__13pQm {
  box-shadow: none
}

@media (min-width: 1024px) {

.InboxPage_desktopTopbar__cMdu6,
.InboxPage_mobileTopbar__13pQm {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05)
}
  }

.InboxPage_navigation__H3Tft {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 29px 24px 0 24px;

  border-top-width: 1px;
  border-top-color: #a1aec1;
  border-top-color: #a1aec1;
  border-top-style: solid;
  background-color: #ffffff;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1)
}

@media (min-width: 768px) {

.InboxPage_navigation__H3Tft {
    padding: 24px 24px 0 24px
}
  }

@media (min-width: 1024px) {

.InboxPage_navigation__H3Tft {
    padding: 113px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent
}
  }

@media (min-width: 1128px) {

.InboxPage_navigation__H3Tft {
    padding: 113px 0 82px calc((100% - 1056px) / 2)
}
  }

.InboxPage_title__1fMb9 {
  /* Font */
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.5rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px
}

@media (min-width: 768px) {

.InboxPage_title__1fMb9 {
  font-size: 1.875rem;
  font-size: 1.875rem;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px
}
    }

.InboxPage_title__1fMb9 {
  color: #4a5569;
  color: #4a5569;

  /* Layout */
  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 768px) {

.InboxPage_title__1fMb9 {
    padding-bottom: 10px;
    margin-top: 0;
    margin-bottom: 0
}
  }

@media (min-width: 1024px) {

.InboxPage_title__1fMb9 {
    padding-bottom: 0
}
  }

.InboxPage_tabs__36kiD {
  padding-top: 2px;

  display: flex;
  flex-direction: row;
  overflow-x: auto
}

@media (min-width: 768px) {

.InboxPage_tabs__36kiD {
    padding-top: 12px;
    padding-top: 20px
}
  }

@media (min-width: 1024px) {

.InboxPage_tabs__36kiD {
    padding-top: 53px;
    flex-direction: column
}
  }

.InboxPage_tab__22JAP {
  margin-left: 16px
}

.InboxPage_tab__22JAP:first-child {
    margin-left: 0;
  }

@media (min-width: 1024px) {

.InboxPage_tab__22JAP {
    margin-left: 0
}
  }

.InboxPage_error__19ydC {
  color: #ff0000;
  color: #ff0000;
  margin: 23px 24px 24px 24px
}

@media (min-width: 1024px) {

.InboxPage_error__19ydC {
    margin-top: 18px
}
  }

.InboxPage_noResults__2NGPB {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 1px
}

@media (min-width: 1024px) {

.InboxPage_noResults__2NGPB {
    margin-top: 6px
}
  }

.InboxPage_itemList__23pzN {
  margin: 0;
  padding: 0;
}

@keyframes InboxPage_loadingSpinnerFadeIn__2lBwQ {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.InboxPage_listItemsLoading__aC8Ms {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;

  /* animation shorthand property doesn't work with local scope of CSS Modules */
  animation-duration: 1s;
  animation-name: InboxPage_loadingSpinnerFadeIn__2lBwQ;
  animation-fill-mode: forwards;
}

.InboxPage_listItem__1rrup {
  /* Layout */
  margin-bottom: 10px;
  padding-bottom: 12px;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #a1aec1;
  border-bottom-color: #a1aec1
}

.InboxPage_listItem__1rrup:last-child {
    border-bottom: none;
  }

@media (min-width: 1024px) {

.InboxPage_listItem__1rrup {
    margin-bottom: 24px;
    padding-bottom: 15px
}
  }

.InboxPage_item__2Sfel {
  display: flex;
  flex-direction: row;
}

.InboxPage_itemLink__3QurF {
  flex: 1 1;

  /* Layout */
  display: flex;

  /* Remove text underline from this link */
  text-decoration: none
}

.InboxPage_itemLink__3QurF:hover {
    text-decoration: none;
  }

.InboxPage_itemLink__3QurF:hover .InboxPage_itemUsername__aJNUx {
    /* Hightlight the username when the whole item is hovered */
    color: #3e704b;
    color: #3e704b;
  }

.InboxPage_itemAvatar__hbiC6 {
  margin-right: 8px
}

@media (min-width: 1024px) {

.InboxPage_itemAvatar__hbiC6 {
    margin-top: 2px;
    margin-right: 15px
}
  }

.InboxPage_rowNotificationDot__1YJKg {
  width: 6px;
  height: 6px;
  margin-top: 10px;
  margin-right: 8px
}

@media (min-width: 1024px) {

.InboxPage_rowNotificationDot__1YJKg {
    margin-right: 13px;
    width: 9px;
    height: 9px
}
  }

.InboxPage_notificationDot__2HXE2 {
  color: #ffffff;
  color: #ffffff;

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ff0000;
  background-color: #ff0000;
}

.InboxPage_itemInfo__1iP0_ {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.InboxPage_itemInfo__1iP0_ {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.InboxPage_itemInfo__1iP0_ {
  font-weight: 600;
  font-weight: 600;

  /* Layout */
  flex-grow: 1;
  flex: 1 1;
  flex-direction: column;

  margin-top: 5px;
  margin-bottom: 0;
}

@media (min-width: 768px) {

.InboxPage_itemInfo__1iP0_ {
    margin-top: 3px;
    margin-bottom: 0;
}
  }

.InboxPage_itemInfo__1iP0_ {

  color: #4a5569;

  color: #4a5569;
}

.InboxPage_itemUsername__aJNUx {
  /* Font */
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.InboxPage_itemUsername__aJNUx {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.InboxPage_itemUsername__aJNUx {
  color: #4a5569;
  color: #4a5569;
  line-height: 18px;

  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 768px) {

.InboxPage_itemUsername__aJNUx {
    margin-top: 0;
    margin-bottom: 0
}
  }

.InboxPage_bookingInfo__2DKAo {
  font-size: 14px;
  line-height: 14px;
  margin-top: 3px
}

@media (min-width: 768px) {

.InboxPage_bookingInfo__2DKAo {
    margin-top: 8px;
    line-height: 16px
}
  }

.InboxPage_itemTimestamp__2WzAm {
  /* Font */
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.InboxPage_itemTimestamp__2WzAm {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.InboxPage_itemTimestamp__2WzAm {

  margin-top: 0px;
  margin-bottom: 0px
}

@media (min-width: 768px) {

.InboxPage_itemTimestamp__2WzAm {
    margin-top: 4px;
    margin-bottom: 0
}
  }

.InboxPage_itemPrice__JM1pa::before {
    font-size: 10px;
    margin: 0 6px;
    display: inline-block;
  }

.InboxPage_itemState__2frn1 {
  /* Font */
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.InboxPage_itemState__2frn1 {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.InboxPage_itemState__2frn1 {

  font-weight: 500;

  font-weight: 500;
  margin-top: 6px;
  margin-bottom: 0px
}

@media (min-width: 768px) {

.InboxPage_itemState__2frn1 {
    margin-top: 0;
    margin-bottom: 0
}
  }

.InboxPage_lastTransitionedAt__1RPBR {
  text-align: right;
  color: #4a5569;
  color: #4a5569;
}

.InboxPage_pagination__17_En {
  margin: auto 24px 0 24px;
}

/* Transaction status affects to certain font colors and weights */

.InboxPage_nameEnquired__sjv1X,
.InboxPage_namePending__1Wb9x {
  font-weight: 700;
  font-weight: 700;

  /* baseline alignment fixes */
  margin-top: -1px;
  margin-bottom: 1px;
}

.InboxPage_stateName__2DR3E {
  /* This class is empty on purpose, it is used below for banned users */
}

.InboxPage_stateActionNeeded__27Y90 {
  font-weight: 600;
  font-weight: 600;
  color: #ffaa00;
  color: #ffaa00;
}

.InboxPage_stateNoActionNeeded__1TAn_ {
  color: #a1aec1;
  color: #a1aec1;
}

.InboxPage_stateSucces__2uCvU {
  font-weight: 600;
  font-weight: 600;
  color: #2ecc71;
  color: #2ecc71;
}

.InboxPage_nameEmphasized__2CMRj {
  font-weight: 700;
  font-weight: 700;

  /* baseline alignment fixes */
  margin-top: -1px;
  margin-bottom: 1px;
}

.InboxPage_nameNotEmphasized__365ny {
  font-weight: 500;
  font-weight: 500;
}

.InboxPage_bookingActionNeeded__1R2uM {
  color: #4a5569;
  color: #4a5569;
  margin-top: 4px
}

@media (min-width: 768px) {

.InboxPage_bookingActionNeeded__1R2uM {
    margin-top: 9px
}
  }

.InboxPage_bookingNoActionNeeded__1qI_y {
  color: #a1aec1;
  color: #a1aec1;
}

.InboxPage_lastTransitionedAtEmphasized__2wWmb {
  color: #4a5569;
  color: #4a5569;
  font-weight: 500;
  font-weight: 500;
}

.InboxPage_lastTransitionedAtNotEmphasized__1naXg {
  color: #a1aec1;
  color: #a1aec1;
}

.InboxPage_bannedUserLink__3b9yD .InboxPage_itemUsername__aJNUx,
  .InboxPage_bannedUserLink__3b9yD:hover .InboxPage_itemUsername__aJNUx,
  .InboxPage_bannedUserLink__3b9yD .InboxPage_bookingInfo__2DKAo,
  .InboxPage_bannedUserLink__3b9yD .InboxPage_stateName__2DR3E,
  .InboxPage_bannedUserLink__3b9yD .InboxPage_lastTransitionedAt__1RPBR {
    color: #a1aec1;
    color: #a1aec1;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.BookingSuccessModal_contentWrapper__1I_l2 {
  margin-bottom: 2.5rem;
  margin-bottom: 2.5rem;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 10rem;
  margin-top: 10rem;
  width: 100%;
  height: 100%;
  position: relative
}

@media (min-width: 768px) {

.BookingSuccessModal_contentWrapper__1I_l2 {
    margin-top: 0;
    padding-bottom: 3.25rem;
    padding-bottom: 3.25rem;
    margin-bottom: 0
}
  }

.BookingSuccessModal_headingWrapper__RXURA {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.BookingSuccessModal_normalLottieAnimation__rfYiN {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 110%;
  height: 100%
}

@media (min-width: 768px) {

.BookingSuccessModal_normalLottieAnimation__rfYiN {
    top: 42%;
    width: 75%
}
  }

.BookingSuccessModal_verifiedLottieAnimation__3PflG {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 110%;
  height: 100%
}

@media (min-width: 768px) {

.BookingSuccessModal_verifiedLottieAnimation__3PflG {
    top: 37%;
    width: 75%
}
  }

.BookingSuccessModal_imageStyles__No4OQ {
  width: 5.75rem;
  width: 5.75rem;
  margin-top: 2.5rem;
  margin-top: 2.5rem
}

@media (min-width: 768px) {

.BookingSuccessModal_imageStyles__No4OQ {
    margin-top: 3.5rem;
    margin-top: 3.5rem
}
  }

.BookingSuccessModal_dividerWrapper__2ylIw {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BookingSuccessModal_divider__2LjMh {
  width: 7.75rem;
  width: 7.75rem;
  height: 1px;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  border: none;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.BookingSuccessModal_heading__3VbGS {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.875rem;
  font-size: 1.875rem;
  font-weight: 800;
  font-weight: 800;
  max-width: 28rem;
  max-width: 28rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.BookingSuccessModal_subHeading__oyfZn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-size: 1rem;
  max-width: 28rem;
  max-width: 28rem;
  width: 19rem;
  width: 19rem
}

@media (min-width: 768px) {

.BookingSuccessModal_subHeading__oyfZn {
    max-width: 27rem;
    max-width: 27rem;
    width: 27rem;
    width: 27rem
}
  }

.BookingSuccessModal_tipsHeading__MF3fu {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.BookingSuccessModal_tipsWrapper__26ORs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.BookingSuccessModal_tipsTextOne__1bwTq {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-size: 0.875rem;
  width: 24.5rem;
  width: 24.5rem;
  color: #1a202c;
  color: #1a202c;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.InquirySuccessModal_contentWrapper__OvDDi {
  width: 100%;
  height: 100%;
}

.InquirySuccessModal_sectionStyles__gZJTL {
  width: 100%;
  height: 100%;
  padding-left: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 0
}

@media (min-width: 768px) {

.InquirySuccessModal_sectionStyles__gZJTL {
    padding-bottom: 3.25rem;
    padding-bottom: 3.25rem
}
  }

.InquirySuccessModal_headingWrapper__Q-mY2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
}

.InquirySuccessModal_normalLottieAnimation__zt4Z9 {
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 110%;
  height: 100%
}

@media (min-width: 768px) {

.InquirySuccessModal_normalLottieAnimation__zt4Z9 {
    top: 40%;
    width: 75%
}
  }

.InquirySuccessModal_verifiedLottieAnimation__2RVmE {
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 110%;
  height: 100%
}

@media (min-width: 768px) {

.InquirySuccessModal_verifiedLottieAnimation__2RVmE {
    top: 35%;
    width: 75%
}
  }

.InquirySuccessModal_imageStyles__dqZZu {
  width: 5.75rem;
  width: 5.75rem;
  margin-top: 7.5rem;
  margin-top: 7.5rem
}

@media (min-width: 768px) {

.InquirySuccessModal_imageStyles__dqZZu {
    margin-top: 3.5rem;
    margin-top: 3.5rem
}
  }

.InquirySuccessModal_dividerWrapper__Nadlh {
  display: flex;
  justify-content: center;
  align-items: center;
}

.InquirySuccessModal_divider__1EZgQ {
  width: 7.75rem;
  width: 7.75rem;
  height: 1px;
  background-color: transparent;
  border: none;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.InquirySuccessModal_divider__1EZgQ {
    background-color: #e2e8f0;
    background-color: #e2e8f0
}
  }

.InquirySuccessModal_heading__3BX-b {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.875rem;
  font-size: 1.875rem;
  font-weight: 800;
  font-weight: 800;
  max-width: 19rem;
  max-width: 19rem;
  width: 100%;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 768px) {

.InquirySuccessModal_heading__3BX-b {
    max-width: 28rem;
    max-width: 28rem
}
  }

.InquirySuccessModal_subHeading__2iHEK {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-size: 1rem;
  max-width: 19rem;
  max-width: 19rem;
  width: 100%
}

@media (min-width: 768px) {

.InquirySuccessModal_subHeading__2iHEK {
    max-width: 28rem;
    max-width: 28rem
}
  }

.InquirySuccessModal_tipSectionWrapper__1B6QQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
}

.InquirySuccessModal_tipsHeading__2nMkV {
  display: flex;
  justify-content: left;
  align-items: left;
  text-align: left;
  width: 100%;
  font-size: 1rem;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem
}

@media (min-width: 768px) {

.InquirySuccessModal_tipsHeading__2nMkV {
    justify-content: center;
    align-items: center;
    width: 19rem;
    width: 19rem
}
  }

.InquirySuccessModal_tipsWrapper__2u7jc {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  text-align: left;
}

.InquirySuccessModal_tipsTextOne__323LX {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-size: 0.875rem;
  width: 100%;
  color: #1a202c;
  color: #1a202c;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.InquirySuccessModal_tipsTextOne__323LX {
    width: 24.5rem;
    width: 24.5rem
}
  }

.InquirySuccessModal_tipsTextTwo__3Uv2k {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-size: 0.875rem;
  width: 100%;
  color: #1a202c;
  color: #1a202c
}

@media (min-width: 768px) {

.InquirySuccessModal_tipsTextTwo__3Uv2k {
    width: 24.5rem;
    width: 24.5rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InboxPageMobile_mainContainer__1ppW4 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 9;
  overflow-x: auto;
  position: relative;
}

.InboxPageMobile_mainContainerMobile__2bRPL {
  padding-bottom: 5.75rem;
  padding-bottom: 5.75rem;
}

.InboxPageMobile_mainContainerMobileConfirmPayment__2-dLL {
  padding-bottom: 10rem;
  padding-bottom: 10rem;
}

.InboxPageMobile_listContainer__1jth8 {
  order: 1;
  width: 100%;
  overflow: hidden;
  z-index: 2;
}

.InboxPageMobile_listContainerHidden__3IDf2 {
  display: none;
}

.InboxPageMobile_detailsWrapper__whsKq {
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.3s ease-out;
  background: #fff;
  background: #fff;
  z-index: 1;
}

.InboxPageMobile_messagesContainer__G-e4A {
  padding-bottom: 10rem;
  padding-bottom: 10rem;
}

.InboxPageMobile_messagesContainerHidden__bjFsZ {
  opacity: 0;
  pointer-events: none;
}

.InboxPageMobile_messagesContent__3egzc {
  overflow: auto;
  flex-grow: 2;
}

.InboxPageMobile_sendMessageContent__2CitJ {
  padding: 0 1.5rem;
  padding: 0 1.5rem;
}

.InboxPageMobile_mobileDetailsContainer__3nIiL {
  display: flex;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem;
}

.InboxPageMobile_actionContainer__3TNP9 {
}

.InboxPageMobile_actionContainerHidden__2zA1g {
  opacity: 0;
  pointer-events: none;
}

.InboxPageMobile_mobileLinks__JOEKQ {
  display: flex;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 0 1.25rem;
  margin: 0 1.25rem;
  font-size: 0.875rem;
  font-size: 0.875rem;
  border-bottom: 1px solid #e2e8f0;
  border-bottom: 1px solid #e2e8f0
}

@media (min-width: 768px) {

.InboxPageMobile_mobileLinks__JOEKQ {
    display: none
}
  }

.InboxPageMobile_mobileLink__vha5F {
  text-decoration: none;
  padding: 0.5rem 0;
  padding: 0.5rem 0
}

.InboxPageMobile_mobileLink__vha5F:hover {
    text-decoration: none;
  }

.InboxPageMobile_mobileLinkActive__24NcJ {
  font-weight: 700;
  font-weight: 700;
  border-bottom: 2px solid #1a202c;
  border-bottom: 2px solid #1a202c;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InboxProviderResponseRate_root__3YT72 {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-size: 0.75rem;
  color: #1a202c;
  color: #1a202c;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InboxPageDesktop_mainContainer__ZJloW {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 9;
  overflow-x: auto
}

@media (min-width: 768px) {

.InboxPageDesktop_mainContainer__ZJloW {
    flex-shrink: 0;
    width: 100%;
    max-width: 1920px;
    height: calc(100vh - 4.5rem - 5.25rem);
    height: calc(100vh - 4.5rem - 5.25rem);
    flex-direction: row;
    padding: 0;
    margin: 0 auto 1.75rem auto;
    margin: 0 auto 1.75rem auto
}
  }

.InboxPageDesktop_listContainer__3JUBi {
  order: 1;
  width: 100%;
  overflow: hidden
}

@media (min-width: 768px) {

.InboxPageDesktop_listContainer__3JUBi {
    flex: 0 0 25%;
    overflow: auto;
    height: auto
}
  }

.InboxPageDesktop_messagesContainer__TMlm3 {
  order: 3
}

@media (min-width: 768px) {

.InboxPageDesktop_messagesContainer__TMlm3 {
    order: 2;

    flex: 1 1;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e2e8f0;
    border-left: 1px solid #e2e8f0;
    padding: 0
}
  }

.InboxPageDesktop_messagesOnlyContainerLoader__duY1T {
  padding: 1rem;
  padding: 1rem;
  background-color: #fff;
  background-color: #fff
}

@media (min-width: 768px) {

.InboxPageDesktop_messagesOnlyContainerLoader__duY1T {
    order: 2;

    flex: 1 1;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e2e8f0;
    border-left: 1px solid #e2e8f0;
    padding: 0
}
  }

.InboxPageDesktop_messagesContent__3dvGS {
  overflow: auto;
  flex-grow: 2;
}

.InboxPageDesktop_sendMessageContent__34Tza {
  padding: 0 1.5rem;
  padding: 0 1.5rem;
}

.InboxPageDesktop_messagesHeader__3MDta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e2e8f0;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700;
  padding: 2rem 0 0.5rem 1.5rem;
  padding: 2rem 0 0.5rem 1.5rem;
}

.InboxPageDesktop_detailsContainer__3y_j4 {
  display: flex;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem
}

@media (min-width: 768px) {

.InboxPageDesktop_detailsContainer__3y_j4 {
    margin-bottom: 0
}
  }

.InboxPageDesktop_actionContainer__7LOq7 {
  order: 2
}

@media (min-width: 768px) {

.InboxPageDesktop_actionContainer__7LOq7 {
    order: 3;

    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    border: 1px solid #e2e8f0;
    border: 1px solid #e2e8f0;
    box-shadow: 0 20px 32px -24px rgba(15, 15, 15, 0.12);
    height: calc(100% - 1.75rem);
    height: calc(100% - 1.75rem);
    overflow: auto;
    margin: 1.75rem 1.5rem 0 1.5rem;
    margin: 1.75rem 1.5rem 0 1.5rem
}
  }

.InboxPageDesktop_emptyStateContainer__2AJye {
  order: 2;
  flex: 1 1;
  border-left: 1px solid #e2e8f0;
  border-left: 1px solid #e2e8f0;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.LandingPage_section__I-Mo_ {
  margin-bottom: 3rem;
  margin-bottom: 3rem;
  padding: 0 1rem;
  padding: 0 1rem
}

.LandingPage_section__I-Mo_:first-child {
    margin-top: 3rem;
    margin-top: 3rem;
  }

@media (min-width: 768px) {

.LandingPage_section__I-Mo_ {
    padding: 0
}
  }

.LandingPage_sectionFullWidth__20E6q {
  padding: 0;
}

.LandingPage_howItWorksSection__3dUv3 {
  margin-bottom: 0;
}

.LandingPage_listYourPropertySection__2pDiP {
  overflow-x: hidden;
  padding-bottom: 3rem;
  padding-bottom: 3rem
}

@media (min-width: 768px) {

.LandingPage_listYourPropertySection__2pDiP {
    overflow: visible;
    padding-bottom: 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.StateCard_root__3m7Gg {
  position: relative;
  border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  transition: ease-in 0.2s;
  transition: ease-in 0.2s
}

.StateCard_root__3m7Gg:hover {
    text-decoration: none;
    transform: scale(1.02);
  }

.StateCard_image__3OSeQ {
  max-width: 100%;
  object-fit: contain;
}

.StateCard_imageOverlay__3OiYk {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(26, 32, 44, 0.6));
}

.StateCard_title__3Kt10 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  color: #fff;
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 600;
  font-weight: 600;
  padding: 1rem;
  padding: 1rem;
  z-index: 10;
  z-index: 10
}

@media (min-width: 768px) {

.StateCard_title__3Kt10 {
    padding: 1.5rem;
    padding: 1.5rem;
    font-size: 1.5rem;
    font-size: 1.5rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionPopularStates_root__2IgAr {
}

.SectionPopularStates_title__2NqwW {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.SectionPopularStates_states__69fdc {
  display: grid;
  grid-gap: 1rem;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 50%));
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content)
}

@media (min-width: 768px) {

.SectionPopularStates_states__69fdc {
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(4, minmax(0, 25%))
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionFavoriteListings_header__37olO {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.SectionFavoriteListings_showAllLink__1zw7k {
  font-weight: 600;
  font-weight: 600;
  display: none
}

@media (min-width: 768px) {

.SectionFavoriteListings_showAllLink__1zw7k {
    display: block
}
  }

.SectionFavoriteListings_carousel__3HaY5 {
  padding: 1rem 0;
  padding: 1rem 0;
  margin: 0 calc(-1 * 0.5rem);
  margin: 0 calc(-1 * 0.5rem);
}

.SectionFavoriteListings_step__27Fce {
  padding: 0.5rem;
  padding: 0.5rem
}

@media (min-width: 768px) {

.SectionFavoriteListings_step__27Fce {
    flex-basis: 25%;
    flex-basis: 25%
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.LandownerBanner_root__2K4_J {
  position: relative;
  background: #233933;
  background: #233933;
  padding: 1rem 1.25rem;
  padding: 1rem 1.25rem;
  color: #fff;
  color: #fff;
  line-height: 1.25;
  line-height: 1.25
}

@media (min-width: 768px) {

.LandownerBanner_root__2K4_J {
    display: none
}
  }

.LandownerBanner_cta__3GzGW {
  color: #fff;
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

.LandownerBanner_close__3WdZp {
  position: absolute;
  top: 1rem;
  top: 1rem;
  right: 1rem;
  right: 1rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.LandownerReferralPage_heading__1L8xO {
  margin: 5px 0 18px 0
}

@media (min-width: 768px) {

.LandownerReferralPage_heading__1L8xO {
    margin: 8px 0 24px 0
}
  }

@media (min-width: 1024px) {

.LandownerReferralPage_content__2yoJe {
    max-width: 563px
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ActivityFeed_sectionContainer__3JL3K {
  position: relative;
  padding: 0 1.5rem 0 1.5rem;
  padding: 0 1.5rem 0 1.5rem;
  border-radius: 10px;
  border-radius: 10px;
  margin-top: 0;
  margin-bottom: 2.5rem;
  margin-bottom: 2.5rem
}

@media (min-width: 1024px) {

.ActivityFeed_sectionContainer__3JL3K {
    margin-bottom: 2.5rem;
    margin-bottom: 2.5rem;
    padding: 3rem 3.75rem 3rem 0;
    padding: 3rem 3.75rem 3rem 0
}
  }

.ActivityFeed_breakoutContainer__1Rp6v {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1
}

@media (min-width: 1024px) {

.ActivityFeed_breakoutContainer__1Rp6v {
    border-radius: 10px;
    border-radius: 10px;
    left: -50vw
}
  }

.ActivityFeed_content__3V7ot {
  position: relative;
  padding-left: 0;
}

.ActivityFeed_visitHeading__2huLh {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0;
}

.ActivityFeed_descriptionHeader__2JoBY {
  position: relative;
  z-index: 2;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #1a202c;
  color: #1a202c;
  display: flex;
  margin: 0;
}

.ActivityFeed_subHeading__3hR3_ {
  position: relative;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  z-index: 2;
  font-size: 1rem;
  font-size: 1rem;
  color: #1a202c;
  color: #1a202c;
  display: flex;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  margin-left: 0;
}

.ActivityFeed_subHeadingEmptyState__38Cba {
  position: relative;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: bold;
  z-index: 2;
  font-size: 1rem;
  font-size: 1rem;
  color: #1a202c;
  color: #1a202c;
  display: flex;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
  margin-top: 0.25rem;
  margin-left: 0;
}

.ActivityFeed_subtitleEmptyState__jVOUv {
  position: relative;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  z-index: 2;
  font-size: 1rem;
  font-size: 1rem;
  color: #1a202c;
  color: #1a202c;
  display: flex;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  margin-left: 0;
}

.ActivityFeed_displayName__2g3Y0 {
  white-space: nowrap;
}

.ActivityFeed_bellWrapper__1R161 {
  position: absolute;
  bottom: 0;
  left: 2.25rem;
  left: 2.25rem;
  transform: translate(-28%, 0%);
  color: #fff;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ActivityFeed_bellStyles__3uk7k {
  width: 1.25rem;
  width: 1.25rem;
}

.ActivityFeed_showMoreButton__19CLs {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-size: 1rem;
  color: #1a202c;
  color: #1a202c;
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  z-index: 2;
  margin-left: 0;
}

.ActivityFeed_followButtonWrapper__1J4M4 {
  position: relative;
  z-index: 2;
  margin: 0;
}

.ActivityFeed_sectionDivider__3QdV1 {
  position: relative;
  height: 1px;
  border: none;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  z-index: 1;
}
:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.FollowButtonSection_followButtonStyles__1wVE0 {
  min-width: 13rem;
  min-width: 13rem;
  max-width: 16.25rem;
  max-width: 16.25rem;
  background-color: #fad44e;
  background-color: #fad44e;
  border: 1px solid #1a202c;
  border: 1px solid #1a202c;
  cursor: pointer;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
  padding: 0.5rem 0.75rem;
}

.FollowButtonSection_whiteFollowButtonStyles__39X0_ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 13rem;
  min-width: 13rem;
  max-width: 16.25rem;
  max-width: 16.25rem;
  background-color: #fff;
  background-color: #fff;
  color: #000;
  color: #000;
  cursor: pointer;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #1a202c;
  border: 1px solid #1a202c;
}

.FollowButtonSection_iconStyles__10wwT {
  width: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  height: 1.25rem;
}

.FollowButtonSection_tooltipIconStyles__1wFm_ {
  margin-right: 0.25rem;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  margin-left: 0.25rem;
  width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  height: 1.5rem;
}

.FollowButtonSection_arrowIconStyles__VFheb {
  margin-right: 0.25rem;
  margin-right: 0.25rem;
  margin-left: 0.75rem;
  margin-left: 0.75rem;
  width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  height: 1.5rem;
}

.FollowButtonSection_tooltipRoot__26yG_ {
  padding: 0.75rem 0.75rem 0.75rem 0.25rem;
  padding: 0.75rem 0.75rem 0.75rem 0.25rem;
}

.FollowButtonSection_tooltipButtonStyles__2t4of {
  margin-right: 0.25rem;
  margin-right: 0.25rem;
}

.FollowButtonSection_tooltipWrapperStyles__12mwe {
  min-width: 10rem;
  min-width: 10rem;
  font-size: 1.125rem;
  font-size: 1.125rem;
  padding-left: 0.5rem;
  padding-left: 0.5rem;
}

.FollowButtonSection_tooltipLineStyles__3Z78o {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
}

.FollowButtonSection_toolipContent__UD1PM {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji' !important;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji' !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ListingUpdate_content__1xtAu {
  position: relative;
  z-index: 20;
  z-index: 20;
  padding-left: 0;
}

.ListingUpdate_flexContainer__XZtti {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 1024px) {

.ListingUpdate_flexContainer__XZtti {
    align-items: center;
    margin-top: 1rem;
    margin-top: 1rem
}
  }

.ListingUpdate_descriptionStyles__1YS3o {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #1a202c;
  color: #1a202c;
  z-index: 2;
}

.ListingUpdate_avatarContainer__3iZ4Y {
  position: relative;
}

.ListingUpdate_postWrapper__2lMjX {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 1024px) {

.ListingUpdate_postWrapper__2lMjX {
    margin-bottom: 0.75rem;
    margin-bottom: 0.75rem
}
  }

.ListingUpdate_titleLink__3ztAj {
  text-decoration: underline;
}

.ListingUpdate_propertyNameContainer__2rroz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
  margin-top: 0.25rem
}

@media (min-width: 768px) {

.ListingUpdate_propertyNameContainer__2rroz {
    margin-top: 0.5rem;
    margin-top: 0.5rem
}
  }

.ListingUpdate_dateContainer__1ac-C {
  display: flex;
  flex-direction: column
}

@media (min-width: 1024px) {

.ListingUpdate_dateContainer__1ac-C {
    flex-direction: row
}
  }

.ListingUpdate_landownerContainer__2YR80 {
  font-size: 0.75rem;
  font-size: 0.75rem;
  color: #1a202c;
  color: #1a202c;
  margin: 0;
}

.ListingUpdate_propertyName__3-7us {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

.ListingUpdate_dateWrapper__3u4fc {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.ListingUpdate_customAvatarSize__1vd2E {
  width: 3rem;
  width: 3rem;
  height: 3rem;
  height: 3rem;
}

.ListingUpdate_gridPhotos__3Tqst {
  position: relative;
  height: calc(0.51 * 100vw);
  max-height: 50vh;
  z-index: 2;
  display: block;
  max-height: 22rem;
  max-height: 22rem;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem
}

@media (min-width: 768px) {

.ListingUpdate_gridPhotos__3Tqst {
    height: calc(0.41 * 100vw)
}
  }

.ListingUpdate_testing__3jqqr {
  position: relative;
  z-index: 200;
  z-index: 200;
}

.ListingUpdate_step__15E-1 {
  max-width: 685px;
}

.ListingUpdate_imageWrapper__1t_I_ {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  object-fit: cover;
  height: 100%;
  max-height: 18.75rem;
  max-height: 18.75rem;
  margin-bottom: 0;
  width: 100%
}

@media (min-width: 768px) {

.ListingUpdate_imageWrapper__1t_I_ {
    max-height: 28rem;
    max-height: 28rem
}
  }

.ListingUpdate_imageStyles__1a8Yr {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ListingUpdate_videoStyles__uUY9Q {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: black;
}

.ListingUpdate_collage__FnMuA {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  background-color: #fff;
  background-color: #fff;
}

.ListingUpdate_collage1Photo__2HDtT {
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.ListingUpdate_collage2Photos__24v5S {
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.ListingUpdate_collage3Photos__mj-p1 {
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.ListingUpdate_collage4Photos__1e3hj {
  grid-template-columns: minmax(0, 0.25fr) minmax(0, 0.25fr) minmax(0, 0.5fr);
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.ListingUpdate_collage4PhotosItem__Ozmxz:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

.ListingUpdate_collage4PhotosItem__Ozmxz:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }

.ListingUpdate_collage4PhotosItem__Ozmxz:nth-child(3) {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }

.ListingUpdate_collage4PhotosItem__Ozmxz:nth-child(4) {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

.ListingUpdate_collage5PlusPhotos__1XoWy {
  grid-template-columns: minmax(0, 0.5fr) minmax(0, 0.25fr) minmax(0, 0.25fr);
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.ListingUpdate_collage5PlusPhotosItem__2USlE:nth-child(1) {
    grid-column: 1;
    grid-row: 1 / 3;
  }

.ListingUpdate_collage5PlusPhotosItem__2USlE:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  }

.ListingUpdate_collage5PlusPhotosItem__2USlE:nth-child(3) {
    grid-column: 2;
    grid-row: 2;
  }

.ListingUpdate_collage5PlusPhotosItem__2USlE:nth-child(4) {
    position: relative;
    grid-column: 3;
    grid-row: 1;
  }

.ListingUpdate_collage5PlusPhotosItem__2USlE:nth-child(5) {
    position: relative;
    grid-column: 3;
    grid-row: 2;
  }

.ListingUpdate_collageImage__3mNw_ {
  width: 100%;
  height: 100%;
  background-color: var(--matterColorNegative);
  cursor: pointer;
  object-fit: cover;
  transition: var(--transitionStyleButton);
  border-radius: 0.75rem;
  border-radius: 0.75rem
}

.ListingUpdate_collageImage__3mNw_:hover {
    transform: scale(1.005);
    box-shadow: var(--boxShadowListingCard);
  }

.ListingUpdate_singleMedia__3ZYgo {
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ListingUpdate_singleVideo__2Kytr {
  object-fit: contain;
  width: 100%;
  height: 100%;
  background-color: black;
}

.ListingUpdate_viewImagesWrapper__2Gc7A {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListingUpdate_viewPhotosBlurWrapper__3BVQg {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListingUpdate_viewPhotosBlur__2GoOt {
  /* background: url('./rectangle.png'); */
  background-color: rgba(21, 21, 21, 0.6);
  filter: blur(43px);
  width: 12rem;
  width: 12rem;
  height: 4rem;
  height: 4rem;
  display: block;
}

.ListingUpdate_viewPhotos__3l2Db {
  font-size: 1.125rem;
  font-size: 1.125rem;
  width: 100%;
  transition: var(--transitionStyleButton);
  color: #fff;
  color: #fff;
  font-weight: 700;
  font-weight: 700;
  display: block;
  z-index: 10;
  z-index: 10
}

@media (min-width: 768px) {

.ListingUpdate_viewPhotos__3l2Db {
    font-size: 1.875rem;
    font-size: 1.875rem
}
  }

.ListingUpdate_viewPhotosText__3xl8S {
  line-height: 1;
  line-height: 1;
}

.ListingUpdate_sectionDivider__2wBdR {
  height: 1px;
  border: none;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
}

.ListingUpdate_carouselModalScrollLayer__1k84Y {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.ListingUpdate_carouselModalContainer__3S9_B {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  padding: 1.5rem;
}

.ListingUpdate_modalContentContainer__qresm {
  height: 100%;
}

.ListingUpdate_customCloseButton__2P_TR {
  position: absolute;
  top: 5rem;
  top: 5rem;
  right: 1.5rem;
  right: 1.5rem;
  width: 2rem;
  width: 2rem;
  height: 2rem;
  height: 2rem;
  padding: 0.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  line-height: 1;
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
  background-color: #fff;
  background-color: #fff;
  color: black;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1)
}

@media (min-width: 768px) {

.ListingUpdate_customCloseButton__2P_TR {
    top: 2.5rem;
    top: 2.5rem
}
  }

.ListingUpdate_imageModalWrapper__LSA3o {
  position: relative;
  z-index: 200;
  z-index: 200;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.OptionMenu_actionButtonWrapper__36f0I {
  display: flex;
  align-items: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-top: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem
}

@media (max-width: 359px) {

.OptionMenu_actionButtonWrapper__36f0I {
    grid-gap: 0.75rem;
    gap: 0.75rem;
    grid-gap: 0.75rem;
    gap: 0.75rem
}
  }

.OptionMenu_truncatedActionButtonWrapper__7s16g {
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  margin-top: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0
}

@media (min-width: 768px) {

.OptionMenu_truncatedActionButtonWrapper__7s16g {
    grid-gap: 1.25rem;
    gap: 1.25rem;
    grid-gap: 1.25rem;
    gap: 1.25rem
}
  }

.OptionMenu_actionStyles__uvHtB {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.75rem;
  font-size: 0.75rem;
  color: #1a202c;
  color: #1a202c;
  cursor: pointer
}

@media (max-width: 359px) {

.OptionMenu_actionStyles__uvHtB {
    font-size: 0.625rem;
    font-size: 0.625rem
}
  }

.OptionMenu_tooltipButtonStyles__2Cc4G {
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  margin-right: 0.25rem;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.OptionMenu_iconStyles__24RzZ {
  width: 100%;
  height: 100%;
}

.OptionMenu_toastStyles__2TaUS {
  position: relative;
  z-index: 100;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.InfoTooltip_tooltipHeader__pY9cd {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-size: 1rem;
  color: #1a202c;
  color: #1a202c;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.InfoTooltip_tooltipHeaderIcon__2xuKD {
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.InfoTooltip_tooltipContent__1n6OH {
  min-width: 13.5rem;
  min-width: 13.5rem;
  max-width: 16rem;
  max-width: 16rem
}

@media (min-width: 768px) {

.InfoTooltip_tooltipContent__1n6OH {
    min-width: 16rem;
    min-width: 16rem;
    max-width: 19rem;
    max-width: 19rem
}
  }

.InfoTooltip_tooltipLink__2BpCF {
  position: relative;
  bottom: 2px;
  left: 0.5rem;
  left: 0.5rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SEOBreadcrumbs_root__3NUUI {
  margin-top: 0;
  overflow: visible;
  margin-bottom: 0;
  margin-left: 1.5rem;
  margin-left: 1.5rem
}

@media (min-width: 768px) {

.SEOBreadcrumbs_root__3NUUI {
    margin-left: 0
}
  }

.SEOBreadcrumbs_breadcrumbs__3NrnX {
  color: #718096;
  color: #718096;
  font-weight: normal;
}
:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ShareButton_root__1Qkmd {
  position: relative;
}

.ShareButton_buttonBody__1doVA {
  display: flex;
  align-items: center;
  justify-content: space-evenly
}

@media (min-width: 768px) {

.ShareButton_buttonBody__1doVA > svg {
      margin-right: 0.75rem;
      margin-right: 0.75rem
  }
    }

.ShareButton_buttonLabel__1VzgR {
  display: none
}

@media (min-width: 768px) {

.ShareButton_buttonLabel__1VzgR {
    display: block
}
  }

.ShareButton_header__3kwCY {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  padding-right: 2.5rem;
  padding-right: 2.5rem;
}

.ShareButton_icon__3X_yz {
  width: 1.25rem;
  width: 1.25rem;
  height: 1.5rem;
  height: 1.5rem
}

@media (min-width: 768px) {

.ShareButton_icon__3X_yz {
    width: 1rem;
    width: 1rem;
    height: 1.25rem;
    height: 1.25rem
}
  }

.ShareButton_listItem__3bXm7 {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.ShareButton_link__GqJq_ {
  position: relative;
  color: #1a202c;
  color: #1a202c;
  display: flex;
  align-items: center
}

.ShareButton_link__GqJq_:hover {
    color: #718096;
    color: #718096;
    text-decoration: none;
  }

.ShareButton_linkLabel__1lEHi {
  margin-left: 0.75rem;
  margin-left: 0.75rem;
}

.ShareButton_linkIcon__GgwdF {
  width: 1.75rem;
  width: 1.75rem;
}

.ShareButton_copyLink__6d6QG {
  position: absolute;
  top: 0;
  left: 2.25rem;
  left: 2.25rem;
  right: 0;
  opacity: 0;
  transition-delay: 0.25s;
  transition: none;
}

.ShareButton_copiedLink__bNG9t {
  position: absolute;
  top: 0;
  left: 2.25rem;
  left: 2.25rem;
  right: 0;
  opacity: 1;
  transition-delay: 0;
  transition: opacity 0.25s ease-in-out;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionKeyListingInfo_keyListingInfoContainer__2WhA5 {
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed
}

@media (min-width: 768px) {

.SectionKeyListingInfo_keyListingInfoContainer__2WhA5 {
    display: flex;
    margin-left: 0
}
  }

.SectionKeyListingInfo_keyInfoFirstRow__3YB7X {
  display: flex;
  margin-right: 1.25rem;
  margin-right: 1.25rem;
  flex-wrap: wrap;
  justify-content: flex-start
}

@media (min-width: 768px) {

.SectionKeyListingInfo_keyInfoFirstRow__3YB7X {
}
  }

.SectionKeyListingInfo_keyInfoItem__3LSc6 {
  display: flex;
  color: #1a202c;
  color: #1a202c;
  margin: 0 1rem 0.25rem 0;
  margin: 0 1rem 0.25rem 0;
  font-size: 0.875rem;
  font-size: 0.875rem
}

@media (min-width: 768px) {

.SectionKeyListingInfo_keyInfoItem__3LSc6 {
    font-size: 1rem;
    font-size: 1rem;
    display: flex;
    justify-content: flex-start
}
  }

.SectionKeyListingInfo_keyInfoIcon__2M_c6 {
  color: #1a202c;
  color: #1a202c;
  margin-right: 0.25rem;
  margin-right: 0.25rem;
}

.SectionKeyListingInfo_iconWrapper__3fB3S {
  flex-grow: 0;
  flex-shrink: 0;
}

.SectionKeyListingInfo_keyInfoTextContainer__7DfyO {
  flex-grow: 1;
  padding-left: 0;
  padding-top: 2px;
}

.SectionKeyListingInfo_descriptorTitle__3uZcC {
  font-weight: 600;
  font-weight: 600;
  display: inline;
}

.SectionKeyListingInfo_nearestTown__1_s99 {
  color: #000;
  color: #000;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.ManageListingsPage_root__2EsH_ {
}

.ManageListingsPage_tabs__2FUkp {
  display: none
}

@media (min-width: 768px) {

.ManageListingsPage_tabs__2FUkp {
    display: flex;
    justify-content: flex-end;
    height: 55px;
    align-items: flex-end;
    padding: 13px 24px 0 24px
}
  }

@media (min-width: 1024px) {

.ManageListingsPage_tabs__2FUkp {
    padding: 0 36px
}
  }

.ManageListingsPage_tab__3lTUe {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px
}

.ManageListingsPage_tab__3lTUe:first-child {
    margin-left: 0;
  }

@media (min-width: 1024px) {

.ManageListingsPage_tab__3lTUe {
    margin-left: 24px
}
  }

.ManageListingsPage_listingPanel__2LQAj {
  width: 100%;
  margin: 1.5rem auto 6rem auto;
  margin: 1.5rem auto 6rem auto
}

@media (min-width: 768px) {

.ManageListingsPage_listingPanel__2LQAj {
    margin-top: 3rem;
    margin-top: 3rem
}
  }

@media (min-width: 1024px) {

.ManageListingsPage_listingPanel__2LQAj {
    margin-top: 80px;
    max-width: 62.5vw
}
  }

.ManageListingsPage_title__11ddk {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.25rem;
  font-size: 1.25rem;

  margin: 0 24px 24px 24px
}

@media (min-width: 768px) {

.ManageListingsPage_title__11ddk {
    font-size: 1.5rem;
    font-size: 1.5rem;
    margin: 0 24px 48px 24px
}
  }

@media (min-width: 1024px) {

.ManageListingsPage_title__11ddk {
    margin: 0 36px 48px 36px
}
  }

.ManageListingsPage_listingCards__2gjk5 {
  padding: 0 1.5rem 1.5rem 1.5rem;
  padding: 0 1.5rem 1.5rem 1.5rem
}

@media (min-width: 768px) {

.ManageListingsPage_listingCards__2gjk5 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap
}
  }

@media (min-width: 1024px) {

.ManageListingsPage_listingCards__2gjk5 {
    padding: 0 2.25rem 2.25rem 2.25rem;
    padding: 0 2.25rem 2.25rem 2.25rem
}
  }

.ManageListingsPage_listingCard__15_0S {
  margin-bottom: 36px;
  flex-basis: 100%
}

@media (min-width: 768px) {

.ManageListingsPage_listingCard__15_0S {
    margin-right: 0.75rem;
    margin-right: 0.75rem
}
  }

@media (min-width: 1024px) {

.ManageListingsPage_listingCard__15_0S {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%
     *
     * resultPanel: 62.5vw from 1920px = 1200px
     * panelPaddings: - 2*36px = 72px
     *
     * columnCount: 3
     * guttersBetweenColumns: 2*24px = 48px
     *
     * (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
     * ergo => listingCardWidth: 360px
     *
     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */
    flex-basis: calc(33.33% - 16px);
    margin-right: 1.5rem;
    margin-right: 1.5rem
}
  }

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

@media (min-width: 1024px) {

.ManageListingsPage_listingCard__15_0S:nth-of-type(3n) {
    margin-right: 0
}
  }

.ManageListingsPage_pagination__2knei {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin: auto 24px 0 24px
}

@media (min-width: 1024px) {

.ManageListingsPage_pagination__2knei {
    margin: auto 36px 0 36px
}
  }

.ManageListingsPage_addListingButton__3deZn {
  border: dashed 2px #3e704b;
  border: dashed 2px #3e704b;
  width: 100%;
  margin: 1.75rem auto;
  margin: 1.75rem auto;
  padding: 1.5rem 6.25rem 1.5rem;
  padding: 1.5rem 6.25rem 1.5rem
}

@media (min-width: 1024px) {

.ManageListingsPage_addListingButton__3deZn {
    padding: 6rem 3.5rem;
    padding: 6rem 3.5rem;
    margin: 0
}
  }

.ManageListingsPage_buttonFlex__3h-qX {
  display: flex;
  align-items: center;
  justify-content: center
}

@media (min-width: 1024px) {

.ManageListingsPage_buttonFlex__3h-qX {
    flex-direction: column
}
  }

.ManageListingsPage_addListing__1jquA {
  display: flex;
}

.ManageListingsPage_plusCircle__2vvAV {
  position: absolute;
  left: 3rem;
  left: 3rem;
  width: 2.75rem;
  width: 2.75rem;
  height: 2.75rem;
  height: 2.75rem
}

@media (min-width: 1024px) {

.ManageListingsPage_plusCircle__2vvAV {
    position: inherit;
    margin-bottom: 0.75rem;
    margin-bottom: 0.75rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.MobileTermsOfServicePage_heading__nNbyj {
  margin: 5px 0 18px 0
}

@media (min-width: 768px) {

.MobileTermsOfServicePage_heading__nNbyj {
    margin: 8px 0 24px 0
}
  }

@media (min-width: 1024px) {

.MobileTermsOfServicePage_content__1Ycsl {
    max-width: 563px
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.NotFoundPage_root__N9q2B {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.NotFoundPage_content__3_G1o {
  text-align: center;
  padding: 5rem 0;
  padding: 5rem 0;
}

.NotFoundPage_notFoundImage__1pZ8R {
  height: auto;
  max-width: 11rem;
  max-width: 11rem
}

@media (min-width: 768px) {

.NotFoundPage_notFoundImage__1pZ8R {
    max-width: 21rem;
    max-width: 21rem
}
  }

.NotFoundPage_heading__4AWHS {
  margin: auto;
  margin-top: 2rem;
  margin-top: 2rem;
  max-width: 66.666667%;
  max-width: 66.666667%;
  font-size: 1.5rem;
  font-size: 1.5rem
}

@media (min-width: 768px) {

.NotFoundPage_heading__4AWHS {
    max-width: 100%;
    font-size: 3rem;
    font-size: 3rem
}
  }

.NotFoundPage_description__2XpPP {
  margin: auto;
  margin-top: 2rem;
  margin-top: 2rem;
  max-width: 75%;
  max-width: 75%
}

@media (min-width: 768px) {

.NotFoundPage_description__2XpPP {
    max-width: 66.666667%;
    max-width: 66.666667%;
    font-size: 1.5rem;
    font-size: 1.5rem
}
  }

.NotFoundPage_actions__1QaIU {
  margin: auto;
  margin-top: 2rem;
  margin-top: 2rem;
  max-width: 66.666667%;
  max-width: 66.666667%
}

@media (min-width: 768px) {

.NotFoundPage_actions__1QaIU {
    display: flex;
    align-items: center;
    justify-content: center
}
  }

.NotFoundPage_action__2EyeC {
  width: 100%;
  margin: 0.5rem;
  margin: 0.5rem;
}

.NotFoundPage_actionPrimary__19LLv {
  background-color: #233933;
  background-color: #233933;
  border-color: #233933;
  border-color: #233933
}

.NotFoundPage_actionPrimary__19LLv:hover {
    background-color: #233933;
    background-color: #233933;
    border-color: #233933;
    border-color: #233933;
  }

.NotFoundPage_actionSecondary__1wFza {
  border-color: #233933;
  border-color: #233933;
  color: #233933;
  color: #233933
}

.NotFoundPage_actionSecondary__1wFza:hover {
    background-color: #233933;
    background-color: #233933;
    border-color: #233933;
    border-color: #233933;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.PasswordRecoveryPage_root__1vLwz {
    /* Page is using flex: AuthenticationPage's .root takes all available space */
  flex-grow: 1;

    /* AuthenticationPage's root uses flexbox */
  display: flex
}

@media (min-width: 768px) {

.PasswordRecoveryPage_root__1vLwz {
  justify-content: center;
  align-items: flex-start
}
    }

@media (min-width: 768px) {

.PasswordRecoveryPage_root__1vLwz {
    /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
      url(/static/media/landowner-header.97202e7b.jpg);

    /* Add loading color for the div */
  background-color: #4a5569;
  background-color: #4a5569;

    /* Cover the whole screen with the background image */
  background-size: cover;

    /* Align the image within the container */
  background-position: 40% 30%
}
  }

@media (min-width: 768px) and (min-width: 768px) {

.PasswordRecoveryPage_root__1vLwz {
  background-position: center center
}
    }

.PasswordRecoveryPage_layoutWrapperMain__3-_q2 {
  min-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px)
}

@media (min-width: 768px) {

.PasswordRecoveryPage_layoutWrapperMain__3-_q2 {
    min-height: calc(100vh - 72px);
    min-height: calc(100vh - 72px)
}
  }

.PasswordRecoveryPage_submitEmailContent__16JO4,
.PasswordRecoveryPage_genericErrorContent__1ixA5,
.PasswordRecoveryPage_emailSubmittedContent__sYJbx,
.PasswordRecoveryPage_emailNotVerifiedContent__3MUTc {
  flex-grow: 1;

    /* Create context so the close button can be positioned within it */
  position: relative;

    /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: 24px 24px 48px 24px;
  padding: 24px 24px 48px 24px;

  background-color: #ffffff;

  background-color: #ffffff;
  border-radius: 2px;
  border-radius: 2px;
  border-bottom: none;
}

@media (min-width: 768px) {

.PasswordRecoveryPage_submitEmailContent__16JO4,
.PasswordRecoveryPage_genericErrorContent__1ixA5,
.PasswordRecoveryPage_emailSubmittedContent__sYJbx,
.PasswordRecoveryPage_emailNotVerifiedContent__3MUTc {
  flex-basis: 480px;
  flex-grow: 0;
      /* min-height: 568px; */
  padding: 55px 60px 55px 60px;
  padding: 55px 60px 55px 60px;
  margin-top: 12.5vh;
  margin-bottom: 12.5vh;
  border-bottom: 8px solid #3e704b;
  border-bottom: 8px solid #3e704b;
}
    }

.PasswordRecoveryPage_bottomWrapper__2KHGP {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;
}

@media (min-width: 768px) {

.PasswordRecoveryPage_bottomWrapper__2KHGP {
  margin-top: 96px;
}
    }

.PasswordRecoveryPage_bottomWrapper__2KHGP {

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.PasswordRecoveryPage_modalIcon__2ejcI {
  height: 48px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.PasswordRecoveryPage_modalIcon__2ejcI {
  height: 64px;
  margin-bottom: 23px;
}
    }

/* ================ Typography ================ */

/* Title of the modal */

.PasswordRecoveryPage_modalTitle__13VK3 {
  font-weight: 600;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  font-weight: 700;
  font-weight: 700;
}

@media (min-width: 768px) {

.PasswordRecoveryPage_modalTitle__13VK3 {
  font-weight: 600;
  font-weight: 600;
  line-height: 40px;
  margin: 0;
}
    }

/* Paragraph for the Modal */

.PasswordRecoveryPage_modalMessage__2e2Og {
  margin: 18px 0 0 0;
}

@media (min-width: 768px) {

.PasswordRecoveryPage_modalMessage__2e2Og {
  margin: 24px 0 0 0;
}
    }

/* Make the email pop */

.PasswordRecoveryPage_email__2l-J8 {
  font-weight: 700;
  font-weight: 700;
}

.PasswordRecoveryPage_helperLink__2HKNH {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: #1a202c;
  color: #1a202c;

    /* Text size should be inherited */
  text-decoration: none;
}

.PasswordRecoveryPage_helperLink__2HKNH:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.PasswordRecoveryPage_helperLink__2HKNH {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.PasswordRecoveryPage_helperLink__2HKNH {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.PasswordRecoveryPage_helperLink__2HKNH {
  color: #4a5569;
  color: #4a5569;
  margin: 0;
}

@media (min-width: 768px) {

.PasswordRecoveryPage_helperLink__2HKNH {
  margin: 0;
}
    }

.PasswordRecoveryPage_helperText__IU0Tk {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.PasswordRecoveryPage_helperText__IU0Tk {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.PasswordRecoveryPage_helperText__IU0Tk {
  color: #a1aec1;
  color: #a1aec1;
  margin: 0;
}

@media (min-width: 768px) {

.PasswordRecoveryPage_helperText__IU0Tk {
  margin: 0;
}
    }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.PasswordResetPage_layoutWrapperMain__1x6J4 {
  min-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px)
}

@media (min-width: 768px) {

.PasswordResetPage_layoutWrapperMain__1x6J4 {
    min-height: calc(100vh - 72px);
    min-height: calc(100vh - 72px)
}
  }

.PasswordResetPage_root__O2rU- {
    /* Page is using flex: AuthenticationPage's .root takes all available space */
  flex-grow: 1;

    /* AuthenticationPage's root uses flexbox */
  display: flex
}

@media (min-width: 768px) {

.PasswordResetPage_root__O2rU- {
  justify-content: center;
  align-items: flex-start
}
    }

@media (min-width: 768px) {

.PasswordResetPage_root__O2rU- {
    /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
      url(/static/media/landowner-header.97202e7b.jpg);

    /* Add loading color for the div */
  background-color: #4a5569;
  background-color: #4a5569;

    /* Cover the whole screen with the background image */
  background-size: cover;

    /* Align the image within the container */
  background-position: 40% 30%
}
  }

@media (min-width: 768px) and (min-width: 768px) {

.PasswordResetPage_root__O2rU- {
  background-position: center center
}
    }

.PasswordResetPage_content__Jq_sv {
  flex-grow: 1;

    /* Create context so the close button can be positioned within it */
  position: relative;

    /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: 24px 24px 48px 24px;
  padding: 24px 24px 48px 24px;

  background-color: #ffffff;

  background-color: #ffffff;
  border-radius: 2px;
  border-radius: 2px;
  border-bottom: none;
}

@media (min-width: 768px) {

.PasswordResetPage_content__Jq_sv {
  flex-basis: 480px;
  flex-grow: 0;
      /* min-height: 568px; */
  padding: 55px 60px 55px 60px;
  padding: 55px 60px 55px 60px;
  margin-top: 12.5vh;
  margin-bottom: 12.5vh;
  border-bottom: 8px solid #3e704b;
  border-bottom: 8px solid #3e704b;
}
    }

.PasswordResetPage_form__3AFz5 {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  height: 100%;
}

.PasswordResetPage_error__15W9s {
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  font-size: 1rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.PasswordResetPage_error__15W9s {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.PasswordResetPage_error__15W9s {
  margin-top: 24px;
  color: #ff0000;
  color: #ff0000;
}

/* ================ Typography ================ */

/* Title of the modal */

.PasswordResetPage_modalTitle__2kwoh {
  font-weight: 600;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  font-weight: 700;
  font-weight: 700;
}

@media (min-width: 768px) {

.PasswordResetPage_modalTitle__2kwoh {
  font-weight: 600;
  font-weight: 600;
  line-height: 40px;
  margin: 0;
}
    }

/* Paragraph for the Modal */

.PasswordResetPage_modalMessage__JXmhg {
  margin: 18px 0 0 0;
}

@media (min-width: 768px) {

.PasswordResetPage_modalMessage__JXmhg {
  margin: 24px 0 0 0;
}
    }

/* Make the email pop */

.PasswordResetPage_email__2ZWP_ {
  font-weight: 700;
  font-weight: 700;
}

.PasswordResetPage_helperLink__28G3E {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.PasswordResetPage_helperLink__28G3E {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.PasswordResetPage_helperLink__28G3E {
  color: #4a5569;
  color: #4a5569;
  margin: 0;
}

@media (min-width: 768px) {

.PasswordResetPage_helperLink__28G3E {
  margin: 0;
}
    }

.PasswordResetPage_helperText__2BUJy {
  font-size: 0.875rem;
  font-size: 0.875rem;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.PasswordResetPage_helperText__2BUJy {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.PasswordResetPage_helperText__2BUJy {
  color: #a1aec1;
  color: #a1aec1;
  margin: 0;
}

@media (min-width: 768px) {

.PasswordResetPage_helperText__2BUJy {
  margin: 0;
}
    }

.PasswordResetPage_submitButton__2aFP2 {
  font-weight: 400;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.PasswordResetPage_submitButton__2aFP2 {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.PasswordResetPage_submitButton__2aFP2 {

    /* Dimensions */
  display: block;
  width: 100%;
  min-height: 59px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 16px 0 0 0;

    /* Borders */
  border-style: solid;
  border-color: #3e704b;
  border-color: #3e704b;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #3e704b;
  color: #3e704b;

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
}

.PasswordResetPage_submitButton__2aFP2:hover,
    .PasswordResetPage_submitButton__2aFP2:focus {
  text-decoration: none;
    }

@media (min-width: 768px) {

.PasswordResetPage_submitButton__2aFP2 {
  min-height: 60px;
}
    }

.PasswordResetPage_submitButton__2aFP2 {

  background-color: #3e704b;

  background-color: #3e704b;
  color: #fff;
  color: #fff;
  border-color: #3e704b;
  border-color: #3e704b;
}

.PasswordResetPage_submitButton__2aFP2:hover,
    .PasswordResetPage_submitButton__2aFP2:focus {
  background-color: #2a5e38;
  background-color: #2a5e38;
  border-color: #2a5e38;
  border-color: #2a5e38;
  color: #fff;
  color: #fff;
    }

.PasswordResetPage_submitButton__2aFP2:disabled {
  background-color: #a1aec1;
  background-color: #a1aec1;
  color: #ffffff;
  color: #ffffff;
    }

.PasswordResetPage_submitButton__2aFP2 {
  margin-top: 24px;
}

.PasswordResetPage_modalIcon__nZ9Sg {
  height: 48px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.PasswordResetPage_modalIcon__nZ9Sg {
  height: 64px;
  margin-bottom: 23px;
}
    }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PackageNotFoundPage_root__1gz67 {
  padding-bottom: 10vh
}

@media (min-width: 768px) {

.PackageNotFoundPage_root__1gz67 {
    flex-direction: row;
    height: 90vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10vh
}

  }

.PackageNotFoundPage_textContainer__3nAfR {
  height: 70vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex
}

@media (min-width: 768px) {

.PackageNotFoundPage_textContainer__3nAfR {
    display: flex;
    flex: 1 1;
    align-items: flex-start;
    margin-left: 11rem;
    margin-left: 11rem;
    margin-right: 1.25rem;
    margin-right: 1.25rem;
    max-width: var(--s-130)
}
  }

.PackageNotFoundPage_notFoundImage__1plzC {
  height: auto;
  max-width: 11rem;
  max-width: 11rem
}

@media (min-width: 768px) {

.PackageNotFoundPage_notFoundImage__1plzC {
    max-width: 25.75rem;
    max-width: 25.75rem
}
  }

.PackageNotFoundPage_imageContainer__eFyIW {
  display: none
}

@media (min-width: 768px) {

.PackageNotFoundPage_imageContainer__eFyIW {
    display: flex;
    flex: 1 1;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center
}
  }

.PackageNotFoundPage_heading__2juMa,
.PackageNotFoundPage_headingBold__2zC_D,
.PackageNotFoundPage_description__MUvmN {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}

.PackageNotFoundPage_heading__2juMa,
.PackageNotFoundPage_headingBold__2zC_D {
  line-height: 1.1;
}

.PackageNotFoundPage_heading__2juMa {
  font-weight: normal;
  text-align: left;
  font-size: 2rem;
  font-size: 2rem
}

@media (min-width: 768px) {

.PackageNotFoundPage_heading__2juMa {
    max-width: 100%;
    font-size: 3.25rem;
    font-size: 3.25rem
}
  }

.PackageNotFoundPage_headingBold__2zC_D {
  font-weight: bold;
  font-style: italic;
  text-align: left;
  font-size: 2.25rem;
  font-size: 2.25rem
}

@media (min-width: 768px) {

.PackageNotFoundPage_headingBold__2zC_D {
    max-width: 100%;
    font-size: 3.75rem;
    font-size: 3.75rem
}
  }

.PackageNotFoundPage_description__MUvmN {
  margin-top: 2rem;
  margin-top: 2rem;
  font-weight: normal;
  text-align: left;
  font-size: 0.75rem;
  font-size: 0.75rem;
  max-width: 20rem;
  max-width: 20rem
}

@media (min-width: 768px) {

.PackageNotFoundPage_description__MUvmN {
    max-width: 100%;
    font-size: 1.25rem;
    font-size: 1.25rem
}
  }

.PackageNotFoundPage_actions__3piHK {
  margin-top: 2rem;
  margin-top: 2rem
}

@media (min-width: 768px) {

.PackageNotFoundPage_actions__3piHK {
    display: flex;
    justify-content: left
}
  }

.PackageNotFoundPage_actionPrimary__2CQHB {
  padding-left: 2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-right: 2.5rem;
  background-color: #3e704b;
  background-color: #3e704b;
  border-color: #3e704b;
  border-color: #3e704b
}

.PackageNotFoundPage_actionPrimary__2CQHB:hover {
    background-color: #3e704b;
    background-color: #3e704b;
    border-color: #3e704b;
    border-color: #3e704b;
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PricingPage_topbar__32WBe {
  width: 100%;
  height: 4.5rem;
  height: 4.5rem;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center
}

@media (min-width: 768px) {

.PricingPage_topbar__32WBe {
    justify-content: left;
    padding: 0 4.25rem;
    padding: 0 4.25rem
}
  }

.PricingPage_logoMobile__3F-6P {
  display: block
}

@media (min-width: 768px) {

.PricingPage_logoMobile__3F-6P {
    display: none
}
  }

.PricingPage_logoDesktop__3sEXN {
  display: none
}

@media (min-width: 768px) {

.PricingPage_logoDesktop__3sEXN {
    display: block
}
  }

.PricingPage_contentContainer__2HzTn {
  display: flex;
  flex-direction: column;
  margin-bottom: 4.5rem;
  margin-bottom: 4.5rem;
  padding: 1.25rem;
  padding: 1.25rem
}

@media (min-width: 768px) {

.PricingPage_contentContainer__2HzTn {
    width: 100%;
    max-width: 80rem;
    max-width: 80rem;
    flex-direction: row;
    margin: 2.75rem auto 1.75rem auto;
    margin: 2.75rem auto 1.75rem auto
}
  }

.PricingPage_aspectWrapper__1n4NZ {
  position: relative;
  padding-bottom: 66.666667%;
  padding-bottom: 66.666667%;
  background-color: #a1aec1;
  background-color: #a1aec1
}

@media (min-width: 768px) {

.PricingPage_aspectWrapper__1n4NZ {
    display: none
}
  }

.PricingPage_detailsAspectWrapper__2XUiD {
  display: none
}

@media (min-width: 768px) {

.PricingPage_detailsAspectWrapper__2XUiD {
    display: block;
    position: relative;
    padding-bottom: 66.666667%;
    padding-bottom: 66.666667%;
    background-color: #a1aec1;
    background-color: #a1aec1;
    border-radius: 10px;
    border-radius: 10px;
    overflow: hidden
}
  }

.PricingPage_rootForImage__UUq6k {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%
}

@media (min-width: 768px) {

.PricingPage_rootForImage__UUq6k {
    border-radius: 2px 2px 0 0
}
  }

.PricingPage_avatarWrapper__3UPje {
  margin-left: 1.5rem;
  margin-left: 1.5rem;
  margin-top: calc(-1 * 2rem);
  margin-top: calc(-1 * 2rem);
  position: relative;
  display: block
}

@media (min-width: 768px) {

.PricingPage_avatarWrapper__3UPje {
    margin-left: 0.75rem;
    margin-left: 0.75rem
}
  }

@media (min-width: 768px) {

.PricingPage_avatarMobile__2xi5V {
    display: none
}
  }

.PricingPage_avatarDesktop__2DmcO {
  display: none
}

@media (min-width: 768px) {

.PricingPage_avatarDesktop__2DmcO {
    display: block
}
  }

.PricingPage_detailsWrapper__3s70a {
  display: flex;
  flex-direction: column;
  padding: 0
}

@media (min-width: 768px) {

.PricingPage_detailsWrapper__3s70a {
    flex-basis: 62%;
    padding: 1.25rem 8rem 1.25rem 1.25rem;
    padding: 1.25rem 8rem 1.25rem 1.25rem
}
  }

.PricingPage_title__3roBe {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  width: 100%;
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  color: #000;
  color: #000
}

@media (min-width: 768px) {

.PricingPage_title__3roBe {
    margin-top: 1.75rem;
    margin-top: 1.75rem;
    margin-bottom: 0.25rem;
    margin-bottom: 0.25rem
}
  }

.PricingPage_detailsHeadings__1HShA {
  display: none
}

@media (min-width: 768px) {

.PricingPage_detailsHeadings__1HShA {
    display: block;
    font-weight: 700;
    font-weight: 700;
    font-size: 1.5rem;
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    margin-bottom: 1.25rem
}
  }

.PricingPage_detailsTitle__20tlv {
  display: none
}

@media (min-width: 768px) {

.PricingPage_detailsTitle__20tlv {
    display: block;
    font-size: 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    font-weight: 700;
    margin-top: 1rem;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    margin-bottom: 0.25rem
}
  }

.PricingPage_detailsLandownerName__3tE8l {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: normal;
}

.PricingPage_breakdownWrapper__3zvOW {
  padding: 1.25rem;
  padding: 1.25rem
}

@media (min-width: 768px) {

.PricingPage_breakdownWrapper__3zvOW {
    padding-left: 2rem;
    padding-left: 2rem;
    border-left: 1px solid #e2e8f0;
    border-left: 1px solid #e2e8f0;
    flex-basis: 45%
}
  }

@media (min-width: 768px) {

.PricingPage_breakdown__1Zs9R {
    max-width: 23.25rem;
    max-width: 23.25rem
}
  }

.PricingPage_formWrapper__3LT10 {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}

.PricingPage_formFieldWrapper__ey6Ym {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem
}

@media (min-width: 768px) {

.PricingPage_formFieldWrapper__ey6Ym {
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

.PricingPage_formFieldHeading__3GxJX {
  font-size: 1rem;
  font-size: 1rem;
  margin: 0 0 0 0;
  color: var(--var-900);
  font-weight: 700;
  font-weight: 700;

  padding-top: 0.25rem;

  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-bottom: 0.25rem
}

@media (min-width: 768px) {

.PricingPage_formFieldHeading__3GxJX {
    font-size: 1.125rem;
    font-size: 1.125rem;
    margin: 0 0 0 0;
    padding-top: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-bottom: 0.25rem
}
  }

.PricingPage_formFieldSubtitle__ibvTH {
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.PricingPage_datesButton__3QZN6 {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  width: 100%;
  cursor: pointer;
  text-align: left;
  padding: 0;
  border-radius: 5px;
  border-radius: 5px;
  border: 2px solid #cbd5e0;
  border: 2px solid #cbd5e0;
  transition: all 0.2s ease-out;
  z-index: 50;
  z-index: 50;
  position: relative;
  cursor: pointer;
}

.PricingPage_datesButtonLabel__3xMUz {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 1rem 1rem 1rem 1rem;
  padding: 1rem 1rem 1rem 1rem;
}

.PricingPage_dateText__DblPd {
  margin-left: 1rem;
  margin-left: 1rem;
}

.PricingPage_iconArrow__1neLr {
  width: 16px;
  height: 14px;
  transition: all 0.2s ease-out;
}

.PricingPage_partySizeSelect__zMcBq {
  border: 2px solid #cbd5e0;
  border: 2px solid #cbd5e0;
  padding: 1rem 1.5rem 1rem 1rem;
  padding: 1rem 1.5rem 1rem 1rem;
}

.PricingPage_arrowIcon__35jzz {
  /* custom styling to match native select arrow */
  width: 1rem;
  width: 1rem;
  height: 17px;
  color: #4a4a4a;
}

.PricingPage_speciesCheckboxWrapper__fBiuo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.PricingPage_speciesCheckbox__GWwp5 {
}

.PricingPage_speciesFeeCheckboxLabel__1LstL {
  margin-bottom: 0;
}

.PricingPage_error__3GDjY {
  color: #ed3615;
  color: #ed3615;
}

.PricingPage_packageInfo__1UZuZ {
  margin-top: 1.25rem;
  margin-top: 1.25rem
}

@media (min-width: 768px) {

.PricingPage_packageInfo__1UZuZ {
    margin-top: 0
}
  }

.PricingPage_packageInfo__1UZuZ a {
    text-decoration: underline;
    color: #000;
    color: #000;
  }

.PricingPage_packageInfoHeading__2nC4- {
  font-weight: 700;
  font-weight: 700;
  font-size: 1.125rem;
  font-size: 1.125rem
}

@media (min-width: 768px) {

.PricingPage_packageInfoHeading__2nC4- {
    font-size: 1.5rem;
    font-size: 1.5rem
}
  }

.PricingPage_submitButtonWrapper__12K2r {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  padding: 1rem;
  background-color: #fff;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  z-index: 60;
  z-index: 60
}

@media (min-width: 768px) {

.PricingPage_submitButtonWrapper__12K2r {
    position: relative;
    padding: 0;
    background-color: transparent;
    box-shadow: none
}
  }

.PricingPage_dateInfo__1Pj_4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PricingPage_dateInfoTitle__1zzoB {
  color: #1a202c;
  color: #1a202c;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.PricingPage_dateInfoBody__3S7x4 {
  margin: 0;
  color: #1a202c;
  color: #1a202c;
  font-size: 0.75rem;
  font-size: 0.75rem;
}

.PricingPage_dateTooltip__1DAZ9 {
  padding: 0 1.5rem 1.5rem 1.5rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.PricingPage_packagePartySize__37dWR {
  font-size: 1rem;
  font-size: 1rem;
  margin: 0;
  color: var(--var-900);
  font-weight: 700;
  font-weight: 700;

  padding-top: 0.25rem;

  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-bottom: 0.25rem
}

@media (min-width: 768px) {

.PricingPage_packagePartySize__37dWR {
    margin: 0;
    padding-top: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-bottom: 0.25rem
}
  }

.PricingPage_lodgingContainer__13ArO {
  font-size: 1rem;
  font-size: 1rem;
  margin: 0;
  color: var(--var-900);
  font-weight: 700;
  font-weight: 700;

  padding-top: 0.25rem;

  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-bottom: 0.25rem
}

@media (min-width: 768px) {

.PricingPage_lodgingContainer__13ArO {
    margin: 0;
    padding-top: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-bottom: 0.25rem
}
  }

.PricingPage_lodging__3OwU6 {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 600;
  font-weight: 600;
  font-size: 1rem;
  font-size: 1rem;
  margin-top: 0.25rem;
  margin-top: 0.25rem;
}

.PricingPage_minPartyDisclaimer__3mBbw {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.PricingPage_minPartyDisclaimer__3mBbw {
    font-size: 0.75rem;
    font-size: 0.75rem
}
  }

.PricingPage_hasLodging__11m8i {
  color: #1a202c;
  color: #1a202c;
  font-size: 1.125rem;
  font-size: 1.125rem;
  line-height: 1.25;
  line-height: 1.25;
  padding-top: 0.5rem;
  padding-top: 0.5rem;
}

.PricingPage_fieldPartySize__3mZne {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.PricingPage_fieldPartySize__3mZne {
    width: 50%;
    width: 50%
}
  }

.PricingPage_infoWrapper__NrR_M {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1.25rem 1rem 1.25rem 0;
  margin: 1.25rem 1rem 1.25rem 0
}

@media (min-width: 768px) {

.PricingPage_infoWrapper__NrR_M {
    margin: 1.25rem 1rem 1.25rem 0;
    margin: 1.25rem 1rem 1.25rem 0
}
  }

.PricingPage_desktopBreadcrumbs__3SvG9 {
  display: none
}

@media (min-width: 768px) {

.PricingPage_desktopBreadcrumbs__3SvG9 {
    display: block
}
  }

@media (min-width: 768px) {

.PricingPage_mobileBreadcrumbs__9Woo6 {
    display: none
}
  }

.PricingPage_availableDates__Sx_vM {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem
}

@media (min-width: 768px) {

.PricingPage_availableDates__Sx_vM {
    margin-top: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0
}
  }

.PricingPage_verificationIcon__2m71B {
  margin-right: 0.75rem;
  margin-right: 0.75rem;
  min-width: 2rem;
  min-width: 2rem;
}

.PricingPage_packageBadges__67emg {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.PricingPage_divider__3nfSA {
  height: 1px;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  margin: 1rem 0;
  margin: 1rem 0
}

@media (min-width: 768px) {

.PricingPage_divider__3nfSA {
    display: none
}
  }

.PricingPage_packageIncludes__35FnD {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.PricingPage_packageIncludesHeading__1Kk_B {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.PrivacyPolicyPage_heading__2EcCg {
  margin: 5px 0 18px 0
}

@media (min-width: 768px) {

.PrivacyPolicyPage_heading__2EcCg {
    margin: 8px 0 24px 0
}
  }

@media (min-width: 1024px) {

.PrivacyPolicyPage_content__2CC6M {
    max-width: 563px
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.LayoutProfile_wrapper__wiKQb {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 2 * 1.25rem);
  width: calc(100% - 2 * 1.25rem);
  margin: 1.25rem;
  margin: 1.25rem
}

@media (min-width: 768px) {

.LayoutProfile_wrapper__wiKQb {
    flex-direction: row;
    margin: 3rem 0;
    margin: 3rem 0;
    width: 100%
}
  }

.LayoutProfile_infoContainer__1JYnE {
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.LayoutProfile_infoContainer__1JYnE {
    width: calc(35% - 2rem);
    width: calc(35% - 2rem);
    margin-right: 2rem;
    margin-right: 2rem
}
  }

.LayoutProfile_separator__YSV_T {
  display: none
}

@media (min-width: 768px) {

.LayoutProfile_separator__YSV_T {
    display: block;
    width: 1px;
    background-color: #e2e8f0;
    background-color: #e2e8f0
}
  }

@media (min-width: 768px) {

.LayoutProfile_mainContainer__sTe7w {
    width: calc(50% - 2rem);
    width: calc(50% - 2rem);
    border-left: 1px solid #e2e8f0;
    border-left: 1px solid #e2e8f0;
    padding-left: 2rem;
    padding-left: 2rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ProfilePage_profileInfoContainer__6g4Bm {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem;
}

.ProfilePage_avatar__yGjuJ {
  width: 5.5rem;
  width: 5.5rem;
  height: 5.5rem;
  height: 5.5rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.ProfilePage_modalContainer__2Y_rl {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #000;
  background-color: #000;
  border-radius: 5px;
  border-radius: 5px;
  border-bottom: none;
  flex-grow: 0;
  height: 100vh;
  overflow: hidden;
  margin: 0
}

@media (min-width: 768px) {

.ProfilePage_modalContainer__2Y_rl {
    padding: 1.5rem;
    padding: 1.5rem;
    height: auto;
    max-width: 50rem;
    max-width: 50rem;
    margin: 0 1.5rem;
    margin: 0 1.5rem
}
  }

.ProfilePage_closeButton__2Tsfo {
  position: absolute;
  top: 5rem;
  top: 5rem;
  right: 1.5rem;
  right: 1.5rem;
  width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  line-height: 1;
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
  z-index: 1
}

@media (min-width: 768px) {

.ProfilePage_closeButton__2Tsfo {
    top: 1rem;
    top: 1rem;
    width: 2rem;
    width: 2rem;
    height: 2rem;
    height: 2rem
}
  }

.ProfilePage_closeIcon__Zxwrm {
  color: white;
  transition: transform 0.3s ease;
  width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  height: 2.5rem
}

@media (min-width: 768px) {

.ProfilePage_closeIcon__Zxwrm {
    width: 2.5rem;
    width: 2.5rem;
    height: 2.5rem;
    height: 2.5rem
}
  }

.ProfilePage_closeIcon__Zxwrm:hover {
  transform: scale(1.1);
}

.ProfilePage_avatarModalContainer__2cujS {
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}

@media (min-width: 768px) {

.ProfilePage_avatarModalContainer__2cujS {
    height: 100%;
    margin-bottom: 1rem;
    margin-bottom: 1rem
}
  }

.ProfilePage_avatarImage__133S- {
  width: 21rem;
  width: 21rem;
  height: 21rem;
  height: 21rem
}

@media (min-width: 768px) {

.ProfilePage_avatarImage__133S- {
    width: 25rem;
    width: 25rem;
    height: 25rem;
    height: 25rem;
    margin-top: 1rem;
    margin-top: 1rem
}
  }

.ProfilePage_profileInfo__2wlpp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProfilePage_profileName__29Cn_ {
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
}

.ProfilePage_profileCreated__3vqe- {
  color: #718096;
  color: #718096;
}

.ProfilePage_profileLink__3i2Ju {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-weight: 600;
  white-space: nowrap;
}

.ProfilePage_profileReviews__10Hj4 {
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
  margin-top: 1.25rem;
}

.ProfilePage_reviewRating__24qhA {
  margin-right: 1rem;
  margin-right: 1rem;
}

.ProfilePage_reviewRatingStar__2RzGL {
  width: 14px;
}

.ProfilePage_profileContent__2KlW9 {
  background-color: #f7fafc;
  background-color: #f7fafc;
  border-radius: 10px;
  border-radius: 10px;
  color: #1a202c;
  color: #1a202c;

  padding: 2rem 0.75rem;

  padding: 2rem 0.75rem;
}

.ProfilePage_profileBioHeader__3Kh2g {
  font-weight: 700;
  font-weight: 700;
  font-size: 1.125rem;
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.ProfilePage_profileBioHeader__3Kh2g {
    font-size: 1.5rem;
    font-size: 1.5rem
}
  }

.ProfilePage_profileInfoItem__3seXY {
  display: flex;
  align-items: center;
  color: #4a5569;
  color: #4a5569;
  font-weight: 600;
  font-weight: 600;
  margin-top: 1rem;
  margin-top: 1rem;
}

.ProfilePage_profileInfoIcon__2Rf5e {
  width: 1.25rem;
  width: 1.25rem;
  margin-right: 0.75rem;
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
}

.ProfilePage_profileListingsHeader__1PLaC {
  font-weight: 700;
  font-weight: 700;
  font-size: 1.125rem;
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  margin-top: 1.25rem
}

@media (min-width: 768px) {

.ProfilePage_profileListingsHeader__1PLaC {
    font-size: 1.5rem;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-top: 2rem
}
  }

@media (min-width: 768px) {

.ProfilePage_profileListings__3gFNx {
    display: flex;
    flex-flow: wrap;
    margin-left: calc(-1 * 1.5rem);
    margin-left: calc(-1 * 1.5rem);
    margin-right: calc(-1 * 1.5rem);
    margin-right: calc(-1 * 1.5rem)
}
  }

.ProfilePage_profileListing__1MZJK {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem
}

@media (min-width: 768px) {

.ProfilePage_profileListing__1MZJK {
    width: calc(50% - 3rem);
    width: calc(50% - 3rem);
    margin-right: 1.5rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    margin-left: 1.5rem
}
  }

.ProfilePage_profileSpeciesWrapper__2fvcu {
  margin-bottom: 2.5rem;
  margin-bottom: 2.5rem;
}

.ProfilePage_profileSpeciesHeader__2GndV {
  font-weight: 700;
  font-weight: 700;
  font-size: 1.125rem;
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.ProfilePage_profileSpeciesHeader__2GndV {
    font-size: 1.5rem;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-top: 2rem
}
  }

.ProfilePage_profileSpecies__2_Le4 {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(-1 * 1rem);
  margin-left: calc(-1 * 1rem);
  margin-right: calc(-1 * 1rem);
  margin-right: calc(-1 * 1rem);
}

.ProfilePage_profileSpeciesImageContainer__1YpIu {
  position: relative;
  display: flex;
  align-items: center
}

@media (min-width: 768px) {

.ProfilePage_profileSpeciesImageContainer__1YpIu {
    height: 8rem;
    height: 8rem
}
  }

.ProfilePage_profileSpeciesItem__2WN6s {
  flex-grow: 0;
  flex-basis: 50%
}

@media (min-width: 768px) {

.ProfilePage_profileSpeciesItem__2WN6s {
    width: 9.5rem;
    width: 9.5rem;
    flex-basis: auto;
    margin-right: 1rem;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-left: 1rem
}

    .ProfilePage_profileSpeciesItem__2WN6s > div > div > svg {
      width: 9.5rem;
      width: 9.5rem;
    }
  }

.ProfilePage_profileSpeciesItemTitle__1uJjK {
  padding: 0.5rem;
  padding: 0.5rem;
  font-weight: 600;
  font-weight: 600;
  font-size: 1rem;
  font-size: 1rem;
  color: #1a202c;
  color: #1a202c;
  line-height: 1;
  line-height: 1;
}

.ProfilePage_profileSpeciesItemImage__3_xEB {
  position: absolute;
  top: calc(50% - (6rem / 2));
  top: calc(50% - (6rem / 2));
  left: calc(50% - (6rem / 2));
  left: calc(50% - (6rem / 2));
  width: 6rem;
  width: 6rem;
  height: 6rem;
  height: 6rem;
  z-index: 10;
  z-index: 10
}

@media (min-width: 768px) {

.ProfilePage_profileSpeciesItemImage__3_xEB {
    position: absolute;
    top: calc(50% - (117px / 2));
    left: calc(50% - (117px / 2));
    width: 117px;
    height: 117px;
    z-index: 10;
    z-index: 10
}
  }

.ProfilePage_profileReviewsWrapper__3xR9k {
  display: flex;
  flex-direction: column;
}

.ProfilePage_profileReviewsHeader__umpG8 {
  font-weight: 700;
  font-weight: 700;
  font-size: 1.125rem;
  font-size: 1.125rem;
  margin-bottom: 0
}

@media (min-width: 768px) {

.ProfilePage_profileReviewsHeader__umpG8 {
    font-size: 1.5rem;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-top: 2rem
}
  }

.ProfilePage_profileReviewsTabs__3sf6t {
  margin-left: calc(-1 * 1.25rem);
  margin-left: calc(-1 * 1.25rem);
  margin-right: calc(-1 * 1.25rem);
  margin-right: calc(-1 * 1.25rem)
}

@media (min-width: 768px) {

.ProfilePage_profileReviewsTabs__3sf6t {
    margin-left: 0;
    margin-right: 0
}
  }

.ProfilePage_reviewStarIcon__3dFRc {
  margin: 0 0.5rem 0 0.5rem;
  margin: 0 0.5rem 0 0.5rem;
  height: 1rem;
  height: 1rem;
  width: 1rem;
  width: 1rem;
}

.ProfilePage_profileReviewsEmpty__3Z1cJ {
  display: flex;
  margin-top: 1.5rem;
  margin-top: 1.5rem;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ProfileSettingsPage_root__2Iswc {
  background-color: #fff;
  background-color: #fff;
}

.ProfileSettingsPage_profileInfoContainer__3fqTl {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem
}

@media (min-width: 768px) {

.ProfileSettingsPage_profileInfoContainer__3fqTl {
    margin-bottom: 0
}
  }

.ProfileSettingsPage_profileFlexContainer__S0aZW {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.ProfileSettingsPage_profileFlexContainer__S0aZW {
    margin-bottom: 1rem;
    margin-bottom: 1rem
}
  }

.ProfileSettingsPage_profileInfo__LewkN {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  margin-left: 1rem;
}

.ProfileSettingsPage_profileName__2bq9D {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700
}

@media (min-width: 768px) {

.ProfileSettingsPage_profileName__2bq9D {
    font-size: 1.25rem;
    font-size: 1.25rem
}
  }

.ProfileSettingsPage_profileLink__AZn8Y {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-weight: 600;
  text-decoration: underline;
}

.ProfileSettingsPage_profilePictureText__3iohI {
  display: none;
  color: #1a202c;
  color: #1a202c
}

@media (min-width: 768px) {

.ProfileSettingsPage_profilePictureText__3iohI {
    display: flex;
    font-size: 0.875rem;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
    padding: 0 0.5rem
}
  }

.ProfileSettingsPage_badgeContainer__2ElJV {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.75rem;
  margin-bottom: 1.75rem;
  color: #1a202c;
  color: #1a202c;
  margin-left: calc(-1 * 0.5rem);
  margin-left: calc(-1 * 0.5rem);
  margin-right: calc(-1 * 0.5rem);
  margin-right: calc(-1 * 0.5rem);
}

.ProfileSettingsPage_badge__fRkql {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
  border-radius: 20px;
  border-radius: 20px;
  background-color: #f7fafc;
  background-color: #f7fafc;
  padding: 1rem;
  padding: 1rem;
  margin-right: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-left: 0.5rem;
}

.ProfileSettingsPage_badgeHeader__oh3sm {
  font-size: 0.75rem;
  font-size: 0.75rem;
}

.ProfileSettingsPage_badgeContent__7h38y {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 600;
  font-weight: 600;
}

.ProfileSettingsPage_mobileTabs__3fY77 {
  margin-left: calc(-1 * 1.25rem);
  margin-left: calc(-1 * 1.25rem);
  margin-right: calc(-1 * 1.25rem);
  margin-right: calc(-1 * 1.25rem)
}

@media (min-width: 768px) {

.ProfileSettingsPage_mobileTabs__3fY77 {
    display: none;
    margin-left: 0;
    margin-right: 0
}
  }

.ProfileSettingsPage_desktopTabs__aufui {
  display: none
}

@media (min-width: 768px) {

.ProfileSettingsPage_desktopTabs__aufui {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0
}
  }

.ProfileSettingsPage_tabContent__2H8Ke {
  margin-top: 1.25rem;
  margin-top: 1.25rem;
}

.ProfileSettingsPage_desktopTab__26FdX {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0px;
  overflow: auto;
  padding: 20px 176px 22px 0;
  border-radius: 12px;
  border: solid 1px #e2e8f0;
  border: solid 1px #e2e8f0;
}

.ProfileSettingsPage_avatarContainer__14M4i {
  position: relative;
}

.ProfileSettingsPage_uploadButton__kNR3T {
  width: 100%;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.ReviewPage_root__36LUi {
  margin-top: 1rem;
  margin-top: 1rem
}

@media (min-width: 768px) {

.ReviewPage_root__36LUi {
    margin-top: 2rem;
    margin-top: 2rem
}
  }

.ReviewPage_reviewWrapper__2N67a {
  display: flex;
  flex-direction: column;
  width: calc(100% - 2 * 1.25rem);
  width: calc(100% - 2 * 1.25rem);
  margin: 1.25rem;
  margin: 1.25rem
}

@media (min-width: 768px) {

.ReviewPage_reviewWrapper__2N67a {
    width: 40rem;
    width: 40rem;
    margin: 0 auto 9rem auto;
    margin: 0 auto 9rem auto
}
  }

.ReviewPage_reviewTitle__OR0Fi {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1a202c;
  color: #1a202c;
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700;
  text-align: center;
  margin-top: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.ReviewPage_reviewTitle__OR0Fi {
    font-size: 2.25rem;
    font-size: 2.25rem;
    margin-top: 2.25rem;
    margin-top: 2.25rem
}
  }

.ReviewPage_expiredTitle__Cpjdu {
  width: 10rem;
  width: 10rem
}

@media (min-width: 768px) {

.ReviewPage_expiredTitle__Cpjdu {
    width: 100%
}
  }

.ReviewPage_reviewee__3yKfa {
  white-space: nowrap;
}

.ReviewPage_avatars__1sGr6 {
  display: flex;
  justify-content: center;
}

.ReviewPage_avatarRight__2l2_S {
  position: relative;
  right: 0.5rem;
  right: 0.5rem;
  border: 3px solid #fff;
  border: 3px solid #fff;
  width: 4.75rem;
  width: 4.75rem;
  height: 4.75rem;
  height: 4.75rem
}

@media (min-width: 768px) {

.ReviewPage_avatarRight__2l2_S {
    width: 6.25rem;
    width: 6.25rem;
    height: 6.25rem;
    height: 6.25rem
}
  }

.ReviewPage_avatarLeft__2mdiH {
  position: relative;
  left: 0.5rem;
  left: 0.5rem;
  border: 3px solid #fff;
  border: 3px solid #fff;
  width: 4.75rem;
  width: 4.75rem;
  height: 4.75rem;
  height: 4.75rem
}

@media (min-width: 768px) {

.ReviewPage_avatarLeft__2mdiH {
    width: 6.25rem;
    width: 6.25rem;
    height: 6.25rem;
    height: 6.25rem
}
  }

.ReviewPage_listingTitle__361pq {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem
}

@media (min-width: 768px) {

.ReviewPage_listingTitle__361pq {
    margin-bottom: 1rem;
    margin-bottom: 1rem
}
  }

.ReviewPage_clockImage__3lSYk {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  height: 7.75rem;
  height: 7.75rem
}

@media (min-width: 768px) {

.ReviewPage_clockImage__3lSYk {
    height: 11rem;
    height: 11rem
}
  }

.ReviewPage_reviewCTA__10AKu {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  margin: 2rem 0;
}

@media (min-width: 768px) {

.ReviewPage_leftReviewContent__2QiT_ {
    width: calc(100% - 2 * 1.25rem);
    width: calc(100% - 2 * 1.25rem)
}
  }

.ReviewPage_starRating__34Ln_ {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.ReviewPage_reviewContent__1O3k0 {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/

.SearchPage_topbarBehindModal__YMPr8 {
  z-index: auto;
  z-index: initial;
}

.SearchPage_topbar__7aZpY {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  /* We need to raise Topbar above .container */
  z-index: 10;
  z-index: 10;
}

.SearchPage_container__3_Ade {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex
}

@media (min-width: 768px) {

.SearchPage_container__3_Ade {
    position: relative;
    min-height: calc(100vh - 72px);
    min-height: calc(100vh - 72px)
}
  }

.SearchPage_searchResultContainer__r2fqe {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem 0 0 0;
  padding: 2rem 0 0 0
}

@media (min-width: 768px) {

.SearchPage_searchResultContainer__r2fqe {
    flex-basis: 50%
}
  }

.SearchPage_searchResultContainerHidden__1hRjD {
  display: none;
}

.SearchPage_error__2N1SR {
  color: #ff0000;
  color: #ff0000;
}

.SearchPage_searchString__3EMf- {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.SearchPage_searchFilters__3n1dQ,
.SearchPage_searchResultSummary__1itRm,
.SearchPage_noSearchResults__1COWe {
  padding: 0 1.25rem 0 1.25rem;
  padding: 0 1.25rem 0 1.25rem
}

@media (min-width: 1024px) {

.SearchPage_searchFilters__3n1dQ,
.SearchPage_searchResultSummary__1itRm,
.SearchPage_noSearchResults__1COWe {
    padding: 0 1.5rem 0 3rem;
    padding: 0 1.5rem 0 3rem
}
  }

.SearchPage_searchResultSummary__1itRm {
  margin-top: 1rem;
  margin-top: 1rem
}

@media (min-width: 1024px) {

.SearchPage_searchResultSummary__1itRm {
    margin-top: 1.5rem;
    margin-top: 1.5rem
}
  }

.SearchPage_noSearchResults__1COWe {
  margin-top: 1rem;
  margin-top: 1rem;
}

@media (min-width: 1024px) {

.SearchPage_noSearchResultsNoFilters__3IzPa {
    margin-top: 4rem;
    margin-top: 4rem
}
  }

.SearchPage_loadingResults__1pg_g,
.SearchPage_resultsFound__2bqFJ {
  line-height: 20px;
  color: #1a202c;
  color: #1a202c;
  font-size: 1rem;
  font-size: 1rem;
  font-weight: bold
}

@media (min-width: 768px) {

.SearchPage_loadingResults__1pg_g,
.SearchPage_resultsFound__2bqFJ {
    font-weight: 600;
    font-weight: 600
}
  }

.SearchPage_resultsFound__2bqFJ {
  white-space: nowrap;
}

.SearchPage_listings__3d2YJ {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 1.5rem;
  margin-top: 1.5rem
}

@media (min-width: 768px) {

.SearchPage_listings__3d2YJ {
    margin-top: 1rem;
    margin-top: 1rem
}
  }

.SearchPage_newSearchInProgress__3uTXI {
  opacity: 0.1;
}

.SearchPage_searchListingsPanel__2T4Gw {
  flex-grow: 1;
}

@media (min-width: 768px) {

.SearchPage_mapPanel__1PHWD {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%
}
  }

.SearchPage_mapWrapper__18eZg {
  width: 100%;
  height: 100%;
}

.SearchPage_map__2tNO6 {
  width: 100vw;
  height: calc(100vh - 72px);
  height: calc(100vh - 72px)
}

@media (min-width: 768px) {

.SearchPage_map__2tNO6 {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: 72px;
    top: 72px;
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.StyleguidePage_root__fWqt2 {
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.StyleguidePage_root__fWqt2 {
    flex-direction: row
}
  }

.StyleguidePage_withPadding__2wB_g {
  padding: calc(2 * 6px);
  padding: calc(2 * 6px)
}

@media (min-width: 768px) {

.StyleguidePage_withPadding__2wB_g {
    padding: calc(2 * 8px);
    padding: calc(2 * 8px)
}
  }

.StyleguidePage_withMargin__2pNI9,
.StyleguidePage_defaultWrapperStyles__Mkiq5 {
  margin: calc(2 * 6px) 0;
  margin: calc(2 * 6px) 0
}

@media (min-width: 768px) {

.StyleguidePage_withMargin__2pNI9,
.StyleguidePage_defaultWrapperStyles__Mkiq5 {
    margin: calc(2 * 8px) 0;
    margin: calc(2 * 8px) 0
}
  }

.StyleguidePage_navBar__11TB3 {
  flex-basis: 300px;
  flex-shrink: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.StyleguidePage_main__3m4PX {
  flex-grow: 1
}

@media (min-width: 768px) {

.StyleguidePage_main__3m4PX {
    padding: 48px 16px 0 16px
}
  }

.StyleguidePage_examplesList__1DS_j {
  margin: 24px 0;
}

.StyleguidePage_example__1hxKS {
  padding: 0;
}

.StyleguidePage_link__252YM {
  color: #3e704b;
  color: #3e704b;
}

.StyleguidePage_groups__1xmcs {
  margin: 0 0 12px 0;
}

.StyleguidePage_group__aXXg6 {
  text-transform: capitalize;
}

.StyleguidePage_selectedGroup__1JMq- {
  font-weight: bold;
  color: #3e704b;
  color: #3e704b;
}

/* Typography.example */

.StyleguidePage_typographyContent__2T0tx {
}

.StyleguidePage_baselines__VJ3Y0 {
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="24" viewBox="0 0 10 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h10M0 06h10M0 12h10" stroke="%23ccc" shape-rendering="crispEdges" /><path d="M0 18h10" stroke="%23ddd" stroke-width="1" shape-rendering="crispEdges" /></svg>');
  background-repeat: repeat
}

@media (min-width: 768px) {

.StyleguidePage_baselines__VJ3Y0 {
    background-image: url('data:image/svg+xml;utf8,<svg width="10" height="24" viewBox="0 0 10 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h10" stroke="%23ddd" stroke-width="1" shape-rendering="crispEdges" /><path d="M0 08h10M0 16h10M0" stroke="%23ccc" shape-rendering="crispEdges" /></svg>');
    background-repeat: repeat
}
  }

.StyleguidePage_spacing2x__12XWB {
  margin: calc(2 * 6px) 0;
  margin: calc(2 * 6px) 0
}

@media (min-width: 768px) {

.StyleguidePage_spacing2x__12XWB {
    margin: calc(2 * 8px) 0;
    margin: calc(2 * 8px) 0
}
  }

.StyleguidePage_fontsContainer__V20bF {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px
}

@media (min-width: 768px) {

.StyleguidePage_fontsContainer__V20bF {
    margin-top: 19px
}
  }

.StyleguidePage_fontCard__3aL8I {
  flex-basis: 300px;
  display: flex;
  flex-direction: column;

  margin-right: calc(2 * 6px);

  margin-right: calc(2 * 6px);
  margin-bottom: calc(2 * 6px + 2px);
  margin-bottom: calc(2 * 6px + 2px)
}

@media (min-width: 768px) {

.StyleguidePage_fontCard__3aL8I {
    margin-right: calc(2 * 8px);
    margin-right: calc(2 * 8px);
    margin-bottom: calc(2 * 8px + 2px);
    margin-bottom: calc(2 * 8px + 2px)
}
  }

.StyleguidePage_element__3CK4s {
  border-style: solid;
  border-color: #aaa;
  border-width: 1px;
}

.StyleguidePage_description__3dRGN {
  flex-grow: 1;
  width: 100%;
  background-color: #f1f1f1;
  padding: calc(2 * 6px - 3px) calc(2 * 6px);
  padding: calc(2 * 6px - 3px) calc(2 * 6px);

  border-style: solid;
  border-color: #aaa;
  border-width: 1px;
  border-top-color: transparent
}

@media (min-width: 768px) {

.StyleguidePage_description__3dRGN {
    padding: calc(2 * 8px - 3px) calc(2 * 8px);
    padding: calc(2 * 8px - 3px) calc(2 * 8px)
}
  }

.StyleguidePage_heroTitle__3zw9G {
  font-weight: 700;
  font-weight: 700;
  font-size: 42px;
  line-height: 42px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 25px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {

.StyleguidePage_heroTitle__3zw9G {
  font-size: 72px;
  line-height: 72px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 25px;
  margin-bottom: 31px;
}
    }

@media (min-width: 1681px) {

.StyleguidePage_heroTitle__3zw9G {
  font-size: 84px;
  line-height: 84px;
}
    }

.StyleguidePage_tinyFont__2IFZd {
  font-size: 13px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
}

@media (min-width: 768px) {

.StyleguidePage_tinyFont__2IFZd {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }

/* Colors.example */

.StyleguidePage_colorsContent__1Xbm3,
.StyleguidePage_colorsContainer__13fVw {
  display: flex;
  flex-direction: column;
}

.StyleguidePage_colorsGroup__3msDO {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(4 * 6px);
  margin-top: calc(4 * 6px);
}

.StyleguidePage_colorCard__3uAXC {
  flex-basis: 275px;
  height: 275px;
  display: flex;
  flex-direction: column;
  margin-bottom: calc(4 * 6px);
  margin-bottom: calc(4 * 6px);
  margin-right: calc(2 * 6px);
  margin-right: calc(2 * 6px);
  overflow: hidden;

  border: 1px solid #a1aec1;

  border: 1px solid #a1aec1;
}

.StyleguidePage_color__1WFMg {
  width: 100%;
  height: 66.6%;
}

.StyleguidePage_colorDescription__3ZGqL {
  flex-grow: 1;
  width: 100%;
  background-color: #fff;
  padding: calc(2 * 6px);
  padding: calc(2 * 6px)
}

@media (min-width: 768px) {

.StyleguidePage_colorDescription__3ZGqL {
    padding: calc(2 * 8px - 2px) calc(2 * 8px);
    padding: calc(2 * 8px - 2px) calc(2 * 8px)
}
  }

.StyleguidePage_marketplaceColorBackground__26OlA {
  background-color: #3e704b;
  background-color: #3e704b;
}

.StyleguidePage_marketplaceColorLightBackground__2Ogbb {
  background-color: #3e704b;
  background-color: #3e704b;
}

.StyleguidePage_marketplaceColorDarkBackground__2e-4U {
  background-color: #3e704b;
  background-color: #3e704b;
}

.StyleguidePage_successColorBackground__2rFF0 {
  background-color: #2ecc71;
  background-color: #2ecc71;
}

.StyleguidePage_failColorBackground__3PYAe {
  background-color: #ff0000;
  background-color: #ff0000;
}

.StyleguidePage_attentionColorBackground__1dbAe {
  background-color: #ffaa00;
  background-color: #ffaa00;
}

.StyleguidePage_matterColorBackground__3rGiV {
  background-color: #4a5569;
  background-color: #4a5569;
}

.StyleguidePage_matterColorAntiBackground__31jFV {
  background-color: #a1aec1;
  background-color: #a1aec1;
}

.StyleguidePage_matterColorDarkBackground__2vC8Y {
  background-color: #1a202c;
  background-color: #1a202c;
}

.StyleguidePage_matterColorNegativeBackground__22umO {
  background-color: #a1aec1;
  background-color: #a1aec1;
}

.StyleguidePage_matterColorLightBackground__3djk8 {
  background-color: #ffffff;
  background-color: #ffffff;
}

.BookingPanelExample_example__3j3HQ {
  width: 312px;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.ButtonExample_buttonLink__1mKV3 {
  font-weight: 400;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.ButtonExample_buttonLink__1mKV3 {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.ButtonExample_buttonLink__1mKV3 {

    /* Dimensions */
  display: block;
  width: 100%;
  min-height: 59px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 16px 0 0 0;

    /* Borders */
  border-style: solid;
  border-color: #3e704b;
  border-color: #3e704b;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #3e704b;
  color: #3e704b;

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
}

.ButtonExample_buttonLink__1mKV3:hover,
    .ButtonExample_buttonLink__1mKV3:focus {
  text-decoration: none;
    }

@media (min-width: 768px) {

.ButtonExample_buttonLink__1mKV3 {
  min-height: 60px;
}
    }

.ButtonExample_buttonLinkPrimary__1G1ET {
  font-weight: 400;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.ButtonExample_buttonLinkPrimary__1G1ET {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.ButtonExample_buttonLinkPrimary__1G1ET {

    /* Dimensions */
  display: block;
  width: 100%;
  min-height: 59px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 16px 0 0 0;

    /* Borders */
  border-style: solid;
  border-color: #3e704b;
  border-color: #3e704b;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #3e704b;
  color: #3e704b;

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
}

.ButtonExample_buttonLinkPrimary__1G1ET:hover,
    .ButtonExample_buttonLinkPrimary__1G1ET:focus {
  text-decoration: none;
    }

@media (min-width: 768px) {

.ButtonExample_buttonLinkPrimary__1G1ET {
  min-height: 60px;
}
    }

.ButtonExample_buttonLinkPrimary__1G1ET {

  background-color: #3e704b;

  background-color: #3e704b;
  color: #fff;
  color: #fff;
  border-color: #3e704b;
  border-color: #3e704b;
}

.ButtonExample_buttonLinkPrimary__1G1ET:hover,
    .ButtonExample_buttonLinkPrimary__1G1ET:focus {
  background-color: #2a5e38;
  background-color: #2a5e38;
  border-color: #2a5e38;
  border-color: #2a5e38;
  color: #fff;
  color: #fff;
    }

.ButtonExample_buttonLinkPrimary__1G1ET:disabled {
  background-color: #a1aec1;
  background-color: #a1aec1;
  color: #ffffff;
  color: #ffffff;
    }

.ButtonExample_buttonLinkSecondary__1k27M {
  font-weight: 400;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.ButtonExample_buttonLinkSecondary__1k27M {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.ButtonExample_buttonLinkSecondary__1k27M {

    /* Dimensions */
  display: block;
  width: 100%;
  min-height: 59px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 16px 0 0 0;

    /* Borders */
  border-style: solid;
  border-color: #3e704b;
  border-color: #3e704b;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #3e704b;
  color: #3e704b;

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
}

.ButtonExample_buttonLinkSecondary__1k27M:hover,
    .ButtonExample_buttonLinkSecondary__1k27M:focus {
  text-decoration: none;
    }

@media (min-width: 768px) {

.ButtonExample_buttonLinkSecondary__1k27M {
  min-height: 60px;
}
    }

.ButtonExample_buttonLinkSecondary__1k27M {

  color: #1a202c;

  color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;

    /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */
  padding: 18px 0 0 0;

  border-width: 1px;
  border-style: solid;
  border-color: #a1aec1;
  border-color: #a1aec1;
}

.ButtonExample_buttonLinkSecondary__1k27M:hover,
    .ButtonExample_buttonLinkSecondary__1k27M:focus {
  background-color: #1a202c;
  background-color: #1a202c;
  border-color: #1a202c;
  border-color: #1a202c;
  color: #fff;
  color: #fff;
    }

.ButtonExample_buttonLinkSecondary__1k27M:disabled {
  background-color: #a1aec1;
  background-color: #a1aec1;
  color: #ffffff;
  color: #ffffff;
  border-color: #a1aec1;
  border-color: #a1aec1;
    }

.ButtonExample_customButton__2eDWO {
  font-weight: 400;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px;
}

@media (min-width: 768px) {

.ButtonExample_customButton__2eDWO {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.ButtonExample_customButton__2eDWO {

    /* Dimensions */
  display: block;
  width: 100%;
  min-height: 59px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 16px 0 0 0;

    /* Borders */
  border-style: solid;
  border-color: #3e704b;
  border-color: #3e704b;
  border-radius: 2px;
  border-radius: 2px;

    /* Colors */
  background-color: transparent;
  color: #3e704b;
  color: #3e704b;

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
}

.ButtonExample_customButton__2eDWO:hover,
    .ButtonExample_customButton__2eDWO:focus {
  text-decoration: none;
    }

@media (min-width: 768px) {

.ButtonExample_customButton__2eDWO {
  min-height: 60px;
}
    }

.ButtonExample_customButton__2eDWO {
  padding: 0;
  border-radius: 8px;
  height: 100px;
}

.ButtonExample_customIcon__2AOFF {
  width: 24px;
  height: 24px;
  stroke: #fff;
  margin-right: 10px;
}

.FieldTextInputExample_field__2rUjM {
  margin-top: 24px;
}

.FieldTextInputExample_submit__XuGLA {
  margin-top: 24px;
}

.FooterExample_example__1f8Zd {
  overflow-x: hidden;
}

.ModalInMobileExample_visibleOnMobileLayout__1eCvr {
  display: none;
}

@media (max-width: 400px) {
  .ModalInMobileExample_visibleOnMobileLayout__1eCvr {
    display: block;
  }
}

.ResponsiveImageExample_root__3YwdU {
  display: block;
  position: relative;
  width: 200px;
  margin-bottom: 70px;
}

/* Firefox doesn't support image aspect ratio inside flexbox */

.ResponsiveImageExample_aspectWrapper__1ZRi0 {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
}

.ResponsiveImageExample_rootForImageWithAspectRatio__1VgMe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.ResponsiveImageExample_rootForSizes__38ZrL {
  display: block;
  position: relative;
  width: 200px;
  margin-bottom: 70px
}

@media (min-width: 601px) {

.ResponsiveImageExample_rootForSizes__38ZrL {
    width: 400px
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.TermsOfServicePage_heading__fzPg1 {
  margin: 5px 0 18px 0
}

@media (min-width: 768px) {

.TermsOfServicePage_heading__fzPg1 {
    margin: 8px 0 24px 0
}
  }

@media (min-width: 1024px) {

.TermsOfServicePage_content__3MqP9 {
    max-width: 563px
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* Fonts */

/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}

/* ================ Custom media queries ================ */

.TransactionPage_root__inY7J {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;
  z-index: 1;
}

.TransactionPage_topbarContainer__1Ts14 {
  position: relative;
  z-index: 20;
}

.TransactionPage_loading__21kY_ {
  margin-left: 24px;
  margin-right: 24px;
}

.TransactionPage_error__1BjoM {
  margin-left: 24px;
  margin-right: 24px;
  color: #ff0000;
  color: #ff0000;
}

.TransactionPage_tabContent__1bTVV {
  display: none;
}

.TransactionPage_tabContentVisible__13K1s {
  display: block;
}

.TransactionPage_footer__fvY_M {
  display: none
}

@media (min-width: 1024px) {

.TransactionPage_footer__fvY_M {
    display: block
}
  }

.TransactionPage_customModal__2o00i {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  max-width: 50rem;
  background-color: #fff;
  background-color: #fff;
  border-radius: 5px;
  border-radius: 5px;
  border-bottom: none;
  flex-grow: 0;
  min-height: 100%;
  min-width: 100vw;
  height: auto;
  overflow: hidden
}

@media (min-width: 1024px) {

.TransactionPage_customModal__2o00i {
    min-width: 40%;
    min-width: 40%;
    min-height: auto
}
  }

.TransactionPage_defaultStyles__FBcTa {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.TransactionPage_scrollLayer__3hEUS {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: none;
}

.TransactionPage_modalContent__1DiYX {
  width: 100%;
  height: 100%;
}

.TransactionPage_customCloseButton__1Vs46 {
  position: absolute;
  top: 5rem;
  top: 5rem;
  right: 1.5rem;
  right: 1.5rem;
  width: 2rem;
  width: 2rem;
  height: 2rem;
  height: 2rem;
  padding: 0.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  line-height: 1;
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
  z-index: 20
}

@media (min-width: 768px) {

.TransactionPage_customCloseButton__1Vs46 {
    top: 1rem;
    top: 1rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.NewInquiryPage_page__29-sb {
}

.NewInquiryPage_container__lLRti {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column
}

@media (min-width: 1024px) {

.NewInquiryPage_container__lLRti {
    flex-direction: row
}
  }

.NewInquiryPage_formContainer__26SuA {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
}

.NewInquiryPage_topBarExitButton__2vaBz {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: bold;
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #1a202c;
  color: #1a202c;
  cursor: pointer;
}

.NewInquiryPage_topbarBackIconMobile__1psYg {
  width: 1rem;
  width: 1rem;
  height: 1rem;
  height: 1rem;
  color: #1a202c;
  color: #1a202c;
}

.NewInquiryPage_pickerContainer__3myK5 {
  display: flex;
  flex: 1 1;
  flex-direction: column
}

@media (min-width: 768px) {

.NewInquiryPage_pickerContainer__3myK5 {
    flex-direction: row;
    justify-content: space-between
}
  }

.NewInquiryPage_coltonContainer__tGLFw {
  display: none
}

@media (min-width: 1024px) {

.NewInquiryPage_coltonContainer__tGLFw {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    width: 350px;
    margin-left: 3rem;
    margin-left: 3rem
}
  }

.NewInquiryPage_pickerElementContainer__5a2pz {
  display: flex;
  flex: 1 1;
  flex-direction: column
}

@media (min-width: 768px) {

.NewInquiryPage_pickerElementContainer__5a2pz {
    flex: initial;
    max-width: 50%;
    max-width: 50%
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.PackagePage_root__LkKi4 {
  display: flex;
  padding-bottom: 90px
}

@media (min-width: 1024px) {

.PackagePage_root__LkKi4 {
    padding-bottom: 0
}
  }

.PackagePage_mobileSecondaryWrapper__2kqmH {
  display: flex;
  align-items: baseline
}

.PackagePage_mobileSecondaryWrapper__2kqmH > button {
    margin-right: 0.5rem;
    margin-right: 0.5rem;
    height: 2.75rem;
    height: 2.75rem;
    width: 2.75rem;
    width: 2.75rem
  }

.PackagePage_mobileSecondaryWrapper__2kqmH > button > svg {
      width: 1.5rem;
      width: 1.5rem;
    }

.PackagePage_sectionImagesContainer__1kS6K {
  position: relative
}

@media (min-width: 768px) {

.PackagePage_sectionImagesContainer__1kS6K {
    padding-bottom: 0.75rem;
    padding-bottom: 0.75rem
}
  }

.PackagePage_listingInfoContainer__2_W3d {
  display: flex;
  flex-direction: column-reverse;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

@media (min-width: 768px) {
  .PackagePage_listingInfoContainer__2_W3d {
    flex-direction: column;
  }
}

.PackagePage_listingActions__1nT2r {
  display: flex;
  padding: 1.25rem 1.5rem 0 1.5rem;
  padding: 1.25rem 1.5rem 0 1.5rem
}

@media (min-width: 768px) {

.PackagePage_listingActions__1nT2r {
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    margin-top: 2rem
}
  }

.PackagePage_listingActionsContent__2eW8B {
  width: 100%
}

@media (min-width: 768px) {

.PackagePage_listingActionsContent__2eW8B {
    width: auto
}
  }

.PackagePage_shareContainer__2mJ3l {
  display: none
}

@media (min-width: 1024px) {

.PackagePage_shareContainer__2mJ3l {
    display: flex
}
  }

.PackagePage_share__gYc7r {
  display: none
}

@media (min-width: 768px) {

.PackagePage_share__gYc7r {
    display: flex
}
  }

@media (min-width: 768px) {

.PackagePage_shareButton__2c_-o {
    text-decoration: underline
}
  }

.PackagePage_contentContainer__1rGYb {
  display: flex;
  position: relative
}

@media (min-width: 1024px) {

.PackagePage_contentContainer__1rGYb {
    margin: 0 auto 3.75rem;
    margin: 0 auto 3.75rem;
    justify-content: space-between
}
  }

.PackagePage_mainContent__2yK-D {
  flex-basis: 100%;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem
}

@media (min-width: 1024px) {

.PackagePage_mainContent__2yK-D {
    flex-basis: 60%;
    margin-bottom: 0px;
    padding-right: 4rem;
    padding-right: 4rem
}
  }

.PackagePage_totalDivider__3Aufx {
  height: 1px;
  margin: 1.5rem;
  margin: 1.5rem;
  border: none;
  background-color: #e2e8f0;
  background-color: #e2e8f0
}

@media (min-width: 768px) {

.PackagePage_totalDivider__3Aufx {
    margin: 1.5rem 0;
    margin: 1.5rem 0
}
  }

.PackagePage_activityTotalDivider__jzeOr {
  height: 1px;
  margin: 2.25rem 1.25rem 1.25rem 1.25rem;
  margin: 2.25rem 1.25rem 1.25rem 1.25rem;
  border: none;
  background-color: #e2e8f0;
  background-color: #e2e8f0
}

@media (min-width: 768px) {

.PackagePage_activityTotalDivider__jzeOr {
    display: none
}
  }

@media (min-width: 768px) {

.PackagePage_secondaryContent__2Dd8Y {
    flex-basis: 40%;
    position: relative;
    bottom: 5.75rem;
    bottom: 5.75rem;
    padding-top: 1.5rem;
    padding-top: 1.5rem
}
  }

.PackagePage_pricingPanel__3T12I {
  display: none
}

@media (min-width: 768px) {

.PackagePage_pricingPanel__3T12I {
    position: -webkit-sticky;
    position: sticky;
    top: 5.5rem;
    top: 5.5rem;
    display: flex;
    flex-direction: column;
    bottom: 1.5rem;
    bottom: 1.5rem;
    padding: 1.5rem 1.5rem 2.5rem 1.5rem;
    padding: 1.5rem 1.5rem 2.5rem 1.5rem;
    border-radius: 10px;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e2e8f0;
    border: solid 1px #e2e8f0;
    background-color: #fff;
    background-color: #fff;
    min-width: 20rem;
    min-width: 20rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-basis: 40%;
    flex-basis: 40%;
    flex-shrink: 1;
    flex-grow: 1
}
  }

@media (min-width: 1280px) {

.PackagePage_pricingPanel__3T12I {
    bottom: 9.5rem;
    bottom: 9.5rem
}
  }

.PackagePage_packageTitleWrapper__3hUfA {
  max-width: 100%;
  padding-bottom: 0.75rem;
  padding-bottom: 0.75rem;
  line-height: 1;
  line-height: 1
}

@media (min-width: 768px) {

.PackagePage_packageTitleWrapper__3hUfA {
    display: flex;
    flex-direction: column;
    padding: 0;
    max-width: 780px;
    font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
    font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
    margin-bottom: 0.75rem;
    margin-bottom: 0.75rem
}
  }

.PackagePage_packageTitle__3SIg2 {
  font-size: 1.5rem;
  font-size: 1.5rem;
}

@media (min-width: 768px) {

.PackagePage_packageTitle__3SIg2 {
  font-size: 1.875rem;
  font-size: 1.875rem;
}
    }

.PackagePage_packageTitle__3SIg2 {
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 700;
  font-weight: 700;
  display: block;
}

.PackagePage_packageTitleListing__3GLOy {
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5
}

@media (min-width: 768px) {

.PackagePage_packageTitleListing__3GLOy {
    font-size: 1.125rem;
    font-size: 1.125rem
}
  }

.PackagePage_packageTitleListingLink__1xONc {
  text-decoration: underline;
}

.PackagePage_packageBadge__3UgUE {
  background-color: #e1ddd5;
  background-color: #e1ddd5;
  padding: 0.25rem 1rem;
  padding: 0.25rem 1rem;
}

.PackagePage_mobileCheckAvailabilityWrapper__2JcxP {
  z-index: 50;
  z-index: 50;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1.25rem;
  padding: 1.25rem;
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.PackagePage_mobileCheckAvailabilityWrapper__2JcxP {
    display: none
}
  }

.PackagePage_mobileCheckAvailability__EhBm7 {
  display: flex
}

@media (min-width: 768px) {

.PackagePage_mobileCheckAvailability__EhBm7 {
    display: none
}
  }

.PackagePage_mobileCheckAvailabilityPrice__1n3Ot {
  font-size: 0.875rem;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.PackagePage_activityFeedContainer__BXp73 {
  margin-top: 3rem;
  margin-top: 3rem;
}

.PackagePage_priceText__3lZMg {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.PackagePage_bookButton__3cbsG {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

.PackagePage_propertyVisitRoot__39i7r {
  background-color: #f5f2ed;
  background-color: #f5f2ed;
}

.PackagePage_activityFeedRoot__2gzaI {
  background-color: inherit
}

@media (min-width: 768px) {

.PackagePage_activityFeedRoot__2gzaI {
    background-color: #f7fafc;
    background-color: #f7fafc
}
  }

@media (min-width: 768px) {

.PackagePage_mobileBadges__G9x9o {
    display: none
}
  }

.PackagePage_formFieldHeading__3KrEg {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

.PackagePage_divider__3ar6f {
  height: 1px;
  background-color: #e2e8f0;
  background-color: #e2e8f0;
  margin-top: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.PackagePage_notifyButton__5_2zC {
  background-color: #1a202c;
  background-color: #1a202c;
  color: #fff;
  color: #fff;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.PackagePage_icon__2ymqx {
  width: 1rem;
  width: 1rem;
  height: 1.25rem;
  height: 1.25rem;
}

.PackagePage_mobileCheckAvailabilityColumn__1r-b0 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.PackagePage_getNotified__2zKui {
  color: #1a202c;
  color: #1a202c;
  font-size: 0.875rem;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-wrap: nowrap;
}

.PackagePage_hostBadgeWrapper__2ygIP {
  padding: 0 0.75rem 0;
  padding: 0 0.75rem 0
}

@media (min-width: 768px) {

.PackagePage_hostBadgeWrapper__2ygIP {
    padding: 0;

    margin-top: 1.5rem;

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-bottom: 1.5rem
}
  }

.PackagePage_availabilityBadgesWrapper__g3gq6 {
  margin: 1rem 0 0.25rem 0;
  margin: 1rem 0 0.25rem 0;
  padding: 0.75rem;
  padding: 0.75rem
}

@media (min-width: 768px) {

.PackagePage_availabilityBadgesWrapper__g3gq6 {
    display: none
}
  }

.PackagePage_sectionCountdownWrapper__mr2_h {
  padding: 0 1.5rem;
  padding: 0 1.5rem;
  margin-bottom: 2rem;
  margin-bottom: 2rem
}

@media (min-width: 768px) {

.PackagePage_sectionCountdownWrapper__mr2_h {
    padding: 0;
    margin-bottom: 0
}
  }

.PackagePage_packageAvailableTimer__3-qr0 {
  display: flex;
  justify-content: center;
  margin: 0.75rem 0;
  margin: 0.75rem 0;
  font-size: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  font-weight: 700;
}

.PackagePage_sectionCountdown__1j_Zx {
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.PackagePage_sectionCountdown__1j_Zx {
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

.PackagePage_mobileCredovaWrapper__103Mg {
  display: block;
  padding: 0 1.5rem;
  padding: 0 1.5rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.PackagePage_mobileCredovaWrapper__103Mg {
    display: none
}
  }

.PackagePage_mobileCredova__2-qFt {
  text-align: center;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionLandDetails_sectionLandDetails__uUlGu {
  position: relative;
}

.SectionLandDetails_sectionHeader__2syW_ {
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem
}

@media (min-width: 768px) {

.SectionLandDetails_sectionHeader__2syW_ {
    margin-bottom: 1.25rem;
    margin-bottom: 1.25rem
}
  }

.SectionLandDetails_landDetailsContent__3RT79 {
  position: relative;
  padding: 0 1.5rem 0;
  padding: 0 1.5rem 0
}

@media (min-width: 1024px) {

.SectionLandDetails_landDetailsContent__3RT79 {
    padding: 0 1.75rem 1rem 0;
    padding: 0 1.75rem 1rem 0
}

    .SectionLandDetails_landDetailsContent__3RT79 section:first-of-type div.SectionLandDetails_detailsTitle__288tm {
      margin-top: 0;
    }
  }

.SectionLandDetails_detailsConatiner__2kKbx {
  margin-bottom: 2.25rem;
  margin-bottom: 2.25rem
}

@media (min-width: 1024px) {

.SectionLandDetails_detailsConatiner__2kKbx {
    margin: 0 2.5rem 3rem 0;
    margin: 0 2.5rem 3rem 0
}
  }

.SectionLandDetails_detailsTitle__288tm {
  display: flex;
  align-items: flex-start;
  margin-top: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem
}

@media --screen-lg {

.SectionLandDetails_detailsTitle__288tm {
    margin-top: 2rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    margin-bottom: 0.5rem
}
  }

.SectionLandDetails_detailsTitleText__2jUL8 {
  font-weight: 700;
  font-weight: 700;
  font-size: 1rem;
  font-size: 1rem;
  padding-left: 0.75rem;
  padding-left: 0.75rem
}

@media (min-width: 768px) {

.SectionLandDetails_detailsTitleText__2jUL8 {
    font-size: 1rem;
    font-size: 1rem
}
  }

.SectionLandDetails_landDetailsShelterTitleText__3wv7r {
  font-weight: 700;
  font-weight: 700;
  font-size: 1rem;
  font-size: 1rem;
  padding-left: 0.5rem;
  padding-left: 0.5rem
}

@media (min-width: 768px) {

.SectionLandDetails_landDetailsShelterTitleText__3wv7r {
    font-size: 1rem;
    font-size: 1rem
}
  }

.SectionLandDetails_landDetailsTitleText__3O4fm {
  font-weight: 700;
  font-weight: 700;
  font-size: 1rem;
  font-size: 1rem;
  padding-left: 0.75rem;
  padding-left: 0.75rem
}

@media (min-width: 768px) {

.SectionLandDetails_landDetailsTitleText__3O4fm {
    font-size: 1rem;
    font-size: 1rem
}
  }

.SectionLandDetails_landDetailsWaterTitleText__46NaS {
  font-weight: 700;
  font-weight: 700;
  font-size: 1rem;
  font-size: 1rem;
  padding-left: 0.5rem;
  padding-left: 0.5rem
}

@media (min-width: 768px) {

.SectionLandDetails_landDetailsWaterTitleText__46NaS {
    font-size: 1rem;
    font-size: 1rem
}
  }

.SectionLandDetails_sources__2erxd {
  font-weight: 700;
  font-weight: 700;
  display: inline-block;
  padding-right: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.SectionLandDetails_sourcesInfo__2miqh {
  white-space: pre-line;
  margin-left: 2.25rem;
  margin-left: 2.25rem;
}

.SectionLandDetails_detailsContainer__AUy3K {
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 768px) {

.SectionLandDetails_detailsContainer__AUy3K {
    margin-bottom: 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionLodgingOptions_root__2RESz {
  padding: 0 1.5rem;
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem
}

@media (min-width: 768px) {

.SectionLodgingOptions_root__2RESz {
    padding: 0;
    margin-bottom: 1.5rem;
    margin-bottom: 1.5rem
}
  }

.SectionLodgingOptions_header__fs7N_ {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.SectionLodgingOptions_sectionDetails__3HSW_ {
  padding: 1rem 1.75rem 1rem 1rem;
  padding: 1rem 1.75rem 1rem 1rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
  border-radius: 10px;
  border-radius: 10px;
  border: solid 1px #e2e8f0;
  border: solid 1px #e2e8f0;
}

.SectionLodgingOptions_sectionHeader__2wXLg {
  margin-bottom: 0;
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-weight: 700;
}

.SectionLodgingOptions_sectionDetailsContainer__tVy1P {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

.SectionLodgingOptions_sectionDetailsContainer__tVy1P:first-of-type {
    margin-top: 1rem;
    margin-top: 1rem;
  }

.SectionLodgingOptions_sectionDetailsContainer__tVy1P:last-of-type {
    margin-bottom: 0;
  }

.SectionLodgingOptions_siteDetailsContainer__3sFqs {
  display: flex;
  flex-direction: column;
  margin-left: 2.25rem;
  margin-left: 2.25rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.SectionLodgingOptions_sectionDetailsContainerDisabled__8Y3RQ {
  opacity: 0.5;
}

.SectionLodgingOptions_sectionDetailsWrapper__1KSCc {
  display: flex;
  align-items: center;
}

.SectionLodgingOptions_sectionDetailsIcon__2AVP0 {
  margin-right: 0.5rem;
  margin-right: 0.5rem;
}

.SectionLodgingOptions_sectionDetailsContent__1Z2fF {
  display: flex;
  flex-direction: column;
}

.SectionLodgingOptions_sectionDetailsHeader__2gji0 {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

.SectionLodgingOptions_sectionDetailsInfo__k8e30 {
  margin-left: 2.25rem;
  margin-left: 2.25rem;
}

.SectionLodgingOptions_sectionDetailsInfoOptions__1_qe2 {
  margin-left: 2.25rem;
  margin-left: 2.25rem;
}

.SectionLodgingOptions_sectionListWrapper__3it6M {
  display: flex;
  flex-direction: column;
  grid-gap: 0 5rem;
  gap: 0 5rem;
  grid-gap: 0 5rem;
  gap: 0 5rem;
  margin-left: 2.25rem;
  margin-left: 2.25rem
}

@media (min-width: 768px) {

.SectionLodgingOptions_sectionListWrapper__3it6M {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 2.25rem;
    margin-left: 2.25rem
}
  }

.SectionLodgingOptions_sectionInfoWrapper__2cN-W {
  margin-left: 2.25rem;
  margin-left: 2.25rem;
}

.SectionLodgingOptions_formFieldWrapper__WlKau {
  max-width: 100%
}

@media (min-width: 768px) {

.SectionLodgingOptions_formFieldWrapper__WlKau {
    max-width: 14rem;
    max-width: 14rem;
    margin-top: 1.25rem;
    margin-top: 1.25rem;
    margin-left: 2.25rem;
    margin-left: 2.25rem
}
  }

.SectionLodgingOptions_buttonText__3gPED {
  padding: 0;
  font-size: 1rem;
  font-size: 1rem;
  font-weight: bold;
  white-space: nowrap;
}

@media (min-width: 768px) {

.SectionLodgingOptions_sectionListItem__1Uw7S {
    width: 33.333333%;
    width: 33.333333%
}
  }

.SectionLodgingOptions_siteListItem__1dWTS {
  font-size: 1rem;
  font-size: 1rem;
  margin-top: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  white-space: nowrap;
}

.SectionLodgingOptions_bedsList__1cb8H {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 2.25rem;
  margin-left: 2.25rem;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.SectionLodgingOptions_bedsList__1cb8H > span:not(:last-child)::after {
  content: ',';
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionHuntDetails_sectionHuntFishDetails__3YlKy {
  padding: 0;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
}

.SectionHuntDetails_huntFishDetailsTitle__qEax- {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
}

.SectionHuntDetails_detailsSubtitle__bBYhs {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.SectionHuntDetails_huntMethodsContainer__3dOib {
  display: block
}

@media (min-width: 768px) {

.SectionHuntDetails_huntMethodsContainer__3dOib {
    width: 100%;
    column-count: 2;
    justify-content: bottom;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem
}
  }

.SectionHuntDetails_huntingFishingIcons__3NWnC {
  width: 1.75rem;
  width: 1.75rem;
  color: var(--gray700);
  margin-right: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0;
}

.SectionHuntDetails_speciesIcon__g_GZG {
  width: 1.75rem;
  width: 1.75rem;
  color: var(--gray700);
  margin-right: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0;
}

.SectionHuntDetails_detailsGroupsContainer__2iTDt {
  display: flex;
  flex-flow: column;
}

.SectionHuntDetails_detailsGroup__1w7Mt {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem
}

@media (min-width: 768px) {

.SectionHuntDetails_detailsGroup__1w7Mt {
    margin-bottom: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 3.5rem;
    margin-right: 3.5rem;
    padding: 0
}
    .SectionHuntDetails_detailsGroup__1w7Mt:last-of-type {
      margin-bottom: 0;
    }
  }

.SectionHuntDetails_huntingMethodsDetailsGroup__2TGIV {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  margin-right: 3.5rem;
  margin-right: 3.5rem;
  padding: 0
}

.SectionHuntDetails_huntingMethodsDetailsGroup__2TGIV:last-of-type {
    margin-bottom: 0;
  }

.SectionHuntDetails_ammenitiesList__3pK64 {
  display: block
}

@media (min-width: 768px) {

.SectionHuntDetails_ammenitiesList__3pK64 {
    width: 100%;
    column-count: 2;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem
}
  }

@media (min-width: 1280px) {

.SectionHuntDetails_ammenitiesList__3pK64,
.SectionHuntDetails_huntMethodsContainer__3dOib,
.SectionHuntDetails_speciesContainer__b39kU {
    width: 80%;
    column-count: 2;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem
}
  }

.SectionHuntDetails_detail__343kV {
  page-break-inside: avoid;
  break-inside: avoid;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.SectionHuntDetails_info__19vsR {
  margin-left: 2.5rem;
  margin-left: 2.5rem;
}

.SectionHuntDetails_speciesContainer__b39kU {
  display: block
}

@media (min-width: 768px) {

.SectionHuntDetails_speciesContainer__b39kU {
    max-width: 100%;
    column-count: 2;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    grid-column-gap: 1rem;
    column-gap: 1rem
}
  }

.SectionHuntDetails_formFieldWrapper__7gc3c {
  max-width: 100%
}

@media (min-width: 768px) {

.SectionHuntDetails_formFieldWrapper__7gc3c {
    max-width: 14rem;
    max-width: 14rem;
    margin-top: 1.25rem;
    margin-top: 1.25rem
}
  }

.SectionHuntDetails_buttonText__xsc0b {
  padding: 0;
  font-size: 1rem;
  font-size: 1rem;
  font-weight: bold;
  white-space: nowrap;
}

.SectionHuntDetails_readMore__2uCtA {
  color: #1a202c;
  color: #1a202c;
  margin: 0;
  text-decoration: underline;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}

.SectionHuntDetails_readMore__2uCtA:hover {
  cursor: pointer;
  text-decoration: underline;
}

.SectionHuntDetails_modalDetails__uD8dG {
  white-space: pre-line;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.SectionHuntDetails_totalDivider__2MUlW {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;

  border: none;
  background-color: #edf2f7;
  background-color: #edf2f7;
}

.SectionHuntDetails_fishTypesPriceTitle__2z2Nb {
  font-weight: normal;
  font-size: 0.875rem;
  font-size: 0.875rem;
}

.SectionHuntDetails_modalContainer__1GkU0 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  padding: 1.5rem;
  background-color: #fff;
  background-color: #fff;
  border-radius: 5px;
  border-radius: 5px;
  border-bottom: none;
  flex-grow: 0;
  min-height: auto;
  height: auto;
  overflow: hidden;
  margin: 0 1.5rem;
  margin: 0 1.5rem;
  flex-basis: 18rem;
  flex-basis: 18rem;
}

.SectionHuntDetails_modalOverlay__1jrCg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.SectionHuntDetails_catchAndRelease__2sPuP {
  margin-bottom: 2rem;
  margin-bottom: 2rem
}

@media (min-width: 1024px) {

.SectionHuntDetails_catchAndRelease__2sPuP {
    margin-bottom: 2rem;
    margin-bottom: 2rem
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionOutdoorActivities_sectionDetails__X8tyz {
  padding: 0;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
}

.SectionOutdoorActivities_modalTitle__vWK7A {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
}

.SectionOutdoorActivities_detailsSubtitle__1Tk1E {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.SectionOutdoorActivities_icon__2aawf {
  width: 1.25rem;
  width: 1.25rem;
  color: var(--gray700);
  margin-right: 0.25rem;
  margin-right: 0.25rem;
}

.SectionOutdoorActivities_detailsGroupsContainer__1KHk9 {
  display: flex;
  flex-flow: column;
}

.SectionOutdoorActivities_detailsGroup__3j60T {
  margin-bottom: 2rem;
  margin-bottom: 2rem;
  margin-right: 3.5rem;
  margin-right: 3.5rem
}

.SectionOutdoorActivities_detailsGroup__3j60T:last-of-type {
    margin-bottom: 0;
  }

.SectionOutdoorActivities_activitiesContainer__3QO4v {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 768px) {

.SectionOutdoorActivities_activitiesContainer__3QO4v {
    flex-wrap: wrap;
    flex-direction: row
}
  }

.SectionOutdoorActivities_detail__sfR-B {
  page-break-inside: avoid;
  break-inside: avoid;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem
}

@media (min-width: 768px) {

.SectionOutdoorActivities_detail__sfR-B {
    width: 50%;
    width: 50%
}
  }

.SectionOutdoorActivities_detailLabel__1okII {
  font-weight: 700;
  font-weight: 700;
}

.SectionOutdoorActivities_detailInfo__3etrI {
  font-size: 0.875rem;
  font-size: 0.875rem;
  margin-left: 1.5rem;
  margin-left: 1.5rem;
}

.SectionOutdoorActivities_readMore__2QPlx {
  color: #1a202c;
  color: #1a202c;
  margin: 0;
  text-decoration: underline;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}

.SectionOutdoorActivities_readMore__2QPlx:hover {
  cursor: pointer;
  text-decoration: underline;
}

.SectionOutdoorActivities_outdoorActivitiesModal__95hdT {
  background-color: rgba(0, 0, 0, 0.4);
  justify-content: center
}

@media (min-width: 768px) {

.SectionOutdoorActivities_outdoorActivitiesModal__95hdT {
    align-items: center
}
  }

.SectionOutdoorActivities_outdoorActivitiesScrollModal__h_9cX {
  overflow: auto
}

@media (min-width: 768px) {

.SectionOutdoorActivities_outdoorActivitiesScrollModal__h_9cX {
    width: 50%
}
  }

.SectionOutdoorActivities_modalDetails__eWbdH {
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.SectionOutdoorActivities_modalDetailContainer__1BunS {
  display: flex;
  flex-direction: column;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.SectionOutdoorActivities_modalDetail__3Dcp_ {
  display: flex;
  align-items: center;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionWhatsIncluded_root__1aPV2 {
  color: #1a202c;
  color: #1a202c;
  padding: 0 1.5rem;
  padding: 0 1.5rem
}

@media (min-width: 768px) {

.SectionWhatsIncluded_root__1aPV2 {
    padding: 0
}
  }

.SectionWhatsIncluded_header__iV-qX {
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.SectionWhatsIncluded_totalDivider__1wcYK {
  height: 1px;
  margin: 1.5rem;
  margin: 1.5rem;
  border: none;
  background-color: #e2e8f0;
  background-color: #e2e8f0
}

@media (min-width: 768px) {

.SectionWhatsIncluded_totalDivider__1wcYK {
    margin: 1.5rem 0;
    margin: 1.5rem 0
}
  }

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionLodgingItems_root__3gwfa {
  padding: 0;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}

.SectionLodgingItems_detailsGroupsContainer__3_Dwx {
  display: flex;
  flex-flow: column;
}

.SectionLodgingItems_detailsGroup__Txv4a {
  margin-bottom: 2rem;
  margin-bottom: 2rem
}

.SectionLodgingItems_detailsGroup__Txv4a:last-of-type {
    margin-bottom: 0;
  }

.SectionLodgingItems_detailsSubtitle__hpPuP {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.SectionLodgingItems_header__2CS08 {
  margin-left: 1.25rem;
  margin-left: 1.25rem;
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

@media (min-width: 768px) {

.SectionLodgingItems_header__2CS08 {
    margin-left: 0
}
  }

.SectionLodgingItems_lodgingItemWrapper__cLmul {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-bottom: 1rem
}

.SectionLodgingItems_lodgingItemWrapper__cLmul:last-of-type {
    margin-bottom: 0;
  }

.SectionLodgingItems_lodgingItem__QJcvl {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.SectionLodgingItems_icon__2En_B {
  margin-right: 0.25rem;
  margin-right: 0.25rem;
  position: relative;
  right: 3px;
  height: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  width: 1.5rem;
}

.SectionLodgingItems_lodgingItemTitle__1tEIl {
  font-weight: bold;
}

.SectionLodgingItems_lodgingItemLink__3CXrP {
  text-decoration: underline;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

.SectionSeasonInfo_root__IogkR {
  color: #1a202c;
  color: #1a202c;
  padding: 0 1.5rem;
  padding: 0 1.5rem
}

@media (min-width: 768px) {

.SectionSeasonInfo_root__IogkR {
    padding: 0
}
  }

.SectionSeasonInfo_header__3CYjH {
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.SectionSeasonInfo_tabContainer__1yxq4 {
  color: #1a202c;
  color: #1a202c;
}

.SectionSeasonInfo_hdTabs__3O3m7 {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.SectionSeasonInfo_tabHeaders__13kQ7 {
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

.SectionSeasonInfo_hdTab__3_Rrt,
.SectionSeasonInfo_speciesPill__108x9,
.SectionSeasonInfo_hdTabInactive__1nCfG,
.SectionSeasonInfo_speciesPillInactive__Z2TpE {
  margin: 0.25rem 1rem 0.25rem 0;
  margin: 0.25rem 1rem 0.25rem 0;
  font-size: 0.75rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  min-width: 3.5rem;
  min-width: 3.5rem;
  padding: 0.5rem 0.75rem;
  padding: 0.5rem 0.75rem;
}

.SectionSeasonInfo_hdTabInactive__1nCfG,
.SectionSeasonInfo_speciesPillInactive__Z2TpE {
  border: 1px solid #e2e8f0;
  border: 1px solid #e2e8f0;
}

.SectionSeasonInfo_speciesPillContainer__2r2QR {
  margin-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.SectionSeasonInfo_speciesPills__1jWm9 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.SectionSeasonInfo_licenseTypeSelector__1e5Pg {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  width: 75%;
  width: 75%;
  border: solid 1px #cbd5e0;
  border: solid 1px #cbd5e0;
}

.SectionSeasonInfo_tagAvailabilitySection__NBT2J {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.SectionSeasonInfo_withTitle__v0zW1 {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.SectionSeasonInfo_titleHeader__E1dRa,
.SectionSeasonInfo_infoHeader__2liy2 {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 700;
  font-weight: 700;
}

.SectionSeasonInfo_infoHeader__2liy2 {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.SectionSeasonInfo_infoText__2kApk {
  margin: 0;
}

.SectionSeasonInfo_noInfoText__3DHWB {
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.SectionSeasonInfo_opportunity__2Uaxu {
  padding: 0 0.75rem 1.25rem 0;
  padding: 0 0.75rem 1.25rem 0;
}

.SectionSeasonInfo_opportunity__2Uaxu h4 {
  text-transform: capitalize;
}

.SectionSeasonInfo_opportunityDetails__3amdj {
  margin: 0 0 0.5rem 0;
  margin: 0 0 0.5rem 0;
  font-size: 0.875rem;
  font-size: 0.875rem;
  color: #718096;
  color: #718096;
}

.SectionSeasonInfo_seasonDates__3gxJZ {
  margin: 0;
}

.SectionSeasonInfo_seasonDate__rfFrS {
  margin-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}

.SectionSeasonInfo_dateTitle__3DnWX {
  margin-right: 0.75rem;
  margin-right: 0.75rem;
}

.SectionSeasonInfo_dateValue__1GSWc {
  margin-left: 0.5rem;
  margin-left: 0.5rem;
}

.SectionSeasonInfo_tabContent__14YvY {
  padding: 0.75rem 0 0 0;
  padding: 0.75rem 0 0 0;
}

.SectionSeasonInfo_specialTitleHeader__OMzPH {
  margin-top: 0.75rem;
  margin-top: 0.75rem;
}

/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */

/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */

/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */

*,
::before,
::after {
	background-repeat: no-repeat; /* 1 */
	box-sizing: inherit; /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */

::before,
::after {
	text-decoration: inherit; /* 1 */
	vertical-align: inherit; /* 2 */
}

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */

html {
	box-sizing: border-box; /* 1 */
	cursor: default; /* 2 */
	-ms-text-size-adjust: 100%; /* 3 */
	-webkit-text-size-adjust: 100%; /* 3 */
}

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */

/**
 * Add the correct display in IE 9-.
 */

article,
aside,
footer,
header,
nav,
section {
	display: block;
}

/**
 * Remove the margin in all browsers (opinionated).
 */

body {
	margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */

h1 {
	font-size: 2em;
	margin: .67em 0;
}

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */

/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */

figcaption,
figure,
main { /* 1 */
	display: block;
}

/**
 * Add the correct margin in IE 8.
 */

figure {
	margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
	box-sizing: content-box; /* 1 */
	height: 0; /* 1 */
	overflow: visible; /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */

nav ol,
nav ul {
	list-style: none;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
	font-family: monospace, monospace; /* 1 */
	font-size: 1em; /* 2 */
}

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */

/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */

a {
	background-color: transparent; /* 1 */
	-webkit-text-decoration-skip: objects; /* 2 */
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
	border-bottom: none; /* 1 */
	text-decoration: underline; /* 2 */
	-webkit-text-decoration: underline dotted;
	        text-decoration: underline dotted; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */

b,
strong {
	font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
	font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
	font-family: monospace, monospace; /* 1 */
	font-size: 1em; /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */

dfn {
	font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */

mark {
	background-color: #ffff00;
	color: #000000;
}

/**
 * Add the correct font size in all browsers.
 */

small {
	font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -.25em;
}

sup {
	top: -.5em;
}

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */

::selection {
	background-color: #b3d4fc; /* 1 */
	color: #000000; /* 1 */
	text-shadow: none;
}

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */

/*
 * Change the alignment on media elements in all browers (opinionated).
 */

audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */

audio,
video {
	display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
	display: none;
	height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */

img {
	border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

svg {
	fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
	overflow: hidden;
}

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */

/**
 * Collapse border spacing
 */

table {
	border-collapse: collapse;
}

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */

/**
 * Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
	margin: 0;
}

/**
 * Inherit styling in all browsers (opinionated).
 */

button,
input,
select,
textarea {
	background-color: transparent;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input { /* 1 */
	overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select { /* 1 */
	text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */

button,
html [type="button"], /* 1 */
[type="reset"],
[type="submit"] {
	-webkit-appearance: button; /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
	outline: 1px dotted ButtonText;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
	box-sizing: border-box; /* 1 */
	color: inherit; /* 2 */
	display: table; /* 1 */
	max-width: 100%; /* 1 */
	padding: 0; /* 3 */
	white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
	display: inline-block; /* 1 */
	vertical-align: baseline; /* 2 */
}

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */

textarea {
	overflow: auto; /* 1 */
	resize: vertical; /* 2 */
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */

[type="checkbox"],
[type="radio"] {
	box-sizing: border-box; /* 1 */
	padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
	-webkit-appearance: textfield; /* 1 */
	outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
	-webkit-appearance: button; /* 1 */
	font: inherit; /* 2 */
}

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */

/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */

details, /* 1 */
menu {
	display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
	display: list-item;
}

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */

/**
 * Add the correct display in IE 9-.
 */

canvas {
	display: inline-block;
}

/**
 * Add the correct display in IE.
 */

template {
	display: none;
}

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */

/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] { /* 1 */
	touch-action: manipulation;
}

/**
 * Add the correct display in IE 10-.
 */

[hidden] {
	display: none;
}

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */

/**
 * Change the cursor on busy elements (opinionated).
 */

[aria-busy="true"] {
	cursor: progress;
}

/*
 * Change the cursor on control elements (opinionated).
 */

[aria-controls] {
	cursor: pointer;
}

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */

[aria-hidden="false"][hidden]:not(:focus) {
	clip: rect(0, 0, 0, 0);
	display: inherit;
	position: absolute;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */

[aria-disabled] {
	cursor: default;
}

:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}

/* latin */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
    U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
    U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
    U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
    U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6FxZCJgg.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
    U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6FxZCJgg.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
    U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

 {
}

body {
  font-size: 1rem;
  font-size: 1rem;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  font-size: 16px;
}

::selection,
::-moz-selection {
  background: #3e704b;
  background: #3e704b;
  color: #fff;
  color: #fff;
}

*,
*:focus {
  outline: none;
}

a {
  color: #1a202c;
  color: #1a202c;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

h1 {
  font-family: 'Playfair Display', serif;
  font-family: 'Playfair Display', serif;
  font-size: 1.5rem;
  font-size: 1.5rem
}

@media (min-width: 768px) {

h1 {
    font-size: 2.25rem;
    font-size: 2.25rem
}
  }

h2 {
  font-size: 1.25rem;
  font-size: 1.25rem;
  font-weight: 600;
  font-weight: 600
}

@media (min-width: 768px) {

h2 {
    font-size: 1.875rem;
    font-size: 1.875rem
}
  }

h3 {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-weight: 600;
  font-weight: 600
}

@media (min-width: 768px) {

h3 {
    font-size: 1.5rem;
    font-size: 1.5rem
}
  }

h4 {
  font-size: 1rem;
  font-size: 1rem;
  font-weight: 600;
  font-weight: 600
}

@media (min-width: 768px) {

h4 {
    font-size: 1.25rem;
    font-size: 1.25rem
}
  }

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  border: 0;
  padding: 0;
  margin: 0;
}

legend,
label {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 600;
  font-weight: 600;
  color: #1a202c;
  color: #1a202c;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

input,
textarea {
  width: 100%;
  padding: 0.5rem 1rem;
  padding: 0.5rem 1rem;
  min-height: 50px;
  background-color: #fff;
  background-color: #fff;
  border: 2px #cbd5e0 solid;
  border: 2px #cbd5e0 solid;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  border-radius: 5px;
  color: #1a202c;
  color: #1a202c
}

input:focus, textarea:focus {
    border-color: #1a202c;
    border-color: #1a202c;
  }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #a1aec1;
    color: #a1aec1;
  }

input::placeholder, textarea::placeholder {
    color: #a1aec1;
    color: #a1aec1;
  }

select {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0.5rem 1rem;
  padding: 0.5rem 1rem;
  padding-right: 2rem;
  padding-right: 2rem;
  -webkit-appearance: none;
          appearance: none;
  border: 2px #cbd5e0 solid;
  border: 2px #cbd5e0 solid;
  border-radius: 5px;
  border-radius: 5px;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';

  background-color: #fff;

  background-color: #fff;
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 16px 16px;
  background-position-y: center;
  background-position-x: calc(100% - 16px);
  cursor: pointer
}

select:focus {
    border-color: #1a202c;
    border-color: #1a202c;
  }

/* NOTE: this file should slowly go away */
:root {
  /* Screen sizes */

  /* Colors */

  /* Typography */ /* 10px */ /* 12px */ /* 14px */ /* 16px */ /* 18px */ /* 20px */ /* 24px */ /* 30px */ /* 36px */ /* 48px */ /* 64px */

  /* Spacing */ /* 4px */ /* 8px */ /* 12px */ /* 16px */ /* 20px */ /* 24px */ /* 28px */ /* 32px */ /* 36px */ /* 40px */ /* 44px */ /* 48px */ /* 52px */ /* 56 */ /* 60 */ /* 64px */ /* 68px */ /* 72px */ /* 76px */ /* 80px */ /* 84px */ /* 88px */ /* 92px */ /* 96px */ /* 100px */ /* 104px */ /* 108px */ /* 112px */ /* 116px */ /* 120px */ /* 124px */ /* 128px */ /* 136px */ /* 144px */ /* 148px */ /* 152px */ /* 160px */ /* 168px */ /* 176px */ /* 184px */ /* 192px */ /* 200px */ /* 216px */ /* 224px */ /* 232px */ /* 240px */ /* 248px */ /* 256px */ /* 260px */ /* 268px */ /* 288px */ /* 292px */ /* 300px */ /* 306px */ /* 320px */ /* 336px */ /* 352px */ /* 372px */ /* 380px */ /* 384px */ /* 390px */ /* 394px */ /*400px */ /*412px */ /* 432px */ /* 448px */ /* 460px */ /* 480px */ /* 504px */ /* 508px */ /* 560px */ /* 568px */ /* 600px */ /* 604px */ /* 608px */ /* 640px */ /* 664px */ /* 700px */ /* 800px */ /* 1280px */

  /* Widths */

  /* Box Shadow */

  /* Opacity */

  /* Z-index */

  /* Border radius */

  /* Transitions */
}
/* Fonts */
/* These are imported into marketplace.css */
:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}
/* ================ Custom properties aka variables ================ */
:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

  /* Shadows */

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  /* small popups on UI should use z-indexes above 50 */
  /* modals and UI overlays should use z-indexes above 100 */
  /* generic error message should overlay even modals */

  /* ================ Border radius ================ */

  /* ================ Transition styles ================ */

  /* ================ Topbar heights ================ */

  /* ================ Modal default padding ================ */

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
}
/* ================ Custom media queries ================ */
html {
  visibility: visible;
  opacity: 1;
}

