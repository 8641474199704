@import '../../landtrust.css';

.root {
  display: flex;
  padding-bottom: 90px;

  @media (--screen-lg) {
    padding-bottom: 0;
  }
}

.mobileSecondaryWrapper {
  display: flex;
  align-items: baseline;

  & > button {
    margin-right: var(--s-2);
    height: var(--s-11);
    width: var(--s-11);

    & > svg {
      width: var(--s-6);
    }
  }
}

.sectionImagesContainer {
  position: relative;
  @media (--screen-md) {
    padding-bottom: var(--s-3);
  }
}

.listingInfoContainer {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--s-2);
}

@media (--screen-md) {
  .listingInfoContainer {
    flex-direction: column;
  }
}
.listingActions {
  display: flex;
  padding: var(--s-5) var(--s-6) 0 var(--s-6);

  @media (--screen-md) {
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-top: var(--s-8);
  }
}

.listingActionsContent {
  width: 100%;

  @media (--screen-md) {
    width: auto;
  }
}

.shareContainer {
  display: none;

  @media (--screen-lg) {
    display: flex;
  }
}

.share {
  display: none;

  @media (--screen-md) {
    display: flex;
  }
}

.shareButton {
  @media (--screen-md) {
    text-decoration: underline;
  }
}

.contentContainer {
  display: flex;
  position: relative;

  @media (--screen-lg) {
    margin: 0 auto var(--s-15);
    justify-content: space-between;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: var(--s-6);

  @media (--screen-lg) {
    flex-basis: 60%;
    margin-bottom: 0px;
    padding-right: var(--s-16);
  }
}

.totalDivider {
  height: 1px;
  margin: var(--s-6);
  border: none;
  background-color: var(--gray-300);

  @media (--screen-md) {
    margin: var(--s-6) 0;
  }
}

.activityTotalDivider {
  height: 1px;
  margin: var(--s-9) var(--s-5) var(--s-5) var(--s-5);
  border: none;
  background-color: var(--gray-300);

  @media (--screen-md) {
    display: none;
  }
}

.secondaryContent {
  @media (--screen-md) {
    flex-basis: 40%;
    position: relative;
    bottom: var(--s-23);
    padding-top: var(--s-6);
  }
}

.pricingPanel {
  display: none;

  @media (--screen-md) {
    position: sticky;
    top: var(--s-22);
    display: flex;
    flex-direction: column;
    bottom: var(--s-6);
    padding: var(--s-6) var(--s-6) var(--s-10) var(--s-6);
    border-radius: var(--border-radius-xl);
    box-shadow: var(--box-shadow);
    border: solid 1px var(--gray-300);
    background-color: var(--white);
    min-width: var(--s-80);
    height: fit-content;
    flex-basis: var(--w-2-5);
    flex-shrink: 1;
    flex-grow: 1;
  }
  @media (--screen-xl) {
    bottom: var(--s-38);
  }
}

.packageTitleWrapper {
  max-width: 100%;
  padding-bottom: var(--s-3);
  line-height: var(--line-height-none);

  @media (--screen-md) {
    display: flex;
    flex-direction: column;
    padding: 0;
    max-width: 780px;
    font-family: var(--font-base);
    margin-bottom: var(--s-3);
  }
}

.packageTitle {
  @apply --typography-heading-size-3xl;
  font-family: var(--font-base);
  font-weight: var(--font-bold);
  display: block;
}

.packageTitleListing {
  font-size: var(--text-sm);
  font-family: var(--font-base);
  font-weight: normal;
  line-height: var(--line-height-normal);

  @media (--screen-md) {
    font-size: var(--text-lg);
  }
}

.packageTitleListingLink {
  text-decoration: underline;
}

.packageBadge {
  background-color: var(--beige);
  padding: var(--s-1) var(--s-4);
}

.mobileCheckAvailabilityWrapper {
  z-index: var(--z-50);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: var(--s-5);
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;

  @media (--screen-md) {
    display: none;
  }
}

.mobileCheckAvailability {
  display: flex;

  @media (--screen-md) {
    display: none;
  }
}

.mobileCheckAvailabilityPrice {
  font-size: var(--text-sm);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.activityFeedContainer {
  margin-top: var(--s-12);
}

.priceText {
  font-size: var(--text-base);
  font-weight: bold;
}

.bookButton {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
}

.propertyVisitRoot {
  background-color: var(--brown-100);
}

.activityFeedRoot {
  background-color: inherit;

  @media (--screen-md) {
    background-color: var(--gray-100);
  }
}

.mobileBadges {
  @media (--screen-md) {
    display: none;
  }
}

.formFieldHeading {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
}

.divider {
  height: 1px;
  background-color: var(--gray-300);
  margin-top: var(--s-2);
  margin-bottom: var(--s-4);
}

.notifyButton {
  background-color: var(--gray-900);
  color: var(--white);
  margin-bottom: var(--s-2);
}

.icon {
  width: var(--s-4);
  height: var(--s-5);
}

.mobileCheckAvailabilityColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.getNotified {
  color: var(--gray-900);
  font-size: var(--text-sm);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-wrap: nowrap;
}

.hostBadgeWrapper {
  padding: 0 var(--s-3) 0;

  @media (--screen-md) {
    padding: 0;

    margin-top: var(--s-6);
    margin-bottom: var(--s-6);
  }
}

.availabilityBadgesWrapper {
  margin: var(--s-4) 0 var(--s-1) 0;
  padding: var(--s-3);

  @media (--screen-md) {
    display: none;
  }
}

.sectionCountdownWrapper {
  padding: 0 var(--s-6);
  margin-bottom: var(--s-8);

  @media (--screen-md) {
    padding: 0;
    margin-bottom: 0;
  }
}

.packageAvailableTimer {
  display: flex;
  justify-content: center;
  margin: var(--s-3) 0;
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
}

.sectionCountdown {
  display: flex;
  flex-direction: column;

  @media (--screen-md) {
    margin-bottom: var(--s-8);
  }
}

.mobileCredovaWrapper {
  display: block;
  padding: 0 var(--s-6);
  margin-bottom: var(--s-5);

  @media (--screen-md) {
    display: none;
  }
}

.mobileCredova {
  text-align: center;
}
