@import '../../landtrust.css';

.wrapper {
  display: flex;
  flex-direction: column;
}

.platformLogos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--s-6);
}

.platformLogo {
  max-height: 100%;
  object-fit: contain;
}

.platformAirBnB {
  height: var(--s-8);
}

.platformVrbo {
  height: var(--s-5);
}

.platformHomeAway {
  height: var(--s-6);
}

.title {
  font-family: var(--font-base);
  font-weight: var(--font-medium);
  margin-bottom: var(--s-6);
  font-size: 22px;
}

.content {
  font-size: var(--text-lg);
  margin: 0;
}

.phoneColumn {
  overflow: hidden;
  max-height: 350px;
  margin-top: var(--s-12);
}

.phoneImage {
  max-width: 100%;
}

@media (--screen-lg) {
  .wrapper {
    flex-direction: row;
  }

  .platformLogos {
    justify-content: left;
  }

  .platformLogo {
    margin-right: var(--s-6);
  }

  .phoneColumn {
    width: var(--w-2-5);
    max-height: 640px;
    order: 0;
  }

  .phoneImage {
    max-width: 500px;
  }

  .contentColumn {
    width: var(--w-1-2);
    padding-top: var(--s-40);
    padding-left: var(--s-32);
    padding-right: var(--s-8);
    order: 1;
  }

  .title {
    font-size: var(--text-3xl);
    font-weight: bold;
  }
}
