@import '../../landtrust.css';

.root {
  position: relative;
  background: var(--hunter);
  padding: var(--s-4) var(--s-5);
  color: var(--white);
  line-height: var(--line-height-tight);

  @media (--screen-md) {
    display: none;
  }
}

.cta {
  color: var(--white);
  font-weight: bold;
  text-decoration: underline;
}

.close {
  position: absolute;
  top: var(--s-4);
  right: var(--s-4);
}
