@import '../../marketplace.css';

.section {
  margin-bottom: var(--s-18);
  padding: 0 var(--s-8);

  &:first-child {
    margin-top: var(--s-18);
  }

  @media (--screen-md) {
    padding: 0;
  }
}

.sectionFullWidth {
  padding: 0;
}

.sectionMarginless {
  margin-bottom: 0;
}
