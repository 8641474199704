@import '../../marketplace.css';
@import '../../landtrust.css';

.root {
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: var(--s-12);
  margin-bottom: var(--s-4);
}

.prev,
.next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--s-12);
  height: var(--s-12);
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.prev {
  margin-right: var(--s-4);
}

.next {
  margin-left: var(--s-4);
}

.arrowIcon {
  /* Color for svg icons */
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);

  &:hover {
    fill: var(--marketplaceColorDark);
    stroke: var(--marketplaceColorDark);
  }
}

.pageNumberList {
  display: flex;
}

.toPageLink {
  color: var(--font-color);
  font-size: var(--text-lg);
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--s-10);
  min-width: var(--s-10);

  &:hover {
    text-decoration: none;
  }
}

.currentPage {
  color: var(--brand);
  font-weight: bold;
}

.paginationGapWrapper {
  composes: toPageLink;
}

.notCurrentPage,
.paginationGapWrapper {
  display: flex;
}

.paginationGap {
  transform: translateY(-20%);
}

.text {
  font-size: var(--text-sm);
  color: var(--gray-700);
  font-weight: bold;
  text-align: center;
}
