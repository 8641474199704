@import '../../landtrust.css';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--s-6);
}

.showAllLink {
  font-weight: var(--font-medium);
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.showAllMobileButtonContainer {
  text-align: center;
}

.showAllMobileButton {
  @media (--screen-md) {
    display: none;
  }
}

.carousel {
  padding: var(--s-4) 0;
  margin: 0 calc(-1 * var(--s-2));
}

.step {
  padding: var(--s-2);

  @media (--screen-md) {
    flex-basis: var(--w-1-4);
  }
}
