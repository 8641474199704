@import '../../marketplace.css';
@import '../../landtrust.css';

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--screen-lg) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImagesContainer {
  position: relative;
  padding-bottom: 0;
}

.sectionHeadingContainer {
  margin-bottom: var(--s-2);

  @media (--screen-md) {
    margin-bottom: var(--s-4);
  }
}

.desktopLayout {
  display: flex;
  flex-direction: column;
}

.desktopLayout .sectionHeadingContainer {
  @media (--screen-md) {
    order: 2;
  }
}

.desktopLayout .sectionKeyListingInfo {
  @media (--screen-md) {
    order: 1;
  }
}

.sectionImages {
  position: relative;

  @media (--screen-lg) {
    margin-top: var(--s-6);
  }
}

.gridPhotos {
  display: none;
  position: relative;
  height: calc(0.41 * 100vw);
  max-height: min(50vh, var(--s-165));

  @media (--screen-md) {
    display: block;
  }
}

.collage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: var(--s-2);
  background-color: var(--white);
}

.collage1Photo {
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.collage2Photos {
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.collage3Photos {
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.collage4Photos {
  grid-template-columns: minmax(0, 0.25fr) minmax(0, 0.25fr) minmax(0, 0.5fr);
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.collage4PhotosItem {
  &:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

  &:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }

  &:nth-child(3) {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }

  &:nth-child(4) {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }
}

.collage5PlusPhotos {
  grid-template-columns: minmax(0, 0.5fr) minmax(0, 0.25fr) minmax(0, 0.25fr);
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.collage5PlusPhotosItem {
  &:nth-child(1) {
    grid-column: 1;
    grid-row: 1 / 3;
  }

  &:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  }

  &:nth-child(3) {
    grid-column: 2;
    grid-row: 2;
  }

  &:nth-child(4) {
    position: relative;
    grid-column: 3;
    grid-row: 1;
  }

  &:nth-child(5) {
    position: relative;
    grid-column: 3;
    grid-row: 2;
  }
}

.collageImage {
  width: 100%;
  height: 100%;
  background-color: var(--matterColorNegative); /* Loading BG color */
  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
  object-fit: cover;
  transition: var(--transitionStyleButton);
  border-radius: var(--border-radius);

  &:hover {
    transform: scale(1.005);
    box-shadow: var(--boxShadowListingCard);
  }
}

.carousel {
  @media (--screen-md) {
    display: none;
  }
}

.step {
  position: relative;
  flex-basis: 100%;
  margin-right: var(--s-4);

  @media (--screen-md) {
    flex-basis: 100%;
  }

  /* Do the aspect ratio trick in a psuedo-element because it doesn't work in a flex child */
  &::after {
    content: '';
    display: block;
    padding-bottom: 66.6667%;
    height: 0;
  }
}

.carouselResponsiveImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */
  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--screen-md) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--screen-md) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--screen-md) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.share {
  display: none;

  @media (--screen-md) {
    display: flex;
  }
}

.shareButton {
  @media (--screen-md) {
    text-decoration: underline;
  }
}

.viewImagesWrapper {
  display: none;

  @media (--screen-lg) {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.viewPhotosBlurWrapper {
  display: none;

  @media (--screen-lg) {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.viewPhotosBlur {
  display: none;
  @media (--screen-lg) {
    background: url('./rectangle.png');
    background-color: rgba(21, 21, 21, 0.6);
    -webkit-filter: blur(43px);
    filter: blur(43px);
    width: var(--s-48);
    height: var(--s-16);
    display: block;
  }
}

.viewPhotos {
  display: none;

  @media (--screen-lg) {
    @apply --typography-heading-size-3xl;
    width: 100%;
    transition: var(--transitionStyleButton);
    color: var(--white);
    font-weight: var(--font-bold);
    display: block;
    z-index: var(--z-10);
  }
}

.viewPhotosText {
  line-height: var(--line-height-medium);
  font-size: var(--text-4xl);
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
  padding: var(--s-6);
}

.contentContainer {
  display: flex;
  position: relative;

  @media (--screen-lg) {
    margin: 0 auto var(--s-15);
    justify-content: space-between;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: var(--s-6);
  width: 100%;

  @media (--screen-md) {
    width: auto;
    margin-bottom: var(--s-13);
  }

  @media (--screen-lg) {
    flex-basis: 60%;
    margin-bottom: 0px;
    padding-right: var(--s-6);
  }
}

.secondaryContent {
  @media (--screen-lg) {
    flex-basis: 40%;
  }
}

.bookingPanel {
  @media (--screen-lg) {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: var(--s-25);
    padding: var(--s-7) var(--s-5) var(--s-12) var(--s-7);
    border-radius: var(--border-radius-xl);
    box-shadow: var(--box-shadow);
    border: solid 1px var(--gray-300);
    background-color: var(--white);
    padding-left: var(--s-8);
    min-width: var(--s-80);
    height: fit-content;
    flex-basis: var(--w-2-5);
    flex-shrink: 1;
    flex-grow: 1;
  }
}

.panelHost {
  display: none;

  @media (--screen-lg) {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: var(--s-6);
    padding: var(--s-12) var(--s-7) var(--s-12) var(--s-8);
    border-radius: var(--border-radius-xl);
    box-shadow: var(--box-shadow);
    border: none;
    background-color: var(--white);
    min-width: var(--s-80);
    height: fit-content;
    flex-basis: var(--w-2-5);
    flex-shrink: 1;
    flex-grow: 1;
  }
  @media (--screen-xl) {
    bottom: var(--s-19);
  }
}

.desktopPriceContainer {
  display: none;

  @media (--screen-lg) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    padding: 0;
  }
}

.desktopPriceValue {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);

  @media (--screen-md) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--screen-md) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.headingContainer {
  @media (--screen-md) {
    margin-bottom: var(--s-1);
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--screen-md) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  @apply --typography-heading-size-3xl;
  font-family: var(--font-base);
  font-weight: var(--font-bold);
  color: var(--gray-900);
}

.descriptionHeader {
  @apply --typography-heading-size-3xl;
  font-family: var(--font-base);
  font-weight: bold;
  color: var(--gray-900);
  display: flex;

  @media (--screen-lg) {
    margin: 0 0 var(--s-1);
  }
}

.subTitle {
  @apply --typography-heading-size-2xl;
  font-size: var(--text-base);
  color: var(--gray-900);
  font-weight: normal;
}

.sectionHeadingDesktop {
  display: none;
  @media (--screen-lg) {
    display: block;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--screen-md) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: inline;

  @media (--screen-md) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0;
  font-weight: bold;
}

.closeButton {
  position: relative;
  top: var(--s-1);
  right: var(--s-6);
  width: var(--s-10);
  height: var(--s-10);
  padding: var(--s-2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: var(--line-height-none);
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
  z-index: 1;

  @media (--screen-md) {
    margin-bottom: var(--s-3);
    margin-top: var(--s-2);
    top: var(--s-4);
    width: var(--s-8);
    height: var(--s-8);
  }
}

.modalContent {
  height: 100vh;
  margin-bottom: var(--s-6);
  overflow-y: auto;

  @media (--screen-md) {
    max-height: 60vh;
    margin-bottom: 0;
  }
}

.modalContent a {
  text-decoration: underline;
}

.containerStyles {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: var(--s-230);
  padding: var(--s-12) var(--s-6) 0 var(--s-6);
  border: 2px solid transparent;
  background-color: var(--white);
  flex-grow: 0;
  min-width: 100vw;
  height: 100%;
  overflow: hidden;

  @media (--screen-lg) {
    height: auto;
    border-radius: 12px;
    padding: var(--s-6);
    min-width: var(--w-2-5);
  }
}

.contentWrapper {
  padding-top: var(--s-9);
}

.contentHeader {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
}

.sectionDescription {
  padding: var(--s-3) var(--s-4) var(--s-4) var(--s-6);
  margin-bottom: 0;

  @media (--screen-md) {
    margin-bottom: var(--s-6);
    padding: 0;
  }
}

.descriptionContent {
  max-height: 174px;
  overflow: hidden;
  position: relative;

  & a {
    color: var(--black);
    text-decoration: underline;
  }
  & ul {
    margin: var(--s-4) 0;
    padding-left: var(--s-10);
    list-style-type: disc;
  }
  > div > p {
    margin-top: 0;
  }
}

.showMoreButton {
  cursor: pointer;
  font-size: var(--text-base);
  margin: 0;
  text-decoration: underline;
  color: var(--gray-900);
  position: absolute;
  bottom: 0;
}

.showMoreContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: var(--s-30) 0 0;
  background: linear-gradient(to bottom, transparent, white 80%);
  text-align: left;
}

.descriptionTitle {
  @apply --typography-heading-base;
  @apply --typography-heading-size-2xl;
  color: var(--gray-900);
  margin-top: 0;
  margin-bottom: var(--s-4);

  @media (--screen-md) {
    margin-top: 0;
    margin-bottom: var(--s-5);
  }
}

.descriptionShowMoreLink {
  display: block;
  font-weight: var(--font-medium);
  margin: var(--s-2) 0 var(--s-2);

  /* The only way of overriding the link styles with !important */
  color: var(--red-500) !important;
  text-decoration: none !important;
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--screen-md) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--ltForegroundColor);
  margin-top: 0;
  margin-bottom: 16px;

  @media (--screen-md) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.genericListSection {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--screen-md) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.genericListTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--ltForegroundColor);
  margin-top: 0;
  margin-bottom: 16px;

  @media (--screen-md) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.readMore {
  @apply --typography-base;
  color: var(--gray-900);
  font-weight: var(--font-medium);
  margin: 0 0 var(--s-5);
  @media (--screen-lg) {
    display: none;
  }
}

.descriptors {
  font-size: var(--s-3);
}

.descriptorTitle {
  font-weight: var(--font-medium);
  display: inline;
}

.sectionMap {
  padding: 0 var(--s-6);
  margin-bottom: var(--s-9);

  @media (--screen-md) {
    padding: 0;
    margin-bottom: 0;
    margin-top: var(--s-12);
  }
}

.locationTitle {
  margin-bottom: 20px;

  @media (--screen-md) {
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 0;
  margin-bottom: var(--s-1);

  @media (--screen-md) {
    margin-bottom: var(--s-8);
    padding: 0;
  }
}

.reviewsHeading {
  margin: 52px 0 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--brown-100);
  padding: 30px 0;

  @media (--screen-md) {
    border-radius: 8px;
    margin: 53px 0 27px 0;
  }
}

.reviewRating {
  font-size: var(--text-3xl);
  font-weight: bold;
}

.reviewsOverall {
  font-size: var(--text-xs);
  font-weight: normal;
  color: var(--gray-900);
}

.starIcon {
  margin: 0 var(--s-2) 0 var(--s-2);
  height: var(--s-4);
  width: var(--s-4);
}

.reviewsList {
  padding: 0 var(--s-5);

  @media (--screen-md) {
    padding: 0;
  }
}

.sectionHost {
  padding: 0 var(--s-6);
  margin-bottom: var(--s-8);

  @media (--screen-lg) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
  }
}

.yourHostHeading {
  margin: 54px 0 23px 0;

  @media (--screen-md) {
    margin: 53px 0 27px 0;
  }

  @media (--screen-lg) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--screen-md) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--screen-md) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.mapWrapper {
  padding-top: var(--s-5);
  padding-bottom: var(--s-5);
}

.map {
  height: var(--s-58);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--screen-md) {
    height: 75vh;
  }

  @media (--screen-lg) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.activityFeedContainer {
  margin-top: var(--s-3);

  @media (--screen-md) {
    margin-top: var(--s-6);
  }
}

.sectionHeader {
  font-size: var(--text-2xl);
  font-family: var(--font-base);
  font-weight: bold;
  color: var(--gray-900);
}

.sectionSubHeading {
  font-size: var(--text-xl);
  font-family: var(--font-base);
  font-weight: bold;
  color: var(--gray-900);
  display: flex;
  @media (--screen-lg) {
    margin: 0 0 var(--s-1);
  }
}

.logingCardsWrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: var(--s-4);

  @media (--screen-md) {
    margin-top: var(--s-5);
  }
}

.landownerHeader {
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);
}

.amenitiesHeader {
  font-weight: var(--font-bold);
  margin-top: var(--s-9);
  margin-bottom: var(--s-3);
}

.amenitiesWrapper {
  display: flex;
  flex-direction: column;

  @media (--screen-md) {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.amenitiesModalWrapper {
  display: flex;
  flex-direction: column;
  width: var(--w-1-2);

  @media (--screen-md) {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.amenitiesItem {
  margin-bottom: var(--s-2);

  @media (--screen-md) {
    width: var(--w-1-3);
  }
}

.amenitiesModalHeader {
  margin-bottom: var(--s-3);
  font-weight: var(--font-bold);
}

.amenitiesModalSectionHeader {
  margin-bottom: var(--s-2);
  font-weight: var(--font-bold);
}

.amenitiesModalItem {
  margin-bottom: var(--s-2);

  @media (--screen-md) {
    width: var(--w-1-2);
  }
}

.rulesList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: var(--s-4);

  @media (--screen-md) {
    margin-top: var(--s-5);
    margin-bottom: var(--s-3);
  }

  @media (--screen-lg) {
    max-height: var(--s-60);
  }
}

.rulesListItem {
  margin-bottom: var(--s-2);
}

.detailsQuestionWrapper {
  margin-top: var(--s-11);
  @media (--screen-md) {
    margin-top: var(--s-8);
  }
}

.tooltipHeader {
  display: flex;
  align-items: center;
  font-size: var(--text-base);
  color: var(--gray-900);
  font-weight: bold;
  margin-bottom: var(--s-4);
}

.tooltipHeaderIcon {
  margin-right: var(--s-2);
}

.tooltipContent {
  max-width: var(--s-64);

  @media (--screen-md) {
    max-width: var(--s-76);
  }
}

.favoriteWrapper {
  display: none;

  @media (--screen-md) {
    display: block;
    position: absolute;
    top: var(--s-4);
    right: var(--s-4);

    & > button {
      height: var(--s-11);
      width: var(--s-11);

      & > svg {
        width: var(--s-6);
      }
    }
  }

  @media (--screen-lg) {
    top: var(--s-10);
  }
}

.mobileSecondaryWrapper {
  display: flex;
  align-items: baseline;

  & > button {
    margin-right: var(--s-2);
    height: var(--s-11);
    width: var(--s-11);

    & > svg {
      width: var(--s-6);
    }
  }
}

.helpfulResources {
  padding: 0 var(--s-6);
  margin-bottom: var(--s-8);

  @media (--screen-md) {
    padding: var(--s-5);
    border-radius: var(--border-radius-xl);
    border: solid 1px var(--gray-300);
  }
}

.genericSection {
  padding: 0 var(--s-6);
  margin-bottom: var(--s-8);

  @media (--screen-md) {
    padding: 0;
    margin-bottom: var(--s-10);
  }
}

.linkContainer {
  margin-top: var(--s-2);
  gap: var(--s-2);
  @media (--screen-md) {
    display: flex;
    flex-direction: column;
    margin-top: var(--s-2);
    gap: var(--s-2);
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.linkDecor {
  color: var(--black);
  text-decoration: underline;
}

.locationLinkDecor {
  color: var(--black);
  font-size: var(--text-sm);

  @media (--screen-md) {
    font-size: var(--text-base);
  }
}

.links {
  margin: var(--s-2) 0;
  @media (--screen-md) {
    margin: 0;
  }
}

.favoritesSection {
  font-weight: bold;
  border-radius: var(--border-radius-xl);
  box-shadow: var(--box-shadow);
  border: dashed 1px var(--gray-400);
  padding: var(--s-6) var(--s-2) var(--s-7) var(--s-3);

  @media (--screen-md) {
    padding: var(--s-6) var(--s-4) var(--s-7) var(--s-4);
  }
}

.favoriteIconWrapper {
  display: flex;
  align-items: center;

  & > button {
    width: auto;
    margin-right: var(--s-4);

    & > svg {
      width: var(--s-6);
      color: var(--red-500);
    }
  }
}

.listingActions {
  display: flex;
  padding: var(--s-5) var(--s-6) 0 var(--s-6);

  @media (--screen-md) {
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-top: var(--s-8);
  }
}

.shareContainer {
  display: none;

  @media (--screen-lg) {
    display: flex;
  }
}

.headerReviewsContainer {
  font-size: var(--text-sm);
  display: flex;
  margin-bottom: 0;
  align-items: center;

  @media (--screen-md) {
    font-size: var(--text-base);
  }
}

.headerReviewLoader {
  height: var(--s-6);
}

.keyInfoIcon {
  display: flex;
  margin-right: var(--s-2);
}
.listingRating {
  color: var(--gray-900);
  display: flex;
  font-size: var(--text-sm);
  border-radius: var(--border-radius);
  white-space: nowrap;
  align-items: center;
  margin-left: var(--s-1);

  @media (--screen-md) {
    font-size: var(--text-base);
  }
}

.listingRating a {
  text-decoration: underline;
  color: var(--gray-900);
}

.ratingSpan {
  display: flex;
  color: var(--gray-900);
}
.infoSeparator {
  margin: 0 var(--s-2);
}

.buttonWrapper {
  margin-left: var(--s-6);
  padding: 0;
  border: none;
  font-weight: normal;
  display: inline-block;
  cursor: pointer;
  font-family: var(--font-base);
  font-size: var(--text-sm);
  text-decoration: underline;

  @media (--screen-md) {
    margin-left: 0;
  }
}

.showButtons {
  font-family: var(--font-base);
  text-decoration: underline;
  font-size: var(--text-base);
  cursor: pointer;
  margin-top: var(--s-1);
  margin-bottom: var(--s-5);
}
.panelHostMobile {
  display: contents;

  @media (--screen-lg) {
    display: none;
  }
}

.sectionActivities {
  margin-top: var(--s-8);

  @media (--screen-md) {
    margin-top: var(--s-5);
  }
}

.totalDivider {
  height: 1px;
  margin: var(--s-6);
  border: none;
  background-color: var(--gray-300);

  @media (--screen-lg) {
    display: none;
  }
}

.sectionSubHeader {
  font-size: var(--text-lg);
  font-weight: bold;
}

.sectionDetailsIcon {
  margin-right: var(--s-1);
}

.sectionValues {
  font-weight: bold;
  margin-top: var(--s-2);
  font-size: var(--s-4);
}

.modalContentContainer {
  height: 100%;
}

.amenitiesModalBtn {
  @apply --typography-base;
  border: none;
  padding: 0;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  margin-top: var(--s-3);
}

.policiesModalBtn {
  @apply --typography-base;
  border: none;
  padding: 0;
  background: none;
  text-decoration: underline;
  cursor: pointer;
}

.amenitiesModalContainer {
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--s-6);
  background-color: var(--white);
  border-bottom: none;
  flex-grow: 0;
  min-height: auto;
  border-radius: 0;
  overflow: scroll;

  @media (--screen-md) {
    margin: auto;
    width: var(--w-1-2);
    height: auto;
    border-radius: var(--border-radius);
  }
}

.amenitiesModalContent {
  padding: var(--s-3);
}

.amenitiesModalInfo {
  margin-top: var(--s-1);
}

.amenitiesModalHeader {
  margin-bottom: var(--s-3);
  font-weight: var(--font-bold);
}

.amenitiesModalSectionHeader {
  margin-bottom: var(--s-2);
  font-weight: var(--font-bold);
}

.amenitiesModalItem {
  margin-bottom: var(--s-2);

  @media (--screen-md) {
    width: var(--w-1-2);
  }
}

.badgesWrapper {
  display: flex;
}

.badge {
  background-color: var(--beige);
  padding: var(--s-2) var(--s-4);
  gap: var(--s-3);
  border-radius: var(--border-radius);
}

.propertyVisitRoot {
  background-color: var(--brown-100);
}

.activityFeedRoot {
  background-color: inherit;
  @media (--screen-md) {
    background-color: var(--gray-100);
  }
}

.additionalLodging {
  display: flex;
  flex-direction: column;
  margin-top: var(--s-4);
}

.policiesContainer {
  display: flex;
  flex-direction: column;
  margin-top: var(--s-2);

  @media (--screen-md) {
    flex-wrap: wrap;
    max-height: var(--s-25);
  }
}

.allows {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: var(--s-4);
}

.reviewPhotos {
  display: flex;
  flex-flow: row wrap;
  gap: var(--s-3);
  margin-bottom: var(--s-3);
}

.reviewPhotoWrapper {
  border-radius: var(--border-radius);
  width: calc(33% - 8px);
  position: relative;
}

.reviewPhotoWrapperOne {
  width: 100%;
}

.reviewPhotoWrapperTwo {
  width: calc(50% - 8px);
}

.reviewPhoto {
  border-radius: var(--border-radius);
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.reviewStars {
  margin-bottom: var(--s-2);
}

.reviewRatingStar {
  height: var(--s-6);
  width: var(--s-6);
}

.reviewImagesWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reviewPhotosBlurWrapper {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reviewPhotosBlur {
  background: url('./rectangle.png');
  background-color: rgba(21, 21, 21, 0.6);
  -webkit-filter: blur(43px);
  filter: blur(43px);
  width: var(--s-48);
  height: var(--s-16);
  display: block;
}

.reviewPhotosButton {
  @apply --typography-heading-size-3xl;
  width: 100%;
  transition: var(--transitionStyleButton);
  color: var(--white);
  font-weight: var(--font-bold);
  display: block;
  z-index: var(--z-10);
}

.reviewLoadingWrapper {
  margin-top: var(--s-8);
}

.reviewLoadingHeader {
  display: flex;
  gap: var(--s-4);
  margin-bottom: var(--s-3);
}

.reviewLoadingContent {
}
