@import '../../landtrust.css';

.root {
  position: relative;
  color: var(--gray-900);
}

.titleContainer {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-1);
}

.subtitleContainer {
  font-size: var(--text-sm);
  margin-bottom: var(--s-3);
}

.insuranceItemWrapper {
  display: flex;
  margin-bottom: var(--s-5);
}

.insuranceIcon {
  width: var(--s-6);
  margin-right: var(--s-2);
  color: var(--gray-500);
  overflow: visible !important;
}

.insuranceItem {
  display: flex;
}

.declinedWrapper {
  display: flex;
  flex-direction: column;
}

.cancellationLink {
  margin: var(--s-4) 0;
  color: var(--gray-900);
  text-decoration: underline;
}
