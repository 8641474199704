@import '../../marketplace.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.textAreaContainer {
  width: 100%;
  padding: var(--s-2) var(--s-4);
  min-height: 50px;
  background-color: var(--white);
  border: 2px var(--gray-400) solid;
  font-family: var(--font-base);
  font-size: var(--text-base);
  border-radius: var(--border-radius);
  color: var(--font-color);

  &.textAreaFocused {
    border-color: var(--gray-900);
  }

  &.inputError {
    border-color: var(--red-500);
  }
}

.textarea {
  border: none;
  resize: none;
  padding: 0;
  min-height: var(--s-38);

  @media (--viewportMedium) {
    min-height: var(--s-12);
  }
}

.submitContainer {
  display: flex;
  flex-direction: column;
}

.submit {
  align-self: flex-end;
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 26px 24px 0 0;
}

.error {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.verificationContainer {
  display: flex;
  align-items: flex-start;
  font-size: var(--text-sm);
  margin-top: var(--s-3);
}

.verificationIconContainer {
  margin-right: var(--s-2);
}

.verifySectionLink {
  font-weight: var(--font-medium);
  text-decoration: underline;
}

.characterCounter {
  text-align: left;
  font-size: var(--text-sm);
  color: var(--gray-600);
  margin-top: var(--s-1);
  padding-left: var(--s-1);
}
