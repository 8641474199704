@import '../../landtrust.css';
@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;
}

/* Dummy Topbar */

.topbar {
  width: 100%;
  height: var(--s-18);
  background-color: var(--white);
  box-shadow: var(--box-shadow-xl);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--screen-md) {
    justify-content: left;
    padding: 0 var(--s-17);
  }
}

.logoMobile {
  display: block;

  @media (--screen-md) {
    display: none;
  }
}

.logoDesktop {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

/* /Dummy Topbar */

/**
 * Content: mobile flow vs desktop 2 columns
 * desktop has reduced version of bookListingContainer (incl. title and Stripe paymentForm).
 * priceBreakdown is inside separate column (detailsContainerDesktop)
 */
.contentContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--s-25);
  padding: var(--s-5);

  @media (--screen-md) {
    width: 100%;
    max-width: var(--s-320);
    flex-direction: row;
    margin: var(--s-2) auto var(--s-7) auto;
  }
}

.bookListingContainer {
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--screen-md) {
    flex-basis: 62%;
    padding: var(--s-5) var(--s-32) var(--s-5) var(--s-5);
  }
}

.aspectWrapper {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  @media (--viewportLarge) {
    display: none;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Layout */
  display: block;

  @media (--viewportLarge) {
    margin-left: 48px;
  }
}

.avatarMobile {
  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  margin-bottom: var(--s-8);
}

.listingButton {
  width: 35%;
  margin-bottom: 1em;
  margin-top: 1em;
}

.listingText {
  text-decoration: underline;
}

.listingText :hover,
.backToListing :hover {
  color: var(--failColor);
}

.title {
  font-family: var(--font-base);
  width: 100%;
  margin-top: 0;
  margin-bottom: var(--s-2);
  color: var(--black);
  @media (--viewportMedium) {
    margin-top: var(--s-7);
    margin-bottom: var(--s-1);
  }
}

.author {
  font-size: var(--text-sm);

  @media (--screen-md) {
    display: none;
  }
}

.totalDivider {
  height: 1px;
  margin-bottom: var(--s-4);
  border: none;
  background-color: var(--gray-300);

  @media (--screen-md) {
    display: none;
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 37px;

  @media (--viewportMedium) {
    margin-bottom: 38px;
  }
  @media (--viewportLarge) {
    /* we don't show price breakdown since it will be visible within 2nd column (detailsContainerDesktop) */
    display: none;
  }
}

.priceBreakdownTitle {
  font-size: var(--text-base);
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 18px;
  }
  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 14px;
  }
}

.priceBreakdownSubtitle {
  font-size: var(--text-xs);
  margin-top: 0;
  margin-bottom: var(--s-4);
}

.priceBreakdownLoading {
  font-size: var(--text-xs);
  margin-top: 0;
  margin-bottom: var(--s-4);
}

.paymentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 2px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    padding: 0;
  }
}

.orderError,
.notFoundError {
  margin: 11px 0 12px 0;

  @media (--viewportMedium) {
    margin: 10px 0 12px 0;
  }
}
.orderError {
  /* color: var(--failColor); */
}
.notFoundError {
  color: var(--attentionColor);
}

.speculateError {
  color: var(--failColor);
  margin: 19px 0 1px 0;

  @media (--viewportLarge) {
    margin: 28px 48px 0 48px;
  }
}

.paymentForm {
  flex-grow: 1;
}

/* Details column is visible only on desktop */
.detailsContainerDesktop {
  display: none;

  @media (--screen-md) {
    flex-shrink: 0;
    display: block;
    /* Dimensions */
    width: 409px;
    padding-bottom: 55px;

    /* Position */
    margin: var(--s-7) 0 24px 0;

    /* Coloring */
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 2px;
  }
}

.detailsAspectWrapper {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.detailsHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 48px;
  }
}

.detailsTitle {
  margin-bottom: 10px;

  @media (--viewportLarge) {
    margin-top: 17px;
    margin-bottom: 9px;
  }
}

.detailsSubtitle {
  @apply --marketplaceH5FontStyles;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookingBreakdownTitle {
  font-size: var(--text-base);
  /* Layout spacing */
  margin: 5px 24px 0px 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 0px 48px;
  }
}

.bookingBreakdownSubtitle {
  font-size: var(--text-xs);
  margin: 0px var(--s-6) var(--s-6) var(--s-6);

  @media (--viewportLarge) {
    margin: 0px var(--s-12) var(--s-6) var(--s-12);
  }
}

.bookingBreakdownLoading {
  font-size: var(--text-xs);
  margin: 0px var(--s-6) var(--s-6) var(--s-6);

  @media (--viewportLarge) {
    margin: 0px var(--s-12) var(--s-6) var(--s-12);
  }
}

.bookingDatesContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 var(--s-6);
  margin-bottom: var(--s-9);

  @media (--screen-md) {
    margin-top: var(--s-1);
    padding: 0;
  }
}

.bookingDatesButton {
  font-family: var(--font-base);
  width: 100%;
  cursor: pointer;
  text-align: left;
  padding: 0;
  border-radius: var(--border-radius);
  border: 1px solid var(--gray-500);
  transition: all 0.2s ease-out;
  z-index: var(--z-50);
  position: relative;
  margin-bottom: var(--s-6);
}

.bookingDatesButtonLabel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: var(--s-4) var(--s-6) var(--s-4) var(--s-4);
}

.bookingDateText {
  margin-left: var(--s-4);
}

.iconArrow {
  stroke: var(--matterColor);
  fill: var(--matterColor);
  transition: all 0.2s ease-out;
}

.formGroupHeading {
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-6);
}

.formFieldHeading {
  font-size: var(--text-base);
  margin: 0 0 0 0;
  color: var(--var-900);
  font-weight: var(--font-bold);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 0 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.packageInfo {
  margin-top: var(--s-5);

  @media (--screen-md) {
    margin-top: 0;
  }
}

.packageInfoHeading {
  font-weight: var(--font-bold);
  font-size: var(--text-lg);

  @media (--screen-md) {
    font-size: var(--text-2xl);
  }
}

.partySizeWrapper {
  margin-bottom: var(--s-10);
}

.partySize {
  margin-bottom: 38px;
  display: block;
  flex-direction: row;
  padding: 0 var(--s-6);

  @media (--viewportMedium) {
    margin-bottom: 24px;
    padding: 0;
  }
}

.partySizeLabel {
  text-align: center;
}

.speciesHeadingSubtitle {
  margin-top: 0;
  margin-bottom: var(--s-2);
  font-size: var(--text-sm);
}

.speciesFee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--s-2);
}

.speciesFeeCheckboxLabel {
  margin-bottom: 0;
}

.submitClassName {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--s-4);
  background-color: var(--white);
  box-shadow: var(--box-shadow-xl);
  display: flex;
  flex-direction: column;
  z-index: var(--z-60);

  & > button {
    margin-top: 0;
  }

  & > p {
    margin-top: var(--s-1);
  }

  @media (--screen-md) {
    position: relative;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    z-index: var(--z-20);
  }
}

.modalContainer {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  min-height: var(--w-2-3);
  width: 100%;
  overflow: hidden;
  border-radius: var(--border-radius-xxxl) var(--border-radius-xxxl) 0 0;

  @media (--screen-md) {
    right: 0;
    min-height: 100%;
    width: var(--w-3-5);
    border-radius: var(--border-radius-xxxl) 0 0 var(--border-radius-xxxl);
  }
}

.innerIDModal {
  display: flex;
  justify-content: center;
}

.leftLoadingColumn {
  display: flex;
  flex-direction: column;
  padding: var(--s-5);

  @media (--screen-md) {
    flex-basis: 55%;
    padding-right: var(--s-25);
    height: calc(100vh - var(--s-30));
  }
}

.leftLoader {
  position: relative;
  box-sizing: content-box;
  height: 784px;
  background-color: #ffffff;
  border-radius: 0px 0px 0px 0px;
  background-image: linear-gradient(#f6f8fa 16px, transparent 0),
    linear-gradient(#f6f8fa 16px, transparent 0), linear-gradient(#f6f8fa 16px, transparent 0),
    linear-gradient(#f6f8fa 27px, transparent 0), linear-gradient(#f6f8fa 180px, transparent 0),
    linear-gradient(#f6f8fa 16px, transparent 0), linear-gradient(#f6f8fa 63px, transparent 0),
    linear-gradient(#f6f8fa 16px, transparent 0), linear-gradient(#f6f8fa 16px, transparent 0),
    linear-gradient(#f6f8fa 33px, transparent 0), linear-gradient(#f6f8fa 197px, transparent 0);
  background-repeat: repeat-y;
  background-size: 350px 784px, 350px 784px, 350px 784px, 170px 784px, 350px 784px, 141px 784px,
    350px 784px, 141px 784px, 141px 784px, 220px 784px, 350px 784px;
  background-position: 0px 766px, 0px 738px, 0px 711px, 0px 666px, 0px 466px, 0px 440px, 0px 346px,
    0px 320px, 0px 258px, 0px 214px, 0px 0px;

  &:before {
    content: ' ';
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 784px;
    -webkit-mask-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    );
    -webkit-mask-repeat: repeat-y;
    -webkit-mask-size: 50px 784px;
    -webkit-mask-position: -20% 0;
    background-image: linear-gradient(rgba(255, 255, 255, 1) 16px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 16px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 16px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 27px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 180px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 16px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 63px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 16px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 16px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 33px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 197px, transparent 0);
    background-repeat: repeat-y;
    background-size: 350px 784px, 350px 784px, 350px 784px, 170px 784px, 350px 784px, 141px 784px,
      350px 784px, 141px 784px, 141px 784px, 220px 784px, 350px 784px;
    background-position: 0px 766px, 0px 738px, 0px 711px, 0px 666px, 0px 466px, 0px 440px, 0px 346px,
      0px 320px, 0px 258px, 0px 214px, 0px 0px;
    animation: loading 2s infinite;
  }

  @media (--screen-md) {
    height: 604px;
    background-color: #ffffff;
    border-radius: 0px 0px 0px 0px;
    background-image: linear-gradient(#f6f8fa 51px, transparent 0),
      linear-gradient(#f6f8fa 197px, transparent 0), linear-gradient(#f6f8fa 26px, transparent 0),
      linear-gradient(#f6f8fa 50px, transparent 0), linear-gradient(#f6f8fa 26px, transparent 0),
      linear-gradient(#f6f8fa 43px, transparent 0);
    background-repeat: repeat-y;
    background-size: 570px 625px, 568px 625px, 233px 625px, 568px 625px, 178px 625px, 245px 625px;
    background-position: 0px 552px, 0px 219px, 0px 185px, 0px 108px, 0px 75px, 0px 0px;

    &:before {
      content: ' ';
      position: absolute;
      z-index: 1000;
      width: 100%;
      height: 604px;
      -webkit-mask-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      );
      -webkit-mask-repeat: repeat-y;
      -webkit-mask-size: 50px 604px;
      -webkit-mask-position: -20% 0;
      background-image: linear-gradient(rgba(255, 255, 255, 1) 51px, transparent 0),
        linear-gradient(rgba(255, 255, 255, 1) 197px, transparent 0),
        linear-gradient(rgba(255, 255, 255, 1) 26px, transparent 0),
        linear-gradient(rgba(255, 255, 255, 1) 50px, transparent 0),
        linear-gradient(rgba(255, 255, 255, 1) 26px, transparent 0),
        linear-gradient(rgba(255, 255, 255, 1) 43px, transparent 0);
      background-repeat: repeat-y;
      background-size: 570px 625px, 568px 625px, 233px 625px, 568px 625px, 178px 625px, 245px 625px;
      background-position: 0px 552px, 0px 219px, 0px 185px, 0px 108px, 0px 75px, 0px 0px;
      animation: loading 2s infinite;
    }
  }
}

@keyframes loading {
  to {
    -webkit-mask-position: 120% 0;
  }
}

.rightLoadingColumn {
  display: none;

  @media (--screen-md) {
    display: block;
    padding-left: var(--s-8);
    border-left: 1px solid var(--gray-300);
    flex-basis: 45%;
  }
}

.rightLoader {
  position: relative;
  box-sizing: content-box;
  height: 604px;
  background-color: #ffffff;
  border-radius: 0px 0px 0px 0px;
  background-image: linear-gradient(#f6f8fa 21px, transparent 0),
    linear-gradient(#f6f8fa 21px, transparent 0), linear-gradient(#f6f8fa 21px, transparent 0),
    linear-gradient(#f6f8fa 21px, transparent 0), linear-gradient(#f6f8fa 21px, transparent 0),
    linear-gradient(#f6f8fa 21px, transparent 0), linear-gradient(#f6f8fa 43px, transparent 0),
    linear-gradient(#f6f8fa 229px, transparent 0);
  background-repeat: repeat-y;
  background-size: 372px 604px, 372px 604px, 372px 604px, 372px 604px, 178px 604px, 178px 604px,
    245px 604px, 372px 604px;
  background-position: 0px 538px, 0px 509px, 0px 480px, 0px 451px, 0px 364px, 0px 318px, 0px 267px,
    0px 0px;

  &:before {
    content: ' ';
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 604px;
    -webkit-mask-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    );
    -webkit-mask-repeat: repeat-y;
    -webkit-mask-size: 50px 604px;
    -webkit-mask-position: -20% 0;
    background-image: linear-gradient(rgba(255, 255, 255, 1) 21px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 21px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 21px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 21px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 21px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 21px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 43px, transparent 0),
      linear-gradient(rgba(255, 255, 255, 1) 229px, transparent 0);
    background-repeat: repeat-y;
    background-size: 372px 604px, 372px 604px, 372px 604px, 372px 604px, 178px 604px, 178px 604px,
      245px 604px, 372px 604px;
    background-position: 0px 538px, 0px 509px, 0px 480px, 0px 451px, 0px 364px, 0px 318px, 0px 267px,
      0px 0px;
    animation: loading 2s infinite;
  }
}

.infoWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: var(--s-5) var(--s-4) var(--s-5) 0;

  @media (--screen-md) {
    margin: var(--s-5) var(--s-4) var(--s-5) 0;
  }
}

.desktopBreadcrumbs {
  display: none;
  @media (--screen-md) {
    display: block;
  }
}
.mobileBreadcrumbs {
  margin-bottom: 0;

  @media (--screen-md) {
    display: none;
  }
}
