@import '../../landtrust.css';

.root {
  font-size: var(--text-base);
  font-weight: var(--font-medium);
  color: var(--gray-900);

  margin: var(--s-4) 0 var(--s-5) 0;

  @media (--screen-md) {
    margin-bottom: var(--s-8);
  }
}
