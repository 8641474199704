@import '../../landtrust.css';

.container {
  padding: var(--s-12) var(--s-4);
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  @media (--screen-lg) {
    padding: var(--s-24) 0;
    flex-direction: row;
  }
}

.verificationContainer {
}

.coltonContainer {
  display: none;

  @media (--screen-lg) {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    width: 350px;
    margin-left: var(--s-24);
  }
}

.frameContainer {
  margin: var(--s-8) 0;
}

.frame {
  min-height: var(--s-72);
}

.separator {
  margin: var(--s-8) 0;
  border-top: 1px var(--gray-300) solid;
}

.topBarExitButton {
  font-family: var(--font-base);
  font-weight: bold;
  font-size: var(--text-sm);
  color: var(--font-color);
  cursor: pointer;
}

.backLink {
  font-weight: var(--font-medium);
}

.sharingConsentContainer {
  display: flex;
  align-items: center;
  font-size: var(--text-sm);
}

.lockIcon {
  width: var(--s-8);
  color: currentColor;
  margin-right: var(--s-2);

  @media (--screen-md) {
    width: var(--s-4);
  }
}

.statusBannerRoot {
  padding: var(--s-4);
  background-color: var(--gray-100);
  border: 2px solid transparent;
  border-radius: var(--border-radius);
  margin-bottom: var(--s-4);
}

.statusBannerRoot--warning {
  border-color: var(--orange-500);
}

.statusBannerRoot--success {
  border-color: var(--green-100);
}

.statusBannerRoot--error {
  border-color: var(--red-500);
}
