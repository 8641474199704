@import '../../landtrust.css';

.tooltipHeader {
  display: flex;
  align-items: center;
  font-size: var(--text-base);
  color: var(--gray-900);
  font-weight: bold;
  margin-bottom: var(--s-4);
}

.tooltipHeaderIcon {
  margin-right: var(--s-2);
}

.tooltipContent {
  min-width: var(--s-54);
  max-width: var(--s-64);

  @media (--screen-md) {
    min-width: var(--s-64);
    max-width: var(--s-76);
  }
}

.tooltipLink {
  position: relative;
  bottom: 2px;
  left: var(--s-2);
}
