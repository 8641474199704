@import '../../marketplace.css';

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  @apply --backgroundImage;

  /* Cover the whole screen with the background image */
  background-size: cover;

  /* Align the image within the container */
  background-position: center center;

  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  height: 33.3vh;
  max-height: 400px;
}

.hero {
  flex-grow: 1;
  justify-content: center;
  padding-bottom: 32px;
}

.heroContent {
  width: 100%;
  padding: 40px 30px;

  @media (--viewportMedium) {
    padding: 60px 7.5vw 60px 7.5vw;
  }

  @media (--viewportLarge) {
    max-width: calc(1200px + 36px + 36px);
    margin: 60px auto;
    padding: 0 36px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);
  margin-bottom: 0px;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroSubTitle {
  @apply --marketplaceDefaultFontStyles;
  font-size: 24px;
  color: var(--matterColorLight);
}

.sections {
  margin: 0;
}

.sectionContent {
  font-size: 16px;
  margin: 0 24px;

  @media (--viewportLarge) {
    max-width: calc(1200px + 36px + 36px);
    margin: 20px auto 60px auto;
    padding: 0 36px 0 36px;
  }
}

.sectionCentered {
  text-align: center;
}

.sectionTitle {
  @apply --marketplaceH1FontStyles;
  margin: 30px 0px;
  padding: 0;
}

.sectionSteps {
  display: flex;
  flex-direction: column;
}

.sectionStep,
.sectionStepReverse {
  display: flex;
  flex-wrap: wrap;
}

.sectionStepReverse {
  flex-wrap: wrap-reverse;
  margin: 0px 0px 40px 0px;
}

.sectionStepDescriptionContainer {
  display: flex;
  flex: 50%;
  flex-direction: column;
  min-width: 300px;
  padding: 0;
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  flex: 50%;
  position: relative;
  width: 100%;
  transition: var(--transitionStyleButton);
}

.imageWrapper2 {
  position: relative;
  height: 300px;
  width: 100%;
  transition: var(--transitionStyleButton);
  margin-bottom: 20px;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
}

.locationImage2 {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: -0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0px 10px;
}

.helpButton {
  @apply --ltButtonStyle;
  @apply --marketplaceBodyFontStyles;
  border-color: var(--ltForegroundColor);
  width: 140px;
}

.boldH4 {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  margin: 0px;
}

.highlightSection {
  margin: 0 24px;
}

.highlightSectionContent {
  @apply --marketplaceH1FontStyles;
  line-height: 1.5;
  text-align: center;

  @media (--viewportMedium) {
    margin: 40px 7.5vw;
  }

  @media (--viewportLarge) {
    max-width: calc(1200px + 36px + 36px);
    margin: 40px auto;
  }
}

.textHighlight {
  padding: 0 8px;
  color: var(--matterColorLight);
  background-color: var(--ltColor);
}

.coverageDetails {
  list-style: disc;
  padding: 16px;
}

.coverageDetails > li {
  font-size: 16px;
}

.whyGroup {
  @media (--viewportMedium) {
    display: flex;
  }
}

.whyGroupItem {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    width: 33%;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.signupCTA {
  max-width: 240px;
  margin: 24px auto;
}
.title {
  @apply --marketplaceH1FontStyles;

  margin-top: 0;
  max-width: 735px;

  @media (--viewportMedium) {
    margin-bottom: 23px;
  }
}

.cardContainer {
  margin: auto;
  @media (--viewport) {
    width: 33.33%;
  }
}

.landownerFrame {
  width: 100%;
  border: none;
  @media (--viewportMedium) {
    padding-left: 20%;
    padding-right: 20%;
  }
}
