@import '../../landtrust.css';

.followButtonStyles {
  min-width: var(--s-50);
  max-width: var(--s-65);
  background-color: var(--yellow-500);
  border: 1px solid var(--gray-900);
  cursor: pointer;
  font-size: var(--text-sm);
  font-weight: bold;
  padding: var(--s-2) var(--s-3);
}

.whiteFollowButtonStyles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: var(--s-50);
  max-width: var(--s-65);
  background-color: var(--white);
  color: var(--black);
  cursor: pointer;
  font-size: var(--text-sm);
  font-weight: bold;
  padding: var(--s-2) var(--s-4);
  border: 1px solid var(--gray-900);
}

.iconStyles {
  width: var(--s-5);
  height: var(--s-5);
}

.tooltipIconStyles {
  margin-right: var(--s-1);
  margin-left: var(--s-1);
  width: var(--s-6);
  height: var(--s-6);
}

.arrowIconStyles {
  margin-right: var(--s-1);
  margin-left: var(--s-3);
  width: var(--s-6);
  height: var(--s-6);
}

.tooltipRoot {
  padding: var(--s-3) var(--s-3) var(--s-3) var(--s-1);
}

.tooltipButtonStyles {
  margin-right: var(--s-1);
}

.tooltipWrapperStyles {
  min-width: var(--s-40);
  font-size: var(--text-lg);
  padding-left: var(--s-2);
}

.tooltipLineStyles {
  display: flex;
  align-items: center;
  margin-bottom: var(--s-2);
  margin-top: var(--s-2);
  cursor: pointer;
}

.toolipContent {
  font-size: var(--text-sm);
  font-family: var(--font-base) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
