@import '../../landtrust.css';
@import '../../components/FlexHelper/FlexHelper.css';

.root {
  background-color: var(--gray-100);
  padding: var(--s-16) var(--s-8);
}

.title {
  text-align: center;
  line-height: var(--line-height-tight);
  margin-bottom: var(--s-18);
}

.cardsWrapper {
  @apply --flexWrapper;
}

.cardsInner {
  @apply --flexInner10;
}

.cardIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--s-5);
}

.cardIcon {
  height: 47px;
  color: var(--brand);
  fill: currentColor;
}

.cardRoot {
  @apply --flexItem10;
  padding: var(--s-5);
  margin-bottom: var(--s-8);
  background-color: var(--white);
  box-shadow: var(--box-shadow-xl);
  border: 1px solid var(--gray-300);
  border-radius: var(--border-radius-xl);
  min-height: 300px;
  max-height: 300px;

  &:last-child {
    margin-bottom: 0;
  }
}

.cardTitle {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.cardBody {
  text-align: center;
}

@media (--screen-lg) {
  .root {
    padding-top: var(--s-32);
  }

  .title {
    max-width: var(--w-1-2);
    margin-left: auto;
    margin-right: auto;
  }

  .cardRoot {
    @apply --flexItem10Width30Percent;
    padding-top: var(--s-10);
  }

  .cardTitle {
    margin-bottom: var(--s-1);
  }
}
