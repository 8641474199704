@import '../../landtrust.css';

.root {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: var(--s-1);
  column-gap: var(--s-2);
}

.badgeWrapper {
  display: flex;
  align-items: center;
  padding: var(--s-1) 0;
}

.icon {
  height: var(--s-6);
  width: var(--s-6);
}

.infoSpan {
  display: block;
  font-size: var(--text-xs);
}
