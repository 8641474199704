@import '../../landtrust.css';

.root {
  width: 100%;
  height: var(--s-18);
  background-color: var(--white);
  box-shadow: var(--box-shadow-xl);
  align-items: center;
  padding: 0;
  display: none;

  @media (--screen-md) {
    display: flex;
  }
}

.rootFullWidth {
  composes: root;
  padding: 0 var(--s-5);

  @media (--viewportLarge) {
    padding: 0 var(--s-12);
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.skeletonLinks {
  display: flex;
  align-items: center;
  margin-left: var(--s-2);
  gap: var(--s-10);
}

.links {
  display: flex;
  align-items: center;
  margin-left: var(--s-2);
}

.link {
  position: relative;
  color: var(--font-color);
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  flex-shrink: 0;
}

.link:hover {
  color: var(--gray-600);
  text-decoration: none;
}

.linkMargin {
  margin-right: var(--s-4);

  @media (--screen-lg) {
    margin-right: var(--s-12);
  }
}

.dropdownMenu {
  padding: var(--s-4);
  font-size: var(--text-sm);
  min-width: var(--s-40);
}

.dropdownLinkItem {
  color: var(--gray-900);
  font-weight: var(--font-medium);
}

.dropdownLinkItem:hover {
  text-decoration: none;
  color: var(--gray-600);
}

.currentPage {
  color: var(--gray-600);
}

.searchForm {
  margin-right: var(--s-4);
  margin-left: var(--s-8);
  max-width: 33%;
  flex: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease-in-out;

  @media (--screen-lg) {
    margin-right: auto;
  }
}

.searchFormVisible {
  opacity: var(--o-100);
  visibility: visible;
}

.addPropertyButton {
  flex-shrink: 0;
}

.newFlag {
  margin: var(--s-9);
  padding: var(--s-1) var(--s-3);
  border-radius: var(--border-radius-xl);
  border: solid 1px var(--green-900);
  background-color: var(--green-400);
  font-size: 10px;
}

.bellIcon {
  width: var(--s-5);
  height: var(--s-5);
  fill: currentColor;
}

.signupLink {
  font-size: var(--text-sm);
  color: white;
  font-weight: 600;
}
