@import '../../landtrust.css';

.verifySection {
  background-color: var(--gray-100);
  padding: var(--s-6) var(--s-4) var(--s-6) var(--s-4);
  margin-bottom: var(--s-5);
  color: var(--gray-900);
  margin-left: calc(-1 * var(--s-5));
  margin-right: calc(-1 * var(--s-5));
}

.verifySectionHeader {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  display: flex;
  align-items: center;
  margin-bottom: var(--s-4);

  & > svg {
    height: var(--s-7);
    margin-left: calc(-1 * var(--s-2));
  }
}

.verifySectionContent {
  font-size: var(--text-base);
  margin-bottom: var(--s-3);
}

.linkText {
  font-size: var(--text-base);
  font-weight: var(--font-medium);
}

.getVerifiedButton {
  width: 100%;

  @media (--screen-md) {
    width: auto;
  }
}

.editVerificationButton {
  display: flex;
  align-items: center;

  & > svg {
    margin-right: var(--s-2);
  }
}
