@import '../../landtrust.css';

.root {
  color: var(--gray-900);
}

.rootMenu {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 70px;
  width: 100%;
  z-index: 99998;
  padding: var(--s-6);
}

.rootTrayIcon {
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 16px;
  z-index: 99999;

  @media (--screen-md) {
    display: none;
  }
}

.menuHeader {
  font-size: 20px;
  font-weight: var(--font-bold);
  padding-bottom: var(--s-5);
  border-bottom: 1px solid var(--gray-300);
}

.menuList {
  padding-top: var(--s-5);
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.menuItem {
  font-weight: var(--font-medium);
}

.menuLink {
  display: flex;
  gap: 12px;
  align-items: center;
  font-weight: var(--font-medium);

  &:hover {
    color: var(--gray-600);
    text-decoration: none;
  }
}

.menuIcon {
  width: 24px;
  height: 24px;
}

.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  font-size: 10px;
  color: var(--gray-900);
  padding: 0 12px;
  gap: 3px;
}

.activeLink {
  composes: link;
  color: var(--green-900);
  font-weight: bold;
}

.label {
  white-space: nowrap;
}
