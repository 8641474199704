@import '../../landtrust.css';

.quoteIcon {
  margin-bottom: var(--s-7);
}

.content {
  font-size: var(--text-2xl);
  margin-bottom: var(--s-5);

  @media (--screen-md) {
    font-size: var(--text-2xl);
  }
}

.ratingWrapper {
  margin-bottom: var(--s-5);
}

.starIcon {
  height: var(--s-4);
  width: var(--s-4);
  margin-right: var(--s-1);
}

.authorWrapper {
  display: flex;
  align-items: center;
  margin-bottom: var(--s-9);
}

.author {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
}

.authorVerified {
  display: flex;
  align-items: center;
  font-style: italic;
}

.authorVerifiedIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--olive-800);
  border-radius: 50%;
  font-size: var(--text-xs);
  width: var(--s-5);
  height: var(--s-5);
  margin-left: var(--s-2);
  margin-right: var(--s-2);
}

.footer {
  color: var(--olive-500);
}
