@import '../../landtrust.css';

.root {
}

.title {
  margin-top: var(--s-3);
  margin-bottom: var(--s-3);
  font-weight: var(--font-bold);
}

.columns {
  margin-top: var(--s-8);
  display: grid;
  grid-gap: var(--s-6) var(--s-10);
  grid-template-columns: repeat(1, minmax(0, 100%));
  grid-auto-rows: minmax(min-content, max-content);

  @media (--screen-md) {
    grid-gap: var(--s-6) var(--s-10);
    grid-template-columns: repeat(4, minmax(0, 25%));
  }
}

.column {
}

.item {
  margin-bottom: var(--s-6);

  &:last-child {
    margin-bottom: 0;
  }
}

.itemActive {
}

.fieldCheckboxLabel {
  margin-bottom: 0;
}

.fieldPriceInput {
  @media (--screen-md) {
    max-width: var(--s-56);
  }
}
