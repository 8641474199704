@import '../../landtrust.css';

.root {
}

.IDModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--s-8) var(--s-5);

  @media (--screen-md) {
    max-width: var(--w-3-4);
  }
}

.verifyProcessing {
  color: var(--green-900);
}

.modalTitle {
  @apply --typography-heading;
  @apply --typography-heading-size-3xl;
  font-weight: var(--font-bold);
  font-family: var(--font-base);
  margin-top: var(--s-7);
  margin-bottom: var(--s-3);
  text-align: center;
}

.modalMessage {
  text-align: center;
}

.bottomWrapper {
  margin-top: var(--s-12);
}

.verifyTime {
  font-size: var(--text-sm);
  margin-top: var(--s-4);
}

.idIcon {
  width: var(--s-24);
  height: var(--s-19);
}
