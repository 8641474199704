@import '../../landtrust.css';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
}

.overlayActive {
  z-index: var(--z-50);
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  background: white;
  width: 100%;
  position: relative;
  overflow: auto;
  padding: var(--s-9) var(--s-5);
  transition: width 0.3s ease-out;

  @media (--screen-md) {
    width: 0;
  }
}

.contentActive {
  @media (--screen-md) {
    width: 100%;
    padding: var(--s-23) 5%;
  }
}

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.mainWrapper {
  display: flex;
  flex-direction: column;
}

.columnWrapper {
  display: flex;
  flex-direction: column;

  @media (--screen-md) {
    flex-direction: row;
  }
}

.formColumn {
  width: 100%;

  @media (--screen-md) {
    width: 50%;
    margin-right: var(--s-25);
  }
}

.previewColumn {
  width: 100%;
  margin-top: var(--s-6);

  @media (--screen-md) {
    margin-top: 0;
    width: calc(50% - var(--s-25));
  }
}

.formWrapper {
  margin: 0;

  @media (--screen-md) {
    margin: 0;
  }
}

.formError {
  color: var(--red-500);
}

.submitButton {
  margin-top: auto;
  margin-bottom: var(--s-6);
  flex-shrink: 0;

  @media (--screen-lg) {
    display: inline-block;
    width: var(--s-60);
    margin-top: var(--s-25);
  }
}

.buttonClose {
  position: absolute;
  top: var(--s-6);
  right: var(--s-6);
  width: var(--s-12);
  height: var(--s-12);
  border-radius: 50%;
  padding: var(--s-1);
  background: var(--white);
  border-color: transparent;
  box-shadow: var(--box-shadow);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-10);
  cursor: pointer;

  &:hover {
    box-shadow: var(--box-shadow-xl);
  }
}

.header {
  font-weight: bold;
  margin-bottom: var(--s-11);

  @media (--screen-md) {
    margin-bottom: var(--s-2);
  }
}

.subheader {
  display: none;

  @media (--screen-md) {
    display: block;
    font-size: var(--text-sm);
    margin-bottom: var(--s-6);
    margin-top: 0;
  }
}

.packageDescription {
  min-height: var(--s-38);
}

.loadingWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loadingTitleWrapper {
  position: absolute;
  top: calc(50% + var(--s-30));
  transform: translateY(-50%);
  text-align: center;
}

.loadingTitle {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-4);

  @media (--screen-md) {
    font-size: var(--text-3xl);
  }
}

.fieldHeader {
  @apply --typography-heading-size-2xl;
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);
}

.fieldSubHeader {
  display: none;
  margin-bottom: var(--s-3);
  font-size: var(--text-sm);

  @media (--screen-md) {
    display: block;
  }
}

.fieldGuestsWrapper {
  display: flex;
  flex-direction: column;

  @media (--screen-md) {
    flex-direction: row;

    & > span:first-of-type {
      margin-right: var(--s-8);
    }
  }
}

.fieldGuests {
  & label {
    margin-right: var(--s-4);
  }
}

.fieldInputPrice {
  & input {
    width: var(--s-29);
  }
}

.packagePreview {
  margin: 0;
  width: 100%;
  padding: var(--s-8) var(--s-5);
}

.previewColumnWrapper {
  position: sticky;
  top: calc(-1 * var(--s-22));
}

.listingSectionRoot {
  margin: 0;
}

.submitButtonWrapper {
  margin-top: var(--s-6);

  @media (--screen-md) {
    margin-top: var(--s-7);
    width: 50%;
  }
}

.speciesListWrapper {
  display: grid;
  margin-top: var(--s-6);
  grid-gap: var(--s-6) var(--s-10);
  grid-template-columns: repeat(2, minmax(0, var(--w-1-2)));
  grid-auto-rows: minmax(min-content, max-content);

  @media (--screen-md) {
    grid-template-columns: repeat(3, minmax(0, var(--w-1-3)));
  }
}

.subspeciesListContainer {
  padding: var(--s-4) var(--s-6);
  background-color: var(--gray-100);
  margin-top: var(--s-7);
  border-radius: var(--border-radius-xl);
}

.subspeciesListWrapper {
  display: grid;
  margin-top: var(--s-6);
  grid-gap: var(--s-6) var(--s-10);
  grid-template-columns: repeat(2, minmax(0, var(--w-1-2)));
  grid-auto-rows: minmax(min-content, max-content);

  @media (--screen-md) {
    grid-template-columns: repeat(3, minmax(0, var(--w-1-3)));
  }
}

.activitiesListWrapper {
  display: flex;
  flex-direction: column;
}

.showMore {
  cursor: pointer;
  @apply --typography-base;
  font-weight: var(--font-medium);
  margin-top: var(--s-7);
  color: var(--brand);
  display: flex;
  align-items: baseline;
}

.arrowIcon {
  stroke: var(--brand);
  margin-left: var(--s-5);
  transition: var(--transition-base);

  & > path {
    /* Translate because the icons are annoyingly not centered in
       their viewbox which makes our rotation look bad */
    transform: translateY(1px);
  }
}

.arrowIconDown {
  /* Rotate instead of changing icon so we can animate it */
  transform: rotateZ(180deg);
}

.radioWrapper {
  display: flex;
  flex-direction: column;

  & > span {
    margin-right: var(--s-7);
    margin-bottom: 0;
  }

  @media (--screen-md) {
    flex-direction: row;
  }
}

.radioButtonLodging {
  & > label {
    font-weight: normal;
  }
}

.photoLibraryContainer {
  width: 100%;
  margin: 0;
  border-radius: 0;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--s-4);
  background-color: var(--white);
  border-bottom: none;
  flex-grow: 0;
  min-height: auto;
  min-width: var(--w-3-4);
  overflow: hidden;

  @media (--screen-md) {
    padding: var(--s-6);
  }
}

.photoPreviews {
  margin-top: var(--s-8);
}

.datesFieldSection {
  margin-top: var(--s-3);
  margin-bottom: var(--s-3);
}

.activityFieldCheckboxLabel {
  font-weight: normal;
}

.activityFieldCheckboxLabelSelected {
  font-weight: var(--font-bold);
}

.activityFieldInput {
  min-height: var(--s-21);
  margin-left: var(--s-7);
  margin-bottom: var(--s-6);
  width: calc(100% - var(--s-7));
}
