@import '../../landtrust.css';

:root {
  --EditListingActions_marginTopMobile: var(--s-9);
  --EditListingActions_marginTopDesktop: var(--s-12);
  --EditListingActions_marginBottomMobile: var(--s-7);
  --EditListingActions_marginBottomDesktop: var(--s-12);
  --EditListingActions_paddingTopMobile: var(--s-9);
  --EditListingActions_paddingTopDesktop: var(--s-6);
}

.root {
  /* We expect to be used in a flex column where we can push to the bottom */
  margin-top: auto;

  /* Start: Get the margin/padding/border sorted */
  display: flex;
  position: relative;

  /* This is the total size of visible space above and below the border */
  /* We don't use margin-top because we want that to be auto */
  /* Think of this as margin-top + padding-top */
  padding-top: calc(
    var(--EditListingActions_marginTopMobile) + var(--EditListingActions_paddingTopMobile)
  );
  margin-bottom: var(--EditListingActions_marginBottomMobile);

  &::before {
    content: '';
    display: block;
    border-top: 1px solid var(--gray-300);
    position: absolute;
    width: 100%;
    /* Think of this as margin-top */
    top: var(--EditListingActions_marginTopMobile);

    @media (--screen-lg) {
      width: var(--s-126);
    }

    @media (--screen-xl) {
      width: var(--s-175);
    }
  }

  @media (--screen-md) {
    /* Think of this as margin-top + padding-top */
    padding-top: calc(
      var(--EditListingActions_marginTopDesktop) + var(--EditListingActions_paddingTopDesktop)
    );
    margin-bottom: var(--EditListingActions_marginBottomDesktop);

    &::before {
      /* Think of this as margin-top */
      top: var(--EditListingActions_marginTopDesktop);
    }
  }
  /* End: Get the margin/padding/border sorted */

  /* Start: How to display the children */
  flex-direction: column;
  align-items: center;

  @media (--screen-md) {
    flex-direction: row;
  }

  & > .backAction {
    display: none;
  }

  & > .skipAction {
    order: 2;
    margin-top: var(--s-6);
  }

  & > .forwardAction {
    order: 1;
  }

  @media (--screen-md) {
    & > .backAction {
      display: block;
      order: 1;
    }

    & > .skipAction {
      order: 2;
      margin-top: 0;
      margin-left: auto;
      margin-right: var(--s-6);
    }

    & > .forwardAction {
      order: 3;
    }

    &.withoutSkipAction > .forwardAction {
      margin-left: auto;
    }
  }
  /* End: How to display the children */
}

.forwardAction {
  @media (--screen-lg) {
    margin-right: var(--s-31);
  }

  @media (--screen-xl) {
    margin-right: var(--s-44);
  }
}
.backAction {
}
.skipAction {
}
