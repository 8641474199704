@import '../../landtrust.css';

.pricingOptionSection {
  margin-bottom: var(--s-5);

  @media (--screen-md) {
    margin-top: var(--s-8);
    margin-bottom: var(--s-8);
  }
}

.pricingOptionSectionTitle {
  font-weight: var(--font-bold);
  margin-bottom: var(--s-2);
}

.iconWrapper {
  width: var(--s-12);
  height: var(--s-12);
  border-radius: 50%;
  padding: var(--s-1);
  background: var(--white);
  border-color: transparent;
  box-shadow: var(--box-shadow);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-10);
  margin-left: var(--s-5);
}

.showMore {
  font-family: var(--font-base);
  cursor: pointer;
  font-weight: var(--font-medium);
  font-size: var(--text-lg);
  color: var(--brand);
  display: flex;
  align-items: center;
}

.arrowIcon {
  stroke: var(--brand);

  transition: var(--transition-base);

  & > path {
    /* Translate because the icons are annoyingly not centered in
       their viewbox which makes our rotation look bad */
    transform: translateY(1px);
  }
}

.arrowIconDown {
  /* Rotate instead of changing icon so we can animate it */
  transform: rotateZ(180deg);
}

.features {
  margin-bottom: var(--s-4);

  @media (--screen-md) {
    margin-bottom: var(--s-8);
  }
}
