:root {
  --pageContainer: {
    width: 100%;
    margin: 0 auto;

    @media (--screen-sm) {
      max-width: var(--screen-sm);
    }

    @media (--screen-md) {
      max-width: var(--screen-md);
    }

    @media (--screen-lg) {
      max-width: var(--screen-lg);
    }

    @media (--screen-xl) {
      max-width: var(--screen-xl);
    }
  }
}
