@import '../../landtrust.css';

.inquiryContainer {
  margin: 0;
  padding: var(--s-2) var(--s-4) var(--s-2) var(--s-4);
  border-radius: var(--s-2) var(--s-2) 0 var(--s-2);
  box-shadow: var(--box-shadow);
  font-weight: normal;
}

.ownInquiry {
  background-color: var(--gray-800);
  color: var(--white);
  font-size: var(--text-base);
}

.inquiry {
  background-color: var(--gray-200);
  color: var(--gray-800);
}

.inquiryMessage {
  white-space: pre-line;
  margin-top: var(--s-4);
}
