@import '../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  color: var(--white);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(34, 39, 32, 0.7);
    z-index: 1;
    pointer-events: none;
  }
}

.headerContainerLoading {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--s-2);
  border-bottom: 1px solid var(--gray-300);
  margin-bottom: var(--s-6);
  font-size: var(--text-lg);
  font-weight: var(--font-bold);
  padding: var(--s-8) var(--s-6) var(--s-2) var(--s-6);
}

.content {
  display: flex;
  align-items: center;
  overflow: auto;
  height: 100%;
  position: relative;
  padding: var(--s-10) var(--s-5);
  z-index: 2;
  min-height: var(--s-88);

  @media (--screen-md) {
    min-height: var(--s-56);
    padding: var(--s-4);
  }
}

.contentLoading {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--screen-md) {
    position: relative;
    top: -4px;
    padding: 0;
  }
}

.sendMessageContainer {
  padding: 0 var(--s-6);
}

.sendMessageForm {
  margin-top: var(--s-5);
}

.textAreaMessageForm {
  min-height: var(--s-19);
}

.genericError {
  color: var(--red-500);
  margin: var(--s-6) 0 0 0;
}

.buttonClose {
  position: absolute;
  top: var(--s-6);
  right: var(--s-6);
  border-color: transparent;
  box-shadow: var(--box-shadow);
  outline: none;
  display: flex;
  z-index: var(--z-10);
  cursor: pointer;

  & > svg {
    color: var(--white);
    width: var(--s-6);
    height: var(--s-6);
  }
}

.footer {
  display: flex;
  flex-direction: column;
  padding: 0 var(--s-5);
}

.hiddenImage {
  display: none;
}

.loaderContainer {
  display: block;
  width: 100%;
  height: 100%;
}
