@import '../../landtrust.css';

.root {
}

.rootIsSorting {
  & * {
    user-select: none;
  }
}

.photoPreviewContainer {
  z-index: 60;
  background: var(--white);
  margin-bottom: var(--s-7);
  margin: 0 calc(-1 * var(--s-2));

  &:last-child {
    margin-bottom: 0;
  }
}

.photoPreviewContainerInner {
  padding: var(--s-2);
  display: flex;
}

.photoPreviewContainerIsSorting {
  box-shadow: var(--box-shadow);
  transform: scale(1.05);

  & .photoPreviewContainerInner {
  }
}

.photoPreviewImageContainer {
  position: relative;
  width: 86px;
  height: 86px;
  flex-grow: 0;
  flex-shrink: 0;
}

.photoPreviewImage {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
  font-size: var(--text-xs);
}

.photoPreviewImageFade {
  opacity: 0.5;
}

.photoPreviewDeleteImage {
  cursor: pointer;
  position: absolute;
  top: calc(0px - (var(--s-7) / 2));
  right: calc(0px - (var(--s-7) / 2));
  background: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: 50%;
  width: var(--s-7);
  height: var(--s-7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.photoPreviewDeleteImageIcon {
  width: var(--s-2);
  height: var(--s-2);
}

.photoPreviewSpinner {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photoPreviewCaptionContainer {
  flex-grow: 1;
  margin-left: var(--s-5);
  align-self: stretch;
}

.photoPreviewCaptionContainerWithTags {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.photoPreviewCaptionInputWrapper {
  height: 100%;
}

.photoPreviewCaptionInputWrapperWithTags {
  height: var(--s-21);
}

.photoPreviewCaptionInput {
  display: block;
  min-height: auto;
  height: 100%;
}

.sortHandleContainer {
  flex-grow: 0;
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: var(--s-5);
}

.sortHandleContainerWithTags {
  height: var(--s-21);
}

.sortHandle {
  cursor: pointer;

  & g {
    stroke: var(--gray-400);
  }
}

.error {
  color: var(--red-500);
  margin: var(--s-2) 0 0 0;
  font-size: var(--text-sm);
}

.photoPreviewTagsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--s-3);
  margin-top: var(--s-2);
}

.photoPreviewTagsItem {
  display: flex;
  align-items: center;
  font-size: var(--text-xs);
  color: var(--gray-900);
  padding: var(--s-1) var(--s-4);
  border-radius: var(--border-radius-xl);
  background-color: var(--gray-200);
  white-space: nowrap;
}
