@import '../../landtrust.css';

/* A simple technique I developed here: https://codesandbox.io/s/flexbox-with-gaps-simple-qu7wl
   These helpers can be used to construct a flexbox layout that supports grid gaps like css grid does.
   This is not as easy as it seems, especially with a responsive flex layout that can wrap, this solution adds
   margins to add flex items then uses negative margins to offset the margins at the edge so that they don't
   interfere with elements outside of this one

   Usage:

   JSX:

   <div className={css.filtersWrapper}>
     <div className={css.filtersInner}>
       <div className={css.filter}>Item 1</div>
       <div className={css.filter}>Item 2</div>
       <div className={css.filter}>Item 3</div>
     </div>
   </div>

   CSS:

   @import '../FlexHelper/FlexHelper.css';

   // Decorate the wrapper here as you wish but don't add padding
   .filtersWrapper {
     @apply --flexWrapper;
   }

   // Don't add much except flexbox configuration here
   .filtersInner {
     @apply --flexInner3;
     justify-content: center; // Or whatever flex stuff you want to add
   }

   // Decorate each item as you wish but don't add margins
   .filter {
     @apply --flexItem3;

     // You can add widths here for more of a grid-like look.
     // You need to use / add helpers though because the widths need to be calculated using the margin
     @apply --flexItem3Width50Percent;

     @media (--screen-md) {
       @apply --flexItem3Width25Percent;
     }
   }

   In this simple version the gaps cannot be configured to change at breakpoints but ...

   Extra classes for different configurations could be added here and reused elsewhere as needed

   Or it can be expended to support a nice Bootstrap-style grid: https://codesandbox.io/s/pmxn0pqpyq
   - This implementation can be responsive but every use on the app is the same

   Or it can be used like this when uncoupled from the limitations of CSS Modules: https://codesandbox.io/s/mz13k4mw4x
   - This implementation can change behavior on a per usage basis but it cannot practically be done in CSS Modules
     without using real CSS Custom Properties
*/

:root {
  --flexWrapper: {
    display: inline-block;
    width: 100%;
  }

  --flexInnerBase: {
    display: flex;
    flex-wrap: wrap;

    /* Stop the negative margins preventing clicks part 1.
       This part makes the negative margins (and all children) unclickable */
    pointer-events: none;
  }

  --flexInner3: {
    @apply --flexInnerBase;

    /* Give the margin top the top/left by default. Then swap the margin from left to right for rtl languages
       which should prevent overflow and scrollbars both ways */
    margin: calc(0px - var(--s-3)) 0 0 calc(0px - var(--s-3));

    &:dir(rtl) {
      margin: calc(0px - var(--s-3)) calc(0px - var(--s-3)) 0 0;
    }
  }

  --flexInner10: {
    @apply --flexInnerBase;

    margin: calc(0px - var(--s-10)) 0 0 calc(0px - var(--s-10));

    &:dir(rtl) {
      margin: calc(0px - var(--s-10)) calc(0px - var(--s-10)) 0 0;
    }
  }

  --flexItemBase: {
    /* Stop the negative margins preventing clicks part 2.
       This part restores clicks for all the items and their children */
    pointer-events: auto;
  }

  --flexItem3: {
    @apply --flexItemBase;

    /* Give the margin top the top/left by default. Then swap the margin from left to right for rtl languages
       which should prevent overflow and scrollbars both ways */
    margin: var(--s-3) 0 0 var(--s-3);

    &:dir(rtl) {
      margin: var(--s-3) var(--s-3) 0 0;
    }
  }

  --flexItem10: {
    @apply --flexItemBase;

    margin: var(--s-10) 0 0 var(--s-10);

    &:dir(rtl) {
      margin: var(--s-10) var(--s-10) 0 0;
    }
  }

  /* Some helpers for setting widths correctly. Can't believe how awkward CSS Modules makes this */
  --flexItem3Width25Percent: {
    width: calc(25% - var(--s-3));
  }
  --flexItem3Width50Percent: {
    width: calc(50% - var(--s-3));
  }

  --flexItem10Width30Percent: {
    width: calc(var(--w-1-3) - var(--s-10));
  }
}
