@import '../../marketplace.css';
@import '../../landtrust.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --Avatar_size: 40px;
  --Avatar_small: 25px;
  --Avatar_sizeMedium: 60px;
  --Avatar_sizeLarge: 96px;

  --Plus_size: 16px;
  --Plus_small: 7px;
  --Plus_sizeMedium: 24px;
  --Plus_sizeLarge: 38px;

  /* Base for all avatars */
  --Avatar_base: {
    border-radius: 50%;

    /* Position possible initials to the center of the component */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    /* Colors */
    background-color: var(--olive-900);
    color: var(--matterColorLight);
    user-select: none;

    &:hover {
      text-decoration: none;
    }
  }
}

/* Small Avatar */

.root {
  /* Font is specific to this component, but defining it in marketplace.css makes it easier
   * to change font if brand look-and-feel needs such changes.
   */
  @apply --Avatar_base;

  /* Layout */
  width: var(--Avatar_size);
  height: var(--Avatar_size);
}

.initials {
  font-size: 14px;
  font-weight: var(--fontWeightBold);
}

.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
}

/* Medium Avatar */

.smallAvatar {
  @apply --Avatar_base;

  /* Fixed dimensions */
  width: var(--Avatar_small);
  height: var(--Avatar_small);
}
.mediumAvatar {
  @apply --Avatar_base;

  /* Fixed dimensions */
  width: var(--Avatar_sizeMedium);
  height: var(--Avatar_sizeMedium);
}

.mediumAvatar .initials {
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
  padding-bottom: 5px;
}

/* Large Avatar */

.largeAvatar {
  @apply --Avatar_base;

  /* Fixed dimensions */
  width: var(--Avatar_sizeLarge);
  height: var(--Avatar_sizeLarge);
}

.largeAvatar .initials {
  font-size: 30px;
  font-weight: var(--fontWeightSemiBold);
}

.extraLargeAvatar {
  @apply --Avatar_base;

  width: var(--s-84);
  height: var(--s-84);

  @media (--screen-md) {
    width: var(--s-100);
    height: var(--s-100);
    margin-top: var(--s-4);
  }
}

.extraLargeAvatar .initials {
  font-size: 30px;
  font-weight: var(--fontWeightSemiBold);
}

.bannedUserIcon {
  width: 100%;
  height: 100%;
}

.plusIcon,
.smallBadge,
.mediumBadge,
.largeBadge,
.extraLargeBadge {
  position: absolute;
}

.plusIcon {
  width: var(--s-4);
  top: -0.5rem;
  right: -0.5rem;
}

.smallBadge {
  width: var(--Plus_small);
  top: -0.25rem;
  right: -0.5rem;
}

.mediumBadge {
  width: var(--Plus_sizeMedium);
  top: -0.25rem;
  right: -0.5rem;
}

.largeBadge {
  width: var(--Plus_sizeLarge);
  top: -0.25rem;
  right: -0.5rem;
}

.extraLargeBadge {
  width: var(--Plus_sizeLarge);
  top: -0.25rem;
  right: -0.5rem;
}
