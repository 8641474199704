@import '../../landtrust.css';

.root {
  background-color: var(--gray-100);
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.heroImageColumn {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: var(--s-48);
}

.column {
  padding: var(--s-7) var(--s-8);
}

.title {
  line-height: var(--line-height-tight);
  margin-bottom: var(--s-8);
}

.cta {
  margin: var(--s-8) 0;
  text-align: center;
}

@media (--screen-lg) {
  .root {
    padding-top: var(--s-24);
    margin-bottom: var(--s-12);
  }

  .wrapper {
    flex-direction: row;
  }

  .heroImageColumn {
    width: var(--w-3-5);
    border-radius: var(--border-radius);
    order: 1;
    margin-bottom: calc(-1 * var(--s-12));
  }

  .contentColumn {
    width: var(--w-2-5);
    font-size: var(--text-lg);
  }

  .cta {
    text-align: left;
  }
}
