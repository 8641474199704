@import '../../landtrust.css';

.root {
}

.fieldLabel {
  font-size: var(--text-lg);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);
}

.inputGroup {
  position: relative;
}

.passwordSection {
  margin-top: var(--s-6);
  position: relative;
  margin-bottom: var(--s-4);
}

.newPasswordSection {
  position: relative;
}

.passwordIcon,
.oldPasswordIcon {
  position: absolute;
  right: var(--s-3);
  width: var(--s-5);
  height: var(--s-5);
  color: var(--gray-900);
  &:hover {
    cursor: pointer;
  }
}

.passwordIcon {
  top: var(--s-11);
}

.oldPasswordIcon {
  top: var(--s-24);

  @media (--screen-sm) {
    top: var(--s-19);
  }
}

.passwordIconError {
  position: absolute;
  top: var(--s-10);
  right: var(--s-3);
  width: var(--s-5);
  height: var(--s-5);
  color: var(--gray-900);
  &:hover {
    cursor: pointer;
  }
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  display: block;
}

.confirmChangesSectionVisible {
  display: block;
}

.confirmChangesTitle {
  color: var(--gray-900);
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  margin-top: 0;
  margin-bottom: var(--s-3);

  @media (--screen-md) {
    margin-top: 0;
    margin-bottom: var(--s-4);
    padding-top: var(--s-1);
    padding-bottom: var(--s-1);
  }
}

.confirmChangesInfo {
  margin-top: 0;
  margin-bottom: var(--s-9);

  @media (--screen-md) {
    margin-bottom: var(--s-12);
  }
}

.passwordChangedSuccess {
  display: block;
  font-size: var(--text-sm);
  margin-top: var(--s-2);
  color: var(--green-500);
  font-weight: var(--font-medium);
}

.bottomWrapper {
  margin-top: var(--s-11);

  @media (--screen-md) {
    margin-top: var(--s-24);
  }
}
