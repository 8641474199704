@import '../../landtrust.css';

.root {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  text-align: center;
  padding: var(--s-20) 0;
}

.notFoundImage {
  height: auto;
  max-width: var(--s-44);

  @media (--screen-md) {
    max-width: var(--s-84);
  }
}

.heading {
  margin: auto;
  margin-top: var(--s-8);
  max-width: var(--w-2-3);
  font-size: var(--text-2xl);

  @media (--screen-md) {
    max-width: 100%;
    font-size: var(--text-5xl);
  }
}

.description {
  margin: auto;
  margin-top: var(--s-8);
  max-width: var(--w-3-4);

  @media (--screen-md) {
    max-width: var(--w-2-3);
    font-size: var(--text-2xl);
  }
}

.actions {
  margin: auto;
  margin-top: var(--s-8);
  max-width: var(--w-2-3);

  @media (--screen-md) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.action {
  width: 100%;
  margin: var(--s-2);
}

.actionPrimary {
  background-color: var(--hunter);
  border-color: var(--hunter);

  &:hover {
    background-color: var(--hunter);
    border-color: var(--hunter);
  }
}

.actionSecondary {
  border-color: var(--hunter);
  color: var(--hunter);

  &:hover {
    background-color: var(--hunter);
    border-color: var(--hunter);
  }
}
