@import '../../landtrust.css';

.input {
  min-height: 50px;
  border: 1px solid var(--gray-300);
}

.inputError {
  border-color: var(--red-900);
}

.textarea {
  min-height: 150px;
}

.helpWrapper {
  display: flex;
  justify-content: flex-end;
}

.errorWrapper {
  flex-grow: 1;
}

.successWrapper {
  flex-grow: 1;
  color: var(--brand);
}

.proTipWrapper {
  margin-top: var(--s-2);
  margin-bottom: var(--s-4);
}

.proTipTextArea {
  display: block;
}

.skeletonContainer {
  display: block;
  line-height: 1;
}
