@import '../../marketplace.css';
@import '../../landtrust.css';

.containerStyle {
  flex: 1;
  border-radius: var(--border-radius-xl);
  box-shadow: var(--box-shadow);
  border: 1px solid var(--gray-200);
  padding: var(--s-2) var(--s-4) var(--s-6) var(--s-5);
  position: relative;
  margin-bottom: var(--s-4);

  @media (--screen-md) {
    margin-bottom: 0;
    padding: var(--s-6) var(--s-4) var(--s-6) var(--s-6);
  }
}

.reviewTextStyles {
  width: 78.66vw;
  font-size: var(--text-base);
  margin-bottom: var(--s-10);

  @media (--screen-md) {
    width: auto;
  }
}

.contentWrapper {
  display: flex;
}

.statusStyles {
  font-size: var(--text-xs);
  font-weight: normal;
  margin-bottom: 2px;
}

.quotesStyles {
  width: var(--s-12);
  height: var(--s-12);
  margin-top: -16px;
  margin-bottom: -8px;

  @media (--screen-md) {
    margin-top: -32px;
  }
}

.sportsmanStyles {
  font-size: var(--text-base);
}

.sportsmanImage {
  width: var(--s-13);
  height: var(--s-16);
  margin-right: var(--s-2);
}

.sportsmanContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
