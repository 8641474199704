@import '../../landtrust.css';

.root {
  display: inline-flex;
  align-items: center;
  color: var(--brand);
  font-family: var(--font-base);
  font-weight: bold;
}

.icon {
  height: 20px;
  margin-right: 8px;
}
