@import '../../landtrust.css';

.accordionItemContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: var(--s-7) 0 var(--s-7) 0;
  border-bottom: 1px solid var(--gray-300);
}

.itemTitle {
  font-weight: var(--font-bold);
  padding-right: var(--s-3);
  cursor: pointer;

  &:hover {
    color: var(--gray-600);
  }
}

.itemContent {
  margin-top: var(--s-4);
  padding-right: var(--s-8);
}

.buttonIcon {
  color: var(--black);
  font-weight: var(--font-bold);
  font-family: var(--font-base);
  display: flex;
  align-items: center;
  margin-bottom: var(--s-4);
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.buttonIcon > svg {
  width: var(--s-6);
  height: var(--s-6);
  transition: 0.3s;

  &:hover {
    color: var(--gray-600);
  }
}

.totalDivider {
  height: 1px;
  margin-top: var(--s-6);
  border: none;
  background-color: var(--gray-300);
}