@import '../../../landtrust.css';

.content {
  position: relative;
  z-index: var(--z-20);
  padding-left: 0;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  margin-top: var(--s-6);
  margin-bottom: var(--s-2);

  @media (--screen-lg) {
    align-items: center;
    margin-top: var(--s-4);
  }
}

.descriptionStyles {
  font-family: var(--font-base);
  margin-bottom: var(--s-2);
  font-size: var(--text-sm);
  color: var(--gray-900);
  z-index: 2;
}

.avatarContainer {
  position: relative;
}

.postWrapper {
  margin-bottom: var(--s-5);

  @media (--screen-lg) {
    margin-bottom: var(--s-3);
  }
}

.titleLink {
  text-decoration: underline;
}

.propertyNameContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: var(--s-2);
  margin-top: var(--s-1);

  @media (--screen-md) {
    margin-top: var(--s-2);
  }
}

.dateContainer {
  display: flex;
  flex-direction: column;

  @media (--screen-lg) {
    flex-direction: row;
  }
}

.landownerContainer {
  font-size: var(--text-xs);
  color: var(--gray-900);
  margin: 0;
}

.propertyName {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
}

.dateWrapper {
  margin-bottom: var(--s-2);
}

.customAvatarSize {
  width: var(--s-12);
  height: var(--s-12);
}

.gridPhotos {
  position: relative;
  height: calc(0.51 * 100vw);
  max-height: 50vh;
  z-index: 2;
  display: block;
  max-height: var(--s-88);
  margin-bottom: var(--s-7);

  @media (--screen-md) {
    height: calc(0.41 * 100vw);
  }
}

.testing {
  position: relative;
  z-index: var(--z-200);
}

.step {
  max-width: 685px;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  object-fit: cover;
  height: 100%;
  max-height: var(--s-75);
  margin-bottom: 0;
  width: 100%;

  @media (--screen-md) {
    max-height: var(--s-112);
  }
}

.imageStyles {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videoStyles {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: black;
}

.collage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: var(--s-2);
  background-color: var(--white);
}

.collage1Photo {
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.collage2Photos {
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.collage3Photos {
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.collage4Photos {
  grid-template-columns: minmax(0, 0.25fr) minmax(0, 0.25fr) minmax(0, 0.5fr);
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.collage4PhotosItem {
  &:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

  &:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }

  &:nth-child(3) {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }

  &:nth-child(4) {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }
}

.collage5PlusPhotos {
  grid-template-columns: minmax(0, 0.5fr) minmax(0, 0.25fr) minmax(0, 0.25fr);
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.collage5PlusPhotosItem {
  &:nth-child(1) {
    grid-column: 1;
    grid-row: 1 / 3;
  }

  &:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  }

  &:nth-child(3) {
    grid-column: 2;
    grid-row: 2;
  }

  &:nth-child(4) {
    position: relative;
    grid-column: 3;
    grid-row: 1;
  }

  &:nth-child(5) {
    position: relative;
    grid-column: 3;
    grid-row: 2;
  }
}

.collageImage {
  width: 100%;
  height: 100%;
  background-color: var(--matterColorNegative);
  cursor: pointer;
  object-fit: cover;
  transition: var(--transitionStyleButton);
  border-radius: var(--s-3);

  &:hover {
    transform: scale(1.005);
    box-shadow: var(--boxShadowListingCard);
  }
}

.singleMedia {
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.singleVideo {
  object-fit: contain;
  width: 100%;
  height: 100%;
  background-color: black;
}

.viewImagesWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewPhotosBlurWrapper {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewPhotosBlur {
  /* background: url('./rectangle.png'); */
  background-color: rgba(21, 21, 21, 0.6);
  -webkit-filter: blur(43px);
  filter: blur(43px);
  width: var(--s-48);
  height: var(--s-16);
  display: block;
}

.viewPhotos {
  font-size: var(--text-lg);
  width: 100%;
  transition: var(--transitionStyleButton);
  color: var(--white);
  font-weight: var(--font-bold);
  display: block;
  z-index: var(--z-10);

  @media (--screen-md) {
    font-size: var(--text-3xl);
  }
}

.viewPhotosText {
  line-height: var(--line-height-none);
}

.sectionDivider {
  height: 1px;
  border: none;
  background-color: var(--gray-300);
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
  padding: var(--s-6);
}

.modalContentContainer {
  height: 100%;
}

.customCloseButton {
  position: absolute;
  top: var(--s-20);
  right: var(--s-6);
  width: var(--s-8);
  height: var(--s-8);
  padding: var(--s-2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: var(--line-height-none);
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
  background-color: var(--white);
  color: black;
  box-shadow: var(--box-shadow-xl);

  @media (--screen-md) {
    top: var(--s-10);
  }
}

.imageModalWrapper {
  position: relative;
  z-index: var(--z-200);
}
