@import '../../landtrust.css';

.modalWrapper {
  height: 100%;
  margin-top: var(--s-24);

  @media (--screen-md) {
    margin-top: 0;
  }
}

.app {
  position: relative;
  width: 100%;
  max-width: var(--s-147);
  margin: auto;
}

.cropContainer {
  width: 100%;
  height: var(--s-84);
  overflow: hidden;

  @media (--screen-md) {
    margin-top: var(--s-13);
    height: var(--s-93);
  }
}

.modalButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.saveButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: var(--s-7);
  padding: var(--s-4) var(--s-2) var(--s-4) var(--s-2);
  border-radius: var(--s-1);
}

.saveButtonContainer:hover {
  background-color: var(--gray-800);
}

.savePhotoButtonText {
  color: black;
  background-color: var(--white);
  border-radius: var(--border-radius-xxxl);
  padding: var(--s-1) var(--s-5) var(--s-1) var(--s-5);
  cursor: pointer;
  font-size: var(--text-lg);
  font-family: var(--font-base);
  font-weight: 600;
}

.controls {
  padding-top: var(--s-8);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-right: var(--s-4);

  @media (--screen-md) {
    padding-left: var(--s-4);
    padding-right: 0;
  }
}

.sliderContainer {
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: var(--s-3);

  @media (--screen-md) {
    margin-left: var(--s-10);
  }
}

.sliderLabel {
  font-family: var(--font-base);
  font-size: var(--text-lg);
  color: var(--white);
}

.slider {
  color: var(--white) !important;
  margin-left: var(--s-4);
}
