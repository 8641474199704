@import '../../landtrust.css';

.root {
  padding-bottom: 10vh;

  @media (--screen-md) {
    flex-direction: row;
    height: 90vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10vh;

  }
}

.textContainer {
  height: 70vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;

  @media (--screen-md) {
    display: flex;
    flex: 1;
    align-items: flex-start;
    margin-left: var(--s-44);
    margin-right: var(--s-5);
    max-width: var(--s-130);
  }
}

.notFoundImage {
  height: auto;
  max-width: var(--s-44);

  @media (--screen-md) {
    max-width: var(--s-103);
  }
}

.imageContainer {
  display: none;
  @media (--screen-md) {
    display: flex;
    flex: 1;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.heading,
.headingBold,
.description {
  font-family: var(--font-base);
}

.heading,
.headingBold {
  line-height: 1.1;
}

.heading {
  font-weight: normal;
  text-align: left;
  font-size: var(--s-8);

  @media (--screen-md) {
    max-width: 100%;
    font-size: var(--s-13);
  }
}

.headingBold {
  font-weight: bold;
  font-style: italic;
  text-align: left;
  font-size: var(--s-9);

  @media (--screen-md) {
    max-width: 100%;
    font-size: var(--s-15);
  }
}

.description {
  margin-top: var(--s-8);
  font-weight: normal;
  text-align: left;
  font-size: var(--s-3);
  max-width: var(--s-80);

  @media (--screen-md) {
    max-width: 100%;
    font-size: var(--s-5);
  }
}

.actions {
  margin-top: var(--s-8);

  @media (--screen-md) {
    display: flex;
    justify-content: left;
  }
}

.actionPrimary {
  padding-left: var(--s-10);
  padding-right: var(--s-10);
  background-color: var(--olive-500);
  border-color: var(--olive-500);

  &:hover {
    background-color: var(--olive-500);
    border-color: var(--olive-500);
  }
}
