@import '../../landtrust.css';

/* Content is visible as modal layer */
.isOpen {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: none;
  }

  & .container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: var(--s-230);
    padding: var(--s-6);
    background-color: var(--white);
    border-radius: var(--border-radius);
    border-bottom: none;
    flex-grow: 0;
    min-height: auto;
    min-width: var(--w-3-4);
    height: auto;
    overflow: hidden;
    margin: 0 var(--s-6);
    @media (--screen-lg) {
      min-width: var(--w-2-5);
    }
  }
}

.content {
  overflow: auto;
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  position: absolute;
  top: var(--s-4);
  right: var(--s-6);
  width: var(--s-8);
  height: var(--s-8);
  padding: var(--s-2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: var(--line-height-none);
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
  z-index: 10;
}

.closeText {
}

.closeIcon {
  height: var(--s-8);
  width: var(--s-8);
}

.closeLight {
  background-color: var(--white);
  color: var(--gray-900);
  box-shadow: var(--box-shadow-xl);
}
