@import '../../landtrust.css';

.root {
}

.imageContainer {
  position: relative;
  padding: 0 var(--s-5);
  margin-bottom: var(--s-8);
  margin-left: calc(-1 * var(--s-5));

  &:before {
    position: absolute;
    top: var(--s-5);
    left: var(--s-10);
    bottom: calc(-1 * var(--s-5));
    right: 0;
    border-radius: var(--border-radius-xl);
    background-color: var(--gray-400);
    content: '';
    z-index: -1;
  }
}

.image {
  max-width: 100%;
  border-radius: var(--border-radius-xl);
}

.contentContainer {
  text-align: center;
  padding: 0 var(--s-3);
}

.quotes {
  margin-bottom: calc(-1 * var(--s-3));
  text-align: center;
  font-size: var(--s-20);
  font-family: var(--font-headings);
  line-height: var(--line-height-none);
  user-select: none;
}

.body {
  font-size: var(--text-lg);
  margin-bottom: var(--s-5);
}

.author {
  font-size: var(--text-lg);
  font-weight: var(--font-medium);
  margin-bottom: var(--s-1);
}

@media (--screen-lg) {
  .root {
    padding: var(--s-32) 0;
    display: flex;
  }

  .imageContainer {
    width: var(--s-1-2);
    margin-right: var(--s-12);
    padding: 0 2rem;

    &:before {
      left: var(--s-12);
    }
  }

  .image {
    max-width: 575px;
  }

  .quotes {
    text-align: left;
    font-size: var(--s-24);
    line-height: 0.5;
  }

  .contentContainer {
    padding: var(--s-24) 0;
    width: var(--s-1-2);
    text-align: left;
  }

  .body {
    font-size: var(--text-2xl);
  }

  .author {
    font-size: var(--text-2xl);
  }

  .location {
    font-size: var(--text-xl);
  }
}
