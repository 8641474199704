@import '../../marketplace.css';
@import '../../landtrust.css';

.root {
  position: relative;
  display: inline-block;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 300px;
  margin-top: 7px;
  background-color: var(--matterColorLight);

  /* Borders */
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.popupSize {
  padding: 15px 30px 17px 30px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modalContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.showResultsButton {
  margin-top: auto;
  flex-shrink: 0;
}

.clearButton {
  margin-top: var(--s-2);
  padding: var(--s-4);
  flex-shrink: 0;
  color: var(--gray-600);
}
