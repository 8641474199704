@import '../../landtrust.css';

.root {
}

.rootAsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--s-3);
}

.inputWrapper {
  position: relative;
}

.inputRoot {
  max-width: var(--s-32);
  padding-left: var(--s-10);
  padding-right: var(--s-10);
  text-align: center;
  color: var(--gray-900);
  font-weight: var(--font-medium);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  -moz-appearance: textfield;
}

.inputRootNoButtons {
  text-align: left;
  padding-left: var(--s-3);
  padding-right: var(--s-4);
  max-width: var(--s-20);
}

.inputButton {
  position: absolute;
  top: calc(50% - 12px);
  min-width: 22px;
  padding: 0 var(--s-1);
  color: var(--brand);
  cursor: pointer;

  &:disabled {
    color: var(--olive-400);
  }
}

.inputButtonIncrement {
  right: var(--s-5);
}

.inputButtonDecrement {
  left: var(--s-5);
}

.inputError {
  border-color: var(--red-500);
}

.unit {
  position: absolute;
  right: var(--s-4);
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
