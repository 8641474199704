@import '../../landtrust.css';

.root {
  display: flex;
  align-items: center;
  gap: var(--s-1);
}

.link {
  text-decoration: underline;
}

.icon {
  width: var(--s-6);
  height: var(--s-6);
  fill: var(--gray-900);

  & > path {
    fill: var(--gray-900);
  }
}
