@import '../../landtrust.css';

.root {
}

.container {
  padding: 0 var(--s-5) 0 var(--s-5);

  @media (--screen-lg) {
    padding: 0 var(--s-6) 0 var(--s-12);
  }
}

.listingCards {
  margin-bottom: var(--s-9);

  @media (--screen-md) {
    margin-bottom: var(--s-16);
  }
}

.listingCard {
  margin-bottom: var(--s-9);

  @media (--screen-md) {
    margin-bottom: var(--s-5);
  }
}

.pagination {
  margin-bottom: var(--s-8);

  @media (--screen-md) {
    margin-bottom: var(--s-16);
  }
}
