@import '../../landtrust.css';

.container {
  width: 100%;
  max-width: var(--s-142);
}

.header {
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);
}

.headerLarge {
  font-size: var(--text-xl);
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: var(--s-3);
  margin-bottom: var(--s-6);
}

.itemBase {
  display: flex;
  align-items: center;
  font-size: var(--text-xs);
  border-radius: var(--border-radius-xxl);
  border: 1px solid var(--gray-400);
  padding: var(--s-2) var(--s-3);

  &:hover {
    text-decoration: none;
  }
}

.item {
  composes: itemBase;
}

.itemSelected {
  composes: itemBase;

  background-color: var(--gray-900);
  color: var(--white);
}
