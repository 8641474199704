@import '../../landtrust.css';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: var(--gray-900);
  gap: 8px;
  padding: 8px 16px;
}

.button:hover {
  background-color: #f3f6f3;
  color: var(--gray-900);
}

.button:focus {
  outline: 2px solid var(--gray-900);
}

.icon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}
