@import '../FlexHelper/FlexHelper.css';

.root {
  position: relative;
}

.filtersWrapper {
  @apply --flexWrapper;
}

.filtersInner {
  @apply --flexInner3;
}

.filter {
  @apply --flexItem3;
}
