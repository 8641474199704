@import '../../landtrust.css';

.root {
  margin-bottom: var(--s-10);

  @media (--screen-md) {
    margin-bottom: var(--s-6);
  }

  &:last-child {
    margin-bottom: 0;
  }
}
