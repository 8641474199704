@import '../../landtrust.css';

.earlyAccess {
  display: flex;
  flex-direction: column;
}

.packageAvailableTimer {
  display: flex;
  justify-content: center;
  margin: var(--s-3) 0;
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
}
