@import '../../landtrust.css';

.root {
  position: absolute;
  z-index: var(--z-20);
  display: flex;
  bottom: var(--s-3);
  right: var(--s-3);
}

.baseBadge {
  display: flex;
  justify-content: center;
  font-size: var(--text-xs);
  font-weight: var(--font-bold);
  padding: var(--s-1) var(--s-4);
  border-radius: 7px;
}

.acceptedBadge {
  composes: baseBadge;
  background-color: var(--olive-300);
  color: var(--olive-500);
}

.declinedBadge {
  composes: baseBadge;
  background-color: var(--gray-100);
  color: var(--gray-900);
}
