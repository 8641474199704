@import '../../marketplace.css';

.page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}

.topbar {
}

.topbarMobile {
  box-shadow: none;
}

.container {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  @media (--screen-lg) {
    flex-direction: row;
  }
}

.formContainer {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;

  @media (--screen-md) {
    margin-top: var(--s-8);
  }

  @media (--screen-lg) {
    margin-top: var(--s-16);
  }
}

.coltonContainer {
  @media (--screen-lg) {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    width: 350px;
    margin-left: var(--s-12);
    margin-top: var(--s-24);
  }
}

.fullColton {
  display: none;

  @media (--screen-lg) {
    display: block;
  }
}

.miniColton {
  margin-bottom: var(--s-8);

  @media (--screen-lg) {
    display: none;
  }
}

.topBarExitButton {
  font-family: var(--font-base);
  font-weight: bold;
  font-size: var(--text-sm);
  color: var(--font-color);
  cursor: pointer;
}

.progressBarSection {
  margin-bottom: var(--s-7);
}
