@import '../../landtrust.css';

.root {
  background-color: var(--gray-800);
  min-height: var(--s-72);
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: var(--border-radius);
  overflow: hidden;

  @media (--screen-md) {
    flex-direction: row;
  }
}

.imageContainer {
  width: 100%;
  overflow: hidden;
  max-height: var(--s-40);

  @media (--screen-md) {
    width: var(--w-1-2);
    max-height: 100%;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reviewContainer {
  color: var(--white);
  padding: var(--s-4) var(--s-4);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  @media (--screen-md) {
    width: var(--w-1-2);
    padding: var(--s-10) var(--s-24);
    padding-bottom: var(--s-12);
  }
}

.reviewBody {
}

.reviewHeader {
  text-align: center;
}

.reviewRating {
  margin-bottom: var(--s-3);
  display: block;

  @media (--screen-md) {
    margin-bottom: var(--s-9);
  }
}

.reviewRatingStar {
  width: var(--s-3);
  height: var(--s-3);

  @media (--screen-md) {
    width: initial;
    height: initial;
  }
}

.reviewTitle {
  font-size: var(--text-lg);
  font-weight: var(--font-medium);
  margin-bottom: var(--s-1);

  @media (--screen-md) {
    font-size: var(--text-3xl);
    margin-bottom: var(--s-2);
  }
}

.reviewLocation {
  font-size: var(--text-xs);
}

.reviewQuotes {
  margin-top: var(--s-2);
  margin-bottom: calc(-1 * var(--s-3));
  text-align: center;
  font-size: var(--text-6xl);
  font-family: var(--font-headings);
  line-height: var(--line-height-none);
  user-select: none;
}

.reviewContent {
  margin-bottom: var(--s-8);
  font-size: var(--text-sm);
}

.reviewCta {
  text-align: center;
}
