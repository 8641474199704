@import '../../marketplace.css';
@import '../../landtrust.css';

:root {
  --fieldBase: {
    margin-top: var(--s-4);

    @media (--viewportMedium) {
      margin-top: var(--s-3);
    }
  }
}

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  @apply --fieldBase;
  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
    margin-top: var(--s-2);
  }
}

.firstNameRoot {
  width: 100%;

  @media (--viewportMedium) {
    @apply --fieldBase;
    width: calc(50% - 9px);
  }
}

.lastNameRoot {
  @apply --fieldBase;
  width: 100%;

  @media (--viewportMedium) {
    width: calc(50% - 9px);
  }
}

.email {
  @apply --fieldBase;
}

.password {
  @apply --fieldBase;
}

.phone {
  @apply --fieldBase;
}

.role {
  @apply --fieldBase;
}

.bottomWrapper {
  margin-top: var(--s-6);
  margin-bottom: var(--s-6);

  @media (--viewportMedium) {
    margin-top: var(--s-10);
  }
}

.bottomWrapperText {
  margin-top: var(--s-4);
  text-align: center;
}

.termsText {
  font-size: var(--text-xs);
}

.termsLink {
  font-size: var(--text-xs);
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.inputGroup {
  position: relative;
}

.passwordIcon {
  position: absolute;
  top: calc(60% - (var(--s-1) / 2));
  right: var(--s-3);
  width: var(--s-5);
  height: var(--s-5);
  color: var(--gray-900);

  &:hover {
    cursor: pointer;
  }
}

.passwordIconError {
  position: absolute;
  top: calc(43% - (var(--s-1) / 2));
  right: var(--s-3);
  width: var(--s-5);
  height: var(--s-5);
  color: var(--gray-900);

  &:hover {
    cursor: pointer;
  }
}

.rolesSection {
  margin-top: var(--s-7);
  font-weight: var(--font-medium);
}

.rolesSportsman {
  margin-bottom: var(--s-2);
}

.activitiesSection {
  margin-top: var(--s-3);
}

.fieldLabel {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);
}

.inputGroup {
  position: relative;
}

.formSection {
  margin-bottom: var(--s-6);
}

.formHeader {
  font-family: var(--font-base);
  font-size: var(--text-4xl);
}

.formSubHeader {
  margin-top: var(--s-4);
  margin-bottom: var(--s-5);
}

.coltonContainer {
  @media (--screen-lg) {
  }
}

.referralOther {
  margin-top: var(--s-7);
}

.huntSpeciesContainer {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.huntSpeciesItem {
  flex: 0 50%;
  padding-right: 15px;
  padding-left: 15px;
}

.nextBtnDesktop {
  display: inline-block;
  min-width: var(--s-84);
}
