@import '../../landtrust.css';

.root {
  margin-bottom: 0;

  @media (--screen-md) {
    margin-bottom: var(--s-3);
  }
}

.title {
  display: none;

  @media (--screen-md) {
    display: block;
    @apply --typography-heading-sans-serif;
    font-size: var(--text-xl);
    font-weight: var(--font-bold);
  }
}

.subtitle {
  margin: 0;
  font-size: var(--text-sm);
}
