@import '../../landtrust.css';

.fieldSurfaceSizeWrapper {
  display: flex;
  flex-direction: column;

  @media (--screen-md) {
    flex-direction: row;

    & > span:first-of-type {
      margin-right: var(--s-8);
    }
  }
}

.fieldSurfaceSize {
  & label {
    margin-right: var(--s-4);
    font-weight: normal;
  }
}

.addButtonWrapper {
  margin-bottom: var(--s-8);
}

.addButton {
  border: solid 1px var(--gray-900);
  padding: var(--s-2) var(--s-12) var(--s-2) var(--s-8);
  color: var(--gray-900);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-xxxl);

  &:hover {
    background-color: var(--gray-100);
    color: var(--gray-900);
  }
}

.buttonFlex {
  display: flex;
  align-items: center;
  font-weight: var(--font-bold);
}

.plusCircle {
  width: var(--s-7);
  height: var(--s-7);
  color: var(--gray-900);
  margin-right: var(--s-5);
}

.fieldInputPrice {
  & input {
    width: var(--s-29);
  }
}

.removeCampsiteLink {
  margin: var(--s-7) 0;
  display: flex;
  width: 100%;
  justify-content: center;
  text-decoration: underline;
}
