@import '../../landtrust.css';

.inquiryExpiredWrapper {
  display: flex;
  width: 100%;

  @media (--screen-md) {
    width: var(--w-4-5);
  }
}

.inquiryGatedWrapper {
  display: flex;
  justify-content: flex-end;
}

.guestInquiryGatedWrapper {
  justify-content: flex-start;
}

.inquiryGated {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (--screen-md) {
    width: var(--w-3-4);
  }
}

.guestInquiryGated {
  align-items: flex-start;
}

.inquiryGatedTitle {
  font-weight: normal;
  color: var(--gray-600);
  font-size: var(--text-sm);
  margin-bottom: var(--s-2);
  display: flex;
  align-items: center;
}

.inquiryGatedTitleWithAuthor {
  margin-bottom: 0;
  margin-top: var(--s-4);
}

.inquiryGatedAuthor {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
  color: var(--gray-900);
  margin: 0 var(--s-2) 0 var(--s-2);
}

.bookingStatus {
  margin: auto;
  width: 100%;
  padding: var(--s-6) var(--s-4) var(--s-6) var(--s-5);
  border-radius: var(--s-3);
  margin-bottom: var(--s-7);

  @media (--screen-md) {
    margin: 0 0 var(--s-5) 0;
  }
}

.bookingStatusHeader {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-1);
}

.bookingStatusBody {
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-1);
}

.bookingStatusDate {
  font-size: var(--text-xs);
  font-weight: normal;
}

.avatar {
  width: var(--s-10);
  height: var(--s-10);
  margin-left: var(--s-4);
  position: relative;
  top: 6px;
  border: 3px solid var(--white);
}

.divider {
  height: 1px;
  background-color: var(--gray-300);
  margin: var(--s-5) 0;
}
