@import '../../landtrust.css';

.filterButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: var(--gray-300);
  gap: 8px;
  padding: 8px 16px;
}

.filterButtonSelected {
  composes: filterButton;
  background-color: var(--gray-900);
  border-color: var(--gray-900);
  color: var(--white);
}

.filterButtonSelected:hover {
  background-color: var(--gray-900);
  border-color: var(--gray-900);
  color: var(--white);
}

.filterButtonOpen {
  border-color: var(--gray-900);
}

.dropdown {
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  padding: var(--s-4);
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid var(--gray-300);
  background-color: var(--white);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: var(--s-2);
}

.filterLabel {
  font-size: var(--text-sm);
  font-weight: normal;
  color: var(--gray-900);
  white-space: nowrap;
}
