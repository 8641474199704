@import '../../landtrust.css';

.header {
  display: flex;
  text-align: center;
  color: var(--gray-900);
  font-weight: var(--font-bold);
  font-size: var(--text-xl);
}

.buttonYes {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
}

.errorMessage {
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  color: var(--red-900);
  border-radius: var(--border-radius);
  border: 1px solid var(--red-900);
  padding: var(--s-3);
}
