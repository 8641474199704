@import '../../../landtrust.css';

.heading {
  margin-top: var(--s-8);
}

.bookingDetailsTitle {
  font-size: var(--text-xl);
  font-weight: bold;
  margin-bottom: var(--s-3);
  margin-left: var(--s-5);

  @media (--screen-md) {
    margin-left: 0;
  }
}

.detailsGroup {
  @media (--screen-md) {
    display: grid;
    grid-gap: var(--s-2);
    grid-template-columns: repeat(2, minmax(0, 100%));
    grid-auto-rows: minmax(min-content, max-content);
  }
}

.detailsSection {
  margin-bottom: var(--s-6);
}

.detailsSectionLabel {
  font-weight: bold;
}

.summaryContainer {
  order: 1;

  @media (--screen-md) {
    order: 1;
  }
}

.messageContainer {
  order: 4;
  margin-bottom: var(--s-7);

  @media (--screen-md) {
    order: 2;
    width: var(--s-100);
  }

  @media (--screen-lg) {
    order: 2;
    width: var(--s-142);
  }
}

.breakdownContainer {
  order: 2;
  border-top: 1px solid var(--gray-300);

  @media (--screen-md) {
    display: none;
  }
}

.topicsContainer {
  order: 3;
  margin-top: var(--s-5);
  margin-bottom: var(--s-5);
  padding: var(--s-6) var(--s-3);
  border-radius: var(--border-radius-xxxl);
  border: 1px solid var(--gray-300);

  @media (--screen-md) {
    display: none;
  }
}

.icon {
  width: var(--s-9);
  margin-right: var(--s-3);
  color: var(--gray-500);
}

.iconSectionWrapper {
  display: flex;
  align-items: center;
  margin-bottom: var(--s-5);
}

.iconSection {
  display: flex;
  flex-direction: column;
}

.iconSectionLabel {
  font-size: var(--text-sm);
}

.iconSectionValue {
  font-weight: var(--font-bold);
}
