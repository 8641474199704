@import '../../landtrust.css';

.root {
  position: relative;
  width: auto;
  height: auto;
  border: 0;
  padding: 0;

  &:hover {
    & .priceLabel,
    & .caret {
      cursor: pointer;

      /* Same as active */
      background-color: var(--brand);
      border-color: var(--brand);
    }
  }
}

.priceLabel {
  position: relative;
  @apply --typography-base;
  background-color: var(--gray-800);
  border-color: var(--gray-800);
  color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-xl);
  padding: var(--s-2);
  transition: var(--transition-fast);
}

.priceLabelActive {
  /* Same as hover */
  background-color: var(--brand);
  border-color: var(--brand);
}

.caretShadow {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  /* Caret should have same box-shadow as label */
  box-shadow: var(--box-shadow-xl);
}

.caret {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);
  transition: var(--transition-fast);

  /* Caret should have same bg-color and border as label */
  background-color: var(--gray-800);
  border-right: 1px solid var(--gray-800);
  border-bottom: 1px solid var(--gray-800);
}

.caretActive {
  /* Same as hover */
  border-color: var(--brand);
}
