@import '../../landtrust.css';

.header {
  display: flex;
  text-align: center;
  color: var(--gray-900);
  font-weight: var(--font-bold);
  font-size: var(--text-xl);
}

.buttonYes {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
}
