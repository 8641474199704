@import '../../landtrust.css';

.root {
  padding: 0 var(--s-6);
  margin-bottom: var(--s-6);

  @media (--screen-md) {
    padding: 0;
    margin-bottom: var(--s-6);
  }
}

.header {
  font-size: var(--text-lg);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);
}

.sectionDetails {
  padding: var(--s-4) var(--s-7) var(--s-4) var(--s-4);
  margin-bottom: var(--s-5);
  width: 100%;
  border-radius: var(--border-radius-xl);
  border: solid 1px var(--gray-300);
}

.sectionHeader {
  margin-bottom: 0;
  font-size: var(--text-lg);
  font-weight: var(--font-bold);
}

.sectionDetailsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--s-5);

  &:first-of-type {
    margin-top: var(--s-4);
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.siteDetailsContainer {
  display: flex;
  flex-direction: column;
  margin-left: var(--s-9);
  margin-bottom: var(--s-5);
}

.sectionDetailsContainerDisabled {
  opacity: 0.5;
}

.sectionDetailsWrapper {
  display: flex;
  align-items: center;
}

.sectionDetailsIcon {
  margin-right: var(--s-2);
}

.sectionDetailsContent {
  display: flex;
  flex-direction: column;
}

.sectionDetailsHeader {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
}

.sectionDetailsInfo {
  margin-left: var(--s-9);
}

.sectionDetailsInfoOptions {
  margin-left: var(--s-9);
}

.sectionListWrapper {
  display: flex;
  flex-direction: column;
  gap: 0 var(--s-20);
  margin-left: var(--s-9);

  @media (--screen-md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: var(--s-9);
  }
}

.sectionInfoWrapper {
  margin-left: var(--s-9);
}

.formFieldWrapper {
  max-width: 100%;

  @media (--screen-md) {
    max-width: var(--s-56);
    margin-top: var(--s-5);
    margin-left: var(--s-9);
  }
}

.buttonText {
  padding: 0;
  font-size: var(--text-base);
  font-weight: bold;
  white-space: nowrap;
}

.sectionListItem {
  @media (--screen-md) {
    width: var(--w-1-3);
  }
}

.siteListItem {
  font-size: var(--text-base);
  margin-top: var(--s-1);
  margin-bottom: var(--s-2);
  white-space: nowrap;
}

.bedsList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: var(--s-9);
  gap: var(--s-1);
}

.bedsList > span:not(:last-child)::after {
  content: ',';
}
