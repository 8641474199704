@import '../../landtrust.css';

.baseBadge {
  display: flex;
  justify-content: center;
  font-size: var(--text-xs);
  font-weight: var(--font-bold);
  padding: var(--s-1) var(--s-4);
  border-radius: 7px;
}

.baseBadgeLarge {
  font-size: var(--text-sm);
  padding: var(--s-3) var(--s-2);

  @media (--screen-md) {
    padding: var(--s-3) var(--s-5);
  }
}

.successBadge {
  composes: baseBadge;
  background-color: var(--olive-300);
  color: var(--olive-500);
}

.dangerBadge {
  composes: baseBadge;
  background-color: var(--red-300);
  color: var(--red-500);
}

.warningBadge {
  composes: baseBadge;
  background-color: var(--brown-100);
  color: var(--brown-900);
}
