@import '../../landtrust.css';

.root {
  position: absolute;
  z-index: var(--z-10);

  background-color: var(--white);
  box-shadow: var(--box-shadow-xl);
  border-radius: var(--border-radius);
  transform: translateY(10px);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
}

.isClosed {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.isOpen {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.content {
}

/* Styles for arrow (if arrowPosition is defined) */
.arrowTop,
.arrowBelow {
  content: ' ';
  position: absolute;
  bottom: 100%;
  height: 0;
  width: 0;
  border: solid transparent;
  pointer-events: none;
}

.arrowTop {
  border-bottom-color: var(--white);
  border-width: 7px;
  margin-left: -7px;
}

.arrowBelow {
  border-width: 9px;
  margin-left: -9px;
}
