@import '../../landtrust.css';

.root {
  background-color: var(--gray-100);
}

.content {
  display: flex;
  flex-direction: column;

  @media (--screen-lg) {
    flex-direction: row;
  }
}

.column {
  width: 100%;

  @media (--screen-lg) {
    width: var(--w-1-2);
  }
}

.sloganColumn {
  padding: var(--s-4);
  order: 1;

  @media (--screen-md) {
    padding: var(--s-8);
  }

  @media (--screen-lg) {
    padding: var(--s-40) 0;
    margin-right: var(--s-6);
    order: 0;
  }
}

.slogan {
  font-weight: var(--font-normal);
  font-size: var(--text-2xl);
  line-height: var(--line-height-tight);
  margin-bottom: var(--s-4);

  @media (--screen-lg) {
    font-size: var(--text-4xl);
    line-height: var(--line-height-normal);
    padding-right: var(--s-32);
  }
}

.featuredColumn {
  position: relative;
  background-repeat: no-repeat;
  background-position: 0 var(--w-1-3);
  background-size: cover;
  min-height: var(--s-40);
  order: 0;

  @media (--screen-lg) {
    order: 1;
    background-position: center center;
  }
}

.featuredListingContainer {
  position: absolute;
  bottom: var(--s-8);
  left: var(--s-8);
  line-height: var(--line-height-none);
  color: var(--white);
  font-size: var(--text-sm);
  align-items: center;
  display: none;

  @media (--screen-lg) {
    display: flex;
  }
}

.featuredListingContainer:hover {
  text-decoration: none;
}

.featuredAvatar {
  width: var(--s-10);
  height: var(--s-10);
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  margin-right: var(--s-2);
}

.authorName {
  margin-bottom: var(--s-1);
}
