@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  z-index: 1;
}

.topbarContainer {
  position: relative;
  z-index: 20;
}

.loading {
  margin-left: 24px;
  margin-right: 24px;
}

.error {
  margin-left: 24px;
  margin-right: 24px;
  color: var(--failColor);
}

.tabContent {
  display: none;
}

.tabContentVisible {
  display: block;
}

.footer {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.customModal {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: var(--s-230);
  background-color: var(--white);
  border-radius: var(--border-radius);
  border-bottom: none;
  flex-grow: 0;
  min-height: 100%;
  min-width: 100vw;
  height: auto;
  overflow: hidden;

  @media (--screen-lg) {
    min-width: var(--w-2-5);
    min-height: auto;
  }
}

.defaultStyles {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.scrollLayer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: none;
}

.modalContent {
  width: 100%;
  height: 100%;
}

.customCloseButton {
  position: absolute;
  top: var(--s-20);
  right: var(--s-6);
  width: var(--s-8);
  height: var(--s-8);
  padding: var(--s-2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: var(--line-height-none);
  flex-shrink: 0;
  margin-left: auto;
  cursor: pointer;
  z-index: 20;

  @media (--screen-md) {
    top: var(--s-4);
  }
}
