@import '../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
}

.header {
  font-weight: var(--font-medium);
  margin-bottom: var(--s-4);
  display: flex;
  align-items: center;
  width: var(--w-3-4);
}

.icon {
  margin-right: var(--s-2);
}

.content {
  max-width: var(--s-76);
  color: var(--gray-900);
  overflow: hidden;

  & a {
    text-decoration: underline;
    color: var(--black);
  }

  & ul {
    list-style: disc;
    padding-left: var(--s-10);
  }
}

.link {
  white-space: nowrap;
  color: var(--gray-900);
  font-weight: var(--font-medium);
  display: flex;
  align-items: center;

  &:hover {
  }
}

.linkAlt {
  white-space: nowrap;
  color: black;
  text-decoration: underline;
  font-weight: var(--font-medium);

  @media (--screen-md) {
    display: flex;
    align-items: center;
  }

  &:hover {
    text-decoration: none;
  }
}
