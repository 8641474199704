@import '../../marketplace.css';

.root {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contentContainer {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  object-fit: contain;
  max-height: 350px;

  @media (--screen-md) {
    max-height: 700px;
  }
}

.controlsContainer {
  margin-top: var(--s-6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageIndex {
  color: var(--white);
  font-size: var(--text-md);
  font-weight: var(--font-medium);
}

.imageLoading {
  opacity: 0.5;
}

.prev,
.next {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  margin: 0 var(--s-6);
  background-position: center;
}

.prev {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="%23FFF" d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path></svg>');
}

.next {
  color: white;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="%23FFF" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path></svg>');
}

.captionsContainer {
  margin-top: var(--s-8);
  color: var(--white);
  font-size: var(--text-md);
  text-align: center;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  opacity: 0;
}

.loadingVisible {
  opacity: 1;
  transition: opacity var(--transitionStyle);
}

.thumbnailCarousel {
  margin: var(--s-8) 0;
  max-width: 100%;
  display: block;

  @media (--screen-md) {
    display: none;
  }
}

.thumbnailStep {
  flex-basis: var(--s-20) !important;
  padding: var(--s-1);
  cursor: pointer;
}

.thumbnail {
  width: var(--s-20);
  height: var(--s-20);
  object-fit: cover;
  border: 2px transparent solid;
  border-radius: var(--border-radius);

  &.active {
    border-color: var(--gray-500);
  }
}
