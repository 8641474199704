@import '../../landtrust.css';

.tooltipContent {
  min-width: var(--s-22);
}

.tooltipLink {
  position: relative;
  bottom: -var(--s-2);
  left: 0;
  color: var(--gray-900);
}
