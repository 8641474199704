@import '../../landtrust.css';

.root {
  margin-top: 0;
  overflow: visible;
  margin-bottom: 0;
  margin-left: var(--s-6);

  @media (--screen-md) {
    margin-left: 0;
  }
}

.breadcrumbs {
  color: var(--gray-600);
  font-weight: normal;
}