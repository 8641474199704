@import '../../landtrust.css';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
}

.overlayActive {
  z-index: var(--z-60);
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  background: white;
  width: 100%;
  position: relative;
  overflow: auto;
  padding: var(--s-9) var(--s-5);
  transition: width 0.3s ease-out;

  @media (--screen-md) {
    width: 0;
  }
}

.contentActive {
  @media (--screen-md) {
    padding: var(--s-23) 5%;
  }
}

.contentFull {
  @media (--screen-md) {
    width: 100%;
  }
}

.contentHalf {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: var(--w-3-5);
  border-top-left-radius: var(--border-radius-xxl);
  border-top-right-radius: var(--border-radius-xxl);

  @media (--screen-md) {
    width: var(--w-1-2);
    height: 100%;
    border-bottom-left-radius: var(--border-radius-xxl);
    border-top-right-radius: 0;
  }
}

.buttonClose {
  position: absolute;
  top: var(--s-6);
  right: var(--s-6);
  width: var(--s-12);
  height: var(--s-12);
  border-radius: 50%;
  padding: var(--s-1);
  background: var(--white);
  border-color: transparent;
  box-shadow: var(--box-shadow);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-10);
  cursor: pointer;

  &:hover {
    box-shadow: var(--box-shadow-xl);
  }
}
