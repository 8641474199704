@import '../../landtrust.css';

.root {
  margin-bottom: var(--s-5);

  @media (--screen-md) {
    margin-top: var(--s-2);
    margin-bottom: var(--s-5);
  }

  &:last-child {
    margin-bottom: 0;
  }
}
