@import '../../landtrust.css';

.header {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.title {
  @apply --typography-heading-sans-serif;
  @apply --typography-heading-size-2xl;
}

.viewPropertyLink {
  font-weight: var(--font-medium);

  &:hover {
    text-decoration: none;
  }
}

.statusTitle {
  margin-bottom: var(--s-7);

  @media (--screen-md) {
    margin-top: var(--s-3);
  }
}

.controlsContainer {
  display: flex;
  flex-direction: column;
  margin: 0 var(--s-8);
  margin-bottom: var(--s-12);

  @media (--screen-md) {
    margin-left: 0;
    margin-right: 0;
  }
}

.listingHeadingWrapper {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.controls {
  margin-top: var(--s-3);

  @media (--screen-md) {
    margin-top: 0;
  }
}

.errorMessage {
  color: var(--red-500);
  margin-top: var(--s-3);
}

.controlsFooter {
  margin-top: var(--s-2);
  font-size: var(--text-sm);

  @media (--screen-md) {
    order: -1;
    padding: var(--s-4) 0;
    margin-top: 0;
    margin-bottom: var(--s-6);
    border-top: 1px var(--gray-300) solid;
    font-size: var(--text-xs);
    max-width: var(--s-142);
  }
}

.formContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.formInnerContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--s-8);
  padding: var(--s-7) 0;

  @media (--screen-md) {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    margin: var(--s-11) auto var(--s-7) auto;
  }
}

.wizardElementsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--s-29);
  padding: var(--s-5) 0;
  order: 2;

  @media (--screen-md) {
    order: 1;
    width: 62%;
    padding-right: var(--s-32);
    padding-top: 0;
  }
}

.stepsContainer {
  flex: 1;
}

.sidebarContainer {
  display: block;
  order: 1;

  @media (--screen-md) {
    order: 2;
    width: 38%;
  }
}

.sidebarPanel {
  @media (--screen-md) {
    padding: var(--s-5);
    border-radius: var(--border-radius-xxxl);
    border: 1px solid var(--gray-300);
    box-shadow: 0 40px 32px -24px rgba(15, 15, 15, 0.12);
    margin-bottom: var(--s-7);
  }
}

.desktopWizardStatus {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.mobileWizardStatus {
  display: block;

  @media (--screen-md) {
    display: none;
  }
}

.nextBtnMobile {
  @media (--screen-md) {
    display: none;
  }
}

.nextBtnDesktop {
  display: none;

  @media (--screen-md) {
    display: inline-block;
    min-width: var(--s-84);
  }
}

.breadcrumbLink {
  color: var(--gray-600);
}

.inquirySectionDesktop {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.modalContent {
  height: 100vh;
  margin-bottom: var(--s-6);
  overflow-y: auto;

  @media (--screen-md) {
    max-height: 60vh;
    margin-bottom: 0;
  }
}

.modalContent a {
  text-decoration: underline;
}

.containerStyles {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: var(--s-230);
  padding: var(--s-12) var(--s-6) 0 var(--s-6);
  border: 2px solid transparent;
  background-color: var(--white);
  flex-grow: 0;
  min-width: 100vw;
  height: 100%;
  overflow: hidden;

  @media (--screen-lg) {
    height: auto;
    border-radius: 12px;
    padding: var(--s-6);
    min-width: var(--w-2-5);
  }
}

.contentWrapper {
  padding-top: var(--s-9);
}

.contentHeader {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
}

.descriptionWrapper {
  margin: 0 0 var(--s-6) 0;

  & a {
    color: var(--black);
    text-decoration: underline;
  }
  & ul {
    margin: var(--s-4) 0;
    padding-left: var(--s-10);
    list-style-type: disc;
  }
  > div > p {
    margin-top: 0;
  }
}
