@import '../../landtrust.css';

.root {
}

.title {
  margin-bottom: var(--s-6);
}

.states {
  display: grid;
  grid-gap: var(--s-4);
  grid-template-columns: repeat(2, minmax(0, 50%));
  grid-auto-rows: minmax(min-content, max-content);

  @media (--screen-md) {
    grid-gap: var(--s-6);
    grid-template-columns: repeat(4, minmax(0, 25%));
  }
}
