@import '../../landtrust.css';

.root {
  color: var(--gray-900);
  padding: 0 var(--s-6);

  @media (--screen-md) {
    padding: 0;
  }
}

.header {
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-4);
}

.totalDivider {
  height: 1px;
  margin: var(--s-6);
  border: none;
  background-color: var(--gray-300);

  @media (--screen-md) {
    margin: var(--s-6) 0;
  }
}
