@import '../../landtrust.css';

.root {
  color: var(--gray-900);
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--gray-100);
  border-radius: var(--border-radius-xl);
  padding: var(--s-8) var(--s-4);
  margin-bottom: var(--s-6);
}

.bannerLogoImage {
  width: var(--s-50);
  margin-bottom: var(--s-3);
}

.bannerDescription {
  margin-bottom: var(--s-6);
}

.membershipDetailsWrapper {
  display: flex;
  flex-direction: column;
}

.membershipHeader {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-2);
}

.membershipDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: var(--s-3);
  gap: var(--s-4);

  @media (--screen-md) {
    flex-direction: row;
  }
}

.membershipTypeWrapper {
  display: flex;
  flex-direction: column;
}

.membershipType {
  font-weight: var(--font-bold);
}

.membershipPrice {
  font-size: var(--text-sm);
}

.membershipAction {
  font-size: var(--text-sm);
}

.membershipActionButton {
  text-decoration: underline;
}

.membershipStatusWrapper {
  display: flex;
  align-items: center;
  font-weight: var(--font-bold);
  font-size: var(--text-xs);
  border-radius: var(--border-radius);
  background-color: var(--yellow-100);
  border-left: 6px solid var(--yellow-500);
  padding: var(--s-3);
  margin-bottom: var(--s-4);
}
