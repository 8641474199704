@import '../../landtrust.css';

.unsupportedCountryError {
  color: var(--red-500);
}

.root {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.input {
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.longForm {
  width: 100%;
  margin-bottom: 24px;
}

@media (--screen-sm) {
  .longForm {
    width: calc(50% - 9px);
  }
}

.inputSuccess {
}

.inputError {
  border-color: var(--red-500);
}

.error {
  margin-top: var(--s-2);
  color: var(--red-500);
  overflow: hidden;
  font-size: var(--text-sm);
}
