@import '../../marketplace.css';

.root {
}

.listing {
  display: flex;
  flex-direction: column;
}

.listingItem {
  margin-bottom: var(--s-5);
}

.link {
  display: inline-flex;
  align-items: center;
  color: var(--gray-900);
  font-family: var(--font-base);
  font-weight: bold;
  text-decoration: underline;
}

.previewLink {
  white-space: nowrap;
  text-decoration: underline;
}

.linkLabel {
  color: var(--gray-900);
}

.iconWrapper {
  display: flex;
  width: 23px;
  justify-content: center;
  margin-right: 17px;
}

.icon {
  color: var(--brand);
}

@media (--viewportMedium) {
  .listing {
    max-height: 360px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
