@import '../../landtrust.css';

.root {
}

.iconContainer {
  text-align: center;
  margin-bottom: var(--s-8);
}

.title {
  @apply --typography-heading-sans-serif;
  @apply --typography-heading-size-3xl;

  padding: 0 var(--s-3);
  text-align: center;
}

.content {
  display: none;
}

@media (--screen-lg) {
  .root {
    padding: var(--s-32) 0;
    width: var(--w-2-3);
    margin: 0 auto;
  }

  .content {
    padding: 0 var(--s-12);
    display: block;
    text-align: center;
    font-size: var(--text-lg);
  }
}
