@import '../../landtrust.css';

.sectionLandDetails {
  position: relative;
}

.sectionHeader {
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-6);

  @media (--screen-md) {
    margin-bottom: var(--s-5);
  }
}

.landDetailsContent {
  position: relative;
  padding: 0 var(--s-6) 0;

  @media (--screen-lg) {
    padding: 0 var(--s-7) var(--s-4) 0;

    & section:first-of-type div.detailsTitle {
      margin-top: 0;
    }
  }
}

.detailsConatiner {
  margin-bottom: var(--s-9);
  @media (--screen-lg) {
    margin: 0 var(--s-10) var(--s-12) 0;
  }
}

.detailsTitle {
  display: flex;
  align-items: flex-start;
  margin-top: var(--s-5);
  margin-bottom: var(--s-1);

  @media --screen-lg {
    margin-top: var(--s-8);
    margin-bottom: var(--s-2);
  }
}

.detailsTitleText {
  font-weight: var(--font-bold);
  font-size: var(--text-base);
  padding-left: var(--s-3);

  @media (--screen-md) {
    font-size: var(--text-base);
  }
}

.landDetailsShelterTitleText {
  font-weight: var(--font-bold);
  font-size: var(--text-base);
  padding-left: var(--s-2);

  @media (--screen-md) {
    font-size: var(--text-base);
  }
}

.landDetailsTitleText {
  font-weight: var(--font-bold);
  font-size: var(--text-base);
  padding-left: var(--s-3);

  @media (--screen-md) {
    font-size: var(--text-base);
  }
}

.landDetailsWaterTitleText {
  font-weight: var(--font-bold);
  font-size: var(--text-base);
  padding-left: var(--s-2);

  @media (--screen-md) {
    font-size: var(--text-base);
  }
}

.sources {
  font-weight: var(--font-bold);
  display: inline-block;
  padding-right: var(--s-4);
  margin-bottom: var(--s-2);
}

.sourcesInfo {
  white-space: pre-line;
  margin-left: var(--s-9);
}

.detailsContainer {
  margin-bottom: var(--s-2);

  @media (--screen-md) {
    margin-bottom: 0;
  }
}
