@import '../../landtrust.css';

.root {
  padding: var(--s-8) var(--s-4);
  background-color: var(--gray-100);
  font-size: var(--text-xs);

  @media (--screen-md) {
    padding: var(--s-32) 0;
    font-size: var(--text-sm);
  }
}

.grid {
  display: flex;
  flex-direction: column;
  margin-top: var(--s-4);

  @media (--screen-md) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.gridItem:nth-child(1) {
  order: 1;

  @media (--screen-md) {
    order: -1;
    white-space: nowrap;
  }
}

.links {
  display: grid;
  grid-gap: var(--s-2);
  grid-template-columns: repeat(3, minmax(0, 150px));
  grid-auto-rows: minmax(min-content, max-content);
  font-size: var(--text-xs);
  font-weight: var(--font-medium);

  @media (--screen-md) {
    margin: 0 var(--s-12);
  }
  @media (--screen-lg) {
    font-size: var(--text-base);
  }
}

.link {
  color: var(--gray-800);
}

.otherLinks {
  width: var(--s-32);
  margin-top: var(--s-4);
  flex-shrink: 0;
}

.socialLinks {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--s-2);
}

.description {
  color: var(--gray-900);
  margin-top: var(--s-4);

  @media (--screen-md) {
    margin-top: 0;
  }
}

.icon {
  width: var(--s-6);

  @media (--screen-md) {
    width: var(--s-8);
  }
}
