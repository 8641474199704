@import '../../marketplace.css';
@import '../../landtrust.css';
.root {
  margin: 0;
}

.review {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-top: var(--s-8);
  margin-bottom: var(--s-5);
}

.reviewHeadingsWrapper {
  display: flex;
  flex-flow: column;
}
.reviewHeadings {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.reviewAuthor {
  margin-right: var(--s-1);
  color: var(--gray-900);
  font-weight: var(--font-medium);
}
.reviewDate {
  font-size: var(--text-sm);
  font-weight: 500;
  color: var(--gray-600);
  line-height: normal;

  padding: 0;
}
.avatar {
  flex-shrink: 0;
  margin-right: var(--s-4);
}

.reviewContentWrapper {
  margin-bottom: var(--s-3);
}

.reviewContent {
  margin: 0;
  white-space: pre-line;
  color: var(--gray-900);
}

.reviewsInfo {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: var(--s-3);
}

.reviewContent {
  margin: 0;
  white-space: pre-line;
  color: var(--gray-900);
}

.reviewDateWrapper {
  color: var(--gray-600);
  font-size: var(--text-xs);
}

.mobileReviewRating {
  display: block;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.desktopReviewRating {
  display: inline-flex;
  white-space: nowrap;
}

.reviewRatingStar {
  height: var(--s-3);
  width: var(--s-3);
  margin-right: var(--s-1);

  &:last-of-type {
    margin-right: 0;
  }
}

.sectionLandowner {
  margin-left: var(--s-8);
  padding-left: var(--s-5);
  border-left: 2px solid var(--gray-300);
}

.reviewHarvest {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: var(--text-xxs);
  font-weight: var(--font-bold);
  border-radius: 6px;
  background-color: var(--gray-200);
  padding: 2px 4px 2px 2px;
  margin-bottom: var(--s-2);

  > svg {
    height: 18px;
    width: 18px;
  }
}
