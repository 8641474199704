@import '../../landtrust.css';

.root {
  display: flex;
  color: var(--gray-900);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  @media (--screen-md) {
    padding-right: var(--s-4);
    padding-left: var(--s-6);
    border-left: 4px solid transparent;

    &:hover {
      background-color: var(--gray-100);
      border-radius: var(--s-3);
    }
  }
}

.rootSelected {
  @media (--screen-md) {
    background-color: var(--gray-100);
    border-radius: var(--s-3);
  }
}

.container {
  display: flex;
  gap: var(--s-3);
  flex-grow: 1;
  padding-top: var(--s-6);
  padding-bottom: var(--s-5);
}

.avatarContainer {
  position: absolute;
  top: var(--s-9);
  right: -4px;
}

.avatar {
  width: var(--s-8);
  height: var(--s-8);
  border: solid 1px var(--white);
}

.itemContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.itemHeader {
  display: flex;
  justify-content: space-between;
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  line-height: 14px;
}

.itemHeaderBold {
  font-weight: var(--font-bold);
}

.itemHeaderDate {
  font-size: var(--text-xxs);
  color: var(--gray-700);
  font-weight: normal;
}

.itemTitle {
  font-size: var(--text-sm);
}

.itemTitleBold {
  font-weight: var(--font-bold);
}

.itemDate {
  font-size: var(--text-xs);
  color: var(--gray-600);
}

.itemFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--s-1);
}

.itemStateLabel {
  font-size: var(--text-xs);
  color: var(--gray-900);
}

.itemBadge {
  display: flex;
  align-items: center;
  gap: var(--s-1);
  font-size: var(--text-xxs);
  font-weight: var(--font-bold);
  padding: var(--s-1);
  border-radius: var(--border-radius);
  white-space: nowrap;
}

.badgeYellow {
  background-color: var(--yellow-200);
}

.badgeGreen {
  background-color: var(--olive-200);
}

.badgeRed {
  background-color: var(--red-100);
}

.imageWrapper {
  position: relative;
}

.image {
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 2px;
}

.clockIcon {
  height: var(--s-4);
  width: var(--s-4);
}
