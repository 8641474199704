@import '../../landtrust.css';

.root {
  background-color: var(--white);
}

.profileInfoContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: var(--s-7);

  @media (--screen-md) {
    margin-bottom: 0;
  }
}

.profileFlexContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: var(--s-4);

  @media (--screen-md) {
    margin-bottom: var(--s-4);
  }
}

.profileInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: var(--s-4);
}

.profileName {
  font-size: var(--text-lg);
  font-weight: var(--font-bold);

  @media (--screen-md) {
    font-size: var(--text-xl);
  }
}

.profileLink {
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  text-decoration: underline;
}

.profilePictureText {
  display: none;
  color: var(--gray-900);

  @media (--screen-md) {
    display: flex;
    font-size: var(--text-sm);
    margin-bottom: var(--s-4);
    padding: 0 var(--s-2);
  }
}

.badgeContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--s-7);
  color: var(--gray-900);
  margin-left: calc(-1 * var(--s-2));
  margin-right: calc(-1 * var(--s-2));
}

.badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
  border-radius: var(--border-radius-xxl);
  background-color: var(--gray-100);
  padding: var(--s-4);
  margin-right: var(--s-2);
  margin-left: var(--s-2);
}

.badgeHeader {
  font-size: var(--text-xs);
}

.badgeContent {
  font-size: var(--text-base);
  font-weight: var(--font-medium);
}

.mobileTabs {
  margin-left: calc(-1 * var(--s-5));
  margin-right: calc(-1 * var(--s-5));

  @media (--screen-md) {
    display: none;
    margin-left: 0;
    margin-right: 0;
  }
}
.desktopTabs {
  display: none;

  @media (--screen-md) {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

.tabContent {
  margin-top: var(--s-5);
}

.desktopTab {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0px;
  overflow: auto;
  padding: 20px 176px 22px 0;
  border-radius: 12px;
  border: solid 1px var(--gray-300);
}

.avatarContainer {
  position: relative;
}

.uploadButton {
  width: 100%;
}
