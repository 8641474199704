@import '../../landtrust.css';

.title {
  font-family: var(--font-base);
  font-weight: var(--font-medium);
  font-size: 22px;
  margin-bottom: var(--s-6);
}

.description {
  margin: 0;
  font-size: var(--text-lg);
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.imageColumn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageInner {
  display: flex;
  align-items: center;
  margin-left: calc(-1 * var(--s-2));
}

.deerImage {
  max-width: 200px;
  margin-left: -75px;
}

.turkeyImage {
  max-width: 134px;
  margin-top: 130px;
  border: var(--s-2) solid var(--white);
  border-radius: 50%;
  z-index: 1;
}

@media (--screen-lg) {
  .root {
    padding-top: var(--s-24);
  }

  .wrapper {
    flex-direction: row;
  }

  .column {
    width: var(--w-3-5);
  }

  .contentColumn {
    width: var(--w-2-5);
    padding-top: var(--s-16);
    padding-right: var(--s-8);
  }

  .title {
    font-size: var(--text-3xl);
    font-weight: bold;
  }

  .imageColumn {
    justify-content: left;
    padding-left: var(--s-32);
  }

  .imageInner {
    align-items: flex-start;
  }

  .deerImage {
    max-width: 356px;
    margin-left: -120px;
  }

  .turkeyImage {
    margin-top: 190px;
    max-width: 236px;
  }
}
