@import '../../landtrust.css';

.root {
}

.select {
  padding: var(--s-3);
}

.selectSuccess {
  border: 1px solid var(--gray-400);
}

.selectError {
  border-color: var(--red-500);
}
