@import '../../landtrust.css';

.root {
  display: flex;
  align-items: center;
  overflow-x: auto;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: var(--text-sm);

  &::-webkit-scrollbar {
    display: none;
  }
}

.breadcrumb {
  color: var(--gray-600);
  font-weight: normal;

  &.active {
    color: var(--gray-900);
    font-weight: var(--font-bold);
  }
}

.arrow {
  height: var(--s-2);
  min-width: var(--s-2);
  margin: 0 var(--s-3);
}
