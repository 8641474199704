@import '../../landtrust.css';

.root {
  margin-top: var(--s-4);
  text-align: left;

  @media (--screen-md) {
    margin-top: var(--s-8);
  }
}

.title {
  @apply --typography-heading-sans-serif;
  font-size: var(--text-base);

  @media (--screen-md) {
    font-size: var(--text-xl);
  }
}

.subtitle {
  margin: 0;
  margin-bottom: var(--s-3);
  font-size: var(--text-xs);
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--s-2);
}

.rowSubtotal {
  margin-top: var(--s-4);
  padding: var(--s-4) 0;
  border-top: 1px var(--gray-300) solid;
  font-weight: var(--font-medium);
}
