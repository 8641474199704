@import '../../landtrust.css';

.section {
  margin-bottom: var(--s-12);
  padding: 0 var(--s-4);

  &:first-child {
    margin-top: var(--s-12);
  }

  @media (--screen-md) {
    padding: 0;
  }
}

.sectionFullWidth {
  padding: 0;
}

.howItWorksSection {
  margin-bottom: 0;
}

.listYourPropertySection {
  overflow-x: hidden;
  padding-bottom: var(--s-12);

  @media (--screen-md) {
    overflow: visible;
    padding-bottom: 0;
  }
}
