@import '../../landtrust.css';

.sectionContainer {
  position: relative;
  padding: var(--s-12) var(--s-5) var(--s-8);
  border-radius: var(--border-radius-xl);
  margin: var(--s-8) 0;

  @media (--screen-lg) {
    margin-bottom: var(--s-13);
    margin-top: 0;
    padding: var(--s-15) var(--s-15) var(--s-20) 0;
  }
}

.breakoutContainer {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: var(--z-10);

  @media (--screen-lg) {
    border-radius: var(--border-radius-xl);
    left: -50vw;
  }
}

.content {
  position: relative;
  z-index: var(--z-20);
}

.flexContainer {
  display: flex;
  flex-direction: row;
  margin: var(--s-9) 0;

  @media (--screen-lg) {
    align-items: center;
    margin: var(--s-4) 0;
  }
}

.visitHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.descriptionHeader {
  font-family: var(--font-base);
  font-size: var(--text-lg);
  font-weight: bold;
  color: var(--gray-900);
  display: flex;
  margin-left: var(--s-2);
  @media (--screen-lg) {
    margin: 0 0 var(--s-1) var(--s-2);
  }
}

.avatarImage {
  max-width: var(--s-19);
  max-height: var(--s-19);
}

.fieldRepContainer {
  display: flex;
  flex-direction: column;
  margin-left: var(--s-3);
}

.fieldRepInfoContainer {
  display: flex;
  flex-direction: column;
  @media (--screen-lg) {
    flex-direction: row;
  }
}
.desktopPipe {
  display: none;

  @media (--screen-lg) {
    display: flex;
    margin: 0 var(--s-1);
  }
}

.fieldRepName {
  margin: 0;
}

.visitDate {
  font-size: var(--text-base);
  font-weight: var(--font-bold);
}
