@import '../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
  margin-top: var(--s-5);
}

.errorMessage {
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  color: var(--red-900);
  border-radius: var(--border-radius);
  border: 1px solid var(--red-900);
  padding: var(--s-3);
  margin-bottom: var(--s-7);
}

.submitContainer {
  margin-top: var(--s-12);
}
