@import '../../landtrust.css';

.pageRoot {
  overflow: hidden;

  @media (--screen-md) {
    overflow-x: initial;
  }
}

.root {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  min-height: 100vh;
  color: var(--gray-900);

  @media (--screen-md) {
    max-height: auto;
    min-height: 100vh;
  }
}

.topbarContainer {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 60;
}

.listContainer {
  order: 1;
  width: 100%;
  overflow: hidden;

  @media (--screen-md) {
    flex: 0 0 25%;
    overflow: auto;
    height: auto;
  }
}

.listContainerLoader {
  composes: listContainer;
  padding: 0;
  min-height: 100vh;

  @media (--screen-md) {
    min-height: auto;
    padding: var(--s-4) 0 var(--s-4) var(--s-4);
  }
}

.messagesContainer {
  order: 3;

  @media (--screen-md) {
    order: 2;

    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--gray-300);
    padding: 0;
  }
}

.messagesContainerLoader {
  composes: messagesContainer;
  border-left: none;

  @media (--screen-md) {
    padding: var(--s-4);
  }
}

.actionContainer {
  order: 2;

  @media (--screen-md) {
    order: 3;

    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    border: 1px solid var(--gray-300);
    box-shadow: 0 20px 32px -24px rgba(15, 15, 15, 0.12);
    height: calc(100% - var(--s-7));
    overflow: auto;
    margin: var(--s-7) var(--s-6) 0 var(--s-6);
  }
}

.actionContainerLoader {
  composes: actionContainer;
  border: none;
  margin-left: 0;
  margin-top: var(--s-4);
}

.containerLoader {
  display: block;
  width: 100%;
  height: 100%;
}

.mainContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 9;
  overflow-x: auto;

  @media (--screen-md) {
    flex-shrink: 0;
    width: 100%;
    max-width: 1920px;
    height: calc(100vh - var(--s-18) - var(--s-21));
    flex-direction: row;
    padding: 0;
    margin: 0 auto var(--s-7) auto;
  }
}

.mainContainerLoader {
  composes: mainContainer;
  padding: var(--s-4);
  background-color: var(--white);

  @media (--screen-md) {
    padding: 0;
  }
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  gap: var(--s-8);
  height: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: var(--s-5);

  @media (--screen-md) {
    padding: 0;
  }
}

.emptyStateImage {
  width: 100%;

  @media (--screen-md) {
    max-width: var(--s-93);
  }
}

.emptyStateTextSection {
  display: flex;
  flex-direction: column;
  gap: var(--s-2);
  align-items: center;
  margin-bottom: var(--s-6);
  text-align: center;
}

.emptyStateTitle {
  font-size: var(--text-2xl);
  font-weight: var(--font-bolder);
}
