@import '../../landtrust.css';
@import '../FlexHelper/FlexHelper.css';

.root {
  background-color: var(--gray-100);
  border-radius: var(--border-radius);
  padding: var(--s-10) 0;
}

.title {
  padding: 0 var(--s-4);
  margin-bottom: var(--s-4);

  @media (--screen-md) {
    padding: 0;
    text-align: center;
  }
}

.carousel {
  overflow: hidden;
  padding: var(--s-4) 0;
  margin: 0;

  @media (--screen-md) {
    padding: 0 var(--s-24);
    overflow: visible;
  }
}

.step {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-basis: 25%;
  padding: var(--s-2);

  @media (--screen-md) {
    flex-basis: var(--w-1-4);
  }
}

.boxesRoot {
  @apply --flexWrapper;
}

.boxesInner {
  @apply --flexInner3;

  justify-content: center;

  @media (--screen-md) {
    justify-content: start;
  }
}

.box {
  display: block;
  @apply --flexItem3;

  max-width: 130px;
  @apply --flexItem3Width50Percent;

  @media (--screen-sm) {
    @apply --flexItem3Width25Percent;
  }

  @media (--screen-md) {
    @apply --flexItem3Width50Percent;
  }

  @media (--screen-lg) {
    @apply --flexItem3Width25Percent;
  }
}

.boxInner {
  position: relative;
  width: 100%;

  /* Keep the aspect ratio as per design 130px x 122px */
  padding-bottom: calc((122% / 130) * 100);
}

.boxImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius-xl);
  overflow: hidden;
}

.boxTitle {
  position: absolute;
  bottom: var(--s-4);
  width: 100%;
  text-align: center;

  font-weight: var(--font-medium);
  font-size: var(--text-sm);
  color: var(--white);
}
