@import '../../marketplace.css';

.filterContainer {
  display: flex;
  flex-direction: row;
}

.filterButton {
  @apply --ltButtonStyle;
  @apply --ltFilterButtonStyle;
}

.filterButtonSelected {
  @apply --ltButtonSelectedStyle;
  @apply --ltFilterButtonStyle;
}
