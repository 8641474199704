@import '../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: var(--s-6) var(--s-6) 0 var(--s-6);

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: var(--s-5);
  }
}

.title {
  font-size: var(--text-2xl);
  font-family: var(--font-base);
  font-weight: bold;
  color: var(--gray-900);
  display: flex;
  margin-bottom: var(--s-5);

  @media (--screen-md) {
    margin-bottom: var(--s-5);
  }
}

.activityTitle {
  @apply --typography-heading-size-2xl;
  color: var(--gray-900);
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);

  @media (--screen-md) {
    margin-bottom: var(--s-4);
  }
}

.tabWrapper {
  margin-bottom: var(--s-7);

  @media (--screen-md) {
    margin-bottom: var(--s-5);
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: var(--z-50);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: var(--s-4) var(--s-4) 0 var(--s-4);
  background-color: var(--white);
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 10%);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.availLink {
  width: 100%;
}

.bookButton {
  border-radius: var(--border-radius-xxxl);
  margin-bottom: 1em;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}
