@import '../../marketplace.css';
@import '../../landtrust.css';

.paymentForm {
  width: 100%;
  align-self: center;
}

.paymentContainer {
  border: none;
  padding: 0;
  margin: 0;

  @media (--screen-md) {
    border-radius: var(--border-radius-xl);
    border: solid 1px var(--gray-300);
    padding: var(--s-5) var(--s-3) var(--s-4) var(--s-3);
    margin-bottom: var(--s-3);
  }
}

.paymentMessage {
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  color: var(--green-700);
  border-radius: var(--border-radius);
  border: 1px solid var(--green-700);
  padding: var(--s-3);
  margin-bottom: var(--s-7);
}

.errorMessage {
  font-size: var(--text-sm);
  font-weight: var(--font-bold);
  color: var(--red-900);
  border-radius: var(--border-radius);
  border: 1px solid var(--red-900);
  padding: var(--s-3);
  margin-bottom: var(--s-7);
}

/* Buttons and links */
.button {
  border-radius: var(--border-radius-xxxl);
  margin: var(--s-6) 0 var(--s-3) 0;
  width: 100%;
}

.button:hover {
  filter: contrast(115%);
}

.button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.card {
  @apply --marketplaceInputStyles;

  /* Layout */
  padding: 8px 0 6px 0;
  height: 36px;

  /* Border */
  border-bottom-color: var(--attentionColor);

  @media (--viewportMedium) {
    height: 38px;
  }
  @media (--viewportLarge) {
    height: 38px;
    padding: 6px 0 14px 0;
  }
}

.cardSuccess {
  border-bottom-color: var(--successColor);
}

.cardError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.formHeading {
  font-size: var(--text-xl);
  font-weight: var(--font-bold);
  margin: var(--s-6) 0 var(--s-1) 0;
  color: var(--gray-900);

  @media (--viewportMedium) {
    margin: 0 0 var(--s-2) 0;
  }
}

.formSubheading {
  font-size: var(--text-sm);
  margin-bottom: var(--s-2);
}

.formSubcopy {
  font-size: var(--text-sm);
  margin-bottom: var(--s-2);
  color: var(--gray-900);

  @media (--viewportMedium) {
    margin-bottom: var(--s-3);
  }
}

.paymentMethodSelector {
  margin-bottom: var(--s-3);
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: var(--s-3);

  @media (--viewportMedium) {
    padding-top: var(--s-2);
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  @apply --marketplaceH5FontStyles;
  color: var(--gray-900);
  font-weight: bold;
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingHeading {
  margin: 0 0 var(--s-3) 0;
  color: var(--gray-900);
  font-weight: var(--font-bold);
  font-size: var(--text-2xl);

  @media (--viewportMedium) {
    margin: 0 0 var(--s-6) 0;
  }
}

.paymentLabel {
  margin: 0;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 2px;
  }
}

.messageHeading {
  font-size: var(--text-base);
  margin: 0 0 12px 0;
  color: var(--var-900);
  margin: 12px 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 12px 0 12px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: var(--s-4);
}

.paymentInfo {
  color: var(--gray-900);
  font-size: var(--text-xs);
  font-weight: var(--font-medium);
  text-align: center;
  padding: 0 var(--s-3);
  margin: var(--s-8) 0 0 0;

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0;
  }
}

.submitButton {
  margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 17px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}

.cancelDisclaimer {
  font-size: var(--text-sm);
  padding: var(--s-2) 0 var(--s-2) 0;
  border-radius: var(--border-radius);
}

.sendBookingDisclaimer {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: var(--text-sm);
  font-style: var(--font-base);
  width: 80%;
  margin: auto;
}

.characterCounter {
  text-align: left;
  font-size: var(--text-sm);
  color: var(--gray-600);
  margin-top: var(--s-1);
  padding-left: var(--s-1);
}
