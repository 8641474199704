@import '../../landtrust.css';

.root {
}

.rootIsSorting {
  & * {
    user-select: none;
  }
}

.photoPreviewContainer {
  background: var(--white);
  margin: 0 calc(-1 * var(--s-2));
  margin-bottom: var(--s-5);

  &:last-child {
    margin-bottom: 0;
  }
}

.photoPreviewContainerInner {
  padding: var(--s-2);
  display: flex;
}

.photoPreviewContainerIsSorting {
  box-shadow: var(--box-shadow);
  transform: scale(1.05);

  & .photoPreviewContainerInner {
  }
}

.photoPreviewImageContainer {
  position: relative;
  width: var(--s-21);
  height: var(--s-21);
  flex-grow: 0;
  flex-shrink: 0;
}

.photoPreviewImage {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
  font-size: var(--text-xs);
}

.photoPreviewImageFade {
  opacity: 0.5;
}

.photoPreviewImageLink {
  text-decoration: none;
}

.photoPreviewDeleteImage {
  cursor: pointer;
  position: absolute;
  top: calc(0px - (var(--s-7) / 2));
  right: calc(0px - (var(--s-7) / 2));
  background: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: 50%;
  width: var(--s-7);
  height: var(--s-7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.photoPreviewDeleteImageIcon {
  width: var(--s-2);
  height: var(--s-2);
}

.photoPreviewSpinner {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photoPreviewCaptionContainer {
  flex-grow: 1;
  margin-left: var(--s-5);
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.photoPreviewCaptionInputWrapper {
  height: var(--s-21);
  margin-bottom: var(--s-2);
}

.photoPreviewCaptionInput {
  display: block;
  min-height: auto;
  height: 100%;
}

.sortHandleContainer {
  flex-grow: 0;
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: var(--s-5);
  height: var(--s-21);
}

.sortHandle {
  cursor: pointer;

  & g {
    stroke: var(--gray-400);
  }
}

.error {
  color: var(--red-500);
  margin: var(--s-2) 0 0 0;
  font-size: var(--text-sm);
}

.photoPreviewTagsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--s-3);
}

.photoPreviewTagsLinkContainer {
  display: flex;
  align-items: center;
}

.photoPreviewTagsIcon {
  height: var(--s-4);
  width: var(--s-4);
}

.photoPreviewTagsLink {
  text-decoration: underline;
  font-size: var(--text-sm);
  margin-left: var(--s-1);
}

.tagsTooltipItemBase {
  display: flex;
  align-items: center;
  font-size: var(--text-xs);
  border-radius: var(--border-radius-xxl);
  border: 1px solid var(--gray-400);
  padding: var(--s-2) var(--s-3);
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }
}

.photoPreviewTagsItem {
  composes: tagsTooltipItemBase;
  color: var(--white);
  background-color: var(--gray-900);
}

.photoPreviewTagsItemClose {
  cursor: pointer;

  & > svg > path {
    fill: var(--white);
  }
}

.photoPreviewTagsCloseIcon {
  width: 10px;
  margin-left: var(--s-2);
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
  padding: var(--s-6);
}

.contentContainer {
  display: flex;
  position: relative;

  @media (--screen-lg) {
    margin: 0 auto var(--s-15);
    justify-content: space-between;
  }
}

.carouselModalImage {
  @media (--screen-md) {
    max-height: calc(100vh - var(--s-40));
  }
}
