@import '../../landtrust.css';

.root {
}

.radioWrapper > span {
  margin-bottom: var(--s-2);
  display: block;
}

@media (--screen-md) {
  .radioWrapper {
    display: flex;

    & > span {
      margin-right: var(--s-7);
      margin-bottom: 0;
    }
  }
}
