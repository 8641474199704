@import '../../landtrust.css';

.sectionHuntFishDetails {
  padding: 0;
  margin-bottom: var(--s-8);
}

.huntFishDetailsTitle {
  margin-bottom: var(--s-5);
  font-size: var(--text-2xl);
  font-weight: var(--font-bold);
}

.detailsSubtitle {
  font-size: var(--text-lg);
  font-weight: bold;
  margin-bottom: var(--s-1);
}

.huntMethodsContainer {
  display: block;
  @media (--screen-md) {
    width: 100%;
    column-count: 2;
    justify-content: bottom;
    column-gap: var(--s-4);
  }
}

.huntingFishingIcons {
  width: var(--s-7);
  color: var(--gray700);
  margin-right: var(--s-2);
  margin-left: 0;
}

.speciesIcon {
  width: var(--s-7);
  color: var(--gray700);
  margin-right: var(--s-2);
  margin-left: 0;
}

.detailsGroupsContainer {
  display: flex;
  flex-flow: column;
}

.detailsGroup {
  margin-bottom: var(--s-5);

  @media (--screen-md) {
    margin-bottom: var(--s-5);
    margin-right: var(--s-14);
    padding: 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.huntingMethodsDetailsGroup {
  margin-bottom: var(--s-3);
  margin-right: var(--s-14);
  padding: 0;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.ammenitiesList {
  display: block;
  @media (--screen-md) {
    width: 100%;
    column-count: 2;
    column-gap: var(--s-4);
  }
}

.ammenitiesList,
.huntMethodsContainer,
.speciesContainer {
  @media (--screen-xl) {
    width: 80%;
    column-count: 2;
    column-gap: var(--s-4);
  }
}

.detail {
  break-inside: avoid;
  display: flex;
  align-items: center;
  margin-bottom: var(--s-2);
}

.info {
  margin-left: var(--s-10);
}

.speciesContainer {
  display: block;
  @media (--screen-md) {
    max-width: 100%;
    column-count: 2;
    column-gap: var(--s-4);
  }
}

.formFieldWrapper {
  max-width: 100%;

  @media (--screen-md) {
    max-width: var(--s-56);
    margin-top: var(--s-5);
  }
}

.buttonText {
  padding: 0;
  font-size: var(--text-base);
  font-weight: bold;
  white-space: nowrap;
}

.readMore {
  color: var(--gray-900);
  margin: 0;
  text-decoration: underline;
  font-family: var(--font-base);
}
.readMore:hover {
  cursor: pointer;
  text-decoration: underline;
}

.modalDetails {
  white-space: pre-line;
  margin-bottom: var(--s-2);
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin-bottom: var(--s-3);

  border: none;
  background-color: var(--gray-200);
}

.fishTypesPriceTitle {
  font-weight: normal;
  font-size: var(--text-sm);
}

.modalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: var(--s-6);
  background-color: var(--white);
  border-radius: var(--border-radius);
  border-bottom: none;
  flex-grow: 0;
  min-height: auto;
  height: auto;
  overflow: hidden;
  margin: 0 var(--s-6);
  flex-basis: var(--s-72);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.catchAndRelease {
  margin-bottom: var(--s-8);
  @media (--screen-lg) {
    margin-bottom: var(--s-8);
  }
}
