@import '../../landtrust.css';

.container {
  position: absolute;
  bottom: 0;
  height: auto;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--s-11) var(--s-6) var(--s-10) var(--s-6);
  background-color: var(--white);
  border-bottom: none;
  border-top-left-radius: var(--border-radius-xl);
  border-top-right-radius: var(--border-radius-xl);
  flex-grow: 0;
  min-height: auto;
  overflow: hidden;

  @media (--screen-md) {
    position: relative;
    margin: auto;
    width: var(--w-1-2);
    height: auto;
    border-radius: var(--border-radius);
    padding: var(--s-28) 0;
    align-items: center;
  }
}

.modalContent {
  padding: var(--s-3);
}

.buttonWrapper {
  width: 100%;

  @media (--screen-md) {
    max-width: var(--s-84);
  }
}

.notifyButton {
  background-color: var(--gray-900);
  color: var(--white);
}

.icon {
  width: var(--s-4);
  height: var(--s-5);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (--screen-md) {
    max-width: var(--s-103);
  }
}

.title {
  font-size: 24px;
  font-weight: var(--font-bold);
  margin-bottom: var(--s-3);

  @media (--screen-md) {
    font-size: 30px;
  }
}

.subtitle {
  font-size: 16px;
  margin-bottom: var(--s-5);

  @media (--screen-md) {
    margin-bottom: var(--s-8);
  }
}

.footer {
  font-size: 14px;
  margin-top: var(--s-3);

  @media (--screen-md) {
    max-width: var(--s-76);
  }
}
