@import '../../marketplace.css';
@import '../../landtrust.css';

.contentStyles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--s-17);
}

.titleStyles {
  font-size: var(--text-2xl);
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  text-align: center;

  @media (--screen-md) {
    width: 100%;
  }
}

.bannerLogoImage {
  width: var(--s-50);
  margin-bottom: var(--s-2);
}

.testimonialWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--s-20);
}

.authComponentWrapper {
  margin: 0 auto;
  width: 90%;
  
  @media (--screen-md) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
}
