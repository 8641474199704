@import '../../landtrust.css';

.root {
  display: flex;
  flex-direction: column;
  font-size: var(--text-sm);
}

.progressBarContainer {
  height: var(--s-2);
  width: 100%;
}

.container {
  padding-top: var(--s-2);
}

.content {
  display: flex;
  gap: var(--s-1);
  flex-direction: column;
  align-items: center;

  @media (--screen-md) {
    flex-direction: row;
  }
}

.counter {
  font-weight: var(--font-bold);
}
