@import '../../../landtrust.css';

.actionButtonWrapper {
  display: flex;
  gap: var(--s-3);
  width: 100%;

  & > button {
    flex-grow: 1;
  }

  @media (--screen-md) {
    width: auto;
  }
}

.acceptButton {
  flex-basis: 60%;
}

.declineButton {
  flex-basis: 40%;
}
